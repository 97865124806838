import { createSelector } from 'reselect';
import {
  ADD_INTERACTION,
  REMOVE_INTERACTION,
  ADD_INTERACTIONS,
  GET_USER_INTERACTIONS,
} from '../actions/resources/interactionActions';
import { handleActionsAsync } from '../util/asyncReducer';
import createShallowEqualSelector from '../util/reducers/createShallowEqualSelector';

const interactionTypeReducer = handleActionsAsync(
  {
    [ADD_INTERACTIONS]: (state, { payload: { interactions } }) => ({
      ...state,
      done: [...state.done, ...interactions],
    }),
    [ADD_INTERACTION]: {
      pending: (state, { meta: { interactionSubjectId: id } }) => ({
        ...state,
        pending: [...state.pending, { action: ADD_INTERACTION, id }],
      }),
      resolved: (state, { meta: { interactionSubjectId: id } }) => ({
        ...state,
        pending: state.pending.filter(
          entry => !(entry.action === ADD_INTERACTION && entry.id === id),
        ),
        done: Array.from(new Set([...state.done, id])),
      }),
      rejected: (state, { meta: { interactionSubjectId: id } }) => ({
        ...state,
        pending: state.pending.filter(
          entry => !(entry.action === ADD_INTERACTION && entry.id === id),
        ),
      }),
    },
    [REMOVE_INTERACTION]: {
      pending: (state, { meta: { interactionSubjectId: id } }) => {
        const ids = [].concat(id);
        return {
          ...state,
          pending: [
            ...state.pending,
            ...ids.map(interactionId => ({ action: REMOVE_INTERACTION, id: interactionId })),
          ],
        };
      },
      resolved: (state, { meta: { interactionSubjectId: id } }) => {
        const ids = [].concat(id);
        return {
          ...state,
          pending: state.pending.filter(
            entry => !(entry.action === REMOVE_INTERACTION && ids.includes(entry.id)),
          ),
          done: state.done.filter(interactionId => !ids.includes(interactionId)),
        };
      },
      rejected: (state, { meta: { interactionSubjectId: id } }) => {
        const ids = [].concat(id);
        return {
          ...state,
          pending: state.pending.filter(
            entry => !(entry.action === REMOVE_INTERACTION && ids.includes(entry.id)),
          ),
        };
      },
    },
  },
  {
    done: [],
    pending: [],
  },
);

const interactionsReducer = (
  state = {
    loadedInitial: false,
    interactions: {},
  },
  action,
) => {
  if (action.meta && action.meta.interactionType) {
    const { interactionType } = action.meta;

    return {
      ...state,
      interactions: {
        ...state.interactions,
        [interactionType]: interactionTypeReducer(state.interactions[interactionType], action),
      },
    };
  }

  switch (action.type) {
    case GET_USER_INTERACTIONS:
      if (action.meta && action.meta.isFulfilled && !action.error) {
        return {
          ...state,
          loadedInitial: true,
        };
      }
      return state;
    default:
      return state;
  }
};

export const makeInteractionTargetStateSelector = () =>
  createSelector(
    (state, props) => state.interactions[props.interactionType],
    (state, props) => props.targetId,
    (interactions, targetId) => {
      const doneIds = (interactions && interactions.done) || [];
      const pendingActions = (interactions && interactions.pending) || [];
      const pendingAction = pendingActions.find(action => action.id === targetId);

      return {
        isDone: doneIds.includes(targetId),
        pendingAction,
      };
    },
  );

export const makeInteractionStateSelector = () =>
  createShallowEqualSelector(makeInteractionTargetStateSelector(), ({ isDone, pendingAction }) => {
    let isActive = isDone;
    if (pendingAction) {
      isActive = pendingAction.type === ADD_INTERACTION;
    }

    return {
      isActive,
      actionIsPending: !!pendingAction,
    };
  });

export default interactionsReducer;
