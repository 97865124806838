/* global 'molecule' */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';

import LocaleMessage from 'components/atoms/LocaleMessage';

import TextNew, { Colors, FontWeight } from '../../atoms/TextNew';

import './contact-us-success-message.scss';

class ContactUsSucessMessage extends Component {
  componentDidMount() {
    const { scrollToElement, disableScrollTo = false } = this.props;

    !disableScrollTo && scrollToElement(this.successMessage, -70);
  }
  render() {
    const { showLongResponseMessage } = this.props;

    return (
      <div
        ref={el => (this.successMessage = el)}
        {...componentClassNameProp('molecule', this)}
      >
        <TextNew.Serif.LG
          cid="thank-you"
          localeId="contactUsSuccessMessage.header"
          color={Colors.BRAND}
        />
        <TextNew.Sans.SM
          localeId="contactUsSuccessMessage.success"
          weight={FontWeight.LIGHT}
          cid="success"
        />

        {showLongResponseMessage ? (
          <TextNew.Sans.SM localeId="contactUsSuccessMessage.veryBusy" weight={FontWeight.LIGHT} />
        ) : (
          <TextNew.Sans.SM
            weight={FontWeight.LIGHT}
            localeParams={{
              DISCLAIMER: <LocaleMessage id="contactUsSuccessMessage.body.workDayDisclaimer" />,
            }}
            localeId="contactUsSuccessMessage.body.default"
          />
        )}
      </div>
    );
  }
}

ContactUsSucessMessage.propTypes = {
  disableScrollTo: PropTypes.bool,
  scrollToElement: PropTypes.func,
  showLongResponseMessage: PropTypes.bool,
};

export default ContactUsSucessMessage;
