import PropTypes from 'prop-types';
import FoodRangeCategory from '../../../data/enum/FoodRangeCategory';
import healthyExtraShape from './healthyExtra';
import imageShape from './image';

/** @module */

/**
 * Reusable object to use in `propTypes` for a `recipe` prop in a detail page.
 * @type {Object}
 * @category templating
 */
const recipeDetailShape = {
  slug: PropTypes.string,
  characteristics: PropTypes.number,
  cuisine: PropTypes.number,
  description: PropTypes.string,
  subdescription: PropTypes.string,
  contentGroup: PropTypes.string,
  image: PropTypes.shape(imageShape),
  ingredients: PropTypes.arrayOf(PropTypes.string),
  subSections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      ingredients: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  healthyExtras: PropTypes.arrayOf(PropTypes.shape(healthyExtraShape)),
  mealType: PropTypes.number,
  method: PropTypes.arrayOf(PropTypes.string),
  palette: PropTypes.string,
  preparationTime: PropTypes.number,
  cookingTime: PropTypes.number,
  likeCount: PropTypes.number,
  totalTime: PropTypes.number,
  additionalTimeInMinutes: PropTypes.number,
  additionalTimeSuffix: PropTypes.string,
  seasonal: PropTypes.number,
  servings: PropTypes.number,
  syns: PropTypes.number,
  tip: PropTypes.string,
  title: PropTypes.string,
  _source: PropTypes.shape({
    actionType: PropTypes.string.isRequired,
  }),
  related: PropTypes.arrayOf(
    PropTypes.shape({
      characteristics: PropTypes.number,
      id: PropTypes.string,
      image: PropTypes.shape(imageShape),
      isFoodRange: PropTypes.bool,
      link: PropTypes.string,
      palette: PropTypes.string,
      servings: PropTypes.number,
      syns: PropTypes.number,
      title: PropTypes.string,
      totalTime: PropTypes.number,
    }),
  ),
  isPublic: PropTypes.bool,
  isFoodRange: PropTypes.bool,
  foodRangeCategoryId: PropTypes.oneOf(Object.values(FoodRangeCategory)),
  id: PropTypes.string,
  diabetesNutritionalInformation: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number,
      content: PropTypes.string,
      colour: PropTypes.string,
      typeDescription: PropTypes.string,
    }),
  ),
};

export default recipeDetailShape;
