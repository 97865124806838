import React, { Fragment } from 'react';
import moment from 'moment';
import TextNew, {
  AccentColors,
  FontSizes,
  FontWeight,
  ElementTypes,
} from 'components/atoms/TextNew';
import PropTypes from 'prop-types';
import GroupField from 'components/atoms/GroupField';
import groupTimesFormatted from 'components/molecules/GroupTimesFormatted';
import withDeviceState, { QueryHelpers } from 'common/src/app/util/device-state/withDeviceState';

const GroupTimetable = ({
  groupSessions,
  textStyle,
  headerTextStyle,
  queryHelper,
  displayGroupTimesAsTime,
}) => {
  const { weight, size, color } = textStyle || {};

  const sessionLabelTextStyles = {
    weight: weight || (queryHelper !== QueryHelpers.TABLET ? FontWeight.LIGHT : FontWeight.NORMAL),
    size: size || (queryHelper === QueryHelpers.TABLET ? FontSizes.XS : FontSizes.SM),
    color: color || AccentColors.ACCENT_GREY,
  };

  return (
    <GroupField
      dataTestid="group-timetable"
      textStyle={headerTextStyle}
      label="groupTimetable.groupTimes"
      cid="group-timetable"
    >
      {Object.keys(groupSessions).map(s => (
        <Fragment key={`session-day-${s}`}>
          <div className="session-label">
            <TextNew.Sans {...sessionLabelTextStyles} cid="session-label-day">
              {moment()
                .weekday(s)
                .format('dddd')}
            </TextNew.Sans>
            <TextNew.Sans
              {...sessionLabelTextStyles}
              cid="session-label-times"
              element={ElementTypes.UL}
            >
              <TextNew element={ElementTypes.LI} color={color || AccentColors.ACCENT_GREY}>
                {`${groupTimesFormatted({
                  day: s,
                  session: groupSessions,
                  displayGroupTimesAsTime,
                })}`}
              </TextNew>
            </TextNew.Sans>
          </div>
        </Fragment>
      ))}
    </GroupField>
  );
};

GroupTimetable.propTypes = {
  groupSessions: PropTypes.object,
  queryHelper: PropTypes.string,
  textStyle: PropTypes.object,
  headerTextStyle: PropTypes.object,
  displayGroupTimesAsTime: PropTypes.bool.isRequired,
};

export default withDeviceState()(GroupTimetable);
