import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TitledBlock from 'components/atoms/TitledBlock';
import TextNew, { AccentColors, ElementTypes } from 'components/atoms/TextNew';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button';
import Pages from 'common/src/app/data/enum/Pages';
import LocaleMessage from 'components/atoms/LocaleMessage';
import LandingGrid from 'components/templates/LandingGrid';
import landingGridProps from 'components/templates/LandingGrid/landingGridProps';
import './related-success-stories.scss';

const RelatedSuccessStories = (
  { gridContents, getSearchResultsAction, successStories, successStoriesLimit },
  context,
  className,
) => {
  // Fill the success stories up to the successStoriesLimit with the latest success stories
  useEffect(() => {
    if (successStories.length < successStoriesLimit) {
      const excludedIds = successStories?.map(successStory => successStory.id);
      getSearchResultsAction(successStoriesLimit - successStories.length, excludedIds);
    }
  }, [getSearchResultsAction, successStories.length]);

  return (
    <div className={className}>
      <TitledBlock
        title={
          <h2>
            <TextNew.Serif.SM
              color={AccentColors.ACCENT_PURPLE}
              localeId="relatedSuccessStories.title"
              element={ElementTypes.H2}
            />
            <TextNew.Script.LG
              color={AccentColors.ACCENT_PURPLE}
              localeId="relatedSuccessStories.subtitle"
              cid="script-title"
              element={ElementTypes.H2}
            />
          </h2>
        }
      >
        <LandingGrid {...gridContents} allowLoadMore={false} hideSortControls />
        <Row justifycenter className="final-button">
          <Button isShadePink type="button" to={Pages.UK_PUBLIC_SUCCESS_STORIES}>
            <LocaleMessage id="relatedSuccessStories.meet" />
          </Button>
        </Row>
      </TitledBlock>
    </div>
  );
};

RelatedSuccessStories.defaultProps = {
  successStories: [],
  successStoriesLimit: 4,
};

RelatedSuccessStories.propTypes = {
  gridContents: landingGridProps,
  successStories: PropTypes.array,
  getSearchResultsAction: PropTypes.func,
  successStoriesLimit: PropTypes.number,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'RelatedSuccessStories',
)(RelatedSuccessStories);
