import React from 'react';
import PropTypes from 'prop-types';
import TextNew, { Colors, FontWeight } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';

import './offer-block.scss';

const OfferBlock = ({ offers }) => (
  <section className="offer-block">
    {offers?.map((offer, index) => {
      const offerKey = offer.title.toLowerCase().replace(' ', '-');

      return (
        <article className="offer" key={offerKey}>
          <TextNew.Sans.SM
            color={Colors.PRIMARY_LIGHT}
            style={FontWeight.BOLD}
            weight={FontWeight.BOLD}
          >
            {offer.title}
          </TextNew.Sans.SM>
          <TextNew.Serif.MD color={Colors.PRIMARY_LIGHT} weight={FontWeight.BOLD}>
            {offer.subtitle}
          </TextNew.Serif.MD>

          <RichTextBlock text={offer.description} cid="offer-body" />

          {index === 1 ? (
            <Button
              cid="download-button"
              href={offer.pdfLink}
              target="_blank"
              dataTestid="voucher-download"
            >
              <TextNew.Sans.SM color={Colors.PRIMARY_LIGHT}>{offer.pdfButtonLabel}</TextNew.Sans.SM>
            </Button>
          ) : null}

          <TextNew.Serif.XS color={Colors.PRIMARY_LIGHT} weight={FontWeight.BOLD} cid="offer-code">
            {offer.discountCode}
          </TextNew.Serif.XS>

          <Button cid="offer-button" href={offer.buttonLink}>
            <TextNew.Sans.SM color={Colors.PRIMARY_LIGHT}>{offer.buttonLabel}</TextNew.Sans.SM>
          </Button>
        </article>
      );
    })}
  </section>
);

OfferBlock.propTypes = {
  offers: PropTypes.array,
};

export default OfferBlock;
