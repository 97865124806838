/**
 * Converts values from cm to feet/inches
 * @param {string} centimeters A string that contains an integer representing
 *
 * @returns {object} The resulting object should be { feet, inches }
 */
const decomposeHeightImperialToMetric = centimeters => {
  if (typeof centimeters === 'undefined') {
    return { feet: '', inches: '' };
  }
  const parsedCentimeters = centimeters || 0;

  const wholeInches = parsedCentimeters / 2.54;
  const parsedFeet = Math.floor(wholeInches / 12);
  const parsedInches = Math.round(wholeInches % 12);

  return {
    feet: parsedFeet,
    inches: parsedInches,
  };
};

export default decomposeHeightImperialToMetric;
