import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { push as historyPush } from 'react-router-redux';
import { setGroupSearchLoading } from 'common/src/app/actions/resources/groupSearchActions';
import { withFormik } from 'formik';
import Pages from 'common/src/app/data/enum/Pages';
import GroupSearchFieldNames from 'common/src/app/data/enum/FieldNames/GroupSearchFieldNames';
import FormNames from 'common/src/app/data/enum/FormNames';
import DropDownGroupSearch from './DropDownGroupSearch';

const mapStateToProps = () => state => ({
  initialValues: {
    [GroupSearchFieldNames.COUNTY]: state?.routing.locationBeforeTransitions.query.query || '',
  },
});

const mapDispatchToProps = dispatch => ({
  handleCountySearch: async county => {
    try {
      await dispatch(setGroupSearchLoading(true));
      return await dispatch(
        historyPush(`${Pages.UK_PUBLIC_NEAREST_GROUP_LANDING}?query=${county}`),
      );
    } catch (error) {
      return error;
    }
  },
});

const enhanced = withFormik({
  displayName: FormNames.PUBLIC_GROUP_SEARCH_COUNTY,
  enableReinitialize: true,
  mapPropsToValues: ({ initialValues }) => ({
    [GroupSearchFieldNames.COUNTY]: initialValues[GroupSearchFieldNames.COUNTY],
  }),
  handleSubmit: (values, { props }) => {
    props.handleCountySearch(values[GroupSearchFieldNames.COUNTY]);
  },
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected, enhanced)(DropDownGroupSearch);
