/* global 'molecule' */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import getTooltipPosition from 'common/src/app/util/getTooltipPosition';
import TextNew, { ElementTypes } from '../../atoms/TextNew';
import './tooltip-wrapper.scss';

/**
 * Tooltip wrapper
 *
 * By default the tooltip arrow is top centered aligned
 *
 * There are in total 6 positions you can set as default:
 * - top (centered)
 * - top left
 * - top right
 * - bottom (centered)
 * - bottom left
 * - bottom right
 *
 * The tooltip reacts to it's surroundings to place it in the most possible position
 *
 * Play with the viewport to see the results
 */
class TooltipWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      tooltip: {
        bottom: 0,
        left: 0,
        position: props.position,
      },
    };
  }

  /**
   * Here lies the logic for the collision detection around the element
   */
  getCollisionDetection = () => {
    const { headerHeight, position } = this.props;

    // The tooltip positions
    const centeredAligned = [DirectionType.TOP, DirectionType.BOTTOM];
    const leftAligned = [DirectionType.TOP_LEFT, DirectionType.BOTTOM_LEFT];
    const rightAligned = [DirectionType.TOP_RIGHT, DirectionType.BOTTOM_RIGHT];

    // The total viewport width and height
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // The hover-able child element
    const targetElement = this.elementWrapper;
    const {
      width: targetElementWidth,
      height: targetElementHeight,
      top: targetElementTop,
      left: targetElementLeft,
    } = targetElement.getBoundingClientRect();

    // The tooltip element
    const tooltip = this.tooltip;
    const { width: tooltipWidth, height: tooltipHeight } = tooltip.getBoundingClientRect();

    // Calculate the collision width based on the current tooltip position
    let tooltipCollisionWidth = 0;
    if (centeredAligned.includes(position)) {
      tooltipCollisionWidth = tooltipWidth / 2;
    } else if (
      (leftAligned.includes(position) || rightAligned.includes(position)) &&
      targetElementWidth < tooltipWidth
    ) {
      tooltipCollisionWidth = tooltipWidth - targetElementWidth;
    }

    const topCollisionDetected = targetElementTop - tooltipHeight < headerHeight;
    const bottomCollisionDetected =
      targetElementTop + targetElementHeight + tooltipHeight > windowHeight;
    const leftCollisionDetected = targetElementLeft - tooltipCollisionWidth < 0;
    const rightCollisionDetected =
      targetElementLeft + targetElementWidth + tooltipCollisionWidth > windowWidth;

    return {
      topCollisionDetected,
      bottomCollisionDetected,
      leftCollisionDetected,
      rightCollisionDetected,
    };
  };

  /**
   * Get the new calculated positions for the tooltip
   */
  getNewPosition = () => {
    const { position: defaultPosition } = this.props;
    const collisionObject = this.getCollisionDetection();
    return getTooltipPosition(defaultPosition, collisionObject);
  };

  /**
   * Get the style positions based on the new calculated positions
   *
   * note: IE transform with calc doesn't work
   */
  getStylePosition = position => {
    const elementSize = this.elementWrapper.getBoundingClientRect();
    const arrowHeight = this.arrow.getBoundingClientRect().height;
    const tooltipHeight = this.tooltip.getBoundingClientRect().height;

    // arrowHeight * arrowHeight = 9px between tooltip and the target element
    const topAligned = `calc(100% + ${arrowHeight * arrowHeight}px)`;
    const bottomAligned = `-${tooltipHeight + arrowHeight * arrowHeight}px`;
    const centeredAligned = `translateX(-50%) translateX(+${elementSize.width / 2}px)`;
    const rightAligned = `translateX(-100%) translateX(+${elementSize.width}px)`;

    let bottom = 0;
    let left = 0;

    switch (position) {
      case DirectionType.TOP_LEFT:
        bottom = topAligned;
        left = 0;
        break;

      case DirectionType.TOP_RIGHT:
        bottom = topAligned;
        left = rightAligned;
        break;

      case DirectionType.BOTTOM:
        bottom = bottomAligned;
        left = centeredAligned;
        break;

      case DirectionType.BOTTOM_LEFT:
        bottom = bottomAligned;
        left = 0;
        break;

      case DirectionType.BOTTOM_RIGHT:
        bottom = bottomAligned;
        left = rightAligned;
        break;

      default:
        bottom = topAligned;
        left = centeredAligned;
        break;
    }

    return {
      bottom,
      left,
    };
  };

  mouseEnter = () => {
    const newPosition = this.getNewPosition();
    const { bottom, left } = this.getStylePosition(newPosition);
    this.setState({
      isVisible: true,
      tooltip: {
        position: newPosition,
        bottom,
        left,
      },
    });
  };

  mouseLeave = debounce(50, false, () => {
    this.setState({
      isVisible: false,
      tooltip: {
        position: this.props.position,
      },
    });
  });

  render() {
    const { label, children } = this.props;

    const { tooltip } = this.state;

    return (
      <div
        {...componentClassNameProp('molecule', this, [
          'state.is-visible',
          '{state.tooltip.position}',
          '{colorScheme}',
        ])}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
      >
        <div ref={ref => (this.elementWrapper = ref)} className="element-wrapper">
          <span ref={ref => (this.arrow = ref)} className="tooltip-arrow" />
          {children}
        </div>
        <div
          ref={ref => (this.tooltip = ref)}
          className="tooltip"
          style={{
            bottom: tooltip.bottom,
            transform: tooltip.left,
            WebkitTransform: tooltip.left,
          }}
        >
          {label && <TextNew.Formal element={ElementTypes.SPAN}>{label}</TextNew.Formal>}
        </div>
      </div>
    );
  }
}

TooltipWrapper.defaultProps = {
  headerHeight: 64,
  position: DirectionType.TOP,
};

TooltipWrapper.propTypes = {
  headerHeight: PropTypes.number,
  /**
   * Label that will be shown in the tooltip
   */
  label: PropTypes.node,
  /**
   * The child elements
   */
  children: PropTypes.node.isRequired,
  /**
   * Optional to override the default positions for the tooltip
   */
  position: PropTypes.oneOf([
    DirectionType.TOP,
    DirectionType.TOP_LEFT,
    DirectionType.TOP_RIGHT,
    DirectionType.BOTTOM,
    DirectionType.BOTTOM_LEFT,
    DirectionType.BOTTOM_RIGHT,
  ]),
  /**
   * Color scheme for the tooltip
   */
  // eslint-disable-next-line react/no-unused-prop-types
  colorScheme: PropTypes.oneOf(['primaryLightColorTheme']),
};

export default TooltipWrapper;
