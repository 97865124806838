export default {
  TOP: 'top',
  TOP_RIGHT: 'top-right',
  RIGHT: 'right',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM: 'bottom',
  BOTTOM_LEFT: 'bottom-left',
  LEFT: 'left',
  TOP_LEFT: 'top-left',
  UP: 'up',
  DOWN: 'down',
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};
