import React from 'react';
import Link from 'react-router/lib/Link';
import PropTypes from 'prop-types';

import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import { imageRatiosMap } from 'common/src/app/util/masterTileImageRatios';

import ImageFlip from '../../organisms/ImageFlip';

import TileFoodRange from '../../atoms/TileFoodRange';

import DiabetesUkBanner from '../../atoms/DiabetesUkBanner';

import Image from '../Image';

const TileImage = ({
  image,
  oldImage,
  onClick,
  link,
  title,
  person,
  isFeatured,
  imageLoading,
  isFoodRange,
  isDiabetesUk,
  _type,
}) => {
  const imageRatio = imageRatiosMap?.[_type]?.[isFeatured ? 'isFeatured' : 'normal'] || 1; // eslint-disable-line no-underscore-dangle

  // The Real life story tile has 2 images - we use imageFlip to flip between them
  if (oldImage && image) {
    return (
      <ImageFlip
        image={image}
        revealImage={oldImage}
        link={link}
        title={title}
        person={person}
        ratio={imageRatio}
      />
    );
  } else if (!oldImage && image) {
    return (
      <Link
        tabIndex="-1"
        aria-hidden="true"
        to={link}
        className="image-link"
        onClick={onClick}
        data-testid="image-link"
      >
        {image && (
          <>
            <Image
              src={image.src || image.content}
              alt={image.alt || title}
              loading={imageLoading}
              ratio={imageRatio}
              useWebp={image.webpFallback}
              role="img"
            />

            {isFoodRange && <TileFoodRange />}
            {isDiabetesUk && <DiabetesUkBanner />}
          </>
        )}
      </Link>
    );
  }

  return null;
};

TileImage.propTypes = {
  image: PropTypes.shape({
    ...imageShape,
  }),
  onClick: PropTypes.func,
  link: PropTypes.string,
  title: PropTypes.string,
  person: PropTypes.string,
  oldImage: PropTypes.shape({
    ...imageShape,
  }),
  isFeatured: PropTypes.bool,
  imageLoading: PropTypes.oneOf(['eager', 'lazy']),
  isFoodRange: PropTypes.bool,
  isDiabetesUk: PropTypes.bool,
  _type: PropTypes.string,
};

export default TileImage;
