import React from 'react';
import PropTypes from 'prop-types';
import Row from 'components/atoms/Row';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';

import './colour-block.scss';

const breakpoints = {
  767: { vw: 100 },
  1023: { px: 400, ratio: 12 / 29 },
  1439: { px: 500, ratio: 41 / 56 },
  other: { vw: 100 },
};

const ColourBlock = ({
  backgroundColour,
  fontColour,
  image,
  imageOrientation,
  title,
  content,
  title2,
  content2,
}) => {
  const backgroundColorOptions = {
    creme: 'light-tone',
    green: 'accent-green',
    grey: 'accent-grey',
    'light-blue': 'accent-blue',
    pink: 'accent-pink',
    purple: 'accent-purple',
    red: 'accent-red',
    white: 'primary-light',
  };

  const fontColorOptions = {
    black: 'primary-dark',
    white: 'primary-light',
    purple: 'accent-purple',
    red: 'accent-red',
    creme: 'light-tone',
    green: 'accent-green',
    grey: 'accent-grey',
    'light-blue': 'accent-blue',
  };

  // map the passed prop colour into a known font color
  const mappedFontColor = fontColorOptions[fontColour] || Colors.PRIMARY_LIGHT;

  return (
    <div
      className={`molecule-colour-block${
        backgroundColour ? ` bg-${backgroundColorOptions[backgroundColour]}` : ''
      }${imageOrientation === 0 ? ' img-left' : ' img-right'}`}
      data-testid="colour-block"
    >
      <Row className="article-detail" column grow>
        {title && (
          <TextNew.Script.SM
            color={mappedFontColor}
            element={ElementTypes.H2}
            cid="color-block-title"
          >
            {title}
          </TextNew.Script.SM>
        )}

        {content && (
          <div className="paragraph">
            <RichTextBlock text={content} />
          </div>
        )}

        {title2 && (
          <div className="paragraph">
            <TextNew.Script.XS color={mappedFontColor}>{title2}</TextNew.Script.XS>
          </div>
        )}

        {content2 && (
          <div className="paragraph">
            <RichTextBlock text={content2} />
          </div>
        )}
      </Row>

      {image?.src && (
        <figure>
          <ResponsiveImage
            src={image.src}
            breakpoints={breakpoints}
            dataTestid="inspiration-image"
            alt="inspiration-image"
          />
        </figure>
      )}
    </div>
  );
};

ColourBlock.propTypes = {
  backgroundColour: PropTypes.string,
  fontColour: PropTypes.oneOf([...Object.values(Colors)]).isRequired,
  image: PropTypes.shape({
    ...imageShape,
  }),
  imageOrientation: PropTypes.oneOf([0, 1]),
  title: PropTypes.string,
  content: PropTypes.string,
  title2: PropTypes.string,
  content2: PropTypes.string,
};

export default ColourBlock;
