import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition as Transition } from 'react-transition-group';

import './css-transition.scss';

const CSSTransition = ({ children, animationType, animateIn }) => (
  <Transition in={animateIn} timeout={500} className={`${animationType}-animation`}>
    {children}
  </Transition>
);

CSSTransition.propTypes = {
  children: PropTypes.node,
  animationType: PropTypes.string,
  animateIn: PropTypes.bool,
};
export default CSSTransition;
