import { connect } from 'react-redux';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { getCounties } from 'common/src/app/actions/resources/groupSearchActions';
import NearestGroupSearchBar from './NearestGroupSearchBar';

const countiesSelector = state => state.view.nearestGroup?.counties;

const mapStateToProps = () => state => ({
  query: state.routing?.locationBeforeTransitions.query,
  geoLocationError: state.geoLocation?.error,
  countyOptions: countiesSelector(state),
});

const mapDispatchToProps = dispatch => {
  if (marketConfig.dropDownBasedGroupSearch) {
    return {
      getCounties: () => dispatch(getCounties(marketConfig.marketName)).catch(),
    };
  }

  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NearestGroupSearchBar);
