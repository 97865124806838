import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { API_STATIC_PUBLIC_MENTAL_HEALTH } from 'common/src/app/data/apiStatics';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import MentalHealth from './MentalHealth';

const healthLinksUK = [
  {
    title: 'mentalHealth.england.title',
    links: [
      {
        href: PagesExternal.NHS_MENTAL_HEALTH_SERVICES,
        localeId: 'mentalHealth.england.one',
      },
      {
        href: PagesExternal.ONE_YOU_MENTAL_HEALTH,
        localeId: 'mentalHealth.england.two',
      },
      {
        href: PagesExternal.MENTAL_HEALTH_UK,
        localeId: 'mentalHealth.england.three',
      },
      {
        href: PagesExternal.MIND,
        localeId: 'mentalHealth.england.four',
      },
      {
        href: PagesExternal.SAMARITANS,
        localeId: 'mentalHealth.england.five',
      },
    ],
  },
  {
    title: 'mentalHealth.scotland.title',
    links: [
      {
        href: PagesExternal.BREATHING_SPACE,
        localeId: 'mentalHealth.scotland.two',
      },
      {
        href: PagesExternal.NHS_INFORM_MENTAL_HEALTH,
        localeId: 'mentalHealth.scotland.one',
      },
      {
        href: PagesExternal.SCOTTISH_ASSOCIATION_FOR_MENTAL_HEALTH,
        localeId: 'mentalHealth.scotland.four',
      },
      {
        href: PagesExternal.MENTAL_HEALTH_UK,
        localeId: 'mentalHealth.scotland.five',
      },
      {
        href: PagesExternal.SAMARITANS,
        localeId: 'mentalHealth.scotland.six',
      },
    ],
  },
  {
    title: 'mentalHealth.wales.title',
    links: [
      {
        href: PagesExternal.MENTAL_HEALTH_HELPLINE_FOR_WALES,
        localeId: 'mentalHealth.wales.one',
      },
      {
        href: PagesExternal.PUBLIC_HEALTH_WALES,
        localeId: 'mentalHealth.wales.two',
      },
      {
        href: PagesExternal.MENTAL_HEALTH_WALES,
        localeId: 'mentalHealth.wales.three',
      },
      {
        href: PagesExternal.MENTAL_HEALTH_UK,
        localeId: 'mentalHealth.wales.four',
      },
      {
        href: PagesExternal.MIND,
        localeId: 'mentalHealth.wales.five',
      },
      {
        href: PagesExternal.SAMARITANS,
        localeId: 'mentalHealth.wales.six',
      },
    ],
  },
];
const healthLinksIE = [
  {
    title: 'mentalHealth.northern.title',
    links: [
      {
        href: PagesExternal.ACTION_MENTAL_HEALTH,
        localeId: 'mentalHealth.northern.one',
      },
      {
        href: PagesExternal.NI_DIRECT,
        localeId: 'mentalHealth.northern.two',
      },
      {
        href: PagesExternal.LIFELINE,
        localeId: 'mentalHealth.northern.three',
      },
      {
        href: PagesExternal.MENTAL_HEALTH_UK,
        localeId: 'mentalHealth.northern.four',
      },
      {
        href: PagesExternal.SAMARITANS,
        localeId: 'mentalHealth.northern.five',
      },
    ],
  },
  {
    title: 'mentalHealth.roi.title',
    links: [
      {
        href: PagesExternal.MENTAL_HEALTH_IRELAND,
        localeId: 'mentalHealth.roi.one',
      },
      {
        href: PagesExternal.YOUR_MENTAL_HEALTH,
        localeId: 'mentalHealth.roi.two',
      },
      {
        href: PagesExternal.SAMARITANS,
        localeId: 'mentalHealth.roi.three',
      },
    ],
  },
];

const mapStateToProps = state => ({
  pageContent: staticPageSelector(state, API_STATIC_PUBLIC_MENTAL_HEALTH),
  healthLinksUK,
  healthLinksIE,
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getStaticContent(API_STATIC_PUBLIC_MENTAL_HEALTH, true)),
});

export default compose(addInitAction, connected)(MentalHealth);
