import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import Home from 'bundle-loader?lazy&reactHot&name=Home!./index';

const routes = (
  <Route
    title="Welcome to Slimming World | Achieve your weight loss dreams"
    description="You’ll love Slimming World’s generous weight loss plan and personalised support – the easiest way to lose weight for life! Start your journey today"
    path={Pages.UK_PUBLIC_HOME}
    {...getRouteComponentProp(Home)}
  />
);

export default routes;
