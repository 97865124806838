import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  textBasedGroupSearch,
  hasGeolocationGroupSearch,
} from 'common/src/app/config/market/market.configdefinitions';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import IconName from 'common/src/app/data/enum/IconName';
import classNames from 'classnames';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import Icon from 'components/atoms/Icon';
import Wrapper from '../../atoms/Wrapper';
import FreeTextGroupSearch from '../../molecules/FreeTextGroupSearch';
import DropDownGroupSearch from '../../molecules/DropDownGroupSearch';
import SignUpCTA from '../../molecules/UkPublicNavigation/components/molecules/SignUpCTA';
import Button from '../../atoms/Button';

import './join-bar.scss';

const JoinBar = ({
  deviceState,
  isEmptySearchQuery,
  geoLocationError,
  external,
  getCounties,
  countyOptions,
  resultsPositionedRelative,
  accountHost,
}) => {
  const className = 'organism-join-bar';
  const [isOpenCSS, setIsOpenCSS] = useState(false);

  useEffect(() => {
    if (deviceState > DeviceState.SM) setIsOpenCSS(true);
    else setIsOpenCSS(false);
  }, [deviceState]);

  return (
    <div
      data-testid="join-bar"
      className={classNames(className, {
        'results-positioned-relative': resultsPositionedRelative,
        'text-based-search': textBasedGroupSearch,
      })}
    >
      <Wrapper width="md" className={`container ${isOpenCSS ? 'open' : ''}`}>
        <div
          data-testid="group-search-form"
          className={`group-search-form ${isOpenCSS ? 'open' : ''}`}
        >
          {deviceState <= DeviceState.SM ? (
            <Button isTertiary cid="close-group-search" onClick={() => setIsOpenCSS(!isOpenCSS)}>
              <Icon cid="icon-cross" name={IconName.CROSS} />
            </Button>
          ) : null}
          <div className="groupSearchWrapper">
            {textBasedGroupSearch ? (
              typeof window !== 'undefined' && (
                <FreeTextGroupSearch
                  showSearchSubmit
                  external={external}
                  localeId="joinBar"
                  resultsPositionedRelative={resultsPositionedRelative}
                  geoLocationError={geoLocationError}
                  isOpenCSS={isOpenCSS}
                />
              )
            ) : (
              <DropDownGroupSearch
                deviceState={deviceState}
                isEmptySearchQuery={isEmptySearchQuery}
                getCounties={getCounties}
                countyOptions={countyOptions}
                showSubmit={true}
                localeId="joinBar"
              />
            )}
          </div>

          {hasGeolocationGroupSearch && geoLocationError && (
            <TextNew.Error
              cid="geo-location-error"
              color={Colors.ERROR}
              localeId="publicGroupSearch.errors.geoLocation.error"
            />
          )}
        </div>
        {deviceState <= DeviceState.SM ? (
          <Button
            onClick={() => setIsOpenCSS(!isOpenCSS)}
            cid="group-search-hide"
            dataTestid="free-text-group-search-button-toggle"
          >
            <TextNew.Sans.SM
              color={Colors.PRIMARY_LIGHT}
              element={ElementTypes.SPAN}
              localeId="joinBar.button"
            />
          </Button>
        ) : null}
        <SignUpCTA accountHost={accountHost} />
      </Wrapper>
    </div>
  );
};

JoinBar.propTypes = {
  accountHost: PropTypes.string,
  deviceState: PropTypes.number,
  isEmptySearchQuery: PropTypes.bool,
  countyOptions: PropTypes.arrayOf(PropTypes.string),
  getCounties: PropTypes.func,
  geoLocationError: PropTypes.object,
  external: PropTypes.bool,
  resultsPositionedRelative: PropTypes.bool,
};

export default withDeviceState()(JoinBar);
