import apiGetEntity from 'common/src/app/actions/resources/apiActions/apiGetEntity';
import orderBy from 'lodash/orderBy';
import { CONSULTANT } from 'common/src/app/data/entityTypes';
import { apiGet } from './apiActions/apiRequest';
import { GATEWAY_GROUP_SEARCH } from '../../data/Injectables';
import dedupeAsyncThunk from '../../util/dedupeAsyncThunk';
import { setGroupSearchResults } from './groupSearchActions';

export const GET_CONSULTANT_DETAIL = 'groupSearchConsultantActions/GET_CONSULTANT_DETAIL';

/**
 * Action to return consultant details including bio & image
 */
export const getConsultantDetail = code =>
  apiGetEntity(
    GET_CONSULTANT_DETAIL,
    GATEWAY_GROUP_SEARCH,
    `/consultants/${code}`,
    CONSULTANT,
    code,
    {
      updateEntityView: 'view.pages.groupDetail.consultant',
    },
  );

export const GET_CONSULTANT_GROUPS = 'groupSearchConsultantActions/GET_CONSULTANT_GROUPS';

/**
 * Action to return all of the groups a consultant runs
 */

export const getConsultantGroups = dedupeAsyncThunk(
  code => dispatch =>
    dispatch(
      apiGet(GET_CONSULTANT_GROUPS, GATEWAY_GROUP_SEARCH, `/consultants/${code}/groups`),
    ).then(result => {
      // filter out consultant data
      const groups = result.data.groups.map(group => {
        // Remove consultant details has these are only displayed once at the top
        // eslint-disable-next-line no-unused-vars
        const { consultant, ...groupDetails } = group;
        return groupDetails;
      });

      const groupsSored = orderBy(groups, ['venue.town', 'venue.address1']);

      return dispatch(setGroupSearchResults(groupsSored));
    }),
  true,
);
