import { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { NavViews } from 'common/src/app/data/enum/NavViews';

const setUiStatus = (viewOptions, status) =>
  viewOptions.reduce((object, key) => ({ ...object, [key]: status }), {});

/**
 *   Takes an array of arguments (using NavViews enum) and set in state
 *   Which the UkPublicNavigation component uses to conditionally output
 *   We always clear the view options and re set on componentDidUpdate
 *   and clear completely on Unmount (e.g. to catch navigation)
 */
class NavigationController extends Component {
  componentDidMount() {
    const { hideUiOptions, toggleUiFeatures } = this.props;
    !hideUiOptions && new Error('no display options passed to NavigationControler');
    toggleUiFeatures(setUiStatus(hideUiOptions, true));
  }

  componentDidUpdate(prevProps) {
    const { hideUiOptions, toggleUiFeatures } = this.props;
    if (!isEqual(prevProps.hideUiOptions, hideUiOptions)) {
      toggleUiFeatures(setUiStatus(hideUiOptions, true));
    }
  }

  componentWillUnmount() {
    const { hideUiOptions, toggleUiFeatures } = this.props;
    toggleUiFeatures(setUiStatus(hideUiOptions, false));
  }

  render() {
    return null;
  }
}

NavigationController.propTypes = {
  toggleUiFeatures: PropTypes.func,
  hideUiOptions: PropTypes.array,
};

export { NavViews };

export default NavigationController;
