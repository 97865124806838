import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Loader from 'components/atoms/Loader';
import recipeDetailShape from 'common/src/app/util/proptypes/apiEntities/recipeDetail';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TypicalDayMealPlan from 'components/organisms/TypicalDayMealPlan';
import GroupOnlineSignUp from 'components/molecules/GroupOnlineSignUp';
import RecipeDetailContent from 'components/organisms/RecipeDetailContent';
import StructuredDataGenerator, { types } from 'components/atoms/StructuredDataGenerator';
import TextNew, { AccentColors } from 'components/atoms/TextNew';
import errorBoundary from 'components/hoc/ErrorBoundary';
import factSheetShape from 'common/src/app/util/proptypes/apiEntities/factSheetShape';
import NewsletterSignUp from '../../../../../molecules/NewsletterSignUp';

import './recipe-detail.scss';

const RecipeDetail = (
  { recipe, factSheet, brightcoveAccountId, brightcovePlayerId, trackVideoEvent, isInitializing },
  context,
  className,
) => (
  <div className={className}>
    {recipe && !isInitializing ? (
      <RecipeDetailContent
        recipe={recipe}
        factSheet={factSheet}
        brightcoveAccountId={brightcoveAccountId}
        brightcovePlayerId={brightcovePlayerId}
        trackVideoEvent={trackVideoEvent}
      />
    ) : (
      <Loader />
    )}

    <TypicalDayMealPlan showSearcBarIntro subTitle={subTitle} />
    <GroupOnlineSignUp />
    <NewsletterSignUp />
    <StructuredDataGenerator data={recipe} type={types.RECIPE} />
  </div>
);

const subTitle = (
  <>
    <TextNew.Serif.SM
      color={AccentColors.ACCENT_PURPLE}
      cid="minititle"
      localeId="recipeDetail.sevenDayMenu.title"
    />
    <TextNew.Script.SM
      color={AccentColors.ACCENT_PURPLE}
      cid="title"
      localeId="recipeDetail.sevenDayMenu.title2"
    />
  </>
);

RecipeDetail.propTypes = {
  /**
   * The recipe to render
   */
  recipe: PropTypes.shape(recipeDetailShape),
  isInitializing: PropTypes.bool,
  /**
   * Dietary info
   */
  factSheet: PropTypes.shape(factSheetShape),
  brightcoveAccountId: PropTypes.string,
  brightcovePlayerId: PropTypes.string,
  /**
   * Action to expose video information to the data layer
   */
  trackVideoEvent: PropTypes.func,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.PAGE, 'RecipeDetail'),
)(RecipeDetail);
