/* global 'template','PodcastLanding' */
import React from 'react';
import PropTypes from 'prop-types';
import TextNew, { ElementTypes, Colors, AccentColors, FontSizes } from 'components/atoms/TextNew';
import Loader from 'components/atoms/Loader';
import Wrapper from 'components/atoms/Wrapper';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import FeaturedPodcasts from 'components/organisms/FeaturedPodcasts/FeaturedPodcasts';
import PodcastsList from 'components/organisms/PodcastsList/PodcastsList';
import Presenters from '../../organisms/Presenters';
import ListenNow from '../../organisms/ListenNow';

import './podcast-landing.scss';

const PodcastLanding = ({ page, deviceState }, context, className) => {
  let iconWidth = 80;

  if (deviceState >= DeviceState.XL) {
    iconWidth = 160;
  } else if (deviceState === DeviceState.LG) {
    iconWidth = 111;
  }

  if (!page) {
    return (
      <div data-testid="podcast-landing-loading" className={className}>
        <Loader />
      </div>
    );
  }

  return (
    <div data-testid="podcast-landing" className={className}>
      <Wrapper.MD padding="xl">
        <div className="icon-wrapper">
          <Icon width={iconWidth} name={IconName.PODCAST} />
        </div>
        <h1>
          <TextNew.Serif.XL
            cid="title"
            localeId="podcastLanding.title"
            color={AccentColors.ACCENT_GREY}
            element={ElementTypes.SPAN}
            size={deviceState >= DeviceState.MD ? FontSizes.XL : FontSizes.LG}
          />
          <TextNew.Script.LG
            cid="sub-title"
            localeId="podcastLanding.subTitle"
            color={Colors.BRAND}
            element={ElementTypes.SPAN}
            size={deviceState >= DeviceState.XL ? FontSizes.XL : FontSizes.MD}
          />
        </h1>
        {page?.introText && (
          <TextNew.Sans.SM cid="intro" element={ElementTypes.SPAN}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: page.introText }}
            />
          </TextNew.Sans.SM>
        )}
        {page?.presenters && <Presenters presenters={page.presenters} />}
      </Wrapper.MD>
      <Wrapper.MD cid="lower-section">
        <div className="lower-container">
          {page?.listenNow && (
            <ListenNow listenNow={page.listenNow} listenBelowCopy={page?.clickBelowToListen} />
          )}

          {page?.featuredPodcasts && <FeaturedPodcasts podcasts={page.featuredPodcasts} />}
          {page?.categories && (
            <>
              <TextNew.Serif.LG cid="categories" localeId="podcastLanding.categories" />
              <PodcastsList categories={page.categories} />
            </>
          )}
        </div>
      </Wrapper.MD>
    </div>
  );
};

PodcastLanding.propTypes = {
  page: PropTypes.object,
  deviceState: PropTypes.number,
};

export default withDeviceState()(withFunctionalClassName('template','PodcastLanding')(PodcastLanding));
