import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import { PUBLIC_NEAREST_GROUP_MAX_AGE } from 'common/src/app/data/cachingDefaults';
import NearestGroupDetail from 'bundle-loader?lazy&reactHot&name=NearestGroupDetail!./components/templates/NearestGroupDetail';
import NearestGroupLanding from 'bundle-loader?lazy&reactHot&name=NearestGroupLanding!./components/templates/NearestGroupLanding';
import NearestGroupCounties from 'bundle-loader?lazy&reactHot&name=NearestGroupCounties!./components/templates/NearestGroupCounties';
import NearestGroupTownsInCounty from 'bundle-loader?lazy&reactHot&name=NearestGroupTownsInCounty!./components/templates/NearestGroupTownsInCounty';
import NearestGroupsInATown from 'bundle-loader?lazy&reactHot&name=NearestGroupsInATown!./components/templates/NearestGroupsInATown';
import NearestGroupConsultantLanding from 'bundle-loader?lazy&reactHot&name=NearestGroupConsultantLanding!./components/templates/NearestGroupConsultantLanding';

const routes = (
  <Route>
    <Route
      path={Pages.UK_PUBLIC_NEAREST_GROUP_LANDING}
      {...getRouteComponentProp(NearestGroupLanding)}
      maxAge={PUBLIC_NEAREST_GROUP_MAX_AGE}
      title="Slimming World Groups | Find a Weight Loss Group Near Me"
      description="Start your journey to a slimmer, healthier you today! Search for your local Slimming World groups here and get in touch with the Consultant to find out more."
    />
    <Route
      path={Pages.UK_PUBLIC_NEAREST_GROUP_DETAIL}
      {...getRouteComponentProp(NearestGroupDetail)}
      maxAge={PUBLIC_NEAREST_GROUP_MAX_AGE}
    />
    <Route
      path={Pages.UK_PUBLIC_NEAREST_GROUP_COUNTIES}
      {...getRouteComponentProp(NearestGroupCounties)}
      maxAge={PUBLIC_NEAREST_GROUP_MAX_AGE}
    />
    <Route
      path={Pages.UK_PUBLIC_NEAREST_GROUP_TOWNS_IN_COUNTY}
      {...getRouteComponentProp(NearestGroupTownsInCounty)}
      maxAge={PUBLIC_NEAREST_GROUP_MAX_AGE}
    />
    <Route
      path={Pages.UK_PUBLIC_NEAREST_GROUP_GROUPS_IN_TOWNS}
      {...getRouteComponentProp(NearestGroupsInATown)}
      maxAge={PUBLIC_NEAREST_GROUP_MAX_AGE}
    />
    <Route
      path={Pages.UK_PUBLIC_NEAREST_GROUP_TOWNS}
      {...getRouteComponentProp(NearestGroupsInATown)}
      maxAge={PUBLIC_NEAREST_GROUP_MAX_AGE}
    />
    <Route
      path={Pages.UK_PUBLIC_NEAREST_CONSULTANT_LANDING}
      {...getRouteComponentProp(NearestGroupConsultantLanding)}
      maxAge={PUBLIC_NEAREST_GROUP_MAX_AGE}
    />
  </Route>
);

export default routes;
