import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import {
  API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION_IE,
  API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION_IE_PAST,
  API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION,
  API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION_PAST,
} from 'common/src/app/data/apiComponents';
import { countryCode } from 'common/src/app/data/enum/Countries';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';
import IcelandFreebieDeal from './IcelandFreebieDeal';

const freebieNewImage =
  marketConfig.marketName === countryCode.IRELAND
    ? API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION_IE
    : API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION;

const freebieOldImage =
  marketConfig.marketName === countryCode.IRELAND
    ? API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION_IE_PAST
    : API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION_PAST;

const mapStateToProps = state => ({
  freebieNewImage: contentComponentSelector(state, freebieNewImage, 'image'),
  freebieOldImage: contentComponentSelector(state, freebieOldImage, 'image'),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    Promise.all([dispatch(getComponent(freebieNewImage)), dispatch(getComponent(freebieOldImage))]),
});

export default compose(addInitAction, connected)(IcelandFreebieDeal);
