import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import BetterHealth from 'bundle-loader?lazy&reactHot&name=BetterHealth!./index';

const routes = (
  <Route
    title="Slimming World: Better health"
    description="If you'd love to lose weight without feeling hungry, welcome to Slimming World! Reach your weight loss dream - enjoying the food you love! Thousands of members can't be wrong!"
    path={Pages.UK_PUBLIC_BETTER_HEALTH}
    {...getRouteComponentProp(BetterHealth)}
  />
);

export default routes;
