import compose from 'redux/lib/compose';
import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';
import ApplicationError from '../ApplicationError';
import NotFound from './NotFound';

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(prepareComponent(ApplicationError)),
});

export default compose(addInitAction)(NotFound);
