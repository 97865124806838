import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import {
  registerFixedHeaderElement,
  unregisterFixedHeaderElement,
} from 'common/src/app/actions/scrollActions';
import { headerToggleFixed } from 'common/src/app/actions/components/headerActions';

import UkPublicNavigation from './UkPublicNavigation';

const mapDispatchToProps = dispatch => ({
  headerToggleFixed: (isFixed, height) => dispatch(headerToggleFixed(isFixed, height)),
  register: bottomY => dispatch(registerFixedHeaderElement('Header', bottomY)),
  unregister: () => dispatch(unregisterFixedHeaderElement('Header')),
});

const mapStateToProps = state => {
  const { isFixed = false, height = 0 } = state.view.components.header;
  const viewOptions = state.view.components.header?.toggleUiFeatures;

  return {
    isFixed,
    height,
    viewOptions,
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(UkPublicNavigation);
