/**
 * Navigation view options for the UK public nav
 */
export const NavViews = {
  HIDE_NAV_LINKS: 'hideNavigationLinks',
  HIDE_GROUP_SEARCH_BAR: 'hideGroupSearchBar',
  HIDE_CARD_REG: 'hideCardRegistration',
  HIDE_COUNTRY_DROPDOWN: 'hideCountryDropDown',
  HIDE_JOIN_CTA: 'hideJoinCta',
  HIDE_FIND_GROUP_CTA: 'hideFindGroupCta',
};

export const NavViewClassNames = {
  [NavViews.HIDE_NAV_LINKS]: 'hide-navigation-links',
  [NavViews.HIDE_GROUP_SEARCH_BAR]: 'hide-group-search-bar',
  [NavViews.HIDE_CARD_REG]: 'hide-card-registration',
  [NavViews.HIDE_COUNTRY_DROPDOWN]: 'hide-country-drop-down',
  [NavViews.HIDE_JOIN_CTA]: 'hide-join-cta',
  [NavViews.HIDE_FIND_GROUP_CTA]: 'hide-find-group-cta',
};

export const NavViewTemplates = {
  BE_CAMPAIGN: [
    NavViews.HIDE_NAV_LINKS,
    NavViews.HIDE_CARD_REG,
    NavViews.HIDE_GROUP_SEARCH_BAR,
    NavViews.HIDE_JOIN_CTA,
  ],
  BE_CAMPAIGN_DESKTOP: [
    NavViews.HIDE_NAV_LINKS,
    NavViews.HIDE_CARD_REG,
    NavViews.HIDE_FIND_GROUP_CTA,
  ],
};

export default NavViews;
