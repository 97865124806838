import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { openModal } from 'common/src/app/actions/components/modalActions';
import GroupSessionExplained from './GroupSessionExplained';

const connector = connect(null, {
  openModal,
});

export default compose(connector)(GroupSessionExplained);
