/* global 'molecule','EventButton' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Link from 'react-router/lib/Link';
import Icon from '../../atoms/Icon';
import TextNew, { Colors, TextTypes } from '../../atoms/TextNew';
import './event-button.scss';

const EventButtonContent = ({
  icon,
  label,
  iconSize,
  textColor,
  textType,
  rounded,
  screenreaderLabel,
}) => (
  <>
    {icon && <Icon width={iconSize} height={iconSize} name={icon} rounded={rounded} />}
    {screenreaderLabel && <div className="screen-reader">{screenreaderLabel}</div>}
    {label && (
      <TextNew color={textColor} type={textType}>
        {label}
      </TextNew>
    )}
  </>
);

EventButtonContent.propTypes = {
  /**
   * Button label
   */
  label: PropTypes.node,
  screenreaderLabel: PropTypes.node,
  rounded: PropTypes.bool,
  /**
   * Optional icon for the button
   */
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  textColor: PropTypes.oneOf([Colors.BRAND, Colors.PRIMARY_DARK, Colors.MID_TONE]),
  textType: PropTypes.oneOf(TextTypes.propTypes),
};

/**
 * The LikeButton and CommentButton button will be using this component only for styling wise
 *
 * The only difference are the action/api handlers in the individual components
 */
const EventButton = ({ onClick, to, id, ...props }, context, className) =>
  to ? (
    <Link to={to} onClick={onClick} className={className} id={id}>
      <EventButtonContent {...props} />
    </Link>
  ) : (
    <button onClick={onClick} className={className} id={id}>
      <EventButtonContent {...props} />
    </button>
  );

EventButton.defaultProps = {
  textType: TextTypes.FORMAL_LIGHT,
};

EventButton.propTypes = {
  to: PropTypes.string,
  id: PropTypes.string,
  /**
   * Button click handler
   */
  onClick: PropTypes.func,
};

export default withFunctionalClassName('molecule','EventButton')(EventButton);
