import stringify from 'qs/lib/stringify';

/** @module */

/**
 * Builds a path from a template and replaces the params
 *
 * @function createPath
 * @param path The configured path (e.g. /foo/:bar)
 * @param params The param values you want to replace it with (e.g. \{bar: 'baz'\})
 * @category routing
 */
export const createPath = (path, params = {}) =>
  path
    // first replace all params
    .replace(/:(\w+)/g, (match, param) => {
      if (typeof params[param] !== 'undefined') {
        return params[param] || '';
      }
      return match;
    })
    // remove parenthesis for resolved optional parts
    .replace(/\(([^:]+?)\)/g, (match, part) => part)

    // remove the other (unresolved) optional parts
    .replace(/\(.+?\)/g, () => '')

    // do we still have params left?
    .replace(/:(\w+)/g, (match, param) => {
      throw new Error(`Param "${param}" is missing in params ${params}, needed for '${path}'`);
    });

/**
 * Looks up a property defined in the deepest route (e.g. title, description, or requirements)
 *
 * @param {Array<Route>} routes Routes returned after route matching
 * @param {string} propName
 */
export const getPropOnDeepestRoute = (routes, propName) =>
  (routes.filter(route => typeof route[propName] !== 'undefined').pop() || {})[propName];

/**
 * Builds a path with a search string
 *
 * @param {string} path Path to add a search string
 * @param {Object} params Object contains keys and values to construct search
 */

// MJ-243 This will return 0 if its an empty array - so this will never hit the fail of the ternary
export const createSearch = (path, params = {}) =>
  path + (Object.keys(params).length ? `?${stringify(params)}` : '');
