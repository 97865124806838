import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { compose } from 'redux';
import Link from 'react-router/lib/Link';
import PropTypes from 'prop-types';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import InputType from 'common/src/app/data/enum/InputType';
import Wrapper from 'components/atoms/Wrapper';
import Row from 'components/atoms/Row';
import LocaleMessage from 'components/atoms/LocaleMessage';
import TextNew, { ElementTypes, AccentColors, Colors, FontWeight } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import HeightInput from 'components/organisms/HeightInputField';
import WeightInput from 'components/organisms/WeightInputField';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import GroupOnlineSignUp from 'components/molecules/GroupOnlineSignUp';
import Pages from 'common/src/app/data/enum/Pages';
import errorBoundary from 'components/hoc/ErrorBoundary';
import ManualSeo from 'components/atoms/ManualSeo';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import FaqBlock from 'components/molecules/FaqBlock';
import NewsletterSignUp from '../../molecules/NewsletterSignUp';
import './bmi-calculator.scss';

const componentName = 'bmi-calculator';

const BmiCalculator = (
  { handleSubmit, loading, pageData, bmiValue, headerImage },
  context,
  className,
) => {
  const bmiResultText = bmi => {
    if (bmi < 18.5) {
      return ['weightTooLow', [{ key: 'first' }, { key: 'second' }]];
    }
    if (bmi >= 18.5 && bmi < 21) {
      return ['healthyWeightBottomRange', [{ key: 'first' }, { key: 'second' }]];
    }
    if (bmi >= 21 && bmi < 23) {
      return [
        'healthyWeight',
        [{ key: 'first' }, { key: 'second', link: Pages.UK_PUBLIC_NEAREST_GROUP_LANDING }],
      ];
    }
    if (bmi >= 23 && bmi < 25) {
      return [
        'healthyWeightTopRange',
        [
          { key: 'first' },
          {
            key: 'second',
            link: Pages.UK_PUBLIC_NEAREST_GROUP_LANDING,
            link2: Pages.UK_PUBLIC_ONLINE_INFORMATION,
          },
        ],
      ];
    }
    if (bmi >= 25 && bmi < 30) {
      return [
        'atRiskLower',
        [
          { key: 'first' },
          {
            key: 'second',
            link: Pages.UK_PUBLIC_NEAREST_GROUP_LANDING,
            link2: Pages.UK_PUBLIC_ONLINE_INFORMATION,
          },
        ],
      ];
    }
    return [
      'atRiskHigher',
      [
        { key: 'first' },
        {
          key: 'second',
          link: Pages.UK_PUBLIC_NEAREST_GROUP_LANDING,
          link2: Pages.UK_PUBLIC_ONLINE_INFORMATION,
        },
      ],
    ];
  };

  const [bmiType, bmiLocales] = bmiResultText(bmiValue);

  return (
    <div className={className}>
      <HeroBarPublic image={headerImage}>
        <TextNew.Script.SM
          element={ElementTypes.H2}
          color={Colors.BRAND}
          localeId="bmiCalculator.heading.h2"
          cid="hero-bar-public-script-heading"
        />
      </HeroBarPublic>

      <form noValidate autoComplete="off" onSubmit={handleSubmit} id="bmi-calculator">
        <Wrapper width="lg" padding="sm">
          <Row className="left-right-container">
            <div className="explainer">
              <TextNew.Sans.SM
                cid="paragraph"
                weight={FontWeight.BOLD}
                localeId="bmiCalculator.paragraphOne"
              />
              <TextNew.PrimaryOriginal cid="paragraph" localeId="bmiCalculator.paragraphTwo" />
            </div>
            <div className="calculator">
              <div className="bmi-inputs">
                <div className="inner-padding">
                  <Row>
                    <TextNew.Script.SM
                      cid="bmi-form-title"
                      color={AccentColors.ACCENT_PURPLE}
                      element={ElementTypes.H1}
                      localeId="bmiCalculator.form.title"
                    />
                    <TextNew.Strong
                      cid="bmi-form-intro"
                      color={AccentColors.ACCENT_PURPLE}
                      localeId="bmiCalculator.form.intro"
                    />
                  </Row>

                  <div className="height-fields">
                    <HeightInput
                      customLabel={
                        <TextNew.Details
                          color={AccentColors.ACCENT_PURPLE}
                          localeId="bmiCalculator.groupLabels.height"
                        />
                      }
                      viewSettings={{
                        measurementUnit: true,
                        fieldLabel: true,
                        informationToggle: false,
                      }}
                    />
                  </div>

                  <div className="weight-fields">
                    <WeightInput
                      customLabel={
                        <TextNew.Details
                          color={AccentColors.ACCENT_PURPLE}
                          localeId="bmiCalculator.groupLabels.weight"
                        />
                      }
                      viewSettings={{
                        unitToggle: true,
                        displayInfoButton: false,
                        fieldLabel: true,
                      }}
                    />
                  </div>

                  <Row>
                    <Button dataTestid={`${componentName}-submit-button`} type={InputType.SUBMIT}>
                      <LocaleMessage id="bmiCalculator.form.button" />
                    </Button>
                  </Row>
                </div>

                {bmiValue && (
                  <div className="result" data-testid={`${componentName}-result`}>
                    <div className="inner-padding">
                      <TextNew.Sans.XL
                        color={AccentColors.ACCENT_PINK}
                        localeId="bmiCalculator.result.title"
                        localeParams={{ BMI: bmiValue }}
                      />
                      {bmiLocales.map(locale => (
                        <TextNew.Sans.LG
                          key={`${bmiType}-${locale.key}`}
                          color={AccentColors.ACCENT_PURPLE}
                          localeId={`bmiCalculator.result.paragraph.${bmiType}.locale.${locale.key}`}
                          localeParams={{
                            LINK: locale.link && (
                              <Link to={locale.link}>
                                <LocaleMessage
                                  id={`bmiCalculator.result.paragraph.${bmiType}.links.linkText`}
                                />
                              </Link>
                            ),
                            LINK2: locale.link2 && (
                              <Link to={locale.link2}>
                                <LocaleMessage
                                  id={`bmiCalculator.result.paragraph.${bmiType}.links.link2Text`}
                                />
                              </Link>
                            ),
                          }}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Row>
        </Wrapper>
      </form>

      {!loading &&
        // eslint-disable-next-line react/prop-types
        pageData?.nestedContent.map(({ _type, ...content }) => {
          switch (_type) {
            case types.FAQ_BLOCK:
              return <FaqBlock {...content} showAnswerTitle={false} />;
            default:
              // nothing or component not compatible
              return null;
          }
        })}

      <GroupOnlineSignUp />
      <NewsletterSignUp />

      <ManualSeo metaKeywords="BMI calculator,BMI calculator UK,BMI check,bmi weight loss calculator,check your bmi" />
    </div>
  );
};

BmiCalculator.propTypes = {
  loading: PropTypes.bool,
  pageData: PropTypes.object,
  handleSubmit: PropTypes.func,
  bmiValue: PropTypes.number,
  headerImage: PropTypes.shape({
    ...imageShape,
  }),
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.PAGE, 'BmiCalculator'),
)(BmiCalculator);
