/* global 'atom','ImageCarouselWithTextBlock' */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import breakpoints from 'common/src/app/data/enum/Breakpoints';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import trackingShape from 'common/src/app/util/proptypes/apiEntities/tracking';
import BackgroundImageWrapper from '../../../../../atoms/BackgroundImageWrapper';
import TextNew from '../../../../../atoms/TextNew';
import Carousel from '../../../../../organisms/Carousel';
import Wrapper from '../../../../../atoms/Wrapper';
import RichTextBlock from '../RichTextBlock';
import TextBlockTriplet from '../TextBlockTriplet';
import ResponsiveImage from '../../../../../atoms/ResponsiveImage';
import { types } from '../../../enum/BlockTypes';
import './image-carousel-with-text-block.scss';

const IS_ITEM_LABEL = 'is-item-label';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  adaptiveHeight: true,
};

const getNavSettings = () => ({
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 1,
  arrows: true,
  draggable: false,
  centerMode: false,
  carousel: true,
  responsive: [
    {
      breakpoint: breakpoints.SM,
      settings: {
        slidesToShow: 1,
        carousel: true,
      },
    },
    {
      breakpoint: breakpoints.MD,
      settings: {
        slidesToShow: 2,
        carousel: true,
      },
    },
    {
      breakpoint: breakpoints.LG,
      settings: {
        slidesToShow: 4,
        carousel: true,
      },
    },
    {
      breakpoint: breakpoints.XL,
      settings: {
        slidesToShow: 6,
        carousel: true,
      },
    },
    {
      breakpoint: breakpoints.XXL,
      settings: {
        slidesToShow: 7,
        carousel: true,
      },
    },
  ],
});

/**
 * Large carousel that can contain categorized items
 */
class ImageCarouselWithTextBlock extends Component {
  state = {
    currentSlide: 1,
  };

  onHandleCurrentSlide = currentSlide => {
    if (this.props.trackingData) {
      this.props.commonTrackEvent(null, {
        category: this.props.trackingData.category,
        [this.props.trackingData.action]: currentSlide,
        contentView: this.props.trackingData.category,
      });
    }

    this.setState({ currentSlide });
  };

  render() {
    const { caption, content, deviceState } = this.props;

    // Strip out the label items (carousel nav items with combined image and labels)
    const mainItems = content.filter(
      item =>
        // eslint-disable-next-line no-underscore-dangle
        item._type === types.IMAGE_WITH_CAPTION_AND_BACKGROUND_BLOCK,
    );

    return (
      <div {...componentClassNameProp(ComponentType.ATOM, this)}>
        {caption ? <RichTextBlock text={caption} /> : null}
        <Carousel
          ref={c => (this.carousel = c)}
          onHandleCurrentSlide={this.onHandleCurrentSlide}
          settings={settings}
          navSettings={getNavSettings(content)}
          navItems={content.map((item, index) => {
            /* eslint-disable no-underscore-dangle */
            const isItemLabel = item._type === types.TEXT_BLOCK_TRIPLET;
            return (
              <div
                className={classNames('nav-slide-content', {
                  [IS_ITEM_LABEL]: isItemLabel,
                })}
                itemType={isItemLabel ? IS_ITEM_LABEL : ''}
                itemID={index}
                key={`nav-slide-content-${index}`}
              >
                {item._type === types.IMAGE_WITH_CAPTION_AND_BACKGROUND_BLOCK && item.image ? (
                  <ResponsiveImage
                    src={item.image.src}
                    alt={item.image.alt}
                    breakpoints={{
                      XS: { px: 182, ratio: 1 },
                      SM: { px: 392, ratio: 1 },
                      MD: { px: 292, ratio: 1 },
                      other: { px: 200, ratio: 1 },
                    }}
                  />
                ) : (
                  <div className="text-container">
                    <TextBlockTriplet content={[item?.text1, item?.text2, item?.text3]} />
                  </div>
                )}
              </div>
            );
            /* eslint-enable no-underscore-dangle */
          })}
          navigationOnTop
        >
          {mainItems.map(item => (
            <BackgroundImageWrapper
              key={item.title}
              image={deviceState > DeviceState.MD && item.image ? item.image : ''}
            >
              {deviceState <= DeviceState.MD && item.image ? (
                <ResponsiveImage src={item.image.src} alt={item.image.alt} ratio={100 / 64} />
              ) : null}
              <Wrapper size="lg" padding="sm">
                <div className="copy-card">
                  <TextNew.Confident>{item.title}</TextNew.Confident>
                  {item.caption ? <RichTextBlock text={item.caption} /> : null}
                </div>
              </Wrapper>
            </BackgroundImageWrapper>
          ))}
        </Carousel>
      </div>
    );
  }
}

ImageCarouselWithTextBlock.propTypes = {
  /**
   * The caption
   */
  caption: PropTypes.string,
  /**
   * Array of items to display
   */
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      caption: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  /**
   * Device state
   */
  commonTrackEvent: PropTypes.func.isRequired,
  deviceState: PropTypes.number,
  trackingData: PropTypes.shape(trackingShape),
};

export default withDeviceState()(ImageCarouselWithTextBlock);
