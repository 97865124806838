import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ArticleType from 'common/src/app/data/enum/ArticleType';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import ImageCarouselBlock from 'components/molecules/BlockContent/components/atoms/ImageCarouselBlock';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import MasterTile from 'components/organisms/MasterTile';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Button from 'components/atoms/Button';
import IcelandProductCharacteristics from '../../molecules/IcelandProductCharacteristics';
import IcelandProductBanner from '../../atoms/IcelandProductBanner';
import './iceland-product-tile.scss';

const IcelandProductTile = (
  {
    item: {
      title: itemTitle,
      images,
      description: descriptionRename,
      associatedRecipeSlug,
      id,
      chilliRating,
      characteristics,
      foodWarehouse,
      flashMessage,
    },
  },
  context,
  className,
) => (
  <div
    className={classNames(className, {
      'has-no-images': images?.length < 1,
      'has-no-recipe-link': !associatedRecipeSlug,
    })}
    data-testid="iceland-product-tile"
  >
    <MasterTile
      id={id}
      _type={ArticleType.FOOD_RANGE_PRODUCT}
      customDescription={
        <div className="card-content">
          <header className="card-header">
            <TextNew.Serif.SM element={ElementTypes.H3} cid="title">
              {itemTitle}
            </TextNew.Serif.SM>

            <IcelandProductCharacteristics
              chilliRating={chilliRating}
              characteristics={characteristics}
            />
          </header>

          {images?.length > 0 && (
            <div className="card-images">
              <ImageCarouselBlock hasDots hasOneSlide hasSquareImage imageCarousel={images} />

              {(foodWarehouse || flashMessage?.text) && (
                <IcelandProductBanner flashMessage={flashMessage} isFoodWarehouse={foodWarehouse} />
              )}
            </div>
          )}

          <TextNew.PrimaryElegant cid="description" element={ElementTypes.DIV}>
            <RichTextBlock text={descriptionRename} />
          </TextNew.PrimaryElegant>

          <div className="card-buttons">
            {associatedRecipeSlug && (
              <Button to={associatedRecipeSlug}>
                <LocaleMessage id="icelandOurRange.cta.recipeButtonText" />
              </Button>
            )}
          </div>
        </div>
      }
    />
  </div>
);

IcelandProductTile.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.shape(imageShape),
    images: PropTypes.arrayOf(PropTypes.shape(imageShape)),
    associatedRecipeSlug: PropTypes.string,
    chilliRating: PropTypes.number,
    characteristics: PropTypes.number,
    description: PropTypes.string,
    foodWareHouse: PropTypes.bool,
    flashMessage: PropTypes.shape({
      text: PropTypes.string,
      accentColor: PropTypes.string,
    }),
  }),
};

export default withFunctionalClassName(
  ComponentType.ORGANISM,
  'IcelandProductTile',
)(IcelandProductTile);
