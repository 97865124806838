import { connect } from 'react-redux';
import { navigateBackLink } from 'common/src/app/actions/components/backLinkActions';
import { hasValidBackRoute } from 'common/src/app/reducers/routeHistoryReducer';
import { push as historyPush } from 'react-router-redux';
import BackLink from './BackLink';

export default connect(
  (state, { useBrowserBack, backlink }) => ({
    hasValidBackRoute: !!(useBrowserBack || backlink || hasValidBackRoute(state.routeHistory)),
  }),
  (dispatch, { backlink }) => ({
    // When a backlink is passed, we navigate to that page. If not, we use the routeHistory.
    navigateBackLink: () => dispatch(backlink ? historyPush(backlink) : navigateBackLink()),
  }),
)(BackLink);
