import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import VideoPropTypes from 'common/src/app/util/proptypes/apiEntities/video';
import Video from '../../molecules/Video';

import './video-background.scss';

const VideoBackground = (
  { video, videoType, brightcoveAccountId, brightcovePlayerId, trackVideoEvent },
  context,
  className,
) => (
  <section className={className}>
    <Video
      video={video}
      videoType={videoType}
      brightcoveAccountId={brightcoveAccountId}
      brightcovePlayerId={brightcovePlayerId}
      trackVideoEvent={trackVideoEvent}
      hasCustomControls
    />
  </section>
);

VideoBackground.propTypes = {
  ...VideoPropTypes,
  brightcoveAccountId: PropTypes.string,
  brightcovePlayerId: PropTypes.string,
  /*
   * Action to expose video information to the data layer
   */
  trackVideoEvent: PropTypes.func,
};

export default withFunctionalClassName(ComponentType.ORGANISM, 'VideoBackground')(VideoBackground);
