import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from 'components/atoms/Wrapper';
import Row from 'components/atoms/Row';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { AccentColors, ElementTypes } from 'components/atoms/TextNew';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';

import './pillars.scss';

const Pillars = ({ title, steps }) => (
  <div className="intro-block" data-testid="intro-block">
    <Wrapper padding="lg" width="lg">
      <TextNew.Script.LG
        color={AccentColors.ACCENT_PURPLE}
        cid="pillarTitle"
        element={ElementTypes.H2}
      >
        {title}
      </TextNew.Script.LG>
      <Row className="pillar" alignstart spacearound>
        {steps?.map(step => (
          <Row key={`${step?.icon?.src}-pillar`} column justifycenter>
            <img alt={step?.icon?.alt} src={step?.icon?.src} height={60} data-testid="stepicon" />
            <TextNew.Strong color={AccentColors.ACCENT_PURPLE} element={ElementTypes.H3}>
              {step?.title}
            </TextNew.Strong>
            <RichTextBlock text={step?.description} cid="pillar-intro" />
          </Row>
        ))}
      </Row>
    </Wrapper>
  </div>
);

Pillars.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'Pillars')(Pillars);
