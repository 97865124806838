var moduleInstance = require("../../../../../node_modules/babel-loader/lib/index.js??ruleSet[1].rules[4].use[0]!./index.js");
var moduleId = require.resolve("../../../../../node_modules/babel-loader/lib/index.js??ruleSet[1].rules[4].use[0]!./index.js");
module.exports = function(cb) {
  cb(moduleInstance.default);
  if(module.hot) {
    module.hot.accept([moduleId], function() {
      console.log('Hot updating "./index.js"');
      moduleInstance = require("../../../../../node_modules/babel-loader/lib/index.js??ruleSet[1].rules[4].use[0]!./index.js");
      cb(moduleInstance.default);
    });
  }
}