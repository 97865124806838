import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Size from 'common/src/app/data/enum/Size';
import Wrapper from 'components/atoms/Wrapper';
import Loader from 'components/atoms/Loader';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import QuotationBlock from 'components/molecules/BlockContent/components/atoms/QuotationBlock';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import CallOutBlock from '../../atoms/CallOutBlock';
import PageTitleHeader from '../../molecules/PageTitleHeader';
import ConsultantCtaBlock from '../../organisms/ConsultantCtaBlock';
import createScriptTitle from '../../../../../../utils/createScriptTitle';
import consultantPageNames from '../../../../../../data/enum/consultantPageNames';
import './our-franchise-package.scss';

const OurFranchisePackage = (
  { loading, pageData, pageBackgroundImage, deviceState },
  context,
  className,
) => (
  <div className={className}>
    {deviceState >= DeviceState.LG && pageBackgroundImage?.src ? (
      <span
        className="fixed-background-wrapper"
        style={{ backgroundImage: `url(${pageBackgroundImage.src}` }}
      />
    ) : null}

    <PageTitleHeader hasSwoosh localePrefix={consultantPageNames.OUR_FRANCHISE_PACKAGE} />

    {loading && <Loader />}

    <Wrapper cid="content-wrapper" padding={Size.XL}>
      {!loading &&
        // eslint-disable-next-line react/prop-types
        pageData?.nestedContent.map(({ _type, ...content }, index) => {
          switch (_type) {
            case types.QUOTATION_WITH_AUTHOR_PHOTO_BLOCK:
            case types.QUOTATION_BLOCK:
              return (
                <QuotationBlock
                  {...content}
                  quotation={createScriptTitle(content.quotation)}
                  isConsultant
                  key={`our-franchise-block-${index}`}
                />
              );
            case types.RICH_TEXT_BLOCK:
              return (
                <RichTextBlock
                  cid="text-block"
                  title={createScriptTitle(content.title)}
                  text={createScriptTitle(content.text)}
                  key={`our-franchise-block-${index}`}
                />
              );
            default:
              // nothing or component not compatible
              return null;
          }
        })}
    </Wrapper>
    {deviceState >= DeviceState.LG ? <CallOutBlock showBackground={false} /> : null}
    <ConsultantCtaBlock localePrefix={consultantPageNames.OUR_FRANCHISE_PACKAGE} />
  </div>
);

OurFranchisePackage.propTypes = {
  loading: PropTypes.bool.isRequired,
  pageData: PropTypes.object,
  pageBackgroundImage: PropTypes.object,
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.TEMPLATE, 'OurFranchisePackage')(OurFranchisePackage),
);
