import { withInitAction } from '@mediamonks/react-redux-component-init';
import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { SearchType, getSearchResults } from 'common/src/app/actions/resources/searchActions';
import { landingGridSelector } from 'common/src/app/selectors/publicSelectors';

import RealLifeStoryNotFound from './RealLifeStoryNotFound';

const mapStateToProps = state => landingGridSelector(state);

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  clientOnly: ({}, dispatch) =>
    dispatch(
      getSearchResults(SearchType.PUBLIC_SUCCESS_STORIES, {
        limit: 4,
      }),
    ),
});

export default compose(addInitAction, connected)(RealLifeStoryNotFound);
