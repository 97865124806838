/* global 'atom','ModalRoot' */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import { modalPropsMap } from 'common/src/app/actions/components/modalActions';
import IconName from 'common/src/app/data/enum/IconName';
import Wrapper from '../Wrapper';
import Icon from '../Icon';
import CloseButton from '../../molecules/CloseButton';
import ComponentSwitcher from '../ComponentSwitcher';
import './modal-root.scss';

/**
 * Component that will display a modal window over the application once enabled.
 *
 * This component can be placed anywhere in the tree, but should ideally be placed as high up as
 * possible to work better with normal z-ordering.
 *
 * The component will not be visible until the `openModal()` action creator has been called.
 * The `closeModal()` action creator closes the modal window again.
 */

class ModalRoot extends Component {
  /**
   * Renders the default wrapper
   */
  renderDefault(mappedModalProps) {
    const { componentMap, modalComponent, closeModal, staticModal } = this.props;
    return (
      staticModal || (
        <ComponentSwitcher
          {...mappedModalProps}
          closeModal={closeModal}
          showLoader
          map={componentMap}
          switchValue={modalComponent}
        />
      )
    );
  }

  /**
   * Renders the full bleed wrapper with a custom close button
   *
   * todo:
   * The CloseButton has been disabled for now.
   *
   * Why:
   * The WeighIn modal is created through the QueryRouting and there is no good way yet to pass a
   * optional close button label.
   *
   * Solution for now:
   * Add the CloseButton in the modals separately. This has been done in the GalleryModal and the
   * Weigh-in
   */
  renderFullBleed(mappedModalProps) {
    const { showCloseButton, closeModal } = this.props;
    return (
      <div className="full-bleed-wrapper">
        {showCloseButton ? <CloseButton onClick={closeModal} /> : null}
        {this.renderDefault(mappedModalProps)}
      </div>
    );
  }

  render() {
    const {
      modalProps,
      modalComponent,
      closeModal,
      showCloseButton,
      enableCloseOnBackdrop,
      isFullBleed,
      alignLeft,
      alignRight,
      staticModal,
    } = this.props;

    if (!modalComponent && !staticModal) {
      return <noscript />;
    }

    let mappedModalProps;
    if (!staticModal) {
      if (modalProps && modalProps.modalCountId && modalPropsMap[modalProps.modalCountId]) {
        mappedModalProps = modalPropsMap[modalProps.modalCountId];
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          '[ModalRoot] You are probably using the modalRoot without the openModal action, so we cannot use the modalPropsMap, so we are passing the props directly!',
        );
        mappedModalProps = modalProps;
      }
    }

    // Default props for wrapper
    const wrapperProps = {
      width: isFullBleed ? null : 'ls',
      padding: isFullBleed ? null : 'xxl',
      background: isFullBleed ? 'transparent' : 'primary-light',
    };

    // Custom class names for the modal
    const classNameProp = {
      isFullBleed,
      alignLeft,
      alignRight,
    };

    return (
      <div {...componentClassNameProp(ComponentType.ATOM, this, classNameProp)}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */}
        <div className="backdrop" onClick={enableCloseOnBackdrop ? closeModal : null} />
        <Wrapper {...wrapperProps}>
          {!isFullBleed && showCloseButton ? (
            <button className="modal-close-button" onClick={closeModal}>
              <div className="screen-reader">Close</div>
              <Icon name={IconName.CROSS} />
            </button>
          ) : null}
          <div className="modal-content">
            {isFullBleed
              ? this.renderFullBleed(mappedModalProps)
              : this.renderDefault(mappedModalProps)}
          </div>
        </Wrapper>
      </div>
    );
  }
}

ModalRoot.propTypes = {
  /**
   * Map of possible modal components that can be rendered inside of this `ModalRoot`. Please
   * refer to the `ComponentSwitcher` `map` prop for more details about the behaviour.
   */
  componentMap: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  /**
   * If set, will not render a component using ComponentSwitcher but render the contents
   * of this prop instead. Passed by `<StaticModalRoot />`
   */
  staticModal: PropTypes.node,
  /**
   * The name of the component that should currently show on the modal.
   * Passed by the `connect()` wrapper.
   */
  modalComponent: PropTypes.string,
  /**
   * These props are passed when calling the `openModal()` action and will
   * be set as props on `modalComponent`
   */
  modalProps: PropTypes.objectOf(PropTypes.any),
  /**
   * action creator to close the modal. Passed by the `connect()` wrapper
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * If true, shows the close button. Passed by the `connect()` wrapper
   */
  showCloseButton: PropTypes.bool,
  /**
   * If true, clicking the backdrop closes the overlay. Passed by the `connect()` wrapper
   */
  enableCloseOnBackdrop: PropTypes.bool,
  /**
   * By default the modal will be shown in the middle
   */
  isFullBleed: PropTypes.bool,
  /**
   * If set in combination with the isFullBleed option the content will be aligned to the left
   */
  alignLeft: PropTypes.bool,
  /**
   * If set in combination with the isFullBleed option the content will be aligned to the right
   */
  alignRight: PropTypes.bool,
};

export default ModalRoot;
