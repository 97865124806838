import React from 'react';
import PropTypes from 'prop-types';
import LocaleMessage from '../../../../../../LocaleMessage';

const WeightInputLabel = ({ weightUnit, abbreviate }) => {
  const id = `general.measureUnit.weight.${weightUnit}.${
    abbreviate ? 'labelAbbreviation' : 'label'
  }`;
  return <LocaleMessage id={id} />;
};

WeightInputLabel.propTypes = {
  weightUnit: PropTypes.string,
  abbreviate: PropTypes.bool,
};

export default WeightInputLabel;
