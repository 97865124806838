/* global 'atom','GroupSearchCounty' */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';

class GroupSearchCounty extends Component {
  componentDidMount() {
    const { countyOptions, getCounties } = this.props;
    getCounties && !countyOptions && getCounties();
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { countyOptions, seoObject, ...groupSearchProps } = this.props;

    const countyOptionsOrEmpty =
      countyOptions && countyOptions.length > 0
        ? countyOptions.map(county => ({
            title: county,
            value: county,
          }))
        : [];

    return (
      <Fragment>
        <Select options={countyOptionsOrEmpty} {...groupSearchProps} />
      </Fragment>
    );
  }
}
GroupSearchCounty.propTypes = {
  countyOptions: PropTypes.arrayOf(PropTypes.string),
  getCounties: PropTypes.func,
  seoObject: PropTypes.object,
};

export default GroupSearchCounty;
