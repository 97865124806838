import React, { PureComponent } from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import classNames from 'classnames';
import createId from 'common/src/app/util/createId';
import Configuration from 'common/src/app/config/Configuration';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import CustomScrollBar from '../../../CustomScrollBar';
import Button from '../../../../atoms/Button';
import Icon from '../../../../atoms/Icon';
import TextNew, { ElementTypes } from '../../../../atoms/TextNew';

import './link-tabs.scss';

/**
 * Link tabs are components that visaully look like tabs but are actually just
 * links this different changes the mark up
 */
class LinkTabs extends PureComponent {
  constructor(props) {
    super(props);
    this.active = React.createRef();
  }

  state = {
    isOverflowing: false,
    currentActive: null,
  };

  componentDidMount() {
    if (this.active?.current !== null) {
      this.setState({ currentActive: this.active.current });
    }
  }

  onUpdate = event => {
    this.setState({
      isOverflowing:
        event.scrollWidth > event.clientWidth || event.scrollHeight > event.clientHeight,
    });

    const { currentActive, isOverflowing } = this.state;

    if (this.active && currentActive !== this.active && isOverflowing) {
      this.scrollRef.scrollLeft(this.active.offsetLeft);
      this.setState({
        currentActive: this.active,
      });
    }
  };

  render() {
    const {
      ariaLabel,
      reference,
      tabs,
      activeTab,
      onClick,
      topDistance,
      hidden,
      deviceState,
      queryString,
      textStyles,
    } = this.props;

    const { isOverflowing } = this.state;
    const arrayTabs = (tabs && Object.keys(tabs)) || [];
    const isMenuFixed = deviceState > DeviceState.XXXL && Configuration.menuFixed;

    const query = queryString || '';

    return (
      <div
        {...componentClassNameProp(ComponentType.MOLECULE, this, {
          isOverflowing,
          hidden,
          isMenuFixed,
        })}
        ref={reference}
        style={topDistance ? { top: `${topDistance}px` } : null}
      >
        <div className="scroll-wrapper">
          <CustomScrollBar updateHandler={this.onUpdate} reference={el => (this.scrollRef = el)}>
            <nav aria-label={ariaLabel}>
              <ul className="link-tabs-list " data-testid={`link-tab-list`}>
                {arrayTabs.length > 0 &&
                  arrayTabs.map(tab => (
                    <li
                      data-testid={`link-tabs-${createId(tab)}`}
                      id={`link-tabs-${createId(tab)}`}
                      className={classNames('link-tabs-list-item', {
                        'is-active': createId(tab) === activeTab,
                      })}
                      key={tab}
                      ref={createId(tab) === activeTab ? this.active : null}
                    >
                      <Button
                        aria-current={
                          tabs[tab].tabLink && createId(tab) === activeTab ? 'page' : null
                        }
                        isTertiary
                        to={tabs[tab].tabLink && `${tabs[tab].tabLink}${query}`}
                        onClick={() =>
                          !tabs[tab].tabLink && onClick ? onClick(createId(tab)) : null
                        }
                        tabIndex="-1"
                      >
                        {tabs[tab].icon && (
                          <Icon
                            dataTestid={`tab-bar-${createId(tab)}-icon`}
                            name={tabs[tab].icon.replace('icon-', '')}
                          />
                        )}
                        <TextNew {...textStyles} element={ElementTypes.SPAN}>
                          {tab}
                        </TextNew>
                      </Button>
                      {tabs[tab].buttonLink && tabs[tab].buttonLabel && (
                        <Button
                          cid="download-button"
                          href={`${tabs[tab].buttonLink}`}
                          target="_blank"
                          isTertiary
                          tabIndex="-1"
                        >
                          <Icon name="download" rounded width={24} height={24} />
                          <TextNew element={ElementTypes.SPAN}>{tabs[tab].buttonLabel}</TextNew>
                        </Button>
                      )}
                    </li>
                  ))}
              </ul>
            </nav>
          </CustomScrollBar>
        </div>
      </div>
    );
  }
}

LinkTabs.defaultProps = {
  tabType: 'links',
};

LinkTabs.propTypes = {
  reference: PropTypes.func,
  /**
   * Array of tabs
   */
  tabs: PropTypes.objectOf(
    PropTypes.shape({
      icon: PropTypes.string,
      tabLink: PropTypes.string,
      buttonLink: PropTypes.string,
      buttonLabel: PropTypes.string,
    }),
  ),
  /**
   * The current reference that needs to be active
   */
  activeTab: PropTypes.any,
  /**
   * Callback function to handle tab click
   */
  onClick: PropTypes.func,
  /**
   * fixed height from top
   */
  topDistance: PropTypes.number,
  /**
   * Hide
   */
  hidden: PropTypes.bool,
  deviceState: PropTypes.number,
  queryString: PropTypes.string,
  textStyles: PropTypes.object,
  ariaLabel: PropTypes.string,
};

export default withDeviceState()(LinkTabs);
