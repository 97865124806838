import handleActions from 'redux-actions/lib/handleActions';
import { STORE_LAT_LNG, STORE_LAT_LNG_ERROR } from '../actions/resources/geoLocationActions';

const initialState = {
  lat: null,
  lng: null,
  error: null,
};

const geoLocation = handleActions(
  {
    [STORE_LAT_LNG]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [STORE_LAT_LNG_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload.error,
    }),
  },
  initialState,
);

export default geoLocation;
