import Pages from './Pages';

const ExcludeAlternateLink = [
  Pages.UK_PUBLIC_NEAREST_GROUP_LANDING,
  Pages.UK_PUBLIC_NEAREST_GROUP_DETAIL,
  Pages.UK_PUBLIC_NEAREST_CONSULTANT_LANDING,
  Pages.UK_PUBLIC_NEAREST_GROUP_COUNTIES,
  Pages.UK_PUBLIC_CYPRUS_GROUP_SEARCH,
];

export default ExcludeAlternateLink;
