/* global 'template','NearestGroupsInATown' */
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { AccentColors, ElementTypes } from 'components/atoms/TextNew';
import Wrapper from 'components/atoms/Wrapper';
import Loader from 'components/atoms/Loader';
import errorBoundary from 'components/hoc/ErrorBoundary';
import EmptySearchResults from '../NearestGroupLanding/components/organisms/EmptySearchResults';
import GroupDirectory from '../../organisms/GroupDirectory';
import getGroupsInCountyAndTown from '../../../Utils/getGroupsInCountyAndTown';

import './nearest-group-towns.scss';

const NearestGroupsInATown = (
  { isInitializing, groups, params: { town, county } },
  context,
  className,
) => {
  if (isInitializing) {
    return <Loader />;
  }
  const groupsInCountyAndTown = getGroupsInCountyAndTown(groups, town, county);
  return (
    <div className={className}>
      <Wrapper.XL cid="header" padding="xs">
        <h1>
          <TextNew.Script.SM
            localeId="groupsInATown.groups.explainer"
            localeParams={{
              TOWN: startCase(town),
              COUNTY: county && county !== town ? `, ${startCase(county)}` : '',
            }}
            color={AccentColors.ACCENT_PINK}
            element={ElementTypes.SPAN}
          />
        </h1>
      </Wrapper.XL>

      {groupsInCountyAndTown?.length > 0 ? (
        <GroupDirectory groups={groupsInCountyAndTown} />
      ) : (
        <EmptySearchResults />
      )}
    </div>
  );
};

NearestGroupsInATown.contextTypes = {
  getMessage: PropTypes.func,
};

NearestGroupsInATown.defaultProps = {
  counties: [],
  towns: [],
};

NearestGroupsInATown.propTypes = {
  groups: PropTypes.array,
  params: PropTypes.object,
  isInitializing: PropTypes.bool,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName('template','NearestGroupsInATown'),
)(NearestGroupsInATown);
