import React from 'react';
import PropTypes from 'prop-types';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { FontWeight, AccentColors } from 'components/atoms/TextNew';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import './our-story-lead-quote.scss';

const OurStoryLeadQuote = ({ authorPhoto, author, quotation }, context, className) => (
  <div className={className}>
    {authorPhoto && <ResponsiveImage px={600} {...authorPhoto} />}
    <div className="quote-contents">
      <RichTextBlock text={quotation} />
      {author && (
        <TextNew.Serif.SM cid="person" weight={FontWeight.BOLD} color={AccentColors.ACCENT_PURPLE}>
          {author}
        </TextNew.Serif.SM>
      )}
    </div>
  </div>
);

OurStoryLeadQuote.propTypes = {
  author: PropTypes.string,
  authorPhoto: PropTypes.shape(imageShape),
  quotation: PropTypes.string.isRequired,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'OurStoryLeadQuote')(OurStoryLeadQuote),
);
