import React from 'react';
import PropTypes from 'prop-types';
import { GROUP_REGISTRATION_FIRST_STEP } from 'common/src/app/data/enum/Pages';
import WebHost from 'common/src/app/data/enum/WebHost';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ResponsiveImage from '../../../../../atoms/ResponsiveImage';
import TextNew, { Colors, ElementTypes } from '../../../../../atoms/TextNew';
import WebHostLink from '../../../../../atoms/WebHostLink';

import CountrySwitch from '../CountrySwitch';
import './card-registration.scss';

const CardRegistration = ({ cardImage }, { getMessage }, className) => (
  <div className={className}>
    <div className="register-card">
      <WebHostLink webHost={WebHost.ACCOUNT} link={GROUP_REGISTRATION_FIRST_STEP}>
        {cardImage && (
          <ResponsiveImage cid="group-card" src={cardImage.src} alt={cardImage.alt} px={48} />
        )}
        <TextNew.FormalLight
          element={ElementTypes.SPAN}
          color={Colors.DARK_TONE}
          localeId="ukPublicNavigationLinks.groupCard"
        />
      </WebHostLink>
    </div>
    <CountrySwitch getMessage={getMessage} />
  </div>
);

CardRegistration.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

CardRegistration.propTypes = {
  cardImage: PropTypes.shape(imageShape),
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'CardRegistration',
)(CardRegistration);
