import debugLib from 'debug';
import { GATEWAY_CONTENT, GATEWAY_CONTENT_AUTH } from '../../data/Injectables';
import apiGetEntity from './apiActions/apiGetEntity';
import { COMPONENT } from '../../data/entityTypes';

const debug = debugLib('SlimmingWorld:componentActions');

export const GET_COMPONENT = 'componentActions/GET_COMPONENT';

/**
 * Gets a single component
 * @param slug {string} The slug that identifies this component
 */
export const getComponent = (slug, authenticated = false) => dispatch =>
  dispatch(
    apiGetEntity(
      GET_COMPONENT,
      authenticated ? GATEWAY_CONTENT_AUTH : GATEWAY_CONTENT,
      `/components/${slug}`,
      COMPONENT,
      slug,
    ),
  ).catch(error => debug(`Error during request for /component/${slug}: ${error}`));
