import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import Icon from 'components/atoms/Icon';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { FaqSeo, schemaUrl } from 'common/src/app/data/enum/SeoSchema';
import './faq-block-item.scss';

const FaqBlockItem = ({ question, answers, showAnswerTitle }, context, className) => {
  const [isCollapsed, setCollapsed] = useState(true);

  const handleQuestionClick = () => setCollapsed(!isCollapsed);

  const chevron = <Icon name={isCollapsed ? IconName.PLUS : IconName.MINUS} width={18} />;

  return (
    <div
      className={classNames(className, { 'is-collapsed': isCollapsed })}
      itemScope
      itemProp={FaqSeo.ITEM_PROP.MAIN_ENTITY}
      itemType={`${schemaUrl}${FaqSeo.SCHEMA.QUESTION}`}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */}
      <h2>
        <button
          className="question-title"
          onClick={handleQuestionClick}
          itemProp={FaqSeo.ITEM_PROP.NAME}
          aria-expanded={!isCollapsed}
        >
          {question} {chevron}
        </button>
      </h2>
      <div
        className="answer-text"
        itemScope
        itemProp={FaqSeo.ITEM_PROP.ACCEPTED_ANSWER}
        itemType={`${schemaUrl}${FaqSeo.SCHEMA.ANSWER}`}
      >
        <div itemProp={FaqSeo.ITEM_PROP.TEXT}>
          {answers.map(({ title, answer }, index) => (
            <RichTextBlock
              title={showAnswerTitle ? title : undefined}
              text={answer}
              key={`faq-block-item-${index}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

FaqBlockItem.propTypes = {
  question: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  showAnswerTitle: PropTypes.bool,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'FaqBlockItem')(FaqBlockItem),
);
