import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ElementTypes from 'common/src/app/data/enum/ElementTypes';
import TextNew from 'components/atoms/TextNew';

import './iceland-our-pages-header.scss';

const IcelandOurPagesHeader = ({ title, description }, context, className) => (
  <header className={className}>
    <div className="page-header">
      {title && (
        <TextNew.Confident cid="page-title" element={ElementTypes.H1}>
          {title}
        </TextNew.Confident>
      )}

      {description && (
        <TextNew.PrimaryElegant cid="page-description">{description}</TextNew.PrimaryElegant>
      )}
    </div>
  </header>
);

IcelandOurPagesHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'IcelandOurPagesHeader',
)(IcelandOurPagesHeader);
