import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import GridSizeFilter from '../../molecules/GridSizeFilter';
import PaginationLabel from '../../molecules/PaginationLabel';

import './grid-control-bar.scss';

const GridControlBar = (
  { gridColumnCount, loadAll, resultCount, total, showLoadAll },
  context,
  className,
) => (
  <div className={className}>
    <GridSizeFilter gridColumnCount={gridColumnCount} />
    <PaginationLabel
      pageSize={resultCount}
      total={total}
      showLoadAll={showLoadAll}
      loadAll={loadAll}
    />
  </div>
);

GridControlBar.defaultProps = {
  showLoadAll: false,
};

GridControlBar.propTypes = {
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loadAll: PropTypes.func,
  resultCount: PropTypes.number,
  gridColumnCount: PropTypes.number,
  showLoadAll: PropTypes.bool.isRequired,
};

export default withFunctionalClassName(ComponentType.ORGANISM, 'GridControlBar')(GridControlBar);
