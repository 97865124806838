import { object, string } from 'yup';
import { FIRSTNAME_EMAIL_SIGNUP_REGEX } from 'common/src/app/data/regexPatterns';
import { isEmailValid, membershipCardValid } from 'common/src/app/validation/formikValidation';

const ContactUsFieldNames = {
  CONTACT_FORM_CATEGORY: 'contactFormCategory',
  ICELAND_TERMS: 'icelandTerms',
  MESSAGE: 'message',
};

export const ContactUsPublicField = {
  NAME: 'name',
  EMAIL: 'email',
  POSTCODE: 'postcode',
  TELEPHONE: 'telephone',
  ONLINE_REF: 'onlineRef',
  CARD_NUMBER: 'cardNumber',
};

export const NotRequiredDefaultFields = [
  ContactUsPublicField.TELEPHONE,
  ContactUsPublicField.ONLINE_REF,
  ContactUsPublicField.CARD_NUMBER,
];

export const ContactUsPublicFormSchema = object().shape({
  [ContactUsPublicField.NAME]: string()
    .required('errors.requiredField')
    .trim()
    .max(30, 'contactUsForm.error.maxLength.name')
    .matches(FIRSTNAME_EMAIL_SIGNUP_REGEX, 'errors.invalidField'),
  [ContactUsPublicField.EMAIL]: string()
    .required('errors.requiredField')
    .trim()
    .test(isEmailValid),
  [ContactUsPublicField.POSTCODE]: string()
    .required('errors.requiredField')
    .trim(),
  [ContactUsPublicField.CARD_NUMBER]: string()
    .trim()
    .typeError('contactUsForm.error.cardNumber')
    .test(membershipCardValid),
  [ContactUsFieldNames.MESSAGE]: string()
    .required('contactUsForm.error.message')
    .trim(),
});

// Advertising artwork / ADS enquiry
export const ContactUsAdvertisementPublicField = {
  NAME: 'name',
  EMAIL: 'email',
  TELEPHONE: 'telephone',
  POSTCODE: 'postcode',
  ADVERT_SIZE: 'advertSize',
  ORIENTATION: 'orientation',
  COLOR_PREFERENCE: 'colorPreference',
  DATE: 'date',
  GROUP_INFORMATION: 'groupInformation',
};

export const NotRequiredAdvertisementFields = [
  ContactUsAdvertisementPublicField.POSTCODE,
  ContactUsAdvertisementPublicField.ADVERT_SIZE,
  ContactUsAdvertisementPublicField.ORIENTATION,
  ContactUsAdvertisementPublicField.COLOR_PREFERENCE,
  ContactUsAdvertisementPublicField.DATE,
  ContactUsAdvertisementPublicField.GROUP_INFORMATION,
];

export const ContactUsPublicAdvertisementFormSchema = object().shape({
  [ContactUsAdvertisementPublicField.NAME]: string()
    .required('errors.requiredField')
    .trim()
    .max(30, 'contactUsForm.error.maxLength.name')
    .matches(FIRSTNAME_EMAIL_SIGNUP_REGEX, 'errors.invalidField'),
  [ContactUsAdvertisementPublicField.TELEPHONE]: string()
    .required('errors.requiredField')
    .trim(),
  [ContactUsAdvertisementPublicField.EMAIL]: string()
    .required('errors.requiredField')
    .trim()
    .test(isEmailValid),
  [ContactUsFieldNames.MESSAGE]: string().required('contactUsForm.error.message'),
});

// Media enquiries / media enquiry
export const ContactUsMediaPublicFields = {
  NAME: 'name',
  EMAIL: 'email',
  TELEPHONE: 'telephone',
  MEDIA_ORGANISATION: 'mediaOrganisation',
  JOB_TITLE: 'jobTitle',
};

export const ContactUsPublicMediaFormSchema = object().shape({
  [ContactUsMediaPublicFields.NAME]: string()
    .required('errors.requiredField')
    .trim()
    .max(30, 'contactUsForm.error.maxLength.name')
    .matches(FIRSTNAME_EMAIL_SIGNUP_REGEX, 'errors.invalidField'),
  [ContactUsMediaPublicFields.EMAIL]: string()
    .required('errors.requiredField')
    .trim()
    .test(isEmailValid),
  [ContactUsMediaPublicFields.TELEPHONE]: string()
    .required('errors.requiredField')
    .trim(),
  [ContactUsMediaPublicFields.MEDIA_ORGANISATION]: string()
    .required('errors.requiredField')
    .trim(),
  [ContactUsMediaPublicFields.JOB_TITLE]: string()
    .required('errors.requiredField')
    .trim(),
  [ContactUsFieldNames.MESSAGE]: string()
    .required('contactUsForm.error.message')
    .trim(),
});

const ContactFormDropDownValues = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
  FULL_COLOUR: 'fullColour',
  BLACK_AND_WHITE: 'blackAndWhite',
};

const ContactFormDropDownOptions = {
  [ContactUsAdvertisementPublicField.ORIENTATION]: [
    ContactFormDropDownValues.LANDSCAPE,
    ContactFormDropDownValues.PORTRAIT,
  ],
  [ContactUsAdvertisementPublicField.COLOR_PREFERENCE]: [
    ContactFormDropDownValues.FULL_COLOUR,
    ContactFormDropDownValues.BLACK_AND_WHITE,
  ],
};

export const getContactUsDropDownOptions = ({ field, getMessage }) =>
  Object.keys(ContactFormDropDownOptions[field]).reduce(
    (subjects, key) => [
      ...subjects,
      {
        value: key,
        title: getMessage(`contactUsForm.selectOptions.${ContactFormDropDownOptions[field][key]}`),
      },
    ],
    [],
  );

export default ContactUsFieldNames;
