import createAction from 'redux-actions/lib/createAction';

export const META_PROP_PAGINATION_VIEW = 'collectionPaginationViewId';

export const UPDATE_COLLECTION_PAGINATION_VIEW = 'view/UPDATE_COLLECTION_PAGINATION_VIEW';

export const updateCollectionPaginationView = createAction(
  UPDATE_COLLECTION_PAGINATION_VIEW,
  (id, { offset, limit, collection }) => ({ offset, limit, collection }),
  id => ({ [META_PROP_PAGINATION_VIEW]: id }),
);
