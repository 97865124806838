/* global 'molecule','UserTestingTestimonial' */
import React from 'react';
import PropTypes from 'prop-types';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import QuotationBlock from 'components/molecules/BlockContent/components/atoms/QuotationBlock';
import Image from 'components/atoms/Image';

import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import './user-testing-testimonial.scss';

const UserTestingTestimonial = ({ header, quote, author }, context, className) => {
  const { image, text } = author;

  return (
    <div className={className}>
      {header && <RichTextBlock cid="header" text={header} />}
      {quote && <QuotationBlock quotation={quote} />}

      <div className="author">
        <Image src={image?.src} alt={image?.alt} ratio={1} />
        <RichTextBlock cid="author" text={text} />
      </div>
    </div>
  );
};

UserTestingTestimonial.propTypes = {
  header: PropTypes.string,
  quote: PropTypes.string,
  author: PropTypes.object,
};

export default withFunctionalClassName('molecule','UserTestingTestimonial')(UserTestingTestimonial);
