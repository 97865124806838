import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Wrapper from 'components/atoms/Wrapper';
import Loader from 'components/atoms/Loader/Loader';
import TextNew, {
  AccentColors,
  Colors,
  ElementTypes,
  FontSizes,
  FontFamily,
} from 'components/atoms/TextNew';
import { NavViews } from 'common/src/app/data/enum/NavViews';
import NavigationController from 'components/atoms/NavigationController';
import Row from 'components/atoms/Row';
import EnhancedFormField from 'common/src/app/formik/components/EnhancedFormField';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import SelectNew from 'components/atoms/SelectNew';
import { descriptorTypes } from 'components/atoms/Form/components/atoms/InputNew';
import SearchFields from 'common/src/app/data/enum/FieldNames/SearchFields';
import IconName from 'common/src/app/data/enum/IconName';
import IconLabel from 'components/molecules/IconLabel';
import Grid from 'components/organisms/Grid';
import GridItem from 'components/organisms/GridItem';
import GridItemType from 'common/src/app/data/enum/GridItemType';
import CountrySwitch from 'components/molecules/UkPublicNavigation/components/molecules/CountrySwitch';
import Pages from 'common/src/app/data/enum/Pages';
import { virtualGroups } from 'common/src/app/config/market/market.configdefinitions';
import NearestGroupTile from '../NearestGroup/components/organisms/NearestGroupTile';
import EmptySearchResults from '../NearestGroup/components/templates/NearestGroupLanding/components/organisms/EmptySearchResults';

import './cyprus.scss';

const Cyprus = (
  {
    navHidden,
    isLoading,
    isInitializing,
    townOptions,
    location: { query: { group } = {} },
    groups,
    handleSubmit,
    handleChange,
    deviceState,
  },
  { getMessage },
  className,
) => (
  <div className={className} data-testid="cyprus">
    <Wrapper cid="intro-content" width="lg" padding="lg">
      {virtualGroups ? (
        <TextNew.Script
          color={AccentColors.ACCENT_PURPLE}
          size={deviceState > DeviceState.MD ? FontSizes.SM : FontSizes.MD}
          family={deviceState > DeviceState.MD ? FontFamily.SCRIPT : FontFamily.SERIF}
          localeId="cyprus.virtualGroups.header"
          cid="virtualGroups-intro"
        />
      ) : (
        <Row className="intro-title" justifycenter>
          <TextNew element={ElementTypes.H1}>
            <TextNew.Script.SM
              color={AccentColors.ACCENT_PURPLE}
              localeId="cyprus.title.intro"
              cid="intro"
            />
            <TextNew.Serif.XL
              color={AccentColors.ACCENT_PURPLE}
              localeId="cyprus.title.main"
              cid="main"
              size={deviceState > DeviceState.SM ? FontSizes.XL : FontSizes.LG}
            />
            <TextNew.Script.SM
              color={AccentColors.ACCENT_PURPLE}
              localeId="cyprus.title.group"
              cid="group"
            />
          </TextNew>
        </Row>
      )}
      {navHidden && (
        <CountrySwitch
          path={`${Pages.UK_PUBLIC_NEAREST_GROUP_LANDING}?persistHideNav=true`}
          getMessage={getMessage}
        />
      )}
    </Wrapper>
    <Wrapper cid="main-paragraph" width="md" padding="lg">
      {virtualGroups ? (
        <TextNew.Serif.SM
          family={deviceState > DeviceState.SM ? FontFamily.SERIF : FontFamily.SANS}
          cid="details"
          localeId="cyprus.virtualGroups.description"
        />
      ) : (
        <>
          {deviceState > DeviceState.SM && (
            <TextNew.Serif.SM cid="details" localeId="cyprus.details1" />
          )}
          <TextNew.Serif.SM localeId="cyprus.details2" />
        </>
      )}
    </Wrapper>

    <Wrapper width="md" padding="lg" cid="drop-down">
      <form onSubmit={handleSubmit} className="city-selector">
        <Row className="find-text-and-icon" justifycenter>
          {deviceState > DeviceState.SM && (
            <IconLabel height={64} icon={IconName.MAP_MARKER_SYMBOL} />
          )}

          <TextNew.Serif.SM
            element={ElementTypes.H1}
            size={deviceState > DeviceState.SM ? FontSizes.SM : FontSizes.XS}
            cid="find-group-text"
            localeId={'nearestGroupSearchBar.title'}
            color={Colors.PRIMARY_LIGHT}
          />
        </Row>

        <EnhancedFormField
          name={SearchFields.QUERY}
          component={SelectNew}
          options={townOptions}
          onChange={e => {
            handleChange(e);
            handleSubmit();
          }}
          descriptorType={descriptorTypes.GHOST}
          placeholder={getMessage('cyprus.placeholder')}
          defaultValue=""
          dataTestId="cyprus-town-select"
        />
      </form>
    </Wrapper>
    {isLoading || (isInitializing && <Loader />)}
    {!isLoading && !isInitializing && group && Object.values(group).length > 0 && (
      <Wrapper width="lg" padding="lg" cid="results">
        <Grid>
          {groups?.length > 0 ? (
            groups.map(item => (
              <GridItem type={GridItemType.FULL_WIDTH} key={item.id}>
                <NearestGroupTile item={item} isCyprus />
              </GridItem>
            ))
          ) : (
            <EmptySearchResults />
          )}
        </Grid>
      </Wrapper>
    )}
    <NavigationController hideUiOptions={[NavViews.HIDE_GROUP_SEARCH_BAR]} />
  </div>
);

Cyprus.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

Cyprus.propTypes = {
  isLoading: PropTypes.bool,
  isInitializing: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  townOptions: PropTypes.array,
  groups: PropTypes.array,
  location: PropTypes.object,
  deviceState: PropTypes.number,
  navHidden: PropTypes.bool,
};

export default withDeviceState()(withFunctionalClassName(ComponentType.PAGE, 'Cyprus')(Cyprus));
