import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import RecipeAdditionalTimeSuffixArray from 'common/src/app/data/RecipeAdditionalTimeSuffixArray';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { ElementTypes, Colors } from 'components/atoms/TextNew';
import LocaleMessage from 'components/atoms/LocaleMessage';

import './recipe-duration-display.scss';

const RecipeDurationDisplay = (
  { totalTime, additionalTimeInMinutes, additionalTimeSuffix, itemProp },
  { getMessage },
  className,
) => {
  // Total time
  const totalTimeHours = Math.floor(totalTime / 60);
  const totalTimeMinutes = totalTime % 60;

  // Additional time
  const additionalTimeHours = additionalTimeInMinutes
    ? Math.floor(additionalTimeInMinutes / 60)
    : null;

  const additionalTimeMinutes = additionalTimeInMinutes ? additionalTimeInMinutes % 60 : null;

  const duration = getMessage('recipeDurationDisplay.totalTime.duration', {
    HOURS: totalTimeHours,
    MINUTES: totalTimeMinutes,
  });

  const addedInstructions =
    additionalTimeInMinutes && !isNaN(additionalTimeHours)
      ? getMessage('recipeDurationDisplay.additionalTime.duration', {
          HOURS: additionalTimeHours,
          MINUTES: additionalTimeMinutes,
          SUFFIX: additionalTimeSuffix?.toLowerCase(),
        })
      : '';

  const recipeDuration = `${duration}${addedInstructions && ` ${addedInstructions}`}`;

  return (
    <>
      <TextNew.Formal
        element={ElementTypes.SPAN}
        color={Colors.MID_TONE}
        itemProp={itemProp}
        className={className}
        ariaHidden
      >
        {recipeDuration}
      </TextNew.Formal>
      <span className="screen-reader">
        <LocaleMessage
          id="recipeDurationDisplay.screenReaderPrefix"
          params={{ DURATION: recipeDuration }}
        />
      </span>
    </>
  );
};

RecipeDurationDisplay.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

RecipeDurationDisplay.propTypes = {
  /**
   * The total time in minutes to be displayed in hours and minutes (this value does not include additional time)
   */
  totalTime: PropTypes.number.isRequired,
  /**
   * The additional time in minutes to be displayed in hours and minutes
   */
  additionalTimeInMinutes: PropTypes.number,
  /**
   * The additional time suffix as a string - e.g Standing, Chilling, Overnight freezing
   * This may not be present on all recipes - due to legacy recipes
   */
  additionalTimeSuffix: PropTypes.oneOf(RecipeAdditionalTimeSuffixArray),
  /**
   * Schema.org attribute to support Pinterest rich pins
   */
  itemProp: PropTypes.string,
};

export default withFunctionalClassName(
  ComponentType.ATOM,
  'RecipeDurationDisplay',
)(RecipeDurationDisplay);
