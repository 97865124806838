/* global 'atom','FeaturesListBlock' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TileCarousel from '../../../../TileCarousel';
import TextNew from '../../../../../atoms/TextNew';
import Wrapper from '../../../../../atoms/Wrapper';
import SmallFeatureTile from '../SmallFeatureTile';
import './features-list-block.scss';

/**
 * Create a list of features in carousel
 */
const FeaturesListBlock = ({ title, text, nestedContent }, context, className) => (
  <div className={className}>
    {title || text ? (
      <Wrapper size="lg" padding="sm">
        {title ? <TextNew.Loud>{title}</TextNew.Loud> : null}
        {text ? <TextNew.Strong>{text}</TextNew.Strong> : null}
      </Wrapper>
    ) : null}
    <div className="carousel-wrapper">
      {nestedContent && nestedContent.length > 0 ? (
        <TileCarousel>
          {nestedContent.map(item => (
            <SmallFeatureTile
              cid="carousel-item"
              key={item.title}
              title={item.title}
              image={item.image}
              link={item.link}
            />
          ))}
        </TileCarousel>
      ) : null}
    </div>
  </div>
);

FeaturesListBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  nestedContent: PropTypes.array,
};

export default withFunctionalClassName('atom','FeaturesListBlock')(FeaturesListBlock);
