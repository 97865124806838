import React, { useRef, useEffect } from 'react';
import { compose } from 'redux';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import TextNew, { Colors, ElementTypes, FontWeight } from 'components/atoms/TextNew';
import HashDeepLinks from 'common/src/app/data/enum/HashDeepLinks';
import errorBoundary from 'components/hoc/ErrorBoundary';
import Loader from 'components/atoms/Loader';

import AdvertiseWithUs from '../../molecules/AdvertiseWithUs';
import StarPromo from './components/molecules/StarPromo';
import ColourBlock from './components/molecules/ColourBlock';
import PrintMagazinePromo from './components/molecules/PrintMagazinePromo';
import DigitalMagazinePromo from './components/molecules/DigitalMagazinePromo';

import './magazine.scss';

const Magazine = (
  { isInitializing, hash, scrollToElement, headerHeight, page },
  context,
  className,
  dataTestid,
) => {
  const {
    outNowTitle,
    outNowSubtitle,
    outNowIntroduction,
    bonusGift,
    magazineInspirationBlock,
    printEditionImage,
    printEditionDateText,
    digitalEditionImage,
    magazineStarImage,
    magazineStarBackgroundColour,
    magazineStarFontColour,
    magazineStarTitle,
    magazineStarSubtitle,
    magazineStarDescription,
  } = page;

  const ref = useRef();

  useEffect(() => {
    if (hash) scrollToTheSubscriptionBlock(hash, scrollToElement, headerHeight, ref);
  }, [hash, headerHeight, scrollToElement]);

  const backgroundColorOptions = {
    creme: 'light-tone',
    green: 'accent-green',
    grey: 'accent-grey',
    'light-blue': 'accent-blue',
    pink: 'accent-pink',
    purple: 'accent-purple',
    red: 'accent-red',
  };

  const fontColorOptions = {
    black: 'primary-dark',
    white: 'primary-light',
    purple: 'accent-purple',
    red: 'accent-red',
    creme: 'light-tone',
    green: 'accent-green',
    grey: 'accent-grey',
    'light-blue': 'accent-blue',
  };

  const starPromotionData = {
    backgroundColor: backgroundColorOptions[magazineStarBackgroundColour],
    color: fontColorOptions[magazineStarFontColour],
    magazineStarImage,
    title: magazineStarTitle,
    subTitle: magazineStarSubtitle,
    description: magazineStarDescription,
  };

  const printMagazinePromotionData = {
    printImage: printEditionImage,
    issueDate: printEditionDateText,
    bonusGift: bonusGift?.length > 0 ? bonusGift[0] : null,
  };

  return (
    <div className={className} data-testid={dataTestid}>
      {!isInitializing && page ? (
        <>
          <HeroBarPublic image={page?.image}>
            {outNowTitle && (
              <TextNew.Serif.MD
                cid="hero-bar-public-serif-heading"
                element={ElementTypes.H1}
                weight={FontWeight.BOLD}
              >
                {outNowTitle}
              </TextNew.Serif.MD>
            )}

            {outNowSubtitle && (
              <TextNew.Script.SM
                cid="hero-bar-public-script-heading"
                color={Colors.BRAND}
                element={ElementTypes.H2}
              >
                {outNowSubtitle}
              </TextNew.Script.SM>
            )}

            {outNowIntroduction && (
              <TextNew.Serif.XS cid="hero-bar-public-introduction">
                {outNowIntroduction}
              </TextNew.Serif.XS>
            )}
          </HeroBarPublic>

          {magazineInspirationBlock?.map(block => (
            <ColourBlock key={block.title} {...block} />
          ))}

          <section className="subscription-wrapper" id="subscribe" ref={ref}>
            <PrintMagazinePromo {...printMagazinePromotionData} />
            <DigitalMagazinePromo digitalEditionImage={digitalEditionImage} />
          </section>

          <StarPromo {...starPromotionData} />

          <AdvertiseWithUs />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const scrollToTheSubscriptionBlock = (hash, scrollToElement, headerHeight, referenceWrapper) => {
  if (referenceWrapper?.current && hash?.length > 0 && hash === HashDeepLinks.SUBSCRIBE) {
    scrollToElement(referenceWrapper.current, -headerHeight);
  }
};

scrollToTheSubscriptionBlock.propTypes = {
  hash: PropTypes.string,
};

Magazine.propTypes = {
  hash: PropTypes.string,
  headerHeight: PropTypes.number,
  page: PropTypes.object,
  scrollToElement: PropTypes.func.isRequired,
  isInitializing: PropTypes.bool,
};

Magazine.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.PAGE, 'Magazine'),
)(Magazine);
