import createAction from 'redux-actions/lib/createAction';

export const SET_DEVICE_STATE = 'deviceStateActions/SET_DEVICE_STATE';

export const setDeviceState = createAction(SET_DEVICE_STATE, (state, name, helper) => ({
  state,
  name,
  helper,
}));

export const SET_SCROLLBAR_WIDTH = 'deviceStateActions/SET_SCROLLBAR_WIDTH';

export const setScrollbarWidth = createAction(SET_SCROLLBAR_WIDTH, width => ({ width }));
