import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Link from 'react-router/lib/Link';
import FoodRangeCategory from 'common/src/app/data/enum/FoodRangeCategory';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import { countryCode } from 'common/src/app/data/enum/Countries';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';
import Image from '../../atoms/Image';
import TextNew, { ElementTypes } from '../../atoms/TextNew';
import FoodRangeImage from './food-range-image.png';
import './food-range.scss';

const FoodRange = ({ foodRangeCategoryId }, { getMessage }, className) => (
  <div className={className}>
    <Link
      href={marketName === countryCode.IRELAND ? PagesExternal.DUNNES : PagesExternal.ICELAND}
      target="_blank"
    >
      <aside>
        <TextNew.Script.XS
          element={ElementTypes.H2}
          cid="buy-me-title"
          localeId="foodRange.title"
        />

        <TextNew.PrimaryOriginal
          localeId={`foodRange.${
            foodRangeCategoryId === FoodRangeCategory.WHOLE ? 'whole' : 'part'
          }Description`}
        />
      </aside>
      <figure>
        <Image
          src={FoodRangeImage}
          useWebp={false}
          ratio={76 / 55}
          alt={getMessage('foodRange.imgAlt')}
        />
      </figure>
    </Link>
  </div>
);

FoodRange.propTypes = {
  foodRangeCategoryId: PropTypes.oneOf(Object.values(FoodRangeCategory)),
};

FoodRange.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'FoodRange')(FoodRange);
