import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { API_CONSULTANT_CAREERS_PAGE_BACKGROUND } from 'common/src/app/data/apiComponents';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';
import ConsultantEnquirySuccess from './ConsultantEnquirySuccess';

const mapStateToProps = state => ({
  pageBackgroundImage: contentComponentSelector(
    state,
    API_CONSULTANT_CAREERS_PAGE_BACKGROUND,
    'image',
  ),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(API_CONSULTANT_CAREERS_PAGE_BACKGROUND)),
});

export default compose(addInitAction, connected)(ConsultantEnquirySuccess);
