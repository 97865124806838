import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { API_STATIC_PUBLIC_BETTER_HEALTH_NEW } from 'common/src/app/data/apiStatics';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import BetterHealth from './BetterHealth';

const mapStateToProps = state => ({
  data: staticPageSelector(state, API_STATIC_PUBLIC_BETTER_HEALTH_NEW) || {},
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getStaticContent(API_STATIC_PUBLIC_BETTER_HEALTH_NEW, true)),
});

export default compose(addInitAction, connected)(BetterHealth);
