import { SET_RENDER_MODE } from '../actions/renderActions';
import {
  SET_ENVIRONMENT_CONFIG,
  SET_VIEWER_COUNTRY,
  SET_WEBVIEW_COOKIE,
  SET_RECAPTCHA_TOKEN,
} from '../actions/configActions';

const configReducer = (
  state = {
    renderMode: 'client',
    viewerCountry: null,
    environmentConfig: {},
    isWebView: false,
    isFree2GoApp: false,
    recaptchaToken: null,
  },
  action,
) => {
  switch (action.type) {
    case SET_RENDER_MODE:
      return {
        ...state,
        renderMode: action.payload,
      };
    case SET_ENVIRONMENT_CONFIG:
      return {
        ...state,
        environmentConfig: action.payload,
      };
    case SET_VIEWER_COUNTRY:
      return {
        ...state,
        viewerCountry: action.payload,
      };
    case SET_WEBVIEW_COOKIE:
      return {
        ...state,
        isWebView: action.payload,
      };
    case SET_RECAPTCHA_TOKEN:
      return {
        ...state,
        recaptchaToken: action.payload,
      };
    default:
      return state;
  }
};

export default configReducer;
