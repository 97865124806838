import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import {
  API_STATIC_PUBLIC_SUCCESS_STORIES_LANDING,
  API_STATIC_PUBLIC_ICELAND_PROMOTION,
} from 'common/src/app/data/apiStatics';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { withInitAction, prepareComponent } from '@mediamonks/react-redux-component-init';
import { staticPageSelector, landingGridSelector } from 'common/src/app/selectors/publicSelectors';
import { getPublicLandingSuccessStories } from 'common/src/app/actions/resources/publicActions';
import { SearchType, getSearchResults } from 'common/src/app/actions/resources/searchActions';
import Promotion from 'components/organisms/Promotion';
import Pages from 'common/src/app/data/enum/Pages';

import SuccessStoriesLanding from './SuccessStoriesLanding';

const genrateGenderFromPath = location => ({
  gender: location?.pathname?.replace(new RegExp(`${Pages.UK_PUBLIC_SUCCESS_STORIES}[/]?`), ''),
});

const getResults = (dispatch, loadMore, query, featuredStories, featuredStory, genderFromPath) => {
  const isGenderFiltered = Boolean(genderFromPath?.gender);
  /*
When no gender filter is applied, 'excludedIds' is used to avoid duplicates in search results on the landing page. It includes IDs from the main featured story and any additional featured ones, so these are not repeated in the search results.
*/
  const excludedIds = isGenderFiltered
    ? []
    : [...(featuredStories?.map(story => story.id) || []), featuredStory?.id].filter(Boolean);

  return dispatch(
    getSearchResults(
      SearchType.PUBLIC_SUCCESS_STORIES,
      {
        limit: 12,
        ...query,
        ...genderFromPath,
      },
      loadMore,
      null,
      null,
      excludedIds,
    ),
  );
};

const mapStateToProps = (state, { location }) => {
  const pageData = staticPageSelector(state, API_STATIC_PUBLIC_SUCCESS_STORIES_LANDING);
  const successStories = landingGridSelector(state, true);

  // Get the search filter from the url
  const genderFromPath = genrateGenderFromPath(location);

  // Has used the female, male success story  set filter
  const hasFiltered = !!genderFromPath?.gender;

  return {
    headerImage: pageData?.headerImage,
    featuredStory: pageData?.featuredStory,
    featuredStories: pageData?.featuredStories,
    icelandBackground: staticPageSelector(state, API_STATIC_PUBLIC_ICELAND_PROMOTION),
    genderFromPath,
    hasFiltered,
    pathname: location?.pathname,
    ...successStories,
  };
};

const mapDispatchToProps = (dispatch, { location: { query } = { query: '' } }) => ({
  loadMore: (featuredStories, featuredStory, genderFromPath) =>
    getResults(dispatch, true, query, featuredStories, featuredStory, genderFromPath),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction(
  ['location'],
  {
    prepared: async ({ location }, dispatch) => {
      const successStoryLandingPage = await dispatch(getPublicLandingSuccessStories());

      return Promise.all([
        dispatch(getStaticContent(API_STATIC_PUBLIC_ICELAND_PROMOTION, true)),
        dispatch(prepareComponent(Promotion)),
        getResults(
          dispatch,
          false,
          location?.query,
          successStoryLandingPage?.entity?.featuredStories,
          successStoryLandingPage?.entity?.featuredStory,
          genrateGenderFromPath(location),
        ),
      ]);
    },
  },
  { getPrepareKey: componentId => componentId },
);

export default compose(addInitAction, connected)(SuccessStoriesLanding);
