import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import FoodRangeCategory from 'common/src/app/data/enum/FoodRangeCategory';
import { countryCode } from 'common/src/app/data/enum/Countries';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState, { DeviceState } from 'common/src/app/util/device-state/withDeviceState';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import TextNew, { Colors, ElementTypes, FontSizes, TextTypes } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Image from '../../atoms/Image';
import FoodRangeImage from '../FoodRange/food-range-image.png';

import './food-range-cta.scss';

const FoodRangeCta = ({ foodRangeCategoryId, deviceState }, { getMessage }, className) => (
  <section className={className}>
    <header>
      <TextNew.Script.SM
        element={ElementTypes.H2}
        cid="cta-title"
        color={Colors.PRIMARY_LIGHT}
        localeId="foodRangeCta.title"
      />
    </header>

    <div className="cta-content">
      <figure>
        <Image
          src={FoodRangeImage}
          useWebp={false}
          ratio={380 / 289}
          alt={getMessage('foodRangeCta.imgAlt')}
        />
      </figure>

      <div className="cta-description">
        <TextNew
          type={deviceState > DeviceState.SM ? TextTypes.STRONG : TextTypes.FORMAL_ORIGINAL}
          size={FontSizes.XS}
          color={Colors.PRIMARY_LIGHT}
          localeId={`foodRangeCta.${
            foodRangeCategoryId === FoodRangeCategory.WHOLE ? 'whole' : 'part'
          }Description`}
        />

        <Button
          href={marketName === countryCode.IRELAND ? PagesExternal.DUNNES : PagesExternal.ICELAND}
          target="_blank"
        >
          <LocaleMessage id="foodRangeCta.button" />
        </Button>
      </div>
    </div>
  </section>
);

FoodRangeCta.propTypes = {
  deviceState: PropTypes.number,
  foodRangeCategoryId: PropTypes.oneOf(Object.values(FoodRangeCategory)),
};

FoodRangeCta.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'FoodRangeCta')(FoodRangeCta),
);
