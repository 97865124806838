import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { API_CONSULTANT_CAREERS_PAGE_BACKGROUND } from 'common/src/app/data/apiComponents';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';
import CallOutBlock from './CallOutBlock';

const mapStateToProps = state => ({
  pageBackgroundImage: contentComponentSelector(
    state,
    API_CONSULTANT_CAREERS_PAGE_BACKGROUND,
    'image',
  ),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction(['showBackground'], {
  prepared: ({ showBackground }, dispatch) =>
    showBackground
      ? dispatch(getComponent(API_CONSULTANT_CAREERS_PAGE_BACKGROUND))
      : Promise.resolve(),
});

export default compose(addInitAction, connected)(CallOutBlock);
