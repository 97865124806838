/* global 'molecule','SocialLinks' */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Icon from '../../atoms/Icon';
import './social-links.scss';

const SocialLinks = ({ links, type, size }, {}, className, dataTestid) => (
  <ul className={className}>
    {links &&
      links.map(footerSocialItem => (
        <li key={footerSocialItem.link} data-testid={`${dataTestid}-item`}>
          <Link
            data-testid={footerSocialItem.link}
            href={footerSocialItem.link}
            target={`_${footerSocialItem.newWindow}`}
          >
            <span className="screen-reader">{footerSocialItem.caption}</span>
            <Icon
              round={type === 'round'}
              name={footerSocialItem.icon.replace('icon-', '')}
              width={size}
              height={size}
            />
          </Link>
        </li>
      ))}
  </ul>
);

SocialLinks.propTypes = {
  /**
   * Footer links dynamically pulled in from the API
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      icon: PropTypes.string,
      link: PropTypes.string,
      newWindow: PropTypes.string,
    }),
  ),
  type: PropTypes.string,
  size: PropTypes.number,
};

SocialLinks.defaultProp = {
  type: 'round',
};

export default withFunctionalClassName('molecule','SocialLinks', ['{type}-type'])(SocialLinks);
