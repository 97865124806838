import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { getCounties } from 'common/src/app/actions/resources/groupSearchActions';
import { manualSetSEO } from 'common/src/app/actions/seoActions';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import GroupSearchCounty from './GroupSearchCounty';

const countiesSelector = state => state.view.nearestGroup?.counties;

const mapStateToProps = () => state => ({
  countyOptions: countiesSelector(state),
});

const addInitAction = withInitAction({
  prepared: ({ seoObject }, dispatch) =>
    dispatch(getCounties(marketConfig.marketName))
      .then(() =>
        dispatch(
          // Need to use manualSetSEO has county call returns a seoObject which is
          // automatically picked up!
          manualSetSEO(seoObject),
        ),
      )
      .catch(),
});

export default compose(addInitAction, connect(mapStateToProps))(GroupSearchCounty);
