import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import GroupOnlineComparison from 'bundle-loader?lazy&reactHot&name=GroupOnlineComparison!./index';

const routes = (
  <Route
    // blank so it can be pulled from the cms
    title=""
    description=""
    path={Pages.UK_PUBLIC_COMPARISON}
    {...getRouteComponentProp(GroupOnlineComparison)}
  />
);

export default routes;
