import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import './overlay.scss';

const Overlay = ({
  maxWidth,
  isOpen,
  onDismiss,
  label,
  children,
  shouldFocusAfterRender = true,
  contentStyle,
  localClassName,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onDismiss}
    closeTimeoutMS={300}
    className="atom-overlay"
    overlayClassName={`atom-overlay-lightbox ${localClassName}`}
    style={{
      overlay: {
        backgroundColor: 'hsla(0, 0%, 0%, 0.33)',
      },
      content: {
        maxWidth,
        ...contentStyle,
      },
    }}
    styles={{ maxWidth }}
    contentLabel={label}
    shouldFocusAfterRender={shouldFocusAfterRender}
    testId="modal"
  >
    {typeof children === 'function' ? children({ onDismiss }) : children}
  </Modal>
);

Overlay.defaultProps = {
  maxWidth: '712px',
};

Overlay.propTypes = {
  maxWidth: PropTypes.string,
  /**
   * Contents of the sheet
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Controls whether or not the sheet is open.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * This function is called whenever the user hits "Escape" or clicks outside the sheet. It's important to close the sheet onDismiss as seen in all the demos on this page.
   */
  onDismiss: PropTypes.func.isRequired,
  /**
   * A dialog needs to be properly labeled to provide context for users with assistive technology such as screen readers. If a dialog is announced to the user without a label, it can be confusing and difficult to navigate.
   */
  label: PropTypes.string.isRequired,
  shouldFocusAfterRender: PropTypes.bool,
  contentStyle: PropTypes.object,
  /**
   * Appends a className to the atom-overlay
   */
  localClassName: PropTypes.string,
};

export default Overlay;
