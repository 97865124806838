/* global 'atom' */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';

import './riddle-block.scss';

/**
 * Renders the Riddle Component
 *
 * This component initializes the riddle manually because the
 * riddle embed script only initializes riddle on the DOMContentLoaded event.
 *
 * See code in: https://www.riddle.com/files/js/embed.js
 */
class RiddleBlock extends Component {
  riddleTarget = null;

  componentDidMount() {
    if (!this.riddleTarget) return;

    /**
     * We need to initialize a riddle manually because the riddle script
     * that is added in the PageRenderer is only initializing new riddle
     * quizzes on the DOMContentLoaded event.
     */
    window.riddleAPI.init(this.riddleTarget);
  }

  componentWillUnmount() {
    this.resetRiddle();
  }

  resetRiddle = () => {
    if (this.riddleTarget) {
      this.riddleTarget = null;
      window.riddleAPI.riddles = [];
    }
  };

  render() {
    const { baseUrl, riddleId, autoScroll } = this.props;

    return (
      <section {...componentClassNameProp('atom', this)}>
        <div
          // Save reference to be able to initialize the riddle quiz
          ref={c => (this.riddleTarget = c)}
          data-rid-id={riddleId}
          data-auto-scroll={autoScroll}
        >
          <iframe title="Quiz" className="riddle-frame" src={`${baseUrl}/a/${riddleId}?`} />
        </div>
      </section>
    );
  }
}

RiddleBlock.defaultProps = {
  baseUrl: 'https://www.riddle.com',
  autoScroll: true,
};

RiddleBlock.propTypes = {
  /**
   * Riddle base url
   */
  baseUrl: PropTypes.string.isRequired,
  /**
   * Riddle id
   */
  riddleId: PropTypes.string.isRequired,
  autoScroll: PropTypes.bool.isRequired,
};

export default RiddleBlock;
