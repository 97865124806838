import { LOCATION_CHANGE } from 'react-router-redux/lib/reducer';
import { handleActionsAsync } from '../util/asyncReducer';
import {
  SET_TRACKING_PERSISTENT_DATA,
  SET_TRACKING_PAGE_DATA,
  PAGE_LOAD_COMPLETE,
} from '../actions/trackingActions';

export default handleActionsAsync(
  {
    [SET_TRACKING_PERSISTENT_DATA]: (state, { payload: { persistentData, merge } }) => ({
      ...state,
      persistentData: merge
        ? {
            ...state.persistentData,
            ...persistentData,
          }
        : persistentData,
    }),
    [SET_TRACKING_PAGE_DATA]: (state, { payload: { pathname, pageData, merge } }) => {
      const index = state.pageData.findIndex(page => page.pathname === pathname);

      const parseData = () => {
        const copy = state.pageData.slice(0);

        if (pageData) {
          copy[index].data = merge ? { ...copy[index].data, ...pageData } : pageData;
        }

        return copy;
      };

      return {
        ...state,
        pageData: state.pageData[index]
          ? parseData()
          : [
              ...state.pageData,
              ...[
                {
                  pathname,
                  ...(pageData ? { data: pageData } : {}),
                },
              ],
            ],
      };
    },
    [LOCATION_CHANGE]: (state, { payload }) => {
      // When a modal is triggered within a page, don't reset the pageLoadComplete parameter
      if (payload.search.indexOf('modal') > -1 && state.pageData.length > 0) {
        return state;
      }

      return { ...state, pageLoadComplete: false };
    },
    [PAGE_LOAD_COMPLETE]: state => ({ ...state, pageLoadComplete: true }),
  },
  { persistentData: {}, pageData: [], pageLoadComplete: false },
);
