/* global 'organism','RecipeTileContent' */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import classNames from 'classnames';
import InteractionType from 'common/src/app/data/enum/InteractionType';
import IconName from 'common/src/app/data/enum/IconName';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { convertRecipeFlagsToIconClass } from 'common/src/app/util/content/convertRecipeFlagsToIcon';
import synsToLocale from 'common/src/app/util/SynsToLocale';
import GridItemType from 'common/src/app/data/enum/GridItemType';
import InteractionToggleWrapper from 'common/src/app/hoc/InteractionToggleWrapper';
import RelatedEntity from 'common/src/app/data/enum/RelatedEntityKind';
import BookmarkButtonInteraction from '../../molecules/BookmarkButtonInteraction';
import TextNew, { ElementTypes, TextTypes, Colors } from '../../atoms/TextNew';
import RecipeDurationDisplay from '../../atoms/RecipeDurationDisplay';
import ResponsiveImage from '../../atoms/ResponsiveImage';
import StandoutLabel from '../../molecules/StandoutLabel';
import LocaleMessage from '../../atoms/LocaleMessage';
import RichTextBlock from '../../molecules/BlockContent/components/atoms/RichTextBlock';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';

import './recipe-tile-content.scss';

/**
 * This is the a standard variant of the tile.
 * It can be used for both features and recipes content type
 */
const RecipeTileContent = (
  {
    id,
    image,
    alternativeImage,
    link,
    title,
    description,
    totalTime,
    isInspirationTile,
    onClick,
    syns,
    video,
    characteristics,
    isFoodRange,
    featureType,
    isRead,
  },
  context,
  className,
) => {
  const isFeatured = featureType === GridItemType.FEATURED;
  const symbols = convertRecipeFlagsToIconClass(characteristics);
  const tileImageRatio = () => {
    if (isFeatured) {
      return 1 / 2;
    }
    return 1;
  };

  return (
    <div
      className={classNames(className, {
        'is-inspiration-tile': isInspirationTile,
      })}
    >
      {image?.src && (
        <figure>
          {isRead && (
            <div className="is-read">
              <Icon name={IconName.CHECKMARK} width={30} height={30} />
            </div>
          )}
          <Link to={link} onClick={onClick}>
            <ResponsiveImage
              src={alternativeImage?.src || image?.src}
              alt={alternativeImage?.alt || image?.alt || title}
              breakpoints={{
                623: { vw: 100, ratio: 1 },
                other: { px: isInspirationTile ? 460 : 280, ratio: tileImageRatio() },
              }}
            />
          </Link>
          {isFoodRange && (
            <StandoutLabel labelText={<LocaleMessage id="general.tiles.foodRange" />} />
          )}

          {video && (
            <button className="video">
              <Icon name="play" />
            </button>
          )}
        </figure>
      )}

      <aside>
        <Link className="intro" to={link} onClick={onClick && onClick}>
          <header className="recipe-header">
            <TextNew
              element={ElementTypes.H3}
              color={isFeatured ? Colors.BRAND : Colors.PRIMARY_DARK}
              type={isFeatured || isInspirationTile ? TextTypes.CONFIDENT : TextTypes.STRONG}
            >
              {title}
            </TextNew>
          </header>

          {(isFeatured || isInspirationTile) && (
            <div className="description">
              <TextNew.PrimaryElegant color={Colors.PRIMARY_DARK}>
                <RichTextBlock text={description} />
              </TextNew.PrimaryElegant>
            </div>
          )}
        </Link>

        {isInspirationTile ? (
          <Button to={link} cid="view-dish-button" isGhostDark>
            <LocaleMessage id="general.cta.viewDish" />
          </Button>
        ) : (
          <>
            <InteractionToggleWrapper
              targetId={id}
              targetTitle={title}
              interactionType={InteractionType.BOOKMARK}
              targetType={RelatedEntity.RECIPE}
              component={BookmarkButtonInteraction}
            />

            <div className="bottom">
              <div className="recipe-content">
                {typeof totalTime !== 'undefined' && totalTime > 0 && (
                  <TextNew.Formal color={Colors.MID_TONE} className="cooking-time">
                    <Icon name="time" />
                    <RecipeDurationDisplay totalTime={totalTime} />
                  </TextNew.Formal>
                )}

                {typeof syns !== 'undefined' && (
                  <TextNew.FomralLight
                    color={Colors.PRIMARY_DARK}
                    localeId="general.syns.shortLabel"
                    localeParams={synsToLocale(syns)}
                  />
                )}

                {symbols && symbols.includes(IconName.SP) && (
                  <Icon name={IconName.SP} height={20} />
                )}
              </div>
            </div>
          </>
        )}
      </aside>
    </div>
  );
};

RecipeTileContent.defaultProps = {
  video: false,
  isFoodRange: false,
  isInspirationTile: false,
};

RecipeTileContent.propTypes = {
  /**
   * The recipe id
   */
  id: PropTypes.string.isRequired,
  /**
   * The tile title
   */
  title: PropTypes.string.isRequired,
  /**
   * Short summary of the recipe
   */
  description: PropTypes.string,
  /**
   * The leading image on the tile
   */
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  alternativeImage: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),

  /**
   * If the content as a video attached, past the link
   */
  video: PropTypes.bool,

  /**
   * Cooking time is how long a recipe takes to cook in total
   */
  totalTime: PropTypes.number,

  /**
   * Tile characteristics
   */
  characteristics: PropTypes.number,

  /**
   * Should the button & description be visible
   */
  isInspirationTile: PropTypes.bool,

  /**
   * How many syns per portion
   */
  syns: PropTypes.number,

  /**
   * If the recipe is part of the food range it will display a label
   */
  isFoodRange: PropTypes.bool,
  link: PropTypes.string,

  /**
   * Component type used for double sized UI
   */
  featureType: PropTypes.oneOf([GridItemType.DEFAULT, GridItemType.FEATURED]),
  isRead: PropTypes.bool,
  onClick: PropTypes.func,
};

export default withFunctionalClassName('organism','RecipeTileContent', [
  ({ featureType }) => (featureType === GridItemType.FEATURED ? ['is-featured'] : []),
])(RecipeTileContent);
