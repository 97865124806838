import React from 'react';
import PropTypes from 'prop-types';
import InstagramEmbed from 'react-instagram-embed';

const InstagramBlock = ({ url, hideCaption }) => (
  <InstagramEmbed url={url} hideCaption={hideCaption} containerTagName="div" />
);

InstagramBlock.defaultProps = {
  hideCaption: false,
};

InstagramBlock.propTypes = {
  url: PropTypes.string.isRequired,
  hideCaption: PropTypes.bool,
};

export default InstagramBlock;
