import { ONLY_SINGLE_DIGITS } from '../../data/regexPatterns';
import CompositeInputFormatterError from '../CompositeInputFormatterError';

/**
 * Converts values from the HeightMetricFieldGroup input into a number value in centimeters
 * @example User entry: 1.3, value: 130cm
 * @example User entry: 1.33, value: 133cm
 * @param {object} formValues
 * @param {string} formValues.meters A string (that contains an integer) or an integer representing
 * the amount of meters
 * @param {string} formValues.centimeters A string (that contains an integer) or an integer representing
 * the amount entered into the centimeters box
 * @returns {number} The resulting number value in centimeters
 *
 * @example
 */

const setCentimeters = centimeters => {
  // checking single digit and converting to 10cm e.g. 5 to 50
  if (ONLY_SINGLE_DIGITS.test(centimeters)) {
    return centimeters * 10;
  }

  return centimeters;
};

const composeHeightMetric = ({ metres = 0, centimeters = 0 }) => {
  // Convert meters to a number - we don't do the same with string as we want leading 0s sometimes
  // so we don't process 07cm as 7cm and in turn convert it to 70cm because it's a single digit
  const metersNumber = Number(metres);

  if (metersNumber >= 3) {
    throw new CompositeInputFormatterError({ meters: 'Meters should be smaller than 3' });
  }
  if (metersNumber > 99) {
    throw new CompositeInputFormatterError({
      centimeters: 'Centimeters should be smaller than 100',
    });
  }

  return metersNumber * 100 + Number(setCentimeters(centimeters));
};

export default composeHeightMetric;
