import { connect } from 'react-redux';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import MeasurementInputField from './MeasurementInputField';

export default connect((state, ownProps) => {
  if (typeof ownProps.unit !== 'undefined') {
    // the unit is already passed, when using this with the toggle
    return {};
  }

  return {
    unit: MeasureUnit.IMPERIAL,
  };
})(MeasurementInputField);
