import { dataLayerCustomEvent } from '../../app/util/tracking/dataLayerUtils';
import { Category, DataLayerKeys } from '../../app/data/enum/Tracking';
import { isFulfilled, metaHasForm } from '../../app/util/actionFilters';
import { SUBMIT } from '../../app/enhanced-redux-form/actions/enhancedFormActions';
import FormNames from '../../app/data/enum/FormNames';

export default addListener => {
  addListener(
    {
      actionType: SUBMIT,
      filter: [isFulfilled, metaHasForm(FormNames.PUBLIC_BMI_CALCULATOR)],
    },
    (_, getState) => {
      dataLayerCustomEvent(
        {
          category: Category.BMI,
          [DataLayerKeys.BMI_RESULTS]: 1,
        },
        getState(),
      );
    },
  );
};
