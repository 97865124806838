import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';

import './group-bulletin.scss';

/**
 * Component that displays an urgent message relating to groups:
 * - such as bad weather or a global pandemic
 */

const GroupBulletin = ({ text }, context, className) => (
  <div className={className}>
    <div className="copy">
      <RichTextBlock text={text} />
    </div>
  </div>
);

GroupBulletin.propTypes = {
  text: PropTypes.string,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'GroupBulletin')(GroupBulletin);
