import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { nearestGroupCountiesSelector } from 'common/src/app/selectors/nearestGroupSearchSelectors';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { getCounties } from 'common/src/app/actions/resources/groupSearchActions';
import NearestGroupCounties from './NearestGroupCounties';

const mapStateToProps = state => ({
  counties: nearestGroupCountiesSelector(state) || {},
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getCounties(marketConfig.marketName)),
});

export default compose(addInitAction, connected)(NearestGroupCounties);
