/**
 * CookingType map
 */
const CookingType = {
  0: 'Grilling',
  1: 'OnePot',
  2: 'SlowCooker',
  3: 'NoCook',
  4: 'Fakeaway',
};

export default CookingType;
