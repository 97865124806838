import GroupSearchFieldNames from 'common/src/app/data/enum/FieldNames/GroupSearchFieldNames';

export const NearestGroupSearchAccessibilityFlags = {
  wheelchairAccess: 1,
  disabledToilet: 2,
  disabledParking: 4,
  hearingLoop: 8,
};

export const NearestGroupSearchDayFlags = {
  monday: 2,
  tuesday: 4,
  wednesday: 8,
  thursday: 16,
  friday: 32,
  saturday: 64,
  sunday: 128,
};

export const NearestGroupSearchTimesFlags = {
  morning: 1,
  afternoon: 2,
  evening: 4,
};

export const groupSearchfilterConfig = [
  {
    fieldName: GroupSearchFieldNames.DAYS,
    legend: 'Days',
    flags: NearestGroupSearchDayFlags,
  },
  {
    fieldName: GroupSearchFieldNames.TIMES,
    legend: 'Time',
    flags: NearestGroupSearchTimesFlags,
  },
  {
    fieldName: GroupSearchFieldNames.VENUE_ACCESSIBILITY,
    legend: 'Venue accessibility',
    flags: NearestGroupSearchAccessibilityFlags,
  },
];
