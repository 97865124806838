import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, { Colors, FontWeight } from 'components/atoms/TextNew';
import Pages from 'common/src/app/data/enum/Pages';
import './free-fresh-start-menu-roundel.scss';

const FreeFreshStartMenuPlannerRoundel = (props, context, className, dataTestid) => (
  <Link
    to={Pages.UK_PUBLIC_OFFERS}
    className={className}
    data-testid={dataTestid}
    style={{ backgroundImage: `url(${props.backgroundImage})` }}
  >
    <div className="roundelTextWrapper">
      <div className="plusFreeWrapper">
        <TextNew.Serif.MD
          cid="plus"
          color={Colors.PRIMARY_LIGHT}
          localeId="FreeFreshStartMenuPlannerRoundel.plus"
        />
        <TextNew.Script.LG
          cid="free"
          color={Colors.PRIMARY_LIGHT}
          localeId="FreeFreshStartMenuPlannerRoundel.free"
        />
      </div>
      <TextNew.Serif.MD
        cid="fresh-start"
        color={Colors.PRIMARY_LIGHT}
        weight={FontWeight.LIGHT}
        localeId="FreeFreshStartMenuPlannerRoundel.fresh-start"
      />
      <TextNew.Serif.MD
        cid="menu"
        color={Colors.PRIMARY_LIGHT}
        weight={FontWeight.LIGHT}
        localeId="FreeFreshStartMenuPlannerRoundel.menu"
      />
      <TextNew.Serif.MD
        cid="planner"
        color={Colors.PRIMARY_LIGHT}
        weight={FontWeight.LIGHT}
        localeId="FreeFreshStartMenuPlannerRoundel.planner"
      />
    </div>
  </Link>
);

FreeFreshStartMenuPlannerRoundel.propTypes = {
  backgroundImage: PropTypes.string,
};

FreeFreshStartMenuPlannerRoundel.defaultProps = {
  backgroundImage: '',
};

export default withFunctionalClassName(
  ComponentType.ATOM,
  'FreeFreshStartMenuPlannerRoundel',
)(FreeFreshStartMenuPlannerRoundel);
