import React from 'react';
import Link from 'react-router/lib/Link';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Button from 'components/atoms/Button';
import PropTypes from 'prop-types';
import LocaleMessage from 'components/atoms/LocaleMessage';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import TextNew from 'components/atoms/TextNew';
import Image from '../../atoms/Image';

import './image-content-vertical-split-block.scss';

const ImageContentVerticalSplitBlock = (
  { content, image, beforeImage, link, member = '', backgroundPosition },
  context,
  className,
) => {
  const [firstName] = member.split(' ');
  return (
    <div className={className}>
      {image && (
        <Link
          className="image-link"
          data-testid="after-image"
          to={link}
          style={{
            backgroundImage: `url(${image.src})`,
            backgroundPosition: `${backgroundPosition} top`,
          }}
        >
          <TextNew.Serif cid="member-link-text">{member}</TextNew.Serif>
          {beforeImage && <Image {...beforeImage} />}
        </Link>
      )}
      <div className="content-wrapper">
        {content}
        <Button isGhostDark to={link}>
          <LocaleMessage id="imageContentVerticalSplitBlock.cta" params={{ NAME: firstName }} />
        </Button>
      </div>
    </div>
  );
};

ImageContentVerticalSplitBlock.defaultProps = {
  backgroundPosition: 'center',
};

ImageContentVerticalSplitBlock.propTypes = {
  content: PropTypes.node,
  image: PropTypes.shape(imageShape),
  beforeImage: PropTypes.shape(imageShape),
  link: PropTypes.string,
  member: PropTypes.string,
  backgroundPosition: PropTypes.string,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'ImageContentVerticalSplitBlock',
)(ImageContentVerticalSplitBlock);
