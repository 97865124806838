import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import QuotationBlock from 'components/molecules/BlockContent/components/atoms/QuotationBlock';
import FranchiseFactsFaqQuestionAnswer from '../../molecules/FranchiseFactsFaqQuestionAnswer';
import createScriptTitle from '../../../../../../utils/createScriptTitle';

const FranchiseFactsFaq = ({ nestedRoleContent }, context, className) => (
  <div className={className}>
    {nestedRoleContent.map(({ _type, ...content }, index) => {
      switch (_type) {
        case types.QUOTATION_WITH_AUTHOR_PHOTO_BLOCK:
          return (
            <QuotationBlock
              {...content}
              quotation={createScriptTitle(content.quotation)}
              isConsultant
              key={`franchise-facts-faq-${index}`}
            />
          );

        default:
          return (
            <FranchiseFactsFaqQuestionAnswer {...content} key={`franchise-facts-faq-${index}`} />
          );
      }
    })}
  </div>
);

FranchiseFactsFaq.propTypes = {
  nestedRoleContent: PropTypes.array.isRequired,
};

export default withFunctionalClassName(
  ComponentType.ORGANISM,
  'FranchiseFactsFaq',
)(FranchiseFactsFaq);
