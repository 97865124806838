import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { titleStyleShape } from 'common/src/app/util/proptypes/masterTilePropTypes';
import TextNew, { TextTypes, Colors } from '../TextNew';

const TileTitle = ({ isFeatured, title, titleStyle, hasTileColor, path, onClick, tabIndex }) => {
  let defaultColor = Colors.PRIMARY_DARK;
  if (isFeatured) {
    defaultColor = Colors.BRAND;
  }

  if (hasTileColor) {
    defaultColor = Colors.PRIMARY_LIGHT;
  }
  const defaultType = isFeatured ? TextTypes.CONFIDENT : TextTypes.STRONG;

  const { fontSize, fontFamily, color, element } = titleStyle || {};

  return path ? (
    <Link className="title" to={path} onClick={onClick} tabIndex={tabIndex}>
      <TextNew
        color={color || defaultColor}
        type={(!fontFamily || !fontSize) && defaultType}
        family={fontFamily}
        size={fontSize}
        element={element}
      >
        {title}
      </TextNew>
    </Link>
  ) : (
    <TextNew
      color={color || defaultColor}
      type={(!fontFamily || !fontSize) && defaultType}
      family={fontFamily}
      size={fontSize}
      element={element}
    >
      {title}
    </TextNew>
  );
};

TileTitle.propTypes = {
  title: PropTypes.string.isRequired,
  hasTileColor: PropTypes.bool,
  isFeatured: PropTypes.bool,
  titleStyle: PropTypes.shape(titleStyleShape),
  path: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.string,
};

export default TileTitle;
