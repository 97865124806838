/* global WP_DEFINE_IS_NODE */
import { ENABLE_PERFORMANCE_MEASUREMENTS } from '../../actions/configActions';
import { isAsyncAction } from '../../util/asyncActionMiddleware';

export default (
  state = {
    enabled: false,
    actions: {},
  },
  action,
) => {
  if (action.type === ENABLE_PERFORMANCE_MEASUREMENTS) {
    return {
      ...state,
      enabled: true,
    };
  }

  if (state.enabled && WP_DEFINE_IS_NODE) {
    if (isAsyncAction(action)) {
      if (action.meta.isFulfilled) {
        return state.actions[action.meta.asyncId]
          ? {
              ...state,
              actions: {
                ...state.actions,
                [action.meta.asyncId]: {
                  ...state.actions[action.meta.asyncId],
                  duration: process.hrtime(state.actions[action.meta.asyncId].start),
                },
              },
            }
          : state;
      }

      return {
        ...state,
        actions: {
          ...state.actions,
          [action.meta.asyncId]: {
            api: action.meta.api || null,
            type: action.type,
            start: process.hrtime(),
          },
        },
      };
    }
  }
  return state;
};
