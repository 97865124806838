import { connect } from 'react-redux';
import { headingMainMenuToggle } from 'common/src/app/actions/components/headerActions';

import HeadingButton from './HeadingButton';

const mapStateToProps = state => {
  const { showMenu = false } = state.view.components.header;

  return {
    showMenu,
  };
};

const mapDispatchToProps = dispatch => ({
  onToggle: () => dispatch(headingMainMenuToggle()),
});

const connectedHeadingButton = connect(mapStateToProps, mapDispatchToProps)(HeadingButton);

export default connectedHeadingButton;
