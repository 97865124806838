import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { Colors } from '../../atoms/TextNew';

import './standout-label.scss';

/**
 * This component is a simple banner / label commonly found on images
 */

const StandoutLabel = ({ labelText }, context, className) => (
  <div className={className}>
    <TextNew.FormalOriginal color={Colors.PRIMARY_LIGHT}>{labelText}</TextNew.FormalOriginal>
  </div>
);

StandoutLabel.propTypes = {
  /**
   * The text that is displayed within the label
   */
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'StandoutLabel')(StandoutLabel);
