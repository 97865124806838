/* global 'organism','DeepLinkDisplayToggle' */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';

class DeepLinkDisplayToggle extends PureComponent {
  constructor() {
    super();
    this.state = {
      topPosition: 1000,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    this.updateStorePosition();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  updateStorePosition = () => {
    if (this.toggle) {
      const pos = this.toggle.getBoundingClientRect().top + document.documentElement.scrollTop;
      if (this.state.topPosition !== pos) {
        this.setState({ topPosition: pos });
        this.props.linkHiddenPosition({ [this.props.currentRoute]: this.state.topPosition });
      }
    }
  };

  onScroll = debounce(200, false, this.updateStorePosition);

  render() {
    return (
      <div ref={ref => (this.toggle = ref)} {...componentClassNameProp(ComponentType.ATOM, this)} />
    );
  }
}

DeepLinkDisplayToggle.propTypes = {
  linkHiddenPosition: PropTypes.func.isRequired,
  currentRoute: PropTypes.string.isRequired,
};

export default DeepLinkDisplayToggle;
