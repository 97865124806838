import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Link from 'react-router/lib/Link';
import Pages, { GROUP_REGISTRATION_FIRST_STEP } from 'common/src/app/data/enum/Pages';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import WebHost from 'common/src/app/data/enum/WebHost';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import Row from '../../atoms/Row';
import TextNew, { FontWeight, TextTypes, FontSizes } from '../../atoms/TextNew';
import WebHostLink from '../../atoms/WebHostLink';
import NavLink from '../../atoms/NavLink';
import ResponsiveImage from '../../atoms/ResponsiveImage';
import UkPublicLinks from '../UkPublicNavigation/UkPublicLinks';

import './uk-public-navigation-links.scss';

/**
 * Quick links component used in the public uk header
 */
const UKPublicNavigationLinks = ({ deviceState, cardImage, isActive }, context, className) => {
  const firstMenuItem = useRef(null);

  useEffect(() => {
    const { current } = firstMenuItem;
    if (isActive) {
      return current?.focus();
    }
    return undefined;
  }, [isActive]);

  return (
    <div className={className}>
      {deviceState > DeviceState.SM && deviceState <= DeviceState.XL && (
        <Row>
          <Link to={Pages.UK_PUBLIC_COMPARISON} innerRef={firstMenuItem}>
            <TextNew.Sans
              size={deviceState >= DeviceState.SM ? FontSizes.SM : FontSizes.XS}
              weight={FontWeight.LIGHT}
              localeId="ukPublicNavigationLinks.joinToday"
            />
          </Link>
        </Row>
      )}
      {deviceState <= DeviceState.SM && (
        <Row>
          <NavLink
            key={Pages.UK_PUBLIC_COMPARISON}
            to={Pages.UK_PUBLIC_COMPARISON}
            innerRef={firstMenuItem}
          >
            <TextNew.Sans
              size={deviceState >= DeviceState.SM ? FontSizes.SM : FontSizes.XS}
              type={deviceState > DeviceState.LG && TextTypes.FORMAL}
              weight={FontWeight.LIGHT}
              localeId="ukPublicNavigationLinks.joinToday"
            />
          </NavLink>

          <WebHostLink
            webHost={WebHost.ACCOUNT}
            link={GROUP_REGISTRATION_FIRST_STEP}
            className="nav-link"
          >
            <TextNew.Sans
              size={deviceState >= DeviceState.SM ? FontSizes.SM : FontSizes.XS}
              weight={FontWeight.LIGHT}
              localeId="ukPublicNavigationLinks.groupCard"
            />
            {deviceState <= DeviceState.SM && cardImage && (
              <ResponsiveImage cid="group-card" src={cardImage.src} alt={cardImage.alt} px={48} />
            )}
          </WebHostLink>

          <WebHostLink webHost={WebHost.ACCOUNT} link={Pages.LOGIN} className="nav-link">
            <TextNew.Sans
              size={deviceState >= DeviceState.SM ? FontSizes.SM : FontSizes.XS}
              weight={FontWeight.LIGHT}
              localeId="ukPublicNavigationLinks.signIn"
            />
          </WebHostLink>
        </Row>
      )}
      <Row>
        {UkPublicLinks.map(link => (
          <NavLink key={link.locale} href={link.href} to={link.to}>
            <TextNew.Sans
              size={deviceState >= DeviceState.SM ? FontSizes.SM : FontSizes.XS}
              type={deviceState > DeviceState.XL && TextTypes.FORMAL}
              localeId={`ukPublicNavigationLinks.${link.locale}`}
            />
          </NavLink>
        ))}
      </Row>
      {deviceState <= DeviceState.XL && (
        <Link className="our-world-link" href={PagesExternal.OUR_WORLD_LOGIN}>
          <TextNew.Sans
            size={deviceState >= DeviceState.SM ? FontSizes.SM : FontSizes.XS}
            weight={FontWeight.LIGHT}
            localeId="ukPublicNavigationLinks.consultantLogin"
          />
        </Link>
      )}
    </div>
  );
};

UKPublicNavigationLinks.propTypes = {
  deviceState: PropTypes.number,
  cardImage: PropTypes.object,
  isActive: PropTypes.bool,
};

export default withDeviceState()(
  withFunctionalClassName(
    ComponentType.MOLECULE,
    'UKPublicNavigationLinks',
  )(UKPublicNavigationLinks),
);
