import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import IcelandAdvertisement from './IcelandAdvertisement';

const compareGridItems = (offerGridLocation, lastGridColumnNumber) => {
  const numbers = [];
  const initialNumber = lastGridColumnNumber - 1;

  for (let i = initialNumber; i <= 100; i += lastGridColumnNumber) {
    numbers.push(i);
  }

  return numbers.includes(offerGridLocation);
};

const mapStateToProps = (state, { offerGridLocation }) => {
  const isThirdGridColumn = compareGridItems(offerGridLocation, 3);
  const isFourthGridColumn = compareGridItems(offerGridLocation, 4);

  return {
    isThirdGridColumn,
    isFourthGridColumn,
  };
};

const connected = connect(mapStateToProps);

export default compose(connected)(IcelandAdvertisement);
