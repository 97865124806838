import GlobalShades from '@slimming-world/ui-library-tokens/dist/variableTypes';

const Colors = {
  PRIMARY_DARK: 'primary-dark',
  PRIMARY_LIGHT: 'primary-light',
  LIGHT_TONE: 'light-tone',
  MID_TONE: 'mid-tone',
  DARK_TONE: 'dark-tone',
  BRAND: 'brand',
  CALLOUT: 'callout',
  SUCCESS: 'success',
  ERROR: 'error',
  TITLE_LIGHT_SWITCH: 'title-light-switch',
  TITLE_DARK_SWITCH: 'title-dark-switch',
  TRANSPARENT: 'transparent',
  SYNS: 'syns',
  FO_FREE_FOOD_BASE: 'fo-free-food-base',
  FO_FREE_FOOD_ONE: 'fo-free-food-one',
  FO_FREE_FOOD_TWO: 'fo-free-food-two',
  HEALTHY_EXTRA_A_BASE: 'healthy-extra-a-base',
  HEALTHY_EXTRA_A_ONE: 'healthy-extra-a-one',
  HEALTHY_EXTRA_A_TWO: 'healthy-extra-a-two',
  HEALTHY_EXTRA_B_BASE: 'healthy-extra-b-base',
  HEALTHY_EXTRA_B_ONE: 'healthy-extra-b-one',
  HEALTHY_EXTRA_B_TWO: 'healthy-extra-b-two',
  FO_SYNS_BASE: 'fo-syns-base',
  FO_SYNS_ONE: 'fo-syns-one',
  FO_SYNS_TWO: 'fo-syns-two',
  FO_RED_BASE: 'fo-red-base',
  FO_RED_ONE: 'fo-red-one',
  FO_RED_TWO: 'fo-red-two',
  PUBLIC_PURPLE_BASE: 'public-purple-base',
};

Colors.propTypes = Object.values(Colors);

export const INHERIT = 'INHERIT';

export default Colors;

export const AccentColors = {
  ACCENT_GREEN: 'accent-green',
  ACCENT_LIGHT_GREEN: 'accent-light-green',
  ACCENT_BLUE: 'accent-blue',
  ACCENT_PURPLE: 'accent-purple',
  ACCENT_PINK: 'accent-pink',
  ACCENT_RED: 'accent-red',
  ACCENT_GREY: 'accent-grey',
  ACCENT_CAMPAIGN: 'accent-campaign-accent',
  ACCENT_MUTED_CREAM: 'accent-muted-cream',
};

export const OnboarderColors = {
  RED: 'onboarder-red',
  RED_LIGHT: 'onboarder-red-light',
  ORANGE: 'onboarder-orange',
  ORANGE_LIGHT: 'onboarder-orange-light',
};

AccentColors.propTypes = Object.values(AccentColors);
OnboarderColors.propTypes = Object.values(OnboarderColors);

export const Shades = GlobalShades;
Shades.propTypes = Object.values(GlobalShades);
