import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import DataLayerKeys from 'common/src/app/data/enum/Tracking/DataLayerKeys';
import VideoPlayerParam from 'common/src/app/data/enum/VideoPlayerParam';
import IconName from 'common/src/app/data/enum/IconName';
import VideoPropTypes from 'common/src/app/util/proptypes/apiEntities/video';
import trackingShape from 'common/src/app/util/proptypes/apiEntities/tracking';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconButton from 'components/molecules/IconButton';

import './brightcove-video.scss';

const BrightcoveVideo = (
  { video, accountId, playerId, trackingData, hasCustomControls, trackVideoEvent },
  context,
  className,
  dataTestId,
) => {
  const [paused, setPaused] = useState(false);
  const [muted, setMuted] = useState(!!video[VideoPlayerParam.MUTED]);
  const playerRef = useRef();
  const videoId = video.videoId || video.id;

  const onSuccess = success => {
    const player = success.ref;
    const { category, action, label } = { ...{}, ...trackingData };

    // Disable video captions by default
    player.on('loadedmetadata', () => {
      const tracks = player.textTracks();

      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].mode === 'disabled') return;

        tracks[i].mode = 'disabled';
      }
    });

    // Fire the shared trackVideoEvent action on the first play of the video
    player.one('play', () => {
      trackVideoEvent(
        {
          label: videoId,
          action: 'video-views',
          ...(action && label ? { [action]: label } : {}),
          ...(category ? { [DataLayerKeys.CATEGORY]: category } : {}),
        },
        'play',
        'brightcove',
        videoId,
      );
    });
  };

  const togglePause = () => {
    const isPaused = playerRef.current.player.paused();

    setPaused(!isPaused);

    if (isPaused) {
      playerRef.current.player.play();
    } else {
      playerRef.current.player.pause();
    }
  };

  const toggleMute = () => {
    const isMuted = playerRef.current.player.muted();

    setMuted(!isMuted);

    if (isMuted) {
      playerRef.current.player.muted(false);
      playerRef.current.player.volume(1);
    } else {
      playerRef.current.player.muted(true);
    }
  };

  return (
    <div className={className} data-testid={dataTestId}>
      <ReactPlayerLoader
        accountId={accountId}
        playerId={playerId}
        videoId={videoId}
        onSuccess={onSuccess}
        ref={playerRef}
        options={{
          textTrackSettings: false,
        }}
      />

      {hasCustomControls && (
        <div className="button-container">
          <IconButton
            cid="play-state-button"
            icon={paused ? IconName.PLAY : IconName.PAUSE}
            onClick={togglePause}
            height={20}
            dataTestid="play-pause"
          />

          <IconButton
            icon={muted ? IconName.SOUND_OFF : IconName.SOUND_ON}
            onClick={toggleMute}
            height={18}
            cid="sound-button"
            dataTestid="sound"
          />
        </div>
      )}
    </div>
  );
};

BrightcoveVideo.propTypes = {
  ...VideoPropTypes,
  accountId: PropTypes.string.isRequired,
  playerId: PropTypes.string.isRequired,
  trackingData: PropTypes.shape(trackingShape),
  hasCustomControls: PropTypes.bool,
  trackVideoEvent: PropTypes.func.isRequired,
};

export default withFunctionalClassName(ComponentType.ATOM, 'BrightcoveVideo')(BrightcoveVideo);
