import {
  PLAY,
  REGISTER_ANIMATION,
  UNREGISTER_ANIMATION,
  RETRIGGER_ANIMATION,
} from '../../../actions/components/lottieActions';

const initialState = {
  animations: {},
  retrigger: true,
};

const lottie = (state = initialState, action) => {
  switch (action.type) {
    case PLAY: {
      const copy = { ...state };
      copy.animations[action.payload].play = true;
      return copy;
    }
    case REGISTER_ANIMATION:
      return {
        ...state,
        animations: {
          ...state.animations,
          ...action.payload,
        },
      };
    case UNREGISTER_ANIMATION: {
      const copy = { ...state };
      delete copy.animations[action.payload];
      return copy;
    }
    case RETRIGGER_ANIMATION:
      return {
        ...state,
        retrigger: action.payload,
      };
    default:
      return state;
  }
};

export default lottie;
