/* global 'atom','TextWithTitleBlock' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { ElementTypes } from '../../../../../atoms/TextNew';
import TextBlock from '../TextBlock';

const TextWithTitleBlock = ({ title, text }, context, className) => (
  <div className={className}>
    <TextNew element={ElementTypes.H3}>{title}</TextNew>
    <TextBlock text={text} />
  </div>
);

TextWithTitleBlock.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withFunctionalClassName('atom','TextWithTitleBlock')(TextWithTitleBlock);
