import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import withDeviceState, { QueryHelpers } from 'common/src/app/util/device-state/withDeviceState';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { offerRoundels } from 'common/src/app/config/market/market.configdefinitions';
import classNames from 'classnames';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import Wrapper from 'components/atoms/Wrapper';
import BackLink from 'components/molecules/BackLink';
import TextNew, { AccentColors, FontSizes, ElementTypes } from 'components/atoms/TextNew/TextNew';
import CurrentOfferRoundel from 'components/molecules/CurrentOfferRoundel';
import GroupBulletin from '../../../../../molecules/GroupBulletin';

import './nearest-group-landing-header.scss';

const NearestGroupLandingHeader = (
  { query: { query, lat, lng }, shouldShowIntro, queryHelper, deviceState, bulletin, subText },
  context,
  className,
) =>
  shouldShowIntro && (
    <div className={classNames(className, { 'disable-roundel': !offerRoundels })}>
      {deviceState <= DeviceState.SM && <BackLink />}
      {bulletin && <GroupBulletin text={bulletin} />}
      <Wrapper cid="nearest-group-landing-header-wrapper" width="md">
        <div className="intro-wrapper">
          <h1 className="search-query">
            <TextNew.Sans.SM
              localeId="nearestGroupLandingHeader.resultsFor"
              element={ElementTypes.SPAN}
            />
            {!query && lat && lng && (
              <TextNew.Serif
                size={queryHelper === QueryHelpers.TABLET ? FontSizes.LG : FontSizes.XL}
                localeId="nearestGroupLandingHeader.yourLocation"
                cid="your-location"
                element={ElementTypes.SPAN}
              />
            )}
            {query && (
              <TextNew.Serif.XL cid="query" element={ElementTypes.SPAN}>
                {query.toUpperCase()}
              </TextNew.Serif.XL>
            )}
          </h1>
          {subText && <RichTextBlock cid="heading-intro-text" text={subText} />}
        </div>
        {deviceState > DeviceState.SM && (
          <CurrentOfferRoundel backgroundColor={AccentColors.ACCENT_PINK} />
        )}
      </Wrapper>
    </div>
  );

NearestGroupLandingHeader.propTypes = {
  handleSubmit: PropTypes.func,
  results: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  query: PropTypes.object,
  reset: PropTypes.func,
  bulletin: PropTypes.string,
  queryHelper: PropTypes.string,
  subText: PropTypes.string,
};

export default withDeviceState()(
  withFunctionalClassName(
    ComponentType.ORGANISM,
    'NearestGroupLandingHeader',
  )(NearestGroupLandingHeader),
);
