import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import Size from 'common/src/app/data/enum/Size';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import Roundel from 'components/atoms/Roundel';
import Button from 'components/atoms/Button';
import TextNew, { Colors, ElementTypes, FontWeight } from 'components/atoms/TextNew';

import classNames from 'classnames';

import './registration-cta-with-optional-roundel-and-title.scss';

const RegistrationCTAWithOptionalRoundelAndTitle = (
  { introText, roundelText, titleText, buttonLeft, buttonRight, deviceState },
  context,
  className,
  dataTestid,
) => (
  <section className={className} data-testid={dataTestid}>
    <div
      className={classNames('wrapper', {
        'has-roundel': roundelText,
      })}
    >
      {introText && (
        <div className="intro-text">
          <RichTextBlock text={introText} />
          {deviceState >= DeviceState.MD && roundelText ? (
            <Roundel backgroundColor={Colors.HEALTHY_EXTRA_B_ONE} size={Size.LG}>
              <TextNew.Serif.SM
                color={Colors.PRIMARY_LIGHT}
                weight={FontWeight.BOLD}
                dataTestid="roundel-text"
              >
                {roundelText}
              </TextNew.Serif.SM>
            </Roundel>
          ) : null}
        </div>
      )}
    </div>
    <div className="cta-container">
      <div className="cta-wrapper">
        {titleText && (
          <div className="title-wrapper">
            <TextNew.Serif.SM
              weight={FontWeight.BOLD}
              color={Colors.PRIMARY_LIGHT}
              element={ElementTypes.H3}
              dataTestid="title-text"
            >
              {titleText}
            </TextNew.Serif.SM>
          </div>
        )}

        <Button isOnBackgroundColor href={buttonLeft.link}>
          <TextNew.Sans.SM
            element={ElementTypes.SPAN}
            weight={FontWeight.BOLD}
            dataTestid="button-left"
          >
            {buttonLeft.label}
          </TextNew.Sans.SM>
        </Button>

        <Button isGhostLight to={buttonRight.link}>
          <TextNew.Sans.SM
            color={Colors.PRIMARY_LIGHT}
            element={ElementTypes.SPAN}
            weight={FontWeight.BOLD}
            dataTestid="button-right"
          >
            {buttonRight.label}
          </TextNew.Sans.SM>
        </Button>
      </div>
    </div>
  </section>
);

RegistrationCTAWithOptionalRoundelAndTitle.propTypes = {
  introText: PropTypes.string,
  roundelText: PropTypes.string,
  titleText: PropTypes.string,
  deviceState: PropTypes.number,
  buttonRight: PropTypes.object,
  buttonLeft: PropTypes.object,
};

export default withDeviceState()(
  withFunctionalClassName(
    ComponentType.MOLECULE,
    'RegistrationCTAWithOptionalRoundelAndTitle',
  )(RegistrationCTAWithOptionalRoundelAndTitle),
);
