import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import Science from 'bundle-loader?lazy&reactHot&name=Science!./index';

const routes = (
  <Route
    title="The Science behind Slimming World | Find out more"
    description="Slimming World is based on a deep understanding of the psychology of slimming, giving you the best way to lose weight and stay slim for life"
    path={Pages.UK_PUBLIC_SCIENCE}
    {...getRouteComponentProp(Science)}
  />
);

export default routes;
