import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { openModal } from 'common/src/app/actions/components/modalActions';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import Colors from 'common/src/app/data/enum/Colors';
import Wrapper from '../../atoms/Wrapper';
import * as ContactUsForm from '../../organisms/ContactUsForm';
import HandleErrorComponent from './HandleErrorComponent';

const mapDispatchToProps = (dispatch, { componentName }) => ({
  openContactFormModal: () =>
    dispatch(
      openModal(
        ModalNames.CONTENT,
        {
          showWrapperCloseButton: false,
          children: (
            <Wrapper width="ls" background={Colors.PRIMARY_LIGHT} padding="lg" paddingBottom="lg">
              <ContactUsForm.Public
                errorBoundaryData={{ location: window.location.href, componentName }}
              />
            </Wrapper>
          ),
        },
        false,
        false,
        true,
      ),
    ),
});

const connected = connect(null, mapDispatchToProps);

export default compose(connected)(HandleErrorComponent);
