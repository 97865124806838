import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { Colors, FontWeight, ElementTypes, TextTypes } from 'components/atoms/TextNew';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import errorBoundary from 'components/hoc/ErrorBoundary';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import RichText from 'components/atoms/RichText';
import Wrapper from 'components/atoms/Wrapper';

import successStoriesShape from 'common/src/app/util/proptypes/apiEntities/successStories';

import { headerAssetsShape, groupOnlineShape } from './propTypes/groupOnlineComparisonPropTypes';

import RelatedSuccessStories from '../SuccessStories/components/molecules/RelatedSuccessStories';
import GroupOrOnlineContentWrapper from './components/organisms/GroupOrOnlineContentWrapper';

import './group-online-comparison.scss';

const GroupOnlineComparison = (
  {
    deviceState,
    headerAssets,
    title,
    subtitle,
    introduction,
    group,
    online,
    outro,
    disclaimerTitle,
    disclaimerText,
    successStories,
  },
  context,
  className,
) => {
  // Select the correct header image
  const headerSelector = () => {
    if (!headerAssets) return null;

    if (deviceState >= DeviceState.XL) {
      return headerAssets?.ultraWideHeaderImage;
    } else if (deviceState >= DeviceState.LG) {
      return headerAssets?.desktopHeaderImage;
    } else if (deviceState > DeviceState.MD) {
      return headerAssets?.tabletHeaderImage;
    } else if (deviceState > DeviceState.SM) {
      return headerAssets?.largeMobileHeaderImage;
    }
    return headerAssets?.smallMobileHeaderImage;
  };

  return (
    <div className={className}>
      <HeroBarPublic image={headerSelector() || headerAssets?.desktopHeaderImage}>
        <TextNew.Serif.SM
          cid="hero-bar-public-serif-heading"
          element={ElementTypes.H1}
          weight={FontWeight.BOLD}
        >
          {title}
        </TextNew.Serif.SM>

        {subtitle && (
          <TextNew.Script.SM
            cid="hero-bar-public-script-heading"
            color={Colors.BRAND}
            element={ElementTypes.H2}
          >
            {subtitle}
          </TextNew.Script.SM>
        )}
        <TextNew.Serif.XS cid="hero-bar-public-introduction">{introduction}</TextNew.Serif.XS>
      </HeroBarPublic>

      {group && online && <GroupOrOnlineContentWrapper group={group} online={online} />}

      <Wrapper.MD>
        <RichText text={outro} type={TextTypes.PRIMARY_ELEGANT} />

        {disclaimerTitle && <TextNew.Sans.SM>{disclaimerTitle}</TextNew.Sans.SM>}
        {disclaimerText && (
          <TextNew.Sans.XS weight={FontWeight.LIGHT}>{disclaimerText}</TextNew.Sans.XS>
        )}
      </Wrapper.MD>

      <RelatedSuccessStories successStories={successStories} />
    </div>
  );
};

GroupOnlineComparison.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  introduction: PropTypes.string.isRequired,
  headerAssets: PropTypes.shape({
    ...headerAssetsShape,
  }).isRequired,
  successStories: PropTypes.arrayOf(PropTypes.shape({ ...successStoriesShape })),
  group: PropTypes.shape({ ...groupOnlineShape }).isRequired,
  online: PropTypes.shape({ ...groupOnlineShape }).isRequired,
  outro: PropTypes.string.isRequired,
  disclaimerTitle: PropTypes.string,
  disclaimerText: PropTypes.string,
  deviceState: PropTypes.number,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName(ComponentType.PAGE, 'GroupOnlineComparison'),
)(GroupOnlineComparison);
