import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import debugLib from 'debug';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import { isIEOrEdge } from 'common/src/app/util/browserUtil';
import * as imageSizeUtils from 'common/src/app/util/imageSizeUtils';
import cancellableCallback from 'common/src/app/util/cancellableCallback';
import Breakpoints from 'common/src/app/data/enum/Breakpoints';
import Loader from '../../atoms/Loader';

import './responsive-image.scss';

if (typeof window !== 'undefined' && isIEOrEdge()) {
  // todo: use import() instead of require() when eslint is updated (ticket MEMB-741)
  require('picturefill'); // eslint-disable-line global-require
  require('picturefill/dist/plugins/mutation/pf.mutation'); // eslint-disable-line global-require
}

const debug = debugLib('SlimmingWorld:Image');

/**
 * The margin around the visible viewport that is used to detect if in image is currently in
 * view. For example, 50% means it is "in viewport" when the distance between the component and
 * the viewport is less than 50% of the page height.
 * @type {string}
 */
const IN_VIEWPORT_MARGIN = '50%';

/**
 * Component to display a responsive image. Should only be used to display images as delivered
 * by our content API. More specifically, images that can be cropped by adding `width` and
 * `height` parameters to the query string in the request url.
 *
 * ## Sizing props
 * Some props added to this component relate to the width of the image (`vw`, `px`, `size` and
 * `breakpoints`). These props have the following purpose:
 *  - Helps this component to determine which size variations to provide to the browser in the
 *  `<img>` `srcset` attribute.
 *  - Helps the browser to estimate what the size of the image is going to be before styling is
 *  applied to this element. This is done by adding a `sizes` attribute to the `<img>` tag.
 *  - __Does not determine what the actual size of the image is going to be once rendered__. Only
 *  used to request an image resource with the optimal size.
 */
class ResponsiveImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: props.showLoader,
      deferImageLoad: !props.immediatelyInViewport,
    };
  }

  componentDidMount() {
    if ('IntersectionObserver' in window && this.wrappingDiv && !this.props.immediatelyInViewport) {
      const imageName = (this.props.src || '').replace(/^.+\//, '').replace(/\?.+$/, '');
      debug(`Deferring image load until it is in viewport: ${imageName}`);

      this.showImageCallback = cancellableCallback(() => {
        if (this.intersectionObserver) {
          this.intersectionObserver.disconnect();
          this.intersectionObserver = null;
        }

        debug(`Image in viewport: "${imageName}"`);
        this.setState({ deferImageLoad: false });
      });

      this.intersectionObserver = new IntersectionObserver(
        ([{ isIntersecting }]) => {
          if (isIntersecting) {
            this.showImageCallback();
          }
        },
        {
          root: null,
          rootMargin: IN_VIEWPORT_MARGIN,
          threshold: 0,
        },
      );

      this.intersectionObserver.observe(this.wrappingDiv);
    } else if (this.state.deferImageLoad) {
      this.setState({ deferImageLoad: false });
    }
  }

  componentWillUnmount() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
      this.intersectionObserver = null;
    }

    if (this.showImageCallback) {
      this.showImageCallback.cancel();
    }
  }

  getSources = (src, { vw, px, size, ratio, breakpoint }) => {
    let srcSet = null;
    let srcSetWebp = null;
    let fallback = null;
    let fallbackWebp = null;
    let sizes = null;
    let mediaQuery = null;

    if (!isNaN(breakpoint)) {
      mediaQuery = `(max-width: ${breakpoint}px)`;
    }

    if (size || vw) {
      ({ fallback, srcSet, srcSetWebp } = imageSizeUtils.getSrcSetFromVariants(
        src,
        imageSizeUtils.filterSimilarVariants(imageSizeUtils.getViewportRelativeVariants(vw / 100)),
        ratio,
      ));
      sizes = size || `${vw}vw`;
      if (px) {
        debug(
          'Both fixed width (px) and fluid width (size and/or vw) props passed to <Image />. Ignored fixed width.',
        );
      }
    } else if (px) {
      fallback = imageSizeUtils.getVariantUrl({ url: src, width: px, ratio, useWebp: false });
      fallbackWebp = imageSizeUtils.getVariantUrl({
        url: src,
        width: px,
        ratio,
        useWebp: this.props.webpFallback,
      });
    } else {
      ({ fallback, srcSet, srcSetWebp } = imageSizeUtils.getSrcSetFromVariants(
        src,
        imageSizeUtils.getViewportRelativeVariants(),
        ratio,
      ));
    }

    return { srcSet, srcSetWebp, fallback, fallbackWebp, sizes, mediaQuery };
  };

  getImage = () => {
    const { breakpoints, src, alt, vw, ratio, size, px, itemProp } = this.props;
    if (breakpoints) {
      return (
        <picture>
          {Object.keys(breakpoints).map(breakpointNameOrValue => {
            let breakpoint;
            if (Breakpoints[breakpointNameOrValue]) {
              breakpoint = Breakpoints[breakpointNameOrValue];
            } else {
              breakpoint = breakpointNameOrValue;
            }
            const {
              srcSet,
              srcSetWebp,
              fallback,
              fallbackWebp,
              sizes,
              mediaQuery,
            } = this.getSources(src, {
              vw,
              px,
              size,
              ratio,
              breakpoint,
              ...breakpoints[breakpointNameOrValue],
            });
            return (
              <Fragment key={breakpointNameOrValue}>
                {srcSetWebp && this.props.webpFallback && (
                  <source
                    type="image/webp"
                    key={`${breakpointNameOrValue}-webp`}
                    srcSet={srcSetWebp}
                    sizes={sizes}
                    media={mediaQuery}
                  />
                )}
                {fallbackWebp && (
                  <source
                    type="image/webp"
                    key={`${breakpointNameOrValue}-webp-fallback`}
                    srcSet={fallbackWebp}
                    sizes={sizes}
                    media={mediaQuery}
                  />
                )}
                {srcSet && (
                  <source
                    key={breakpointNameOrValue}
                    srcSet={srcSet}
                    sizes={sizes}
                    media={mediaQuery}
                  />
                )}
                {fallback && (
                  <source key={'fallback'} srcSet={fallback} sizes={sizes} media={mediaQuery} />
                )}
              </Fragment>
            );
          })}
          <img draggable="false" alt={alt || ''} srcSet={src} />
        </picture>
      );
    }

    const { srcSet, fallback, sizes } = this.getSources(src, this.props);

    return (
      <img
        alt={alt || ''}
        srcSet={srcSet}
        src={fallback || src}
        sizes={sizes}
        onLoad={this.handleImageLoaded}
        itemProp={itemProp}
      />
    );
  };

  getCloudinaryImage = () => {
    const { hasWebpSupport, cloudinarySrc, cloudinaryCropType, px, ratio, alt } = this.props;
    const cloudinaryOptions = {
      url: cloudinarySrc,
      useWebp: hasWebpSupport,
      width: px,
      ratio,
      cropType: cloudinaryCropType,
    };

    return (
      <img
        type={`image${hasWebpSupport ? '/webp' : ''}`}
        alt={alt || ''}
        src={imageSizeUtils.getCloudinaryVariants(cloudinaryOptions)}
        onLoad={this.handleImageLoaded}
      />
    );
  };

  registerWrappingDiv = div => (this.wrappingDiv = div);

  handleImageLoaded = () => this.setState({ showLoader: false });

  render() {
    const { showLoader, deferImageLoad } = this.state;
    const { cloudinarySrc } = this.props;
    const getAsset = cloudinarySrc ? this.getCloudinaryImage() : this.getImage();

    return (
      <div {...componentClassNameProp(ComponentType.ATOM, this)} ref={this.registerWrappingDiv}>
        {showLoader && <Loader />}
        {(this.props.src || !showLoader) && !deferImageLoad ? getAsset : null}
      </div>
    );
  }
}

ResponsiveImage.defaultProps = {
  showLoader: false,
  webpFallback: true,
  immediatelyInViewport: false,
};

// disable unused proptypes rule because eslint does not understand dynamic prop access
/* eslint-disable react/no-unused-prop-types */
ResponsiveImage.propTypes = {
  /**
   * The source url of the image. This should not include the `width` and `height` query parameters,
   * these will be added dynamically by this component.
   */
  src: PropTypes.string,
  /**
   * Hint to what the size of the image is going to be in percentage of the viewport width. This
   * is an estimate to request the most optimal image size. Does not have to match the actual
   * rendered size exactly.
   */
  vw: PropTypes.number,
  /**
   * If set, indicates that this image will always be displayed at a fixed width in px. This will
   * cause this component not to use the responsive `srcset` and `sizes` attributes, but rather
   * set a single `src` with a fixed size. To also have a fixed height, include the "ratio" prop.
   */
  px: PropTypes.number,
  /**
   * If set, is passed to the `sizes` attribute of the img tag to hint to the browser what the
   * size of the image is going to be. This can be any CSS size value, such as:
   *  - `10rem`
   *  - `calc(80vw - 300px)`
   *
   * When using this prop, image variants that will be passed to `srcset` are optimized for
   * 100% of the viewport width. To optimize for a different size, also pass a value to the
   * "vw" prop. For example:
   * ```
   * <Image ... vw={80} size="calc(80vw - 30px)" />
   * ```
   * The above example indicates to the browser that the image width will be `80vw - 30px`. The
   * set of images variants attached are optimized for 80% of the browser's viewport width.
   */
  size: PropTypes.string,
  /**
   * If set, will request an image from the content api with a fixed aspect ratio. This value should
   * equal width divided by height. For example: `ratio={16 / 9}` indicates a 16:9 aspect ratio.
   *
   * This does NOT determine the actual height of this component once rendered (this height is
   * determined by styling). It only affects the height of the image resource requested from
   * the content api.
   */
  ratio: PropTypes.number,
  /**
   * An map containing sizing properties per breakpoint.
   *
   *  - The keys of this map should be one of the breakpoints (`XS`, `SM`, `MD`, `LG`, `XL`
   *  or `XXL`), number (336, 2034) for a unique breakpoint size,  or `other` for a
   *  catch-all when none of the breakpoints match.
   *  - The properties of this map should be objects that contain some of the sizing props (`vw`,
   *  `px`, `size`, `ratio`) for that breakpoint.
   *  - Each breakpoint increases the HTML output of this component. Use as little breakpoints
   *  as possible (only at points where the design actually 'breaks').
   *  - Any of the sizing props (`vw`, `px`, `size`, `ratio`) outside of this `breakpoints` map
   *  these will be applied at every breakpoint.
   */
  breakpoints: PropTypes.objectOf(
    PropTypes.shape({
      vw: PropTypes.number,
      px: PropTypes.number,
      size: PropTypes.string,
      ratio: PropTypes.number,
    }),
  ),
  /**
   * If true, will not use IntersectionObserver to wait until the component is scrolled into
   * view but will load the image immediately. Use this if you're sure that the image is not
   * rendered below the fold.
   */
  immediatelyInViewport: PropTypes.bool,
  /**
   * The string to set as alt text on the image.
   */
  alt: PropTypes.string,
  showLoader: PropTypes.bool,
  cloudinarySrc: PropTypes.string,
  /**
   * Possiable param when using cloudinary.
   * See https://cloudinary.com/documentation/image_transformations for options
   */
  cloudinaryCropType: PropTypes.string,
  hasWebpSupport: PropTypes.bool,
  webpFallback: PropTypes.bool,
  /**
   * Schema.org attribute to support Pinterest rich pins
   */
  itemProp: PropTypes.string,
};

export default ResponsiveImage;
