import { withInitAction } from '@mediamonks/react-redux-component-init';
import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { API_COMPONENT_MAGAZINE_COMPETITION_BACKGROUND } from 'common/src/app/data/apiComponents';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';

import MagazineCompetitions from './MagazineCompetitions';

const mapStateToProps = state => ({
  headerImage: contentComponentSelector(
    state,
    API_COMPONENT_MAGAZINE_COMPETITION_BACKGROUND,
    'image',
  ),
});

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(API_COMPONENT_MAGAZINE_COMPETITION_BACKGROUND)),
});

const connected = connect(mapStateToProps, null);

export default compose(addInitAction, connected)(MagazineCompetitions);
