import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import './radio-button.scss';

const RadioButton = ({ name, label, ...props }) => (
  <label className="radio-button-new">
    <input name={name} type="radio" {...props} />
    {label && <TextNew.PrimaryElegant element={ElementTypes.SPAN}>{label}</TextNew.PrimaryElegant>}
  </label>
);

RadioButton.propTypes = { name: PropTypes.string, label: PropTypes.string };

export default withFunctionalClassName(ComponentType.ATOM, 'RadioButton')(RadioButton);
