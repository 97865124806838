import { ONLY_SINGLE_DIGITS } from 'common/src/app/data/regexPatterns';

/**
 * Converts values from the HeightImperialFieldGroup input into a number value in meters
 * @param {object} formValues
 * @param {string} formValues.feet A string that contains an integer representing
 * the amount of feet
 * @param {string} formValues.inches A string that contains an integer representing
 * the amount of inches
 *
 * @returns {number} The resulting number value in centimeters
 */
export const composeHeightImperialToMetric = ({ feet, inches, halfInch }) => {
  if (typeof feet === 'undefined' && typeof inches === 'undefined') {
    return feet;
  }

  const parsedFeet = feet ? parseInt(feet, 10) : 0;
  const parsedInches = inches ? parseInt(inches, 10) : 0;
  const parsedHalfInch = halfInch ? parseFloat(halfInch) : 0;

  const totalInches = parsedFeet * 12 + parsedInches + parsedHalfInch;

  // convert to cm
  return totalInches * 2.54;
};

/**
 * Converts values from cm to feet/inches
 * @param {string} centimeters A string that contains an integer representing
 *
 * @returns {object} The resulting object should be { feet, inches }
 */
export const decomposeHeightImperialToMetric = centimeters => {
  if (typeof centimeters === 'undefined') {
    return { feet: '', inches: '' };
  }
  const parsedCentimeters = centimeters || 0;

  const wholeInches = parsedCentimeters / 2.54;
  const parsedFeet = Math.floor(wholeInches / 12);
  const parsedInches = Math.round(wholeInches % 12);

  return {
    feet: parsedFeet,
    inches: parsedInches,
  };
};

/**
 * Converts values from the HeightMetricFieldGroup input into a number value in centimeters
 * @example User entry: 1.3, value: 130cm
 * @example User entry: 1.33, value: 133cm
 * @param {object} formValues
 * @param {string} formValues.meters A string (that contains an integer) or an integer representing
 * the amount of meters
 * @param {string} formValues.centimeters A string (that contains an integer) or an integer representing
 * the amount entered into the centimeters box
 * @returns {number} The resulting number value in centimeters
 *
 * @example
 */

const setCentimeters = centimeters => {
  // checking single digit and converting to 10cm e.g. 5 to 50
  if (ONLY_SINGLE_DIGITS.test(centimeters)) {
    return centimeters * 10;
  }

  return centimeters;
};

export const composeHeightMetric = ({ metres = 0, centimeters = 0 }) => {
  // Convert meters to a number - we don't do the same with string as we want leading 0s sometimes
  // so we don't process 07cm as 7cm and in turn convert it to 70cm because it's a single digit
  const metersNumber = Number(metres);

  return metersNumber * 100 + Number(setCentimeters(centimeters));
};

/**
 * Converts values from centimeters to { meters: 0, centimeters: 0 }
 * @param {string} centimeters A string that contains an integer representing
 * the amount of centimeters
 *
 * @returns {object} The resulting number value in { meters: 0, centimeters: 0 }
 */
export const decomposeHeightMetric = centimeters => {
  if (typeof centimeters === 'undefined') {
    return { metres: '', centimeters: '' };
  }
  const parsedCentimeters = centimeters || 0;

  const parsedMeters = Math.floor(parsedCentimeters / 100);
  const wholeCentimeters = Math.round(parsedCentimeters % 100);

  return {
    metres: parsedMeters,
    centimeters: wholeCentimeters,
  };
};
