const MeasureUnit = {
  IMPERIAL: 0,
  METRIC: 1,
};

export const MeasureUnitSpring = {
  IMPERIAL: `0`,
  METRIC: `1`,
};

export default MeasureUnit;
