import React from 'react';
import Link from 'react-router/lib/Link';
import { onlineMemberSupportPhoneNumber } from 'common/src/app/config/market/market.configdefinitions';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import { Department } from 'common/src/app/data/enum/DepartmentPhoneNumbersInfo';
import OpeningTimes from '../OpeningTimes';

import TextNew, { FontWeight, Colors, ElementTypes } from '../TextNew';

import './contact-us-online-member-support.scss';

const ContactUsOnlineMemberSupport = ({}, context, className, dataTestid) => (
  <div className={className} data-testid={dataTestid}>
    <TextNew.Serif.SM
      cid="contact-section-title"
      color={Colors.BRAND}
      localeId="contactUsOnlineMemberSupport.title"
    />

    <TextNew.Sans.SM localeId="contactUsOnlineMemberSupport.intro.digitalSupport" />

    <OpeningTimes department={Department.ONLINE_MEMBER_SUPPORT} />

    {/* Call us section */}

    <TextNew.Sans.SM
      cid="contact-section-title"
      color={Colors.BRAND}
      localeId="contactUsOnlineMemberSupport.contactUsCallUs.header"
    />

    <TextNew.Sans.SM
      localeId="contactUsOnlineMemberSupport.contactUsCallUs.body"
      weight={FontWeight.LIGHT}
      localeParams={{
        PHONE_NUMBER: (
          <Link className="phone-number-link" href={`tel:${onlineMemberSupportPhoneNumber}`}>
            <TextNew.Sans.SM
              weight={FontWeight.LIGHT}
              color={Colors.BRAND}
              element={ElementTypes.SPAN}
            >
              {onlineMemberSupportPhoneNumber}
            </TextNew.Sans.SM>
          </Link>
        ),
      }}
    />
  </div>
);

export default withFunctionalClassName(
  ComponentType.ATOM,
  'ContactUsOnlineMemberSupport',
)(ContactUsOnlineMemberSupport);
