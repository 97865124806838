export const schemaUrl = 'https://schema.org/';

export const FaqSeo = {
  SCHEMA: {
    FAQ_PAGE: 'FAQPage',
    ANSWER: 'Answer',
    QUESTION: 'Question',
  },
  ITEM_PROP: {
    NAME: 'name',
    TEXT: 'text',
    MAIN_ENTITY: 'mainEntity',
    ACCEPTED_ANSWER: 'acceptedAnswer',
  },
};
