import * as ValidateOn from '../enhanced-redux-form/data/ValidateOn';
import { EXTRACT_BOTH_PARTS_OF_UK_POSTCODE_REGEX } from '../data/regexPatterns';
import { createRequiredRule } from './validationUtils';
import CountryPostalCode from '../data/enum/CountryPostalCode';
import { countryCodeValue } from '../data/enum/Countries';

export const isValidPostcodeFormat = value => {
  if (value === undefined) {
    return true;
  }
  const match = (value || '').match(EXTRACT_BOTH_PARTS_OF_UK_POSTCODE_REGEX);
  return match && match.length === 3;
};

/*
 * The countryCode paramater is used to drive the validation messages field name:
 * e.g. postcode, zipcode or eircode
 */
export const createPostcodeRule = (fieldName, formName = 'validation', countryCode) => ({
  rule: isValidPostcodeFormat,
  message: {
    locale: `${formName}.errors.${fieldName}.formatError`,
    params: {
      POSTAL_CODE: () => CountryPostalCode[countryCodeValue[countryCode]] || 'Postcode',
    },
  },
});

/*
 * Simple helper function to create a complete 'required' validation config,
 * when there is no other validations needed on the field.
 * The countryCode paramater is used to drive the validation messages field name:
 *  e.g. postcode, zipcode or eircode
 */
const createPostcodeValidation = ({ fieldName, validateOn, formName, countryCode }) => ({
  [fieldName]: {
    validators: [
      createRequiredRule(fieldName),
      createPostcodeRule(fieldName, formName, countryCode),
    ],
    validateOn: validateOn || [ValidateOn.BLUR],
  },
});

export default createPostcodeValidation;
