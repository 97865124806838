import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import SevenDayMenu from 'bundle-loader?lazy&reactHot&name=SevenDayMenu!./index';

const routes = (
  <Route
    path={Pages.UK_PUBLIC_SEVEN_DAY_MENU}
    {...getRouteComponentProp(SevenDayMenu)}
    title="Free 7-Day Menu | {pageTitle}"
  />
);

export default routes;
