import PropTypes from 'prop-types';

export default PropTypes.shape({
  gridColumnCount: PropTypes.number,
  pagination: PropTypes.shape({
    after: PropTypes.shape({
      atEnd: PropTypes.bool,
      count: PropTypes.number,
    }),
    before: PropTypes.shape({
      atBegin: PropTypes.bool,
      count: PropTypes.number,
    }),
    hasMore: PropTypes.bool,
    limit: PropTypes.number,
    offset: PropTypes.number,
    total: PropTypes.string,
  }),
  results: PropTypes.array,
});
