/**
 * Recipe additional time suffix
 */
const RecipeAdditionalTimSuffix = [
  'Standing',
  'Chilling',
  'Cooling',
  'Marinating',
  'Freezing',
  'Setting time',
  'Overnight freezing',
  'Overnight chilling',
  'Cooling and chilling',
  'Cooling and freezing',
  'Marinating and standing',
  'Soaking',
  'Soaking overnight',
  'Overnight marinating',
];

export default RecipeAdditionalTimSuffix;
