/**
 * These data attributes overides are used in certain contexts when creating
 * cypress tests. Please see
 https://slimmingworlddigital.atlassian.net/wiki/spaces/DT/pages/1024458768/End-to-end+testing
 * for more info
 */

const DataTestidOverides = {
  LOGO: 'logo',
  PUBLIC_NAVIGATION_BAR: 'public-navigation-bar',
  LEGACY_GROUP_REGISTER_LINK: 'legacy-group-register-link',
  PUBLIC_COUNTRY_SELECT: 'public-country-select',
  PUBLIC_SIGNIN_LINK: 'public-signin-link',
  PUBLIC_JOIN_BUTTON: 'public-join-button',
  // Landing grid
  LOAD_MORE: 'load-more',
  // Recipe Landing
  HEADER_INTRO_TEXT: 'header-intro-text',
  SEARCH_INTRO_TEXT: 'search-intro-text',
  // Grid size filter
  GRID_CONTROL_THREE: 'grid-control-three',
  GRID_CONTROL_FOUR: 'grid-control-four',
  // Public horizontal recipes search bar
  RECIPE_SEARCH_INPUT: 'recipe-search-input',
  COOKIE_CONTINUE: 'cookie-continue',
  GROUP_SEARCH_BAR_BUTTON: 'group-search-bar-button',
  GROUP_SEARCH_BAR: 'group-search-bar',
  // Group search intro
  GROUP_SEARCH_EMPTY_QUERY_TITLE: 'empty-query-title',
  GROUP_SEARCH_EMPTY_QUERY_INTRO: 'empty-query-intro',
  // Group search box
  GROUP_LANDING_SEARCH_BOX: 'group-landing-search-box',
  GROUP_LANDING_SEARCH_BOX_ICON: 'icon-svg',
  // Group search form
  GROUP_SEARCH_FORM_ERROR_MESSAGE: 'stacked-form-error-message',
  GROUP_SEARCH_FORM_TERM: 'group-search-term',
  // Group search tile
  GROUP_SEARCH_TITLE_LINK: 'group-search-title-link',
  GROUP_SEARCH_TIMETABLE: 'group-search-timetable',
  // Group search intro section
  GROUP_SEARCH_OFFER_INTRO: 'group-search-offer-intro',
  GROUP_SEARCH_OFFER_LINK: 'group-search-offer-link',
  // Group search filters
  GROUP_SEARCH_FILTER_BUTTON: 'group-search-filter-button',
  GROUP_SEARCH_FILTER_DAY: 'group-search-filter-day',
  GROUP_SEARCH_FILTER_TIME: 'group-search-filter-time',
  GROUP_SEARCH_FILTER_CLEAR: 'group-search-filter-clear',
  GROUP_SEARCH_TIME_LABEL: 'group-search-time-label',
};

export default DataTestidOverides;
