import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { getPublicDetailSuccessStories } from 'common/src/app/actions/resources/publicActions';
import { makeEntitySelector } from 'common/src/app/reducers/view/entityViewReducer';
import SuccessStoriesDetail from './SuccessStoriesDetail';

const mapStateToProps = state => {
  const currentRef = state.view.pages.successStoriesDetail?.successStory;
  const entitySelector = makeEntitySelector();
  return {
    successStory: entitySelector(state, currentRef) || {},
  };
};

const connector = connect(mapStateToProps);

const addInitAction = withInitAction(['params.slug'], {
  prepared: ({ params: { slug } }, dispatch) => dispatch(getPublicDetailSuccessStories(slug)),
});

export default compose(addInitAction, connector)(SuccessStoriesDetail);
