import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { API_STATIC_PUBLIC_ICELAND_OUR_RANGE } from 'common/src/app/data/apiStatics';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { getFoodRangeCategories } from 'common/src/app/actions/resources/foodRangeActions';
import { foodRangeProductsSelector } from 'common/src/app/selectors/foodRangeSelectors';
import { syncValuesToRoute } from 'common/src/app/enhanced-redux-form/actions/formRouteSyncActions';
import FormNames from 'common/src/app/data/enum/FormNames';
import FoodRangeFlags, { FoodRangeFlagsBitwise } from 'common/src/app/data/enum/FoodRangeFlags';
import { availableFlags, toArray, toBitwise } from 'common/src/app/util/bitwiseUtils';
import IcelandOurRange from './IcelandOurRange';

const EMPTY_OBJECT = {};

const haveSameProperties = (initialValues, values) =>
  ['categories', 'foodRangeDiet'].every(
    field =>
      initialValues?.[field] === values[field] ||
      (!initialValues?.[field] && values[field] === null),
  );

const enhanced = withFormik({
  displayName: FormNames.PUBLIC_ICELAND_OUR_RANGE,
  mapPropsToValues: ({ location }) => {
    const query = location?.query;

    return {
      categories: query?.categories ? query.categories.split(',') : [],
      foodRangeDiet: query?.foodRangeDiet
        ? toArray(FoodRangeFlagsBitwise, query?.foodRangeDiet)
        : [],
    };
  },
  handleSubmit: (
    { categories: categoriesArray, foodRangeDiet: foodRangeDietArray },
    { setSubmitting, props },
  ) => {
    const values = {
      categories: categoriesArray ? categoriesArray.join(',') : [],
      foodRangeDiet: foodRangeDietArray ? toBitwise(foodRangeDietArray, FoodRangeFlagsBitwise) : [],
    };

    if (haveSameProperties(props.initialValues, values)) {
      setSubmitting(false);
      return;
    }

    props.updateUrl(values);

    setSubmitting(false);
  },
});

const mapStateToProps = (state, { location: { query } }) => {
  const pageData = staticPageSelector(state, API_STATIC_PUBLIC_ICELAND_OUR_RANGE) || EMPTY_OBJECT;
  const currentFiltersDiet = availableFlags([query.foodRangeDiet], FoodRangeFlags);
  const products = foodRangeProductsSelector(query?.categories, currentFiltersDiet)(state);
  const isLoaded = !!products;

  return {
    pageData,
    products,
    isLoaded,
  };
};

const mapDispatchToProps = dispatch => ({
  updateUrl: values => dispatch(syncValuesToRoute(values, ['categories', 'foodRangeDiet'])),
});
const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction(
  {
    clientOnly: ({}, dispatch) => dispatch(getFoodRangeCategories()),
    prepared: ({}, dispatch) =>
      dispatch(getStaticContent(API_STATIC_PUBLIC_ICELAND_OUR_RANGE, true)),
  },
  {
    getPrepareKey: componentId => componentId,
  },
);

export default compose(addInitAction, connected, enhanced)(IcelandOurRange);
