/* global 'organism','DeepLink' */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import createId from 'common/src/app/util/createId';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';

/**
 * This component has two purposes:
 * - a reference block for navigating to the correct section of the page
 * - used to create the section navigation (TabBar)
 *
 * Dependancy:
 * - Use together with the DeepLinkHandler
 * - Pass a title property for the label in the navigation
 *
 * props that can be passed for creating the navigation:
 * - title
 * - buttonLabel
 * - buttonLink
 * - icon,
 *
 * Example usage:
 * <DeepLinkWithIconAndMediaButtonBlock title="Your title" />
 *
 * See Slimming World Essentials page and Home page
 */
class DeepLink extends Component {
  componentDidMount() {
    const { title, buttonLabel, buttonLink, icon, registerDeepLink, currentRoute } = this.props;
    registerDeepLink({
      [title]: {
        buttonLabel,
        buttonLink,
        icon,
      },
      currentRoute,
    });
  }
  render() {
    const { title } = this.props;
    return (
      <div
        {...componentClassNameProp(ComponentType.ATOM, this)}
        ref={ref => (this.deepLinkBlock = ref)}
        id={createId(title)}
      />
    );
  }
}

DeepLink.defaultProps = {
  title: '',
};

DeepLink.propTypes = {
  currentRoute: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
  icon: PropTypes.string,
  registerDeepLink: PropTypes.func.isRequired,
};

export default DeepLink;
