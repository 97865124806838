import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { getInstagramPosts } from 'common/src/app/actions/components/instagramTrendingActions';
import { instagramPostSelector } from 'common/src/app/selectors/publicSelectors';
import { openModal } from 'common/src/app/actions/components/modalActions';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import InstagramTrending from './InstagramTrending';

const mapStateToProps = state => ({
  posts: instagramPostSelector(state),
  instagramCredentials: state.config.environmentConfig.instagram,
});

const mapDispatchToProps = dispatch => ({
  openModal: (image, caption) =>
    dispatch(
      openModal(
        ModalNames.INSTAGRAM_IMAGE_MODAL,
        {
          image,
          caption,
        },
        false,
        false,
        true,
        false,
      ),
    ),
  saveInstagramPosts: () => {
    dispatch(getInstagramPosts()).catch();
  },
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(InstagramTrending);
