import React from 'react';
import PropTypes from 'prop-types';
import IconName from 'common/src/app/data/enum/IconName';
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import Row from 'components/atoms/Row';
import TextNew, { Colors, AccentColors, ElementTypes } from 'components/atoms/TextNew';
import DirectionType from 'common/src/app/data/enum/DirectionType';

const Arrows = ({ type, prevSlideIndex, nextSlideIndex, ...props }) => {
  if (type === 'next') {
    return (
      <div className="arrow">
        <Button className={props.classNames} onClick={props.onClick} data-role={props['data-role']}>
          <Row aligncenter>
            <TextNew.Sans.SM
              color={Colors.PRIMARY_LIGHT}
              element={ElementTypes.SPAN}
            >{`Day ${nextSlideIndex} `}</TextNew.Sans.SM>
            <Icon height={12} name={IconName.CHEVRON} direction={DirectionType.RIGHT} />
          </Row>
        </Button>
      </div>
    );
  }
  if (type === 'prev') {
    return (
      <div className="arrow">
        <Button
          isGhostDark
          className={props.classNames}
          onClick={props.onClick}
          data-role={props['data-role']}
        >
          <Row aligncenter>
            <Icon height={12} name={IconName.CHEVRON} direction={DirectionType.LEFT} />
            <TextNew.Sans.SM
              color={Colors.BRAND}
              element={ElementTypes.SPAN}
            >{`Day ${prevSlideIndex}`}</TextNew.Sans.SM>
          </Row>
        </Button>
      </div>
    );
  }

  return null;
};

Arrows.propTypes = {
  type: PropTypes.string,
  prevSlideIndex: PropTypes.number,
  nextSlideIndex: PropTypes.number,
  onClick: PropTypes.func,
  'data-role': PropTypes.string,
  classNames: PropTypes.string,
  textColor: PropTypes.oneOf([...Colors.propTypes, ...AccentColors.propTypes]),
};

export default Arrows;
