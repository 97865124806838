/* global 'molecule','IcelandFreebieDeal' */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import Image from 'components/atoms/Image';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import './Iceland-freebie-deal.scss';

const IcelandFreebieDeal = (
  { offer: { fullImage, cardImage, disclaimer } = {}, deviceState, fullWidth = false },
  context,
  className,
) => {
  const image = deviceState <= DeviceState.MD || !fullWidth ? cardImage : fullImage;
  const ratio = deviceState <= DeviceState.MD || !fullWidth ? 383 / 551 : 1130 / 244;
  return (
    <div className={classNames(className, { 'full-width': fullWidth })}>
      <Image ratio={ratio} {...image} />

      <TextNew.Sans.XS cid="disclaimer" color={Colors.PRIMARY_LIGHT} element={ElementTypes.FOOTER}>
        {disclaimer}
      </TextNew.Sans.XS>
    </div>
  );
};

IcelandFreebieDeal.propTypes = {
  offer: PropTypes.object,
  fullWidth: PropTypes.bool,
  deviceState: PropTypes.string,
};

export default withDeviceState()(withFunctionalClassName('molecule','IcelandFreebieDeal')(IcelandFreebieDeal));
