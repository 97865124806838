import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction, prepareComponent } from '@mediamonks/react-redux-component-init';
import { API_STATIC_PUBLIC_WHAT_CAN_I_EAT } from 'common/src/app/data/apiStatics';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import TypicalDayMealPlan from 'components/organisms/TypicalDayMealPlan';
import WhatCanIEat from './WhatCanIEat';

const whatCanIEatBenefitsList = [
  'whatCanIEat.intro.one',
  'whatCanIEat.intro.two',
  'whatCanIEat.intro.three',
];

const mapStateToProps = state => ({
  pageContent: staticPageSelector(state, API_STATIC_PUBLIC_WHAT_CAN_I_EAT),
  whatCanIEatBenefitsList,
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(prepareComponent(TypicalDayMealPlan)),
      dispatch(getStaticContent(API_STATIC_PUBLIC_WHAT_CAN_I_EAT, true)),
    ]),
});

export default compose(addInitAction, connected)(WhatCanIEat);
