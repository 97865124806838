import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Creates a wrapper HTML element that tracks when `<a />` elements are clicked. Clicking a link
 * inside this wrapper will result in a `TRACK_LINK` action to be dispatched. This action can
 * be listened to using `redux-listeners-middleware` to trigger a tracking event.
 *
 * By default, the wrapper element is a `div`, but this can be changed with the `element` prop.
 *
 * **Note: any additional props passed to <LinkTracker /> will be passed on to the wrapping
 * element. This can be used to attach a `className`. for example. **
 */
class LinkTracker extends PureComponent {
  handleClick = e => {
    let element = e.target;

    do {
      if (element.tagName.toLowerCase() === 'a') {
        const href = element.getAttribute('href') || '';
        const { meta = {}, trackLink } = this.props;
        trackLink(meta, href);

        break;
      }

      element = element.parentNode;
    } while (element.parentNode && element !== this.wrapperElement);
  };

  registerWrapperElement = el => (this.wrapperElement = el);

  render() {
    // eslint-disable-next-line no-unused-vars
    const { element: Element, children, trackLink, meta, ...props } = this.props;

    return (
      <Element onClick={this.handleClick} {...props} ref={this.registerWrapperElement}>
        {children}
      </Element>
    );
  }
}

LinkTracker.defaultProps = {
  element: 'div',
};

LinkTracker.propTypes = {
  /**
   * The element to use as wrapper. Defaults to 'div'
   */
  element: PropTypes.string,
  /**
   * Optional object containing additional metadata that will be attached to the `meta` of the
   * `TRACK_LINK` action.
   */
  meta: PropTypes.object,
  children: PropTypes.node.isRequired,
  /**
   * Tracks a link click. Passed by connect() wrapper
   */
  trackLink: PropTypes.func.isRequired,
};

export default LinkTracker;
