import handleActions from 'redux-actions/lib/handleActions';
import { UPDATE_COLLECTION_PAGINATION_VIEW } from '../../../actions/collectionPaginationViewActions';

const collectionPaginationViewReducer = handleActions(
  {
    [UPDATE_COLLECTION_PAGINATION_VIEW]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {
    collection: null,
    limit: 10,
    offset: 0,
  },
);

export default collectionPaginationViewReducer;
