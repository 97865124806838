/* global 'organism','Episode' */
import React from 'react';
import PropTypes from 'prop-types';
import TextNew, { Colors } from 'components/atoms/TextNew';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import featuredPodcastShape from 'common/src/app/util/proptypes/apiEntities/featuredPodcastShape';

import './episode.scss';

const Episode = ({ podcast }) => {
  const { title, subtitle, description, link } = podcast;
  const componentClass = 'molecule-recent-podcast-episode';

  return (
    <div className={componentClass} data-testid="podcast-episode">
      <a href={link} data-testid="podcast-link">
        <Icon name={IconName.PODCAST} rounded={false} width={80} />
        <TextNew.Script.MD
          color={Colors.BRAND}
          cid={`${componentClass}-title`}
          dataTestid="podcast-title"
        >
          {title}
        </TextNew.Script.MD>
        <div>
          <TextNew.Serif.MD
            weight="bold"
            cid={`${componentClass}-recent`}
            dataTestid="podcast-sub-title"
          >
            {subtitle}
          </TextNew.Serif.MD>
          <TextNew.Sans.SM dataTestid="podcast-description">{description}</TextNew.Sans.SM>
        </div>
      </a>
    </div>
  );
};

Episode.propTypes = {
  podcast: PropTypes.shape(featuredPodcastShape),
};

export default Episode;
