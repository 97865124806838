import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { IRISH_COUNTIES } from 'common/src/app/data/entityTypes';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { makeCollectionSelector } from 'common/src/app/selectors/collectionSelector';
import {
  getIrishCounties,
  GET_IRISH_COUNTIES,
} from 'common/src/app/actions/resources/irishCountyActions';
import Counties from './Counties';

const mapStateToProps = state => {
  const collectionSelector = makeCollectionSelector();
  const makeIsLoading = makeIsLoadingSelector(GET_IRISH_COUNTIES);
  const list = collectionSelector(state, { collectionId: IRISH_COUNTIES }).entities;

  const irishCounties = list && list.map(({ title }) => ({ title, value: title }));

  return {
    irishCounties,
    isLoading: makeIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch, {}) => ({
  getIrishCounties: () => dispatch(getIrishCounties()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose(connector)(Counties);
