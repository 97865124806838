import React from 'react';
import Link from 'react-router/lib/Link';
import PropTypes from 'prop-types';

import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import Size from 'common/src/app/data/enum/Size';
import Roundel from 'components/atoms/Roundel';
import TextNew, { AccentColors, Colors, ElementTypes, FontWeight } from 'components/atoms/TextNew';
import Row from 'components/atoms/Row';
import Image from 'components/atoms/Image';
import getPublicPrices from 'common/src/app/util/getPublicPrices';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import IconName from 'common/src/app/data/enum/IconName';
import Icon from 'components/atoms/Icon';

import './digital-magazine-promo.scss';

const platformSubscriptions = [
  {
    id: 'apple',
    iconName: IconName.APP_STORE,
    link: PagesExternal.DIGITAL_MAGAZINE_SUBSCRIPTION_APPLE_STORE,
  },
  {
    id: 'google',
    iconName: IconName.GOOGLE_PLAY_STORE,
    link: PagesExternal.DIGITAL_MAGAZINE_SUBSCRIPTION_GOOGLE_PLAY_STORE,
  },
  {
    id: 'amazon',
    customHeight: 20,
    iconName: IconName.AMAZON_KINDLE_STORE,
    link: PagesExternal.DIGITAL_MAGAZINE_SUBSCRIPTION_AMAZON_KINDLE_STORE,
  },
  {
    id: 'microsoft',
    iconName: IconName.MICROSOFT_STORE,
    customHeight: 18,
    link: PagesExternal.DIGITAL_MAGAZINE_SUBSCRIPTION_MICROSOFT_STORE,
  },
  {
    id: 'pocket-mags',
    iconName: IconName.POCKET_MAGS_STORE,
    link: PagesExternal.DIGITAL_MAGAZINE_SUBSCRIPTION_POCKET_MAGS,
  },
];

const DigitalMagazinePromo = ({ digitalEditionImage }, context, className, dataTestid) => (
  <Row className={className}>
    <div className="digital-wrap" data-testid={dataTestid}>
      <div className="digital-left">
        <div className="promo-top">
          <div className="promo-title">
            <TextNew.Script.LG
              cid="promo-title-first"
              color={AccentColors.ACCENT_PURPLE}
              element={ElementTypes.H2}
              localeId="digitalMagazinePromo.title"
            />

            <TextNew.Serif.LG
              color={AccentColors.ACCENT_PURPLE}
              element={ElementTypes.H2}
              localeId="digitalMagazinePromo.titleTwo"
            />
          </div>
        </div>

        <div className="promo-content">
          {['single', 'annual'].map((signUpItem, index) => (
            <div key={`${index}-${signUpItem}`} className="content-col">
              <TextNew.Sans.XL
                color={AccentColors.ACCENT_PURPLE}
                weight={FontWeight.BOLD}
                element={ElementTypes.H2}
                localeId={`digitalMagazinePromo.mainContent.${signUpItem}.intro`}
              />

              <div className="group">
                <TextNew.Sans.XL
                  color={AccentColors.ACCENT_PURPLE}
                  weight={FontWeight.BOLD}
                  element={ElementTypes.H2}
                >
                  {getPublicPrices('nonMember', signUpItem)}
                </TextNew.Sans.XL>

                {signUpItem === 'annual' && (
                  <TextNew.Sans.SM
                    color={AccentColors.ACCENT_PURPLE}
                    element={ElementTypes.H2}
                    localeId="digitalMagazinePromo.mainContent.annual.issues"
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="platforms">
          <TextNew.Sans.SM
            color={AccentColors.ACCENT_PURPLE}
            element={ElementTypes.H2}
            localeId="digitalMagazinePromo.platform.title"
          />
          <div className="icons">
            {platformSubscriptions?.map(platform => (
              <Link
                key={platform?.id}
                href={platform?.link}
                aria-label={platform?.id}
                className={`platform-icon ${platform?.customHeight ? 'custom-height' : ''}`}
              >
                <Icon name={platform?.iconName} height={platform.customHeight || 27} />
              </Link>
            ))}
          </div>
          <TextNew.Sans.XS
            cid="platform-description"
            color={AccentColors.ACCENT_PURPLE}
            localeId="digitalMagazinePromo.platform.description"
          />
        </div>
      </div>

      <div className="digital-right">
        {digitalEditionImage?.src && (
          <figure>
            <Image
              isLazy={false}
              src={digitalEditionImage.src}
              ratio={416 / 423}
              dataTestid="digital-magazine-image"
              alt={digitalEditionImage?.alt}
            />
          </figure>
        )}
        <div className="roundel">
          <Roundel backgroundColor={AccentColors.ACCENT_PURPLE} size={Size.LS}>
            <TextNew.Sans.XL
              color={Colors.PRIMARY_LIGHT}
              element={ElementTypes.H2}
              localeId="digitalMagazinePromo.roundel"
            />
          </Roundel>
        </div>
      </div>
    </div>
  </Row>
);

DigitalMagazinePromo.propTypes = {
  digitalEditionImage: PropTypes.object,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'DigitalMagazinePromo',
)(DigitalMagazinePromo);
