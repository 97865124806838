import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import VideoType from 'common/src/app/data/enum/VideoType';
import VideoPropTypes from 'common/src/app/util/proptypes/apiEntities/video';
import trackingShape from 'common/src/app/util/proptypes/apiEntities/tracking';
import BrightcoveVideo from '../BlockContent/components/atoms/BrightcoveVideo';
import YouTube from '../BlockContent/components/atoms/YouTube';

import './video.scss';

/**
 * Render the video based on the type
 */
const renderVideo = (
  video,
  videoType,
  brightcoveAccountId,
  brightcovePlayerId,
  trackingData,
  trackVideoEvent,
  hasCustomControls,
) => {
  if (video && videoType) {
    switch (videoType) {
      case VideoType.BRIGHTCOVE:
        return (
          <BrightcoveVideo
            video={video}
            accountId={brightcoveAccountId}
            playerId={brightcovePlayerId}
            trackingData={trackingData}
            trackVideoEvent={trackVideoEvent}
            hasCustomControls={hasCustomControls}
          />
        );
      case VideoType.YOUTUBE:
      default:
        return (
          <YouTube
            video={video}
            trackingData={trackingData}
            trackVideoEvent={trackVideoEvent}
            hasCustomControls={hasCustomControls}
          />
        );
    }
  }
  return null;
};

const Video = (
  {
    video,
    videoType,
    brightcoveAccountId,
    brightcovePlayerId,
    trackingData,
    trackVideoEvent,
    hasCustomControls,
  },
  context,
  className,
) => (
  <section className={className}>
    {renderVideo(
      video,
      videoType,
      brightcoveAccountId,
      brightcovePlayerId,
      trackingData,
      trackVideoEvent,
      hasCustomControls,
    )}
  </section>
);

Video.propTypes = {
  ...VideoPropTypes,
  trackingData: PropTypes.shape(trackingShape),
  hasCustomControls: PropTypes.bool,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'Video')(Video);
