import handleActions from 'redux-actions/lib/handleActions';
import { createSelector } from 'reselect';
import { LOCATION_CHANGE } from 'react-router-redux/lib/reducer';

const initialState = [];

const routeHistoryReducer = handleActions(
  {
    [LOCATION_CHANGE]: (state, { payload }) =>
      // ignore changes on pageload to prevent duplicate entries
      state.length === 1 && state[0].key === null && payload.pathname === state[0].pathname
        ? state
        : [payload].concat(state),
  },
  initialState,
);

/**
 * Selector that has the current routeHistory state as input and returns `true` if
 * there is a route in the current session's history that the user can go back to.
 */
export const hasValidBackRoute = createSelector(
  state => state,
  ([currentRoute, ...routeHistory]) =>
    routeHistory.some(history => !history.pathname.startsWith(currentRoute.pathname)),
);

export default routeHistoryReducer;
