/* global 'organism','EmptySearchResults' */
import React from 'react';

import ManualSeo from 'components/atoms/ManualSeo';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import TextNew, { AccentColors, ElementTypes } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import Pages from 'common/src/app/data/enum/Pages';
import Wrapper from 'components/atoms/Wrapper';
import ResponsiveImage from 'components/atoms/ResponsiveImage';

import './empty-search-results.scss';

const EmptySearchResults = (
  { deviceState, noResultsImage, hasFilter },
  context,
  className,
  dataTestid,
) => {
  const localeToUse = hasFilter ? 'filter' : 'postcode';

  const ctaBlock = (
    <div className="find-out-more">
      <TextNew.Serif.SM
        cid="details"
        localeId={`emptySearchResults.${localeToUse}.detail`}
        color={AccentColors.ACCENT_GREY}
      />
      {!hasFilter && (
        <Button to={Pages.UK_PUBLIC_ONLINE_INFORMATION} isShadePink>
          <TextNew.Sans.SM
            element={ElementTypes.SPAN}
            color={AccentColors.ACCENT_PURPLE}
            cid="moreAction"
            localeId="emptySearchResults.moreAction"
          />
        </Button>
      )}
    </div>
  );

  return (
    <div className={className} data-testid={dataTestid}>
      <Wrapper width="md" padding="sm" cid="main-wrapper">
        <ManualSeo metaTitle="Sorry, no results were found matching your search" />
        <div className="text-content">
          <TextNew element={ElementTypes.H1} cid="title-intro">
            <TextNew.Serif.SM
              color={AccentColors.ACCENT_PURPLE}
              localeId={`emptySearchResults.${localeToUse}.titleIntro`}
              element={ElementTypes.SPAN}
            />
            <TextNew.Script.MD
              color={AccentColors.ACCENT_PURPLE}
              localeId={`emptySearchResults.${localeToUse}.titleNoGroups`}
              element={ElementTypes.SPAN}
            />
          </TextNew>
          {deviceState >= DeviceState.LG && ctaBlock}
        </div>
        <ResponsiveImage {...noResultsImage} />
        {deviceState < DeviceState.LG && ctaBlock}
      </Wrapper>
    </div>
  );
};

EmptySearchResults.propTypes = {
  deviceState: PropTypes.number,
  noResultsImage: PropTypes.object,
  hasFilter: PropTypes.bool,
};

export default withDeviceState()(withFunctionalClassName('organism','EmptySearchResults')(EmptySearchResults));
