/* global 'atom','Counties' */
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputType from 'common/src/app/data/enum/InputType';
import { addressFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { countryCode } from 'common/src/app/data/enum/Countries';
import states from 'common/src/app/data/statedata';
import InputNew from '../../../../../atoms/InputNew';
import Select from '../../../../../../../../atoms/Select';

/**
 * Address component that creates a set of fields that changes based off the country
 */

const Counties = (
  { isLoading, irishCounties, getIrishCounties, activeCountry, county, formProps, errorMessage },
  { getMessage },
) => {
  useEffect(() => {
    if (activeCountry === countryCode.IRELAND) {
      getIrishCounties();
    }
  }, [activeCountry, getIrishCounties]);

  const countyOptions = activeCountry === countryCode.US ? states : irishCounties;

  return (
    <Fragment>
      {activeCountry === countryCode.US || (activeCountry === countryCode.IRELAND && !isLoading) ? (
        <Fragment>
          <Select
            stacked
            name={addressFields.COUNTRY}
            value={county}
            options={countyOptions}
            placeholder={getMessage(`addressForm.placeholders.countyIe`)}
            suppress
            {...formProps}
          />
          {errorMessage &&
            React.cloneElement(errorMessage, {
              errorName: getMessage(`addressFormGroup.placeholders.${addressFields.COUNTY}`),
              name: addressFields.COUNTY,
            })}
        </Fragment>
      ) : (
        <Fragment>
          <InputNew
            stacked
            name={addressFields.COUNTY}
            value={county}
            type={InputType.TEXT}
            data-hj-suppress={true}
            placeholder={getMessage(
              `addressForm.placeholders.${activeCountry === countryCode.US ? 'state' : 'county'}`,
            )}
            {...formProps}
          />
          {errorMessage &&
            React.cloneElement(errorMessage, {
              errorName: getMessage(`addressFormGroup.placeholders.${addressFields.COUNTY}`),
              name: addressFields.COUNTY,
            })}
        </Fragment>
      )}
    </Fragment>
  );
};

Counties.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

Counties.propTypes = {
  isLoading: PropTypes.bool,
  activeCountry: PropTypes.string,
  county: PropTypes.string,
  errorMessage: PropTypes.node,
  formProps: PropTypes.object,
  irishCounties: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  getIrishCounties: PropTypes.func,
};

export default Counties;
