import { LOCATION_CHANGE } from 'react-router-redux/lib/reducer';

import { getValue } from '../../../app/util/injector';
import { AUTHENTICATION_MANAGER } from '../../../app/data/Injectables';

const clientAuthenticationReduxMiddleware = (/* { dispatch } */) => next => action => {
  if (action && action.type && action.type === LOCATION_CHANGE) {
    const authenticationManager = getValue(AUTHENTICATION_MANAGER);
    authenticationManager.onRouteChange(false);
  }

  return next(action);
};

export default clientAuthenticationReduxMiddleware;
