import PropTypes from 'prop-types';

/** @module */

/**
 * Reusable object to use in `propTypes` for a `image` prop.
 * @type {Object}
 * @category templating
 */
const trackingShape = {
  category: PropTypes.string,
  label: PropTypes.string,
  action: PropTypes.string,
};

export default trackingShape;
