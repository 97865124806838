import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Colors, { AccentColors } from 'common/src/app/data/enum/Colors';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ElementTypes from 'common/src/app/data/enum/ElementTypes';
import Size from 'common/src/app/data/enum/Size';

import './roundel.scss';

const Roundel = (
  { children, size, backgroundColor, backgroundColorHex, element },
  context,
  className,
) => {
  const HtmlElement = element;

  return (
    <HtmlElement className={className}>
      <div
        className={`roundel-child ${!backgroundColorHex && backgroundColor} ${size}`}
        style={backgroundColorHex && { backgroundColor: `${backgroundColorHex}` }}
      >
        <div className="content">{children}</div>
      </div>
    </HtmlElement>
  );
};

Roundel.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.oneOf([...Colors.propTypes, ...AccentColors.propTypes]),
  backgroundColorHex: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Size)),
  element: PropTypes.string,
};

Roundel.defaultProps = {
  backgroundColor: AccentColors.ACCENT_GREEN,
  color: AccentColors.ACCENT_GREEN,
  element: ElementTypes.DIV,
};

export default withFunctionalClassName(ComponentType.ATOM, 'Roundel')(Roundel);
