/**
 * seasonal enum map for filter flags
 */
const SeasonalType = {
  0: 'Christmas',
  1: 'NewYear',
  2: 'Thanksgiving',
  3: 'Halloween',
  4: 'ValentinesDay',
  5: 'Summer',
  6: 'Fall',
  7: 'Winter',
  8: 'Spring',
};

export default SeasonalType;
