export const composeDeepLink = (page, hash) => `${page}${hash}`;

const HashDeepLinks = {
  AWARDS: '#awards',
  MOVE_MORE: '#move-more',
  SUBSCRIBE: '#subscribe',
  MIGRATION_MOVED: '#moved',
  MIGRATION_MOT_MOVED: '#NotMoved',
};

export default HashDeepLinks;
