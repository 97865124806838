/* global 'molecule' */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import TextNew, { Colors, FontSizes } from 'components/atoms/TextNew';
import Wrapper from 'components/atoms/Wrapper';

import './news-letter-sign-up-submitted-success-message.scss';

class NewsletterSignUpSubmittedSuccessMessage extends Component {
  componentDidMount() {
    const { scrollToElement } = this.props;
    scrollToElement(this.successMessage, 0);
  }
  render() {
    const { deviceState } = this.props;

    return (
      <div
        ref={el => (this.successMessage = el)}
        {...componentClassNameProp('molecule', this)}
      >
        <Wrapper width="md" className="success-wrapper">
          <TextNew.Script
            color={Colors.BRAND}
            size={deviceState >= DeviceState.SM ? FontSizes.MD : FontSizes.SM}
            localeId="newsletterSignUpSubmittedSuccessMessage.title"
          />
          <TextNew.Serif
            cid="newsletter-subtitle"
            color={Colors.PRIMARY_DARK}
            size={deviceState >= DeviceState.SM ? FontSizes.SM : FontSizes.XS}
            localeId="newsletterSignUpSubmittedSuccessMessage.subtitle"
          />
          <TextNew.PrimaryElegant
            cid="newsletter-verify-email"
            color={Colors.PRIMARY_DARK}
            size={deviceState >= DeviceState.SM ? FontSizes.SM : FontSizes.MD}
            localeId="newsletterSignUpSubmittedSuccessMessage.verifyEmail"
          />
          <TextNew.PrimaryElegant
            cid="newsletter-description"
            color={Colors.PRIMARY_DARK}
            size={deviceState >= DeviceState.SM ? FontSizes.SM : FontSizes.XS}
            localeId="newsletterSignUpSubmittedSuccessMessage.description"
          />
        </Wrapper>
      </div>
    );
  }
}

NewsletterSignUpSubmittedSuccessMessage.propTypes = {
  scrollToElement: PropTypes.func,
  deviceState: PropTypes.number,
};

export default withDeviceState()(NewsletterSignUpSubmittedSuccessMessage);
