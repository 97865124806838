import components from './componentsReducer';
import collectionPagination from './collectionPaginationViewsReducer';

/**
 * Contains default reducers for all state that is visible to the end user. Some examples of
 * state that belongs in this reducer:
 *  - which recipe is currently visible on a recipe detail page
 *  - which articles are part of the search results for the current search of the user
 *  - if the modal window is currently open, and what content is currently being shown inside
 *  - current value of a toggle that determines the sorting of posts on the community overview page
 *
 * Some examples of state that does not belong in this reducer:
 *  - the current state of a form. While this is technically 'view' state, this state is put
 *  into our 'form' and 'enhancedForm' reducers because it is handled by other libraries.
 *  - the status of the users payment. This state is not (directly) visible to the user so
 *  it does not belong in 'view'
 *  - the data representing a recipe. While a 'view' reducer might contain state about which
 *  recipe is currently visible, the data itself should be in the 'entities' reducer
 *
 * @type {object}
 */
const defaultViewReducers = {
  components,
  collectionPagination,
};

export default defaultViewReducers;
