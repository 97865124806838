import { Category, DataLayerKeys } from '../../app/data/enum/Tracking';
import { setTrackingPageData } from '../../app/actions/trackingActions';
import { GET_SEARCH_RESULTS } from '../../app/actions/resources/searchActions';

export default addListener => {
  /**
   * Track search query
   */
  addListener(
    {
      actionType: GET_SEARCH_RESULTS,
    },
    (
      {
        meta: {
          api: {
            data: { query },
          },
        },
        payload: { pagination },
      },
      getState,
      dispatch,
    ) =>
      query &&
      dispatch(
        setTrackingPageData(
          {
            category: Category.PUBLIC_SEARCH,
            [DataLayerKeys.SEARCH_QUERY]: 1,
            [DataLayerKeys.QUERY]: query,
            [DataLayerKeys.NUM_RESULTS]: (pagination && pagination.total) || 0,
            [DataLayerKeys.CONTENT_GROUP_NAME]: Category.PUBLIC_SEARCH,
          },
          true,
        ),
      ),
  );
};
