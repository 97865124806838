import Configuration from 'common/src/app/config/Configuration';

/**
 * Utilities to calculate BMI from weight and height, and determining if the BMI
 * is within configured limits
 * @module
 * @category forms
 */

/**
 * Calculates the BMI with metric units
 * NOTE: both units need to be metric
 *
 * @param weight in kilograms
 * @param height in centimeters
 * @returns {number}
 */
export function calculateBmi(weight, height) {
  return weight / Math.pow(height / 100, 2);
}

/**
 * Checks if your BMI is within the safe range as defined by the marketConfig
 *
 * @param weight
 * @param height
 * @returns {boolean}
 */
export function isBmiRangeSafe(weight, height) {
  // you need to have a BMI equal or higher than 23
  return calculateBmi(weight, height) >= Configuration.registerBMI;
}

/**
 * Checks if your BMI is within the safe range to transfer as defined by the marketConfig
 *
 * @param weight
 * @param height
 * @returns {boolean}
 */
export function isTransferBmiRangeSafe(weight, height) {
  // you need to have a BMI equal or higher than 20
  return calculateBmi(weight, height) >= Configuration.transferBMI;
}

/**
 * Checks if you are able to weigh in based on your BMI
 *
 * @param weight
 * @param height
 * @returns {boolean}
 */
export function canWeighIn(weight, height) {
  // you need to have a BMI equal or higher than 17
  return calculateBmi(weight, height) >= Configuration.weighInBMI;
}

/**
 * Checks if you are able to weigh in based on your BMI
 *
 * @param weight
 * @param height
 * @returns {boolean}
 */
export function isDangerous(weight, height) {
  // a BMI below (18.5) is considered dangerous, so show a warning
  return calculateBmi(weight, height) < Configuration.dangerousBMI;
}

/**
 * Checks if you are able to set a target weigh in based on your BMI
 *
 * @param weight
 * @param heigh
 * @returns {boolean}
 */
export function isAtRisk(weight, height) {
  // a BMI below (20) is considered critical, so show a warning
  return calculateBmi(weight, height) <= Configuration.atRiskBMI;
}
