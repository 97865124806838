/* global WP_DEFINE_IS_NODE */
import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { headingMainMenuToggle } from 'common/src/app/actions/components/headerActions';
import LocationHash from 'common/src/app/data/enum/LocationHash';
import SkipLinks from './SkipLinks';

const mapDispatchToProps = dispatch => ({
  onToggleNavigation: () => {
    if (!WP_DEFINE_IS_NODE) {
      const hash = window.location.hash.substring(1);
      hash === LocationHash.SUB_NAVIGATION && dispatch(headingMainMenuToggle());
    }
  },
});

const connected = connect(null, mapDispatchToProps);

export default compose(connected)(SkipLinks);
