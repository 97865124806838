/* global 'organism','PlaceDirectory' */
import React from 'react';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import PropTypes from 'prop-types';
import TextNew, { FontWeight } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import Grid from 'components/organisms/Grid';
import GridItem from 'components/organisms/GridItem';
import GridItemType from 'common/src/app/data/enum/GridItemType';
import Loader from 'components/atoms/Loader';
import Wrapper from 'components/atoms/Wrapper';

import './place-directory.scss';

const PlaceDirectory = ({ data, displaySortTitle, isInitializing }, getMessage, className) => (
  <div className={className}>
    {isInitializing ? (
      <div className="is-loading">
        <Loader />
      </div>
    ) : (
      data &&
      Object.keys(data).map(resultsWithLetter => (
        <Wrapper cid="content" padding="xs" width="xl" key={resultsWithLetter}>
          <Grid>
            {displaySortTitle && (
              <TextNew.Serif.MD cid="letter-title" weight={FontWeight.BOLD}>
                {resultsWithLetter}
              </TextNew.Serif.MD>
            )}
            {data[resultsWithLetter].map(place => (
              <GridItem key={place.url} type={GridItemType.FOURTHS}>
                <TextNew.Sans.SM>
                  <Button isGhostDark to={place.link}>
                    {place.name}
                  </Button>
                </TextNew.Sans.SM>
              </GridItem>
            ))}
          </Grid>
        </Wrapper>
      ))
    )}
  </div>
);

PlaceDirectory.propTypes = {
  data: PropTypes.object,
  isInitializing: PropTypes.bool,
  displaySortTitle: PropTypes.bool,
};

export default withFunctionalClassName('organism','PlaceDirectory')(PlaceDirectory);
