import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { setGeoLocation } from 'common/src/app/actions/resources/geoLocationActions';
import NearestGroupGeoLocation from './NearestGroupGeoLocation';

const mapDispatchToProps = (dispatch, { external, publicHost }) => ({
  getGroupsByLatLng: () => dispatch(setGeoLocation({ external, publicHost })),
});

const connected = connect(null, mapDispatchToProps);

export default compose(connected)(NearestGroupGeoLocation);
