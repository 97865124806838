import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import FreeFoodFebruary from 'bundle-loader?lazy&reactHot&name=FreeFoodFebruary!./index';

const routes = (
  <Route
    title="Free Food February | {pageTitle}"
    path={Pages.UK_PUBLIC_FREE_FOOD_FEBRUARY}
    {...getRouteComponentProp(FreeFoodFebruary)}
  />
);

export default routes;
