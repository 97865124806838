import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import LocaleMessage from 'components/atoms/LocaleMessage';

import './iceland-header.scss';

const IcelandHeader = ({}, context, className) => (
  <header className={className}>
    <h1 className="iceland-title">
      <TextNew.Serif.XL
        cid="title-element"
        element={ElementTypes.SPAN}
        color={Colors.PRIMARY_LIGHT}
        localeId="icelandHeader.title.top"
      />
      <TextNew.Script.MD
        cid="title-element"
        element={ElementTypes.SPAN}
        color={Colors.BRAND}
        localeId="icelandHeader.title.bottom"
      />
    </h1>

    <Button to={Pages.UK_PUBLIC_ICELAND_OUR_RANGE}>
      <LocaleMessage id="icelandHeader.button" />
    </Button>
  </header>
);

IcelandHeader.propTypes = {
  accentColor: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'IcelandHeader', [
  ({ accentColor }) => accentColor && `is-accent-color-${accentColor}`,
])(IcelandHeader);
