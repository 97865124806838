import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import LocationHash from 'common/src/app/data/enum/LocationHash';
import TextNew, { Colors } from 'components/atoms/TextNew';
import './skip-links.scss';

const links = [
  {
    locale: 'navigation',
    hash: LocationHash.MAIN_NAVIGATION,
  },
  {
    locale: 'subNavigation',
    hash: LocationHash.SUB_NAVIGATION,
  },
  {
    locale: 'content',
    hash: LocationHash.CONTENT,
  },
  {
    locale: 'footer',
    hash: LocationHash.FOOTER,
  },
];

const SkipLinks = ({ isPublic, onToggleNavigation, deviceState }, context, className) => {
  useEffect(() => {
    window.addEventListener('hashchange', onToggleNavigation, false);

    return () => {
      window.removeEventListener('hashchange', onToggleNavigation, false);
    };
  }, [onToggleNavigation]);
  return (
    <ul className={className}>
      {links.map(({ locale, hash }) => {
        /**
         * For public the sub navigation will only appear in smaller screens
         */
        if (isPublic && deviceState > DeviceState.XL && hash === LocationHash.SUB_NAVIGATION) {
          return null;
        }

        return (
          <li key={`${hash}-${locale}`}>
            <Link href={`#${hash}`}>
              <TextNew.PrimaryElegant
                color={Colors.PRIMARY_LIGHT}
                localeId={`skipLinks.${locale}`}
              />
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

SkipLinks.propTypes = {
  isPublic: PropTypes.bool,
  onToggleNavigation: PropTypes.func.isRequired,
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'SkipLinks')(SkipLinks),
);
