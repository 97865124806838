import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withDeviceState, {
  DeviceState,
  QueryHelpers,
} from 'common/src/app/util/device-state/withDeviceState';
import Pages from 'common/src/app/data/enum/Pages';
import WebHost from 'common/src/app/data/enum/WebHost';
import TextNew, { Colors, ElementTypes } from '../../../../../atoms/TextNew';
import Row from '../../../../../atoms/Row';
import WebHostLink from '../../../../../atoms/WebHostLink';
import UKPublicNavigationLinks from '../../../../UKPublicNavigationLinks';
import HeadingButton from '../../../../../atoms/HeadingButton';
import CountrySwitch from '../CountrySwitch';

const PublicNavMenu = ({ deviceState, queryHelper }, { getMessage }) => {
  const mobile = queryHelper === QueryHelpers.MOBILE;

  return (
    <Fragment>
      {mobile && <HeadingButton />}

      <Row className="menu" spacebetween aligncenter>
        {!mobile && (
          <Fragment>
            {deviceState <= DeviceState.XL ? <HeadingButton /> : <UKPublicNavigationLinks />}
          </Fragment>
        )}

        <WebHostLink webHost={WebHost.ACCOUNT} link={Pages.LOGIN} className="signin">
          <TextNew.Formal
            color={Colors.BRAND}
            element={ElementTypes.SPAN}
            cid="header-login"
            localeId="ukPublicNavigationLinks.signIn"
          />
        </WebHostLink>
        {mobile && <CountrySwitch getMessage={getMessage} />}
      </Row>
    </Fragment>
  );
};
PublicNavMenu.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};
PublicNavMenu.propTypes = {
  deviceState: PropTypes.number,
  queryHelper: PropTypes.string,
};

export default withDeviceState()(PublicNavMenu);
