import React from 'react';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import OurStorySection from '../OurStorySection';
import OurStoryLeadQuote from '../OurStoryLeadQuote';

const BLOCK_COMPONENT_MAPPING = {
  ContentPageSection: OurStorySection,
  QuotationBlock: OurStoryLeadQuote,
  RichTextBlock,
};

const OurStoryBlockContent = ({ content }) =>
  content?.map((block, index) => {
    // eslint-disable-next-line no-underscore-dangle
    const BlockComponent = BLOCK_COMPONENT_MAPPING[block._type];
    if (BlockComponent) {
      return <BlockComponent key={index} {...block} />;
    }

    return '';
  });

export default OurStoryBlockContent;
