import handleActions from 'redux-actions/lib/handleActions';

import { SET_DEVICE_STATE, SET_SCROLLBAR_WIDTH } from '../actions/deviceStateActions';

const initialState = {
  state: null,
  name: null,
  scrollbarWidth: null,
};

export default handleActions(
  {
    [SET_DEVICE_STATE]: (currentState, { payload: { state, name, helper } }) => ({
      ...currentState,
      state,
      name,
      helper,
    }),
    [SET_SCROLLBAR_WIDTH]: (currentState, { payload: { width } }) => ({
      ...currentState,
      scrollbarWidth: width,
    }),
  },
  initialState,
);
