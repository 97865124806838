export const countryCode = {
  US: 'US',
  GB: 'GB',
  IRELAND: 'IE',
  CYPRUS: 'CY',
  DEFAULT: 'DEFAULT',
};

export const countryCodeValue = {
  US: 'US',
  GB: 'GB',
  IE: 'IRELAND',
  CY: 'CYPRUS',
  DEFAULT: 'default',
};

export const countryCodeToString = {
  [countryCode.US]: 'us',
  [countryCode.GB]: 'gb',
  [countryCode.IRELAND]: 'ireland',
  [countryCode.CYPRUS]: 'cyprus',
  [countryCode.DEFAULT]: 'default',
};
