/* global 'molecule' */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import TextNew, { ElementTypes } from '../../atoms/TextNew';
import IconButton from '../IconButton';
import LocaleMessage from '../../atoms/LocaleMessage';
import './information-toggle.scss';

/**
 * Information toggle component, showing a clickable icon to see more info
 */
class InformationToggle extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };
  }

  toggleActive = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive,
    }));
  };

  render() {
    const { label, information } = this.props;
    const { isActive } = this.state;

    return (
      <div {...componentClassNameProp('molecule', this)}>
        <IconButton icon={IconName.INFORMATION} onClick={this.toggleActive} width={20} rounded>
          <TextNew.FormalOriginal element={ElementTypes.DIV}>{label}</TextNew.FormalOriginal>
        </IconButton>
        {isActive && (
          <TextNew.FormalOriginal cid="information-box" element={ElementTypes.DIV}>
            {information}
            <IconButton icon={IconName.CROSS} width={10} onClick={this.toggleActive} />
          </TextNew.FormalOriginal>
        )}
      </div>
    );
  }
}

InformationToggle.defaultProps = {
  label: <LocaleMessage id="informationToggle.defaultLabel" />,
};

InformationToggle.propTypes = {
  /**
   * Label before the 'i' icon, can be a string or a LocaleMessage
   */
  label: PropTypes.node,
  /**
   * The information to toggle, can be a string or a LocaleMessage
   */
  information: PropTypes.node.isRequired,
};

export default InformationToggle;
