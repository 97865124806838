import React from 'react';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, { Colors } from 'components/atoms/TextNew';
import Pages from 'common/src/app/data/enum/Pages';
import './free-membership-roundel.scss';

const FreeMembershipRoundel = (props, context, className, dataTestid) => (
  <Link to={Pages.UK_PUBLIC_OFFERS} className={className} data-testid={dataTestid}>
    <TextNew.Serif.MD
      cid="save"
      color={Colors.PRIMARY_LIGHT}
      localeId="FreeMembershipRoundel.save"
    />
    <TextNew.Serif.XL
      cid="price"
      color={Colors.PRIMARY_LIGHT}
      localeId="FreeMembershipRoundel.price"
    />
  </Link>
);

export default withFunctionalClassName(
  ComponentType.ATOM,
  'FreeMembershipRoundel',
)(FreeMembershipRoundel);
