import PropTypes from 'prop-types';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';

const promotionShape = PropTypes.shape({
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  desktopImage: PropTypes.shape({ ...imageShape }).isRequired,
  largeMobileImage: PropTypes.shape({ ...imageShape }).isRequired,
  smallDesktopImage: PropTypes.shape({ ...imageShape }).isRequired,
  smallMobileImage: PropTypes.shape({ ...imageShape }).isRequired,
  tabletImage: PropTypes.shape({ ...imageShape }).isRequired,
  ultraWideImage: PropTypes.shape({ ...imageShape }).isRequired,
});

export default promotionShape;
