import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { API_STATIC_PUBLIC_RESEARCH_USER_TESTING } from 'common/src/app/data/apiStatics';
import {
  getStaticContent,
  GET_STATIC_CONTENT,
} from 'common/src/app/actions/resources/staticContentActions';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { scrollToElement } from 'common/src/app/actions/scrollActions';

import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import UserTesting from './UserTesting';

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT);

const mapStateToProps = state => ({
  isLoading: pageLoading(state),
  pageContent: staticPageSelector(state, API_STATIC_PUBLIC_RESEARCH_USER_TESTING),
});

const mapDispatchToProps = dispatch => ({
  scrollToElement: (...args) => {
    dispatch(scrollToElement(...args));
  },
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    dispatch(getStaticContent(API_STATIC_PUBLIC_RESEARCH_USER_TESTING, true)),
});

export default compose(addInitAction, connected)(UserTesting);
