import { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * ManualSeo
 *
 * Add SEO meta data to state, which is then taken and passed into our page heads
 *
 */
class ManualSeo extends Component {
  componentDidMount() {
    const { metaTitle, metaImage, metaDescription, setSeo, metaKeywords } = this.props;
    setSeo({ metaTitle, metaImage, metaDescription, metaKeywords });
  }

  shouldComponentUpdate(nextProps) {
    const { setSeo } = this.props;
    const { metaTitle, metaImage, metaDescription, metaKeywords } = nextProps;

    if (metaTitle !== this.props.metaTitle) {
      setSeo({
        metaTitle,
        metaImage,
        metaDescription,
        metaKeywords,
      });
      return true;
    }
    return false;
  }

  render() {
    return null;
  }
}

ManualSeo.propTypes = {
  metaTitle: PropTypes.string,
  metaImage: PropTypes.string,
  metaDescription: PropTypes.string,
  setSeo: PropTypes.func,
  metaKeywords: PropTypes.string,
};

export default ManualSeo;
