/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import errorBoundary from 'components/hoc/ErrorBoundary';
import Image from 'components/atoms/Image';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';

import './magazine-competitions.scss';

const MagazineCompetitions = ({ headerImage }) => (
  <div className="page-magazine-competitions">
    {headerImage && <Image ratio={94 / 25} {...headerImage} />}
    <iframe
      src="https://slimming-world.formstack.com/forms/magazinecompetitions"
      title="Slimming World Magazine Competitions"
      height="1250"
    />
  </div>
);

MagazineCompetitions.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

MagazineCompetitions.propTypes = {
  headerImage: PropTypes.shape(imageShape),
};

export default compose(errorBoundary({ wholePageError: true }))(MagazineCompetitions);
