import createAction from 'redux-actions/lib/createAction';

/**
 * All functions in this module are action creators and the return value should be passed to the
 * redux store dispatch() function.
 *
 * @module
 */

export const SET_ENTITY_VIEW_REF = 'entityActions/SET_ENTITY_VIEW_REF';

/**
 * Updates the reference to which entity is currently visible in the entityViewReducer.
 * @function setEntityViewRef
 * @param {string} targetView The location in the state tree of the entityViewReducer to update.
 * For example: 'view.pages.home.featuredArticle'
 * @param {object} ref A reference to an entity
 * @param {string} ref.type The type of entity
 * @param {string} ref.id The id of the entity
 */
export const setEntityViewRef = createAction(
  SET_ENTITY_VIEW_REF,
  (targetView, ref) => ref,
  targetView => ({ target: targetView }),
);
