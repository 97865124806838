import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import {
  registerFixedHeaderElement,
  unregisterFixedHeaderElement,
} from 'common/src/app/actions/scrollActions';
import { headerToggleFixed } from 'common/src/app/actions/components/headerActions';
import Header from './Header';

const mapStateToProps = state => {
  const { isFixed = false, height } = state.view.components.header;

  return {
    isFixed,
    height,
  };
};

const mapDispatchToProps = dispatch => ({
  headerToggleFixed: (isFixed, height) => dispatch(headerToggleFixed(isFixed, height)),
  register: bottomY => dispatch(registerFixedHeaderElement('Header', bottomY)),
  unregister: () => dispatch(unregisterFixedHeaderElement('Header')),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(Header);
