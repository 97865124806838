/* global 'template' */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew from 'components/atoms/TextNew';
import errorBoundary from 'components/hoc/ErrorBoundary';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ElementTypes from 'common/src/app/data/enum/ElementTypes';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import NewsletterSignUp from '../../../../../molecules/NewsletterSignUp';

import './confirmed-newsletter-signup-email-deprecated.scss';

const ConfirmedNewsletterSignupEmailDeprecated = (
  { deviceState, background },
  context,
  className,
) => (
  <div className={className}>
    <BackgroundImageWrapper image={deviceState >= DeviceState.XL && background ? background : ''}>
      <div className="wrapper">
        <TextNew.Loud
          localeId="confirmedNewsletterSignupEmailDeprecated.title"
          element={ElementTypes.H1}
        />
        <TextNew.PrimaryOriginal localeId="confirmedNewsletterSignupEmailDeprecated.subTitle" />
      </div>
      <NewsletterSignUp />
    </BackgroundImageWrapper>
  </div>
);

ConfirmedNewsletterSignupEmailDeprecated.propTypes = {
  background: ImagePropTypes,
  deviceState: PropTypes.number,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName(ComponentType.TEMPLATE, 'ConfirmedNewsletterSignupEmailDeprecated'),
)(ConfirmedNewsletterSignupEmailDeprecated);
