import { dataLayerCustomEvent } from '../../app/util/tracking/dataLayerUtils';
import { Category, DataLayerKeys } from '../../app/data/enum/Tracking';
import { STORE_LAT_LNG } from '../../app/actions/resources/geoLocationActions';

export default addListener => {
  addListener(
    {
      actionType: STORE_LAT_LNG,
    },
    (_, getState) => {
      dataLayerCustomEvent(
        {
          action: 'use-location-clicked',
          category: Category.GROUP_SEARCH,
          [DataLayerKeys.LOCATION]: 1,
        },
        getState(),
      );
    },
  );
};
