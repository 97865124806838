import React, { useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Pages from 'common/src/app/data/enum/Pages';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import Category from 'common/src/app/data/enum/Tracking/Category';
import DataLayerKeys from 'common/src/app/data/enum/Tracking/DataLayerKeys';
import TextNew, { ElementTypes, FontWeight, Colors, FontSizes } from 'components/atoms/TextNew';
import Carousel from 'components/organisms/Carousel';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Wrapper from 'components/atoms/Wrapper';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import NavigationButton from 'components/atoms/NavigationButton';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';
import Button from 'components/atoms/Button';
import LinkTracker from 'components/atoms/LinkTracker';
import errorBoundary from 'components/hoc/ErrorBoundary';
import colorCarouselPropTypes from './colorCarouselPropTypes';
import './color-carousel.scss';

const colorMap = { 0: 'green', 1: 'pink', 2: 'blue' };
const trackMap = [
  {
    action: 'hungry-learn-more-clicked',
    name: DataLayerKeys.HUNGRY_LEARN_MORE,
  },
  {
    action: 'friends-learn-more-clicked',
    name: DataLayerKeys.FRIENDS_LEARN_MORE,
  },
  {
    action: 'activity-learn-more-clicked',
    name: DataLayerKeys.ACTIVITY_LEARN_MORE,
  },
];

const ColorCarousel = ({ deviceState, carouselContent = [] }, context, className) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    dots: deviceState <= DeviceState.LG,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: deviceState >= DeviceState.XL,
    prevArrow: (
      <NavigationButton
        direction={DirectionType.LEFT}
        buttonLabel={<LocaleMessage id="colorCarousel.navigation.prevLabel" />}
      />
    ),
    nextArrow: (
      <NavigationButton
        direction={DirectionType.RIGHT}
        buttonLabel={<LocaleMessage id="colorCarousel.navigation.nextLabel" />}
      />
    ),
  };
  // TODO SWPUB-207 Move common device states into utils
  const tablet = deviceState >= DeviceState.MD && deviceState < DeviceState.XL;

  const pathTo = index => {
    if (index === 0) {
      return Pages.UK_PUBLIC_WHAT_CAN_I_EAT;
    } else if (index === 2) {
      return Pages.UK_PUBLIC_BODY_MAGIC;
    }

    return Pages.UK_PUBLIC_HOW_IT_WORKS;
  };

  const getActiveIndex = (currentIndex, newIndex) => {
    setActiveIndex(newIndex);
  };

  return (
    <div className={className}>
      {carouselContent && carouselContent.length > 0 && (
        <Carousel settings={settings} handleBeforeChange={getActiveIndex}>
          {carouselContent.map((content, index) => {
            const slideKey = content.title
              .replace(/[^a-zA-Z\d\s]+|(\s+)/g, (match, space) => (space ? '-' : ''))
              .toLowerCase();

            return (
              <Wrapper cid="carousel-parent-wrapper" key={slideKey} width="md">
                {content && (
                  <div className={`carousel-wrapper ${colorMap[index]}`}>
                    {tablet ? (
                      <BackgroundImageWrapper image={content.image} />
                    ) : (
                      <ResponsiveImage
                        px={492}
                        src={content.image.src}
                        alt={content.image.alt}
                        breakpoints={{
                          MD: { ratio: 16 / 8 },
                          other: { ratio: 1 },
                        }}
                      />
                    )}
                    <div className="content">
                      <TextNew.Serif.SM
                        cid="step-title"
                        element={ElementTypes.H3}
                        weight={FontWeight.BOLD}
                      >
                        {content.title}
                      </TextNew.Serif.SM>
                      <TextNew.Sans
                        size={deviceState >= DeviceState.MD ? FontSizes.MD : FontSizes.SM}
                        cid="main-content"
                        weight={FontWeight.LIGHT}
                        element={ElementTypes.DIV}
                      >
                        <RichTextBlock text={content.caption} />
                      </TextNew.Sans>
                      <LinkTracker
                        meta={{
                          category: Category.PUBLIC_HOMEPAGE,
                          action: trackMap[index].action,
                          [trackMap[index].name]: 1,
                        }}
                      >
                        <Button
                          isOnBackgroundColor
                          to={pathTo(index)}
                          cid="content-cta"
                          tabIndex={activeIndex !== index ? '-1' : undefined}
                        >
                          <TextNew.PrimaryFootnote
                            localeId={`colorCarousel.buttonLabels.${index}.button`}
                            element={ElementTypes.SPAN}
                            color={Colors.PRIMARY_LIGHT}
                            cid="cta-button-text"
                          />
                        </Button>
                      </LinkTracker>
                    </div>
                  </div>
                )}
              </Wrapper>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

ColorCarousel.propTypes = {
  deviceState: PropTypes.number,
  carouselContent: colorCarouselPropTypes,
};

export default compose(
  errorBoundary(),
  withDeviceState(),
  withFunctionalClassName(ComponentType.MOLECULE, 'ColorCarousel'),
)(ColorCarousel);
