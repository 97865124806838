import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import './animated-input.scss';

const AnimatedInput = ({ onBlur, onFocus, stacked, value, ...props }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(value !== '' && value !== undefined && value !== null);
    return () => setActive(value !== '' && value !== undefined && value !== null);
  }, [value]);

  const handleBlur = e => {
    if (onBlur) {
      onBlur(e);
    }
    setActive(value !== '' && value !== undefined && value !== null);
  };

  const handleFocus = e => {
    if (onFocus) {
      onFocus(e);
    }
    setActive(true);
  };

  return (
    <div
      className={functionalComponentClassName(ComponentType.ATOM, 'animated-input-wrapper', null, {
        'stacked-input': stacked,
        active,
      })}
    >
      <input {...props} value={value} onBlur={handleBlur} onFocus={handleFocus} />
      <div className="descriptor-wrapper">
        <span className="descriptor">
          <span className="mask" />
          {props.placeholder}
        </span>
      </div>
    </div>
  );
};

AnimatedInput.propTypes = {
  fields: PropTypes.array,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.node,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  stacked: PropTypes.bool,
  dataTestid: PropTypes.string,
};

export default AnimatedInput;
