import handleActions from 'redux-actions/lib/handleActions';
import { LOCATION_CHANGE } from 'react-router-redux/lib/reducer';

import {
  CLOSE_ALL_HEADER_MENUS,
  HEADER_TOGGLE_FIXED,
  HEADING_MAIN_MENU_TOGGLE,
  PROFILE_MENU_TOGGLE,
  NOTIFICATION_MENU_TOGGLE,
  TOGGLE_UI_FEATURES,
} from '../../../actions/components/headerActions';
import { NavViews } from '../../../data/enum/NavViews';

const initialState = {
  height: null,
  isFixed: null,
  showMenu: null,
  showProfileMenu: null,
  showNotificationDropdown: null,
  toggleUiFeatures: {
    [NavViews.HIDE_NAV_LINKS]: false,
    [NavViews.HIDE_GROUP_SEARCH_BAR]: false,
    [NavViews.HIDE_CARD_REG]: false,
    [NavViews.HIDE_COUNTRY_DROPDOWN]: false,
  },
};

export default handleActions(
  {
    [CLOSE_ALL_HEADER_MENUS]: state => ({
      ...state,
      showProfileMenu: false,
      showMenu: false,
      showNotificationDropdown: false,
    }),
    [LOCATION_CHANGE]: state => ({
      ...state,
      showProfileMenu: false,
      showMenu: false,
      showNotificationDropdown: false,
    }),
    [HEADER_TOGGLE_FIXED]: (state, { payload: { height, isFixed } }) => ({
      ...state,
      height,
      isFixed,
    }),
    [HEADING_MAIN_MENU_TOGGLE]: state => ({
      ...state,
      showMenu: !state.showMenu,
      showProfileMenu: false,
      showNotificationDropdown: false,
    }),
    [PROFILE_MENU_TOGGLE]: state => ({
      ...state,
      showProfileMenu: !state.showProfileMenu,
      showMenu: false,
      showNotificationDropdown: false,
    }),
    [NOTIFICATION_MENU_TOGGLE]: state => ({
      ...state,
      showNotificationDropdown: !state.showNotificationDropdown,
      showMenu: false,
      showProfileMenu: false,
    }),
    [TOGGLE_UI_FEATURES]: (state, { payload }) => ({
      ...state,
      toggleUiFeatures: payload,
    }),
  },
  initialState,
);
