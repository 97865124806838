import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import Input from '../../atoms/Input';
import Icon from '../../atoms/Icon';
import IconButton from '../IconButton';

import './icon-input.scss';

/**
 * This component is simply an input with an icon
 * displayed to the within
 */

const IconInput = (
  { icon, buttonLabel, isIconButton, iconButtonType, iconPosition, iconSize, onClick, ...props },
  context,
  className,
  dataTestid,
) => (
  <div className={className}>
    <Input {...props} dataTestid={dataTestid} />
    {isIconButton && icon && (
      <IconButton
        buttonLabel={buttonLabel}
        iconPosition={iconPosition}
        onClick={onClick}
        icon={icon}
        width={iconSize.width}
        height={iconSize.height}
        type={iconButtonType}
      />
    )}
    {!isIconButton && icon && <Icon name={icon} {...iconSize} />}
  </div>
);

IconInput.defaultProps = {
  iconPosition: DirectionType.RIGHT,
  descriptorType: 'static',
  iconSize: {
    width: null,
    height: null,
  },
};

IconInput.propTypes = {
  /**
   * This will displayed within the component
   */
  placeholder: PropTypes.string,
  /**
   * This is the name of the icon+
   */
  icon: PropTypes.string,
  /**
   * This is the type prop of the icon button (if true the icon is a button)
   */
  isIconButton: PropTypes.bool,
  /**
   * This is the type prop of the icon button type (ignored if isIconButton is false)
   */
  iconButtonType: PropTypes.string,
  /**
   * A class that determines position of the icon
   */
  // eslint-disable-next-line react/no-unused-prop-types
  iconPosition: PropTypes.oneOf([DirectionType.LEFT, DirectionType.RIGHT]),
  /**
   * onClick action for IconButton
   */
  onClick: PropTypes.func,
  /**
   * Icon width and height
   */
  iconSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  buttonLabel: PropTypes.string,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'IconInput', [
  'icon-position-{iconPosition}',
])(IconInput);
