export const measurementFields = {
  INITIAL_WEIGHT: 'initialWeight',
  TARGET_WEIGHT: 'targetWeight',
  WEIGHT_UNIT: 'weightUnit',
  HEIGHT_UNIT: 'heightUnit',
  HEIGHT: 'height',
  INITIAL_HEIGHT: 'height',
};

export const journeyFields = {
  RESTART_JOURNEY: 'restartJourney',
  START_JOURNEY: 'startJourney',
  JOURNEY_START_DATE: 'journeyStartDate',
  JOURNEY_START_WEIGHT: 'journeyStartWeight',
  JOURNEY_CURRENT_WEIGHT: 'journeyCurrentWeight',
};

export const medicalFields = {
  HAS_MEDICAL_CONDITIONS: 'hasMedicalConditions',
  ARE_MEDICAL_CONDITIONS: 'medical',
  ARTHRITIS: '0',
  COELIAC_DISEASE: '1',
  DIABETES: '2',
  HEART_DISEASE: '3',
  HIGH_BLOOD_PRESSURE: '4',
  HIGH_CHOLESTEROL: '5',
  OSTEOPOROSIS: '6',
  FOOD_ALLERGIES: '8',
  IBS: '9',
  LACTOSE_INTOLERANCE: '10',
  PCOS: '11',
  WEIGHT_LOSS_SURGERY: '12',
};

export const medicalFieldsToString = {
  [medicalFields.ARTHRITIS]: 'hasBoneProblems',
  [medicalFields.COELIAC_DISEASE]: 'isCoeliac',
  [medicalFields.DIABETES]: 'hasDiabetes',
  [medicalFields.HEART_DISEASE]: 'hasHeartDisease',
  [medicalFields.HIGH_BLOOD_PRESSURE]: 'hasHighBloodPressure',
  [medicalFields.HIGH_CHOLESTEROL]: 'hasHighCholesterol',
  [medicalFields.OSTEOPOROSIS]: 'hasOsteoperosis',
  [medicalFields.FOOD_ALLERGIES]: 'hasFoodAllergies',
  [medicalFields.IBS]: 'hasIbs',
  [medicalFields.LACTOSE_INTOLERANCE]: 'hasLactoseIntolerance',
  [medicalFields.PCOS]: 'hasPcos',
  [medicalFields.WEIGHT_LOSS_SURGERY]: 'hadWeightLossSurgery',
};

export const dietaryFields = {
  NO_PREFERENCE: 'noDietaryPreference',
  HAS_PREFERENCE: 'diet',
  VEGETARIAN: '0',
  EXCLUDE_RED_MEAT: '5',
  EXCLUDE_PORK: '1',
  EXCLUDE_POULTRY: '6',
  EXCLUDE_FISH: '2',
  EXCLUDE_EGGS: '7',
  EXCLUDE_DAIRY: '3',
  EXCLUDE_NUTS: '8',
  EXCLUDE_GLUTEN: '4',
  VEGAN: '11',
};

export const dietaryFieldsToString = {
  [dietaryFields.EXCLUDE_DAIRY]: 'excludeDairy',
  [dietaryFields.EXCLUDE_EGGS]: 'excludeEgg',
  [dietaryFields.EXCLUDE_FISH]: 'excludeFish',
  [dietaryFields.EXCLUDE_GLUTEN]: 'excludeGluten',
  [dietaryFields.EXCLUDE_NUTS]: 'excludeNut',
  [dietaryFields.EXCLUDE_PORK]: 'excludePork',
  [dietaryFields.EXCLUDE_POULTRY]: 'excludePoultry',
  [dietaryFields.EXCLUDE_RED_MEAT]: 'excludeRedMeat',
  [dietaryFields.VEGETARIAN]: 'isVegetarian',
  [dietaryFields.VEGAN]: 'isVegan',
};

export const userDetailFields = {
  USER_NAME: 'userName',
  FIRST_NAME: 'firstName',
  FULL_NAME: 'fullName',
  LAST_NAME: 'lastName',
  DATE_OF_BIRTH: 'dateOfBirth',
  DATE_JOINED_SLIMMING_WORLD: 'dateJoinedSlimmingWorld',
  AVATAR: 'avatar',
  PHONE_NUMBER: 'phoneNumber',
  TIME_ZONE: 'timeZoneId',
  GENDER: 'gender',
  IS_PREGNANT: 'isPregnant',
  WARNING_IS_PREGNANT: 'isPregnantWarning',
  CHOSEN_GROUP: 'chosenGroup',
  IS_BREASTFEEDING: 'isBreastfeeding',
  BABY_DATE_OF_BIRTH: 'babyDateOfBirth',
  SECURITY_QUESTION: 'securityQuestionId',
  SECURITY_ANSWER: 'securityQuestionAnswer',
  BABY_REMINDER_EMAIL_ADDRESS: 'babyReminderEmailAddress',
  INVITE_ID: 'inviteId',
  WEIGH_IN_DAY: 'weighInDay',
  JOIN_HALL_OF_FAME: 'joinHallOfFame',
  LIVE_EVENT_DAY: 'dayOfWeek',
  LIVE_EVENT_TIME_SLOT: 'timeSlot',
  GROUP_JOIN_DATE: 'groupJoinDate',
  GROUP_JOIN_DATE_RADIO: 'groupJoinDateRadio',
  GROUP_DAY: 'groupDay',
  USE_CURRENT_WEIGHT: 'useCurrentWeight',
};

export const membershipCardFields = {
  CARD_NUMBER: 'cardNumber',
  PIN: 'pin',
  CONFIRM_START_AFRESH: 'confrmStartAfresh',
  RECAPTCHA: 'recaptcha',
};

export const emailFields = {
  EMAIL_ADDRESS: 'email',
  NEW_EMAIL_ADDRESS: 'newEmail',
  CONFIRM_EMAIL_ADDRESS: 'emailConfirm',
  CONTACT_MESSAGE: 'contactMessage',
  CATEGORY: 'category',
  VEGETARIAN: 'isVegetarian',
};

export const passwordFields = {
  NEW_PASSWORD: 'newPassword',
  OLD_PASSWORD: 'oldPassword',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'passwordConfirm',
};

export const addressFields = {
  PERSONAL_DETAILS: 'personalDetails',
  YOUR_ADDRESS: 'yourAddress',
  SHIPPING_ADDRESS: 'shippingAddress',
  BILLING_ADDRESS: 'billingAddress',
  ADDRESS_LINE_1: 'addressLine1',
  ADDRESS_LINE_2: 'addressLine2',
  ADDRESS_LINE_3: 'addressLine3',
  CITY_OR_TOWN: 'city',
  COUNTY: 'county',
  COUNTY_IE: 'countyIe',
  ZIP_OR_POSTAL: 'zip',
  STATE: 'state',
  COUNTRY: 'country',
  GROUP_SEARCH_POST_CODE: 'groupSearchPostcode',
  IS_CONTACT_ADDRESS: 'isContactAddress',
  POSTCODE: 'postcode',
};

export const termsFields = {
  CONTINUE_WITHOUT_JOINING: 'continueWithoutJoining',
  SUPPORT_EMAILS: 'receiveSupportEmails',
  NOTIFICATION_EMAILS: 'receiveEmailNotifications',
  TERMS_OF_USE: 'termsOfUse',
  PRIVACY_POLICY: 'privacyPolicy',
  USER_SUBSCRIPTION: 'userSubscription',
  RECEIVE_CONSULTANT_SUPPORT_EMAILS: 'receiveConsultantSupportEmails',
  TOS_VERSION: 'termsOfServiceVersion',
};

export const shopFields = {
  VOUCHER_CODE: 'voucherCode',
  VOUCHER_CODE_EDIT: 'voucherCodeEdit',
  PACKAGE: 'package',
  REGION: 'region',
};

export const purchaseFields = {
  PURCHASES: 'purchases',
  PURCHASE_RETURN_ITEMS: 'purchaseReturnItems',
  PURCHASE_SELECTED: 'purchaseSelected',
};

export const returnFields = {
  APPLY_TO_ALL: 'applyToAll',
  REASON_APPLY_TO_ALL: 'reasonApplyToAll',
  RETURNS: 'returns',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
};

export const pregnancyNotificationFields = {
  PACKAGE: 'package',
};

export const loginFields = {
  USER_NAME: 'userName',
  PASSWORD: 'password',
  REMEMBER_ME: 'rememberMe',
};

export const appSignUp = {
  ANDROID: 'android',
  APPLE: 'apple',
};

export const transferToGroupFields = {
  CONFIRM_GROUP_TRANSFER: 'confirmGroupTransfer',
  VOUCHER_DELIVERY_ADDRESS: 'voucherDeliverypAddress',
};

export const transferToOnlineFields = {
  CONFIRM_PREGNANCY: 'confirmPregnant',
  CONFIRM_MEDICAL: 'confirmMedical',
  CONFIRM_COUNTDOWN: 'confirmCountdown',
};

export const multiFactorAuthenticationFields = {
  CODE: 'code',
};
