import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { setRecaptchaToken } from 'common/src/app/actions/configActions';
import { updateSubmissionCount } from 'common/src/app/actions/resources/groupAccountActions';

import ReCaptchaCheckbox from './ReCaptchaCheckbox';

const mapDispatchToProps = dispatch => ({
  setToken: token => dispatch(setRecaptchaToken(token)),
  resetSubmissionCount: () => dispatch(updateSubmissionCount({ reset: true })),
});

const mapStateToProps = state => ({
  siteKey: state.config.environmentConfig?.recaptcha?.siteKey,
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(ReCaptchaCheckbox);
