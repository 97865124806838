import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import InputType from 'common/src/app/data/enum/InputType';
import { dropDownBasedGroupSearch } from 'common/src/app/config/market/market.configdefinitions';
import {
  ContactUsAdvertisementPublicField,
  ContactUsPublicField,
  NotRequiredAdvertisementFields,
  NotRequiredDefaultFields,
  getContactUsDropDownOptions,
  ContactUsMediaPublicFields,
} from 'common/src/app/data/enum/FieldNames/ContactUsFieldNames';
import { ContactUsType } from 'common/src/app/data/enum/ContactUsType';
import { ContactFormSubjectIds } from 'common/src/app/data/enum/ContactFormCategory';
import ContactUsForm from '../../../../ContactUsForm';
import GroupSearchCounty from '../../../../../atoms/GroupSearchCounty';
import Input from '../../../../../atoms/Input';
import FormikError from '../../../../../atoms/FormikError';
import Select from '../../../../../atoms/Select';
import Textarea from '../../../../../atoms/Textarea';
import { DateInputGroup } from '../../../../../atoms/Form';

const createFormFieldsObject = fieldObject =>
  Object.values(fieldObject).reduce((fields, field) => ({ ...fields, [field]: false }), {});

class ContactUsPublicForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...createFormFieldsObject(ContactUsPublicField),
    };
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props;

    if (prevProps?.values?.contactFormCategory !== values?.contactFormCategory) {
      // Use the default form fields
      let formFocusFields = createFormFieldsObject(ContactUsPublicField);

      // isContactTypeAdvertisement
      if (values?.contactFormCategory === ContactFormSubjectIds.ADVERTISEMENT_ARTWORK) {
        formFocusFields = createFormFieldsObject(ContactUsAdvertisementPublicField);
      }

      // isContactTypeMedia
      if (values?.contactFormCategory === ContactFormSubjectIds.MEDIA_ENQUIRIES) {
        formFocusFields = createFormFieldsObject(ContactUsMediaPublicFields);
      }

      this.setState({ ...formFocusFields });
    }
  }

  setFocus = (field, focus) => () => {
    this.setState({ [field]: focus });
  };

  render() {
    const { values, handleChange, handleBlur, setFieldValue } = this.props;
    const { getMessage } = this.context;
    const { contactFormCategory } = values;
    const isContactTypeAdvertisement =
      contactFormCategory === ContactFormSubjectIds.ADVERTISEMENT_ARTWORK;
    const isContactTypeMedia = contactFormCategory === ContactFormSubjectIds.MEDIA_ENQUIRIES;

    // Set the default fields
    let formFields = ContactUsPublicField;
    let notRequired = NotRequiredDefaultFields;

    // Advertisement enquiry
    // - Update the form fields and not required form fields
    if (isContactTypeAdvertisement) {
      formFields = ContactUsAdvertisementPublicField;
      notRequired = NotRequiredAdvertisementFields;
    }

    // Media enquiry
    // - Update the form fields
    if (isContactTypeMedia) {
      formFields = ContactUsMediaPublicFields;
    }

    return (
      <>
        <ContactUsForm {...this.props}>
          {Object.values(formFields).map(field => {
            let placeHolderKey = ContactUsType.DEFAULT;

            // Advertisement placeholder
            if (isContactTypeAdvertisement) {
              placeHolderKey = ContactUsType.ADVERTISEMENT;
            }

            // Media enquiry placeholder
            if (isContactTypeMedia) {
              placeHolderKey = ContactUsType.MEDIA;
            }

            const placeholder = getMessage(`contactUsForm.placeholder.${placeHolderKey}.${field}`);

            if (field === formFields.POSTCODE && dropDownBasedGroupSearch) {
              return (
                <GroupSearchCounty
                  key={field}
                  autocomplete="off"
                  onChange={handleChange}
                  name={field}
                  seoObject={{
                    metaTitle: "How to contact Slimming World's warm and friendly support teams",
                    metaDescription:
                      "How to contact Slimming World's warm and friendly support teams",
                  }}
                  placeholder={getMessage('contactUsForm.placeholder.default.county')}
                  value={values[field]}
                />
              );
            }

            if (field === formFields.ORIENTATION || field === formFields.COLOR_PREFERENCE) {
              const selectOptions = [
                {
                  title: placeholder,
                  value: '',
                  disabled: true,
                },
                ...getContactUsDropDownOptions({ field, getMessage }),
              ];

              return (
                <Select
                  defaultValue=""
                  key={field}
                  name={field}
                  options={selectOptions}
                  onChange={handleChange}
                />
              );
            }

            if (field === formFields.DATE) {
              return (
                <DateInputGroup
                  key={field}
                  type="inline"
                  label={placeholder}
                  onDateChange={date => moment(date).isValid() && setFieldValue(field, date)}
                />
              );
            }

            if (field === formFields.ADVERT_SIZE || field === formFields.GROUP_INFORMATION) {
              return (
                <Textarea
                  key={field}
                  type={InputType.TEXT}
                  name={field}
                  placeholder={placeholder}
                  onChange={handleChange}
                  rows="4"
                />
              );
            }

            return (
              <Fragment key={field}>
                <Input
                  autocomplete={
                    field === formFields.CARD_NUMBER || field === formFields.POSTCODE ? 'off' : ''
                  }
                  convertOnChange={false}
                  name={field}
                  onBlur={e => {
                    handleBlur(e);
                    this.setState({ [field]: values[field] !== '' });
                  }}
                  placeholder={placeholder}
                  onChange={handleChange}
                  hasValue={typeof values[field] !== 'undefined' && values[field] !== ''}
                  meta={{ active: this.state[field], dirty: values[field] !== '' }}
                  onFocus={this.setFocus(field, true)}
                  type={InputType.TEXT}
                  dataTestid={field}
                />
                {field !== ContactUsPublicField.TELEPHONE && (
                  <FormikError
                    errorName={
                      !notRequired.includes(field)
                        ? getMessage(`contactUsForm.error.required.${field}`)
                        : ''
                    }
                    name={field}
                  />
                )}
              </Fragment>
            );
          })}
        </ContactUsForm>
      </>
    );
  }
}

ContactUsPublicForm.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

ContactUsPublicForm.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
};

export default ContactUsPublicForm;
