import React from 'react';
import PropTypes from 'prop-types';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import ArticleType from 'common/src/app/data/enum/ArticleType';
import IconName from 'common/src/app/data/enum/IconName';
import MasterTile from 'components/organisms/MasterTile';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import TextNew, { Colors } from 'components/atoms/TextNew';
import LocaleMessage from 'components/atoms/LocaleMessage';
import IconButton from 'components/molecules/IconButton';

const SingleDayMenu = ({ day, dayIndex, currentDayIndex }) => (
  <div className="single-day-menu-grid" dataTestid="day-grid">
    {['breakfast', 'lunch', 'dinner', 'snack'].map((mealType, index) => {
      const { slug, image, title, description, link } = day?.[mealType]?.[0] || [];

      return (
        <div className="single-day-menu-grid-item" key={`day-menu-${mealType}`} data-testid="meals">
          <TextNew.Script.XS
            cid="seven-day-menu-title"
            color={Colors.BRAND}
            localeId={`sevenDayMenu.carousel.headers.${mealType}`}
          />
          <MasterTile
            _type={ArticleType.DEFAULT}
            id={`masterTile-${index}`}
            image={image}
            title={title}
            slug={slug}
            customDescription={
              <div className="single-day-content">
                <RichTextBlock text={description} dataTestid="step-text" />
                {link && (
                  <IconButton
                    cid="chevron"
                    width={6}
                    icon={IconName.CHEVRON_RIGHT}
                    iconPosition="right"
                    variant="tertiary"
                    tabIndex={dayIndex === currentDayIndex ? 0 : '-1'}
                    to={link}
                    buttonLabel="Go to recipe"
                  >
                    <LocaleMessage id="sevenDayMenu.recipeLink" />
                  </IconButton>
                )}
              </div>
            }
          />
        </div>
      );
    })}
  </div>
);

SingleDayMenu.propTypes = {
  day: PropTypes.object,
  dayIndex: PropTypes.number,
  currentDayIndex: PropTypes.number,
};

export default withDeviceState()(SingleDayMenu);
