import React, { Fragment } from 'react';
import Route from 'react-router/lib/Route';
import Pages from 'common/src/app/data/enum/Pages';
import routeCallback from 'common/src/app/util/routeCallback';
import PolicyContent from 'bundle-loader?lazy&name=PolicyContent!./index';

const routes = (
  <Fragment>
    <Route
      path={Pages.COOKIESPOLICY}
      getComponent={routeCallback(PolicyContent)}
      title="Find out how we use Cookies across the Slimming World websites. | {pageTitle}"
      description="This page covers Slimming World's use of cookies across our websites."
    />
    <Route
      path={Pages.PRIVACYPOLICY}
      getComponent={routeCallback(PolicyContent)}
      title="Slimming World's Privacy Promise to our members | {pageTitle}"
      description="Slimming World's Privacy Promise outlines our commitment to confidentiality, including how we use member information."
    />
    <Route
      path={Pages.TERMS_OF_USE}
      getComponent={routeCallback(PolicyContent)}
      title="Information about terms of use for Slimming World groups and digital services | {pageTitle}"
      description="Terms and conditions for Slimming World Group members and Slimming World Online members"
    />
  </Fragment>
);

export default routes;
