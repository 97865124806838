import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Size from 'common/src/app/data/enum/Size';
import Wrapper from 'components/atoms/Wrapper';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import Loader from 'components/atoms/Loader';
import { schemaUrl, FaqSeo } from 'common/src/app/data/enum/SeoSchema';
import PageTitleHeader from '../../molecules/PageTitleHeader';
import FranchiseFactsFaq from '../../organisms/FranchiseFactsFaq';
import ConsultantCtaBlock from '../../organisms/ConsultantCtaBlock';
import createScriptTitle from '../../../../../../utils/createScriptTitle';
import consultantPageNames from '../../../../../../data/enum/consultantPageNames';
import './franchise-facts.scss';

const FranchiseFacts = ({ loading, pageData }, context, className) => (
  <div className={className} itemScope itemType={`${schemaUrl}${FaqSeo.SCHEMA.FAQ_PAGE}`}>
    <PageTitleHeader hasSwoosh localePrefix={consultantPageNames.FRANCHISE_FACTS} />

    {loading && <Loader />}

    <Wrapper cid="content-wrapper" padding={Size.XL}>
      {!loading &&
        // eslint-disable-next-line react/prop-types
        pageData?.nestedContent.map(({ _type, ...content }, index) => {
          switch (_type) {
            case types.ROLE_BASED_CONTENT:
              return <FranchiseFactsFaq {...content} key={`franchise-facts-block-${index}`} />;

            default:
              return (
                <RichTextBlock
                  title={createScriptTitle(content.title)}
                  text={createScriptTitle(content.text)}
                  key={`franchise-facts-block-${index}`}
                />
              );
          }
        })}
    </Wrapper>
    <ConsultantCtaBlock localePrefix={consultantPageNames.FRANCHISE_FACTS} />
  </div>
);

FranchiseFacts.propTypes = {
  loading: PropTypes.bool.isRequired,
  pageData: PropTypes.object.isRequired,
};

export default withFunctionalClassName(ComponentType.TEMPLATE, 'FranchiseFacts')(FranchiseFacts);
