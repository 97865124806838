import { DURATION_HOURS, DURATION_MINUTES } from '../../data/regexPatterns';

/**
 * Convert a combined duration string back into separate hours and minutes input
 * values
 * @param {string} durationString A duration string formatted as 'PT0H0M'
 * @returns {{hours: string, minutes: string}} The input values
 */
const decomposeDuration = durationString => {
  if (!durationString) {
    return {
      hours: '',
      minutes: '',
    };
  }

  return {
    hours: durationString.match(DURATION_HOURS)
      ? durationString.match(DURATION_HOURS).toString()
      : '',
    minutes: durationString.match(DURATION_MINUTES)
      ? durationString.match(DURATION_MINUTES).toString()
      : '',
  };
};

export default decomposeDuration;
