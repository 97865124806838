import { createSelector } from 'reselect';

/**
 * Gets the recaptcha token value from state
 */
export const getRecaptchaToken = createSelector(
  [state => state?.config?.recaptchaToken],
  recaptchaToken => recaptchaToken,
);

export default getRecaptchaToken;
