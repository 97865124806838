import PropTypes from 'prop-types';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';

const colorCarouselPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    _type: PropTypes.string,
    caption: PropTypes.string,
    image: PropTypes.shape(imageShape),
    link: PropTypes.string,
    palette: PropTypes.string,
    title: PropTypes.string,
  }),
);

export default colorCarouselPropTypes;
