import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { Colors, AccentColors, ElementTypes, FontSizes } from 'components/atoms/TextNew';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import NearestGroupSearchBar from 'components/organisms/NearestGroupSearchBar';
import NavigationController from 'components/atoms/NavigationController';
import { NavViews } from 'common/src/app/data/enum/NavViews';

const GroupNotFound = ({ deviceState }, context, className) => (
  <div className={className} data-testid="group-not-found">
    <div className="copy-without-background">
      <TextNew.Script.MD
        color={Colors.BRAND}
        localeId="smartNotFound.header"
        element={ElementTypes.H2}
        cid="sorry"
      />
      <TextNew.Serif.SM
        color={Colors.BRAND}
        localeId="smartNotFound.body"
        element={ElementTypes.H3}
        cid="alt"
      />
      <TextNew.Sans.SM color={Colors.BRAND} localeId="smartNotFound.group.tagLine" />
    </div>
    <h1 className="intro">
      <TextNew.Serif.SM
        cid="group-title"
        color={AccentColors.ACCENT_PURPLE}
        element={ElementTypes.SPAN}
        localeId="smartNotFound.group.title"
        size={deviceState > DeviceState.MD ? FontSizes.MD : FontSizes.SM}
      />
      <TextNew.Script.SM
        cid="group-sub-title"
        color={AccentColors.ACCENT_PURPLE}
        element={ElementTypes.SPAN}
        localeId="smartNotFound.group.subTitle"
        size={deviceState > DeviceState.MD ? FontSizes.LG : FontSizes.SM}
      />
    </h1>
    <div className="details">
      <NearestGroupSearchBar isEmptySearchQuery defaultGroupSearch />
    </div>
    <NavigationController hideUiOptions={[NavViews.HIDE_GROUP_SEARCH_BAR]} />
  </div>
);

GroupNotFound.propTypes = {
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.PAGE, 'GroupNotFound')(GroupNotFound),
);
