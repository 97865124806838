import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';

import Row from 'components/atoms/Row';
import TextNew from 'components/atoms/TextNew';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/molecules/Tabs';

import GroupOrOnlineContent from '../../molecules/GroupOrOnlineContent';
import { groupOnlineShape } from '../../../propTypes/groupOnlineComparisonPropTypes';

import './group-or-online-content-wrapper.scss';

const GroupOrOnlineContentWrapper = ({ deviceState, group, online }, {}, className) => {
  const [displayTabbed, setDisplayTabbed] = useState(false);

  useEffect(() => {
    setDisplayTabbed(deviceState <= DeviceState.MD);
  }, [deviceState]);

  if (displayTabbed) {
    return (
      <div className={className}>
        <Tabs>
          <TabList>
            <Tab className="tab">
              <TextNew.Script.XS localeId="groupOrOnlineContentWrapper.group.tabHeading" />
            </Tab>
            <Tab className="tab">
              <TextNew.Script.XS localeId="groupOrOnlineContentWrapper.online.tabHeading" />
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Section data={group} isGroup />
            </TabPanel>
            <TabPanel>
              <Section data={online} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    );
  }

  return (
    <Row className={className}>
      <Section data={group} isTabbed={false} isGroup />
      <Section data={online} isTabbed={false} />
    </Row>
  );
};

GroupOrOnlineContentWrapper.propTypes = {
  group: PropTypes.shape({ ...groupOnlineShape }).isRequired,
  online: PropTypes.shape({ ...groupOnlineShape }).isRequired,
  deviceState: PropTypes.number,
};

const Section = ({ data, isTabbed = true, isGroup = false }) => (
  <GroupOrOnlineContent
    membershipType={isGroup ? 'group' : 'online'}
    isTabbed={isTabbed}
    offer={data?.introductoryOffer?.offer}
    title={data?.title}
    subtitle={data?.subtitle}
    description={data?.description}
    features={data?.features[0]}
    upperCTA={data?.upperCTA}
    lowerCTA={data?.lowerCTA}
  />
);

Section.propTypes = {
  data: PropTypes.shape({
    ...groupOnlineShape,
  }).isRequired,
  isTabbed: PropTypes.bool,
  isGroup: PropTypes.bool,
};

export default compose(
  withDeviceState(),
  withFunctionalClassName(ComponentType.ORGANISM, 'GroupOrOnlineContentWrapper'),
)(GroupOrOnlineContentWrapper);
