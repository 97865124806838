import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { push as historyPush } from 'react-router-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import Pages from 'common/src/app/data/enum/Pages';
import { getTowns } from 'common/src/app/actions/resources/groupSearchActions';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { nearestGroupTownsSelector } from 'common/src/app/selectors/nearestGroupSearchSelectors';
import { MATCH_HYPHENS } from 'common/src/app/data/regexPatterns';
import NearestGroupTownsInCounty from './NearestGroupTownsInCounty';

const EMPTY_OBJECT = {};

const countyFormatted = county => county.replace(MATCH_HYPHENS, '%20');

const mapStateToProps = (state, { params: { county } }) => ({
  towns: nearestGroupTownsSelector(state)(county) || EMPTY_OBJECT,
});

const mapDispatchToProps = dispatch => ({
  redirectToTowns: town =>
    dispatch(historyPush(`${Pages.UK_PUBLIC_NEAREST_GROUP_COUNTIES}/${town}/${town}`)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction(['params.county'], {
  prepared: ({ params: { county } }, dispatch) =>
    dispatch(getTowns(marketConfig.marketName, countyFormatted(county))),
});

export default compose(addInitAction, connected)(NearestGroupTownsInCounty);
