import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import Size from 'common/src/app/data/enum/Size';
import Roundel from 'components/atoms/Roundel';
import TextNew, {
  AccentColors,
  Colors,
  ElementTypes,
  FontWeight,
  FontStyles,
} from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Image from 'components/atoms/Image';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import getPublicPrices from 'common/src/app/util/getPublicPrices';
import moment from 'moment';
import { DMMMMYYYY } from 'common/src/app/data/enum/dateFormats';

import './print-magazine-promo.scss';

const PrintMagazinePromo = (
  { issueDate, printImage, bonusGift },
  context,
  className,
  dataTestid,
) => (
  <section className={className} data-testid={dataTestid}>
    <section className="container">
      <aside className="image">
        {printImage?.src && (
          <figure>
            <Image
              isLazy={false}
              src={printImage.src}
              ratio={320 / 431}
              dataTestid="print-magazine-image"
              alt={printImage?.alt}
            />
            <figcaption className="screen-reader">
              <LocaleMessage id="printMagazinePromo.imageCaption" />
            </figcaption>
          </figure>
        )}
        <div className="roundel">
          <Roundel backgroundColor={AccentColors.ACCENT_PINK} size={Size.LS}>
            <TextNew.Sans.XL
              color={Colors.PRIMARY_LIGHT}
              element={ElementTypes.H2}
              localeId="printMagazinePromo.roundel"
            />
          </Roundel>
        </div>
      </aside>
      <section className="details">
        <h2 className="title">
          <TextNew.Script.LG
            cid="promo-title-first"
            color={AccentColors.ACCENT_PINK}
            element={ElementTypes.SPAN}
            localeId="printMagazinePromo.title"
          />
          <TextNew.Serif.LG
            color={AccentColors.ACCENT_PINK}
            element={ElementTypes.SPAN}
            localeId="printMagazinePromo.subTitle"
          />
        </h2>

        <TextNew.Sans.SM
          color={AccentColors.ACCENT_PINK}
          element={ElementTypes.P}
          dataTestid="magazine-intro"
        >
          <LocaleMessage
            id="printMagazinePromo.introduction"
            params={{
              DATE: issueDate && (
                <TextNew.Sans.XL
                  element={ElementTypes.SPAN}
                  weight={FontWeight.BOLD}
                  cid="magazine-issue-date"
                >
                  {moment(issueDate).format(DMMMMYYYY)}
                </TextNew.Sans.XL>
              ),
            }}
          />
          {marketName === 'UK' ? (
            <span className="magazine-price">
              <LocaleMessage
                id="printMagazinePromo.price"
                params={{ PRICE: getPublicPrices('nonMember', 'magazine') }}
              />
            </span>
          ) : (
            <LocaleMessage id="printMagazinePromo.price" />
          )}
        </TextNew.Sans.SM>

        <Button
          href={PagesExternal.PRINT_MAGAZINE_SUBSCRIPTION_SELECT}
          title="Subscribe to the print edition of our magazine"
          cid="subscribe-button"
          isShadePink
        >
          <LocaleMessage id="printMagazinePromo.button" />
        </Button>

        {bonusGift && (
          <section className="bonus-gift-wrapper" data-testid="bonus-gift-section">
            {bonusGift?.image?.src && (
              <Image
                isLazy={false}
                src={bonusGift.image.src}
                ratio={400 / 482}
                dataTestid="bonus-gift-magazine-image"
                alt={bonusGift.image?.alt}
              />
            )}

            {bonusGift?.title && (
              <section className="gift-detail">
                <TextNew.Script.SM color={AccentColors.ACCENT_PINK} element={ElementTypes.H3}>
                  {bonusGift.title}
                </TextNew.Script.SM>
                {bonusGift?.gift && (
                  <TextNew.Sans.MD
                    color={AccentColors.ACCENT_PINK}
                    weight={FontWeight.BOLD}
                    element={ElementTypes.P}
                  >
                    {bonusGift.gift}
                  </TextNew.Sans.MD>
                )}
                {bonusGift?.price && (
                  <TextNew.Sans.XS color={AccentColors.ACCENT_PINK} element={ElementTypes.P}>
                    {bonusGift.price}
                  </TextNew.Sans.XS>
                )}
                {bonusGift?.description && (
                  <TextNew.Sans.XS color={AccentColors.ACCENT_PINK} element={ElementTypes.P}>
                    {bonusGift.description}
                  </TextNew.Sans.XS>
                )}
              </section>
            )}
          </section>
        )}

        <footer>
          <p className="magazine-disclaimer" data-testid="magazine-disclaimer">
            <TextNew.Sans.XS
              color={AccentColors.ACCENT_PINK}
              element={ElementTypes.SPAN}
              weight={FontWeight.BOLD}
              localeId="printMagazinePromo.dataProtection.heading"
            />
            <TextNew.Sans.XS
              localeId="printMagazinePromo.dataProtection.productName"
              element={ElementTypes.SPAN}
              color={AccentColors.ACCENT_PINK}
              style={FontStyles.ITALIC}
            />
            <TextNew.Sans.XS
              localeId="printMagazinePromo.dataProtection.disclaimer"
              element={ElementTypes.SPAN}
              color={AccentColors.ACCENT_PINK}
            />
          </p>
        </footer>
      </section>
    </section>
  </section>
);

PrintMagazinePromo.propTypes = {
  issueDate: PropTypes.string.isRequired,
  printImage: PropTypes.shape({
    ...imageShape,
  }).isRequired,
  bonusGift: PropTypes.shape({
    gift: PropTypes.string,
    image: PropTypes.shape({
      ...imageShape,
    }),
    price: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default compose(withFunctionalClassName(ComponentType.MOLECULE, 'PrintMagazinePromo'))(
  PrintMagazinePromo,
);
