import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { currentRoute } from 'common/src/app/util/routeCheckUtils';
import NavLink from './NavLink';

const mapStateToProps = state => ({
  currentRoute: currentRoute(state),
});

const connected = connect(mapStateToProps);

export default compose(connected)(NavLink);
