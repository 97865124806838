import debugLib from 'debug';
import { dataLayerPageView } from './dataLayerUtils';

const debug = debugLib('SlimmingWorld:tracking');

/**
 * This module contains general tracking utilities that will call all relevant
 * implementation-specific tracking utilities. Usage of these tracking utilities
 * (if available) is preferred above using the implementation-specific utils.
 * @module
 * @category tracking
 */

/**
 * Tracks a page view
 * @param data Data object for tracking
 * @param {object} [state] The current redux state, used to get tracking meta properties
 */
// eslint-disable-next-line import/prefer-default-export
export const trackPageView = (data, state) => {
  debug(`Tracking page view: ${state.tracking.persistentData.pathname}`);
  dataLayerPageView(data, state);
};
