import React from 'react';
import PropTypes from 'prop-types';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import LocaleMessage from 'components/atoms/LocaleMessage';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import { measurementFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import abbreviate from 'common/src/app/util/locale/abbreviate';
import './weight-input-unit-toggle.scss';

const WeightInputUnitToggle = ({ onClick, weightUnit, deviceState, onChange }) => {
  const imperialValue = `${MeasureUnit.IMPERIAL}`;
  const metricValue = `${MeasureUnit.METRIC}`;

  return (
    <div className="atom-weight-input-unit-toggle">
      <input
        name={measurementFields.WEIGHT_UNIT}
        id="imperial"
        type="radio"
        checked={weightUnit === imperialValue}
        value={imperialValue}
        onChange={onChange}
        data-testid="weight-unit-imperial"
      />
      <label htmlFor="imperial">
        <LocaleMessage id={`general.measureUnit.weight.stone.${abbreviate(deviceState)}`} />
      </label>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */}
      <a tabIndex="-1" className="toggle-slider" onClick={onClick}>
        <div
          className={`toggle-ball ${
            weightUnit === imperialValue ? DirectionType.LEFT : DirectionType.RIGHT
          }`}
        />
      </a>
      <input
        name={measurementFields.WEIGHT_UNIT}
        id="metric"
        type="radio"
        value={metricValue}
        checked={weightUnit === metricValue}
        onChange={onChange}
        data-testid="weight-unit-metric"
      />
      <label htmlFor="metric">
        <LocaleMessage id={`general.measureUnit.weight.kilogram.${abbreviate(deviceState)}`} />
      </label>
    </div>
  );
};

WeightInputUnitToggle.propTypes = {
  weightUnit: PropTypes.string,
  deviceState: PropTypes.number,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

export default withDeviceState()(WeightInputUnitToggle);
