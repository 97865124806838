import { connect } from 'react-redux';
import { COMPONENT } from 'common/src/app/data/entityTypes';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import compose from 'redux/lib/compose';
import { confirmNewsletterSignup } from 'common/src/app/actions/resources/publicActions';
import { API_COMPONENT_STATIC_PAGE_BACKGROUND } from 'common/src/app/data/apiComponents';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import ConfirmNewsletterSignupEmail from './ConfirmNewsletterSignupEmail';

const mapStateToProps = state => ({
  confirmationFlow: state?.newsletterSignupEmailConfirmation?.flow,
  menuType: state?.newsletterSignupEmailConfirmation?.menuType,
  background: state?.entities?.[COMPONENT]?.[API_COMPONENT_STATIC_PAGE_BACKGROUND]?.image,
});

const mapDispatchToProps = {
  confirmNewsletterSignup,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(API_COMPONENT_STATIC_PAGE_BACKGROUND)),
});

export default compose(addInitAction, connected)(ConfirmNewsletterSignupEmail);
