import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { API_STATIC_PUBLIC_OFFERS } from 'common/src/app/data/apiStatics';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';

import Offers from './Offers';

const mapStateToProps = state => ({
  page: staticPageSelector(state, API_STATIC_PUBLIC_OFFERS),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getStaticContent(API_STATIC_PUBLIC_OFFERS, true)),
})('Offers');

export default compose(addInitAction, connected)(Offers);
