/* eslint no-underscore-dangle: ["error", { "allow": ["_type"] }] */
/* global 'molecule','BlockContent' */
import React, { Fragment } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Wrapper from 'components/atoms/Wrapper';
import DeepLink from 'components/organisms/DeepLink';
import TextNew from 'components/atoms/TextNew';
import BlockContentComponentMap, { excludeBlockFromWrapper } from './enum/BlockTypes';
import errorBoundary from '../../hoc/ErrorBoundary/index';

import './block-content.scss';

/**
 * Assign the current data 'block' a component based on data _type (mapped from BlockContentComponentMap)
 */
export const getBlocks = data =>
  data
    .map(element => {
      const block = { data: element };
      if (Object.prototype.hasOwnProperty.call(BlockContentComponentMap, element._type)) {
        block.component = BlockContentComponentMap[element._type];
      }
      return block;
    })
    .filter(block => !!block.component);

/**
 * renderComponent renders the component assigned from getBlocks function
 * n.b. We are also adding a wrapper based on a prop and if the component _type is added to a blacklist
 * This should ideally be a prop on each component from umbraco or an actual wrapper block
 */
export const renderComponent = ({ errorTitleType, block, key, noWrapper, addDeepLinkSection }) => {
  const renderedBlock = (
    <Fragment key={key}>
      {/* this is for the policy pages to work manually */}
      {addDeepLinkSection && block.data.title && <DeepLink title={block.data.title} />}
      <block.component {...block.data}>
        {/* this line adds recursion */}
        {block.data.content &&
          createBlocks({ errorTitleType, data: block.data.content, noWrapper, addDeepLinkSection })}
      </block.component>
    </Fragment>
  );

  // TODO: REMOVE, SHOULD COME FROM UMBRACO AS RECURSIVE BLOCK
  if (!excludeBlockFromWrapper.includes(block.data._type) && noWrapper === false) {
    return (
      <Wrapper cid="block-content-wrapper" key={key}>
        {renderedBlock}
      </Wrapper>
    );
  }

  return renderedBlock;
};

renderComponent.propTypes = {
  block: PropTypes.object.isRequired,
  key: PropTypes.string,
  noWrapper: PropTypes.bool,
  addDeepLinkSection: PropTypes.bool,
  errorTitleType: PropTypes.string,
};

/**
 * createBlocks chains the getBlocks & renderComponent functions together, this allows us to reuse the
 * mapping and rendering of _types to component and reuse it within within the actual component and
 * recursively in in the renderComponent section
 */
export const createBlocks = ({ errorTitleType, data, noWrapper, addDeepLinkSection }) =>
  data &&
  getBlocks(data).map((block, i) =>
    renderComponent({
      errorTitleType,
      block,
      key: `${block.data._type}${i}`,
      noWrapper,
      addDeepLinkSection,
    }),
  );

/**
 * Block Content
 *
 * This component maps over a data object and recursively creates components.
 * We check for a component _type, passing it's data through.
 * If the component data also has a _type, we run the component generation down until
 * the last child
 *
 * "data": [
 *   {
 *     "content": [
 *       {
 *         "_type": "RichText",
 *         "data": {
 *            ...
 *         }
 *       },
 *       {...}
 *     ],
 *   }
 * ]
 *
 * Please note, for recursion to work we must allow our components to render children.
 * Currently some do not, manually tackling this step!
 */

const BlockContent = (
  { errorTitleType, data, historyPush, noWrapper, addDeepLinkSection },
  context,
  className,
) => {
  if (!data) {
    return (
      <div className={className}>
        <TextNew.Error localeId="errors.notFound" />
      </div>
      /* eslint-enable */
    );
  }
  return (
    <div // eslint-disable-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      className={className}
      onClick={e => {
        if (e.target.tagName === 'A') {
          const href = e.target.getAttribute('href');
          const target = e.target.getAttribute('target');
          if ((!target || target === '_self') && !href.match(/^[a-zA-Z\d]+:/)) {
            e.preventDefault();
            historyPush(href);
          }
        }
      }}
    >
      {createBlocks({ errorTitleType, data, noWrapper, addDeepLinkSection })}
    </div>
  );
};

BlockContent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      documentTypeAlias: PropTypes.string,
      description: PropTypes.string,
      nestedContent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.any),
        PropTypes.objectOf(PropTypes.any),
      ]),
      _type: PropTypes.string,
      slug: PropTypes.string,
      id: PropTypes.string,
      seo: PropTypes.array,
    }),
  ).isRequired,
  noWrapper: PropTypes.bool,
  historyPush: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  addDeepLinkSection: PropTypes.bool,
  // To locate to the correct local error message
  errorTitleType: PropTypes.string,
};

BlockContent.defaultProps = {
  addSectionPaddingTop: false,
  showFixedNavigation: false,
  width: 'md',
  padding: 'sm',
  noWrapper: false,
};

export default compose(errorBoundary(), withFunctionalClassName('molecule','BlockContent'))(BlockContent);
