/* global 'molecule','JanuaryCampaign2021Promotion' */
import React from 'react';
import PropTypes from 'prop-types';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import Image from 'components/atoms/Image';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import Loader from 'components/atoms/Loader';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import './january-campaign-2021-promotion.scss';

const getAsset = (assets, video = false, deviceState) => {
  if (!assets) return null;
  const [mobile, largeMobile, tablet, desktop, videoContent] = assets;

  if (video) return videoContent.video;

  if (deviceState > DeviceState.XL) {
    return [desktop?.image, 1509 / 1353];
  } else if (deviceState > DeviceState.MD) {
    return [tablet?.image, 1152 / 1602];
  } else if (deviceState > DeviceState.XS) {
    return [largeMobile?.image, 1245 / 1101];
  }
  return [mobile?.image, 960 / 987];
};

const JanuaryCampaign2021Promotion = (
  { openModal, isLoading, assets, deviceState },
  context,
  className,
) => {
  const openVideoModal = () =>
    openModal(
      ModalNames.VIDEO,
      {
        video: getAsset(assets, true),
      },
      false,
      false,
      true,
    );

  if (isLoading) return <Loader />;

  const [image, ratio] = getAsset(assets, false, deviceState) || [];

  return (
    <div className={className}>
      {image && (
        <button onClick={openVideoModal}>
          <Image ratio={ratio} src={image.src} alt={image.alt} />
        </button>
      )}
    </div>
  );
};

JanuaryCampaign2021Promotion.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  assets: PropTypes.array,
  deviceState: PropTypes.number.isRequired,
  openModal: PropTypes.func,
};

export default withDeviceState()(
  withFunctionalClassName('molecule','JanuaryCampaign2021Promotion')(JanuaryCampaign2021Promotion),
);
