import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import {
  contentComponentSelector,
  staticPageSelector,
} from 'common/src/app/selectors/publicSelectors';
import {
  GET_STATIC_CONTENT,
  getStaticContent,
} from 'common/src/app/actions/resources/staticContentActions';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { API_STATIC_PUBLIC_COMPARISON_PAGE } from 'common/src/app/data/apiStatics';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { API_COMPARISON_PAGE_HERO_IMAGE } from 'common/src/app/data/apiComponents';
import ComparisonPage from './ComparisonPage';

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT);

const mapStateToProps = state => {
  const pageData = staticPageSelector(state, API_STATIC_PUBLIC_COMPARISON_PAGE);

  return {
    heroImage: contentComponentSelector(state, API_COMPARISON_PAGE_HERO_IMAGE, 'image'),
    loading: pageLoading(state),
    pageData,
    structuredContent: pageData?.nestedContent[0]?.questions,
  };
};

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(getStaticContent(API_STATIC_PUBLIC_COMPARISON_PAGE, true)),
      dispatch(getComponent(API_COMPARISON_PAGE_HERO_IMAGE)),
    ]),
});

export default compose(addInitAction, connected)(ComparisonPage);
