import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptchaCheckbox = ({
  setToken,
  onChange,
  siteKey,
  resetTokenSubmissionCount,
  resetSubmissionCount,
}) => {
  const change = token => {
    if (token) {
      setToken(token);
      if (resetTokenSubmissionCount) {
        // Group registration card check
        // - reset the submission count
        // - set the recaptchaToken to null
        resetSubmissionCount();
        setToken(null);
      }
    }
    return onChange();
  };

  return (
    <ReCAPTCHA
      className="g-recaptcha"
      sitekey={siteKey}
      onChange={change}
      theme="light"
      size="normal"
    />
  );
};

ReCaptchaCheckbox.defaultProps = {
  resetTokenSubmissionCount: false,
};

ReCaptchaCheckbox.propTypes = {
  onChange: PropTypes.func,
  siteKey: PropTypes.string.isRequired,
  setToken: PropTypes.func.isRequired,
  resetTokenSubmissionCount: PropTypes.bool,
  resetSubmissionCount: PropTypes.func.isRequired,
};

export default ReCaptchaCheckbox;
