import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';

import { DeviceState } from 'common/src/app/data/MediaQueries';
import IconName from 'common/src/app/data/enum/IconName';

import imageShape from 'common/src/app/util/proptypes/apiEntities/image';

import Link from 'react-router/lib/Link';
import Image from 'components/atoms/Image';
import TextNew, {
  Colors,
  AccentColors,
  FontWeight,
  FontSizes,
  ElementTypes,
} from 'components/atoms/TextNew';
import Icon from 'components/atoms/Icon';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';

import './advertise-with-us.scss';

const AdvertiseWithUs = (
  { deviceState, isStandAlone, content },
  _context,
  className,
  dataTestid,
) => {
  const { contactLandline, contactMobile } = content || {};
  const contactNumbers = [contactMobile, contactLandline].filter(Boolean).map(number => (
    <a key={number} className="telephone-number" href={`tel:${number}`}>
      {number}
    </a>
  ));

  return (
    <div className={className} data-testid={dataTestid}>
      {content && (
        <>
          <header>
            <TextNew
              cid="title-text"
              color={AccentColors.ACCENT_PURPLE}
              element={isStandAlone ? ElementTypes.H1 : ElementTypes.DIV}
            >
              <TextNew.Script.XL color={AccentColors.ACCENT_PURPLE} element={ElementTypes.SPAN}>
                {content.titleLine1}
              </TextNew.Script.XL>{' '}
              <TextNew.Serif.SM
                cid="magazine-title"
                color={AccentColors.ACCENT_PURPLE}
                element={ElementTypes.SPAN}
              >
                {content.titleLine2}
              </TextNew.Serif.SM>
            </TextNew>
          </header>
          <div className="content">
            <section className="contacting">
              <TextNew.Sans.SM weight={FontWeight.LIGHT} element={ElementTypes.DIV} cid="body-text">
                <RichTextBlock text={content.bodyText} />
              </TextNew.Sans.SM>
              <address className="contact-details">
                <TextNew.Sans.SM color={Colors.DARK_TONE} weight={FontWeight.BOLD}>
                  {content.contactName}
                </TextNew.Sans.SM>
                <TextNew.Sans.SM color={Colors.DARK_TONE} weight={FontWeight.BOLD}>
                  {content.contactTitle}
                </TextNew.Sans.SM>
                <TextNew.Sans.SM color={Colors.DARK_TONE} weight={FontWeight.BOLD} cid="number">
                  {contactNumbers}
                </TextNew.Sans.SM>
                <Link href={`mailto:${content.contactEmail}`}>
                  <TextNew.Sans.SM
                    cid="use-underline"
                    color={Colors.DARK_TONE}
                    weight={FontWeight.BOLD}
                    element={ElementTypes.SPAN}
                  >
                    {content.contactEmail}
                  </TextNew.Sans.SM>
                </Link>
              </address>
            </section>
            <section className="media-pack">
              <div className="info">
                <TextNew.Serif.LG color={AccentColors.ACCENT_PURPLE} element={ElementTypes.H2}>
                  {content.mediaPackTitle}
                </TextNew.Serif.LG>
                <TextNew.Sans.SM
                  cid="media-pack-subtext"
                  color={Colors.DARK_TONE}
                  weight={FontWeight.LIGHT}
                >
                  {content.mediaPackBodyText}
                </TextNew.Sans.SM>
                <TextNew.Sans.SM
                  color={Colors.DARK_TONE}
                  weight={FontWeight.LIGHT}
                  cid="media-pack-intro"
                >
                  {content.mediaPackContentsHeading}
                </TextNew.Sans.SM>
                <div className="pack-includes">
                  <ul>
                    {content.mediaPackContentsList.map((listItem, index) => (
                      <li key={index}>
                        <Icon name={IconName.CHECKMARK} width={8} />
                        <TextNew.Sans.XS color={Colors.DARK_TONE} weight={FontWeight.LIGHT}>
                          {listItem.text}
                        </TextNew.Sans.XS>
                      </li>
                    ))}
                  </ul>
                  {content.mediaPackImage?.src && (
                    <Image
                      isLazy={false}
                      src={content.mediaPackImage.src}
                      ratio={256 / 191}
                      alt={content.mediaPackImage.alt}
                    />
                  )}
                  <div className="download-links">
                    {content.downloadItems.map((download, index) => (
                      <Link className="download" href={download.pdfLinkUrl} key={index}>
                        <Icon name={IconName.PDF} width={deviceState > DeviceState.SM ? 32 : 24} />
                        <TextNew.Sans
                          cid="use-underline"
                          size={deviceState > DeviceState.SM ? FontSizes.MD : FontSizes.SM}
                          color={Colors.DARK_TONE}
                          weight={FontWeight.LIGHT}
                        >
                          {download.linkText}
                        </TextNew.Sans>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </div>
  );
};

AdvertiseWithUs.propTypes = {
  deviceState: PropTypes.number,
  isStandAlone: PropTypes.bool,
  content: PropTypes.shape({
    titleLine1: PropTypes.string,
    titleLine2: PropTypes.string,
    bodyText: PropTypes.string,
    contactName: PropTypes.string,
    contactTitle: PropTypes.string,
    contactLandline: PropTypes.string,
    contactMobile: PropTypes.string,
    contactEmail: PropTypes.string,
    mediaPackTitle: PropTypes.string,
    mediaPackBodyText: PropTypes.string,
    mediaPackContentsHeading: PropTypes.string,
    mediaPackContentsList: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
    downloadItems: PropTypes.arrayOf(
      PropTypes.shape({
        linkText: PropTypes.string,
        pdfLinkUrl: PropTypes.string,
      }),
    ),
    mediaPackImage: PropTypes.shape({
      ...imageShape,
    }),
  }),
};

export default compose(
  withDeviceState(),
  withFunctionalClassName(ComponentType.MOLECULE, 'AdvertiseWithUs'),
)(AdvertiseWithUs);
