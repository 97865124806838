import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import salesforceFaqsLink from 'common/src/app/util/salesforceFaqsLink';
import NavigationController, { NavViews } from 'components/atoms/NavigationController';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { ContactUsType } from 'common/src/app/data/enum/ContactUsType';
import Pages from 'common/src/app/data/enum/Pages';
import Image from '../../atoms/Image';
import Wrapper from '../../atoms/Wrapper';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '../../molecules/Tabs';
import TextNew, { Colors, FontSizes, ElementTypes, FontFamily } from '../../atoms/TextNew';
import ContactUsGetInTouch from '../../templates/ContactUsGetInTouch';
import ContactUsFaqs from '../../templates/ContactUsFaqs';
import DetailTopBar from '../../organisms/DetailTopBar';

import './contact-us.scss';

const contactUsSectionType = {
  FAQS: 'FAQs',
  FORM: 'Get in touch',
};

const ContactUs = ({ background, deviceState, host, pathname }, context, className, dataTestid) => {
  // Extract the last segment from the pathname string.
  const match = pathname?.match(/\/([^/]+)$/);
  let contactType = null;

  if (pathname !== Pages.CONTACT_US) {
    contactType = match ? match[1] : null;
  }

  return (
    <div className={className} data-testid={dataTestid}>
      <NavigationController hideUiOptions={[NavViews.HIDE_GROUP_SEARCH_BAR]} />
      <Image isBackgroundImage ratio={1900 / 1233} src={background?.src} alt={background?.alt}>
        {contactType && contactType !== ContactUsType.GET_IN_TOUCH && (
          <DetailTopBar backlink useBrowserBack />
        )}
        <ContactUsMain host={host} deviceState={deviceState} contactType={contactType} />
      </Image>
    </div>
  );
};

const ContactUsMain = ({ host, deviceState, contactType }) => {
  const styles = {
    size: deviceState > DeviceState.MD ? FontSizes.SM : null,
    family: deviceState > DeviceState.MD ? FontFamily.SERIF : null,
  };

  return (
    <Wrapper>
      <header>
        <TextNew.Script
          element={ElementTypes.H1}
          size={deviceState > DeviceState.SM ? FontSizes.XL : FontSizes.LG}
          color={Colors.BRAND}
          cid="title"
          localeId="contactUs.header"
        />
      </header>
      <Tabs defaultIndex={contactType ? 1 : undefined}>
        <TabList className="list">
          <Tab dataTestid="tab-bar-id-faqs" styles={styles} className="tab">
            {contactUsSectionType.FAQS}
          </Tab>
          <Tab dataTestid="tab-bar-id-get-in-touch" styles={styles} className="tab">
            {contactUsSectionType.FORM}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel className="section">
            <ContactUsFaqs faqsLink={salesforceFaqsLink(host)} />
          </TabPanel>
          <TabPanel className="section">
            <ContactUsGetInTouch contactType={contactType} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Wrapper>
  );
};

ContactUsMain.propTypes = {
  deviceState: PropTypes.number,
  contactType: PropTypes.string,
  host: PropTypes.string,
};

ContactUs.propTypes = {
  background: ImagePropTypes,
  deviceState: PropTypes.number,
  host: PropTypes.string,
  pathname: PropTypes.string,
};

export default compose(
  withDeviceState(),
  withFunctionalClassName(ComponentType.PAGE, 'ContactUs'),
)(ContactUs);
