import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import TextNew, { AccentColors, Colors, ElementTypes, TextTypes } from 'components/atoms/TextNew';
import Image from 'components/atoms/Image';
import RichText from 'components/atoms/RichText';

import './star-promo.scss';

const StarPromo = (
  { description, deviceState, color, title, subTitle, backgroundColor, magazineStarImage },
  context,
  className,
  dataTestid,
) => (
  <div className={`${className} ${backgroundColor}-background-color`} data-testid={dataTestid}>
    {deviceState > DeviceState.SM && magazineStarImage?.src && (
      <Image
        src={magazineStarImage.src}
        ratio={168 / 509}
        dataTestid="magazine-star-image"
        alt="magazine-star-image"
      />
    )}
    <div className="wrap-content">
      <div className="star-title">
        {title && (
          <TextNew.Serif.MD color={color} element={ElementTypes.H2} dataTestid="star-promo-title">
            {title}
          </TextNew.Serif.MD>
        )}
        {subTitle && (
          <TextNew.Script.XL cid="magazine-star" color={color} element={ElementTypes.H2}>
            {subTitle}
          </TextNew.Script.XL>
        )}
      </div>
      <div className="star-content">
        {description && (
          <RichText
            text={description}
            color={color}
            type={TextTypes.PRIMARY_ELEGANT}
            dataTestid="star-promo-description"
          />
        )}
      </div>
    </div>
  </div>
);

StarPromo.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  magazineStarImage: PropTypes.object,
  deviceState: PropTypes.number,
};

StarPromo.defaultProps = {
  backgroundColor: AccentColors.ACCENT_BLUE,
  color: Colors.PRIMARY_LIGHT,
};

export default compose(
  withDeviceState(),
  withFunctionalClassName(ComponentType.MOLECULE, 'StarPromo'),
)(StarPromo);
