import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Size from 'common/src/app/data/enum/Size';
import TextNew, { ElementTypes, FontSizes } from 'components/atoms/TextNew';
import Wrapper from 'components/atoms/Wrapper';
import { FaqSeo, schemaUrl } from 'common/src/app/data/enum/SeoSchema';
import FaqBlockItem from './components/FaqBlockItem';
import './faq-block.scss';

const FaqBlock = ({ deviceState, title, questions, showAnswerTitle }, context, className) => (
  <div className={className} itemScope itemType={`${schemaUrl}${FaqSeo.SCHEMA.FAQ_PAGE}`}>
    <Wrapper.MD padding={Size.XL}>
      <TextNew.Script
        element={ElementTypes.H2}
        size={deviceState >= DeviceState.LG ? FontSizes.MD : FontSizes.SM}
        cid="faq-block-title"
      >
        {title}
      </TextNew.Script>
      {questions.map((itemProps, i) => (
        <FaqBlockItem
          {...itemProps}
          showAnswerTitle={showAnswerTitle}
          key={`faq-block-item-${i}`}
        />
      ))}
    </Wrapper.MD>
  </div>
);

FaqBlock.defaultProps = {
  showAnswerTitle: true,
};

FaqBlock.propTypes = {
  deviceState: PropTypes.number,
  title: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  showAnswerTitle: PropTypes.bool,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'FaqBlock')(FaqBlock),
);
