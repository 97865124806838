import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import MentalHealth from 'bundle-loader?lazy&reactHot&name=MentalHealth!./index';

const routes = (
  <Route
    title="Slimming World: Mental health support and resources"
    description="We value looking after your mental health it's essential for good overall health and wellbeing – find out more about Every Mind Matters and support you can get."
    path={Pages.UK_PUBLIC_MENTAL_HEALTH}
    {...getRouteComponentProp(MentalHealth)}
  />
);

export default routes;
