import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Wrapper from 'components/atoms/Wrapper';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import BubbleBackgroundWrapper from '../../atoms/BubbleBackgroundWrapper';
import './page-title-header.scss';

const PageTitleHeader = ({ localePrefix, hasSwoosh, deviceState }, context, className) => (
  <div className={className}>
    <BubbleBackgroundWrapper>
      <Wrapper.MD>
        <TextNew.Serif.XXL
          element={ElementTypes.SPAN}
          color={Colors.PRIMARY_LIGHT}
          localeId={`${localePrefix}.title.start`}
        />
        <TextNew.Script.XXL
          element={ElementTypes.SPAN}
          color={Colors.PRIMARY_LIGHT}
          localeId={`${localePrefix}.title.end`}
        >
          {hasSwoosh && (
            <Icon
              name={IconName.UNDERLINE_SWOOSH_SMALL}
              width={deviceState >= DeviceState.LG ? 200 : 110}
            />
          )}
        </TextNew.Script.XXL>
      </Wrapper.MD>
    </BubbleBackgroundWrapper>
  </div>
);

PageTitleHeader.propTypes = {
  localePrefix: PropTypes.string,
  hasSwoosh: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'PageTitleHeader', ['hasSwoosh'])(
    PageTitleHeader,
  ),
);
