import handleActions from 'redux-actions/lib/handleActions';
import { WEBP_SUPPORT } from '../actions/featureSupportActions';

const featureSupport = handleActions(
  {
    [WEBP_SUPPORT]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {},
);

export default featureSupport;
