import React from 'react';
import Route from 'react-router/lib/Route';
import routeCallback from 'common/src/app/util/routeCallback';
import StaticContent from 'bundle-loader?lazy&name=StaticContent!./index';
import Pages from 'common/src/app/data/enum/Pages';

const routes = (
  <Route getComponent={routeCallback(StaticContent)}>
    <Route path={Pages.USER_SUBSCRIPTION_AGREEMENT} />
    <Route path={Pages.MODERNSLAVERYACT} />
    <Route path={Pages.ACCESSIBILITYPOLICY} />
  </Route>
);

export default routes;
