/* eslint-disable no-console */
import { Component, PureComponent, isValidElement } from 'react';
import isEqualWith from 'lodash/isEqualWith';
import getDisplayName from './getDisplayName';
import { logActionIfChanged } from './actionLogger';
import { enablePurePerf } from './recomposePatched';

const DEEP_EQUAL = Symbol('DEEP_EQUAL');
const NEW_FUNCTION = Symbol('NEW_FUNCTION');

const logMessage = ({ type, key }) => {
  let message;
  switch (type) {
    case DEEP_EQUAL:
      message = 'deep equal but new object reference';
      break;
    case NEW_FUNCTION:
      message = 'new function reference';
      break;
    default:
      message = 'unknown';
  }
  console.log(
    `%c${key}%c ${message}`,
    'background: #222; color: #bada55',
    'background: #fff; color: #000',
  );
};

function isEqualCustomizer(objValue, otherValue) {
  if (
    typeof objValue === 'object' &&
    typeof otherValue === 'object' &&
    (isValidElement(objValue) || isValidElement(otherValue))
  ) {
    return false;
  }
  return undefined;
}

const compareProps = (instance, property, previous) => {
  const next = instance[property];
  const messages = [];
  let hasValidUpdateReason = false;

  if (next && previous) {
    const keys = Object.keys(next);

    keys.forEach(key => {
      if (next[key] !== previous[key]) {
        if (isValidElement(next[key]) || isValidElement(previous[key])) {
          return;
        }

        if (typeof next[key] === 'function') {
          messages.push({
            key: `${property}.${key}`,
            type: NEW_FUNCTION,
          });
          return;
        }

        if (isEqualWith(next[key], previous[key], isEqualCustomizer)) {
          messages.push({
            key: `${property}.${key}`,
            type: DEEP_EQUAL,
          });
          return;
        }

        hasValidUpdateReason = true;
      }
    });
  } else {
    hasValidUpdateReason = true;
  }

  return { hasValidUpdateReason, messages };
};

const shouldProfile = () => true;

/* eslint-disable no-underscore-dangle */
export function getComponentPath(instance, displayName, ancestors = 0) {
  if (
    ancestors > 0 &&
    instance._reactInternalFiber &&
    instance._reactInternalFiber._debugOwner &&
    instance._reactInternalFiber._debugOwner.stateNode
  ) {
    const parentInstance = instance._reactInternalFiber._debugOwner.stateNode;
    return getComponentPath(
      parentInstance,
      `${getDisplayName(parentInstance)} > ${displayName}`,
      ancestors - 1,
    );
  }
  return displayName;
}
/* eslint-enable no-underscore-dangle */

export function componentDidUpdateHook(prevProps, prevState) {
  const displayName = getDisplayName(this);

  if (!shouldProfile(displayName)) {
    return;
  }

  const allMessages = [];
  let shouldLogMessages = true;
  const properties = [
    ['props', prevProps],
    ['state', prevState],
  ];

  for (let i = 0; i < properties.length; i++) {
    const [property, previous] = properties[i];
    const { hasValidUpdateReason, messages } = compareProps(this, property, previous);

    if (hasValidUpdateReason) {
      shouldLogMessages = false;
      break;
    }

    allMessages.push(...messages);
  }

  if (shouldLogMessages && allMessages.length) {
    const componentPath = getComponentPath(this, displayName, 3);
    logActionIfChanged();
    console.groupCollapsed(`${componentPath} (${allMessages.length})`);
    console.log({ props: this.props, state: this.state });
    allMessages.forEach(logMessage);
    console.groupEnd();
  }
}

export const enable = () => {
  Component.prototype.componentDidUpdate = componentDidUpdateHook;
  PureComponent.prototype.componentDidUpdate = componentDidUpdateHook;
  enablePurePerf();
};
