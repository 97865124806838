import CompositeInputFormatterError from '../CompositeInputFormatterError';

/**
 * Composes the values of the Duration inputs into a single string
 * @param {object} values
 * @param {string} values.hours A string containing an integer value representing the hours number
 * @param {string} values.minutes A string containing an integer value representing the minutes number
 * @returns {string} a duration string formatted in ISO8601 format see https://en.wikipedia.org/wiki/ISO_8601 for more info
 * @example values {hours: 3, minutes: 5} would return string 'PT3H5M'
 */
const composeDuration = ({ hours, minutes }) => {
  const parsedHours = hours && parseInt(hours, 10);
  const parsedMinutes = minutes && parseInt(minutes, 10);

  if ((!parsedHours || parsedHours <= 0) && (!parsedMinutes || parsedMinutes <= 0)) {
    throw new CompositeInputFormatterError({
      minutes: 'Activity entries need to be 1 minute or more.',
    });
  }

  if ((parsedHours === 12 && parsedMinutes > 0) || parsedHours > 12) {
    throw new CompositeInputFormatterError({
      hours: 'You can add up to 12 hours of activity at once.',
    });
  }

  if (parsedMinutes >= 60) {
    throw new CompositeInputFormatterError({
      minutes: 'Use the ‘hours’ box for activities over 60 minutes. ',
    });
  }

  return `PT${parsedHours && parsedHours > 0 ? `${parsedHours}H` : ``}${
    parsedMinutes && parsedMinutes > 0 ? parsedMinutes : ``
  }${!parsedMinutes || parsedMinutes === '0' ? `` : `M`}`;
};

export default composeDuration;
