module.exports = {
  "webHost": "public",
  "useServerAuthentication": false,
  "useClientAuthentication": false,
  "persistClientAuthentication": false,
  "useOidcDiscovery": false,
  "clientScopes": [
    "openid"
  ],
  "useManifest": true,
  "verifyPinterest": true,
  "embedMarketingScript": true,
  "preloadCriticalCss": true
};