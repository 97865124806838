import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import RichTextBlock from '../../molecules/BlockContent/components/atoms/RichTextBlock';
import TextNew, { FontWeight, Colors } from '../TextNew';

const TileDescription = ({ description, hasTileColor }, context, className) => (
  <div className={className}>
    <TextNew.Sans.SM weight={FontWeight.LIGHT} color={hasTileColor && Colors.PRIMARY_LIGHT}>
      <RichTextBlock text={description} />
    </TextNew.Sans.SM>
  </div>
);

TileDescription.propTypes = {
  description: PropTypes.string,
  hasTileColor: PropTypes.bool,
};

export default withFunctionalClassName(ComponentType.ATOM, 'TileDescription')(TileDescription);
