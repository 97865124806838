import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import ResponsiveImage from './ResponsiveImage';

const connector = connect(
  state => ({
    hasWebpSupport: state.featureSupport?.hasWebpSupport,
  }),
  null,
);

export default compose(connector)(ResponsiveImage);
