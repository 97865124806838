import React from 'react';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import StandoutLabel from '../../molecules/StandoutLabel';
import LocaleMessage from '../../atoms/LocaleMessage';

import './tile-food-range.scss';

const TileFoodRange = ({}, context, className) => (
  <div className={className}>
    <StandoutLabel labelText={<LocaleMessage id="general.tiles.foodRange" />} />
  </div>
);

export default withFunctionalClassName(ComponentType.ATOM, 'TileFoodRange')(TileFoodRange);
