import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { compose } from 'redux';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import errorBoundary from 'components/hoc/ErrorBoundary';
import AdvertiseWithUs from '../../molecules/AdvertiseWithUs';

/**
 * Display information about how to advertise content with the SW magazine
 */

const AdvertiseWithUsPage = (_props, _context, className) => (
  <main className={className}>
    <AdvertiseWithUs isStandAlone />
  </main>
);

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.PAGE, 'AdvertiseWithUsPage'),
)(AdvertiseWithUsPage);
