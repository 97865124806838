/* global 'molecule','IcelandOurStoryPromo' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Pages from 'common/src/app/data/enum/Pages';
import Button from 'components/atoms/Button';
import LocaleMessage from 'components/atoms/LocaleMessage';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import QuotationBlock from 'components/molecules/BlockContent/components/atoms/QuotationBlock';

import './iceland-our-story-promo.scss';

const IcelandOurStoryPromo = (
  {
    pageData: {
      ourStoryQuote,
      ourStoryMessage,
      ourStoryRepresentativeImage,
      ourStoryRepresentativeName,
      ourStoryRepresentativeJobTitle,
      ourStoryRepresentativeLocation,
    },
    deviceState,
  },
  context,
  className,
) => {
  const getOurStoryQuote = () => (
    <TextNew.Confident cid="quote-text" element={ElementTypes.DIV}>
      <QuotationBlock quotation={ourStoryQuote} hasLargeQuoteStyle={false} />
    </TextNew.Confident>
  );

  return (
    <section className={className}>
      <div className="wrapper">
        <div className="representative">
          <header className="header">
            <TextNew.Strong cid="name" element={ElementTypes.H2}>
              {ourStoryRepresentativeName}
            </TextNew.Strong>

            <TextNew.FormalOriginal cid="job-title" element={ElementTypes.H3}>
              {ourStoryRepresentativeJobTitle}
            </TextNew.FormalOriginal>

            <TextNew.PrimaryOriginal cid="location" element={ElementTypes.H4}>
              {ourStoryRepresentativeLocation}
            </TextNew.PrimaryOriginal>
          </header>

          <ResponsiveImage {...ourStoryRepresentativeImage} />

          {deviceState < DeviceState.LG && getOurStoryQuote()}
        </div>

        <div className="content">
          {deviceState >= DeviceState.LG && getOurStoryQuote()}

          <TextNew.PrimaryOriginal cid="message" element={ElementTypes.DIV}>
            <RichTextBlock text={ourStoryMessage} />
          </TextNew.PrimaryOriginal>

          <Button to={Pages.UK_PUBLIC_ICELAND_OUR_STORY}>
            <LocaleMessage id="icelandOurStoryPromo.buttonText" />
          </Button>
        </div>
      </div>
    </section>
  );
};

IcelandOurStoryPromo.propTypes = {
  pageData: PropTypes.object,
  deviceState: PropTypes.number,
};

export default withDeviceState()(withFunctionalClassName('molecule','IcelandOurStoryPromo')(IcelandOurStoryPromo));
