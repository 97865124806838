/* global 'template','IcelandOurStory' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Loader from 'components/atoms/Loader';
import IcelandOurPagesContent from '../IcelandOurPagesContent';

import './iceland-our-story.scss';

const IcelandOurStory = ({ pageData }, context, className) => (
  <section className={className}>
    {pageData ? <IcelandOurPagesContent headerRatio={281 / 100} pageData={pageData} /> : <Loader />}
  </section>
);

IcelandOurStory.propTypes = {
  pageData: PropTypes.object,
};

export default withFunctionalClassName('template','IcelandOurStory')(IcelandOurStory);
