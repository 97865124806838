module.exports = {
  "marketName": "IE",
  "marketNameTracking": "IE",
  "locale": "en-IE",
  "legacyNearestGroupSearchUrl": "http://www.slimmingworld.ie/joining-a-group/nearest-group.aspx?",
  "publicUrl": "http://www.slimmingworld.ie",
  "pregnancyCTAUrl": "https://www.slimmingworld.co.uk/mums",
  "supportEmail": "online.support@slimmingworld.ie",
  "currencyCode": "EUR",
  "onlineMemberSupportPhoneNumber": "",
  "technicalMemberSupportPhoneNumber": "",
  "defaultCountry": "IE",
  "defaultOfferCopy": false,
  "textBasedGroupSearch": false,
  "dropDownBasedGroupSearch": true,
  "voucherPhoneNumber": "0344 892 0400",
  "groupEnquiriesNumber": "01 656 9696",
  "headOfficeNumber": "01 656 9600",
  "hasCyprusGroups": false,
  "hasGeolocationGroupSearch": false,
  "homepageDefaultVideo": false,
  "magazineRoundel": false,
  "offerRoundels": false,
  "virtualGroups": true,
  "facebookBusinessVerification": "m33jr8o97ow7o0z405d85sxvhx8dy2",
  "oneTrustCookieConsentId": "c5939318-e491-4c10-9e7e-536f8f49869c"
};