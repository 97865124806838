import { SEGMENT_RAW } from '../../data/enum/SegmentType';

/**
 * Processes the result of regex match on a segment
 * @param matches {Array<Object>} An Array containing the entire match result; null if
 * there are no matches.
 * @param segment {Object}
 * @param matchType {String} The new type matched segments should get
 */
export const processMatches = (matches, segment, matchType) => {
  if (matches === null) {
    return segment;
  }

  const { type, data } = segment;

  const result = [];

  // we start reading from the input data at index 0
  let lastIndex = 0;

  matches.forEach(match => {
    // if there is some raw data between the last read index and the next matched url,
    // add that as new segment as current segment's type
    if (match.index > lastIndex) {
      result.push({
        type,
        data: data.substring(lastIndex, match.index),
      });
    }

    // add the segment match with the matchType
    result.push({
      type: matchType,
      data: match,
    });

    lastIndex = match.lastIndex;
  });

  // if we have some raw data left over after the last read index,
  // add that as current segment's type
  if (lastIndex <= data.length - 1) {
    result.push({
      type,
      data: data.substring(lastIndex),
    });
  }

  return result;
};

export const parseMessage = (message, parser) =>
  parser([
    {
      type: SEGMENT_RAW,
      data: message,
    },
  ]);
