/* global 'organism','Carousel' */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import breakpoints from 'common/src/app/data/enum/Breakpoints';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import NavigationButton from '../../atoms/NavigationButton';
import LocaleMessage from '../../atoms/LocaleMessage';
import './carousel.scss';

const IS_ITEM_LABEL = 'is-item-label';
/**
 * Wrapper for slick Carousel
 *
 * Provides two carousel instances:
 *  - Main carousel, takes multiple options (https://github.com/akiran/react-slick)
 *  - Nav carousel, can be used for controlling the first, synced by index position
 *
 *  Nav carousel can either be static rendered items or a carousel itself! just pass
 * 'carousel:true' in the navSettings array
 */
class Carousel extends PureComponent {
  constructor(props) {
    super(props);

    if (props.carouselRef) {
      props.carouselRef(this);
    }
  }

  componentDidMount() {
    // A fix for first page load init
    setTimeout(() => {
      if (this.thumbCarousel) {
        this.thumbCarousel.forceUpdate();
      }
      this.carousel && this.carousel.forceUpdate();
    }, 100);
  }

  // Detect how many category labels are before the current clicked item
  getTotalDetectedCategoryLabels(slideIndex) {
    const { navItems } = this.props;
    let totalCategoryLabelsDetected = 0;
    // Detect if there are any labels based on the current slide index
    for (let i = 0; i < slideIndex; i++) {
      const hasCategoryLabel = navItems.find(
        category => category.props.itemID === i && category.props.itemType === IS_ITEM_LABEL,
      );
      // There are labels detected so we memorize this
      if (hasCategoryLabel) {
        totalCategoryLabelsDetected += 1;
      }
    }
    return totalCategoryLabelsDetected;
  }

  previousThumbSlideIndex = 0;
  totalDetectedCategoryLabels = 0;

  /**
   * Click handler for the next and prev buttons in the thumb carousel
   */
  handleThumbNavigationClick = currentMainSlideIndex =>
    this.carousel.slickGoTo(currentMainSlideIndex);

  /**
   * Click handler for the thumbnail items
   */
  handleThumbClick = event => {
    const { navItems } = this.props;

    // Get the current clicked item index
    let currentThumbSlideIndex = parseInt(event.currentTarget.value, 10);
    // Does the current clicked item belongs to a category item?
    const currentIsCategoryItem = navItems.find(
      category =>
        category.props.itemID === currentThumbSlideIndex - 1 &&
        category.props.itemType === IS_ITEM_LABEL,
    );

    if (!currentIsCategoryItem) {
      currentThumbSlideIndex -= this.getTotalDetectedCategoryLabels(currentThumbSlideIndex);
    } else {
      // Category item detected
      currentThumbSlideIndex = currentIsCategoryItem.props.itemID - 1;
    }

    // Slide to the correct main item
    this.carousel.slickGoTo(currentThumbSlideIndex);

    // Recalculate how many category labels are before the clicked item
    this.totalDetectedCategoryLabels = this.getTotalDetectedCategoryLabels(currentThumbSlideIndex);
  };

  /**
   * Update the thumb carousel when swiping through the main carousel
   *
   * When there are category labels we need to skip to the next possible item
   */
  afterChange = currentMainSlideIndex => {
    const {
      onHandleCurrentSlide,
      navItems,
      navSettings: { carousel: isCarousel },
      settings: { loadMore, slidesToShow },
      children,
    } = this.props;

    if (isCarousel) {
      // Get the current clicked item index
      let thumbSlideIndex = currentMainSlideIndex + this.totalDetectedCategoryLabels;
      // Lets check if the previousItem of the current clicked item is a category label
      const currentIsCategoryItem = navItems.find(
        category =>
          category.props.itemID === thumbSlideIndex - 1 &&
          category.props.itemType === IS_ITEM_LABEL,
      );

      // Swipe to the left
      if (this.previousThumbSlideIndex < thumbSlideIndex && currentIsCategoryItem) {
        thumbSlideIndex += 1;
        this.totalDetectedCategoryLabels += 1;
      } else if (this.previousThumbSlideIndex > thumbSlideIndex && currentIsCategoryItem) {
        // Swipe to the right
        thumbSlideIndex -= 1;
        this.totalDetectedCategoryLabels -= 1;
      }

      // Memorize previous slide index
      this.previousThumbSlideIndex = thumbSlideIndex;
      // Let's scroll to the correct thumb slide
      this.thumbCarousel.slickGoTo(thumbSlideIndex);
    }

    // load more items before the last item is shown
    loadMore &&
      children &&
      children.length - 1 === currentMainSlideIndex + slidesToShow &&
      loadMore();

    // Update the slide counter
    onHandleCurrentSlide && onHandleCurrentSlide(currentMainSlideIndex + 1);
  };

  beforeChange = (oldIndex, newIndex) => {
    const { handleBeforeChange } = this.props;
    handleBeforeChange && handleBeforeChange(oldIndex, newIndex);
  };

  render() {
    const { navigationOnTop, children, settings, navItems, deviceState } = this.props;
    // Needed to merge the navigation button object when truthy
    let { navSettings } = this.props;
    const currentBP = deviceState ? breakpoints[DeviceState[deviceState]] : null;
    let SliderOrDiv = navSettings.carousel ? Slider : 'div';

    navSettings.responsive &&
      navSettings.responsive.map(breakpoint => {
        if (breakpoint.breakpoint === currentBP) {
          SliderOrDiv = breakpoint.settings.carousel ? Slider : 'div';
        }
        return null;
      });

    // Add the custom next and prev buttons behavior
    const thumbNavigationButtons =
      navSettings.carousel && navSettings.arrows
        ? {
            nextArrow: (
              <NavigationButton
                direction={DirectionType.RIGHT}
                onCustomClick={prop =>
                  this.handleThumbNavigationClick(
                    prop.currentSlide + 1 - this.getTotalDetectedCategoryLabels(prop.currentSlide),
                  )
                }
                buttonLabel={<LocaleMessage id="general.cta.nextSlide" />}
              />
            ),
            prevArrow: (
              <NavigationButton
                direction={DirectionType.LEFT}
                onCustomClick={prop =>
                  this.handleThumbNavigationClick(
                    prop.currentSlide - 1 - this.getTotalDetectedCategoryLabels(prop.currentSlide),
                  )
                }
                buttonLabel={<LocaleMessage id="general.cta.previousSlide" />}
              />
            ),
          }
        : {};

    navSettings = Object.assign(navSettings, thumbNavigationButtons);

    return (
      <div
        {...componentClassNameProp(ComponentType.ORGANISM, this, { navigationOnTop })}
        /* It seems the slider needs this to init the carousel size in the PatternLib */
        style={{
          width: '100%',
        }}
      >
        <Slider
          {...settings}
          beforeChange={settings.beforeChange || this.beforeChange}
          afterChange={settings.afterChange || this.afterChange}
          ref={c => (this.carousel = c)}
        >
          {children.map((child, index) => (
            <div className="index-slide" key={`${index}-slide`}>
              {child}
            </div>
          ))}
        </Slider>
        {navItems ? (
          <div className="carousel-navigation-wrapper">
            <SliderOrDiv
              {...(SliderOrDiv !== 'div' ? navSettings : null)}
              ref={c => (this.thumbCarousel = c)}
              className="carousel-navigation"
            >
              {navItems.map((item, index) => (
                <button
                  key={`${index}-slide`}
                  onClick={this.handleThumbClick}
                  value={index}
                  type="button"
                >
                  {item}
                </button>
              ))}
            </SliderOrDiv>
          </div>
        ) : null}
      </div>
    );
  }
}

Carousel.defaultProps = {
  settings: {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    centerMode: false,
  },
  navSettings: {
    carousel: false,
    // ...takes standard options
  },
};

Carousel.propTypes = {
  /**
   * The main elements to be displayed
   */
  children: PropTypes.node.isRequired,
  /**
   * Settings : https://github.com/akiran/react-slick
   */
  settings: PropTypes.shape({
    /**
     * enables tabbing and arrow key navigation
     */
    accessibility: PropTypes.bool,
    /**
     * Additional class name for the inner slider div
     */
    className: PropTypes.string,
    /**
     * Adjust the slide's height automatically
     */
    adaptiveHeight: PropTypes.bool,
    /**
     * Should we show Left and right nav arrows
     */
    arrows: PropTypes.bool,
    /**
     * Element Use this element for the next arrow button
     */
    nextArrow: PropTypes.node,
    /**
     * Element Use this element for the prev arrow button
     */
    prevArrow: PropTypes.node,
    /**
     * Should the scroller auto scroll?
     */
    autoplay: PropTypes.bool,
    /**
     * delay between each auto scoll. in ms
     */
    autoplaySpeed: PropTypes.number,
    /**
     * Should we centre to a single item?
     */
    centerMode: PropTypes.bool,
    /**
     * Custom paging templates. Example
     */
    customPaging: PropTypes.func,
    centerPadding: PropTypes.string,
    /**
     * Should we show the dots at the bottom of the gallery
     */
    dots: PropTypes.bool,
    /**
     * Class applied to the dots if they are enabled
     */
    dotsClass: PropTypes.string,
    /**
     * Is the gallery scrollable via dragging on desktop?
     */
    draggable: PropTypes.bool,
    /**
     * css easing type
     */
    easing: PropTypes.string,
    /**
     * Slides use fade for transition
     */
    fade: PropTypes.bool,
    /**
     * Go to slide on click
     */
    focusOnSelect: PropTypes.bool,
    /**
     * should the gallery wrap around it's contents
     */
    infinite: PropTypes.bool,
    /**
     * int which item should be the first to be displayed
     */
    initialSlide: PropTypes.number,
    /**
     * Loads images or renders components on demands
     */
    lazyLoad: PropTypes.bool,
    /**
     * prevents autoplay while hovering
     */
    pauseOnHover: PropTypes.bool,
    /**
     * Settings for breakpoints
     */
    responsive: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * breakpoint in PX to apply new setting
         */
        breakpoint: PropTypes.number,
        /**
         * takes all settings
         */
        settings: PropTypes.object,
      }),
    ),
    /**
     * Reverses the slide order
     */
    rtl: PropTypes.bool,
    /**
     * Number of slides to be visible at a time
     */
    slidesToShow: PropTypes.number,
    /**
     * Number of slides to scroll for each navigation item
     */
    slidesToScroll: PropTypes.number,
    /**
     * speed of swiping
     */
    speed: PropTypes.number,
    /**
     * touch enabled
     */
    swipe: PropTypes.bool,
    /**
     * Allow users to drag or swipe
     */
    swipeToSlide: PropTypes.bool,
    /**
     * allow different width items
     */
    variableWidth: PropTypes.bool,
    /**
     * Enable/Disable CSS Transitions
     */
    useCSS: PropTypes.bool,
    /**
     * Vertical slide mode
     */
    vertical: PropTypes.bool,
    /**
     * callback function called after the current index changes
     */
    afterChange: PropTypes.func,
    /**
     * callback function called before the current index changes
     */
    beforeChange: PropTypes.func,
    /**
     *go to the specified slide number
     */
    slickGoTo: PropTypes.number,
    /**
     * load more items from the carousel
     */
    loadMore: PropTypes.func,
  }),
  /**
   * Typical device state
   */
  deviceState: PropTypes.number,
  /**
   * Duplicate of children, linked by index
   * this is seperate so you can pass a simplifed version of
   * your carsouel items if needed
   */
  navItems: PropTypes.node,
  /**
   * Settings : https://github.com/akiran/react-slick
   * duplicate of settings used for the nav carousel, except
   * takes 'carousel: bool' to active sliding on the nav item
   */
  navSettings: PropTypes.shape({
    dots: PropTypes.bool,
    carousel: PropTypes.bool, // Shows a thumbnail carousel that corresponds the main carousel
    slidesToShow: PropTypes.number, // Total items to show
  }),
  // Place the navigation on top
  navigationOnTop: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  // Get current slide
  onHandleCurrentSlide: PropTypes.func,
  // onBeforeChange
  handleBeforeChange: PropTypes.func,
  // carouselRef
  carouselRef: PropTypes.func,
};

export default withDeviceState()(Carousel);
