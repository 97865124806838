import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import './row.scss';

const Row = ({
  children,
  aligncenter,
  justifycenter,
  spacearound,
  spacebetween,
  stretch,
  justifyend,
  justifystart,
  alignend,
  alignstart,
  alignbaseline,
  wrap,
  column,
  shrink,
  grow,
  reverse,
  className,
}) => (
  <div
    className={functionalComponentClassName(
      ComponentType.ATOM,
      'row',
      className,
      {
        aligncenter,
        spacearound,
        spacebetween,
        justifycenter,
        justifyend,
        justifystart,
        alignend,
        alignstart,
        alignbaseline,
        wrap,
        stretch,
        column,
        shrink,
        grow,
        reverse,
      },
      className,
    )}
  >
    {children}
  </div>
);

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  aligncenter: PropTypes.bool,
  justifycenter: PropTypes.bool,
  stretch: PropTypes.bool,
  spacearound: PropTypes.bool,
  spacebetween: PropTypes.bool,
  justifyend: PropTypes.bool,
  justifystart: PropTypes.bool,
  alignend: PropTypes.bool,
  alignstart: PropTypes.bool,
  alignbaseline: PropTypes.bool,
  wrap: PropTypes.bool,
  column: PropTypes.bool,
  shrink: PropTypes.bool,
  grow: PropTypes.bool,
  reverse: PropTypes.bool,
};

export default Row;
