const ElementTypes = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  SPAN: 'span',
  P: 'p',
  UL: 'ul',
  LI: 'li',
  DIV: 'div',
  BLOCKQUOTE: 'blockquote',
  TD: 'td',
  A: 'a',
  F: 'footer',
  SECTION: 'section',
  ADDRESS: 'address',
  LABEL: 'label',
  BUTTON: 'button',
  ASIDE: 'aside',
  HEADER: 'header',
};

ElementTypes.propTypes = Object.values(ElementTypes);

export default ElementTypes;
