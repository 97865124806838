import React from 'react';
import PropTypes from 'prop-types';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import Icon from 'components/atoms/Icon';
import ComponentType from 'common/src/app/data/enum/ComponentType';

import TextNew, { AccentColors, FontWeight, FontStyles } from '../../atoms/TextNew';
import './five-star-review.scss';

const FiveStarReview = ({ reviewTitle, reviewBody }) => (
  <section className={functionalComponentClassName(ComponentType.MOLECULE, 'FiveStarReview')}>
    <TextNew.Serif.SM weight={FontWeight.BOLD} color={AccentColors.ACCENT_PURPLE}>
      {reviewTitle}
    </TextNew.Serif.SM>
    <div>
      <TextNew.Serif.SM style={FontStyles.ITALIC}>{reviewBody}</TextNew.Serif.SM>
      <Icon name={IconName.FIVE_STAR} />
    </div>
  </section>
);

FiveStarReview.propTypes = {
  reviewTitle: PropTypes.string.isRequired,
  reviewBody: PropTypes.string.isRequired,
};

export default FiveStarReview;
