/**
 * Enums defined by backend
 *
 * RedMeat: 1 << 0,
 * Poultry: 1 << 1,
 * Fish: 1 << 2,
 * Pork: 1 << 3,
 * Nuts: 1 << 4,
 * Eggs: 1 << 5,
 * Dairy: 1 << 6,
 * Gluten: 1 << 7,
 * Vegetarian: 1 << 8,
 * FreezerFriendly: 1 << 9,
 * SP: 1 << 10,
 * Vegan: 1 << 11
 */

/* eslint-disable no-bitwise */
// eslint-disable-next-line import/prefer-default-export
export const convertRecipeFlagsToIconClass = value => {
  const list = [];

  /*
  if (!(value & (1 << 0))) {
    list.push('red-meat');
  }

  if (!(value & (1 << 1))) {
    list.push('poultry');
  }

  if (!(value & (1 << 2))) {
    list.push('fish');
  }

  if (!(value & (1 << 3))) {
    list.push('pork');
  }

  if (!(value & (1 << 4))) {
    list.push('nuts');
  }

  if (!(value & (1 << 5))) {
    list.push('eggs');
  }
  */

  /** Caution: Negated flags! */
  if (!(value & (1 << 6))) {
    list.push('dairy-free');
  }

  /** Caution: Negated flags! */
  if (!(value & (1 << 7))) {
    list.push('gluten-free');
  }

  if (value & (1 << 8)) {
    list.push('vegetarian');
  }

  if (value & (1 << 9)) {
    list.push('freezer-friendly');
  }

  if (value & (1 << 10)) {
    list.push('sp');
  }

  if (value & (1 << 11)) {
    list.push('vegan');
  }

  return list;
};
