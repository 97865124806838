import React from 'react';
import PropTypes from 'prop-types';
import { MeasureUnitSpring } from 'common/src/app/data/enum/MeasureUnit';
import WeightInputImperial from './components/WeightInputImperial';
import WeightInputMetric from './components/WeightInputMetric';
import WeightInputValidation from './components/WeightInputValidation';
import WeightInputUnitToggle from './components/WeightInputUnitToggle';
import './weight-input.scss';

const WeightInput = ({ toggleWeightUnit, weightUnit, legend, ...restProps }) => (
  <>
    <fieldset className="atom-weight-input">
      {toggleWeightUnit && <WeightInputUnitToggle weightUnit={weightUnit} {...restProps} />}
      <legend className="screen-reader">{legend}</legend>
      <div className="weight-inputs">
        {weightUnit === `${MeasureUnitSpring.METRIC}` ? (
          <WeightInputMetric {...restProps} />
        ) : (
          <WeightInputImperial {...restProps} />
        )}
      </div>
      <WeightInputValidation weightUnit={weightUnit} />
    </fieldset>
  </>
);

WeightInput.defaultProps = {
  toggleWeightUnit: false,
};

WeightInput.propTypes = {
  legend: PropTypes.string,
  toggleWeightUnit: PropTypes.bool,
  weightUnit: PropTypes.oneOf([MeasureUnitSpring.IMPERIAL, MeasureUnitSpring.METRIC]),
};

export default WeightInput;
