import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Slider from 'react-slick';
import IconName from 'common/src/app/data/enum/IconName';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import classNames from 'classnames';
import withDeviceState, { DeviceState } from 'common/src/app/util/device-state/withDeviceState';
import Icon from '../../../../../atoms/Icon';
import TextNew, { ElementTypes, Colors, FontWeight } from '../../../../..//atoms/TextNew';
import RichTextBlock from '../RichTextBlock';
import Image from '../../../../../atoms/Image';

import './image-with-caption-carousel-block.scss';

const ImageWithCaptionCarouselBlock = ({ imageCarousel, deviceState }, context, className) => {
  const [active, setActiveSlide] = useState(0);
  const ref = useRef();

  const carouselSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    focusOnSelect: true,
    centerPadding: '0px',
    afterChange: index => setActiveSlide(index),
  };

  return (
    <div className={className}>
      <Slider {...carouselSettings} ref={ref}>
        {imageCarousel.map(({ caption, image }, index) => (
          <div key={`${index}-number-slide`}>
            <div>
              <Image ratio={1} src={image.src} alt={image.alt} />
            </div>
            <RichTextBlock cid="caption" text={caption} />
          </div>
        ))}
      </Slider>
      <div className="controls">
        <button
          className={classNames('arrow', 'left', { hidden: active === 0 })}
          onClick={() => ref.current.slickPrev()}
        >
          <Icon
            width={deviceState > DeviceState.MD ? 40 : 24}
            name={IconName.ARROW_NEW}
            direction={DirectionType.LEFT}
          />
        </button>
        <TextNew.Sans.SM
          weight={FontWeight.LIGHT}
          color={Colors.DARK_TONE}
          element={ElementTypes.SPAN}
          localeParams={{
            CURRENT_SLIDE: active + 1,
            SLIDE_COUNT: imageCarousel.length,
          }}
          localeId="imageWithCaptionCarouselBlock.counter"
        />
        <button
          className={classNames('arrow', 'right', { hidden: active + 1 === imageCarousel.length })}
          onClick={() => ref.current.slickNext()}
        >
          <Icon
            width={deviceState > DeviceState.MD ? 40 : 24}
            name={IconName.ARROW_NEW}
            direction={DirectionType.RIGHT}
          />
        </button>
      </div>
    </div>
  );
};

ImageWithCaptionCarouselBlock.propTypes = {
  /**
   * Array of items to display
   */
  imageCarousel: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName(
    ComponentType.ATOM,
    'ImageWithCaptionCarouselBlock',
  )(ImageWithCaptionCarouselBlock),
);
