import compose from 'redux/lib/compose';
import { push as historyPush } from 'react-router-redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { object, string } from 'yup';
import Pages from 'common/src/app/data/enum/Pages';
import SearchFields from 'common/src/app/data/enum/FieldNames/SearchFields';
import FormNames from 'common/src/app/data/enum/FormNames';
import PublicHorizontalSearchBar from './PublicHorizontalSearchBar';

const publicHorizontalSearchBarSchema = object().shape({
  [SearchFields.QUERY]: string().required('publicHorizontalSearchBar.required'),
});

const mapDispatchToProps = dispatch => ({
  loadRecipeUrl: query =>
    dispatch(
      historyPush(
        query ? `${Pages.UK_PUBLIC_RECIPE_SEARCH}?query=${query}` : Pages.UK_PUBLIC_RECIPE_SEARCH,
      ),
    ),
});

const EnhancedPublicHorizontalSearchBar = withFormik({
  displayName: FormNames.SEARCH_RECIPES,
  validationSchema: publicHorizontalSearchBarSchema,
  validateOnChange: false,
  handleSubmit: (values, { props }) => props.loadRecipeUrl(values.query),
})(PublicHorizontalSearchBar);

const connected = connect(null, mapDispatchToProps);

export default compose(connected)(EnhancedPublicHorizontalSearchBar);
