import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import Loader from 'components/atoms/Loader';
import PageTitleHeader from '../../molecules/PageTitleHeader';
import ConsultantBlock from '../../organisms/ConsultantBlock';
import ConsultantCtaBlock from '../../organisms/ConsultantCtaBlock';
import consultantPageNames from '../../../../../../data/enum/consultantPageNames';
import './our-consultants.scss';

const OurConsultants = (
  { brightcoveAccountId, brightcovePlayerId, trackVideoEvent, loading, pageData },
  context,
  className,
) => (
  <div className={className}>
    <PageTitleHeader localePrefix={consultantPageNames.OUR_CONSULTANTS} />

    {loading && <Loader />}

    {!loading &&
      // eslint-disable-next-line react/prop-types
      pageData?.nestedContent.map(({ _type, ...content }, index) => {
        if (_type === types.ROLE_BASED_CONTENT) {
          return (
            <ConsultantBlock
              {...content}
              key={`${_type}-${index}`}
              brightcoveAccountId={brightcoveAccountId}
              brightcovePlayerId={brightcovePlayerId}
              trackVideoEvent={trackVideoEvent}
            />
          );
        }
        return null;
      })}
    <ConsultantCtaBlock />
  </div>
);

OurConsultants.propTypes = {
  brightcoveAccountId: PropTypes.string,
  brightcovePlayerId: PropTypes.string,
  trackVideoEvent: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  pageData: PropTypes.object,
};

export default withFunctionalClassName(ComponentType.TEMPLATE, 'OurConsultants')(OurConsultants);
