import { compose } from 'redux';
import { connect } from 'react-redux';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { GET_COMPONENT } from 'common/src/app/actions/resources/componentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import {
  getStaticContent,
  GET_STATIC_CONTENT,
} from 'common/src/app/actions/resources/staticContentActions';
import { API_STATIC_PHYSICAL_GROUPS_LANDING_V2 } from 'common/src/app/data/apiStatics';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';

import getMarketRichTextContent from '../../../../../../Utils/getMarketRichTextContent';

import PhysicalGroupsLanding from './PhysicalGroupsLanding';

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT, GET_COMPONENT);

const mapStateToProps = state => ({
  isLoading: pageLoading(state),
  phsyicalGroupsMessage: getMarketRichTextContent(
    marketName,
    staticPageSelector(state, API_STATIC_PHYSICAL_GROUPS_LANDING_V2),
  ),
});
const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    dispatch(getStaticContent(API_STATIC_PHYSICAL_GROUPS_LANDING_V2, true)),
});

export default compose(addInitAction, connected)(PhysicalGroupsLanding);
