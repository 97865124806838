/* global 'template','NearestGroupConsultantLanding' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Loader from 'components/atoms/Loader';

import GroupSearchConsultantDetails from 'components/organisms/GroupSearchConsultantDetails';
import {
  consultantShape,
  groupShape,
} from 'common/src/app/util/proptypes/apiEntities/groupSearchShape';
import GroupDirectory from '../../../components/organisms/GroupDirectory';

import './nearest-group-consultant-landing.scss';

const NearestGroupConsultantLanding = ({ consultant, groups, isLoading }, context, className) => (
  <div className={className}>
    {isLoading ? (
      <Loader />
    ) : (
      <>
        <GroupSearchConsultantDetails onLightBackground {...consultant} />
        <GroupDirectory consultant={consultant} groups={groups} />
      </>
    )}
  </div>
);

NearestGroupConsultantLanding.propTypes = {
  consultant: PropTypes.shape(consultantShape),
  groups: PropTypes.arrayOf(PropTypes.shape(groupShape)),
  isLoading: PropTypes.bool,
};

export default withFunctionalClassName('template','NearestGroupConsultantLanding')(NearestGroupConsultantLanding);
