/* global 'atom' */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Youtube from 'react-youtube';
import DataLayerKeys from 'common/src/app/data/enum/Tracking/DataLayerKeys';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import VideoPlayerParam from 'common/src/app/data/enum/VideoPlayerParam';
import VideoPropTypes from 'common/src/app/util/proptypes/apiEntities/video';
import IconName from 'common/src/app/data/enum/IconName';
import IconButton from '../../../../IconButton';

import './you-tube.scss';

const PlayerState = {
  UNSTARTED: -1,
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
};

const allowedVideoParams = [
  VideoPlayerParam.AUTO_PLAY,
  VideoPlayerParam.CONTROLS,
  VideoPlayerParam.LOOP,
  VideoPlayerParam.MUTED,
  VideoPlayerParam.AUTO_HIDE,
  VideoPlayerParam.REL,
];

class YouTube extends PureComponent {
  constructor(props) {
    super(props);

    this.videoRef = React.createRef();
    this.state = {
      isMuted: Boolean(props.video[VideoPlayerParam.MUTED]),
      isPaused: false,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getOptions = video => {
    const params = {};
    allowedVideoParams.forEach(videoParam => {
      if (Object.prototype.hasOwnProperty.call(video, videoParam)) {
        videoParam === VideoPlayerParam.MUTED
          ? (params[VideoPlayerParam.MUTED_YOUTUBE] = video[videoParam])
          : (params[videoParam] = video[videoParam]);
      }
    });

    return params;
  };

  /**
   * Used for tagging
   *
   * todo implement tagging
   */
  handleStateChange = event => {
    const videoData = event.target.getVideoData();
    const playerState = event.target.getPlayerState();

    if (videoData && videoData.title !== '') {
      switch (playerState) {
        case PlayerState.UNSTARTED: {
          const { category, action, label } = { ...{}, ...this.props.trackingData };

          this.props.trackVideoEvent(
            {
              label: event.target.getVideoData().video_id,
              action: 'video-views',
              ...(action && label ? { [action]: label } : {}),
              ...(category ? { [DataLayerKeys.CATEGORY]: category } : {}),
            },
            'play',
            'youtube',
            event.target.getVideoData().video_id,
          );
          break;
        }
        case PlayerState.ENDED:
          break;
        case PlayerState.PLAYING:
          break;
        case PlayerState.PAUSED:
          break;
        default:
          break;
      }
    }
  };

  togglePause = async () => {
    const ytPlayer = this.videoRef?.current?.internalPlayer;
    const playerState = await ytPlayer.getPlayerState();
    const isPaused = playerState === PlayerState.PAUSED;

    this.setState({
      isPaused: !isPaused,
    });

    if (isPaused) {
      ytPlayer.playVideo();
    } else {
      ytPlayer.pauseVideo();
    }
  };

  toggleMute = async () => {
    const ytPlayer = this.videoRef?.current?.internalPlayer;
    const isMuted = await ytPlayer.isMuted();

    this.setState({
      isMuted: !isMuted,
    });

    if (isMuted) {
      ytPlayer.unMute();
    } else {
      ytPlayer.mute();
    }
  };

  render() {
    const { video, hasCustomControls } = this.props;
    const { isMuted, isPaused } = this.state;

    return (
      <div {...componentClassNameProp('atom', this)}>
        <Youtube
          className="youtube-player"
          ref={this.videoRef}
          videoId={video.videoId}
          opts={{
            playerVars: {
              ...this.getOptions(video),
              wmode: 'opaque',
            },
          }}
          onStateChange={this.handleStateChange}
        />

        {hasCustomControls && (
          <div className="button-container">
            <IconButton
              cid="play-state-button"
              icon={isPaused ? IconName.PLAY : IconName.PAUSE}
              onClick={this.togglePause}
              height={20}
            />

            <IconButton
              cid="sound-button"
              icon={isMuted ? IconName.SOUND_OFF : IconName.SOUND_ON}
              onClick={this.toggleMute}
              height={18}
            />
          </div>
        )}
      </div>
    );
  }
}

YouTube.propTypes = {
  ...VideoPropTypes,
  hasCustomControls: PropTypes.bool,
  trackVideoEvent: PropTypes.func.isRequired,
};

export default YouTube;
