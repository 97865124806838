import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import './text-block-triplet.scss';

const TextBlockTriplet = ({ content }, context, className) =>
  content?.length > 0 && (
    <div className={className}>
      <TextNew element={ElementTypes.H3}>
        {content.map(text => (
          <span key={text}>{text}</span>
        ))}
      </TextNew>
    </div>
  );

TextBlockTriplet.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      text1: PropTypes.string,
      text2: PropTypes.string,
      text3: PropTypes.string,
    }),
  ),
};

export default withFunctionalClassName(ComponentType.ATOM, 'TextBlockTriplet')(TextBlockTriplet);
