import PagesExternal from '../data/enum/PagesExternal';
import { ONLY_ALPHANUMERIC, MATCH_WHITESPACE } from '../data/regexPatterns';
/* eslint-disable import/prefer-default-export */

/**
 * Returns an object to be used as props for a link tag, that based on the url returns a different
 * attribute name. If the url is absolute, it will use the `href` prop to not link internally.
 * Otherwise it will use the `to` attribute to have it picked up by React Router.
 *
 * @param {string} url Either a relative or absolute url
 * @return {object}
 */
export const getLinkPropsFromUrl = url => {
  const prop = /(?:https?:)?\/\//i.test(url) ? 'href' : 'to';
  return {
    [prop]: url,
  };
};

/**
 * Takes a string and converts to a URL-friendly string
 * Truncates length at 64 charachters
 * @param {string}
 * @return {string}
 */
export const stringToUrl = string => {
  const stripChars = string.replace(ONLY_ALPHANUMERIC, '');
  const reduceLength = stripChars.substring(0, 64);
  const addDash = reduceLength.replace(MATCH_WHITESPACE, '-');
  return addDash.toLowerCase();
};

/**
 * Get specific url parameter
 * @param location
 * @param name
 * @returns {string}
 */
export const getUrlParameter = (location, name) => {
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const EXCLUDED_KEYS = ['PRODUCTION_IE', 'PRODUCTION_UK'];

const SATELLITE_SITE_URLS = Object.entries(PagesExternal)
  .filter(([key]) => !EXCLUDED_KEYS.includes(key))
  .map(([, url]) => url);

/**
 * Cleans a URL by removing its protocol and 'www' prefix if any, and trims trailing slashes.
 * This standardization facilitates more reliable comparison of URLs.
 *
 * @param {string} url - The URL to be cleaned.
 * @return {string} - The cleaned URL, stripped of protocol, 'www', and trailing slashes.
 */
const cleanUrl = url => url.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/+$/, '');

/**
 * Checks if a normalized input URL matches any format of a base site URL.
 * It considers the base URL both with and without a trailing slash to enhance matching accuracy.
 *
 * @param {string} normalizedInputUrl - The normalized URL to check.
 * @param {string} baseSiteUrl - The base site URL in its normalized form.
 * @return {boolean} - True if the input URL matches any format of the base site URL; otherwise, false.
 */
const matchesUrlFormats = (normalizedInputUrl, baseSiteUrl) => {
  const baseWithSlash = `${baseSiteUrl}/`;
  const baseWithoutSlash = baseSiteUrl.endsWith('/') ? baseSiteUrl.slice(0, -1) : baseSiteUrl;

  return (
    normalizedInputUrl === baseSiteUrl ||
    normalizedInputUrl === baseWithSlash ||
    normalizedInputUrl === baseWithoutSlash ||
    normalizedInputUrl.startsWith(baseWithSlash) // Check if the URL starts with the base URL followed by more path segments
  );
};

/**
 * Determines the appropriate properties for a link element based on whether the URL is for an external site.
 * Returns an object with `href` for external URLs to be treated as such by the browser,
 * or `to` for internal links to be handled by React Router.
 *
 * @param {string} url - The URL for which to create link properties.
 * @return {object} - An object containing either an `href` or `to` key with the URL as its value.
 */
export const createLinkProps = url => {
  // Normalize the URL to remove 'www.' and any trailing slashes
  const normalizedUrl = cleanUrl(url);
  const isExternalSecure = /^https:\/\//.test(url); // Check if URL is external and secure
  const normalizedSatelliteSiteUrls = SATELLITE_SITE_URLS.map(cleanUrl);

  // Checking if it matches external URL formats and is secured with 'https'
  const shouldUseHref =
    isExternalSecure &&
    normalizedSatelliteSiteUrls.some(baseUrl => matchesUrlFormats(normalizedUrl, baseUrl));

  // Use `href` if it's a secure matching external URL, otherwise, default to `to`
  return shouldUseHref ? { href: url } : { to: url };
};
