import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/atoms/Button';
import TextNew, { ElementTypes, TextTypes } from 'components/atoms/TextNew';
import RichText from 'components/atoms/RichText';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';

import { ctaShape, featuresShape } from '../../../propTypes/groupOnlineComparisonPropTypes';

import './group-or-online-content.scss';

const GroupOrOnlineContent = ({
  membershipType,
  isTabbed,
  offer,
  title,
  subtitle,
  description,
  features,
  upperCTA,
  lowerCTA,
}) => (
  <div className={classNames('molecule-group-or-online-content', membershipType)}>
    <div className="content">
      {!isTabbed && (
        <div className="comparison-title">
          <TextNew.Strong cid="title">{title}</TextNew.Strong>
          <TextNew.Script.MD cid="subtitle">{subtitle}</TextNew.Script.MD>
        </div>
      )}

      <div className="comparison-introduction">
        <RichText text={offer} type={TextTypes.PRIMARY_ELEGANT} />
        {upperCTA && <RenderButton cta={upperCTA} membershipType={membershipType} />}
      </div>

      <div className="features">
        {description && (
          <RichText cid="description" text={description} type={TextTypes.CONFIDENT} />
        )}

        {features?.title && (
          <TextNew.Strong cid="title" element={ElementTypes.H3}>
            {features.title}
          </TextNew.Strong>
        )}
        {features?.listItems && (
          <ul>
            {features.listItems?.map(({ listItem } = features.listItems, index) => (
              <li key={`feature-list-item-${index}`}>
                <Icon cid="icon-checkmark" name={IconName.CHECKMARK} />
                <RichText text={listItem} type={TextTypes.PRIMARY_ELEGANT} />
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="lower-ctas">
        {lowerCTA?.map((cta, index) => (
          <RenderButton
            cta={cta}
            key={`${membershipType}-${index}`}
            membershipType={membershipType}
            isGhostButton={index === 1}
          />
        ))}
      </div>
    </div>
  </div>
);

GroupOrOnlineContent.propTypes = {
  membershipType: PropTypes.oneOf(['group', 'online']).isRequired,
  isTabbed: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  offer: PropTypes.string,
  features: PropTypes.shape({ ...featuresShape }),
  upperCTA: PropTypes.shape({ ...ctaShape }).isRequired,
  lowerCTA: PropTypes.arrayOf(PropTypes.shape({ ...ctaShape })).isRequired,
};

const RenderButton = ({ cta, membershipType, isGhostButton = false }) => {
  const isExternalLink = cta?.link?.includes('http');

  return (
    <Button
      aria-label={cta?.text}
      href={isExternalLink ? cta?.link : null}
      to={isExternalLink ? null : cta?.link}
      isGhostPink={isGhostButton && membershipType === 'group'}
      isGhostBlue={isGhostButton && membershipType === 'online'}
      isOnBackgroundColor={!isGhostButton}
    >
      <TextNew.Sans.SM element={ElementTypes.SPAN}>{cta?.text}</TextNew.Sans.SM>
    </Button>
  );
};

RenderButton.propTypes = {
  cta: PropTypes.shape({ ...ctaShape }).isRequired,
  membershipType: PropTypes.oneOf(['group', 'online']).isRequired,
  isGhostButton: PropTypes.bool,
};

export default GroupOrOnlineContent;
