import handleActions from 'redux-actions/lib/handleActions';
import {
  GET_GROUP_SEARCH_RESULTS,
  SET_GROUP_SEARCH_RESULTS,
  GET_GROUP_DETAIL,
  SET_GROUP_SEARCH_COUNTIES,
  SET_GROUP_SEARCH_TOWNS,
  SET_GROUP_SEARCH_LOADING,
  SET_ALL_GROUP_SEARCH_TOWNS,
} from '../actions/resources/groupSearchActions';

const initialState = { searchResults: null, groupDetail: null, formReset: false };

export default handleActions(
  {
    [GET_GROUP_SEARCH_RESULTS]: (state, { payload }) => ({
      ...state,
      searchResults: payload,
    }),
    [SET_GROUP_SEARCH_LOADING]: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    [SET_GROUP_SEARCH_RESULTS]: (state, { payload }) => ({
      ...state,
      searchResults: payload,
    }),
    [GET_GROUP_DETAIL]: (state, { payload }) => ({
      ...state,
      groupDetail: payload,
    }),
    [SET_GROUP_SEARCH_COUNTIES]: (state, { payload }) => ({
      ...state,
      counties: payload,
    }),
    [SET_GROUP_SEARCH_TOWNS]: (state, { payload }) => ({
      ...state,
      towns: payload,
    }),
    [SET_ALL_GROUP_SEARCH_TOWNS]: (state, { payload }) => ({
      ...state,
      allTowns: payload,
    }),
  },
  initialState,
);
