/* eslint-disable import/prefer-default-export */
import createTransform from 'redux-persist/lib/createTransform';
import omit from 'lodash/omit';
import get from 'lodash/get';
import set from 'lodash/set';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import FormNames from '../data/enum/FormNames';

function filterObject({ path, filterFunction = () => true }, state) {
  const value = get(state, path, state);

  if (value instanceof Array) {
    return value.filter(filterFunction);
  }

  return pickBy(value, filterFunction);
}

export function persistFilter(state, paths = [], transformType = 'allowlist') {
  let subset = {};

  // support only one key
  if (typeof paths === 'string') {
    paths = [paths]; // eslint-disable-line no-param-reassign
  }

  if (transformType === 'allowlist') {
    paths.forEach(path => {
      if (typeof path === 'object' && !(path instanceof Array)) {
        const value = filterObject(path, state);

        if (!isEmpty(value)) {
          set(subset, path.path, value);
        }
      } else {
        const value = get(state, path);

        if (typeof value !== 'undefined') {
          set(subset, path, value);
        }
      }
    });
  } else {
    subset = state;
  }

  return subset;
}

function createFilter(reducerName, inboundPaths, transformType = 'allowlist') {
  return createTransform(
    inboundState =>
      inboundPaths ? persistFilter(inboundState, inboundPaths, transformType) : inboundState,
    { allowlist: [reducerName] },
  );
}

export const shopBasketForm = createFilter('form', [FormNames.SHOP_BASKET]);

export const viewFilter = createTransform((inboundState, key) => {
  if (key !== 'view') return inboundState;
  return omit(inboundState, ['components.lottie', 'components.modal', 'collectionPagination']);
});
