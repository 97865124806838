/* global 'molecule','PolicyDescription' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import BlockContent from '../../../../../molecules/BlockContent';
import TextNew, { ElementTypes } from '../../../../../atoms/TextNew';

const PolicyDescription = ({ title, description, nestedContent }, {}, className) => (
  <div className={className}>
    {title && <TextNew.Loud element={ElementTypes.H1}>{title}</TextNew.Loud>}
    {description && <TextNew.PrimaryElegant>{description}</TextNew.PrimaryElegant>}
    {nestedContent && <BlockContent noWrapper data={nestedContent} addDeepLinkSection />}
  </div>
);

PolicyDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  nestedContent: PropTypes.array,
};

export default withFunctionalClassName('molecule','PolicyDescription')(PolicyDescription);
