import OpeningTimes, {
  Department,
  OpeningDays,
  DepartmentDetails,
  OpeningTimesShape,
} from './OpeningTimes';

export { Department, OpeningDays, OpeningTimesShape, DepartmentDetails };

export default OpeningTimes;
