import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import Magazine from 'bundle-loader?lazy&reactHot&name=Magazine!./index';

const routes = (
  <Route
    title="The latest issue of Slimming World Magazine - the UK's No.1 slimming title | {pageTitle}"
    description="The latest issue of Slimming World Magazine is packed full of ideas, recipes, success stories, and fantastic advice that will help you lose weight and stay slim for life!"
    path={Pages.UK_PUBLIC_MAGAZINE}
    {...getRouteComponentProp(Magazine)}
  />
);

export default routes;
