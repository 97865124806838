import createAction from 'redux-actions/lib/createAction';
import { GATEWAY_GROUP_ACCOUNT } from '../../data/Injectables';
import { getValue } from '../../util/injector';

/**
 * AFT for group account api calls
 * @return {object}
 * Use the returned result.data in the head of subsequent calls:
 * {
 *   headers: {
 *    'X-XSRF-Token': result.data,
 *   },
 *   credentials: 'include',
 * }
 *
 */
export const getAntiForgeryToken = () => () =>
  getValue(GATEWAY_GROUP_ACCOUNT).get('/antiforgery', null, {
    credentials: 'include',
  });

/**
 * Action to check if membership card, pin and email address are already registered
 * @param {string} membershipCard
 * @param {string} pin
 * @param {string} email
 *
 * @returns {object}
 */
export const SUBMISSION_COUNT = 'groupAccountActions/SUBMISSION_COUNT';
export const updateSubmissionCount = createAction(SUBMISSION_COUNT);
