import header from './HeaderReducer';
import modal from './modalReducer';
import deepLinks from './deepLinkReducer';
import scrollManager from './scrollManagerReducer';
import grid from './gridViewReducer';
import lottie from './lottieReducer';
import contactUs from './contactUsReducer';
import combineReducersNamed from '../../../util/reducers/combineReducersNamed';
import instagramTrending from './instagramTrendingReducer';

export default combineReducersNamed({
  header,
  lottie,
  scrollManager,
  modal,
  deepLinks,
  grid,
  instagramTrending,
  contactUs,
});
