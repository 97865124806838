/**
 * Meal type map
 */
const MealType = {
  0: 'Breakfast',
  1: 'Lunch',
  2: 'Dinner',
  3: 'Snack',
  4: 'Dessert',
  5: 'Appetizer',
  6: 'SaucesAndCondiments',
  7: 'Drinks',
  8: 'SideDishes',
};

export default MealType;
