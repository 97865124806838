import { withInitAction } from '@mediamonks/react-redux-component-init';
import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { SearchType, getSearchResults } from 'common/src/app/actions/resources/searchActions';
import { landingGridSelector } from 'common/src/app/selectors/publicSelectors';

import RecipeNotFound from './RecipeNotFound';

const mapStateToProps = state => landingGridSelector(state);

const connected = connect(mapStateToProps);

const addInitAction = withInitAction(['slug'], {
  clientOnly: ({ slug }, dispatch) =>
    dispatch(
      getSearchResults(SearchType.PUBLIC_RECIPE, {
        limit: 4,
        query: slug,
      }),
    ),
});

export default compose(addInitAction, connected)(RecipeNotFound);
