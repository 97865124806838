import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import FormikError from 'components/atoms/FormikError';
import InputType from 'common/src/app/data/enum/InputType';
import SearchFields from 'common/src/app/data/enum/FieldNames/SearchFields';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import IconName from 'common/src/app/data/enum/IconName';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import DataTestidOverides from 'common/src/app/data/enum/DataTestidOverides';
import TextNew, { FontSizes, AccentColors, Colors, ElementTypes } from '../../atoms/TextNew';
import IconInput from '../IconInput';
import { descriptorTypes } from '../../atoms/Input';

import './public-horizontal-search-bar.scss';

const PublicHorizontalSearchBar = (
  { handleSubmit, handleChange, showTitle, showIntro, deviceState },
  { getMessage },
  className,
  dataTestid,
) => {
  const large = deviceState > DeviceState.XL;
  return (
    <div className={className} data-testid={dataTestid}>
      {showTitle && (
        <h3>
          <TextNew.Serif.MD
            size={large ? FontSizes.SM : FontSizes.SM}
            color={Colors.BRAND}
            cid="recipeSearchFor"
            localeId="publicHorizontalSearchBar.searchFor"
            element={ElementTypes.SPAN}
          />
          <TextNew.Script.MD
            size={large ? FontSizes.SM : FontSizes.SM}
            color={Colors.BRAND}
            localeId="publicHorizontalSearchBar.delicious"
            element={ElementTypes.SPAN}
            cid="recipeDelicious"
          />
          <TextNew.Serif.MD
            size={large ? FontSizes.SM : FontSizes.SM}
            color={Colors.BRAND}
            cid="recipeRecipes"
            localeId="publicHorizontalSearchBar.recipes"
            element={ElementTypes.SPAN}
          />
        </h3>
      )}
      {showIntro && (
        <TextNew.Sans.SM
          size={FontSizes.SM}
          color={AccentColors.ACCENT_GREY}
          cid="intro"
          dataTestid="search-bar-intro"
          localeId="publicHorizontalSearchBar.intro"
        />
      )}
      <form onSubmit={handleSubmit} className="form" autoComplete="off" noValidate>
        <IconInput
          name={SearchFields.QUERY}
          buttonLabel={getMessage('publicHorizontalSearchBar.search')}
          placeholder={getMessage('publicHorizontalSearchBar.placeholder')}
          type={InputType.TEXT}
          onChange={handleChange}
          dataTestid={DataTestidOverides.RECIPE_SEARCH_INPUT}
          isIconButton
          iconButtonType="submit"
          icon={IconName.MAGNIFY_GLASS}
          iconPosition={DirectionType.RIGHT}
          descriptorType={descriptorTypes.GHOST}
        />
        <FormikError triggerUntouched name={SearchFields.QUERY} />
      </form>
    </div>
  );
};

PublicHorizontalSearchBar.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  showTitle: PropTypes.bool,
  showIntro: PropTypes.bool,
  deviceState: PropTypes.number,
};

PublicHorizontalSearchBar.defaultProps = {
  showTitle: true,
  showIntro: false,
  isTile: true,
};

PublicHorizontalSearchBar.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'PublicHorizontalSearchBar', [
    'stacked',
    'is-tile',
  ])(PublicHorizontalSearchBar),
);
