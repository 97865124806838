import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import MagazineCompetitions from 'bundle-loader?lazy&reactHot&name=MagazineCompetitions!./index';

const routes = (
  <Route
    title="Slimming World | Magazine Competitions"
    path={Pages.UK_PUBLIC_MAGAZINE_COMPETITIONS}
    {...getRouteComponentProp(MagazineCompetitions)}
  />
);

export default routes;
