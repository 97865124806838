import handleActions from 'redux-actions/lib/handleActions';
import { CONFIRM_NEWSLETTER_SIGNUP } from 'common/src/app/actions/resources/publicActions';

const initialState = {
  flow: null,
  vegetarian: null,
};

export default handleActions(
  {
    [CONFIRM_NEWSLETTER_SIGNUP]: (state, { payload: response, meta }) => {
      if (meta?.isFulfilled && response) {
        const { result, menuType } = response?.data;
        return {
          ...state,
          flow: result,
          menuType,
        };
      }
      return state;
    },
  },
  initialState,
);
