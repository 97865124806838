/**
 * RecipeTime map
 */
const RecipeTime = {
  0: 'LessThan30',
  1: 'LessThan60',
  2: 'MoreThan60',
};

export default RecipeTime;
