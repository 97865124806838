/* global 'atom','RichTextsListBlock' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { Colors } from '../../../../../atoms/TextNew';
import RichTextBlock from '../RichTextBlock';
import './rich-texts-list-block.scss';

/**
 * Title with a numbered list of rich text blocks
 */
const RichTextsListBlock = ({ title, nestedContent }, context, className) => (
  <div className={className}>
    <TextNew.Loud color={Colors.PRIMARY_DARK}>{title}</TextNew.Loud>
    {nestedContent && nestedContent.length ? (
      <ol className="text-list">
        {nestedContent.map(item => (
          <li key={item.text}>
            <RichTextBlock text={item.text} />
          </li>
        ))}
      </ol>
    ) : null}
  </div>
);

RichTextsListBlock.propTypes = {
  /**
   * The main title
   */
  title: PropTypes.string.isRequired,
  /**
   * List of texts to display
   */
  nestedContent: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
  ),
};

export default withFunctionalClassName('atom','RichTextsListBlock')(RichTextsListBlock);
