import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';

import GroupTimetable from './GroupTimetable';

const connected = connect(
  state => ({
    displayGroupTimesAsTime:
      marketName === 'UK'
        ? state?.config?.environmentConfig?.nearestGroupSessions?.showGroupTimesUK === 'true' ||
          false
        : state?.config?.environmentConfig?.nearestGroupSessions?.showGroupTimesIE === 'true' ||
          false,
  }),
  {},
);

export default compose(connected)(GroupTimetable);
