import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { INHERIT } from 'common/src/app/data/enum/Colors';
import withDeviceState, { QueryHelpers } from 'common/src/app/util/device-state/withDeviceState';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import LocaleMessage from '../LocaleMessage';
import TextNew, { Colors, FontWeight, AccentColors, FontSizes } from '../TextNew';

import './group-field.scss';

const GroupField = (
  {
    cid,
    label,
    textStyle,
    children,
    className: additionalClassName,
    titleColour = AccentColors.ACCENT_GREEN,
    queryHelper,
  },
  context,
  className,
  dataTestid,
) => {
  const { weight, size, color, titleElement } = textStyle || {};

  return (
    <div className={classNames(className, additionalClassName)} data-testid={dataTestid}>
      <TextNew.Sans
        weight={weight || FontWeight.BOLD}
        size={size || (queryHelper === QueryHelpers.TABLET ? FontSizes.XS : FontSizes.SM)}
        color={color || titleColour}
        cid={cid}
        element={titleElement}
        dataTestid="group-field-header"
      >
        <LocaleMessage id={label} />
      </TextNew.Sans>
      {children}
    </div>
  );
};

GroupField.propTypes = {
  queryHelper: PropTypes.string,
  cid: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  textStyle: PropTypes.object,
  titleColour: PropTypes.oneOf([...AccentColors.propTypes, ...Colors.propTypes, INHERIT]),
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ATOM, 'GroupField')(GroupField),
);
