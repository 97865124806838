import get from 'lodash/get';
import { currencyCode } from 'common/src/app/config/market/market.configdefinitions';
import currencySymbolProvider from './currencySymbolProvider';
import publicPrices from '../data/enum/publicPrices';

/**
 * Returns a market-specific price with currency symbol if defined,
 * else return UK price in pounds
 * @param category  e.g. "group"
 * @param item e.g. "magazine"
 */

const getPublicPrices = (category, item) => {
  const priceCategory = get(publicPrices[currencyCode], category);
  return priceCategory && priceCategory[item]
    ? currencySymbolProvider(currencyCode) + priceCategory[item]
    : currencySymbolProvider('GBP') + get(publicPrices.GBP, category)[item];
};

export default getPublicPrices;
