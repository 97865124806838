/**
 * Create an id from a given string
 *
 * @param name
 * @returns {string}
 */

const cleanseId = name =>
  name
    .replace(/ & /g, '-')
    .replace(/ /g, '-')
    .toLowerCase(); // replace all spaces with hyphens and remove &

const createId = name => `id-${cleanseId(name)}`;

export default createId;
