import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import AdvertiseWithUsPage from 'bundle-loader?lazy&reactHot&name=AdvertiseWithUs!./index';

const routes = (
  <Route
    title="Find out more about advertising with Slimming World  | {pageTitle}"
    description="Download our media pack for details of advertising opportunities in the UK's no 1 Slimming title - Slimming World Magazine"
    path={Pages.UK_PUBLIC_ADVERTISE_WITH_US}
    {...getRouteComponentProp(AdvertiseWithUsPage)}
  />
);

export default routes;
