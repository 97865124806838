import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Row from 'components/atoms/Row';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import TextNew, {
  ElementTypes,
  FontWeight,
  FontSizes,
  Colors,
  AccentColors,
} from 'components/atoms/TextNew';
import { INHERIT } from 'common/src/app/data/enum/Colors';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import Button from 'components/atoms/Button';

import './one-step.scss';

const colorRemap = {
  white: Colors.PRIMARY_LIGHT,
  black: Colors.PRIMARY_DARK,
  red: AccentColors.ACCENT_RED,
  green: AccentColors.ACCENT_GREEN,
  blue: AccentColors.ACCENT_BLUE,
  'light-blue': 'accent-blue',
  purple: AccentColors.ACCENT_PURPLE,
  pink: AccentColors.ACCENT_PINK,
  grey: AccentColors.ACCENT_GREY,
};

const OneStep = ({ blocks, hasSubtitle, hasButtonTo, deviceState }, context, className) => (
  <div data-testid="one-step">
    {blocks?.map(
      ({ title, subtitle, description, button, image, backgroundColour, fontColour } = blocks) => {
        const titleId = title.split(' ', 4).join('-');
        const lowercasedTitleId = titleId.toLowerCase();

        return (
          <div
            id={lowercasedTitleId}
            className={classNames(className, {
              [`background-${colorRemap[backgroundColour]}`]: backgroundColour,
            })}
            key={`${title}-articles`}
          >
            <Row className="article-detail" column grow>
              <TextNew.Script
                size={deviceState <= DeviceState.SM ? FontSizes.XS : FontSizes.SM}
                color={colorRemap[fontColour]}
                element={ElementTypes.H2}
                cid="title"
              >
                {title}
              </TextNew.Script>
              {hasSubtitle && (
                <TextNew.Serif
                  size={deviceState <= DeviceState.SM ? FontSizes.SM : FontSizes.MD}
                  color={colorRemap[fontColour]}
                  cid="subtitle"
                >
                  {subtitle}
                </TextNew.Serif>
              )}
              <TextNew.Sans.MD
                color={colorRemap[fontColour]}
                cid="description"
                element={ElementTypes.DIV}
              >
                <RichTextBlock text={description} />
              </TextNew.Sans.MD>

              {hasButtonTo && button && (
                <Button
                  to={button.link}
                  isOnBackgroundColor={backgroundColour !== 'white'}
                  isShadePurple={backgroundColour === 'white'}
                >
                  <TextNew.Sans.SM
                    element={ElementTypes.SPAN}
                    weight={FontWeight.BOLD}
                    color={INHERIT}
                  >
                    {button.text}
                  </TextNew.Sans.SM>
                </Button>
              )}
            </Row>
            <BackgroundImageWrapper image={image} />
          </div>
        );
      },
    )}
  </div>
);
OneStep.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      description: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired, // add prop type validation for button prop
      image: PropTypes.string.isRequired,
      backgroundColour: PropTypes.string.isRequired,
      fontColour: PropTypes.string.isRequired,
    }),
  ).isRequired,
  hasSubtitle: PropTypes.bool,
  hasButtonTo: PropTypes.bool,
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'OneStep')(OneStep),
);
