import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
//  Layout
import Header from '../../organisms/Header';
import Wrapper from '../../atoms/Wrapper';
import Row from '../../atoms/Row';
import SlimmingWorldLogo from '../../atoms/SlimmingWorldLogo';
import BackgroundImageWrapper from '../../atoms/BackgroundImageWrapper';

import './application-error.scss';

const ApplicationError = props => {
  const { children, publicHost, includeHeader, deviceState, background } = props;

  return (
    <div className={functionalComponentClassName(ComponentType.TEMPLATE, 'ApplicationError')}>
      <BackgroundImageWrapper image={deviceState >= DeviceState.LG && background ? background : ''}>
        {includeHeader ? <Header center={<SlimmingWorldLogo />} /> : null}
        <Wrapper width="md" padding="xxl">
          <div className="content-block">
            {children}
            <Row justifycenter className="main-content-footer">
              <SlimmingWorldLogo href={publicHost} />
            </Row>
          </div>
        </Wrapper>
      </BackgroundImageWrapper>
    </div>
  );
};

ApplicationError.propTypes = {
  background: ImagePropTypes,
  publicHost: PropTypes.string.isRequired,
  children: PropTypes.node,
  includeHeader: PropTypes.bool,
  deviceState: PropTypes.number,
};

export default withDeviceState()(ApplicationError);
