import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import StyleType from 'common/src/app/data/enum/StyleType';
import Label from '../../../../Label';

import './check-box-new.scss';

const CheckBoxNew = (
  { input: inputProps = {}, disabled, children, onClick, styleType },
  _context,
  className,
  dataTestid,
) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    // Ticket DF-224: Checkbox Update - Replace <div> with <label> and <input>
    <div
      data-testid={dataTestid}
      className={classnames(className, styleType, { checked: inputProps.checked })}
    >
      <input
        id={`${inputProps.name}-label`}
        type="checkbox"
        disabled={disabled}
        checked={inputProps.checked}
        className="checkbox-input"
        onClick={handleClick}
        data-testid={`${dataTestid}-input`}
        {...inputProps}
      />
      <Label htmlFor={inputProps.id || `${inputProps.name}-label`} text={children} />
    </div>
  );
};

CheckBoxNew.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  styleType: PropTypes.string,
};

CheckBoxNew.defaultProps = {
  styleType: StyleType.PRIMARY,
};

export default memo(withFunctionalClassName(ComponentType.ATOM, 'CheckBoxNew')(CheckBoxNew));
