import { GATEWAY_ACCOUNT } from '../../data/Injectables';
import apiGetCollection from './apiActions/apiGetCollection';
import { IRISH_COUNTIES } from '../../data/collectionIds';

export const GET_IRISH_COUNTIES = 'accountActions/GET_IRISH_COUNTIES';

export const getIrishCounties = () => dispatch =>
  dispatch(
    apiGetCollection(
      GET_IRISH_COUNTIES,
      GATEWAY_ACCOUNT,
      '/irish-counties',
      IRISH_COUNTIES,
      {},
      {
        entityType: IRISH_COUNTIES,
        caching: false,
        transformEntity: apiEntity => ({
          title: apiEntity.county,
          value: apiEntity.id,
        }),
      },
    ),
  );
