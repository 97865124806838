const groupTimesFormatted = ({ day, session, displayGroupTimesAsTime = false }) => {
  let timeFormattedArray = [];

  // Display the group session times
  if (displayGroupTimesAsTime) {
    timeFormattedArray =
      session && session[day].times.map(time => time.format('LT').replace(' ', ''));
  } else {
    // Takes the session time and rather than displaying the time:
    // - we display the time period in the format of:
    // - Morning/Afternoon/Evening
    timeFormattedArray = session?.[day]?.times?.map(time => {
      const hour = time.format('HH');

      if (hour >= 12) {
        return 'Afternoon/Evening';
      }
      return 'Morning';
    });

    // use ...new Set to remove any duplicated keys
    timeFormattedArray = [...new Set(timeFormattedArray)];
  }

  // If there is a single time - return the single time
  if (timeFormattedArray && timeFormattedArray.length === 1) {
    return `${timeFormattedArray[0]}`;
  }

  // If there are two times - add `and` inbetween
  if (timeFormattedArray && timeFormattedArray.length === 2) {
    return `${timeFormattedArray[0]} and ${timeFormattedArray[1]}`;
  }

  // Create a copy of the times array and remove the last item
  const timeFormattedArrayLastItem = [...timeFormattedArray];
  timeFormattedArrayLastItem.pop();

  // Return each time with a `,` and for the last time add an `and` before it
  return `${timeFormattedArrayLastItem.join(', ')} and ${
    timeFormattedArray[timeFormattedArray.length - 1]
  }`;
};

export default groupTimesFormatted;
