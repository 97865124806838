import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import InputType from 'common/src/app/data/enum/InputType';
import EnhancedFormField from 'common/src/app/formik/components/EnhancedFormField';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { composeWeightMetric, decomposeWeightMetric } from 'common/src/app/util/weightUtils';
import { handleFieldGroupInput } from 'common/src/app/util/form/inputEventUtil';
import LocaleMessage from 'components/atoms/LocaleMessage';
import TextNew from 'components/atoms/TextNew';
import { InputNew, descriptorTypes } from 'components/atoms/Form';
import Row from 'components/atoms/Row';
import './weight-metric-field.scss';

const KILOS_LENGTH = 3;
const GRAMS_LENGTH = 2;

const WeightMetricField = (
  {
    name,
    abbreviate,
    disabled,
    hidePlaceholder,
    formik: { values, isSubmitting, setFieldValue, setFieldTouched, setValues },
  },
  { getMessage },
  className,
) => {
  const { kilos: kiloValue, grams: gramsValue, initialWeight } = values;

  useEffect(() => {
    if (initialWeight) {
      // Decompose when we have the composed value in place e.g. from the initial value
      const { kilos, grams } = decomposeWeightMetric(initialWeight);
      setValues({ ...values, kilos, grams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!kiloValue) {
      setFieldValue(name, null);
    }

    if (kiloValue || gramsValue) {
      const composedValue = composeWeightMetric({
        kilos: kiloValue,
        grams: gramsValue,
      });

      setFieldValue(name, composedValue);

      if (initialWeight) {
        setFieldTouched(name, true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [kiloValue, gramsValue, initialWeight, isSubmitting, setFieldValue, name, setFieldTouched]);

  const setValue = (fieldName, value) => {
    setFieldValue(fieldName, value, false);

    if (!kiloValue || !gramsValue) {
      requestAnimationFrame(() => setFieldTouched(name, true, false));
    }
  };

  return (
    <Row className={className}>
      <EnhancedFormField
        data-testid={`${getMessage('general.measureUnit.weight.kilogram.labelKilos')}-input`}
        component={InputNew}
        name={getMessage('general.measureUnit.weight.kilogram.labelKilos')}
        type={InputType.NUMBER}
        min="0"
        max="318"
        aria-label={getMessage('general.measureUnit.weight.kilogram.labelKilos')}
        placeholder={hidePlaceholder ? ' ' : '000'}
        descriptor={descriptorTypes.STATIC}
        onKeyPress={event => handleFieldGroupInput(event, KILOS_LENGTH)}
        onBlur={e =>
          setValue(getMessage('general.measureUnit.weight.kilogram.labelKilos'), e.target.value)
        }
        disabled={disabled}
      />
      <TextNew.Formal aria-hidden="true">.</TextNew.Formal>
      <EnhancedFormField
        data-testid={`${getMessage('general.measureUnit.weight.kilogram.labelGrams')}-input`}
        component={InputNew}
        name={getMessage('general.measureUnit.weight.kilogram.labelGrams')}
        type={InputType.NUMBER}
        min="0"
        max="99"
        aria-label={getMessage('general.measureUnit.weight.kilogram.labelGrams')}
        placeholder={hidePlaceholder ? ' ' : '00'}
        label={
          <LocaleMessage
            id={`general.measureUnit.weight.kilogram.${abbreviate ? 'abbreviation' : 'name'}`}
          />
        }
        descriptor={descriptorTypes.STATIC}
        onKeyPress={event => handleFieldGroupInput(event, GRAMS_LENGTH)}
        onBlur={e =>
          setValue(getMessage('general.measureUnit.weight.kilogram.labelGrams'), e.target.value)
        }
        disabled={disabled}
      />
    </Row>
  );
};

WeightMetricField.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

WeightMetricField.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object,
  disabled: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  abbreviate: PropTypes.bool,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'WeightMetricField',
)(WeightMetricField);
