import { connect } from 'react-redux';
import { registerDeepLinks } from 'common/src/app/actions/components/deepLinkActions';
import { currentRoute } from 'common/src/app/util/routeCheckUtils';
import DeepLink from './DeepLink';

export default connect(
  state => ({
    currentRoute: currentRoute(state),
  }),
  dispatch => ({
    registerDeepLink: linkBlock => dispatch(registerDeepLinks(linkBlock)),
  }),
)(DeepLink);
