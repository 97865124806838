import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import createId from 'common/src/app/util/createId';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import './deep-link-handler.scss';

// With this extra offset the scroll offset won't stick to the top of the element
const EXTRA_OFFSET_TOP = 32;
/**
 * Manage scroll behaviour for internal page links
 *
 * Dependancy:
 * Use together with the DeepLink for the sections
 *
 * @example usage 1:
 * <DeepLinkHandler>
 *  <ComponentToDisplayLinks />
 *  <DeepLinkDisplayToggle />
 *  <AnotherCcomponentToDoThings />
 * </DeepLinkHandler>
 *
 * <div>
 * <DeepLinkWithIconAndMediaButtonBlock title="label 1" />
 *   dynamic content
 * </div>
 * <div>
 * <DeepLinkWithIconAndMediaButtonBlock title="label 2" />
 *   dynamic content
 * </div>
 */
class DeepLinkHandler extends PureComponent {
  constructor() {
    super();
    this.state = {
      activeTab: null,
      hideLinks: true,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deepLinks && Object.keys(prevProps.deepLinks).length !== 0) {
      this.updateTabBarActiveState();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = debounce(200, false, () => {
    this.updateTabBarActiveState();
  });

  /*
   * Check if we have a vertical navigation
   */
  calculateHeightOffset = () => {
    const verticalNav =
      this.props.hasFixedNavigation && this.ChildComponent
        ? this.ChildComponent.getBoundingClientRect().height
        : 0;
    return verticalNav + EXTRA_OFFSET_TOP;
  };

  /**
   * Handle the scroll to section click
   */
  handleTabClick = reference => {
    const blockElement = document.getElementById(reference);
    if (this.props.animatedScroll) {
      this.props.scrollToElement(blockElement, -this.calculateHeightOffset(), false);
    } else if (typeof window !== 'undefined') {
      window.scrollTo(
        0,
        blockElement.getBoundingClientRect().top +
          -this.calculateHeightOffset() +
          -this.props.headerHeight +
          document.documentElement.scrollTop,
      );
    }
  };

  /**
   * Update the tab bar active state
   */
  updateTabBarActiveState = () => {
    const { hideUntil, headerHeight, deepLinks } = this.props;

    if (hideUntil) {
      const hideUntilHere = hideUntil - headerHeight;
      const scrollPosition = document.documentElement.scrollTop;
      if (hideUntilHere <= scrollPosition && this.state.hideLinks === true) {
        this.setState({ hideLinks: false });
      } else if (hideUntilHere > scrollPosition && this.state.hideLinks === false) {
        this.setState({ hideLinks: true });
      }
    }

    if (deepLinks && Object.keys(deepLinks).length !== 0) {
      const activeSection = Object.keys(deepLinks)
        .concat()
        .reverse()
        .find(block => {
          const blockElement = document.getElementById(createId(block));
          return (
            blockElement &&
            blockElement.getBoundingClientRect().top <=
              this.calculateHeightOffset() + EXTRA_OFFSET_TOP + this.props.headerHeight
          );
        });
      this.setState({ activeTab: createId(activeSection || Object.keys(deepLinks)[0]) });
    }
  };

  render() {
    const { hasFixedNavigation, headerHeight, hideUntil, deepLinks, children } = this.props;

    const paddingHeight =
      (this.ChildComponent && this.ChildComponent.getBoundingClientRect().height) || 0;
    return (
      <div {...componentClassNameProp(ComponentType.ORGANISM, this)}>
        {hasFixedNavigation && !hideUntil && (
          <div style={{ paddingBottom: `${paddingHeight}px` }} />
        )}
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            hidden: !!hideUntil && this.state.hideLinks,
            topDistance: hasFixedNavigation ? headerHeight : 0,
            reference: ref => (this.ChildComponent = ref),
            tabs: deepLinks,
            activeTab: this.state.activeTab,
            onClick: this.handleTabClick,
            ...child.props,
          }),
        )}
      </div>
    );
  }
}

DeepLinkHandler.defaultProps = {
  hasFixedNavigation: false,
  animatedScroll: true,
};

DeepLinkHandler.propTypes = {
  /**
   * Bool to trigger a 'jump' or smooth scroll
   * default smooth scrolling
   */
  animatedScroll: PropTypes.bool,
  /**
   * Show the fixed navigation
   */
  hasFixedNavigation: PropTypes.bool,
  /**
   * Value taken from state if a DeepLinkDisplayToggle component
   * is used in the tree
   */
  hideUntil: PropTypes.number,
  /**
   * Child(ren) components to be decorated with additional props:
   * @param {boolean} hidden - defines if be hidden, based on hideUntil component reference
   * @param {integer} topDistance - distance in px from top of page
   * @param {function} reference - ref for component to include height in calculation (top navs etc)
   * @param {object} tabs - e.g. { Tabname: { title: 'dave'}, Tabname2: { title: 'percy'} }
   * @param {string} activeTab - active tab id created via createId function = createId(Tabname)
   * @param {function} onClick - funciton to activate a tab = onClick(createId(Tabname))
   */
  children: PropTypes.node,

  /**
   * Function to scroll to a specific element. This is passed by the `connect()` wrapper
   */
  scrollToElement: PropTypes.func.isRequired,
  /**
   * An object with all the current pages links in, passed via state
   */
  deepLinks: PropTypes.object,
  /**
   * Add header heigh into calculations, passed via state
   */
  headerHeight: PropTypes.number,
};

export default DeepLinkHandler;
