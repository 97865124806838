import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import OffCanvasMenu from './OffCanvasMenu';

const mapStateToProps = state => ({
  showMenu: state.view.components.header?.showMenu,
  headerHeight: state.view.components.header?.height,
});

const connected = connect(mapStateToProps);

export default compose(connected)(OffCanvasMenu);
