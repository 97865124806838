import React, { PureComponent } from 'react';

/* eslint-disable import/no-unresolved, import/extensions */
import { hoistStatics, wrapDisplayName } from 'recompose';
import { componentDidUpdateHook } from './index';

export {
  mapProps,
  withProps,
  withPropsOnChange,
  withHandlers,
  defaultProps,
  renameProp,
  renameProps,
  flattenProp,
  withState,
  withReducer,
  branch,
  renderComponent,
  renderNothing,
  shouldUpdate,
  onlyUpdateForKeys,
  onlyUpdateForPropTypes,
  withContext,
  getContext,
  lifecycle,
  toClass,
  setStatic,
  setPropTypes,
  setDisplayName,
  compose,
  getDisplayName,
  shallowEqual,
  isClassComponent,
  createSink,
  componentFromProp,
  nest,
  componentFromStream,
  componentFromStreamWithConfig,
  mapPropsStream,
  mapPropsStreamWithConfig,
  createEventHandler,
  setObservableConfig,
} from 'recompose';

let purePerfEnabled = false;

export function enablePurePerf() {
  purePerfEnabled = true;
}

export const pure = hoistStatics(
  InputComponent =>
    class PurePatched extends PureComponent {
      static displayName = wrapDisplayName(InputComponent, 'pure');

      componentDidUpdate(prevProps, prevState) {
        if (purePerfEnabled) {
          componentDidUpdateHook.call(this, prevProps, prevState);
        }
      }

      render() {
        return <InputComponent {...this.props} />;
      }
    },
);

export { hoistStatics, wrapDisplayName };
