import CompositeInputFormatterError from '../CompositeInputFormatterError';
import { poundsToKilograms } from '../../util/conversionUtils';

/**
 * Converts values from the WeightImperialFieldGroup input into a number value in kilograms
 * @param {object} formValues
 * @param {string} formValues.fullPounds A string that contains an integer representing
 * the amount of pounds
 * @param {string} formValues.remainingPounds A string that contains an integer representing
 * the any non-whole pounds, rounded to the nearest half
 *
 * @returns {number} The resulting number value in metric (kilos)
 */
const composeWeightImperialToMetric = ({ fullPounds, remainingPounds }) => {
  if (typeof fullPounds === 'undefined' && typeof remainingPounds === 'undefined') {
    return fullPounds;
  }
  const parsedPounds = fullPounds ? parseInt(fullPounds, 10) : 0;
  const parsedremainingPounds = remainingPounds ? parseFloat(remainingPounds) : 0;

  if (isNaN(parsedPounds)) {
    throw new CompositeInputFormatterError({ fullPounds: 'fullPounds should be a number' });
  }
  const wholePounds = parsedremainingPounds + parsedPounds;
  return poundsToKilograms(wholePounds);
};

export default composeWeightImperialToMetric;
