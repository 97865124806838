/* global 'atom','DisplayWeight' */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Configuration from 'common/src/app/config/Configuration';
import convertWeightValue from 'common/src/app/util/convertWeightValue';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';

import LocaleMessage from '../LocaleMessage';
import './display-weight.scss';

/**
 * DisplayWeight component, measuring weight in kilogram, pounds and stones
 */
const DisplayWeight = (
  { weight, precision, weightUnit, imperialWeightUseStones, customFormat },
  getMessage,
  className,
) => {
  const parts = convertWeightValue(
    {
      weight,
      precision,
      weightUnit,
      imperialWeightUseStones,
      customFormat,
    },
    getMessage,
  );

  return (
    <span className={classNames(className, { 'has-stones': parts.length > 1 })}>
      {parts.map((item, index) => (
        <span className="weight-part" key={index}>
          <span className="weight-value">{item.value}</span>
          <span className="weight-type">
            <LocaleMessage id={item.label} params={{ WEIGHT: item.value }} />
          </span>
        </span>
      ))}
    </span>
  );
};

DisplayWeight.defaultProps = {
  precision: 2,
  imperialWeightUseStones: Configuration.imperialWeightUseStones,
};

DisplayWeight.propTypes = {
  /**
   * Imperial weight
   */
  weight: PropTypes.number.isRequired,
  /**
   * The number of digits to appear after the decimal point
   */
  precision: PropTypes.number,
  /**
   * weightUnit used in conversion
   */
  weightUnit: PropTypes.oneOf([MeasureUnit.IMPERIAL, MeasureUnit.METRIC]),
  /**
   * Override imperialWeightUseStones
   */
  imperialWeightUseStones: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  customFormat: PropTypes.bool,
};

export default withFunctionalClassName(ComponentType.ATOM, 'DisplayWeight', {
  displayInStones: ({ weightUnit }) =>
    Configuration.imperialWeightUseStones && weightUnit === MeasureUnit.IMPERIAL,
})(DisplayWeight);
