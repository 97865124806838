import compose from 'redux/lib/compose';
import matchRoute from 'common/src/app/util/routeCheckUtils';
import { connect } from 'react-redux';
import Pages from 'common/src/app/data/enum/Pages';
import CountrySwitch from './CountrySwitch';

const mapDispatchToProps = () => ({
  countrySelectHistoryPush: e => (window.location = e.target.value),
});

const mapStateToProps = state => {
  const pageIsCyprus = matchRoute(Pages.UK_PUBLIC_CYPRUS_GROUP_SEARCH, state);
  const currentHost = state.config.environmentConfig.web.public.host;

  return {
    pageIsCyprus,
    currentHost,
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(CountrySwitch);
