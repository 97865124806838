import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TableRowColumnAmount from 'common/src/app/data/enum/TableRowColumnAmount';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import RichText from '../../../../../atoms/RichText';
import TextNew, { ElementTypes, TextTypes } from '../../../../../atoms/TextNew';

import './table-block.scss';

const TableBlock = (
  { title, displayHeaders, column1Header, column2Header, column3Header, tableRows },
  context,
  className,
  dataTestid,
) => {
  // Determine how many columns the table needs
  // The only way we know is by looking at the _type returned within the tableRows object
  // and getting the first rows _type to determine if its 2 row or 3 row
  // eslint-disable-next-line no-underscore-dangle
  const COLUMNS_TO_DRAW =
    tableRows && tableRows[0]?._type === TableRowColumnAmount.THREE_COLUMNS ? 3 : 2; // eslint-disable-line no-underscore-dangle

  const renderTableHeaderItems = () => {
    const columnHeaders = {
      1: renderTableCellContents(column1Header),
      2: renderTableCellContents(column2Header),
      3: renderTableCellContents(column3Header),
    };

    // remove item number 3 - (if we only have 2 columns of row data)
    if (COLUMNS_TO_DRAW === 2) {
      delete columnHeaders[3];
    }

    return (
      <thead data-testid="table-header">
        <tr>
          {Object.keys(columnHeaders).map(
            headeritem =>
              columnHeaders[headeritem] && (
                <th key={`${headeritem}-head`}>{columnHeaders[headeritem]}</th>
              ),
          )}
        </tr>
      </thead>
    );
  };

  const renderTableBodyItems = () => (
    <tbody>
      {tableRows?.length &&
        tableRows.map((rowitem, index) => (
          <tr key={`row-${index}`}>
            <td>{renderTableCellContents(rowitem.column1)}</td>
            <td>{renderTableCellContents(rowitem.column2)}</td>
            {/* eslint-disable-next-line no-underscore-dangle */}
            {rowitem._type === TableRowColumnAmount.THREE_COLUMNS && (
              <td>{renderTableCellContents(rowitem.column3)}</td>
            )}
          </tr>
        ))}
    </tbody>
  );

  const renderTableCellContents = cellContents => (
    <RichText type={TextTypes.PRIMARY_ELEGANT} text={cellContents} />
  );

  return (
    <div className={className} data-testid={dataTestid}>
      {title && (
        <TextNew.Confident cid="title" element={ElementTypes.H2} dataTestid="table-title">
          {title}
        </TextNew.Confident>
      )}
      <table className="table-content">
        {displayHeaders && renderTableHeaderItems()}
        {renderTableBodyItems()}
      </table>
    </div>
  );
};

TableBlock.propTypes = {
  // Title to (optionally) display above the table.
  title: PropTypes.string,
  // To display the <thead> or not
  displayHeaders: PropTypes.bool.isRequired,
  // Column header cell items
  column1Header: PropTypes.string.isRequired,
  column2Header: PropTypes.string.isRequired,
  column3Header: PropTypes.string,
  // The table row body column
  tableRows: PropTypes.arrayOf(
    PropTypes.shape({
      column1: PropTypes.string.isRequired,
      column2: PropTypes.string.isRequired,
      column3: PropTypes.string,
      _type: PropTypes.string.isRequired,
    }),
  ),
};

export default withFunctionalClassName(ComponentType.ATOM, 'TableBlock')(TableBlock);
