/* global 'atom','ItemRating' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import Icon from '../Icon';
import './item-rating.scss';

/**
 * This component allows you to display a rating out of scale for example
 * in use case of a review you could have 1 out of 5 stars
 */

const ItemRating = ({ icon, iconWidth, itemRating, maximumRating }, context, className) => (
  <div className={className}>
    {Array(maximumRating)
      .fill()
      .map((_, index) => (
        <Icon
          width={iconWidth}
          cid={`icon-${!itemRating === 0 || index <= itemRating - 1 ? 'fill' : 'empty'}`}
          key={`item-rating-${index}`}
          name={icon}
        />
      ))}
  </div>
);

ItemRating.defaultProps = {
  iconWidth: 24,
};

ItemRating.propTypes = {
  itemRating: PropTypes.number,
  maximumRating: PropTypes.number,
  icon: PropTypes.oneOf(Object.values(IconName)),
  iconWidth: PropTypes.number,
};

export default withFunctionalClassName('atom','ItemRating')(ItemRating);
