import PropTypes from 'prop-types';

/**
 * Reusable object to use in `propTypes` for a `factSheet` prop.
 * @type {Object}
 * @category templating
 */
const factSheetShape = {
  factSheetLink: PropTypes.string,
  pdfLinkText: PropTypes.string,
  pdfDisclaimer: PropTypes.string,
};

export default factSheetShape;
