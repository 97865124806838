import React from 'react';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';

import { Department } from 'common/src/app/data/enum/DepartmentPhoneNumbersInfo';

import { groupEnquiriesNumber } from 'common/src/app/config/market/market.configdefinitions';

import TextNew, { Colors, ElementTypes, FontWeight } from '../../atoms/TextNew';
import NearestGroupSearchBar from '../../organisms/NearestGroupSearchBar';

import OpeningTimes from '../../atoms/OpeningTimes';

import './contact-us-group-enquiries.scss';

const ContactUsGroupEnquiries = ({}, context, className, dataTestid) => (
  <div className={className} data-testid={dataTestid}>
    <div className="title-and-phone-number-wrapper">
      <TextNew.Serif.SM
        localeId="contactUsGroupEnquiries.title"
        color={Colors.BRAND}
        cid="contact-section-title"
      />

      {/* get the phone number from the market config:
    - this ensures that we will get the correct one, for:
    - UK and IE */}

      <Link className="phone-number-link" href={`tel:${groupEnquiriesNumber}`}>
        <TextNew.Sans.SM weight={FontWeight.LIGHT} color={Colors.BRAND}>
          {groupEnquiriesNumber}
        </TextNew.Sans.SM>
      </Link>
    </div>

    <OpeningTimes department={Department.GROUP_ENQUIRIES} />

    <TextNew.Sans.SM
      key="group-enquiries-body-text"
      localeId="contactUsGroupEnquiries.body.info"
      weight={FontWeight.LIGHT}
      localeParams={{
        FOCUS: (
          <TextNew.Sans.SM
            key="group-enquiries-focus-text"
            weight={FontWeight.NORMAL}
            localeId="contactUsGroupEnquiries.body.focus"
            element={ElementTypes.SPAN}
          />
        ),
      }}
    />
    <NearestGroupSearchBar hideIntro />
  </div>
);

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'ContactUsGroupEnquiries',
)(ContactUsGroupEnquiries);
