import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import ContactUs from 'bundle-loader?lazy&reactHot&name=ContactUs!./index';

const routes = (
  <Route {...getRouteComponentProp(ContactUs)}>
    <Route
      title="How to contact Slimming World's warm and friendly support teams"
      description="If you have any questions about Slimming World's services, feel free to reach out to us for help and support. We're here to help you with your weight loss journey."
      path={Pages.CONTACT_US}
      {...getRouteComponentProp(ContactUs)}
    />
    <Route
      title="How to contact Slimming World's warm and friendly support teams"
      description="If you've got a question about any aspect of Slimming World's services, we're here to help. Find out how to get in touch with our support teams here."
      path={Pages.CONTACT_US_GET_IN_TOUCH}
      {...getRouteComponentProp(ContactUs)}
    />
    <Route
      title="How to contact Slimming World's warm and friendly support teams"
      description="If you have any questions about Slimming World's services, feel free to reach out to us for help and support. We're here to help you with your weight loss journey."
      path={Pages.CONTACT_US_MEDIA}
      {...getRouteComponentProp(ContactUs)}
    />
    <Route
      title="How to contact Slimming World's warm and friendly support teams"
      description="If you have any questions about Slimming World's services, feel free to reach out to us for help and support. We're here to help you with your weight loss journey."
      path={Pages.CONTACT_US_ADS}
      {...getRouteComponentProp(ContactUs)}
    />
  </Route>
);

export default routes;
