/* global 'page','FreeFoodFebruary' */
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import Size from 'common/src/app/data/enum/Size';
import TextNew, {
  Colors,
  AccentColors,
  ElementTypes,
  FontWeight,
  FontSizes,
  TextDecoration,
} from 'components/atoms/TextNew';
import SocialShare from 'components/molecules/SocialShare';
import Roundel from 'components/atoms/Roundel';
import VideoBlock from 'components/molecules/BlockContent/components/atoms/VideoBlock';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import Pages from 'common/src/app/data/enum/Pages';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Wrapper from 'components/atoms/Wrapper';
import GroupOnlineSignUp from 'components/molecules/GroupOnlineSignUp';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import getPublicPrices from 'common/src/app/util/getPublicPrices';
import errorBoundary from 'components/hoc/ErrorBoundary';
import NewsletterSignUp from '../../molecules/NewsletterSignUp';
import './free-food-february.scss';

const FreeFoodFebruary = ({ page, deviceState }, context, className) => {
  const [header, video, videoThumbnail, bodyImage] = page?.nestedContent || [];
  const { src } = bodyImage?.image || {};

  const deviceStateToFontSize = (large, medium, small) => {
    if (deviceState >= DeviceState.LG) {
      return large;
    } else if (deviceState >= DeviceState.MD) {
      return medium;
    }
    return small;
  };

  return (
    <div className={className}>
      <HeroBarPublic image={header?.image}>
        <Wrapper cid="hero-wrapper">
          <TextNew cid="hero" color={Colors.PRIMARY_PURPLE} element={ElementTypes.H1}>
            <TextNew.Serif.MD
              color={AccentColors.ACCENT_PURPLE}
              cid="hero-one"
              weight={FontWeight.LIGHT}
              localeId="freeFoodFebruary.hero.one"
            />
            <TextNew.Serif.MD
              color={AccentColors.ACCENT_PURPLE}
              cid="hero-two"
              weight={FontWeight.LIGHT}
              localeId="freeFoodFebruary.hero.two"
            />
            <TextNew.Script.SM
              color={AccentColors.ACCENT_PURPLE}
              cid="hero-three"
              localeId="freeFoodFebruary.hero.three"
            />
          </TextNew>
          <Icon name={IconName.UNDERLINE_SWOOSH} />
        </Wrapper>
      </HeroBarPublic>

      <div className="freeFoodFebruary-intro">
        <Wrapper.XL>
          <TextNew cid="title" color={Colors.PRIMARY_DARK}>
            <TextNew.Script.XS
              cid="intro-one"
              localeId="freeFoodFebruary.intro.one"
              element={ElementTypes.H2}
            >
              <Icon
                width={deviceState > DeviceState.LG ? 12 : 8}
                name={IconName.REGISTERED_TRADEMARK_SYMBOL}
              />
            </TextNew.Script.XS>
            <TextNew.Serif.XS
              cid="intro-two"
              localeId="freeFoodFebruary.intro.two"
              element={ElementTypes.H3}
            />
            <TextNew.Serif.SM
              cid="intro-three"
              localeId="freeFoodFebruary.intro.three"
              element={ElementTypes.H3}
            >
              <Icon width={8} name={IconName.REGISTERED_TRADEMARK_SYMBOL} />
            </TextNew.Serif.SM>
            <Wrapper cid="fff-body-text">
              <TextNew.Sans.SM
                color={AccentColors.ACCENT_PURPLE}
                cid="intro-four"
                localeId="freeFoodFebruary.intro.four"
              >
                <Link to={Pages.UK_PUBLIC_NEAREST_GROUP_LANDING}>
                  <TextNew.Sans.SM
                    color={AccentColors.ACCENT_PURPLE}
                    localeId="freeFoodFebruary.intro.five"
                    element={ElementTypes.SPAN}
                    decoration={TextDecoration.UNDERLINE}
                  />
                </Link>
              </TextNew.Sans.SM>
            </Wrapper>
          </TextNew>
          <Wrapper cid="fff-body-text">
            <Link className="roundel" to={Pages.UK_PUBLIC_OFFERS}>
              <Roundel size={Size.XL}>
                <TextNew.Serif.XS
                  color={Colors.PRIMARY_LIGHT}
                  cid="roundel-one"
                  localeId="freeFoodFebruary.roundel.one"
                />
                <TextNew.Script.SM
                  color={Colors.PRIMARY_LIGHT}
                  cid="roundel-two"
                  localeId="freeFoodFebruary.roundel.two"
                />
                <TextNew.Serif.XS
                  color={Colors.PRIMARY_LIGHT}
                  cid="roundel-three"
                  localeId="freeFoodFebruary.roundel.three"
                />
                <TextNew.Serif.SM
                  color={Colors.PRIMARY_LIGHT}
                  cid="roundel-four"
                  localeId="freeFoodFebruary.roundel.four"
                />
                <TextNew.Serif.SM
                  color={Colors.PRIMARY_LIGHT}
                  cid="roundel-five"
                  localeId="freeFoodFebruary.roundel.five"
                />
                <TextNew.Sans.XS
                  color={Colors.PRIMARY_LIGHT}
                  cid="roundel-six"
                  localeId="freeFoodFebruary.roundel.six"
                  localeParams={{
                    PRICE: (
                      <TextNew.Sans.XS
                        weight={FontWeight.BOLD}
                        color={Colors.PRIMARY_LIGHT}
                        element={ElementTypes.SPAN}
                      >
                        {getPublicPrices('freeFoodFeb', 'price')}
                      </TextNew.Sans.XS>
                    ),
                  }}
                />
              </Roundel>
            </Link>
          </Wrapper>
        </Wrapper.XL>
      </div>

      <div className="freeFoodFebruary-bookOffer">
        <Wrapper cid="book-offer-wrap">
          <div className="fff-image">
            <ResponsiveImage src={src} />
          </div>
          <Wrapper cid="fff-offer-text">
            <TextNew cid="bookOffer">
              <TextNew.Script.SM
                size={deviceState >= DeviceState.MD ? FontSizes.MD : FontSizes.SM}
                cid="book-offer-one"
                localeId="freeFoodFebruary.bookOffer.one"
                element={ElementTypes.H2}
              />
              <TextNew.Sans
                size={deviceState > DeviceState.LG ? FontSizes.MD : FontSizes.SM}
                cid="book-offer-two"
                localeId="freeFoodFebruary.bookOffer.two"
                weight={FontWeight.BOLD}
              />
              <ul className="bulletpoint">
                <TextNew.Sans.SM
                  localeId="freeFoodFebruary.bookOffer.bulletpoint.one"
                  cid="bullet-one"
                  element={ElementTypes.LI}
                />
                <TextNew.Sans.SM
                  localeId="freeFoodFebruary.bookOffer.bulletpoint.two"
                  cid="bullet-two"
                  element={ElementTypes.LI}
                />
              </ul>
              <TextNew.Sans.SM cid="book-offer-three" localeId="freeFoodFebruary.bookOffer.three" />
            </TextNew>
            <div className="actions">
              <SocialShare />
            </div>
          </Wrapper>
        </Wrapper>
      </div>
      <div className="freeFoodFebruary-fffVideo">
        <Wrapper>
          <TextNew.Serif
            size={deviceStateToFontSize(FontSizes.MD, FontSizes.SM, FontSizes.XS)}
            color={Colors.PRIMARY_LIGHT}
            cid="fff-video-one"
            weight={FontWeight.LIGHT}
            localeId="freeFoodFebruary.fffVideo.one"
          />
          <TextNew.Script.XL
            size={deviceStateToFontSize(FontSizes.XL, FontSizes.LG, FontSizes.MD)}
            color={Colors.PRIMARY_LIGHT}
            cid="fff-video-two"
            localeId="freeFoodFebruary.fffVideo.two"
          />
          <TextNew.Serif
            size={deviceStateToFontSize(FontSizes.MD, FontSizes.SM, FontSizes.XS)}
            color={Colors.PRIMARY_LIGHT}
            cid="fff-video-three"
            weight={FontWeight.LIGHT}
            localeId="freeFoodFebruary.fffVideo.three"
          />
        </Wrapper>
        <Wrapper cid="fff-vid">
          {videoThumbnail && video && (
            <VideoBlock video={video?.video} videoThumbnail={videoThumbnail?.image} />
          )}
        </Wrapper>
      </div>
      <GroupOnlineSignUp />
      <NewsletterSignUp />
    </div>
  );
};

FreeFoodFebruary.propTypes = {
  page: PropTypes.object,
  deviceState: PropTypes.number,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName('page','FreeFoodFebruary'),
)(FreeFoodFebruary);
