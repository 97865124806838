import { DeviceState } from 'common/src/app/data/MediaQueries';

const abbreviate = deviceState => {
  if (deviceState <= DeviceState.MD) {
    return 'labelAbbreviation';
  }
  return 'label';
};

export default abbreviate;
