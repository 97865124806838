import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Configuration from 'common/src/app/config/Configuration';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import Wrapper from '../../atoms/Wrapper';
import './header.scss';

const TOP_OFFSET = 0;

/**
 * Used on pages with a wizard navigation, rather than header / navigation
 * ideally used on forms
 *
 * This will automatically set the component to fixed
 *
 * todo nice to have combined with a scroll manager when this component will not be
 * at the top of the page
 */
class Header extends Component {
  componentDidMount() {
    this.initState();
  }

  componentDidUpdate() {
    this.initState();
  }

  componentWillUnmount() {
    const { unregister } = this.props;

    unregister && unregister();
  }

  // eslint-disable-next-line class-methods-use-this
  getDistanceBetweenTop = el => el && el.getBoundingClientRect().top;

  // eslint-disable-next-line class-methods-use-this
  getHeight = el => el && el.getBoundingClientRect().height;

  isFixed() {
    const fromTop = this.getDistanceBetweenTop(this.fakePaddingDiv);
    return fromTop <= TOP_OFFSET;
  }

  initState = () => {
    const {
      register,
      headerToggleFixed,
      isFixed: previousIsFixed,
      height: previousHeight,
    } = this.props;

    requestAnimationFrame(() => {
      const height = this.getHeight(this.wholeNavWrapper);
      const isFixed = this.isFixed();

      if (isFixed !== previousIsFixed || height !== previousHeight) {
        register && register(height);
        headerToggleFixed && headerToggleFixed(isFixed, height);
      }
    });
  };

  render() {
    const {
      useWrapper = true,
      children,
      center,
      top,
      bottom,
      isFixed,
      height,
      isLiveEvent,
      deviceState,
      hasMenu, // eslint-disable-line react/prop-types
      emailConfirmed,
      homeOnboarderPaused,
    } = this.props;

    // This fix is necessary to ensure the "Continue onboarder tour" row
    // remains visible and not hidden behind the fixed position header
    const continueOnboarderFix = !emailConfirmed && homeOnboarderPaused;

    const headerBackground = isLiveEvent ? 'primary-light' : Configuration.headerBackgroundColor;

    const isMenuFixed = deviceState > DeviceState.XXXL && Configuration.menuFixed;

    const hasWrapper = (content, wrapperProps) =>
      useWrapper ? (
        <Wrapper width="xl" {...wrapperProps}>
          {content}
        </Wrapper>
      ) : (
        content
      );

    return (
      <div {...componentClassNameProp(ComponentType.ORGANISM, this)}>
        <div
          ref={ref => (this.fakePaddingDiv = ref)}
          style={{
            // eslint-disable-next-line no-nested-ternary
            paddingBottom: continueOnboarderFix ? 105 : isFixed ? height : 0,
          }}
        />
        <div
          ref={ref => (this.wholeNavWrapper = ref)}
          className={classNames('header-content', {
            'has-menu': hasMenu,
            'is-fixed': isFixed,
            'menu-fixed': isMenuFixed,
            'using-wrapper': useWrapper,
          })}
        >
          {top && hasWrapper(top)}

          {hasWrapper(
            <Fragment>
              {children}
              {center && <div className="header-center">{center}</div>}
            </Fragment>,
            {
              background: headerBackground,
              cid: 'main-content',
            },
          )}
          {bottom && hasWrapper(bottom, { background: 'primary-light' })}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  /**
   * Nodes to place within the header's wrapper
   */
  children: PropTypes.node,
  /**
   * Nodes to place in the center of the header
   */
  center: PropTypes.node,
  /**
   * Nodes to place below the header
   */
  bottom: PropTypes.node,
  /**
   * Nodes to place on top of the header
   */
  top: PropTypes.node,
  /**
   * denotes whether it is fixed
   */
  isFixed: PropTypes.bool,
  /**
   * denotes whether it is fixed
   */
  height: PropTypes.number,
  /**
   * A function to toggle the fixed state
   */
  headerToggleFixed: PropTypes.func.isRequired,
  /**
   * Action to register this as a fixed element with the ScrollManager
   */
  register: PropTypes.func,
  /**
   * Action to unregister this as a fixed element with the ScrollManager
   */
  unregister: PropTypes.func,
  /**
   * market switch to show a nav
   */
  isLiveEvent: PropTypes.bool,
  deviceState: PropTypes.number,
  useWrapper: PropTypes.bool,
  emailConfirmed: PropTypes.bool,
  homeOnboarderPaused: PropTypes.bool,
};

export default withDeviceState()(Header);
