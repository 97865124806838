import Size from '../../data/enum/Size';

/** @module */

/**
 * Reusable Array of sizes for `propTypes` of a `size` prop.
 * @const sizePropTypes
 * @type {Object}
 * @category templating
 */
export default [Size.XS, Size.SM, Size.MD, Size.LS, Size.LG, Size.XL, Size.XXL];
