import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Pages from 'common/src/app/data/enum/Pages';
import IconName from 'common/src/app/data/enum/IconName';
import SlimmingWorldLogo from 'components/atoms/SlimmingWorldLogo';
import HeadingButton from 'components/atoms/HeadingButton';
import Wrapper from 'components/atoms/Wrapper';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import LocaleMessage from 'components/atoms/LocaleMessage';
import NavLink from 'components/atoms/NavLink';
import Icon from 'components/atoms/Icon';
import './consultant-header.scss';

const consultantNavigationLinks = [
  {
    to: Pages.UK_PUBLIC_BECOME_A_CONSULTANT,
    locale: 'home',
  },
  {
    to: Pages.UK_PUBLIC_BECOME_A_CONSULTANT_OUR_CONSULTANTS,
    locale: 'consultants',
  },
  {
    to: Pages.UK_PUBLIC_BECOME_A_CONSULTANT_OUR_FRANCHISE_PACKAGE,
    locale: 'franchisePackage',
  },
  {
    to: Pages.UK_PUBLIC_BECOME_A_CONSULTANT_FRANCHISE_FACTS,
    locale: 'franchiseFacts',
  },
];

const ConsultantHeader = ({ deviceState }, context, className) => (
  <div className={className}>
    <div className="header-top">
      <Wrapper.XL>
        <SlimmingWorldLogo link={Pages.HOME} />
        <TextNew.Sans.SM element={ElementTypes.SPAN} localeId="consultantHeader.tagLine" />
      </Wrapper.XL>
    </div>
    <nav className="header-bottom">
      <Wrapper.XL>
        {deviceState <= DeviceState.LG ? <HeadingButton /> : null}
        <ul className="main-navigation">
          {consultantNavigationLinks.map(navLink => (
            <li className="navigation-item" key={`navigation-item-${navLink.locale}`}>
              <NavLink to={navLink.to}>
                <TextNew.Formal
                  localeId={`consultantHeader.navigation.${navLink.locale}`}
                  color={Colors.PRIMARY_LIGHT}
                  element={ElementTypes.SPAN}
                />
              </NavLink>
            </li>
          ))}
        </ul>
        <Button to={Pages.UK_PUBLIC_BECOME_A_CONSULTANT_ENQUIRY} cid="enquiry-cta">
          <Icon name={IconName.MENU_COMMUNITY} />
          <LocaleMessage id="consultantHeader.cta" />
        </Button>
      </Wrapper.XL>
    </nav>
  </div>
);

ConsultantHeader.propTypes = {
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'ConsultantHeader')(ConsultantHeader),
);
