const DataLayerKeys = {
  SUBMIT: 'submit',
  DATE_OF_BIRTH_CHECK: 'dateOfBirthCheck',
  PREGNANCY_CHECK: 'pregnancyCheck',
  MEDICAL_CONDITIONS: 'medicalConditions',
  MEDICAL_CRITERIA_CHECK: 'medicalCriteriaCheck',
  DATE_OF_BIRTH: 'dateOfBirth',
  GENDER: 'gender',
  BREASTFEEDING: 'breastfeeding',
  STARTING_BMI: 'startingBmi',
  BMI_CHECK: 'bmiCheck',
  BREASTFEEDING_CHECK: 'breastfeedingCheck',
  VOUCHER: 'voucher',
  PACKAGE_NAME: 'packageName',
  REJOIN_PACKAGE_NAME: 'rejoinPackageName',
  PACKAGE_ADD_ON_SELECTED: 'packageAddOnSelected',
  PURCHASE_TYPE: 'purchaseType', // when the purchase takes place
  PURCHASE_ID: 'purchaseId',
  PURCHASE_VALUE: 'purchaseValue', // the amount in local currency
  PURCHASE_NAME: 'purchaseName', // package name or description about what was bought
  PURCHASE_CURRENCY: 'purchaseCurrency', // currency type for payment
  TOWNCITY: 'towncity',
  COUNTY: 'county',
  STATE: 'state',
  COUNTRY: 'country',
  LIKE: 'like',
  BOOKMARK: 'bookmark',
  FAVOURITE: 'favourite',
  POST: 'post',
  COMMENT: 'comment',
  DAILY_INSPIRATION: 'dailyInspiration',
  WEEK_NUMBER: 'visitsToWeekContent',
  MEMBERSHIP_START_DATE: 'membershipStartDate',
  MEMBERSHIP_END_DATE: 'membershipEndDate',
  MEMBER_WEEK_NUMBER: 'memberWeekNumber',
  MEMBER_WEEK_DAYS: 'memberWeekDays',
  FORCED_FEED_OVERLAYER: 'forcedFeedOverlayer',
  PHOTO_UPLOADED: 'photoUploaded',
  TIMEZONE: 'timezone',
  TARGET_WEIGHT: 'targetWeight',
  DIETARY_PREFERENCES: 'dietaryPreferences',
  GROUP_DETAILS: 'groupDetails',
  GROUP: 'group',
  SEARCH: 'search',
  QUERY: 'query',
  NUM_RESULTS: 'numResults',
  SEARCH_RESULTS_NUMBER: 'searchResultsNumber',
  CURRENT_WEIGHT: 'currentWeight',
  WEIGH_IN_CURRENT_WEIGHT: 'weighInCurrentWeight',
  WEIGH_IN_SKIPPED: 'weighInSkipped',
  WEIGH_IN_COMPLETED: 'weighInCompleted',
  WEIGH_IN_DAY: 'weighInDay',
  WEIGHT_LOSS_SINCE_START: 'weightLossSinceStart',
  WEIGHT_LOSS_PREVIOUS_WEEK: 'weightLossPreviousWeek',
  NUM_OF_WEIGH_INS: 'numOfWeighIns',
  CURRENT_WEIGH_IN_WEEK: 'currentWeighInWeek',
  WEIGHT_TOTAL_PERCENTAGE_LOSS: 'weightTotalPercentageLoss',
  WEIGHT_DIFFERENCE_IN_PERCENTAGE_BETWEEN_WEIGH_INS: 'weightDifferenceInPercentageBetweenWeighIns',
  AWARD: 'award',
  TARGET_ACHIEVED: 'targetAchieved',
  MOOD: 'mood',
  MINI_TARGET: 'miniTarget',
  PHOTO_UPDATED: 'photoUpdated',
  PHOTO_DELETED: 'photoDeleted',
  AVATAR_UPLOADED: 'avatarUploaded',
  EMAIL: 'email',
  TWELVE_WEEK_PROGRAM_FEATURES: 'twelveWeekProgram',
  SUPPORT_EMAIL_OPTIN: 'supportEmailOptin',
  NOTIFICATIONS_EMAIL_OPTIN: 'notificationsEmailOptin',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE_NUMBER: 'phoneNumber',
  ZIP: 'zip',
  REGION: 'region',
  BEFORE_AFTER: 'beforeAfterView',
  NAME: 'name',
  NAME_FEATURES: 'nameFeatures',
  NAME_RECIPES: 'nameRecipes',
  NAME_SUCCESS_STORIES: 'nameSuccess',
  NAME_COMMUNITY: 'nameCommunity',
  NAME_SLIMMING_GROUP: 'nameSlimmingGroup',
  INVITED: 'invited',
  USER_LABEL: 'userLabel',
  ROLE: 'role',
  CONTENT_GROUP_NAME: 'contentGroupName',
  ADDITIONAL_CONTENT_GROUP_NAME: 'additionalContentGroupName',
  MARKET_NAME: 'marketName',
  MEMBER_ID: 'memberId',
  EVENT_TYPE: 'eventType',
  PLAYER_TYPE: 'playerType',
  PATHNAME: 'pathname',
  CATEGORY: 'category',
  MARKET: 'market',
  REFERRER: 'referrer',
  PAGE_SOURCE: 'pageSource',
  PAGE_ROUTE: 'pageRoute',
  PAGE_URL: 'pageUrl',
  FULL_URL: 'fullUrl',
  PAGE_TITLE: 'title',
  WEEK_PROGRAM_CURRENT_WEEK: 'weekProgramCurrentWeek',
  LIVE_EVENT_BUTTON: 'liveEventButton',
  LIVE_EVENT_TAB: 'liveEventTab',
  LIVE_EVENT_EMOJI_SHORTNAME: 'liveEventEmojiShortname',
  LIVE_EVENT_GROUP_ID: 'liveEventGroupId',
  LIVE_EVENT_STAGE: 'liveEventStage',
  LIVE_EVENT_PHOTO: 'liveGroupPhoto',
  PERSONAL_MESSAGE: 'personalMessage',
  WEIGHT_SHARED: 'weightShared',
  RECIPE_NAME: 'recipeName',
  SEARCH_QUERY_RECIPE_RESULTS: 'searchQueryRecipeResults',
  SEARCH_QUERY_FOOD_RESULTS: 'searchQueryFoodResults',
  SEARCH_QUERY_FILTER: 'searchQueryFilter',
  FOOD_DIARY_ACTION: 'foodDiaryAction',
  FOOD_DIARY_TYPE: 'foodDiaryType',
  FOOD_DIARY_NAME: 'foodDiaryName',
  FOOD_DIARY_SYNS: 'foodDiarySyns',
  FOOD_DIARY_FREE: 'foodDiaryFree',
  FOOD_DIARY_SP: 'foodDiarySP',
  FOOD_DIARY_HEX_A: 'foodDiaryHexA',
  FOOD_DIARY_HEX_B: 'foodDiaryHexB',
  FOOD_DIARY_PORTION: 'foodDiaryPortion',
  FOOD_DIARY_MEAL_CATEGORY: 'foodDiaryMealCategory',
  USER_ORIGIN: 'userOrigin',
  HALL_OF_FAME: 'hallOfFame',
  WEIGHT_UNITS_CHANGED: 'weightUnitsChanged',
  BIO_UPDATED: 'bioUpdated',
  RESTART_JOURNEY: 'restartJourney',
  ACTION_PLAN: 'actionPlan',
  OPEN_NAVIGATION: 'navigation',
  NEWSLETTER_SIGNUP: 'newsletterSignup',
  MENU_SIGNUP: 'menuSignup',
  POSTCODE: 'postcode',
  PLACE_SEARCH: 'place',
  HUNGRY_LEARN_MORE: 'hungryLearnMore',
  FRIENDS_LEARN_MORE: 'friendsLearnMore',
  ACTIVITY_LEARN_MORE: 'activityLearnMore',
  BMI_RESULTS: 'bmiResults',
  SEARCH_QUERY: 'searchQuery',
  PRINT: 'print',
  LOAD_MORE: 'loadMore',
  TO_DO_TASK: 'toDoTask',
  ACTIVITY_ADDED: 'activityAdded',
  AWARD_COMPLETED: 'awardCompleted',
  AWARD_RESTARTED: 'awardRestarted',
  AWARD_STARTED: 'awardStarted',
  AWARD_CANCELLED: 'awardCancelled',
  CHOOSE_A_MEAL: 'chooseAMeal',
  POPUP_FOOD_INFO: 'popupFoodInfo',
  POPUP_ICON: 'popupIcon',
  SYNS_EXCEEDED: 'synsExceeded',
  DIARY_DATE_CHANGE: 'diaryDateChange',
  ACTIVITY_FAVOURITE: 'activityFavourite',
  AMEND_START_WEIGHT: 'amendStartWeight',
  AMEND_TARGET_WEIGHT: 'amendTargetWeight',
  REPORT_POST: 'reportPost',
  REPORT_COMMENT: 'reportComment',
  RECENT_RECIPES: 'recentRecipes',
  RECIPES_ADDED: 'recipesAdded',
  RECIPE_IMAGE: 'recipeImage',
  SEARCH_QUERY_ACTIVITY_RESULTS: 'searchQueryActivityResults',
  ONBOARDER_PAGE: 'onboarderPage',
  ONBOARDER_STATUS: 'onboarderStatus',
  ONBOARDER_STEP: 'onboarderStep',
  SKIP_TOUR: 'skipTour',
  MEMBER_TYPE: 'memberType',
  START_JOURNEY: 'startJourney',
  RECOMMENDED_READING_ARTICLE: 'twelveWeekProgram',
  LOCATION: 'location',
  MIGRATION_FAQS_CATEGORY: 'migrationFaqsCategory',
  MIGRATION_FAQS_VIDEO_PLAYED: 'migrationFaqsVideoPlayed',
  MIGRATION_FAQS_QUESTION_SELECTED: 'migrationFaqsQuestionSelected',
  MIGRATION_FAQS_ALL_QUESTIONS: 'migrationFaqsAllQuestions',
  PACKAGE_IMAGE_UPLOAD_TOTAL_PHOTOS: 'packageImageUploaderTotalPhotos',
  SECURITY_QUESTION_FAIL: 'securityQuestionFail',
  ADD_YOUR_WEIGHT_AWARDS: 'addYourWeightAwards',
  BUILD_PROFILE_ENTER_TARGET_WEIGHT: 'enterTargetWeight',
  BUILD_PROFILE_ENTER_START_WEIGHT: 'enterStartWeight',
  BUILD_PROFILE_PREVIOUS_MEMBER: 'previousMember',
  BUILD_PROFILE_GROUP_RECENCY: 'groupRecency',
  BUILD_PROFILE_GROUP_WEGHIN_DAY: 'groupWeighinDay',
  BUILD_PROFILE_VEGETARIAN_VEGAN: 'vegetarianVegan',
  BUILD_PROFILE_COOKING_ABILITY: 'cookingAbility',
  BUILD_PROFILE_ACTIVITY_LEVEL: 'activityLevel',
  BUILD_PROFILE_USERNAME: 'userName',
  BUILD_PROFILE_MOTIVATION: 'motivation',
  SLIMMER_OF_THE_WEEK: 'sow',
  SLIMMER_OF_THE_MONTH: 'som',
  OTHER_GROUPS: 'otherGroups',
  ACCOUNT_RECOVERY_FAILED: 'accountRecoveryFailed',
};

export default DataLayerKeys;
