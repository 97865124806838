import compose from 'redux/lib/compose';
import { API_STATIC_PUBLIC_RECIPE_LANDING_PAGE_V2 } from 'common/src/app/data/apiStatics';
import { connect } from 'react-redux';
import { SearchType, getSearchResults } from 'common/src/app/actions/resources/searchActions';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { landingGridSelector, staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';

import RecipeLanding from './RecipeLanding';

const getResults = (dispatch, loadMore, query, featuredRecipes, highlightedRecipe) => {
  // Recipe ids to exclude from the search - as we already have them within the featuredRecipes / highlighted recipe data
  // but only if we have an empty search query
  const excludedIds =
    Object.keys(query)?.length > 0 ? [] : featuredRecipes?.map(recipe => recipe?.id);

  // Also include the highlightedRecipe id into the to the excludedIds array
  if (excludedIds && highlightedRecipe?.id) {
    excludedIds.push(highlightedRecipe.id);
  }

  return dispatch(
    getSearchResults(
      SearchType.PUBLIC_RECIPE,
      {
        limit: 12,
        ...query,
      },
      loadMore,
      null,
      null,
      excludedIds,
    ),
  );
};

const mapStateToProps = state => {
  const page = staticPageSelector(state, API_STATIC_PUBLIC_RECIPE_LANDING_PAGE_V2);

  const recipes = landingGridSelector(state, true);
  return {
    headerAssets: page?.nestedContent,
    highlightedRecipe: page?.highlightedRecipe,
    featuredRecipes: page?.featuredRecipes,
    ...recipes,
  };
};

const mapDispatchToProps = (dispatch, { location: { query } = {} }) => ({
  loadMore: (featuredRecipes, highlightedRecipe) =>
    getResults(dispatch, true, query, featuredRecipes, highlightedRecipe),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction(
  ['location.query'],
  {
    prepared: async ({ location: { query } = {} }, dispatch) => {
      const recipeLandingPage = await dispatch(
        getStaticContent(API_STATIC_PUBLIC_RECIPE_LANDING_PAGE_V2, true),
      );

      return [
        getResults(
          dispatch,
          false,
          query,
          recipeLandingPage?.entity?.featuredRecipes,
          recipeLandingPage?.entity?.highlightedRecipe,
        ),
      ];
    },
  },
  { getPrepareKey: componentId => componentId },
);

export default compose(addInitAction, connected)(RecipeLanding);
