import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction, prepareComponent } from '@mediamonks/react-redux-component-init';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { API_COMPONENT_CONTACT_US_BACKGROUND } from 'common/src/app/data/apiComponents';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';
import ContactUsGetInTouch from '../../templates/ContactUsGetInTouch';

import ContactUs from './ContactUs';

const mapStateToProps = (state, { location }) => ({
  host: state.config.environmentConfig.web.public.host,
  background: contentComponentSelector(state, API_COMPONENT_CONTACT_US_BACKGROUND, 'image'),
  pathname: location?.pathname,
});

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(prepareComponent(ContactUsGetInTouch)),
      dispatch(getComponent(API_COMPONENT_CONTACT_US_BACKGROUND)),
    ]),
});

const connected = connect(mapStateToProps);

export default compose(addInitAction, connected)(ContactUs);
