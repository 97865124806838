/* global WP_DEFINE_IS_NODE */
import React from 'react';
import moment from 'moment';
import MealType from 'common/src/app/data/enum/FilterTypes/MealType';
import Cuisine from 'common/src/app/data/enum/FilterTypes/Cuisine';
import PropTypes from 'prop-types';
import getPublicPrices from 'common/src/app/util/getPublicPrices';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';

/**
 *StructuredDataGenerator
 *
 * Currently Hard coded - this will need making much smarter!
 */
export const types = {
  RECIPE: 'Recipe',
  PERSON: 'Person',
  LOCAL_BUSINESS: 'LocalBusiness',
  ORGANIZATION: 'Organization',
  FAQ: 'FAQ',
};

const tileMappings = {
  Recipe: types.RECIPE,
  RecipeTile: types.RECIPE,
  PublicSuccessStoryTile: types.PERSON,
  LocalBusiness: types.LOCAL_BUSINESS,
  SuccessStoryTile: types.PERSON,
  Organization: types.ORGANIZATION,
  FAQ: types.FAQ,
};

const StructuredDataGenerator = ({ data, type }) => {
  if (!tileMappings[type]) return null;

  const json = {
    '@context': 'http://schema.org/',
    '@type': tileMappings[type],
  };

  // This schema.org type is called from the public home page
  // -This type is hard coded and is not passed any 'data' from the calling component
  // -There is a UK and a ROI version
  if (tileMappings[type] === types.ORGANIZATION) {
    json.name = 'Slimming World';
    json.alternateName =
      marketName === 'IE' ? 'slimmingworld.ie' : ['slimmingworld.co.uk', 'slimmingworld'];
    json.logo = 'https://content.presspage.com/uploads/1756/1920_swlogo2018cmyk-1.png';
    json.description =
      'Slimming World is a weight loss programme like no other. Millions have reached their dream weight with our generous no-hunger eating plan, an activity programme personalised to your lifestyle, plus unbeatable support in group or online to help you make lasting changes – for life.';

    json.sameAs = [
      'https://www.instagram.com/slimmingworld/',
      'https://www.facebook.com/slimmingworld',
      'https://twitter.com/SlimmingWorld',
      'https://www.youtube.com/user/slimmingworld',
      'https://www.pinterest.co.uk/slimmingworld/',
      'https://www.wikidata.org/wiki/Q7540657',
      'https://en.wikipedia.org/wiki/Slimming_World',
    ];

    json.brand = 'Slimming World';
    json.founder = {
      '@type': 'Person',
      name: 'Margaret Miles-Bramwell',
    };
    json.url =
      marketName === 'IE' ? 'https://www.slimmingworld.ie/' : 'https://www.slimmingworld.co.uk/';
    json.foundingDate = '1969';
    json.address = {
      '@type': 'PostalAddress',
      addressLocality: 'Derbyshire, England',
      postalCode: 'DE55 4SW',
      streetAddress: '4 Clover Nook Road, Somercotes, Alfreton',
    };
    json.contactPoint = {
      '@type': 'ContactPoint',
      contactType: marketName === 'IE' ? '01 656 9600' : '0344 892 0400',
    };

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
      />
    );
  }

  // For other types - ensure that we have data to display
  if (!data) return null;

  if (data.title) {
    json.name = data.title;
  }

  if (data.description) {
    json.description = data.description;
  }

  if (tileMappings[type] === types.PERSON) {
    json.author = {
      '@type': 'Organization',
      name: 'Slimming World',
    };
    json.name = data.person;
    json.image = data?.image?.src;
  }

  if (tileMappings[type] === types.RECIPE) {
    json.author = {
      '@type': 'Organization',
      name: 'Slimming World',
    };
    json.image = `${data?.image?.src}&width=552&height=552`;

    if (data.preparationTime) {
      json.prepTime = `PT${data.preparationTime}M`;
    }

    if (data.cookingTime) {
      json.cookTime = `PT${data.cookingTime}M`;
    }

    if (data.totalTime) {
      json.totalTime = `PT${data.totalTime}M`;
    }

    json.recipeYield = `${data.servings} servings`;
    json.recipeCategory = MealType[data.mealType];
    json.recipeCuisine = Cuisine[data.cuisine];
    json.recipeIngredient = data.ingredients && [...data.ingredients];
    json.recipeInstructions = data.method && [
      data.method.map(methodItem => ({
        '@type': 'HowToStep',
        text: methodItem,
      })),
    ];
  }

  if (tileMappings[type] === types.LOCAL_BUSINESS) {
    const schedule = [];

    data.sessions.forEach(session => {
      const start = moment(session.time, 'h:mm A').format('HH:mm');
      // Get the day name from the day
      const dayOfWeek = moment()
        .isoWeekday(session.day)
        .format('dddd');
      schedule.push({
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: `http://schema.org/${dayOfWeek}`,
        opens: start,
      });
    });

    const {
      consultant: { name: consultantName, telephone },
      venue,
      name,
    } = data;
    const streetAddress = `${venue.address1}, ${venue.address2}, ${venue.address3}`;

    json.name = `${consultantName}'s Slimming World group in ${name}`;
    json.image = 'https://www.slimmingworld.co.uk/slimming-world-logo.png';
    json.telephone = telephone;
    json.address = {
      '@type': 'PostalAddress',
      addressLocality: venue.town,
      addressRegion: venue.county,
      postalCode: venue.postcode,
      streetAddress: streetAddress && streetAddress.replace(', undefined', ''),
    };

    json.priceRange = getPublicPrices('group', 'week');
    json.openingHoursSpecification = schedule;
  }

  if (tileMappings[type] === types.FAQ) {
    json.author = {
      '@type': 'Organization',
      name: 'Slimming World',
    };

    json.mainEntity = [];

    if (data?.questions?.length > 0) {
      data.questions.forEach(question => {
        const faqItem = {
          '@type': 'Question',
          name: question.questions,
          acceptedAnswer: {
            '@type': 'Answer',
            text: question.answers,
          },
        };

        json.mainEntity.push(faqItem);
      });
    }
  }

  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }} />
  );
};

StructuredDataGenerator.propTypes = {
  data: PropTypes.object,
  type: PropTypes.oneOf(Object.keys(tileMappings)).isRequired,
};

export default StructuredDataGenerator;
