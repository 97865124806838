import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import classNames from 'classnames';
import InputType from 'common/src/app/data/enum/InputType';
import GroupSearchFieldNames from 'common/src/app/data/enum/FieldNames/GroupSearchFieldNames';
import formikErrorCodes from 'common/src/app/validation/formikErrorCodes';
import { hasGeolocationGroupSearch } from 'common/src/app/config/market/market.configdefinitions';
import GroupSearchType from 'common/src/app/data/enum/GroupSearchType';
import IconName from 'common/src/app/data/enum/IconName';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Icon from '../../atoms/Icon';
import TextNew, { Colors, ElementTypes } from '../../atoms/TextNew';
import FormikError from '../../atoms/FormikError';
import Button from '../../atoms/Button';
import Input, { descriptorTypes } from '../../atoms/Input';
import NearestGroupGeoLocation from '../NearestGroupGeoLocation';
import DropDownResults from '../DropDownResults';
import './free-text-group-search.scss';

const FreeTextGroupSearch = (
  {
    handleSubmit,
    handleChange,
    values,
    towns,
    getTowns,
    publicHost,
    external,
    setFieldValue,
    loadingResults,
    setErrors,
    errors,
    goToTown,
    resultsPositionedRelative,
    geoLocationError,
    deviceState,
    showSearchSubmit,
    localeId,
  },
  { getMessage },
) => {
  const entry = values[GroupSearchFieldNames.FREE_TEXT];
  const [searchType, setSearchType] = useState(GroupSearchType.POSTCODE);

  const [isLoadingGeo, setIsLoadingGeo] = useState(false);

  const loadingCallBack = isLoading => {
    setIsLoadingGeo(isLoading);
  };

  useEffect(() => {
    if (geoLocationError) {
      setIsLoadingGeo(false);
    }
  }, [geoLocationError]);

  const hasNoResultError =
    errors[GroupSearchFieldNames.FREE_TEXT] === formikErrorCodes.NO_GROUP_SEARCH_TOWNS;

  /**
   * Listens for text entry changes to
   * - re-evaluate search type
   * - reset 'no town' result
   *   (to re-evaluate wether query matches any result on deletion of characters)
   */
  useEffect(() => {
    /**
     * ALWAYS SET GROUP SEARCH STRATERGY TO POSTCODE SO THAT THE DROPDOWN
     * DOESN'T SHOW. THIS IS TEMPORARY UNTIL WE RESOLVE THE GOOGLE API RATE
     * LIMIT ISSUE.
     */
    // const result = detectGroupSearchType(entry);
    const result = GroupSearchType.POSTCODE;
    setSearchType(result);

    if (
      result === GroupSearchType.PLACE &&
      errors[GroupSearchFieldNames.FREE_TEXT] === formikErrorCodes.NO_GROUP_SEARCH_TOWNS
    ) {
      // Because we set the error after this, it will only remove the error on a subsequent change
      const errorCopied = errors;
      delete errorCopied[GroupSearchFieldNames.FREE_TEXT];
      setErrors(errorCopied);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry]);

  /**
   * When the search type changes, reset any previous errors
   */
  useEffect(() => {
    setErrors({});
    setFieldValue('searchType', searchType);
    searchType === GroupSearchType.PLACE && !towns && getTowns(setErrors);
  }, [searchType, towns, setFieldValue, setErrors, getTowns]);

  function stateReducer(state, changes) {
    // this overwrites downshifts default behaviour of reseting the input value
    // on blur to last selected item or resetting and instead maintains what
    // they have typed
    switch (changes.type) {
      case Downshift.stateChangeTypes.touchEnd:
      case Downshift.stateChangeTypes.mouseUp:
      case Downshift.stateChangeTypes.blurInput:
        return {
          ...changes,
          inputValue: values.postcode,
        };
      default:
        return changes;
    }
  }

  const handleTownChange = item => {
    // if the user selects one of the suggestions update the input value
    // to be in sync
    setFieldValue(GroupSearchFieldNames.FREE_TEXT, item ? item.value : '');
    goToTown(item ? item.value : '');
  };

  return (
    <form
      onSubmit={handleSubmit}
      data-testid="free-text-group-search"
      className={classNames(
        showSearchSubmit && 'show-search-submit',
        'molecule-free-text-group-search',
        searchType === GroupSearchType.PLACE && !hasNoResultError && 'dropdown-results',
      )}
    >
      <Downshift
        stateReducer={stateReducer}
        onChange={item => handleTownChange(item)}
        itemToString={item => (item ? item.value : '')}
      >
        {({
          getRootProps,
          getInputProps,
          getItemProps,
          getMenuProps,
          getLabelProps,
          highlightedIndex,
          selectedItem,
          isOpen,
        }) => (
          <div className="inputWrapper" {...getRootProps({}, { suppressRefError: true })}>
            <div className="inputs">
              {hasGeolocationGroupSearch && (
                <NearestGroupGeoLocation
                  external={external}
                  publicHost={publicHost}
                  loadingCallBack={loadingCallBack}
                />
              )}
              <Input
                dataTestid="free-text-group-search-input"
                autocomplete="off"
                convertOnChange={false}
                type={InputType.TEXT}
                {...getInputProps({
                  name: GroupSearchFieldNames.FREE_TEXT,
                  onChange: handleChange,
                  placeholder: getMessage(`${localeId}.searchPlaceholder`),
                })}
                descriptorType={descriptorTypes.GHOST}
                labelProps={{ ...getLabelProps() }}
              />
              <Button
                dataTestid="free-text-group-search-button"
                type={InputType.SUBMIT}
                cid="find-group-button"
                isLoading={loadingResults || isLoadingGeo}
              >
                {showSearchSubmit && deviceState <= DeviceState.SM ? (
                  <Icon name={IconName.MAGNIFY_GLASS} />
                ) : (
                  <TextNew.Sans.SM
                    color={Colors.PRIMARY_LIGHT}
                    element={ElementTypes.SPAN}
                    localeId={`${localeId}.button`}
                  />
                )}
              </Button>
            </div>
            <FormikError
              dataTestid="free-text-group-search-error"
              name={GroupSearchFieldNames.FREE_TEXT}
              triggerUntouched
            />

            {Object.keys(errors).length === 0 &&
              (isOpen || resultsPositionedRelative) &&
              searchType === GroupSearchType.PLACE && (
                <DropDownResults
                  loadingResults={loadingResults}
                  hasNoResultError={hasNoResultError}
                  highlightedIndex={highlightedIndex}
                  selectedItem={selectedItem}
                  getMenuProps={getMenuProps}
                  towns={towns}
                  entry={entry}
                  getItemProps={getItemProps}
                  setFieldValue={setFieldValue}
                  setErrors={setErrors}
                />
              )}
          </div>
        )}
      </Downshift>
    </form>
  );
};

FreeTextGroupSearch.defaultProps = {
  localeId: 'nearestGroupSearchBar',
};

FreeTextGroupSearch.propTypes = {
  localeId: PropTypes.string,
  showSearchSubmit: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  getTowns: PropTypes.func.isRequired,
  loadingResults: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  towns: PropTypes.array,
  publicHost: PropTypes.string,
  external: PropTypes.bool,
  geoLocationError: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  goToTown: PropTypes.func,
  resultsPositionedRelative: PropTypes.bool,
  deviceState: PropTypes.number,
};

FreeTextGroupSearch.contextTypes = {
  getMessage: PropTypes.func,
};

export default withDeviceState()(FreeTextGroupSearch);
