/* global 'atom','SmallFeatureTile' */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ResponsiveImage from '../../../../../atoms/ResponsiveImage';
import TextNew from '../../../../../atoms/TextNew';
import './small-feature-tile.scss';

const SmallFeatureTile = ({ title, link, onClick, image }, context, className) => (
  <Link to={link} onClick={onClick} className={className}>
    <TextNew.Details cid="label">{title}</TextNew.Details>
    <ResponsiveImage px={278} ratio={1 / 1.34} src={image.src} alt={title} />
  </Link>
);

SmallFeatureTile.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func,
};

export default withFunctionalClassName('atom','SmallFeatureTile')(SmallFeatureTile);
