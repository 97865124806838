import PropTypes from 'prop-types';
import VideoType from '../../../data/enum/VideoType';
import ImagePropTypes from '../ImagePropTypes';

const propType = {
  video: PropTypes.shape({
    _type: PropTypes.oneOf([VideoType.YOUTUBE, VideoType.BRIGHTCOVE, VideoType.LEGACY]),
    videoId: PropTypes.string,
    autohide: PropTypes.number,
    autoPlay: PropTypes.number,
    controls: PropTypes.number,
    videoThumbnail: ImagePropTypes,
    rel: PropTypes.number,
  }),
};

export default propType;
