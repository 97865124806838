/* global 'organism','NutritionSymbols' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import Icon from '../../atoms/Icon';
import TooltipWrapper from '../../molecules/TooltipWrapper';
import LocaleMessage from '../../atoms/LocaleMessage';
import './nutrition-symbols.scss';

/**
 * This component holds important infomation relating a to recipes
 */
const NutritionSymbols = ({ symbols }, context, className) => (
  <div className={className}>
    {symbols.map(icon => (
      <TooltipWrapper
        key={icon}
        position={DirectionType.BOTTOM_RIGHT}
        label={<LocaleMessage id={`nutritionSymbols.${icon}`} />}
      >
        <div className="symbol">
          <Icon name={icon} />
        </div>
      </TooltipWrapper>
    ))}
  </div>
);

NutritionSymbols.propTypes = {
  symbols: PropTypes.arrayOf(PropTypes.string),
};

export default withFunctionalClassName('organism','NutritionSymbols')(NutritionSymbols);
