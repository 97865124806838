import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import camelCase from 'lodash/camelCase';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import FilterSidebar from 'components/organisms/FilterSidebar';
import { icelandfilterConfig } from 'common/src/app/data/enum/FoodRangeFlags';
import FilterCategoryGroup from 'components/organisms/FilterSidebar/components/FilterCategoryGroup';

/**
 * Generates an array of objects with 'name' and 'text' properties based on the fields.
 */
const generateValues = ({ types, keyOnly, getMessage }) =>
  Object.keys(types).map(key => {
    const name = keyOnly ? key : types[key];
    const text = keyOnly
      ? getMessage(`searchFilter.foodRange.foodRangeDiet.${camelCase(key)}`)
      : key;
    return { name, text };
  });

const IcelandFilter = ({ categoryFilters, values, toggle, handleChange, getMessage }) => (
  <FilterSidebar filterLabel={'Filter Food Range recipes'} onClose={toggle}>
    {/* eslint-disable-next-line react/prop-types */}
    {icelandfilterConfig(categoryFilters)?.map(({ fieldName, legend, flags }) => (
      <FilterCategoryGroup
        key={fieldName}
        activeValues={values[fieldName]}
        legend={legend}
        values={generateValues({
          types: flags,
          keyOnly: fieldName !== 'categories',
          getMessage,
        })}
        handleChange={handleChange}
        fieldName={fieldName}
      />
    ))}
  </FilterSidebar>
);

IcelandFilter.propTypes = {
  categoryFilters: PropTypes.objectOf(PropTypes.string),
  values: PropTypes.object,
  toggle: PropTypes.func,
  handleChange: PropTypes.func,
  getMessage: PropTypes.func,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'IcelandFilter')(IcelandFilter);
