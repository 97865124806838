import React from 'react';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, { Colors } from 'components/atoms/TextNew';
import Pages from 'common/src/app/data/enum/Pages';
import Link from 'react-router/lib/Link';

import './virtual-group-roundel.scss';

const VirtualGroupRoundel = (props, context, className, dataTestid) => (
  <Link to={Pages.UK_PUBLIC_NEAREST_GROUP_LANDING} className={className} data-testid={dataTestid}>
    <TextNew.Serif.SM
      cid="virtual-group-roundel-serif"
      color={Colors.PRIMARY_LIGHT}
      localeId="virtualGroupRoundel.group"
    />
    <TextNew.Script.LG
      cid="virtual-group-roundel-script"
      color={Colors.PRIMARY_LIGHT}
      localeId="virtualGroupRoundel.virtual"
    />
    <TextNew.Serif.SM color={Colors.PRIMARY_LIGHT} localeId="virtualGroupRoundel.trail" />
  </Link>
);

export default withFunctionalClassName(
  ComponentType.ATOM,
  'VirtualGroupRoundel',
)(VirtualGroupRoundel);
