import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Creates a wrapper HTML element that tracks events on child elements of the given type. When
 * an event occurs, a `TRACK_DOM_EVENT` action is dispatched. This action can
 * be listened to using `redux-listeners-middleware` to trigger a tracking event.
 *
 * See the props description for configuration information.
 *
 * **Note: only events that bubble up the DOM tree can be tracked using this method**
 *
 * **Note: any additional props passed to <DomEventTracker /> will be passed on to the wrapping
 * element. This can be used to attach a `className`. for example. **
 */
class DomEventTracker extends PureComponent {
  handleEvent = e => {
    const { targetElements, meta = {}, trackDomEvent } = this.props;

    let element = e.target;

    do {
      const tagName = element.tagName.toLowerCase();
      if (targetElements.includes(tagName)) {
        const className = element.className;
        const match = className.match(/cid-([^ ]+)/);
        const cid = match ? match[1] : null;

        if (meta) {
          trackDomEvent(meta, e.type, tagName, className, cid);
        }

        break;
      }

      element = element.parentNode;
    } while (element.parentNode && element !== this.wrapperElement);
  };

  registerWrapperElement = el => (this.wrapperElement = el);

  render() {
    const {
      element: Element,
      children,
      eventType,
      trackDomEvent, // eslint-disable-line no-unused-vars
      targetElements, // eslint-disable-line no-unused-vars
      meta, // eslint-disable-line no-unused-vars
      ...props
    } = this.props;

    return (
      <Element
        className="dom-event-tracker"
        {...props}
        {...{ [eventType]: this.handleEvent }}
        ref={this.registerWrapperElement}
      >
        {children}
      </Element>
    );
  }
}

DomEventTracker.defaultProps = {
  element: 'div',
  eventType: 'onClick',
  targetElements: ['button', 'a'],
};

DomEventTracker.propTypes = {
  /**
   * The element to use as wrapper. Defaults to `'div'`
   */
  element: PropTypes.string,
  /**
   * The event type that should be tracked. Defaults to `'onClick'`
   */
  eventType: PropTypes.string,
  /**
   * Array of dom element names to track for events. Defaults to `['button', 'a']`
   */
  targetElements: PropTypes.arrayOf(PropTypes.string),
  /**
   * Optional object containing additional metadata that will be attached to the `meta` of the
   * `TRACK_EVENT` action.
   */
  meta: PropTypes.object,
  children: PropTypes.node.isRequired,
  /**
   * Tracks a Event click. Passed by connect() wrapper
   */
  trackDomEvent: PropTypes.func.isRequired,
};

export default DomEventTracker;
