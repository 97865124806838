import PropTypes from 'prop-types';

/** @module */

/**
 * Reusable object to use in `propTypes` for a `image` prop.
 * @type {Object}
 * @category templating
 */
const imageShape = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  caption: PropTypes.string,
  focalPoint: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
  }),
  _type: PropTypes.string,
  id: PropTypes.string,
};

export default imageShape;
