import ImageRefs from './ImageRefs';

export default {
  [ImageRefs.ULTRA_WIDE]: '(min-width: 1440px)',
  [ImageRefs.DESKTOP]: '(min-width: 1280px)',
  [ImageRefs.SMALL_DESKTOP]: '(min-width: 1024px)',
  [ImageRefs.TABLET]: '(min-width: 768px)',
  [ImageRefs.LARGE_MOBILE]: '(min-width: 578px)',
  [ImageRefs.SMALL_MOBILE]: null, // we set this to null as it will be our fallback image
};
