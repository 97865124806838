import createAction from 'redux-actions/lib/createAction';
import orderBy from 'lodash/orderBy';
import { push as historyPush } from 'react-router-redux';
import Pages from 'common/src/app/data/enum/Pages';
import { toIndexArray, convertBitwiseToArrayOfFlags } from 'common/src/app/util/bitwiseUtils';
import {
  NearestGroupSearchDayFlags,
  NearestGroupSearchTimesFlags,
} from 'common/src/app/data/enum/GroupSearchFilterFlags';

import StatusCode from 'common/src/app/data/enum/StatusCode';
import { apiGet, apiPost } from './apiActions/apiRequest';
import { GATEWAY_GROUP_SEARCH } from '../../data/Injectables';
import dedupeAsyncThunk from '../../util/dedupeAsyncThunk';
import { createPath } from '../../util/routeUtils';
import NotFoundErrorRedirect from '../../util/NotFoundErrorRedirect';

/**
 * Public Group
 */

export const GET_GROUP_DETAIL_CALL = 'publicActions/GET_GROUP_DETAIL_CALL';
export const GET_GROUP_DETAIL = 'publicActions/GET_GROUP_DETAIL';
export const getGroupDetailSave = createAction(GET_GROUP_DETAIL);
export const getGroupDetail = dedupeAsyncThunk(
  id => dispatch =>
    dispatch(apiGet(GET_GROUP_DETAIL_CALL, GATEWAY_GROUP_SEARCH, `/groups/${id}`))
      .then(result => {
        result && result.data && dispatch(getGroupDetailSave(result.data));
        return result.data;
      })
      .catch(error => {
        const { status } = error?.response;
        // If the status is 404:
        // - redirect to the smart not found page
        // - prefixing with -groups- (so that we know it is a group detail page 404)
        if (status === StatusCode.STATUS_404) {
          const combinedSlug = `group-${id}`;
          throw new NotFoundErrorRedirect(
            `${createPath(Pages.UK_PUBLIC_SMART_CONTENT_NOT_FOUND_PAGE, { slug: combinedSlug })}`,
          );
        }
        throw error;
      }),
  true,
);

export const GET_GROUP_SEARCH_RESULTS_CALL = 'publicActions/GET_GROUP_SEARCH_RESULTS_CALL';
export const GET_GROUP_SEARCH_RESULTS = 'publicActions/GET_GROUP_SEARCH_RESULTS';
export const getGroupSearchResults = createAction(GET_GROUP_SEARCH_RESULTS);

/**
 * There are some inconsistencies in the way the filters are set due to the way the enums
 * are configured in the backend. It made sense to deal with those inconsistencies here rather
 * than create a breaking change in the API.
 *
 * Days filter needs to convert the bitwise flag to an array of indexes to match the
 * enum in the backend and ensure that we can set the correct filter checkboxes in the frontend.
 * Times filter needs to convert to an array of flags to match the enum in the backend.
 * VenueAccessibilty just passed the bitwise flag directly to the API instead of converting it to
 * an array of values.
 */
export const geocodeLocationGroupSearch = ({
  days,
  times,
  venueAccessibility,
  lat,
  lng,
  rawPostcode,
}) => (dispatch, getState) => {
  const state = getState();
  return dispatch(
    apiPost(GET_GROUP_SEARCH_RESULTS_CALL, GATEWAY_GROUP_SEARCH, `/groups/search/location`, {
      rawPostcode,
      region: state.config.environmentConfig.geocoding.region,
      filters: {
        days: toIndexArray(NearestGroupSearchDayFlags, days),
        times: convertBitwiseToArrayOfFlags(NearestGroupSearchTimesFlags, times),
        venueAccessibility,
      },
      lat,
      lng,
    }),
  ).then(apiResult => dispatch(getGroupSearchResults(apiResult.data.groups)));
};

export const FETCH_GROUP_SEARCH_RESULTS = 'publicActions/FETCH_GROUP_SEARCH_RESULTS';
export const SET_GROUP_SEARCH_RESULTS = 'publicActions/SET_GROUP_SEARCH_RESULTS';
export const setGroupSearchResults = createAction(SET_GROUP_SEARCH_RESULTS);

/**
 * There are some inconsistencies in the way the filters are set due to the way the enums
 * are configured in the backend. It made sense to deal with those inconsistencies here rather
 * than create a breaking change in the API.
 *
 * Days filter needs to convert the bitwise flag to an array of indexes to match the
 * enum in the backend and ensure that we can set the correct filter checkboxes in the frontend.
 * Times filter needs to convert to an array of flags to match the enum in the backend.
 * VenueAccessibilty just passed the bitwise flag directly to the API instead of converting it to
 * an array of values.
 */
export const countyBasedGroupSearch = (
  query,
  region,
  days,
  times,
  venueAccessibility,
  searchType = 'county',
) => dispatch =>
  dispatch(
    apiPost(
      FETCH_GROUP_SEARCH_RESULTS,
      GATEWAY_GROUP_SEARCH,
      `/groups/search/field`,
      {
        region,
        [searchType]: query,
        filters: {
          days: toIndexArray(NearestGroupSearchDayFlags, days),
          times: convertBitwiseToArrayOfFlags(NearestGroupSearchTimesFlags, times),
          venueAccessibility,
        },
      },
      {},
    ),
  ).then(groups =>
    dispatch(setGroupSearchResults(orderBy(groups.data.groups, ['venue.town', 'venue.address1']))),
  );

export const SET_GROUP_SEARCH_COUNTIES = 'publicActions/SET_GROUP_SEARCH_COUNTIES';
export const setCounties = createAction(SET_GROUP_SEARCH_COUNTIES);

export const GET_GROUP_SEARCH_COUNTIES = 'publicActions/GET_GROUP_SEARCH_COUNTIES';

export const getCounties = region => dispatch =>
  dispatch(apiGet(GET_GROUP_SEARCH_COUNTIES, GATEWAY_GROUP_SEARCH, `/groups/counties/${region}`))
    .then(response => dispatch(setCounties(response.data.counties)))
    .catch(e => {
      console.error(e);
    });

// Towns specific to a search request
export const SET_GROUP_SEARCH_TOWNS = 'publicActions/SET_GROUP_SEARCH_TOWNS';
export const setTowns = createAction(SET_GROUP_SEARCH_TOWNS);

// Every town we have
export const SET_ALL_GROUP_SEARCH_TOWNS = 'publicActions/SET_ALL_GROUP_SEARCH_TOWNS';
export const setAllTowns = createAction(SET_ALL_GROUP_SEARCH_TOWNS);

export const SET_GROUP_SEARCH_LOADING = 'publicActions/SET_GROUP_SEARCH_LOADING';
export const setGroupSearchLoading = createAction(SET_GROUP_SEARCH_LOADING);

export const GET_GROUP_SEARCH_TOWNS = 'publicActions/GET_GROUP_SEARCH_TOWNS';
export const GET_GEOCODE_FOR_TOWN = 'publicActions/GET_GEOCODE_FOR_TOWN';

export const getTowns = (region, county = '') => dispatch =>
  dispatch(
    apiGet(
      GET_GROUP_SEARCH_TOWNS,
      GATEWAY_GROUP_SEARCH,
      `/groups/towns/${region}${county === '' ? '' : `/${county}`}`,
    ),
  ).then(response => {
    const isTownsNotEmpty = response.data.towns.length > 0;
    if (isTownsNotEmpty) {
      dispatch(setTowns(response.data.towns));
      return response;
    }
    throw new NotFoundErrorRedirect(
      `${createPath(Pages.UK_PUBLIC_SMART_CONTENT_NOT_FOUND_PAGE, { slug: 'group-county' })}`,
    );
  });

/**
 * Get all towns from the DB (this is not xpressweigh's list of towns)
 */
export const getFullTowns = () => dispatch =>
  dispatch(apiGet(GET_GROUP_SEARCH_TOWNS, GATEWAY_GROUP_SEARCH, '/towns')).then(response => {
    dispatch(setAllTowns(response.data));
    return response;
  });

export const getGeocodeByTown = town => dispatch =>
  dispatch(apiGet(GET_GEOCODE_FOR_TOWN, GATEWAY_GROUP_SEARCH, `/towns/find?townAndCounty=${town}`));

export const goToTowns = async (town, dispatch) => {
  let result = {};

  await dispatch(getGeocodeByTown(town)).then(response => {
    const data = response.data;
    return (result = { lat: data.lat, long: data.lon });
  });

  return dispatch(
    historyPush(
      `${Pages.UK_PUBLIC_NEAREST_GROUP_LANDING}?lat=${result.lat}&lng=${result.long}&query=${town}`,
    ),
  );
};
