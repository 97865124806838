import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import SocialMedia from 'common/src/app/data/enum/SocialMedia';
import instagramPostShape from 'common/src/app/util/proptypes/apiEntities/instagramPostShape';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import GridItemType from 'common/src/app/data/enum/GridItemType';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import IconName from 'common/src/app/data/enum/IconName';
import InstagramMediaTypes from 'common/src/app/data/enum/InstagramMediaTypes';
import Image from '../../atoms/Image';
import TextNew, { ElementTypes, FontSizes } from '../../atoms/TextNew';
import Icon from '../../atoms/Icon';
import Grid from '../../organisms/Grid';
import GridItem from '../../organisms/GridItem';
import Button from '../../atoms/Button';
import LocaleMessage from '../../atoms/LocaleMessage';
import './instagram-trending.scss';

/**
 * This component displays a number of post from the slimming world instagram account.
 * To see usages look at the public homepage
 */

const InstagramTrending = (
  { posts, saveInstagramPosts, openModal, deviceState },
  context,
  className,
) => {
  useEffect(() => {
    if (posts && posts.length > 0) return;

    saveInstagramPosts();
  }, [posts, saveInstagramPosts]);

  return (
    <section className={className}>
      <h2>
        <TextNew.Serif.SM element={ElementTypes.SPAN}>
          {SocialMedia.INSTAGRAM_HANDLE}
        </TextNew.Serif.SM>
        <TextNew.Script.LG element={ElementTypes.SPAN} localeId="instagramTrending.instagram" />
      </h2>
      <TextNew.Serif
        size={deviceState > DeviceState.LG ? FontSizes.SM : FontSizes.XS}
        cid="trending-description"
        localeId="instagramTrending.description"
      />
      <Grid>
        {posts?.length > 0 &&
          posts.map(post => (
            <GridItem key={post.id} type={GridItemType.FOURTHS}>
              <button
                className="aspect-ratio"
                onClick={() =>
                  post.mediaType === InstagramMediaTypes.VIDEO
                    ? window.open(post.permalink)
                    : openModal(post.mediaUrl, post.caption)
                }
                aria-label="View this post on Instagram"
              >
                {post.mediaType === InstagramMediaTypes.VIDEO && (
                  <div className="video-link">
                    <Icon name={IconName.INSTAGRAM_CAMCORDER} width={40} />
                  </div>
                )}
                <Image ratio={1} loading="lazy" src={post.mediaUrl} />
              </button>
            </GridItem>
          ))}
      </Grid>
      <Button target="_blank" href={PagesExternal.INSTAGRAM} isGhostDark>
        <LocaleMessage id="instagramTrending.cta" />
      </Button>
    </section>
  );
};

InstagramTrending.propTypes = {
  saveInstagramPosts: PropTypes.func,
  posts: instagramPostShape,
  deviceState: PropTypes.number,
  openModal: PropTypes.func,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'InstagramTrending')(InstagramTrending),
);
