import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/**
 * Injects the `queryRoutingResults` passed in props to the context so it can be picked up
 * by `QueryRoutingContainer`.
 */
class QueryRoutingProvider extends PureComponent {
  static childContextTypes = {
    queryRoutingResults: PropTypes.objectOf(PropTypes.any),
  };

  getChildContext() {
    return {
      queryRoutingResults: this.props.queryRoutingResults,
    };
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

QueryRoutingProvider.propTypes = {
  children: PropTypes.node,
  queryRoutingResults: PropTypes.objectOf(PropTypes.any),
};

export default QueryRoutingProvider;
