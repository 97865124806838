import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import BecomeAConsultant from 'bundle-loader?lazy&reactHot&name=BecomeAConsultant!./index';
import ConsultantLanding from 'bundle-loader?lazy&reactHot&name=ConsultantLanding!./components/templates/ConsultantLanding';
import OurConsultants from 'bundle-loader?lazy&reactHot&name=OurConsultants!./components/templates/OurConsultants';
import OurFranchisePackage from 'bundle-loader?lazy&reactHot&name=OurFranchisePackage!./components/templates/OurFranchisePackage';
import FranchiseFacts from 'bundle-loader?lazy&reactHot&name=FranchiseFacts!./components/templates/FranchiseFacts';
import ConsultantEnquiry from 'bundle-loader?lazy&reactHot&name=ConsultantEnquiry!./components/templates/ConsultantEnquiry';
import ConsultantEnquirySuccess from 'bundle-loader?lazy&reactHot&name=ConsultantEnquirySuccess!./components/templates/ConsultantEnquirySuccess';

const routes = (
  <Route {...getRouteComponentProp(BecomeAConsultant)}>
    <IndexRoute
      {...getRouteComponentProp(ConsultantLanding)}
      title="Become a consultant | {pageTitle}"
      description="Enjoy the freedom and flexibility of self-employment – find out how to become a Slimming World Consultant and help make members’ dreams come true."
    />

    <Route
      title="Become a consultant | {pageTitle}"
      description="Enjoy the freedom and flexibility of self-employment – find out how to become a Slimming World Consultant and help make members’ dreams come true."
      path={Pages.UK_PUBLIC_BECOME_A_CONSULTANT}
      {...getRouteComponentProp(ConsultantLanding)}
    />

    <Route
      title="Become a consultant | Meet our consultants | {pageTitle}"
      description="Meet our Slimming World Consultants, who are motivating and supporting members to achieve their weight loss dreams."
      path={Pages.UK_PUBLIC_BECOME_A_CONSULTANT_OUR_CONSULTANTS}
      {...getRouteComponentProp(OurConsultants)}
    />

    <Route
      title="Become a consultant | Our franchise package | {pageTitle}"
      description="We’re looking for caring and committed people to run their own Slimming World groups – find out more about our Slimming World franchise packages."
      path={Pages.UK_PUBLIC_BECOME_A_CONSULTANT_OUR_FRANCHISE_PACKAGE}
      {...getRouteComponentProp(OurFranchisePackage)}
    />

    <Route
      title="Become a consultant | Franchise facts | {pageTitle}"
      description="Discover how you can set up a self-employed franchise business with Slimming World and how much you could earn as a Slimming World Consultant."
      path={Pages.UK_PUBLIC_BECOME_A_CONSULTANT_FRANCHISE_FACTS}
      {...getRouteComponentProp(FranchiseFacts)}
    />

    <Route
      title="Become a consultant | Consultant enquiry | {pageTitle}"
      path={Pages.UK_PUBLIC_BECOME_A_CONSULTANT_ENQUIRY}
      {...getRouteComponentProp(ConsultantEnquiry)}
    />
    <Route
      title="Become a consultant | Consultant enquiry success | {pageTitle}"
      path={Pages.UK_PUBLIC_BECOME_A_CONSULTANT_ENQUIRY_SUCESS}
      {...getRouteComponentProp(ConsultantEnquirySuccess)}
    />
  </Route>
);

export default routes;
