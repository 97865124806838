import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { COMPONENT } from 'common/src/app/data/entityTypes';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { API_COMPONENT_APPLICATION_ERROR_BACKGROUND } from 'common/src/app/data/apiComponents';

import ApplicationError from './ApplicationError';

const mapStateToProps = state => ({
  publicHost: state.config.environmentConfig.web.public.host,
  background: state.entities[COMPONENT]?.[API_COMPONENT_APPLICATION_ERROR_BACKGROUND]?.image,
});

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(API_COMPONENT_APPLICATION_ERROR_BACKGROUND)),
});

const connected = connect(mapStateToProps);

export default compose(addInitAction, connected)(ApplicationError);
