import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { offerRoundels } from 'common/src/app/config/market/market.configdefinitions';
// Roundels
import SaveUpToOfferRoundel from './Components/atoms/SaveUpToOfferRoundel';
import HalfPriceOfferRoundel from './Components/atoms/HalfPriceOfferRoundel';
import FreeBookOfferRoundel from './Components/atoms/FreeBookOfferRoundel';
import VirtualGroupRoundel from './Components/atoms/VirtualGroupRoundel';
import FreeFestivePlannerOfferRoundel from './Components/atoms/FreeFestivePlannerOfferRoundel';
import FreeMembershipRoundel from './Components/atoms/FreeMembershipRoundel';
import FreeFreshStartMenuPlannerRoundel from './Components/atoms/FreeFreshStartMenuPlannerRoundel';
import './current-offer-roundel.scss';

export const OfferRoundelType = {
  SAVE_UP_TO: 'saveUpToOfferRoundel',
  HALF_PRICE_OFFER: 'halfPriceOfferRoundel',
  FREE_BOOK: 'freeBookOfferRoundel',
  FREE_FESTIVE_PLANNER: 'freeFestivePlannerOfferRoundel',
  FREE_MEMBERSHIP: 'freeMembershipRoundel',
  VIRTUAL_GROUPS: 'virtualGroupRoundel',
  FREE_FRESH_START_MENU_PLANNER: 'freeFreshStartMenuPlannerRoundel',
};

const Roundel = ({ offerType, ...props }) => {
  switch (offerType) {
    case OfferRoundelType.SAVE_UP_TO:
      return <SaveUpToOfferRoundel {...props} />;
    case OfferRoundelType.VIRTUAL_GROUPS:
      return <VirtualGroupRoundel {...props} />;
    case OfferRoundelType.HALF_PRICE_OFFER:
      return <HalfPriceOfferRoundel {...props} />;
    case OfferRoundelType.FREE_BOOK:
      return <FreeBookOfferRoundel {...props} />;
    case OfferRoundelType.FREE_FESTIVE_PLANNER:
      return <FreeFestivePlannerOfferRoundel {...props} />;
    case OfferRoundelType.FREE_MEMBERSHIP:
      return <FreeMembershipRoundel {...props} />;
    case OfferRoundelType.FREE_FRESH_START_MENU_PLANNER:
      return <FreeFreshStartMenuPlannerRoundel {...props} />;
    default:
      return <SaveUpToOfferRoundel {...props} />;
  }
};

Roundel.propTypes = {
  offerType: PropTypes.oneOf(Object.values(OfferRoundelType)),
};

/**
 * This component is used mainly on the public site to show the closest offer in
 *group.
 *
 * Tt stores all of the different offer types component and allows you to either
 * use the currently active default or a specific one if requied by passing in
 * offerType props
 */
const CurrentOfferRoundel = (
  { disableRoundel, offerType, backgroundColor, ...props },
  context,
  className,
  dataTestid,
) => {
  if (disableRoundel) {
    return null;
  }

  return (
    <div data-testid={dataTestid} className={classNames(className, backgroundColor)}>
      <Roundel offerType={offerType} {...props} />
    </div>
  );
};

CurrentOfferRoundel.defaultProps = {
  offerType: OfferRoundelType.SAVE_UP_TO,
  disableRoundel: !offerRoundels,
};

CurrentOfferRoundel.propTypes = {
  backgroundColor: PropTypes.string,
  disableRoundel: PropTypes.bool,
  offerType: PropTypes.oneOf(Object.values(OfferRoundelType)),
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'CurrentOfferRoundel',
)(CurrentOfferRoundel);
