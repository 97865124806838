import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import EmptySearchQuery from './EmptySearchQuery';

const mapStateToProps = state => ({
  navHidden: state.persistHideNav?.hideNav || false,
});

const connected = connect(mapStateToProps);

export default compose(connected)(EmptySearchQuery);
