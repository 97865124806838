const fractionOfFourthsEntities = {
  0: '',
  0.25: '¼',
  0.5: '½',
  0.75: '¾',
};

/**
 * @param {Number} value - The value which should be converted
 * @param {Number} fractionClamp - Clamps the restFraction value to given exponent,
 *                                 1 = whole number, 2 = halves, 4 = quaters etc
 */
const getFraction = (value = 0, fractionClamp = 2) => {
  const decimal = value - Math.floor(value); // only take the decimal from the value
  const roundedValue = parseFloat((Math.round(decimal * fractionClamp) / fractionClamp).toFixed(2)); // round the decimal using the clamp
  const roundedFraction = roundedValue - Math.floor(roundedValue);
  const fraction = fractionOfFourthsEntities[roundedFraction] || ''; // get the symbol
  return [Math.trunc(value) + (roundedValue - roundedFraction), fraction];
};

export default getFraction;
