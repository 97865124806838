import debugLib from 'debug';
import compositeInputFormatters from '../../enhanced-redux-form/compositeInputFormatters';
import { COMPOSITE_FORMSECTION_NAME_PREFIX } from '../../enhanced-redux-form/data/constants';

const debug = debugLib('SlimmingWorld:compositeInputActions');

/**
 * Uses the decompose function in the compositeInputFormatter configuration to
 * decompose a single composite value back to values of the inputs and update
 * them accordingly. If no decompose function is configured, will ignore
 * this call.
 *
 * @param name
 * @param formValues
 * @param formFormatter
 * @param props
 * @param prevProps
 * @param setFieldValue
 * @returns {function(...[*]=)}
 */
export const decomposeCompositeFormField = ({
  currentProps: { name, values: formValues, formatter: formFormatter },
  prevProps,
  setFieldValue,
}) => () => {
  const formValue = formValues;
  const compositeFormName = `${COMPOSITE_FORMSECTION_NAME_PREFIX}${name}`;
  const newValue =
    formValue && typeof formValue[name] !== 'undefined' ? formValue[name] : undefined;

  // Need to not ignore 0 inputs
  const composedValue =
    typeof prevProps?.values?.[name] !== 'undefined' ? prevProps.values[name] : undefined;

  if (composedValue === newValue) {
    debug(
      `decomposeCompositeInput() ignored change (.${name} => ${newValue}) because it equals the last value composed by composeCompositeInputs()`,
    );

    return;
  }

  const { formatter } = compositeInputFormatters[formFormatter.name] || {};

  if (!(formatter && formatter.decompose)) {
    debug('no decompose() config found on formatter. ignoring change');
    return;
  }

  debug(`decomposeCompositeInput() detected change (${name}: ${composedValue} => ${newValue}). `);

  const compositeFormValues = formValue ? formValue[compositeFormName] : undefined;
  const currentValues = typeof compositeFormValues !== 'undefined' ? compositeFormValues : {};

  let decomposedValues;

  try {
    decomposedValues = formatter.decompose(newValue, currentValues);
  } catch (e) {
    debug(
      `Error while running decompose() in formatter "${
        formFormatter.name
      }" for field "${name}" and value "${newValue}": ${e.message || e}`,
    );

    debug('skipping update.');

    return;
  }

  if (typeof decomposedValues !== 'object') {
    debug(
      `Invalid return of decompose() in formatter "${
        formFormatter.name
      }" for field "${name}" and value "${newValue}". Expected "object", got "${typeof decomposedValues}". skipping update.`,
    );

    return;
  }

  Object.keys(decomposedValues).forEach(fieldName => {
    const originalValue = compositeFormValues?.[compositeFormName]?.[fieldName];
    if (originalValue !== decomposedValues[fieldName]) {
      setFieldValue(`${compositeFormName}.${fieldName}`, decomposedValues[fieldName]);
    }
  });
};

/**
 * Uses the formatter passed to CompositeFormField components to format the inner values
 * of a CompositeInput to a composed value.
 *
 * @param name
 * @param formValues
 * @param formFormatter
 * @param props
 * @param prevProps
 * @param setFieldValue
 * @returns {function(...[*]=)}
 */
export const composeCompositeFormField = ({
  currentProps: { name, values: formValues, formatter: formFormatter },
  prevProps,
  setFieldValue,
}) => () => {
  if (!formValues) {
    return;
  }

  const { formatter } = compositeInputFormatters[formFormatter.name];
  const compositeFormName = `${COMPOSITE_FORMSECTION_NAME_PREFIX}${name}`;

  try {
    const childFieldValues = formValues[compositeFormName] || {};
    const value = formatter.compose(childFieldValues);

    if (prevProps.values[name] !== value) {
      const transformedValue = value ?? '';
      debug(
        `composeCompositeFormField() detected change (${name}: ${prevProps.values[name]} => ${transformedValue}). `,
      );
      setFieldValue(name, transformedValue);
    }
  } catch (e) {
    debug(
      `Error while running compose() in formatter "${
        formFormatter.name
      }" for field "${name}": ${e.message || e}`,
    );
  }
};
