/* global WP_DEFINE_MARKET */

export default async function getMessages() {
  let locale;

  if (WP_DEFINE_MARKET === 'uk') {
    locale = await import('../../locale/en-GB.i18n').then(module => module.default);
  }
  if (WP_DEFINE_MARKET === 'ie') {
    locale = await import('../../locale/en-IE.i18n').then(module => module.default);
  }

  if (!locale) {
    throw new ReferenceError(`Unknown locale id "${WP_DEFINE_MARKET}"`);
  }

  return locale;
}
