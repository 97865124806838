import PropTypes from 'prop-types';

import imageShape from 'common/src/app/util/proptypes/apiEntities/image';

export const ctaShape = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export const featuresShape = {
  listType: PropTypes.number.isRequired,
  title: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      listItem: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export const groupOnlineShape = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  introductoryOffer: PropTypes.shape({
    offer: PropTypes.string.isRequired,
  }).isRequired,
  upperCTA: PropTypes.shape({ ...ctaShape }).isRequired,
  lowerCTA: PropTypes.arrayOf(PropTypes.shape({ ...ctaShape })).isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      ...featuresShape,
    }),
  ).isRequired,
};

export const headerAssetsShape = {
  smallMobileHeaderImage: PropTypes.shape({
    ...imageShape,
  }),
  largeMobileHeaderImage: PropTypes.shape({
    ...imageShape,
  }),
  tabletHeaderImage: PropTypes.shape({
    ...imageShape,
  }),
  desktopHeaderImage: PropTypes.shape({
    ...imageShape,
  }).isRequired,
  ultraWideHeaderImage: PropTypes.shape({
    ...imageShape,
  }),
};
