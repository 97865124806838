/**
 * features map
 */
const FeaturesType = {
  0: 'FoodOptimizing',
  1: 'FoodOptimizingSP',
  2: 'GettingStarted',
  3: 'TravelAndVacation',
  4: 'FoodIdeas',
  5: 'Exercise',
  6: 'TrickyTimes',
  7: 'Health',
  8: 'EatingOut',
  9: 'TargetMembers',
  10: 'Seasonal',
};

export default FeaturesType;
