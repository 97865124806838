import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import VideoPropTypes from 'common/src/app/util/proptypes/apiEntities/video';
import BlockContent from 'components/molecules/BlockContent';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import VideoBackground from 'components/organisms/VideoBackground';
import NewsletterSignUp from '../../../../../../components/molecules/NewsletterSignUp';
import IcelandRecipeInspiration from '../../organisms/IcelandRecipeInspiration';
import IcelandOurPagesHeader from '../../molecules/IcelandOurPagesHeader';

import './iceland-our-pages-content.scss';

const IcelandOurPagesContent = (
  {
    pageData: { video, image, title, description, recipeInspirations, nestedContent },
    hasHeroBar,
    headerRatio,
    hasHeader,
    children,
  },
  context,
  className,
) => (
  <div className={className}>
    {video?._type && video?.videoId ? ( // eslint-disable-line no-underscore-dangle
      <VideoBackground video={video} videoType={video._type} /> // eslint-disable-line no-underscore-dangle
    ) : (
      image && hasHeroBar && <HeroBarPublic headerRatio={headerRatio} image={image} />
    )}

    {hasHeader && (title || description) && (
      <IcelandOurPagesHeader title={title} description={description} />
    )}

    {children}

    {nestedContent && <BlockContent data={nestedContent} />}

    {recipeInspirations && recipeInspirations?.length > 0 && (
      <IcelandRecipeInspiration recipeInspirations={recipeInspirations} />
    )}

    <NewsletterSignUp />
  </div>
);

IcelandOurPagesContent.defaultProps = {
  hasHeader: true,
  hasHeroBar: true,
};

IcelandOurPagesContent.propTypes = {
  ...VideoPropTypes,
  pageData: PropTypes.object,
  children: PropTypes.node,
  hasHeroBar: PropTypes.bool,
  hasHeader: PropTypes.bool,
  headerRatio: PropTypes.number,
};

export default withFunctionalClassName(
  ComponentType.TEMPLATE,
  'IcelandOurPagesContent',
)(IcelandOurPagesContent);
