/* global 'atom','RichTextWithIconLinkAndTitleBlock' */
import React from 'react';
import PropTypes from 'prop-types';
import IconWithRichText from 'components/molecules/IconWithRichText';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import RichTextBlock from '../RichTextBlock';
import './rich-text-with-icon-link-and-title-block.scss';

/**
 * RichText with icon, link and title block
 */
const RichTextWithIconLinkAndTitleBlock = (
  { content, title, nestedContent, text },
  context,
  className,
) => (
  <div className={className}>
    {title ? <TextNew element={ElementTypes.H1}>{title}</TextNew> : null}
    {text ? <RichTextBlock text={text} /> : null}
    <ul>
      {content &&
        content.map((item, idx) => (
          <li key={item.link + idx} className="list-item">
            <IconWithRichText item={item} />
          </li>
        ))}
      {nestedContent &&
        nestedContent.map(item => (
          <li key={item.icon} className="list-item">
            <IconWithRichText item={item} />
          </li>
        ))}
    </ul>
  </div>
);

RichTextWithIconLinkAndTitleBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.node,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
  nestedContent: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
};

export default withFunctionalClassName('atom','RichTextWithIconLinkAndTitleBlock')(RichTextWithIconLinkAndTitleBlock);
