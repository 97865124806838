import PropTypes from 'prop-types';
import { FontSizes, FontFamily } from '../../data/enum/TextTypes';
import Colors, { AccentColors, INHERIT } from '../../data/enum/Colors';
import DirectionType from '../../data/enum/DirectionType';
import ElementTypes from '../../data/enum/ElementTypes';

export const titleStyleShape = {
  color: PropTypes.oneOf([...Colors.propTypes, ...AccentColors.propTypes, INHERIT]),
  fontSize: PropTypes.oneOf(FontSizes.propTypes),
  fontFamily: PropTypes.oneOf(FontFamily.propTypes),
  element: PropTypes.oneOf(ElementTypes.propTypes),
};

export const titlePositionShape = [DirectionType.TOP, DirectionType.BOTTOM];

export const buttonShape = {
  styles: PropTypes.object,
  to: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};
