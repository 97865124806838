/* global 'atom','RichTextWithVideoBlock' */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import trackingShape from 'common/src/app/util/proptypes/apiEntities/tracking';
import TextNew, { Colors, ElementTypes } from '../../../../../atoms/TextNew';
import RichTextBlock from '../RichTextBlock';
import VideoBlock from '../VideoBlock';
import './rich-text-with-video-block.scss';

/**
 * Rich text with video component, used on the Essentials page
 */
const RichTextWithVideoBlock = (
  { title, subtitle, text, video, videoThumbnail, videoNote, isInverted, trackingData },
  context,
  className,
  dataTestid,
) => (
  <div data-testid={dataTestid} className={className}>
    <TextNew.Script.LG cid="title" color={Colors.BRAND} element={ElementTypes.H2}>
      {title}
    </TextNew.Script.LG>
    <TextNew.Strong>{subtitle}</TextNew.Strong>
    <div className={classNames('content-wrapper', { 'has-columns': text })}>
      {text && !isInverted ? <RichTextBlock text={text} /> : null}
      <div className="video-container">
        <VideoBlock video={video} videoThumbnail={videoThumbnail} trackingData={trackingData} />
        {videoNote ? <TextNew.Formal>{videoNote}</TextNew.Formal> : null}
      </div>
      {text && isInverted ? <RichTextBlock text={text} /> : null}
    </div>
  </div>
);

RichTextWithVideoBlock.defaultProps = {
  isInverted: false,
};

RichTextWithVideoBlock.propTypes = {
  /**
   * The big title
   */
  title: PropTypes.string.isRequired,
  /**
   * The subtitle underneath the big title
   */
  subtitle: PropTypes.string,
  /**
   * The main copy
   */
  text: PropTypes.string,
  /**
   * Video info
   */
  video: PropTypes.object.isRequired,
  /**
   * Video image
   */
  videoThumbnail: PropTypes.object.isRequired,
  /**
   * Video caption
   */
  videoNote: PropTypes.string,
  /**
   * Set to true to display the video before the text.
   * Default (false) will display the video after text.
   */
  isInverted: PropTypes.bool,
  /**
   * Tracking for video
   */
  trackingData: PropTypes.shape(trackingShape),
};

export default withFunctionalClassName('atom','RichTextWithVideoBlock')(RichTextWithVideoBlock);
