/* global 'organism','Presenters' */
import React from 'react';
import PropTypes from 'prop-types';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import './presenters.scss';

const Presenters = ({ presenters }, {}, className) => (
  <div className={className} data-testid="podcast-presenters">
    <Row className="presenters-row" alignstart spacebetween>
      {Object.values(presenters).map(({ image, link, linkText }, index) => (
        <Row key={`${index}-presenter`} column justifycenter>
          {image?.src && <Image src={image?.src} alt={image?.alt} ratio={1} />}
          {link && (
            <Button href={link} target="_blank">
              <TextNew.Sans.SM element={ElementTypes.SPAN}>{linkText}</TextNew.Sans.SM>
            </Button>
          )}
        </Row>
      ))}
    </Row>
  </div>
);

Presenters.propTypes = {
  presenters: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object.isRequired,
      link: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default withFunctionalClassName('organism','Presenters')(Presenters);
