/* global 'molecule','ReadMoreAccordion' */
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { FontWeight, ElementTypes } from 'components/atoms/TextNew';

import './read-more-accordion.scss';

const maxHeightDefault = 600;

class ReadMoreAccordion extends Component {
  state = { open: false, maxHeight: 0 };

  toggleOpen = () => {
    this.setState(() => ({
      open: !this.state.open,
    }));
  };

  componentDidMount() {
    const maxHeight =
      // On first load the height can be less then the default causing cut off
      this.textElement.clientHeight < maxHeightDefault
        ? maxHeightDefault
        : this.textElement.clientHeight;

    this.setState({ maxHeight });
  }

  render() {
    const { text, closedHeight, onLightBackground } = this.props;
    const { open, maxHeight } = this.state;

    // Convert any returns in the input to be linebreaks
    const textWithLineBreaks = text && text.replace(new RegExp('\\r', 'g'), '\n');

    return (
      <div {...componentClassNameProp(ComponentType.MOLECULE, this)}>
        <div
          className={classNames('text', { open }, { 'light-background': onLightBackground })}
          style={{
            height: `${open ? 'auto' : ''}`,
            maxHeight: `${open ? `${maxHeight}px` : `${closedHeight}px`}`,
          }}
        >
          <TextNew.Sans.SM textRef={el => (this.textElement = el)} weight={FontWeight.LIGHT}>
            {textWithLineBreaks}
          </TextNew.Sans.SM>
        </div>
        <button className="toggle" onClick={this.toggleOpen}>
          <TextNew.Sans.SM
            element={ElementTypes.SPAN}
            localeId={`general.cta.${open ? 'close' : 'readMore'}`}
          />
        </button>
      </div>
    );
  }
}

ReadMoreAccordion.propTypes = {
  text: PropTypes.string,
  closedHeight: PropTypes.number,
  onLightBackground: PropTypes.bool,
};

export default ReadMoreAccordion;
