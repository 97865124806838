/* global 'atom','OpeningTimes' */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import {
  Department,
  DepartmentDetails,
  OpeningDays,
} from 'common/src/app/data/enum/DepartmentPhoneNumbersInfo';
import TextNew, { FontWeight } from '../TextNew';

import './opening-times.scss';

const OpeningTimes = ({ department }, context, className, dataTestid) => {
  const formatTime = (time, openingDay) => {
    const departmentTimes = DepartmentDetails[department].openingTimes[openingDay][time];
    const [hour, mintute = 0] = departmentTimes.split(':') || '';
    return moment([0, 0, 1, hour, mintute, 0, 0]).format(`h${mintute ? ':m' : ''}a`);
  };

  return (
    <div data-testid={dataTestid} className={className}>
      {Object.keys(DepartmentDetails?.[department]?.openingTimes).map(openingDay => (
        <div key={openingDay} className="opening-hours">
          <TextNew.Sans.SM
            dataTestid={`${dataTestid}-days`}
            localeId={`openingTimes.openingDays.${openingDay}`}
            weight={FontWeight.LIGHT}
          />
          <TextNew.Sans.SM dataTestid={`${dataTestid}-times`} weight={FontWeight.LIGHT} cid="hours">
            {`${formatTime('opening', openingDay)} – ${formatTime('closing', openingDay)}`}
          </TextNew.Sans.SM>
        </div>
      ))}
    </div>
  );
};

const OpeningTimesShape = {
  openingDays: PropTypes.string,
  department: PropTypes.oneOf(Object.values(Department)),
};

OpeningTimes.propTypes = {
  ...OpeningTimesShape,
};

export { Department, OpeningDays, OpeningTimesShape, DepartmentDetails };

export default withFunctionalClassName(ComponentType.ATOM, 'OpeningTimes')(OpeningTimes);
