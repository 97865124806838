import DeepLink from 'components/organisms/DeepLink';
import TextBlock from '../components/atoms/TextBlock';
import TextWithTitleBlock from '../components/atoms/TextWithTitleBlock';
import ImageBlock from '../components/atoms/ImageBlock';
import RichTextBlock from '../components/atoms/RichTextBlock';
import VideoBlock from '../components/atoms/VideoBlock';
import VideoWithThumbnailBlock from '../components/atoms/VideoWithThumbnailBlock';
import RichTextWithImageBlock from '../components/atoms/RichTextWithImageBlock';
import RichTextWithVideoBlock from '../components/atoms/RichTextWithVideoBlock';
import QuotationBlock from '../components/atoms/QuotationBlock';
import InstagramBlock from '../components/atoms/InstagramBlock';
import FeaturesListBlock from '../components/atoms/FeaturesListBlock';
import GettingStartedWrapperBlock from '../components/atoms/SectionBlock';
import RichTextWithIconLinkAndTitleBlock from '../components/atoms/RichTextWithIconLinkAndTitleBlock';
import IconRichTextAndLinkListBlock from '../components/atoms/IconRichTextAndLinkListBlock';
import ImageCarouselWithTextBlock from '../components/atoms/ImageCarouselWithTextBlock';
import ImageCarouselBlock from '../components/atoms/ImageCarouselBlock';
import ButtonBlock from '../components/atoms/ButtonBlock';
import RichTextsListBlock from '../components/atoms/RichTextsListBlock';
import RichTextsAndRichTextsWithIconsListBlock from '../components/atoms/RichTextsAndRichTextsWithIconsListBlock';
import RiddleBlock from '../components/atoms/RiddleBlock';
import TableBlock from '../components/atoms/TableBlock';
import ListBlock from '../components/atoms/ListBlock';
import ImageWithCaptionCarouselBlock from '../components/atoms/ImageWithCaptionCarouselBlock';
import ImageBlockCarouselBlock from '../components/atoms/ImageBlockCarouselBlock';
import TextBlockTriplet from '../components/atoms/TextBlockTriplet';

export const types = {
  BUTTON_CONTAINER_BLOCK: 'ButtonContainerBlock',
  COLOURED_BUTTON_TILE: 'ColouredButtonTile',
  DEEP_LINK_BLOCK: 'DeepLinkBlock',
  DEEP_LINK_WITH_ICON_AND_MEDIA_BUTTON_BLOCK: 'DeepLinkWithIconAndMediaButtonBlock',
  FEATURES_LIST_BLOCK: 'FeaturesListBlock',
  GETTING_STARTED_WRAPPER_BLOCK: 'GettingStartedWrapperBlock',
  GROUP_MEETUP_WITH_TEXT_IMAGE_AND_BACKGROUNDS_BLOCK: 'GroupMeetupWithTextImageAndBackgroundsBlock',
  ICON_RICH_TEXT_AND_LINK_LIST_BLOCK: 'IconRichTextAndLinkListBlock',
  IMAGE_BLOCK: 'ImageBlock',
  IMAGE_CAROUSEL_BLOCK: 'ImageCarouselBlock',
  IMAGE_CAROUSEL_WITH_TEXT_BLOCK: 'ImageCarouselWithTextBlock',
  IMAGE_WITH_CAPTION_AND_BACKGROUND_BLOCK: 'ImageWithCaptionAndBackgroundBlock',
  INSTAGRAM_BLOCK: 'InstagramBlock',
  LINK_BUTTON_BLOCK: 'LinkButtonBlock',
  MEDIA_BUTTON_BLOCK: 'MediaButtonBlock',
  QUOTATION_BLOCK: 'QuotationBlock',
  QUOTATION_WITH_AUTHOR_PHOTO_BLOCK: 'QuotationWithAuthorPhotoBlock',
  RICH_TEXT_BLOCK: 'RichTextBlock',
  RICH_TEXT_WITH_ALTERNATING_LABELLED_ICONS_LIST_BLOCK:
    'RichTextWithAlternatingLabelledIconsListBlock',
  RICH_TEXT_WITH_BACKGROUND_BLOCK: 'RichTextWithBackgroundBlock',
  RICH_TEXT_WITH_ICON_LINK_AND_TITLE_BLOCK: 'RichTextWithIconLinkAndTitleBlock',
  RICH_TEXT_WITH_IMAGE_AND_BACKGROUND_BLOCK: 'RichTextWithImageAndBackgroundBlock',
  RICH_TEXT_WITH_IMAGE_AND_BACKGROUND_IMAGE_BLOCK: 'RichTextWithImageAndBackgroundImageBlock',
  RICH_TEXT_WITH_IMAGE_BLOCK: 'RichTextWithImageBlock',
  RICH_TEXT_WITH_VIDEO_BLOCK: 'RichTextWithVideoBlock',
  RICH_TEXTS_AND_RICH_TEXTS_WITH_ICONS_LIST_BLOCK: 'RichTextsAndRichTextsWithIconsListBlock',
  RICH_TEXTS_LIST_BLOCK: 'RichTextsListBlock',
  RICH_TEXTS_WITH_ICONS_AND_TITLES_LIST_BLOCK: 'RichTextWithIconLinkAndTitleBlock',
  RICH_TEXTS_WITH_ICONS_LINKS_TITLES_AND_MEDIA_BUTTONS_LIST_BLOCK:
    'RichTextsWithIconsLinksTitlesAndMediaButtonsListBlock',
  RICH_TEXTS_WITH_ICONS_LIST_BLOCK: 'RichTextsWithIconsListBlock',
  RIDDLE_BLOCK: 'RiddleBlock',
  SYNS_LIMIT_BLOCK: 'SynsLimitBlock',
  TEXT_BLOCK: 'TextBlock',
  TEXT_WITH_TITLE_BLOCK: 'TextWithTitleBlock',
  VIDEO_BLOCK: 'VideoBlock',
  VIDEO_WITH_THUMBNAIL_BLOCK: 'VideoWithThumbnailBlock',
  TABLE_BLOCK: 'TableBlock',
  LIST_BLOCK: 'ListBlock',
  IMAGE_WITH_CAPTION_CAROUSEL_BLOCK: 'ImageWithCaptionCarouselBlock',
  IMAGE_BLOCK_CAROUSEL_BLOCK: 'ImageBlockCarouselBlock',
  TEXT_BLOCK_TRIPLET: 'TextBlockTriplet',
  ROLE_BASED_CONTENT: 'RoleBasedContent',
  FAQ_BLOCK: 'FaqBlock',
  PREFILLED_SUCCESS_STORY_TILES_BLOCK: 'PrefilledSuccessStoryTilesBlock',
};

/**
 * Exclude components from having wrappers
 */
export const excludeBlockFromWrapper = [
  types.GETTING_STARTED_WRAPPER_BLOCK,
  types.IMAGE_CAROUSEL_WITH_TEXT_BLOCK,
  types.FEATURES_LIST_BLOCK,
  types.DEEP_LINK_WITH_ICON_AND_MEDIA_BUTTON_BLOCK,
  types.RICH_TEXTS_WITH_ICONS_LINKS_TITLES_AND_MEDIA_BUTTONS_LIST_BLOCK,
];

/**
 * This maps block content _types to a component
 */
const BlockContentComponentMap = {
  [types.COLOURED_BUTTON_TILE]: ButtonBlock,
  [types.DEEP_LINK_WITH_ICON_AND_MEDIA_BUTTON_BLOCK]: DeepLink,
  [types.FEATURES_LIST_BLOCK]: FeaturesListBlock,
  [types.GETTING_STARTED_WRAPPER_BLOCK]: GettingStartedWrapperBlock,
  [types.IMAGE_BLOCK]: ImageBlock,
  [types.IMAGE_CAROUSEL_BLOCK]: ImageCarouselBlock,
  [types.IMAGE_CAROUSEL_WITH_TEXT_BLOCK]: ImageCarouselWithTextBlock,
  [types.INSTAGRAM_BLOCK]: InstagramBlock,
  [types.QUOTATION_BLOCK]: QuotationBlock,
  [types.QUOTATION_WITH_AUTHOR_PHOTO_BLOCK]: QuotationBlock,
  [types.RICH_TEXT_BLOCK]: RichTextBlock,
  [types.RICH_TEXT_WITH_BACKGROUND_BLOCK]: RichTextBlock,
  [types.RICH_TEXT_WITH_ICON_LINK_AND_TITLE_BLOCK]: RichTextWithIconLinkAndTitleBlock,
  [types.RICH_TEXT_WITH_IMAGE_AND_BACKGROUND_BLOCK]: RichTextWithImageBlock,
  [types.RICH_TEXT_WITH_IMAGE_AND_BACKGROUND_IMAGE_BLOCK]: RichTextWithImageBlock,
  [types.RICH_TEXT_WITH_IMAGE_BLOCK]: RichTextWithImageBlock,
  [types.RICH_TEXT_WITH_VIDEO_BLOCK]: RichTextWithVideoBlock,
  [types.RICH_TEXTS_AND_RICH_TEXTS_WITH_ICONS_LIST_BLOCK]: RichTextsAndRichTextsWithIconsListBlock,
  [types.RICH_TEXTS_LIST_BLOCK]: RichTextsListBlock,
  [types.RICH_TEXTS_WITH_ICONS_LINKS_TITLES_AND_MEDIA_BUTTONS_LIST_BLOCK]: IconRichTextAndLinkListBlock,
  [types.RICH_TEXTS_WITH_ICONS_LIST_BLOCK]: RichTextWithIconLinkAndTitleBlock,
  [types.RIDDLE_BLOCK]: RiddleBlock,
  [types.TEXT_BLOCK]: TextBlock,
  [types.TEXT_WITH_TITLE_BLOCK]: TextWithTitleBlock,
  [types.VIDEO_BLOCK]: VideoBlock,
  [types.VIDEO_WITH_THUMBNAIL_BLOCK]: VideoWithThumbnailBlock,
  [types.TABLE_BLOCK]: TableBlock,
  [types.LIST_BLOCK]: ListBlock,
  [types.IMAGE_WITH_CAPTION_CAROUSEL_BLOCK]: ImageWithCaptionCarouselBlock,
  [types.IMAGE_BLOCK_CAROUSEL_BLOCK]: ImageBlockCarouselBlock,
  [types.TEXT_BLOCK_TRIPLET]: TextBlockTriplet,
};

export default BlockContentComponentMap;
