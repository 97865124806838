import React from 'react';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import TextNew, { FontWeight } from '../../atoms/TextNew';

import './contact-us-ipso.scss';

const ContactUsIpso = ({}, context, className, dataTestid) => (
  <div className={className} data-testid={dataTestid}>
    <Icon name={IconName.IPSO} width={196} />
    <TextNew.Sans.SM weight={FontWeight.LIGHT} localeId="contactUsIpso.body.info" />
  </div>
);

export default withFunctionalClassName(ComponentType.MOLECULE, 'ContactUsIpso')(ContactUsIpso);
