import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Loader from 'components/atoms/Loader';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import ElementTypes from 'common/src/app/data/enum/ElementTypes';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import StructuredDataGenerator, {
  types as structuredContentTypes,
} from 'components/atoms/StructuredDataGenerator';
import FaqBlock from 'components/molecules/FaqBlock';
import createScriptTitle from '../../../utils/createScriptTitle';
import RelatedSuccessStories from '../SuccessStories/components/molecules/RelatedSuccessStories';
import './comparison-page.scss';

const ComparisonPage = (
  { heroImage, loading, pageData, structuredContent },
  context,
  className,
) => {
  const questionsAndAnswers = structuredContent?.map(question => {
    const questions = question.question;
    const answers = question.answers.reduce((acc, curr) => acc + curr.title + curr.answer, '');
    return {
      questions,
      answers,
    };
  });

  const data = {
    questions: questionsAndAnswers,
  };

  return (
    <div className={className}>
      <header className="hero">
        <BackgroundImageWrapper image={heroImage} />
      </header>
      <div className="content-wrapper">
        {loading && <Loader />}

        {!loading && pageData?.title && pageData?.description && (
          <RichTextBlock
            title={createScriptTitle(pageData.title)}
            text={pageData.description}
            cid="comparison-page-intro"
            titleTag={ElementTypes.H1}
          />
        )}

        {!loading &&
          // eslint-disable-next-line react/prop-types
          pageData?.nestedContent.map(({ _type, ...content }) => {
            switch (_type) {
              case types.FAQ_BLOCK:
                return <FaqBlock {...content} key="faq-block" />;

              case types.PREFILLED_SUCCESS_STORY_TILES_BLOCK:
                return <RelatedSuccessStories {...content} key="success-story-block" />;

              default:
                // nothing or component not compatible
                return null;
            }
          })}
      </div>

      {data?.questions?.length > 0 && (
        <StructuredDataGenerator data={data} type={structuredContentTypes.FAQ} />
      )}
    </div>
  );
};

ComparisonPage.propTypes = {
  heroImage: PropTypes.shape(imageShape),
  loading: PropTypes.bool.isRequired,
  pageData: PropTypes.object,
  structuredContent: PropTypes.array,
};

export default withFunctionalClassName(ComponentType.PAGE, 'ComparisonPage')(ComparisonPage);
