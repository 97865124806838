/* global 'organism','ListenNow' */
import React from 'react';
import PropTypes from 'prop-types';
import TextNew, { ElementTypes, FontSizes } from 'components/atoms/TextNew';
import Image from 'components/atoms/Image';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import './listen-now.scss';

const ListenNow = ({ listenNow, listenBelowCopy, deviceState }, {}, className) => (
  <div className={className} data-testid="podcast-listen-now">
    {listenNow?.title && (
      <TextNew.Serif
        cid="listen-now-title"
        element={ElementTypes.H2}
        size={deviceState >= DeviceState.MD ? FontSizes.LG : FontSizes.MD}
      >
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: listenNow.title }}
        />
      </TextNew.Serif>
    )}

    {listenNow?.text && (
      <TextNew.Sans.SM cid="intro" element={ElementTypes.SPAN}>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: listenNow.text }}
        />
      </TextNew.Sans.SM>
    )}

    {listenNow?.image?.src && (
      <Image
        cid="listen-now-image"
        src={listenNow.image.src}
        alt={listenNow.image.alt}
        ratio={800 / 250}
      />
    )}

    <div className="listenBelow">
      {listenBelowCopy && (
        <TextNew.Sans.SM cid="intro" element={ElementTypes.SPAN}>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: listenBelowCopy }}
          />
        </TextNew.Sans.SM>
      )}
    </div>
  </div>
);

ListenNow.propTypes = {
  listenNow: PropTypes.shape({
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  deviceState: PropTypes.number,
  listenBelowCopy: PropTypes.string.isRequired,
};

export default withDeviceState()(withFunctionalClassName('organism','ListenNow')(ListenNow));
