import handleActions from 'redux-actions/lib/handleActions';
import {
  REGISTER_DEEP_LINK,
  REGISTER_DEEP_LINK_HIDDEN_POSITION,
} from '../../../actions/components/deepLinkActions';

const initialState = {};

export default handleActions(
  {
    [REGISTER_DEEP_LINK]: (state, { payload: { currentRoute, ...rest } }) => ({
      ...state,
      [currentRoute]: {
        ...state[currentRoute],
        ...rest,
      },
    }),
    [REGISTER_DEEP_LINK_HIDDEN_POSITION]: (state, { payload }) => ({
      ...state,
      hideUntil: payload,
    }),
  },
  initialState,
);
