import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import {
  composeCompositeFormField,
  decomposeCompositeFormField,
} from '../../actions/compositeFormFieldActions';
import CompositeFormField, { CompositeFormFieldContext } from './CompositeFormField';

const mapStateToProps = (_, { values, name }) => ({
  value: values?.[name],
});

const mapDispatchToProps = {
  composeCompositeFormField,
  decomposeCompositeFormField,
};

const CompositeFormFieldDefault = compose(connect(mapStateToProps, mapDispatchToProps))(
  CompositeFormField,
);

export { CompositeFormFieldDefault as default, CompositeFormFieldContext };
