import { withFormik } from 'formik';
import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { push as historyPush } from 'react-router-redux';
import { withInitAction, prepareComponent } from '@mediamonks/react-redux-component-init';
import Pages from 'common/src/app/data/enum/Pages';
import FormNames from 'common/src/app/data/enum/FormNames';
import {
  getTowns,
  countyBasedGroupSearch,
  FETCH_GROUP_SEARCH_RESULTS,
} from 'common/src/app/actions/resources/groupSearchActions';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import tempGroupSelector, { townsSelector } from '../../../reducers/getCyprusGroupsByTownSelector';
import EmptySearchResults from '../NearestGroup/components/templates/NearestGroupLanding/components/organisms/EmptySearchResults';

import Cyprus from './Cyprus';

const mapStateToProps = state => {
  const towns = townsSelector(state);
  const groups = tempGroupSelector(state);
  const isLoadingSearchSelector = makeIsLoadingSelector(FETCH_GROUP_SEARCH_RESULTS);

  const townOptions =
    towns?.length > 0
      ? towns.map(town => ({
          title: town,
          value: town,
        }))
      : [];

  return {
    townOptions,
    groups,
    isLoading: isLoadingSearchSelector(state),
    navHidden: state.persistHideNav?.hideNav || false,
  };
};

const mapDispatchToProps = dispatch => ({
  searchGroups: query =>
    dispatch(historyPush(`${Pages.UK_PUBLIC_CYPRUS_GROUP_SEARCH}?group=${query}`)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const enhanced = withFormik({
  displayName: FormNames.PUBLIC_CYPRUS_GROUP_SEARCH,
  handleSubmit: ({ query }, { props }) => props.searchGroups(query),
});

const addInitAction = withInitAction(['location.query'], {
  prepared: ({ location: { query } = {} }, dispatch, getState) => {
    const { days, times, venueAccessibility } = query;
    const state = getState();
    const towns = townsSelector(state);

    const promises = [dispatch(prepareComponent(EmptySearchResults))];

    if (towns === undefined) {
      promises.push(dispatch(getTowns('CY')));
    }

    if (query?.group !== undefined) {
      promises.push(
        dispatch(
          countyBasedGroupSearch(query.group, 'CY', days, times, venueAccessibility, 'town'),
        ),
      );
    }

    return Promise.all(promises);
  },
});

export default compose(addInitAction, connected, enhanced)(Cyprus);
