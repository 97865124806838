import React from 'react';
import PropTypes from 'prop-types';
import compose from 'redux/lib/compose';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';

import ContactUsHeadOffice from '../../atoms/ContactUsHeadOffice';
import ContactUsOnlineMemberSupport from '../../atoms/ContactUsOnlineMemberSupport';
import ContactUsGroupEnquiries from '../../molecules/ContactUsGroupEnquiries';
import ContactUsIpso from '../../molecules/ContactUsIpso';

import * as ContactUsForm from '../../organisms/ContactUsForm';

import './contact-us-get-in-touch.scss';

const ContactUsGetInTouch = ({ contactType }, context, className, dataTestid) => (
  <div className={className} data-testid={dataTestid}>
    <ContactUsGroupEnquiries />
    {marketName === 'UK' ? <ContactUsOnlineMemberSupport /> : null}
    <ContactUsHeadOffice />
    <ContactUsForm.Public contactType={contactType} />
    <ContactUsIpso />
  </div>
);

ContactUsGetInTouch.propTypes = {
  contactType: PropTypes.string,
};

export default compose(withFunctionalClassName(ComponentType.TEMPLATE, 'ContactUsGetInTouch'))(
  ContactUsGetInTouch,
);
