import { compose } from 'redux';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { GET_COMPONENT } from 'common/src/app/actions/resources/componentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import {
  getStaticContent,
  GET_STATIC_CONTENT,
} from 'common/src/app/actions/resources/staticContentActions';
import { API_STATIC_PODCAST_LANDING_PAGE } from 'common/src/app/data/apiStatics';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';

import PodcastLanding from './PodcastLanding';

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT, GET_COMPONENT);

const mapStateToProps = state => ({
  isLoading: pageLoading(state),
  page: staticPageSelector(state, API_STATIC_PODCAST_LANDING_PAGE),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    dispatch(getStaticContent(API_STATIC_PODCAST_LANDING_PAGE, true, null, { count: 5 })),
});

export default compose(addInitAction, connected)(PodcastLanding);
