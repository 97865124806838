import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import BackgroundImageWrapper from './BackgroundImageWrapper';

const connector = connect(
  state => ({
    hasWebpSupport: state.featureSupport?.hasWebpSupport,
  }),
  null,
);

export default compose(connector)(BackgroundImageWrapper);
