/**
 * Seasonal map
 */
const Seasonal = {
  0: 'Christmas',
  1: 'NewYear',
  2: 'Thanksgiving',
  3: 'Halloween',
  4: 'StPatricksDay',
  5: 'Easter',
  6: 'ValentinesDay',
  7: 'Summer',
  8: 'Fall',
  9: 'Winter',
  10: 'Spring',
};

export default Seasonal;
