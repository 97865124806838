import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';
import Button from '../../atoms/Button';
import TextNew, { Colors, ElementTypes } from '../../atoms/TextNew';
import SocialShare from '../SocialShare';

import './public-recipe-meta-bar.scss';

const PublicRecipeMetaBar = ({ image }, context, className) => (
  <div className={className}>
    <SocialShare image={{ image }} pageType="socialShare.typesOfPost.recipe" />
    <div className="join-block">
      <TextNew.Script.XS localeId="publicRecipeMetaBar.join.content" />
      <Button to={Pages.UK_PUBLIC_COMPARISON} isShadePink type="button">
        <TextNew.Sans.SM
          element={ElementTypes.SPAN}
          color={Colors.PRIMARY_LIGHT}
          localeId="publicRecipeMetaBar.join.button"
        />
      </Button>
    </div>
  </div>
);

PublicRecipeMetaBar.propTypes = {
  image: PropTypes.string.isRequired,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'PublicRecipeMetaBar',
)(PublicRecipeMetaBar);
