import handleActions from 'redux-actions/lib/handleActions';
import { GRID_COLUMNS } from '../../../actions/components/gridViewActions';

const initialState = { layout: 4 };

export default handleActions(
  {
    [GRID_COLUMNS]: (state, { payload }) => ({
      ...state,
      layout: payload,
    }),
  },
  initialState,
);
