import defaultViewReducers from 'common/src/app/reducers/view/defaultViewReducers';
import combineReducersNamed from 'common/src/app/util/reducers/combineReducersNamed';
import nearestGroup from 'common/src/app/reducers/nearestGroupSearchReducer';
import pages from './pagesReducer';
import publicBmi from './bmiReducer';

export default combineReducersNamed({
  ...defaultViewReducers,
  pages,
  publicBmi,
  nearestGroup,
});
