import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import {
  getGroupDetail,
  GET_GROUP_DETAIL_CALL,
} from 'common/src/app/actions/resources/groupSearchActions';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';
import {
  API_STATIC_PUBLIC_NEAREST_GROUP_BULLETIN_V2,
  API_STATIC_PUBLIC_NEAREST_GROUP_DETAIL_V2,
} from 'common/src/app/data/apiStatics';
import { getConsultantDetail } from 'common/src/app/actions/resources/groupSearchConsultantActions';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { makeEntitySelector } from 'common/src/app/reducers/view/entityViewReducer';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';

import getMarketRichTextContent from '../../../Utils/getMarketRichTextContent';

import NearestGroupDetail from './NearestGroupDetail';

export const nearestGroupSelector = state => state.view.nearestGroup.groupDetail;

const mapStateToProps = state => {
  const entitySelector = makeEntitySelector();
  const isLoadingSearchSelector = makeIsLoadingSelector(GET_GROUP_DETAIL_CALL);
  const group = nearestGroupSelector(state);
  const consultant = entitySelector(state, state.view.pages.groupDetail?.consultant);
  const pageData = staticPageSelector(state, API_STATIC_PUBLIC_NEAREST_GROUP_DETAIL_V2)
    ?.nestedContent;
  const prefilledSuccessStories = pageData?.find(
    ({ _type }) => _type === types.PREFILLED_SUCCESS_STORY_TILES_BLOCK,
  );
  return {
    bulletin: getMarketRichTextContent(
      marketName,
      staticPageSelector(state, API_STATIC_PUBLIC_NEAREST_GROUP_BULLETIN_V2),
    ),
    consultant,
    group,
    text: getMarketRichTextContent(
      marketName,
      staticPageSelector(state, API_STATIC_PUBLIC_NEAREST_GROUP_DETAIL_V2),
    ),
    isLoading: isLoadingSearchSelector(state),
    prefilledSuccessStories,
  };
};

const connected = connect(mapStateToProps);

const addInitAction = withInitAction(['params.id'], {
  prepared: ({ params: { id } }, dispatch) =>
    Promise.all([
      dispatch(getStaticContent(API_STATIC_PUBLIC_NEAREST_GROUP_DETAIL_V2, true)),
      dispatch(getStaticContent(API_STATIC_PUBLIC_NEAREST_GROUP_BULLETIN_V2, true)).catch(
        () => null,
      ),
      dispatch(getGroupDetail(id)).then(result => {
        const { code } = result?.consultant;
        return dispatch(getConsultantDetail(code));
      }),
    ]),
});

export default compose(addInitAction, connected)(NearestGroupDetail);
