/* global 'molecule','UserTestingResearcher' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import QuotationBlock from 'components/molecules/BlockContent/components/atoms/QuotationBlock';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import TextNew, { FontWeight } from 'components/atoms/TextNew';
import Image from 'components/atoms/Image';
import './user-testing-researcher.scss';

const UserTestingResearcher = ({ image, name, bio, title }, context, className) => (
  <div className={className}>
    {image && (
      <>
        <Image src={image?.src} alt={image?.alt} ratio={1} />
        <TextNew.Sans.XS weight={FontWeight.LIGHT}>{title}</TextNew.Sans.XS>
        <RichTextBlock text={name} />
      </>
    )}
    {bio && <QuotationBlock quotation={bio} />}
  </div>
);

UserTestingResearcher.propTypes = {
  image: PropTypes.object,
  name: PropTypes.string,
  title: PropTypes.string,
  bio: PropTypes.string,
};

export default withFunctionalClassName('molecule','UserTestingResearcher')(UserTestingResearcher);
