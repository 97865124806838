import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import quickLinkShape from 'common/src/app/util/proptypes/apiEntities/quickLink';
import Link from 'react-router/lib/Link';
import CustomScrollBar from '../../molecules/CustomScrollBar';
import TextNew, { AccentColors, ElementTypes } from '../../atoms/TextNew';
import './quick-links.scss';

/**
 * take an array of objects  of quicklinks and they will
 * display as row of buttons which is scrollable on mobile.
 * The main use case for this is on landing pages.
 */

const QuickLinks = ({ items, location, foodPreferences }, context, className) => (
  <div className={className}>
    <CustomScrollBar>
      <div className="wrapper">
        {items.map(quickLink => {
          const active = location === quickLink.searchLink;

          return (
            <Link
              to={
                foodPreferences
                  ? `${quickLink.searchLink}&useFoodPreferences=1`
                  : quickLink.searchLink
              }
              className={classNames('link', active && 'is-active')}
              key={quickLink.title}
            >
              <TextNew.Formal color={AccentColors.ACCENT_PINK} element={ElementTypes.SPAN}>
                {quickLink.title}
              </TextNew.Formal>
            </Link>
          );
        })}
      </div>
    </CustomScrollBar>
  </div>
);

QuickLinks.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(quickLinkShape)),
  location: PropTypes.string,
  foodPreferences: PropTypes.bool,
};

export default withFunctionalClassName(ComponentType.ORGANISM, 'QuickLinks')(QuickLinks);
