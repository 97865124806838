import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import IcelandLanding from 'bundle-loader?lazy&reactHot&name=IcelandLanding!./components/templates/IcelandLanding';
import IcelandOurStory from 'bundle-loader?lazy&reactHot&name=IcelandOurStory!./components/templates/IcelandOurStory';
import IcelandOurProducts from 'bundle-loader?lazy&reactHot&name=IcelandOurProducts!./components/templates/IcelandOurProducts';
import IcelandOurRange from 'bundle-loader?lazy&reactHot&name=IcelandOurRange!./components/templates/IcelandOurRange';

const routes = (
  <Route>
    <Route
      path={Pages.UK_PUBLIC_ICELAND}
      {...getRouteComponentProp(IcelandLanding)}
      title="Discover more about the full Slimming World frozen food range"
      description="The Slimming World frozen food range, available exclusively in Iceland, includes delicious meals, side dishes, soups, sauces, lunch bowls, ultra-lean meats and meat alternatives."
    />
    <Route
      path={Pages.UK_PUBLIC_ICELAND_OUR_STORY}
      {...getRouteComponentProp(IcelandOurStory)}
      title="Find out more about why we created the Slimming World food range"
      description="Slimming World’s Free Food dishes are packed with healthy ingredients, generously portioned and easy to prepare in the microwave or oven.  The range helps our members to Food Optimise and lose weight, even when life gets frantic."
    />
    <Route
      path={Pages.UK_PUBLIC_ICELAND_OUR_PRODUCTS}
      {...getRouteComponentProp(IcelandOurProducts)}
      title="Find out how we developed the Slimming World frozen food range"
      description="The delicious Slimming World Free Food range is perfect for those days when there’s just no time to cook. The range includes meals, soups, sauces and sides, available exlcusively in Iceland stores."
    />
    <Route
      path={Pages.UK_PUBLIC_ICELAND_OUR_RANGE}
      {...getRouteComponentProp(IcelandOurRange)}
      title="Discover more about the full Slimming World frozen food range"
      description="The Slimming World frozen food range, available exclusively in Iceland, includes delicious meals, side dishes, soups, sauces, lunch bowls, ultra-lean meats and meat alternatives."
    />
  </Route>
);

export default routes;
