/* global WP_DEFINE_DEVELOPMENT */

/**
 * Returns `true` in each of the following cases:
 *  - we are running a development build
 *  - we are running a distribution build locally (identified by the `NODE_ENV` environment
 *  variable being `"development"` or `"localhost"`)
 *  - we are running a distribution build on the `development` environment (e.g.
 *  https://dev-account.slimmingworld.com)
 *
 * Returns `false` otherwise
 *
 * @returns {boolean}
 */
function isLocalOrDevelopment() {
  const nodeEnv = process.env['NODE_ENV']; // eslint-disable-line dot-notation

  return (
    WP_DEFINE_DEVELOPMENT ||
    typeof nodeEnv === 'undefined' ||
    nodeEnv === 'development' ||
    nodeEnv === 'testa' ||
    nodeEnv === 'testb' ||
    nodeEnv === 'testg' ||
    nodeEnv === 'localhost'
  );
}

export default isLocalOrDevelopment;
