/* global 'atom','IcelandProductBanner' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { Colors } from 'components/atoms/TextNew';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import ComponentType from 'common/src/app/data/enum/ComponentType';

import './iceland-product-banner.scss';

const IcelandProductBanner = (
  { isFoodWarehouse, flashMessage, warehouseImage },
  context,
  className,
) => (
  <div className={className}>
    {isFoodWarehouse ? (
      <>
        <TextNew.Details localeId="icelandProductBanner.warehouse" color={Colors.PRIMARY_LIGHT} />
        {warehouseImage && (
          <ResponsiveImage src={warehouseImage.src} px={140} alt={warehouseImage.alt} />
        )}
      </>
    ) : (
      <TextNew.Script.XS color={Colors.PRIMARY_LIGHT}>
        {flashMessage.text.toLowerCase()}
      </TextNew.Script.XS>
    )}
  </div>
);

IcelandProductBanner.propTypes = {
  isFoodWarehouse: PropTypes.bool,
  flashMessage: PropTypes.shape({
    text: PropTypes.string,
    accentColor: PropTypes.string,
  }),
  warehouseImage: PropTypes.shape(imageShape),
};

export default withFunctionalClassName(ComponentType.ATOM, 'IcelandProductBanner', [
  ({ flashMessage }) => flashMessage?.accentColor && `is-accent-color-${flashMessage.accentColor}`,
])(IcelandProductBanner);
