/**
 * Prefered usage
 */
export const FontWeight = {
  LIGHT: 'light',
  NORMAL: 'normal',
  BOLD: 'bold',
  BOLDER: 'bolder',
};
FontWeight.propTypes = Object.values(FontWeight);

export const FontFamily = {
  SANS: 'sans',
  SERIF: 'serif',
  SCRIPT: 'script',
};
FontFamily.propTypes = Object.values(FontFamily);

export const FontStyles = {
  NORMAL: 'normal',
  ITALIC: 'italic',
};
FontStyles.propTypes = Object.values(FontStyles);

export const FontSizes = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  XXL: 'xxl',
};
FontSizes.propTypes = Object.values(FontSizes);

export const TextDecoration = {
  UNDERLINE: 'underline',
};
TextDecoration.propTypes = Object.values(TextDecoration);

/**
 * Market differences for text styles
 */

const TextTypes = {
  SCRIPT_LG: 'script-lg',
  SCRIPT_MD: 'script-md',
  SCRIPT_SM: 'script-sm',
  SCRIPT_XS: 'script-xs',
  LOUD: 'loud',
  CONFIDENT: 'confident',
  STRONG: 'strong',
  DETAILS: 'details',
  FORMAL: 'formal',
  FORMAL_ORIGINAL: 'formal-original',
  FORMAL_LIGHT: 'formal-light',
  PRIMARY_ELEGANT: 'primary-elegant',
  PRIMARY_ORIGINAL: 'primary-original',
  PRIMARY_FOOTNOTE: 'primary-footnote',
  ERROR: 'error',
  SUCCESS: 'success',
  QUIET: 'quiet',
  FORM: 'form',
};

TextTypes.propTypes = Object.values(TextTypes);

export default TextTypes;
