import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import LinkTracker from '../../../../../atoms/LinkTracker';
import DeepLinkDisplayToggle from '../../../../../organisms/DeepLinkDisplayToggle';
import TextNew, { Colors, ElementTypes } from '../../../../../atoms/TextNew';
import Button from '../../../../../atoms/Button';
import Icon from '../../../../../atoms/Icon';
import RichTextBlock from '../RichTextBlock';
import './icon-rich-text-and-link-list-block.scss';

/**
 * List of blocks with an icon, rich text and a (download) link, used at the top of essentials page
 */
class IconRichTextAndLinkListBlock extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  onIconClick = index => {
    this.setState({
      activeIndex: index,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  renderElement = (item, index, { element: HtmlElement = ElementTypes.BUTTON, ...props }) => (
    <HtmlElement key={index} className="icon-container" {...props}>
      {item?.icon && <Icon name={item.icon.replace('icon-', '')} width={56} height={56} />}
    </HtmlElement>
  );

  render() {
    const { content, deviceState } = this.props;
    const activeIndex = this.state.activeIndex;

    return (
      <div {...componentClassNameProp(ComponentType.ATOM, this)}>
        <ul className="icon-list">
          {content &&
            content.map((item, index) => (
              <li
                className={classNames('icon-item', {
                  'is-active': index === activeIndex,
                  'is-before-active': index === activeIndex - 1,
                })}
                key={item.title + item.text}
              >
                {deviceState < DeviceState.LG
                  ? this.renderElement(item, index, {
                      onClick: () => this.onIconClick(index),
                    })
                  : this.renderElement(item, index, {
                      element: ElementTypes.DIV,
                    })}
              </li>
            ))}
        </ul>
        <ul className="content-list">
          {content &&
            content.map((item, index) => (
              <li
                className={classNames('content-item', { 'is-active': index === activeIndex })}
                key={item.title + item.text}
              >
                <div className="copy-container">
                  {item.title ? (
                    <TextNew.Confident cid="block-title">{item.title}</TextNew.Confident>
                  ) : null}
                  {item.text ? <RichTextBlock text={item.text} /> : null}
                </div>
                {item.buttonLink && item.buttonLabel ? (
                  <LinkTracker
                    meta={
                      item.trackingData
                        ? {
                            [`${item.trackingData.action}`]: item.trackingData.label,
                            contentView: item.trackingData.category,
                            category: item.trackingData.category,
                          }
                        : {}
                    }
                  >
                    <Button href={item.buttonLink} target="_blank" isTertiary>
                      <Icon name="download" width={24} height={24} />
                      <TextNew color={Colors.BRAND} element={ElementTypes.SPAN}>
                        {item.buttonLabel}
                      </TextNew>
                    </Button>
                  </LinkTracker>
                ) : null}
              </li>
            ))}
        </ul>
        <DeepLinkDisplayToggle />
      </div>
    );
  }
}

IconRichTextAndLinkListBlock.propTypes = {
  deviceState: PropTypes.number,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      buttonLink: PropTypes.string,
      buttonLabel: PropTypes.string,
    }),
  ),
};

export default withDeviceState()(IconRichTextAndLinkListBlock);
