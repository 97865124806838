import { kilogramsToPounds, poundsToKilograms } from '../util/conversionUtils';

/**
 * Converts values from the WeightMetricFieldGroup input into a
 * number value in kg.
 *
 * @returns {number} The resulting number value in kg
 */
export const composeWeightMetric = ({ kilos, grams }) => {
  if (typeof kilos === 'undefined' && typeof grams === 'undefined') {
    return kilos;
  }
  const parsedKilos = kilos ? parseInt(kilos, 10) : 0;
  const parsedGrams = grams ? parseFloat(`0.${grams}`) : 0;

  return parsedKilos + parsedGrams;
};

/**
 * Converts from kilograms to object { kilos, grams }
 * @param {number} kg A number that contains an integer representing
 * the amount of kilograms
 *
 * @returns {object} The resulting object value in { kilos, grams }
 */
export const decomposeWeightMetric = kg => {
  if (typeof kg === 'undefined') {
    return { kilos: '', grams: '' };
  }
  const parsedKg = kg || 0;

  const kilos = Math.floor(parsedKg);
  const grams = Math.round((parsedKg % 1) * 100);

  return { kilos, grams };
};

/**
 * Converts values from the WeightMetricFieldGroup with stones input into a
 * number value in pounds and then in kilograms.
 *
 * @returns {number} The resulting number value in kilograms
 */
export const composeWeightImperialWithStonesToMetric = ({
  stones,
  fullPounds,
  remainingPounds,
}) => {
  if (typeof stones === 'undefined' && typeof fullPounds === 'undefined') {
    return stones;
  }
  const parsedStones = stones ? parseInt(stones, 10) : 0;
  const parsedPounds = fullPounds ? parseInt(fullPounds, 10) : 0;
  const parsedremainingPounds = remainingPounds ? parseFloat(remainingPounds) : 0;

  // if (isNaN(parsedStones)) {
  //   throw new CompositeInputFormatterError({ stones: 'stones should be a number' });
  // }
  // if (isNaN(parsedPounds)) {
  //   throw new CompositeInputFormatterError({ fullPounds: 'lbs should be a number' });
  // }
  // if (parsedPounds >= 14) {
  //   throw new CompositeInputFormatterError({ fullPounds: 'lbs should be smaller than 14' });
  // }
  const wholePounds = parsedremainingPounds + parsedPounds;
  // There are 14 pounds in each stone this add together wholepounds and converts
  const wholePoundsWithStones = wholePounds + parsedStones * 14;
  return poundsToKilograms(wholePoundsWithStones);
};

/**
 * Converts values from kilos to pounds with stones
 *
 * @returns {object} The resulting object value in { stones, fullPounds, remainingPounds }
 */
export const decomposeWeightImperialWithStonesToMetric = kilos => {
  if (typeof kilos === 'undefined') {
    return { stones: '', fullPounds: '', remainingPounds: '' };
  }

  const parsedKilos = kilos || 0;

  const pounds = kilogramsToPounds(parsedKilos);
  const stones = Math.floor(pounds / 14);
  const poundsWithoutStones = pounds % 14;
  const fullPounds = Math.floor(poundsWithoutStones);
  const remainingPounds = parseFloat((poundsWithoutStones % 1).toFixed(2));

  return { stones, fullPounds, remainingPounds };
};

/**
 * Converts values from the WeightImperialFieldGroup input into a number value in kilograms
 * @param {object} formValues
 * @param {string} formValues.fullPounds A string that contains an integer representing
 * the amount of pounds
 * @param {string} formValues.remainingPounds A string that contains an integer representing
 * the any non-whole pounds, rounded to the nearest half
 *
 * @returns {number} The resulting number value in metric (kilos)
 */
export const composeWeightImperialToMetric = ({ fullPounds, remainingPounds }) => {
  if (typeof fullPounds === 'undefined' && typeof remainingPounds === 'undefined') {
    return fullPounds;
  }
  const parsedPounds = fullPounds ? parseInt(fullPounds, 10) : 0;
  const parsedremainingPounds = remainingPounds ? parseFloat(remainingPounds) : 0;

  const wholePounds = parsedremainingPounds + parsedPounds;
  return poundsToKilograms(wholePounds);
};

/**
 * Converts values from kilos to pounds
 * @param {string} kilos A string that contains an integer representing
 * the amount of kilograms
 *
 * @returns {object} The resulting object with { fullPounds, remainingPounds }
 */
export const decomposeWeightImperialToMetric = kilos => {
  if (typeof kilos === 'undefined') {
    return { fullPounds: '', remainingPounds: '' };
  }

  const parsedKilos = kilos || 0;

  const pounds = kilogramsToPounds(parsedKilos);
  const fullPounds = Math.floor(pounds);
  const remainingPounds = Math.round(parseFloat((pounds % 1) * 100));

  return { fullPounds, remainingPounds };
};
