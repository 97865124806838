import handleActions from 'redux-actions/lib/handleActions';

import { SET_SEO, CLEAR_SEO } from '../actions/seoActions';

const initialState = {
  metaTitle: null,
  metaDescription: null,
  metaImage: null,
};

export default handleActions(
  {
    [SET_SEO]: (currentState, { payload: { seo, route } }) => ({
      // store per route for client (when going back to cached pages)
      [route]: seo,
      // store on server for just the current data
      ...seo,
    }),
    [CLEAR_SEO]: currentState => ({
      ...currentState,
      // only reset the global keys to null, leave per-page in there
      ...initialState,
    }),
  },
  initialState,
);
