import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction, prepareComponent } from '@mediamonks/react-redux-component-init';
import { API_STATIC_PUBLIC_ICELAND_LANDING } from 'common/src/app/data/apiStatics';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import IcelandFreebieDeal from '../../molecules/IcelandFreebieDeal';
import IcelandLanding from './IcelandLanding';

const mapStateToProps = state => ({
  pageData: staticPageSelector(state, API_STATIC_PUBLIC_ICELAND_LANDING),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(prepareComponent(IcelandFreebieDeal)),
      dispatch(getStaticContent(API_STATIC_PUBLIC_ICELAND_LANDING, true)),
    ]),
});

export default compose(addInitAction, connected)(IcelandLanding);
