import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';
import { genderFilterValues } from 'common/src/app/data/enum/Gender';
import QuickLinks from '../../organisms/QuickLinks';

// Todo: Hook up API call for this data

const SuccessStoryFilters = () => (
  <div className={functionalComponentClassName(ComponentType.MOLECULE, 'SuccessStoryFilters')}>
    <QuickLinks
      items={[
        {
          title: genderFilterValues.FEMALE,
          searchLink: `${Pages.UK_PUBLIC_SUCCESS_STORIES}/${genderFilterValues.FEMALE}`,
        },
        {
          title: genderFilterValues.MALE,
          searchLink: `${Pages.UK_PUBLIC_SUCCESS_STORIES}/${genderFilterValues.MALE}`,
        },
      ]}
    />
  </div>
);

export default SuccessStoryFilters;
