import { connect } from 'react-redux';
import compose from 'redux/lib/compose';

import { setDeviceState, setScrollbarWidth } from '../../actions/deviceStateActions';
import DeviceStateProvider from './DeviceStateProvider';

const mapStateToProps = state => state.deviceState;

const mapDispatchToProps = {
  setDeviceState,
  setScrollbarWidth,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(DeviceStateProvider);
