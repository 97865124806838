import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'redux/lib/compose';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import { Link } from 'react-router';
import Image from 'components/atoms/Image';
import LocaleMessage from 'components/atoms/LocaleMessage';
import TextNew, { FontSizes, FontWeight, ElementTypes } from 'components/atoms/TextNew';
import Wrapper from 'components/atoms/Wrapper';
import errorBoundary from 'components/hoc/ErrorBoundary';
import './mental-health.scss';

const HealthLinks = ({ links }) =>
  links.map(item => (
    <ul key={item.title}>
      <li>
        <TextNew.Sans.LG weight={FontWeight.BOLD} element={ElementTypes.H4} localeId={item.title} />
      </li>
      {item.links.map(link => (
        <li key={link.localeId}>
          <Link target="_blank" href={link.href}>
            <TextNew.Sans.LG weight={FontWeight.LIGHT} localeId={link.localeId} />
          </Link>
        </li>
      ))}
    </ul>
  ));

HealthLinks.propTypes = {
  links: PropTypes.array,
};

const MentalHealth = ({ deviceState, healthLinksIE, healthLinksUK, pageContent }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://digitalcampaignsstorage.blob.core.windows.net/mental-health-tool/production/deploy/public/static/js/main.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const [mainImage, mobileImage] = pageContent?.nestedContent || [];
  const image = deviceState >= DeviceState.MD ? mainImage?.image?.src : mobileImage?.image?.src;

  return (
    <div className="page-mental-health">
      <Image src={image} ratio={deviceState >= DeviceState.MD ? 1849 / 520 : 500 / 400} />
      <Wrapper className="mental-health-content">
        <TextNew.Serif.MD
          cid="quiz-title"
          element={ElementTypes.H2}
          localeId="mentalHealth.quiz.title"
        />
        <TextNew.Sans.LG
          weight={FontWeight.BOLD}
          localeId="mentalHealth.quiz.bold"
          localeParams={{
            LINK: (
              <Link
                key="everymindlink"
                href="https://www.nhs.uk/every-mind-matters/?WT.mc_ID=slimmingworld"
              >
                <LocaleMessage id="mentalHealth.link" />
              </Link>
            ),
          }}
        />
        <TextNew.Sans.LG weight={FontWeight.LIGHT} localeId="mentalHealth.quiz.body" />
        <TextNew.Sans.LG weight={FontWeight.BOLD} localeId="mentalHealth.quiz.cta" cid="quiz-cta" />
      </Wrapper>
      <div
        id="yourMindPlanQuiz"
        data-site-id="Slimming World"
        className="mind-plan-quiz"
        data-syndication-id="cnlhbi53YWl0ZUBzbGltbWluZ3dvcmxkLmNvLnVr"
      />
      <Wrapper className="mental-health-content">
        <TextNew.Script
          cid="health-title"
          element={ElementTypes.H2}
          localeId="mentalHealth.health.title"
          size={deviceState > DeviceState.XS ? FontSizes.LG : FontSizes.MD}
        />
        <TextNew.Serif
          cid="health-subtitle"
          element={ElementTypes.H3}
          localeId="mentalHealth.health.subtitle"
          size={deviceState > DeviceState.XS ? FontSizes.MD : FontSizes.SM}
        />

        <TextNew.Sans.LG localeId="mentalHealth.health.copy" />
        <nav>
          <HealthLinks links={healthLinksUK} />
          <div className="ireland">
            <HealthLinks links={healthLinksIE} />
          </div>
        </nav>
      </Wrapper>
    </div>
  );
};

MentalHealth.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

MentalHealth.propTypes = {
  deviceState: PropTypes.number,
  healthLinksUK: PropTypes.array,
  healthLinksIE: PropTypes.array,
  pageContent: PropTypes.shape({
    nestedContent: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
        }),
      }),
    ),
  }),
};

export default compose(errorBoundary({ wholePageError: true }), withDeviceState())(MentalHealth);
