import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import Image from 'components/atoms/Image';

import './offers-box.scss';

const OffersBoxText = ({ title, description }) => (
  <>
    <TextNew.Serif.SM element={ElementTypes.H2} cid="offer-box-title" color={Colors.BRAND}>
      {title}
    </TextNew.Serif.SM>
    <RichTextBlock text={description} />
  </>
);

const OffersBox = ({ image, displayHalfWidth, disclaimer, deviceState, ...content }) => (
  <div
    data-testid="offers-box"
    className={classNames('offers-box', { 'half-width': displayHalfWidth }, { image })}
  >
    {deviceState > DeviceState.LG && !displayHalfWidth ? (
      <div className="offers-box-content-full-length">
        <OffersBoxText {...content} />
      </div>
    ) : (
      <OffersBoxText {...content} />
    )}
    {image && <Image {...image} ratio={39 / 47} />}
    {disclaimer && (
      <TextNew.Sans.SM
        dataTestid="offer-disclaimer"
        cid="offer-box-disclaimer"
        color={Colors.MID_TONE}
      >
        {disclaimer}
      </TextNew.Sans.SM>
    )}
  </div>
);

export default withDeviceState()(OffersBox);

OffersBox.propTypes = {
  description: PropTypes.node.isRequired,
  image: PropTypes.shape(Image.propTypes),
  title: PropTypes.string.isRequired,
  displayHalfWidth: PropTypes.bool,
  disclaimer: PropTypes.string,
  deviceState: PropTypes.number,
};
