import React from 'react';
import propTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { ElementTypes, FontWeight, Colors } from '../../atoms/TextNew';
import Button from '../../atoms/Button';
import LocaleMessage from '../../atoms/LocaleMessage';
import './contact-us-faqs.scss';

const ContactUsFaqs = ({ faqsLink }, context, className, dataTestid) => (
  <div data-testid={dataTestid} className={className}>
    <header>
      <TextNew.Sans.SM
        localeId="contactUsFaqs.header"
        cid="head"
        element={ElementTypes.H2}
        color={Colors.PRIMARY_DARK}
      />
    </header>
    <TextNew.Sans.SM
      localeId="contactUsFaqs.body"
      cid="body"
      color={Colors.PRIMARY_DARK}
      weight={FontWeight.LIGHT}
    />
    <Button href={faqsLink} target="blank">
      <LocaleMessage id="contactUsFaqs.cta" />
    </Button>
  </div>
);

ContactUsFaqs.propTypes = {
  faqsLink: propTypes.string,
};

export default withFunctionalClassName(ComponentType.TEMPLATE, 'ContactUsFaqs')(ContactUsFaqs);
