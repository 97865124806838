import React from 'react';
import Link from 'react-router/lib/Link';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import reducePublicGroupSessions from 'common/src/app/util/ReducePublicGroupSessions';
import GroupTimetable from 'components/molecules/GroupTimetable';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { consultantShape } from 'common/src/app/util/proptypes/apiEntities/groupSearchShape';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button';
import Wrapper from 'components/atoms/Wrapper';
import TextNew, {
  Colors,
  FontWeight,
  FontSizes,
  ElementTypes,
  AccentColors,
} from 'components/atoms/TextNew';
import ModifiedGroupTimetable from 'components/molecules/ModifiedGroupTimetable';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import { shopFields, addressFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';
import DomEventTracker from 'components/atoms/DomEventTracker';
import { DataLayerKeys } from 'common/src/app/data/enum/Tracking';
import GroupSearchConsultantDetails from 'components/organisms/GroupSearchConsultantDetails';
import NearestGroupSessionMessages from 'components/organisms/NearestGroupSessionMessages';
import VenueAccessibility from '../../../../../../../../molecules/VenueAccessibility';

import './group-full-details.scss';

const MapMarker = () => (
  <div
    data-testid="map-marker"
    style={{ transform: 'translate(-50%, -50%)', transformOrigin: 'bottom center' }}
  >
    <Icon name={IconName.MAP_PIN} width={28} />
  </div>
);

const textStyleFormetter = (text, dataTestid) => (
  <TextNew.Sans.SM key={text} dataTestid={dataTestid || 'field'} testcid cid="text-item">
    {text}
  </TextNew.Sans.SM>
);

const GroupFullDetails = ({ group, isLoading, consultant, googleMapApi }, context, className) => {
  const groupedSessions = reducePublicGroupSessions(group.sessions);
  const groupLatLong = group?.venue?.location;
  const venueAccessibility = group?.venue?.venueAccessibility || null;

  const content = group?.venue && {
    venue: (
      <address>
        {Object.keys(group.venue)
          .filter(
            field =>
              field !== shopFields.REGION &&
              (field !== addressFields.POSTCODE ||
                (field === addressFields.POSTCODE && !marketConfig.dropDownBasedGroupSearch)),
          )
          .map(key =>
            typeof group.venue[key] === 'string'
              ? textStyleFormetter(group.venue[key], 'address')
              : '',
          )}
      </address>
    ),
    telephone: (
      <Link data-testid="telephone" href={`tel:${group?.consultant?.telephone}`}>
        {textStyleFormetter(group?.consultant?.telephone)}
      </Link>
    ),
    sessions: (
      <GroupTimetable
        textStyle={{ size: FontSizes.SM, weight: FontWeight.NORMAL, color: Colors.PRIMARY_DARK }}
        headerTextStyle={{
          weight: FontWeight.NORMAL,
          size: FontSizes.MD,
          color: AccentColors.ACCENT_PINK,
          titleElement: ElementTypes.H2,
        }}
        groupSessions={groupedSessions}
      />
    ),
  };

  return (
    <div className={className}>
      <Wrapper padding="sm">
        <div className="group-details">
          {consultant && !isLoading && (
            <GroupSearchConsultantDetails
              name={consultant?.name}
              imageUrl={consultant?.imageUrl}
              biography={consultant?.biography}
              location={group?.venue?.address1}
            />
          )}
          <div className="profile-details detail-wrapper-flex">
            {content &&
              Object.keys(content).map((detail, index) => (
                <div key={`detail-${index}`} className={`detail-wrapper ${detail}`}>
                  {detail !== 'sessions' && (
                    <TextNew.Sans.MD
                      element={ElementTypes.H2}
                      cid="title"
                      color={AccentColors.ACCENT_PINK}
                      localeId={`nearestGroupDetails.profileDetails.${detail}`}
                    />
                  )}
                  {content[detail]}
                </div>
              ))}
            {group.events &&
              Object.keys(group.events).map(e => (
                <div key={`detail-${e}`} className={`detail-wrapper ${e}`}>
                  <ModifiedGroupTimetable
                    key={`group-${group.id}-${e}-timetable`}
                    type={e}
                    groupSessions={group.events[e]}
                    headerTextStyle={{
                      weight: FontWeight.NORMAL,
                      size: FontSizes.MD,
                      color: AccentColors.ACCENT_PINK,
                      titleElement: ElementTypes.H2,
                    }}
                    textStyle={{
                      size: FontSizes.SM,
                      weight: FontWeight.NORMAL,
                      color: Colors.PRIMARY_DARK,
                    }}
                  />
                </div>
              ))}

            {venueAccessibility && (
              <div className={'detail-wrapper venueAccessibility'}>
                <VenueAccessibility
                  headerTextStyle={{
                    weight: FontWeight.NORMAL,
                    size: FontSizes.MD,
                    color: AccentColors.ACCENT_PINK,
                    titleElement: ElementTypes.H2,
                  }}
                  textStyle={{
                    size: FontSizes.SM,
                    weight: FontWeight.NORMAL,
                    color: Colors.PRIMARY_DARK,
                  }}
                  venueAccessibility={venueAccessibility}
                />
              </div>
            )}
          </div>

          {groupedSessions && <NearestGroupSessionMessages sessions={groupedSessions} />}

          <TextNew.Sans.XS
            dataTestid="disclaimer"
            localeId="nearestGroupDetails.disclaimer"
            cid="session-length-disclaimer"
          />

          <div className="back-to-search">
            <Row spaceBetween aligncenter>
              <DomEventTracker
                meta={{
                  [DataLayerKeys.OTHER_GROUPS]: 1,
                }}
              >
                <Button
                  dataTestid="back-to-search"
                  isGhostDark
                  isTiny
                  onClick={() => history.back()}
                >
                  <LocaleMessage id={`nearestGroupDetails.otherGroupButton`} />
                </Button>
              </DomEventTracker>
              <Link data-testid="online-info" to={Pages.UK_PUBLIC_ONLINE_INFORMATION}>
                <TextNew.Formal localeId="nearestGroupDetails.noSuitableGroup" />
              </Link>
            </Row>
          </div>
        </div>

        <div className="map" data-testid="map">
          <GoogleMapReact bootstrapURLKeys={googleMapApi} defaultZoom={17} center={groupLatLong}>
            <MapMarker {...groupLatLong} />
          </GoogleMapReact>
        </div>
      </Wrapper>
    </div>
  );
};

GroupFullDetails.propTypes = {
  group: PropTypes.object,
  consultant: PropTypes.shape(consultantShape),
  googleMapApi: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default withFunctionalClassName(
  ComponentType.ORGANISM,
  'GroupFullDetails',
)(GroupFullDetails);
