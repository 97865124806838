export default {
  BUTTON: 'ButtonBackgroundIconTile',
  FEATURE: 'FeatureTile',
  PUBLIC_RECIPE: 'PublicRecipe',
  PUBLIC_RECIPE_LARGE: 'PublicRecipeLarge',
  RECIPE: 'RecipeTile',
  SUCCESS_STORY: 'SuccessStoryTile',
  PUBLIC_SUCCESS_STORY: 'PublicSuccessStoryTile',
  DEFAULT: 'Default',
  FOOD_RANGE_PRODUCT: 'FoodRangeProduct',
};
