import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { SearchType, getSearchResults } from 'common/src/app/actions/resources/searchActions';
import { landingGridSelector } from 'common/src/app/selectors/publicSelectors';
import ArticleType from 'common/src/app/data/enum/ArticleType';
import RelatedSuccessStories from './RelatedSuccessStories';

const EMPTY_ARRAY = [];

const publicSuccessStories = stories =>
  stories.reduce((acc, cur) => {
    // eslint-disable-next-line no-underscore-dangle, no-param-reassign
    cur._type = ArticleType.PUBLIC_SUCCESS_STORY;
    acc.push(cur);

    return acc;
  }, []);

const mapStateToProps = (state, { successStories, successStoriesLimit = 4 }) => {
  const gridContents = {
    results: [
      ...(successStories?.length > 0 ? publicSuccessStories(successStories) : EMPTY_ARRAY),
      ...landingGridSelector(state).gridContents.results,
    ],
  };
  if (gridContents.results.length > successStoriesLimit) {
    gridContents.results = gridContents.results.slice(0, successStoriesLimit);
  }
  return {
    gridContents,
  };
};

const mapDispatchToProps = dispatch => ({
  getSearchResultsAction: (limit, excludedIds) =>
    dispatch(
      getSearchResults(
        SearchType.PUBLIC_SUCCESS_STORIES,
        {
          limit,
        },
        false,
        null,
        null,
        excludedIds,
      ),
    ),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(RelatedSuccessStories);
