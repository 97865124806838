import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { ElementTypes, Colors, FontWeight } from '../../../../atoms/TextNew';
import CheckBoxNew from '../../../../atoms/Form/components/atoms/CheckBoxNew';
import './filter-category-group.scss';

const FilterCategoryGroup = (
  { legend, legendTextStyle, values, fieldName, handleChange, activeValues, checkBoxTextStyle },
  context,
  className,
) => (
  <fieldset className={className}>
    <TextNew.Sans.SM
      dataTestid="legend"
      cid="legend"
      color={Colors.PRIMARY_DARK}
      element={ElementTypes.LEGEND}
      {...legendTextStyle}
    >
      {legend}
    </TextNew.Sans.SM>
    {values.map(({ name, text, icon }, index) => (
      <CheckBoxNew
        key={name}
        dataTestid={`${fieldName}-${index}`}
        input={{
          name: fieldName,
          value: name,
          id: `${name}-${index}`,
          onChange: handleChange,
          type: 'checkbox',
          checked: activeValues?.includes(name),
        }}
      >
        <TextNew.Formal
          color={Colors.PRIMARY_DARK}
          weight={FontWeight.LIGHT}
          element={ElementTypes.SPAN}
          {...checkBoxTextStyle}
        >
          {text}
        </TextNew.Formal>
        {icon && icon}
      </CheckBoxNew>
    ))}
  </fieldset>
);

FilterCategoryGroup.propTypes = {
  legend: PropTypes.string.isRequired,
  icon: PropTypes.node,
  values: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, text: PropTypes.string }))
    .isRequired,
  fieldName: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  activeValues: PropTypes.arrayOf(PropTypes.string),
  legendTextStyle: PropTypes.object,
  checkBoxTextStyle: PropTypes.object,
};

export const FilterCategoryGroupProps = FilterCategoryGroup;

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'FilterCategoryGroup',
)(FilterCategoryGroup);
