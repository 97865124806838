/* global 'molecule','HandleErrorComponent' */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { Colors } from '../../atoms/TextNew';
import LocaleMessage from '../../atoms/LocaleMessage';
import './handle-error-component.scss';

const HandleErrorComponent = (
  { title, wholePageError, openContactFormModal },
  context,
  className,
) => (
  <div className={className}>
    <div className="content-emotion">
      <TextNew.Script.MD
        cid="formal-original-margin"
        color={Colors.BRAND}
        localeId="handleErrorComponent.title"
      />
    </div>
    <div className="content-text">
      <TextNew.PrimaryOriginal
        cid="formal-original-margin"
        localeId="handleErrorComponent.descriptionError"
        localeParams={{
          TITLE: title,
          COMPONENT_TYPE: (
            <LocaleMessage
              id={`handleErrorComponent.errorLevel.${wholePageError ? 'site' : 'page'}`}
            />
          ),
        }}
      />

      <TextNew.PrimaryOriginal>
        <LocaleMessage id="handleErrorComponent.keepExperiencingProblems" />
        <Link onClick={openContactFormModal}>
          <LocaleMessage id="handleErrorComponent.contactUs" />
        </Link>
      </TextNew.PrimaryOriginal>
    </div>
  </div>
);

HandleErrorComponent.propTypes = {
  title: PropTypes.node.isRequired,
  wholePageError: PropTypes.bool,
  openContactFormModal: PropTypes.func.isRequired,
};

export default withFunctionalClassName('molecule','HandleErrorComponent', ['isColumn'])(HandleErrorComponent);
