import { connect } from 'react-redux';
import { makeInteractionStateSelector } from 'common/src/app/reducers/interactionsReducer';
import InteractionType from 'common/src/app/data/enum/InteractionType';
import MasterTile from './MasterTile';

const mapStateToProps = () => {
  const interactionSelector = makeInteractionStateSelector();

  return (state, { id, isTwelveWeekProgramContent }) => {
    if (isTwelveWeekProgramContent) {
      const { isActive: isRead } = interactionSelector(state.interactions, {
        interactionType: InteractionType.READ,
        targetId: id,
      });

      return { isRead };
    }

    return {};
  };
};

export default connect(mapStateToProps)(MasterTile);
