const KeyCode = {
  ENTER: 13,
  SPACE: 32,
  TAB: 9,
  BACKSPACE: 8,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
};

export const Key = {
  ENTER: 'Enter',
  SPACE: 'Space',
};

export default KeyCode;
