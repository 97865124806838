// Weekly prices
const groupWeeklyPriceGBP = '5.95';
const groupWeeklyPriceEUR = '10';

// Total prices
const groupTotalPriceGBP = '10.95';
const groupTotalPriceEUR = '19';

export default {
  GBP: {
    group: {
      week: groupWeeklyPriceGBP,
      overSixty: 4.65,
      sixteenSeventeen: 3.95,
      magazine: '1.95',
      introductoryCharge: 5,
      magazineSaving: 1,
      total: groupTotalPriceGBP,
      firstGroup: '10',
      countdownSaving: '9.90',
      book: '4.95',
      roundelSaving: '5',
      virtualGroupsActive: '3.75',
      membershipFee: '10',
      betterHealth: '5',
    },
    online: {
      week: 5,
      month: 20,
      threeMonths: 60,
      virtualGroupsActive: 5,
      membershipPackage: {
        bronze: '60',
        silver: '65',
        gold: '80',
      },
      magazine: '1.95',
    },
    nonMember: {
      single: 2.99,
      annual: 17.99,
      magazine: '3.99',
    },
    icelandCategories: {
      meals: '3.00',
      meats: '3.00',
      sides: '2.00',
      soup: '1.50',
      sauces: '1.50',
    },
    icelandDealDisclaimerPrices: {
      mealPriceLow: '3.00',
      mealPriceHigh: '3.50',
    },
    freeFoodFeb: {
      price: '4.95',
    },
    summerBookOffer: {
      price: '4.95',
    },
    betterHealthOffer: {
      price: '15',
      total: '55',
    },
    magazineSubscription: {
      directDebitCost: '20.70',
      annualCost: '21.50',
    },
    recipeBook: {
      price: '4.95',
    },
  },
  EUR: {
    group: {
      week: groupWeeklyPriceEUR,
      overSixty: '8.50',
      sixteenSeventeen: '7.50',
      magazine: '2.90',
      introductoryCharge: 9,
      total: groupTotalPriceEUR,
      firstGroup: 18,
      countdownSaving: '9',
      book: '7.95',
      roundelSaving: '9',
      virtualGroupsActive: '6',
      membershipFee: '18',
      betterHealth: '7',
    },
    online: {
      week: 6.25,
      month: 25,
      threeMonths: 75,
      virtualGroupsActive: '6.25',
      membershipPackage: {
        bronze: '75',
        silver: '90',
        gold: '120',
      },
      magazine: '2.90',
    },
    nonMember: {
      single: '3.49',
      annual: '20.49',
    },
    icelandCategories: {
      meals: '3.75',
      meats: '2.50',
      sides: '3.75',
      soup: '2.00',
      sauces: '2.00',
    },
    icelandDealDisclaimerPrices: {
      mealPriceLow: '3.75',
      mealPriceHigh: '4.00',
    },
    freeFoodFeb: {
      price: '7.95',
    },
    summerBookOffer: {
      price: '7.95',
    },
    betterHealthOffer: {
      price: '25',
      total: '68',
    },
    magazineSubscription: {
      annualCost: '37.99',
    },
    recipeBook: {
      price: '7.95',
    },
  },
};
