import React from 'react';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { consultantShape } from 'common/src/app/util/proptypes/apiEntities/groupSearchShape';
import Row from 'components/atoms/Row';
import TextNew, { AccentColors, ElementTypes } from 'components/atoms/TextNew';
import ReadMoreAccordion from 'components/molecules/ReadMoreAccordion';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import withDeviceState, {
  DeviceState,
  QueryHelpers,
} from 'common/src/app/util/device-state/withDeviceState';

import './group-search-consultant-details.scss';

const GROUP_SEARCH_VISABLE_CHARACTER_LIMIT = 150;

const GroupSearchConsultantDetails = (
  { name, biography, imageUrl, location, telephone, onLightBackground, queryHelper, deviceState },
  context,
  className,
  dataTestid,
) => (
  <div className={className} data-testid={dataTestid}>
    <div className="header">
      {location && name ? (
        <TextNew.Script.SM
          element={ElementTypes.H1}
          cid="group-title"
          color={AccentColors.ACCENT_PURPLE}
          localeId="groupSearchConsultantDetails.consultantLocation"
          localeParams={{
            NAME: name,
            PLACE: location,
          }}
        />
      ) : (
        <TextNew.Script.SM
          element={ElementTypes.H1}
          cid="group-title"
          localeId={`groupSearchConsultantDetails.welcomeText.${
            deviceState > DeviceState.XS ? 'desktop' : 'mobile'
          }`}
          color={AccentColors.ACCENT_PURPLE}
        />
      )}
      {imageUrl && (
        <ResponsiveImage
          ratio={94 / 120}
          px={94}
          cid="consultant-image"
          alt={name}
          cloudinarySrc={imageUrl}
        />
      )}
    </div>
    <Row column={telephone && queryHelper === QueryHelpers.MOBILE}>
      <div className="name">
        <TextNew.Sans.MD
          element={ElementTypes.H2}
          color={AccentColors.ACCENT_PINK}
          localeId="groupSearchConsultantDetails.consultantName"
        />
        <TextNew.Sans.SM cid="consultant-name">{name}</TextNew.Sans.SM>
      </div>
      {telephone && (
        <div className="telephone">
          <TextNew.Sans.MD
            color={AccentColors.ACCENT_PINK}
            localeId="groupSearchConsultantDetails.consultantTelephone"
          />
          <TextNew.Sans.SM cid="consultant-telephone">
            <a href={`tel:${telephone}`}>{telephone}</a>
          </TextNew.Sans.SM>
        </div>
      )}
    </Row>
    {/* When bio is under the visable limit then there is no need to use the accordion component */}
    {biography && biography.length > GROUP_SEARCH_VISABLE_CHARACTER_LIMIT ? (
      <ReadMoreAccordion onLightBackground={onLightBackground} text={biography} closedHeight={48} />
    ) : (
      <TextNew.Sans.SM>{biography}</TextNew.Sans.SM>
    )}
  </div>
);

GroupSearchConsultantDetails.propTypes = consultantShape;

export default withDeviceState()(
  withFunctionalClassName(
    ComponentType.ORGANISM,
    'GroupSearchConsultantDetails',
  )(GroupSearchConsultantDetails),
);
