import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import {
  API_COMPONENT_PUBLIC_FOOTER,
  API_COMPONENT_PUBLIC_MAGAZINE_UPSELL,
} from 'common/src/app/data/apiComponents';
import { COMPONENT } from 'common/src/app/data/entityTypes';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';
import UkPublicFooter from './UkPublicFooter';

const emptyArray = [];

const mapStateToProps = state => ({
  magazineImage: contentComponentSelector(state, API_COMPONENT_PUBLIC_MAGAZINE_UPSELL, 'image'),
  host: state.config.environmentConfig.web.public.host,
  footerSocialLinks:
    (state.entities[COMPONENT] &&
      state.entities[COMPONENT][API_COMPONENT_PUBLIC_FOOTER] &&
      state.entities[COMPONENT][API_COMPONENT_PUBLIC_FOOTER].socialLinks.menuContainer) ||
    emptyArray,
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(getComponent(API_COMPONENT_PUBLIC_MAGAZINE_UPSELL)),
      dispatch(getComponent(API_COMPONENT_PUBLIC_FOOTER)),
    ]),
});

export default compose(addInitAction, connected)(UkPublicFooter);
