import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { API_CONSULTANT_HOME_HERO_IMAGE } from 'common/src/app/data/apiComponents';
import {
  contentComponentSelector,
  staticPageSelector,
} from 'common/src/app/selectors/publicSelectors';
import {
  GET_STATIC_CONTENT,
  getStaticContent,
} from 'common/src/app/actions/resources/staticContentActions';
import { API_STATIC_PUBLIC_CONSULTANT_HOME } from 'common/src/app/data/apiStatics';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import CallOutBlock from '../../atoms/CallOutBlock';
import ConsultantLanding from './ConsultantLanding';

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT);

const mapStateToProps = state => ({
  heroImage: contentComponentSelector(state, API_CONSULTANT_HOME_HERO_IMAGE, 'image'),
  loading: pageLoading(state),
  pageData: staticPageSelector(state, API_STATIC_PUBLIC_CONSULTANT_HOME),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  clientOnly: ({}, dispatch) => dispatch(getStaticContent(API_STATIC_PUBLIC_CONSULTANT_HOME, true)),
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(prepareComponent(CallOutBlock, { showBackground: true })),
      dispatch(getComponent(API_CONSULTANT_HOME_HERO_IMAGE)),
    ]),
});

export default compose(addInitAction, connected)(ConsultantLanding);
