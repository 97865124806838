import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';
import { API_STATIC_PUBLIC_MAGAZINE } from 'common/src/app/data/apiStatics';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { scrollToElement } from 'common/src/app/actions/scrollActions';
import AdvertiseWithUs from '../../molecules/AdvertiseWithUs';

import Magazine from './Magazine';

const mapStateToProps = (state, { location: { hash } }) => ({
  hash,
  headerHeight: state.view.components.header?.height || 0,
  page: staticPageSelector(state, API_STATIC_PUBLIC_MAGAZINE) || [],
});

const mapDispatchToProps = dispatch => ({
  scrollToElement: (...args) => dispatch(scrollToElement(...args)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(prepareComponent(AdvertiseWithUs)),
      dispatch(getStaticContent(API_STATIC_PUBLIC_MAGAZINE, true)),
    ]),
});

export default compose(addInitAction, connected)(Magazine);
