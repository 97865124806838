export default {
  CONFIRM: 'ConfirmModal',
  CONTENT: 'ContentModal',
  GALLERY: 'GalleryModal',
  VIDEO: 'VideoModal',
  RECIPE_DETAIL: 'RecipeDetailModal',
  SYNS_ESTIMATOR_FOOD_CONFIRM_MODAL: 'SynsEstimatorFoodConfirmationModal',
  PUBLIC_GROUP_EXPLAINED_MODAL: 'GroupSessionExplained',
  INSTAGRAM_IMAGE_MODAL: 'InstagramImageModal',
  REGISTRATION_ERROR: 'RegistrationError',
  WEIGHT_PROMPT_MODAL: 'WeightPromptModal',
  EXISTING_LEGACY_ACCOUNT_PROMPT_MODAL: 'ExistingLegacyAccountPromptModal',
  SUCCESSFULLY_REGISTERED_FREE2GO: 'SuccessfullyRegisteredFree2Go',
  SKIP_GROUP_CONFIRM_MODAL: 'SkipGroupConfirmModal',
  TARGET_WEIGHT_HELP_MODAL: 'TargetWeightHelpModal',
  SHIPPING_ADDRESS_MODAL: 'ShippingAddressModal',
  PERSONAL_DETAILS_MODAL: 'PersonalDetailsModal',
  BILLING_ADDRESS_MODAL: 'BillingAddressModal',
  ORDER_CONFIRMATION_MODAL: 'OrderConfirmationModal',
};
