/* global 'page','Science' */
import React from 'react';
import { compose } from 'redux';
import Link from 'react-router/lib/Link';
import PropTypes from 'prop-types';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Wrapper from 'components/atoms/Wrapper';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import Row from 'components/atoms/Row';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';
import TextNew, { AccentColors, Colors, ElementTypes, FontWeight } from 'components/atoms/TextNew';
import LocaleMessage from 'components/atoms/LocaleMessage';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import errorBoundary from 'components/hoc/ErrorBoundary';

import './science.scss';

const Science = ({ page, headerImage }, context, className) => {
  const content = [IconName.HAMBURGER, IconName.FOOTPRINTS, IconName.SUPPORT];

  const list = {
    left: {
      listLength: 3,
    },
    right: {
      listLength: 3,
    },
  };

  const bodyMagicImage = page?.nestedContent?.[0]?.image;

  const onlineImage = page?.nestedContent?.[1]?.image;

  return (
    <div className={className}>
      <>
        <HeroBarPublic image={headerImage}>
          <TextNew.Serif.MD
            element={ElementTypes.H1}
            cid="hero-bar-public-serif-heading"
            localeId="science.heading.h1"
            weight={FontWeight.BOLD}
          />
          <TextNew.Script.SM
            cid="hero-bar-public-script-heading"
            color={Colors.BRAND}
            localeId="science.heading.h2"
            element={ElementTypes.H2}
          />
          <TextNew.Serif.XS
            localeId="science.heading.introduction"
            cid="hero-bar-public-introduction"
          />
        </HeroBarPublic>

        <div className="science-intro-block">
          <Wrapper.SM padding="sm">
            <div className="science-intro-content">
              <TextNew.Serif.XS
                color={AccentColors.ACCENT_PURPLE}
                localeId="science.philosophy.paragraph"
              />
            </div>
          </Wrapper.SM>
        </div>

        <div className="pillars-block">
          <Wrapper padding="lg" width="lg">
            <div className="pillars-title">
              <TextNew.Script.SM
                color={AccentColors.ACCENT_PURPLE}
                localeId="science.pillars.title"
              />
              <TextNew.Sans.LG
                cid="pillars-block-subtitle"
                color={AccentColors.ACCENT_PURPLE}
                weight={FontWeight.LIGHT}
                localeId="science.pillars.subtitle"
              />
            </div>
            <Row className="pillar" alignstart spacearound>
              {content.map((item, index) => (
                <Row key={`${item}-${index}`} column justifycenter>
                  <Icon name={item} width={60} height={60} />
                  <TextNew.Serif.SM
                    color={AccentColors.ACCENT_PURPLE}
                    localeId={`science.pillars.pillar${index}.title`}
                  />
                  <TextNew.Serif.XS
                    cid="pillar-intro"
                    color={Colors.DARK_TONE}
                    localeId={`science.pillars.pillar${index}.description`}
                  />
                </Row>
              ))}
            </Row>
          </Wrapper>
        </div>

        <div className="food-optimising-block">
          <Wrapper width="md" padding="xl">
            <div className="food-content">
              <TextNew.Serif.MD
                cid="food-title"
                weight={FontWeight.BOLD}
                color={AccentColors.ACCENT_PURPLE}
                localeId="science.foodOptimising.title"
              />
              <TextNew.Serif.XS localeId="science.foodOptimising.para" />
            </div>
          </Wrapper>

          <Wrapper width="lg" padding="sm">
            <TextNew.Serif.SM
              weight={FontWeight.BOLD}
              localeId="science.foodOptimising.componentstitle"
            />
            <Row className="components" alignstart spacearound>
              {content.map((item, index) => (
                <Row className="component" key={`${item}-${index}`} column justifycenter>
                  <TextNew.Script.SM
                    color={Colors.PRIMARY_LIGHT}
                    localeId={`science.foodOptimising.components.component${index}.title`}
                  />
                  <TextNew.Serif.XS
                    color={Colors.PRIMARY_LIGHT}
                    localeId={`science.foodOptimising.components.component${index}.info`}
                  />
                </Row>
              ))}
            </Row>
          </Wrapper>
        </div>

        <div className="body-magic-block">
          <Wrapper width="md" padding="xl">
            <div className="body-magic-content">
              <TextNew.Serif.MD
                cid="body-magic-title"
                weight={FontWeight.BOLD}
                color={AccentColors.ACCENT_PURPLE}
                localeId="science.bodyMagic.title"
              />
              <TextNew.Serif.XS localeId="science.bodyMagic.para" />
            </div>
          </Wrapper>
        </div>

        <div className="color-block body-magic">
          <Row className="article-detail" column grow>
            <div className="body-magic-article-title">
              <h2>
                <TextNew.PrimaryElegant
                  color={Colors.PRIMARY_LIGHT}
                  localeId={`science.bodyMagic.block.subtitlePartOne`}
                  cid="body-magic-title-part-one"
                  element={ElementTypes.SPAN}
                />
                <TextNew.Script.SM
                  color={Colors.PRIMARY_LIGHT}
                  element={ElementTypes.SPAN}
                  cid="body-magic-title-part-title"
                  localeId={`science.bodyMagic.block.title`}
                />
                <TextNew.PrimaryElegant
                  color={Colors.PRIMARY_LIGHT}
                  localeId={`science.bodyMagic.block.subtitleComma`}
                  cid="body-magic-title-part-comma"
                  element={ElementTypes.SPAN}
                />
                <TextNew.PrimaryElegant
                  color={Colors.PRIMARY_LIGHT}
                  localeId={`science.bodyMagic.block.subtitlePartTwo`}
                  cid="body-magic-title-part-two"
                  element={ElementTypes.SPAN}
                />
              </h2>
            </div>

            <div className="article-list">
              <ul>
                {Object.keys(list).map(col => (
                  <div className={col} key={col}>
                    {Array.from(Array(list[col].listLength).keys()).map(listItem => (
                      <TextNew.PrimaryElegant
                        key={listItem}
                        cid="list-item"
                        weight={FontWeight.LIGHT}
                        color={Colors.PRIMARY_LIGHT}
                        element={ElementTypes.LI}
                      >
                        <Icon name={IconName.CHECKMARK} size={32} />
                        <LocaleMessage
                          id={`science.bodyMagic.block.${col}.${listItem}`}
                          params={{
                            YOU: (
                              <TextNew.Sans.MD
                                weight={FontWeight.BOLD}
                                color={Colors.PRIMARY_LIGHT}
                                localeId={'science.bodyMagic.block.you'}
                                element={ElementTypes.SPAN}
                              />
                            ),
                            YOUR: (
                              <TextNew.Sans.MD
                                weight={FontWeight.BOLD}
                                color={Colors.PRIMARY_LIGHT}
                                localeId={'science.bodyMagic.block.your'}
                                element={ElementTypes.SPAN}
                              />
                            ),
                          }}
                        />
                      </TextNew.PrimaryElegant>
                    ))}
                  </div>
                ))}
              </ul>
            </div>
          </Row>
          <BackgroundImageWrapper image={bodyMagicImage} />
        </div>

        <div className="group-block">
          <Wrapper width="md" padding="xl">
            <div className="group-content">
              <TextNew.Serif.MD
                cid="group-title"
                weight={FontWeight.BOLD}
                color={AccentColors.ACCENT_PURPLE}
                localeId="science.group.title"
              />
              <TextNew.Serif.XS localeId="science.group.para" />
            </div>
          </Wrapper>
        </div>

        <div className="color-block online">
          <Row className="article-detail" column grow>
            <TextNew.Script.SM
              color={Colors.PRIMARY_LIGHT}
              element={ElementTypes.H2}
              cid="color-block-title"
              localeId="science.online.title"
            />
            <TextNew.Serif.XS color={Colors.PRIMARY_LIGHT} localeId="science.online.para" />
          </Row>
          {onlineImage && <ResponsiveImage src={onlineImage.src} alt={onlineImage.alt} vw={100} />}
        </div>

        <div className="know-more-block">
          <Wrapper width="md" padding="xxl">
            <TextNew.Serif.MD color={AccentColors.ACCENT_PURPLE} localeId="science.bottom.title" />
            <TextNew.Script.LG
              cid="group-title"
              color={AccentColors.ACCENT_PURPLE}
              localeId="science.bottom.titleTwo"
            />
            <TextNew.Serif.XS
              cid="find-out"
              color={AccentColors.ACCENT_PURPLE}
              localeId="science.bottom.info"
            />
            <Link href={PagesExternal.HEALTH_PROFESSIONALS}>
              <TextNew.Sans.LG
                weight={FontWeight.BOLD}
                color={AccentColors.ACCENT_PURPLE}
                localeId="science.bottom.link"
              />
            </Link>
          </Wrapper>
        </div>
      </>
    </div>
  );
};

Science.propTypes = {
  page: PropTypes.object,
  headerImage: PropTypes.shape({
    ...imageShape,
  }),
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName('page','Science'),
)(Science);
