import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import withDeviceState, { DeviceState } from 'common/src/app/util/device-state/withDeviceState';
import TextNew, { FontSizes, ElementTypes, AccentColors } from 'components/atoms/TextNew';
import GroupOnlineSignUp from 'components/molecules/GroupOnlineSignUp';
import Wrapper from 'components/atoms/Wrapper';
import InstagramTrending from 'components/molecules/InstagramTrending';
import HomepageSecondaryShowcase from 'components/molecules/HomepageSecondaryShowcase';
import Promotion from 'components/organisms/Promotion';
import TypicalDayMealPlan from 'components/organisms/TypicalDayMealPlan';
import errorBoundary from 'components/hoc/ErrorBoundary';
import StructuredDataGenerator, { types } from 'components/atoms/StructuredDataGenerator';
import NewsletterSignUp from '../../molecules/NewsletterSignUp';
import colorCarouselPropTypes from './components/molecules/ColorCarousel/colorCarouselPropTypes';
import ColorCarousel from './components/molecules/ColorCarousel';
import HomepageCampaignBlock from '../../organisms/HomepageCampaignBlock';

import './home.scss';

const HomeContent = ({ carouselContent, icelandBackground, deviceState, className }) => (
  <>
    <section className={className}>
      <section className="intro-block">
        <Wrapper element={ElementTypes.HEADER} padding="sm" width="sm">
          <h2>
            <TextNew.Confident
              color={AccentColors.ACCENT_PURPLE}
              cid="title-intro"
              localeId="ukHome.main.greeting"
              element={ElementTypes.SPAN}
            />
            <TextNew.Script
              cid="script"
              size={deviceState >= DeviceState.MD ? FontSizes.LG : FontSizes.SM}
              color={AccentColors.ACCENT_PURPLE}
              localeId="ukHome.main.title"
              element={ElementTypes.SPAN}
            />
          </h2>
          <TextNew.Strong
            cid="intro"
            color={AccentColors.ACCENT_PURPLE}
            localeId="ukHome.main.intro"
          />
        </Wrapper>
      </section>
      <ColorCarousel carouselContent={carouselContent} />

      <GroupOnlineSignUp />
      <NewsletterSignUp />
      <TypicalDayMealPlan showTitle showSearchSubtitle={false} />
      <Promotion
        deviceState={deviceState}
        icelandBackground={icelandBackground}
        switchOrderMobile
      />
      <InstagramTrending />
      <StructuredDataGenerator type={types.ORGANIZATION} />
    </section>
  </>
);

HomeContent.propTypes = {
  carouselContent: colorCarouselPropTypes,
  icelandBackground: PropTypes.object,
  className: PropTypes.string,
  deviceState: PropTypes.number,
};

const Home = (
  { homepageSecondaryShowcase, icelandBackground, deviceState, pageContent = {} },
  context,
  className,
) => {
  const carouselContent = pageContent?.content?.[0]?.content;

  return (
    <>
      <HomepageCampaignBlock />
      <HomepageSecondaryShowcase {...homepageSecondaryShowcase} />
      <HomeContent
        carouselContent={carouselContent}
        icelandBackground={icelandBackground}
        className={className}
        deviceState={deviceState}
      />
    </>
  );
};

Home.propTypes = {
  deviceState: PropTypes.number,
  pageContent: PropTypes.object,
  homepageSecondaryShowcase: PropTypes.shape({
    buttonText: PropTypes.shape({
      _type: PropTypes.string,
      text: PropTypes.string,
    }),
    richText: PropTypes.shape({
      _type: PropTypes.string,
      text: PropTypes.string,
      title: PropTypes.string,
    }),
    image: PropTypes.shape({
      _type: PropTypes.string,
      alt: PropTypes.string,
      caption: PropTypes.string,
      focalPoint: PropTypes.shape({
        left: PropTypes.number,
        top: PropTypes.number,
      }),
      id: PropTypes.string,
      src: PropTypes.string,
    }),
  }),
  icelandBackground: PropTypes.object,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName(ComponentType.PAGE, 'Home'),
)(Home);
