import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import TextNew, { Colors } from '../../atoms/TextNew';
import IconLabel from '../../molecules/IconLabel';
import IconButton from '../../molecules/IconButton';
import './filter-sidebar.scss';

const FilterSidebar = (
  { children, onClose, disclaimerLocale, filterLabel, bottomContent },
  { getMessage },
  className,
) => (
  // DF-225: Content Restructuring and Sheet Component Enhancement
  <div className={className}>
    <div className="filter-content-wrapper">
      <div className="filter-header">
        <IconLabel dataTestid="filter-label" icon={IconName.FILTER} label={filterLabel} />
        <IconButton
          buttonLabel={getMessage('general.cta.close')}
          dataTestid="on-close"
          icon={IconName.CROSS}
          onClick={onClose}
        />
      </div>
      {children}
    </div>
    {disclaimerLocale && (
      <TextNew.Sans.XS
        dataTestid="disclaimer"
        color={Colors.MID_TONE}
        localeId={disclaimerLocale}
      />
    )}
    {bottomContent && bottomContent}
  </div>
);

export const FilterSidebarProps = FilterSidebar;

FilterSidebar.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

FilterSidebar.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  disclaimerLocale: PropTypes.string,
  filterLabel: PropTypes.string,
  bottomContent: PropTypes.node,
};

export default withFunctionalClassName(ComponentType.ORGANISM, 'FilterSidebar')(FilterSidebar);
