import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';

import { DeviceState } from 'common/src/app/data/MediaQueries';
import Colors from 'common/src/app/data/enum/Colors';

import errorBoundary from '../../hoc/ErrorBoundary';

import AffixContainer from '../../molecules/AffixContainer';
import DeepLinkHandler from '../../organisms/DeepLinkHandler';
import StaticContentMenu from '../../molecules/StaticContentMenu';
import ActiveMobileTab from '../../atoms/ActiveMobileTab';
import Loader from '../../atoms/Loader';
import Header from '../../organisms/Header';
import SlimmingWorldLogo from '../../atoms/SlimmingWorldLogo';
import Wrapper from '../../atoms/Wrapper';
import Row from '../../atoms/Row';
import PolicyDescription from './components/molecules/PolicyDescription';

import './policy-content.scss';

const PolicyContent = (
  { page, deviceState, publicHost, route: { showHeader }, location, isInitializing },
  _context,
  className,
  dataTestid,
) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(
    () => setShowLoader(isInitializing || !page || !location?.pathname?.includes(page?.slug)),
    [isInitializing, page, location],
  );

  useEffect(() => {
    if (!showLoader && typeof window.OneTrust?.initializeCookiePolicyHtml === 'function') {
      // Running OneTrust code in a setTimeout in order to put it to the back of the execution queue
      // and ensure that the render has chance to complete
      const timeoutId = setTimeout(() => window.OneTrust?.initializeCookiePolicyHtml(), 0);
      return () => clearTimeout(timeoutId);
    }
    return undefined;
  }, [showLoader]);

  const handleOpenMenu = () => setIsMenuOpen(true);

  const handleCloseMenu = () => setIsMenuOpen(false);

  const sidebarType =
    deviceState >= DeviceState.XL ? (
      <AffixContainer>
        <DeepLinkHandler>
          <StaticContentMenu />
        </DeepLinkHandler>
      </AffixContainer>
    ) : (
      <DeepLinkHandler animatedScroll={false} hasFixedNavigation>
        <ActiveMobileTab openMenu={handleOpenMenu} />
        <StaticContentMenu closeMenu={handleCloseMenu} />
      </DeepLinkHandler>
    );

  const { title, description, nestedContent } = page ?? {};

  return (
    <div className={className} data-testid={dataTestid} key="loader">
      {showLoader ? (
        <Loader />
      ) : (
        <>
          {showHeader && <Header center={<SlimmingWorldLogo link={publicHost} />} />}
          <Wrapper
            className={classNames(isMenuOpen ? 'show-menu' : '')}
            width="lg"
            background={Colors.PRIMARY_LIGHT}
          >
            <Row>
              <div className="sidebar">{sidebarType}</div>
              <PolicyDescription
                title={title}
                description={description}
                nestedContent={nestedContent}
              />
            </Row>
          </Wrapper>
        </>
      )}
    </div>
  );
};

PolicyContent.propTypes = {
  isInitializing: PropTypes.bool,
  deviceState: PropTypes.number,
  location: PropTypes.object,
  publicHost: PropTypes.string.isRequired,
  route: PropTypes.shape({
    showHeader: PropTypes.bool,
  }),
  page: PropTypes.shape({
    nestedContent: PropTypes.array,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName(ComponentType.PAGE, 'PolicyContent'),
)(PolicyContent);
