/* global 'atom','ButtonBlock' */
import PropTypes from 'prop-types';

import React from 'react';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import trackingShape from 'common/src/app/util/proptypes/apiEntities/tracking';
import LinkTracker from '../../../../../atoms/LinkTracker';
import Button from '../../../../../atoms/Button';

const ButtonBlock = ({ buttonText, link, trackingData }, context, className) => (
  <div className={className}>
    <LinkTracker
      meta={
        trackingData
          ? {
              category: trackingData.category,
              contentView: trackingData.category,
              [trackingData.action]: trackingData.label,
            }
          : {}
      }
    >
      <Button to={link}>{buttonText}</Button>
    </LinkTracker>
  </div>
);

ButtonBlock.propTypes = {
  buttonText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  trackingData: PropTypes.shape(trackingShape),
};

export default withFunctionalClassName('atom','ButtonBlock')(ButtonBlock);
