import React from 'react';
import PropTypes from 'prop-types';
import LocaleMessage from '../../../../../../LocaleMessage';
import InputNew from '../../../../atoms/InputNew';
import TextNew from '../../../../../../TextNew';
import WeightInputLabel from '../WeightInputLabel';

const WeightInputMetric = ({ abbreviate, disabled, values, ...restProps }) => (
  <>
    <InputNew
      data-testid="kilos"
      disabled={disabled}
      descriptor="static"
      name="kilos"
      type="number"
      min="0"
      max="318"
      placeholder="000"
      value={values?.kilos || ''}
      label={
        <>
          <span className="screen-reader">
            <LocaleMessage id="general.measureUnit.weight.kilogram.labelKilos" />
          </span>
        </>
      }
      disabled={disabled}
      {...restProps}
    />
    <TextNew.Formal ariaHidden cid="dot">
      .
    </TextNew.Formal>
    <InputNew
      data-testid="grams"
      descriptor="static"
      name="grams"
      type="number"
      min="0"
      max="99"
      placeholder="00"
      value={values?.grams || ''}
      label={
        <>
          <span aria-hidden="true">
            <WeightInputLabel weightUnit="kilogram" abbreviate={abbreviate} />
          </span>
          <span className="screen-reader">
            <LocaleMessage id="general.measureUnit.weight.kilogram.labelGrams" />
          </span>
        </>
      }
      disabled={disabled}
      {...restProps}
    />
  </>
);

WeightInputMetric.defaultProps = {
  abbreviate: true,
};

WeightInputMetric.propTypes = {
  abbreviate: PropTypes.bool,
  disabled: PropTypes.bool,
  values: PropTypes.object,
};

export default WeightInputMetric;
