import React from 'react';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, { Colors, FontWeight } from 'components/atoms/TextNew';
import Pages from 'common/src/app/data/enum/Pages';
import './free-festive-planner-offer-roundel.scss';

const FreeFestivePlannerOfferRoundel = ({ staticSize }, context, className, dataTestid) => (
  <Link
    data-testid={dataTestid}
    to={Pages.UK_PUBLIC_OFFERS}
    className={classNames(className, { 'static-size': staticSize })}
  >
    <TextNew.Serif.XL
      cid="offer-free"
      color={Colors.LIGHT_TONE}
      localeId="freeFestivePlannerOfferRoundel.free"
    />
    <TextNew.Serif.SM
      cid="offer-planner"
      weight={FontWeight.LIGHT}
      color={Colors.LIGHT_TONE}
      localeId="freeFestivePlannerOfferRoundel.planner"
    />
    <TextNew.Sans.XS
      cid="offer-until"
      color={Colors.LIGHT_TONE}
      localeId="freeFestivePlannerOfferRoundel.until"
    />
  </Link>
);

FreeFestivePlannerOfferRoundel.propTypes = {
  staticSize: PropTypes.bool,
};

export default withFunctionalClassName(
  ComponentType.ATOM,
  'FreeFestivePlannerOfferRoundel',
)(FreeFestivePlannerOfferRoundel);
