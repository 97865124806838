/**
 * Converts from kilograms to object { kilos, grams }
 * @param {number} kg A number that contains an integer representing
 * the amount of kilograms
 *
 * @returns {object} The resulting object value in { kilos, grams }
 */
const decomposeWeightMetric = kg => {
  if (typeof kg === 'undefined') {
    return { kilos: '', grams: '' };
  }
  const parsedKg = kg || 0;

  const kilos = Math.floor(parsedKg);
  const grams = Math.round((parsedKg % 1) * 100);

  return { kilos, grams };
};

export default decomposeWeightMetric;
