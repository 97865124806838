import compose from 'redux/lib/compose';
import { withInitAction, prepareComponents } from '@mediamonks/react-redux-component-init';
import BetterHealthTile from '../../molecules/BetterHealthTile';
import IcelandPromotion from '../../molecules/IcelandPromotion';
import Promotion from './Promotion';

const addInitAction = withInitAction(
  {
    prepared: ({}, dispatch) => dispatch(prepareComponents([BetterHealthTile, IcelandPromotion])),
  },
  { getPrepareKey: componentId => componentId },
);

export default compose(addInitAction)(Promotion);
