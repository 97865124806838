import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';
import { API_COMPONENT_TYPICAL_DAY_MEAL_PLAN } from 'common/src/app/data/apiComponents';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { withInitAction } from '@mediamonks/react-redux-component-init';

import TypicalDayMealPlan from './TypicalDayMealPlan';

const mapStateToProps = state => ({
  typicalDayMealPlan: contentComponentSelector(state, API_COMPONENT_TYPICAL_DAY_MEAL_PLAN),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(API_COMPONENT_TYPICAL_DAY_MEAL_PLAN)),
});

export default compose(addInitAction, connected)(TypicalDayMealPlan);
