import { Category, DataLayerKeys } from '../../data/enum/Tracking';
import { RECIPE, FEATURE, SUCCESS_STORY, POST } from '../../data/entityTypes';

export const entityNameMap = {
  [RECIPE]: DataLayerKeys.NAME_RECIPES,
  [FEATURE]: DataLayerKeys.NAME_FEATURES,
  [SUCCESS_STORY]: DataLayerKeys.NAME_SUCCESS_STORIES,
  [POST]: DataLayerKeys.NAME_COMMUNITY,
};

export const entityCategoryMap = {
  [RECIPE]: Category.RECIPES,
  [FEATURE]: Category.FEATURES,
  [SUCCESS_STORY]: Category.SUCCESS_STORIES,
  [POST]: Category.COMMUNITY,
};
