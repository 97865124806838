import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { Colors, ElementTypes, FontWeight } from 'components/atoms/TextNew';
import NavigationController from 'components/atoms/NavigationController';
import { NavViews } from 'common/src/app/data/enum/NavViews';
import Pages from 'common/src/app/data/enum/Pages';
import NearestGroupSearchBar from 'components/organisms/NearestGroupSearchBar';
import DataTestidOverides from 'common/src/app/data/enum/DataTestidOverides';
import CountrySwitch from 'components/molecules/UkPublicNavigation/components/molecules/CountrySwitch';

import './empty-search-query.scss';

const EmptySearchQuery = ({ navHidden }, { getMessage }, className) => (
  <div className={className}>
    {navHidden && (
      <CountrySwitch
        path={`${Pages.UK_PUBLIC_NEAREST_GROUP_LANDING}?persistHideNav=true`}
        getMessage={getMessage}
      />
    )}
    <div className="intro" data-testid={DataTestidOverides.GROUP_SEARCH_EMPTY_QUERY_TITLE}>
      <TextNew.Serif.MD
        cid="title-emphasis"
        element={ElementTypes.SPAN}
        localeId="emptySearchQuery.titleEmphasis"
        weight={FontWeight.BOLD}
      />
      <TextNew.Script.SM
        color={Colors.BRAND}
        cid="title-outro"
        localeId="emptySearchQuery.titleOutro"
      />
    </div>
    <div className="details">
      <NearestGroupSearchBar
        dataTestid={DataTestidOverides.GROUP_LANDING_SEARCH_BOX}
        resultsPositionedRelative
        addH2Tag
      />
    </div>
    <NavigationController hideUiOptions={[NavViews.HIDE_GROUP_SEARCH_BAR]} />
  </div>
);

EmptySearchQuery.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

EmptySearchQuery.propTypes = {
  navHidden: PropTypes.bool,
};

export default withFunctionalClassName(
  ComponentType.ORGANISM,
  'EmptySearchQuery',
)(EmptySearchQuery);
