import React from 'react';
import PropTypes from 'prop-types';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import Icon from 'components/atoms/Icon';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import TextNew, { FontWeight, ElementTypes, Colors } from '../../atoms/TextNew';
import './membership-package-card.scss';

const localeName = 'membershipPackage.';

const MembershipPackageCard = ({ title, benefits, badge, description }) => (
  <div className={functionalComponentClassName(ComponentType.MOLECULE, 'MembershipPackageCard')}>
    <Icon name={badge} />

    <div className="package-name-container">
      <TextNew.Serif.SM element={ElementTypes.H3} weight={FontWeight.BOLD} cid="package-name">
        {title}
      </TextNew.Serif.SM>
    </div>

    <div className="package-benefits-container">
      <TextNew.Sans.LG cid="description">{description}</TextNew.Sans.LG>
      {benefits?.length && (
        <>
          <TextNew.Script.XS color={Colors.BRAND} localeId={`${localeName}plus`} />
          <ul>
            {benefits.map((benefit, i) => (
              <li key={`${i}-benefit`}>
                <RichTextBlock text={benefit.featureItem} />
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  </div>
);

MembershipPackageCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  benefits: PropTypes.arrayOf(PropTypes.shape({ featureItem: PropTypes.string })),
  badge: PropTypes.oneOf([
    IconName.MEMBERSHIP_BADGE_BRONZE,
    IconName.MEMBERSHIP_BADGE_SILVER,
    IconName.MEMBERSHIP_BADGE_GOLD,
  ]).isRequired,
};

MembershipPackageCard.defaultProps = {
  badge: undefined,
  benefits: [],
};

MembershipPackageCard.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default MembershipPackageCard;
