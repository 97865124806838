import PropTypes from 'prop-types';

const linkPropTypes = {
  caption: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isInternal: PropTypes.bool,
  link: PropTypes.string.isRequired,
  newWindow: PropTypes.string.isRequired,
  _type: PropTypes.string,
};

export default linkPropTypes;
