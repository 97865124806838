import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Wrapper from '../../atoms/Wrapper';
import IcelandPromotion from '../../molecules/IcelandPromotion';
import BetterHealthTile from '../../molecules/BetterHealthTile';

import './promotion.scss';

/**
 * Promotional block used to upsell cetain products and pages to see usage go
 * to Home.js
 */

// TODO SWPUB-371 Rename these components based off of a wider discussion

const Promotion = ({ icelandBackground, switchOrderMobile, deviceState }, context, className) => (
  <section className={className}>
    <Wrapper width="lg">
      {deviceState <= DeviceState.MD && switchOrderMobile ? (
        <>
          <IcelandPromotion icelandBackground={icelandBackground} deviceState={deviceState} />
          <BetterHealthTile />
        </>
      ) : (
        <>
          <BetterHealthTile />
          <IcelandPromotion icelandBackground={icelandBackground} deviceState={deviceState} />
        </>
      )}
    </Wrapper>
  </section>
);

Promotion.propTypes = {
  /* Background of the Iceland promotional block */
  icelandBackground: PropTypes.object,
  /* On mobile the first item in the dom will display second */
  switchOrderMobile: PropTypes.bool,
  deviceState: PropTypes.number,
};

const PromotionMemo = memo(withFunctionalClassName(ComponentType.ORGANISM, 'Promotion')(Promotion));
PromotionMemo.displayName = 'Promotion';

export default PromotionMemo;
