import collectionReducer from './collectionReducer';
import { META_PROP_COLLECTION } from '../../actions/entities/collectionActions';
import { COLLECTION_WILDCARD } from '../../data/collectionIds';

export default (state = {}, action) => {
  if (action.meta && action.meta[META_PROP_COLLECTION]) {
    const collection = action.meta[META_PROP_COLLECTION];

    // handle parameter wildcards
    if (collection.endsWith(COLLECTION_WILDCARD)) {
      const baseCollection = collection.substring(
        0,
        collection.length - COLLECTION_WILDCARD.length,
      );
      const collectionsToUpdate = Object.keys(state).filter(key => key.startsWith(baseCollection));
      const newCollectionState = collectionsToUpdate.map(key =>
        collectionReducer(state[key], action),
      );
      if (collectionsToUpdate.some((key, index) => newCollectionState[index] !== state[key])) {
        const newState = { ...state };
        collectionsToUpdate.forEach((key, index) => (newState[key] = newCollectionState[index]));
        return newState;
      }
      return state;
    }

    // handle normal collection names
    const newCollectionState = collectionReducer(state[collection], action);
    if (newCollectionState !== state[collection]) {
      return {
        ...state,
        [collection]: newCollectionState,
      };
    }
  }

  return state;
};
