/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import classNames from 'classnames';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import IconName from 'common/src/app/data/enum/IconName';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import ResponsiveImage from '../ResponsiveImage';
import Button from '../Button';
import Icon from '../Icon';
import TextNew from '../TextNew';
import CloseButton from '../../molecules/CloseButton';
import MediaType from './MediaType';
import './gallery-modal.scss';

/**
 * The Media Gallery Modal has to ability to render video/images in a modal.
 * Simple pagination is included.
 */
class GalleryModal extends Component {
  state = {
    currentIndex: 0,
  };

  /**
   * Update itemIndex
   * @private
   * @param newIndex pass -1 or +1
   */
  setItemIndex = newIndex => {
    this.setState({
      currentIndex: (this.props.media.length + newIndex) % this.props.media.length,
    });
  };
  /**
   * Shows next media item
   * @public
   */
  nextItem = () => this.setItemIndex(this.state.currentIndex + 1);
  /**
   * Shows previous media item
   * @public
   */
  previousItem = () => this.setItemIndex(this.state.currentIndex - 1);

  render() {
    const { media, closeModal, fullScreen } = this.props;

    return (
      <div
        data-testid={componentClassNameProp(ComponentType.ATOM, this)[' data-testid']}
        className={classNames(componentClassNameProp(ComponentType.ATOM, this).className, {
          'full-screen': fullScreen,
        })}
      >
        <CloseButton onClick={closeModal} />
        <button className="close-area" onClick={closeModal} />
        <div className="gallery-wrapper">
          {media.map((mediaObject, index) =>
            renderMedia(mediaObject, index, this.state.currentIndex),
          )}
        </div>
        {media.length > 1 && (
          <>
            <TextNew.FormalOriginal
              key="slideIndex"
              localeId="galleryModal.slideIndex"
              localeParams={{
                currentSlide: this.state.currentIndex + 1,
                totalSlides: media.length,
              }}
            />
            <div key="navigation" className="navigation-wrapper">
              <Button isTertiary onClick={this.previousItem}>
                <Icon name={IconName.CHEVRON_LARGE} direction={DirectionType.LEFT} />
              </Button>
              <Button isTertiary onClick={this.nextItem}>
                <Icon name={IconName.CHEVRON_LARGE} direction={DirectionType.RIGHT} />
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

const renderMedia = (mediaObject, arrayIndex, stateCurrentIndex) => {
  if (mediaObject.type === MediaType.IMAGE) {
    return wrapMediaElement(
      renderImage(mediaObject.src, mediaObject.alt),
      arrayIndex,
      stateCurrentIndex,
    );
  }
  if (mediaObject.type === MediaType.VIDEO) {
    return wrapMediaElement(renderVideo(mediaObject.src), arrayIndex, stateCurrentIndex);
  }

  return null;
};

const wrapMediaElement = (element, arrayIndex, stateCurrentIndex) => (
  <div
    key={arrayIndex}
    className={`gallery-item${arrayIndex === stateCurrentIndex ? ' is-active' : ''}`}
  >
    {element}
  </div>
);

const renderImage = (src, alt) => <ResponsiveImage src={src} alt={alt} />;
// TODO Implement correct render logic for displaying video content
const renderVideo = src => (
  <div>
    MP4 {src.mp4}
    <br />
    WEBM {src.webm}
  </div>
);

GalleryModal.defaultProp = {
  fullScreen: false,
};

/**
 * PropTypes Shapes are defined here as inlining these would break PropTypes check
 * https://github.com/facebook/react/issues/4553
 */
const imageShape = PropTypes.shape({
  type: PropTypes.oneOf([MediaType.IMAGE]),
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
});

const videoShape = PropTypes.shape({
  type: PropTypes.oneOf([MediaType.VIDEO]),
  src: PropTypes.shape({
    mp4: PropTypes.string.isRequired,
    webm: PropTypes.string.isRequired,
  }).isRequired,
});

GalleryModal.propTypes = {
  /**
   * media Populated array with image/video items.
   */
  media: PropTypes.arrayOf(PropTypes.oneOfType([imageShape, videoShape]).isRequired).isRequired,

  /**
   * Prop is passed by RootModal. Close the modal.
   */
  closeModal: PropTypes.func.isRequired,
  fullScreen: PropTypes.string,
};

export default GalleryModal;
