import { reducer as form } from 'redux-form';
import { initReducer as init } from '@mediamonks/react-redux-component-init';
import { routerReducer as routing } from 'react-router-redux';
import config from './configReducer';
import routeHistory from './routeHistoryReducer';
import asyncReducer from './asyncReducer';
import enhancedForm from '../enhanced-redux-form/reducers';
import entities from './entitiesReducer';
import nonSerializable from './nonSerializableReducer';
import error from './errorReducer';
import performance from './performanceReducer';
import collections from './collectionsReducer';
import tracking from './trackingReducer';
import deviceState from './deviceStateReducer';
import seo from './seoReducer';
import externalApis from './externalApiReducer';
import featureSupport from './featureSupportReducer';

/**
 * Object containing all the default reducers that should be included in the main reducer
 * by every microservice.
 */
export default {
  async: asyncReducer,
  collections,
  config,
  deviceState,
  enhancedForm,
  entities,
  error,
  form,
  init,
  nonSerializable,
  performance,
  routing,
  routeHistory,
  seo,
  tracking,
  externalApis,
  featureSupport,
};
