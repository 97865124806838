/* global 'molecule','UserTestingScrollTo' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Button from 'components/atoms/Button';
import IconName from 'common/src/app/data/enum/IconName';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import Icon from 'components/atoms/Icon';
import LocaleMessage from 'components/atoms/LocaleMessage';

import './user-testing-scroll-to.scss';

const ScrollButton = ({ reference, icon, iconWidth, locale, scrollToElement }) => {
  const handleClick = ref => () => {
    scrollToElement(ref.current, 0, false);
  };

  return (
    <Button isGhostDark cid="scroll-to-button" onClick={handleClick(reference)}>
      <Icon width={iconWidth} name={icon} />
      <LocaleMessage id={locale} />
      <Icon cid="to-arrow" width={15} direction={DirectionType.LEFT} name={IconName.REPLY} />
    </Button>
  );
};

ScrollButton.propTypes = {
  reference: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  icon: PropTypes.string,
  iconWidth: PropTypes.number,
  locale: PropTypes.string,
  scrollToElement: PropTypes.func,
};

const UserTestingScrollTo = ({ scrollTo, scrollToElement }, context, className) => (
  <div className={className}>
    {scrollTo.map(button => (
      <ScrollButton key={button.locale} {...button} scrollToElement={scrollToElement} />
    ))}
  </div>
);

UserTestingScrollTo.propTypes = {
  scrollTo: PropTypes.array,
  scrollToElement: PropTypes.func,
};

export default withFunctionalClassName('molecule','UserTestingScrollTo')(UserTestingScrollTo);
