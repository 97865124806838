import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { openModal } from 'common/src/app/actions/components/modalActions';
import { trackVideoEvent } from 'common/src/app/actions/trackingActions';
import VideoWithThumbnailBlock from './VideoWithThumbnailBlock';

const connector = connect(
  state => ({
    brightcoveAccountId: state.config.environmentConfig.brightCove.accountId,
    brightcovePlayerId: state.config.environmentConfig.brightCove.playerId,
  }),
  {
    openModal,
    trackVideoEvent,
  },
);

export default compose(connector)(VideoWithThumbnailBlock);
