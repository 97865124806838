import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import TextNew, { Colors, FontSizes, FontWeight, ElementTypes } from 'components/atoms/TextNew';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import Image from 'components/atoms/Image';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';

import './online-signup.scss';

const OnlineSignup = (
  { title, description, descriptionMobile, caption, image, deviceState },
  context,
  className,
  dataTestid,
) => (
  <div className={className} data-testid={dataTestid}>
    <section className="text-column">
      <TextNew.Serif.SM
        element={ElementTypes.H3}
        weight={FontWeight.BOLD}
        dataTestid="title"
        cid="carousel-title"
        size={deviceState >= DeviceState.LG ? FontSizes.SM : FontSizes.XS}
      >
        {title}
      </TextNew.Serif.SM>

      {deviceState <= DeviceState.MD && descriptionMobile ? (
        <RichTextBlock cid="carousel-description" text={descriptionMobile} />
      ) : (
        <RichTextBlock cid="carousel-description" text={description} />
      )}

      <figcaption className="script">
        <Icon width={68} name={IconName.CURVY_ARROW} />
        <TextNew.Script.XS color={Colors.PUBLIC_PURPLE_BASE} dataTestid="caption" cid="script-text">
          {caption}
        </TextNew.Script.XS>
      </figcaption>
    </section>
    <figure>
      {image && (
        <Image
          src={image?.src}
          alt={image?.alt}
          ratio={deviceState >= DeviceState.LG ? 320 / 649 : 160 / 325}
          dataTestid="carousel-image"
        />
      )}
    </figure>
  </div>
);
OnlineSignup.propTypes = {
  deviceState: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  descriptionMobile: PropTypes.string,
  caption: PropTypes.string.isRequired,
  image: PropTypes.shape({
    ...imageShape,
  }).isRequired,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ATOM, 'OnlineSignup')(OnlineSignup),
);
