import { RECIPE, FEATURE, SUCCESS_STORY, COMMENT, POST, PLANNER_ACTIVITY } from '../entityTypes';

const RelatedEntityKind = {
  RECIPE: 0,
  FEATURE: 1,
  SUCCESS_STORY: 2,
  COMMENT: 3,
  POST: 4,
  PLANNER_ACTIVITY: 5,
};

export const getEntityTypeFromRelatedEntityKind = entityKind =>
  [RECIPE, FEATURE, SUCCESS_STORY, COMMENT, POST, PLANNER_ACTIVITY][entityKind];

export default RelatedEntityKind;
