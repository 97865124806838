/* global 'molecule','AddressFormGroup' */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputType from 'common/src/app/data/enum/InputType';
import { addressFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import countries from 'common/src/app/data/countrydata';
import { countryCode } from 'common/src/app/data/enum/Countries';
import InputNew from '../../atoms/InputNew';
import FormGroup from '../../../../../molecules/FormGroup';
import Select from '../../../../Select';
import Counties from './components/atoms/Counties';

/**
 * Address form group component that creates a set of inputs
 */

/** Fields that return a list of options and/or change depending on the
 * country.
 * For example if you selected Ireland as your country the list of
 * countries could change as a result of that
 */
const dynamaticFields = [addressFields.COUNTY, addressFields.COUNTRY, addressFields.STATE];

const AddressFormGroup = (
  { fields, values, defaultCountry, formProps, errorMessage },
  { getMessage },
  className,
) => {
  const [activeCountry, setActiveCountry] = useState(countryCode.GB);

  const postalLocale =
    activeCountry && countryCode && Object.values(countryCode).includes(activeCountry)
      ? `zip.${activeCountry.toLowerCase()}`
      : 'zip.default';

  useEffect(() => {
    /** Set default country so that the postalLacale & country/state input adjusts correctly **/
    setActiveCountry(defaultCountry);
    return () => setActiveCountry(defaultCountry);
  }, [defaultCountry]);

  const getSelect = field => {
    if (field === addressFields.COUNTY || field === addressFields.STATE) {
      return (
        <Counties
          activeCountry={activeCountry}
          county={values[field]}
          formProps={formProps}
          errorMessage={errorMessage}
        />
      );
    }

    return (
      <Select
        stacked
        name={addressFields.COUNTRY}
        options={countries}
        value={values[field]}
        placeholder={getMessage(`addressFormGroup.placeholders.${field}`)}
        suppress
        {...formProps}
      />
    );
  };

  return (
    <FormGroup type="stacked" className={className}>
      {fields &&
        fields.map(field =>
          dynamaticFields.includes(field) ? (
            getSelect(field)
          ) : (
            <Fragment>
              <InputNew
                stacked
                name={field}
                value={values && values[field]}
                type={InputType.TEXT}
                data-hj-suppress={true}
                placeholder={getMessage(
                  `addressFormGroup.placeholders.${
                    field === addressFields.ZIP_OR_POSTAL ? postalLocale : field
                  }`,
                )}
                {...formProps}
              />
              {errorMessage &&
                React.cloneElement(errorMessage, {
                  errorName: getMessage(
                    `addressFormGroup.placeholders.${
                      field === addressFields.ZIP_OR_POSTAL ? postalLocale : field
                    }`,
                  ),
                  name: field,
                })}
            </Fragment>
          ),
        )}
    </FormGroup>
  );
};

AddressFormGroup.defaultProps = {
  fields: [
    addressFields.COUNTRY,
    addressFields.ADDRESS_LINE_1,
    addressFields.ADDRESS_LINE_2,
    addressFields.ADDRESS_LINE_3,
    addressFields.CITY_OR_TOWN,
    addressFields.COUNTY,
    addressFields.ZIP_OR_POSTAL,
  ],
};

AddressFormGroup.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

AddressFormGroup.propTypes = {
  fields: PropTypes.array,
  errorMessage: PropTypes.node,
  values: PropTypes.object,
  formProps: PropTypes.object,
  defaultCountry: PropTypes.string,
};

export default AddressFormGroup;
