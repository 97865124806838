/* global 'atom','SaveUpToOfferRoundel' */
import React from 'react';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Size from 'common/src/app/data/enum/Size';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, { Colors, FontWeight } from 'components/atoms/TextNew';
import Pages from 'common/src/app/data/enum/Pages';
import getPublicPrices from 'common/src/app/util/getPublicPrices';
import './save-up-to-offer-roundel.scss';

const SaveUpToOfferRoundel = ({ size, upTo, moreDetails }, context, className, dataTestid) => (
  <Link
    data-testid={dataTestid}
    to={Pages.UK_PUBLIC_OFFERS}
    className={classNames(className, { lg: size === Size.LG, xl: size === Size.XL, 'up-to': upTo })}
  >
    <TextNew.Serif.MD
      cid="offer-save"
      color={Colors.LIGHT_TONE}
      localeId={`saveUpToOfferRoundel.${upTo ? 'saveUpTo' : 'save'}`}
    />
    <TextNew.Serif.XL
      cid="offer-price"
      color={Colors.LIGHT_TONE}
      localeId="saveUpToOfferRoundel.price"
      localeParams={{
        PRICE: getPublicPrices('group', 'introductoryCharge'),
      }}
    />
    {!upTo && moreDetails && (
      <TextNew.Sans.SM
        cid="offer-more-details"
        weight={FontWeight.LIGHT}
        color={Colors.LIGHT_TONE}
        localeId="saveUpToOfferRoundel.moreDetails"
      />
    )}
  </Link>
);

SaveUpToOfferRoundel.propTypes = {
  size: PropTypes.oneOf(Object.values(Size)),
  upTo: PropTypes.bool,
  moreDetails: PropTypes.bool,
};

SaveUpToOfferRoundel.defaultProps = {
  upTo: false,
  moreDetails: true,
};

export default withFunctionalClassName(
  ComponentType.ATOM,
  'SaveUpToOfferRoundel',
)(SaveUpToOfferRoundel);
