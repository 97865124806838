import PropTypes from 'prop-types';
import InstagramMediaTypes from 'common/src/app/data/enum/InstagramMediaTypes';

const instagramPostShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    mediaUrl: PropTypes.string.isRequired,
    mediaType: PropTypes.oneOf([InstagramMediaTypes.IMAGE, InstagramMediaTypes.VIDEO]).isRequired,
    permalink: PropTypes.string.isRequired,
  }).isRequired,
);

export default instagramPostShape;
