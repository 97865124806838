import React from 'react';
import Link from 'react-router/lib/Link';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';
import ApplicationError from '../ApplicationError';
import Button from '../../atoms/Button';
import TextNew from '../../atoms/TextNew';
import LocaleMessage from '../../atoms/LocaleMessage';
import './not-found.scss';

const NotFound = () => (
  <div className={functionalComponentClassName(ComponentType.PAGE, 'NotFound')}>
    <ApplicationError>
      <TextNew.Loud cid="title" localeId="notFound.title" />
      <TextNew.PrimaryElegant localeId="notFound.description" />
      <TextNew.PrimaryElegant>
        <LocaleMessage id="general.error.contactSupport" />
        &nbsp;
        <Link href={`mailto:${marketConfig.supportEmail}`} target="_blank">
          {marketConfig.supportEmail}
        </Link>
      </TextNew.PrimaryElegant>
      <Button href={Pages.HOME} ripple>
        <LocaleMessage id="notFound.buttonLabel" />
      </Button>
    </ApplicationError>
  </div>
);

export default NotFound;
