import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import TextNew, {
  AccentColors,
  ElementTypes,
  FontWeight,
  TextDecoration,
  Colors,
} from 'components/atoms/TextNew';
import GroupOnlineSignUp from 'components/molecules/GroupOnlineSignUp';
import Wrapper from 'components/atoms/Wrapper';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import Row from 'components/atoms/Row';
import TypicalDayMealPlan from 'components/organisms/TypicalDayMealPlan';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import errorBoundary from 'components/hoc/ErrorBoundary';
import NewsletterSignUp from '../../molecules/NewsletterSignUp';

import './what-can-i-eat.scss';

const WhatCanIEat = ({ pageContent, whatCanIEatBenefitsList }, context, className) => {
  const [header, bodyImage] = pageContent?.nestedContent || [];

  return (
    <div className={className}>
      <HeroBarPublic image={header?.image}>
        <TextNew.Serif.MD
          cid="hero-bar-public-serif-heading"
          localeId="whatCanIEat.heading.h2"
          weight={FontWeight.BOLD}
          element={ElementTypes.H2}
        />
        <TextNew.Script.SM
          color={Colors.BRAND}
          cid="hero-bar-public-script-heading"
          localeId="whatCanIEat.heading.h1"
          element={ElementTypes.H1}
        />
        <TextNew.Serif.XS
          cid="hero-bar-public-introduction"
          localeId="whatCanIEat.heading.introduction"
        />
      </HeroBarPublic>
      <div className="what-eat-title">
        <Wrapper.SM>
          <TextNew.Sans.SM
            weight={FontWeight.BOLD}
            cid="intro-paragraph"
            localeId="whatCanIEat.intro.title"
            color={AccentColors.ACCENT_PURPLE}
          />
          <ul>
            {whatCanIEatBenefitsList.map(localeId => (
              <TextNew.Sans.SM
                weight={FontWeight.LIGHT}
                localeId={localeId}
                color={AccentColors.ACCENT_PURPLE}
                element={ElementTypes.LI}
                key={localeId}
              />
            ))}
          </ul>

          <Link className="science-link" to={Pages.UK_PUBLIC_SCIENCE}>
            <TextNew.Sans.SM
              color={AccentColors.ACCENT_PURPLE}
              decoration={TextDecoration.UNDERLINE}
              localeId="whatCanIEat.intro.link"
            />
          </Link>
        </Wrapper.SM>
      </div>

      <div className="food-optimising-section">
        <Wrapper>
          <TextNew
            cid="food-optimising-title"
            color={AccentColors.ACCENT_PURPLE}
            element={ElementTypes.H2}
          >
            <TextNew.Serif.MD
              color={AccentColors.ACCENT_PURPLE}
              localeId="whatCanIEat.foodOptimising.title.one"
            />

            <TextNew.Script.LG
              color={AccentColors.ACCENT_PURPLE}
              localeId="whatCanIEat.foodOptimising.title.two"
            />
          </TextNew>

          <Row className="tile-wrapper">
            <div className="freefood tile">
              <div className="content">
                <TextNew.Script.XS
                  color={AccentColors.ACCENT_PINK}
                  cid="section-title"
                  localeId="whatCanIEat.foodOptimising.freefood.title"
                />
                <TextNew.Serif.XS localeId="whatCanIEat.foodOptimising.freefood.content" />
              </div>
              {bodyImage && (
                <ResponsiveImage
                  {...bodyImage.image}
                  breakpoints={{
                    other: { px: 320, ratio: 4 / 7 },
                    1279: { px: 200, ratio: 1 / 4 },
                    1023: { vw: 100, ratio: 67 / 25 },
                  }}
                />
              )}
            </div>
            <Row className="syn-hex" column>
              <div className="healthyextra tile">
                <div className="content">
                  <TextNew.Script.XS
                    color={AccentColors.ACCENT_PINK}
                    cid="section-title"
                    localeId="whatCanIEat.foodOptimising.healthyextra.title"
                  />
                  <TextNew.Serif.XS localeId="whatCanIEat.foodOptimising.healthyextra.content" />
                </div>
              </div>
              <div className="syns tile">
                <div className="content">
                  <TextNew.Script.XS
                    color={AccentColors.ACCENT_PINK}
                    cid="section-title"
                    localeId="whatCanIEat.foodOptimising.syns.title"
                  />
                  <TextNew.Serif.XS localeId="whatCanIEat.foodOptimising.syns.content" />
                </div>
              </div>
            </Row>
          </Row>
        </Wrapper>
      </div>
      {/* TODO SWPUB-382 Align typicalDay */}
      <TypicalDayMealPlan
        subTitle={
          <div className="typicalday-title">
            <TextNew.Script.SM
              color={AccentColors.ACCENT_PURPLE}
              localeId="whatCanIEat.typicalDay.title.one"
            />
            <TextNew.Serif.XS
              color={AccentColors.ACCENT_PURPLE}
              localeId="whatCanIEat.typicalDay.title.two"
            />
          </div>
        }
      />
      <GroupOnlineSignUp />
      <NewsletterSignUp />
    </div>
  );
};

WhatCanIEat.propTypes = {
  pageContent: PropTypes.object,
  whatCanIEatBenefitsList: PropTypes.array,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.PAGE, 'WhatCanIEat'),
)(WhatCanIEat);
