import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { openModal } from 'common/src/app/actions/components/modalActions';
import ConsultantBlock from './ConsultantBlock';

const connector = connect(null, {
  openModal,
});

export default compose(connector)(ConsultantBlock);
