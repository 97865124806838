/**
 * Converts values from centimeters to { meters: 0, centimeters: 0 }
 * @param {string} centimeters A string that contains an integer representing
 * the amount of centimeters
 *
 * @returns {object} The resulting number value in { meters: 0, centimeters: 0 }
 */
const decomposeHeightMetric = centimeters => {
  if (typeof centimeters === 'undefined') {
    return { metres: '', centimeters: '' };
  }
  const parsedCentimeters = centimeters || 0;

  const parsedMeters = Math.floor(parsedCentimeters / 100);
  const wholeCentimeters = Math.round(parsedCentimeters % 100);

  return {
    metres: parsedMeters,
    centimeters: wholeCentimeters,
  };
};

export default decomposeHeightMetric;
