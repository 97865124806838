/* global 'molecule','IcelandOurProductsPromo' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Pages from 'common/src/app/data/enum/Pages';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Button from 'components/atoms/Button';

import './iceland-our-products-promo.scss';

const IcelandOurProductsPromo = (
  {
    pageData: { ourProductsImage, ourProductsTitle, ourProductsSubtitle, ourProductsMessage },
    deviceState,
  },
  context,
  className,
) => {
  const getOurProductsImage = () => (
    <div className="image-container">
      <ResponsiveImage {...ourProductsImage} vw={50} />
    </div>
  );

  return (
    <section className={className}>
      <div className="wrapper">
        <div className="content">
          <header className="header">
            <TextNew.Script.MD element={ElementTypes.H2} cid="title">
              {ourProductsTitle}
            </TextNew.Script.MD>

            <TextNew.Confident element={ElementTypes.H3}>{ourProductsSubtitle}</TextNew.Confident>
          </header>

          {deviceState < DeviceState.LG && getOurProductsImage()}

          <TextNew.FormalOriginal cid="description" element={ElementTypes.DIV}>
            <RichTextBlock text={ourProductsMessage} />
          </TextNew.FormalOriginal>

          <Button to={Pages.UK_PUBLIC_ICELAND_OUR_PRODUCTS}>
            <LocaleMessage id="icelandOurProductsPromo.buttonText" />
          </Button>
        </div>

        {deviceState >= DeviceState.LG && getOurProductsImage()}
      </div>
    </section>
  );
};

IcelandOurProductsPromo.propTypes = {
  pageData: PropTypes.object,
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName('molecule','IcelandOurProductsPromo')(IcelandOurProductsPromo),
);
