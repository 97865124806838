import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { API_STATIC_PUBLIC_SLIMMMING_WORLD_APP } from 'common/src/app/data/apiStatics';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';

import SlimmingWorldApp from './SlimmingWorldApp';

const mapStateToProps = () => state => ({
  slimmingWorldAppData: staticPageSelector(state, API_STATIC_PUBLIC_SLIMMMING_WORLD_APP),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    dispatch(getStaticContent(API_STATIC_PUBLIC_SLIMMMING_WORLD_APP, true)),
});

export default compose(addInitAction, connected)(SlimmingWorldApp);
