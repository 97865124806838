import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import { isEmptyInputValue } from 'common/src/app/util/form/baseUtil';
import './static-input.scss';

export const staticInputStyleTypes = {
  STACKED: 'stacked',
  INLINE: 'inline',
};

const StaticInput = ({
  name,
  label,
  id = name,
  labelHidden,
  showIconLockWhenDisabled,
  disabled,
  value,
  descriptor,
  ...props
}) => (
  <div className={classNames('atom-static-input', descriptor)}>
    <label htmlFor={id} className={labelHidden ? 'screen-reader' : 'descriptor-wrapper'}>
      {label}
    </label>
    <input
      id={id}
      name={name}
      disabled={disabled}
      value={!isEmptyInputValue(value) ? value : ''}
      {...props}
    />
    {disabled && showIconLockWhenDisabled ? <Icon name={IconName.LOCK} /> : null}
  </div>
);

StaticInput.defaultProps = {
  styleType: staticInputStyleTypes.INLINE,
};

StaticInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  labelHidden: PropTypes.bool,
  showIconLockWhenDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  descriptor: PropTypes.string,
};

export default withFunctionalClassName(ComponentType.ATOM, 'StaticInput', [
  ({ styleType }) => styleType,
])(StaticInput);
