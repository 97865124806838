/** This component applies flexbox scss allowing the child flex items to span columns correctly.
 * It should only take GridItems as its children.
 * The grid is made up of 12 columns.
 * Variables within GridItemType each represent how much of the grid is filled at
 * different breakpoints. E.g lg=6:  at the large breakpoint 50% of the 12 columns will be filled.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import './grid.scss';

const Grid = ({ children }, context, className, dataTestid) => (
  <div data-testid={dataTestid} className={className}>
    {children}
  </div>
);

Grid.propTypes = {
  children: PropTypes.node,
};

export default withFunctionalClassName(ComponentType.ORGANISM, 'Grid')(Grid);
