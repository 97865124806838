import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import RecipeDetail from 'bundle-loader?lazy&reactHot&name=RecipeDetail!./components/pages/RecipeDetail';
import RecipeLanding from 'bundle-loader?lazy&reactHot&name=RecipeLanding!./components/pages/RecipeLanding';

const routes = (
  <Route>
    <Route path={Pages.UK_PUBLIC_RECIPE_DETAIL} {...getRouteComponentProp(RecipeDetail)} />
    <Route
      title="Slimming World Recipes | Delicious, filling & healthy meals"
      description="Enjoy all the food you love with delicious Slimming World recipes. Choose from hundreds of easy meal ideas and lose weight without ever feeling hungry"
      path={Pages.UK_PUBLIC_RECIPE_SEARCH}
      {...getRouteComponentProp(RecipeLanding)}
    />
  </Route>
);

export default routes;
