const MemberType = {
  ONLINE: 0,
  GROUP: 1,
};

export default MemberType;

export const MemberTypeStrings = {
  ONLINE: 'online',
  GROUP: 'group',
  PUBLIC: 'public',
};

export const MemberTypeStringKeys = {
  0: 'ONLINE',
  1: 'GROUP',
};
