import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState, { DeviceState } from 'common/src/app/util/device-state/withDeviceState';
import factSheetShape from 'common/src/app/util/proptypes/apiEntities/factSheetShape';
import SearchFields from 'common/src/app/data/enum/FieldNames/SearchFields';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import IconName from 'common/src/app/data/enum/IconName';
import { RoleType } from 'common/src/app/data/enum/AccessibilityEnum';
import IconButton from '../../molecules/IconButton';
import TextNew, { Colors } from '../../atoms/TextNew';
import LocaleMessage from '../../atoms/LocaleMessage';
import AllergyInfo from '../../molecules/AllergyInfo';
import LabeledSelect from '../../atoms/LabeledSelect';

import Button from '../../atoms/Button';
import './search-filter-bar.scss';

// TO-DO integrate options for all search pages in this component SWO-10198

const filterToggleButton = (toggleCollapse, totalFilters) => (
  <IconButton
    buttonLabel={<LocaleMessage id="searchFilterBar.labels.open" />}
    cid="toggle-filter-button"
    icon={IconName.FILTER}
    onClick={toggleCollapse}
  >
    <TextNew.Formal localeId="searchFilterBar.labels.filterTitle" color={Colors.DARK_TONE} />

    {totalFilters > 0 && (
      <TextNew.Formal
        cid="total-filters"
        localeId="searchFilterBar.totalFilters"
        localeParams={{ NUM_FILTERS: totalFilters }}
        color={Colors.MID_TONE}
      />
    )}
  </IconButton>
);

const clearFilterbutton = clearFilters => (
  <Button cid="clear-filter-button" onClick={clearFilters} isTertiary>
    <LocaleMessage id="general.cta.clear" />
  </Button>
);

const SearchFilterBar = (
  {
    toggleCollapse,
    clearFilters,
    hasClearFilterButton,
    totalResults,
    totalFilters,
    factSheet,
    noPagination,
    changeOrder,
    onSubmit,
    deviceState,
    isFoodRange,
    sort,
  },
  { getMessage },
  className,
) => (
  <section className={className}>
    <div className="search-filter-bar-container">
      {deviceState >= DeviceState.LG && (
        <div className="filter-buttons">
          {filterToggleButton(toggleCollapse, totalFilters)}

          {hasClearFilterButton && clearFilterbutton(clearFilters)}
        </div>
      )}

      <TextNew.Formal
        role={RoleType.ALERT}
        cid="search-results"
        localeId="searchFilterBar.searchResults"
        localeParams={{ NUM_RESULTS: totalResults }}
        color={Colors.DARK_TONE}
      />

      {sort && (
        <LabeledSelect
          text={<LocaleMessage id="overviewSortBar.sortByLabel" />}
          name={SearchFields.SORT}
          isHidden={totalResults === 0 || noPagination}
          changeHandler={() => requestAnimationFrame(() => changeOrder(onSubmit, 100))}
          selectOptions={[
            {
              title: getMessage('general.sort.relevance'),
              value: 0,
            },
            {
              title: getMessage('general.sort.newest'),
              value: 1,
            },
          ]}
        />
      )}

      {isFoodRange && <AllergyInfo factSheet={factSheet} />}
    </div>

    {deviceState < DeviceState.LG && (
      <div className="mobile-search-filter-bar">
        <IconButton
          cid="mobile-toggle-icon"
          icon={IconName.CHEVRON}
          iconDirection={DirectionType.UP}
          onClick={toggleCollapse}
        />

        {filterToggleButton(toggleCollapse, totalFilters)}

        {clearFilterbutton(clearFilters)}
      </div>
    )}
  </section>
);

SearchFilterBar.defaultProps = {
  hasClearFilterButton: true,
  isFoodRange: false,
  sort: true,
};

SearchFilterBar.propTypes = {
  hasClearFilterButton: PropTypes.bool,
  totalResults: PropTypes.number.isRequired,
  totalFilters: PropTypes.number,
  toggleCollapse: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  factSheet: PropTypes.shape(factSheetShape),
  noPagination: PropTypes.bool,
  changeOrder: PropTypes.func,
  onSubmit: PropTypes.func,
  deviceState: PropTypes.number,
  isFoodRange: PropTypes.bool,
  sort: PropTypes.bool,
};

SearchFilterBar.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'SearchFilterBar', ['sort', 'isFoodRange'])(
    SearchFilterBar,
  ),
);
