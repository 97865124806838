import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import EnhancedFormField from 'common/src/app/formik/components/EnhancedFormField';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import {
  composeWeightImperialWithStonesToMetric,
  decomposeWeightImperialWithStonesToMetric,
  composeWeightImperialToMetric,
  decomposeWeightImperialToMetric,
} from 'common/src/app/util/weightUtils';
import Configuration from 'common/src/app/config/Configuration';
import { handleFieldGroupInput } from 'common/src/app/util/form/inputEventUtil';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Row from 'components/atoms/Row';
import InputType from 'common/src/app/data/enum/InputType';
import { InputNew, descriptorTypes } from 'components/atoms/Form';
import SelectNew from 'components/atoms/SelectNew';
import TextNew from 'components/atoms/TextNew';
import './weight-imperial-field.scss';

const FULL_POUND_LENGTH = 3;
const HALF_POUND_LENGTH = 2;

const WeightImperialField = (
  {
    name,
    abbreviate,
    selectArrowType,
    disabled,
    hidePlaceholder,
    hideStonesUsePounds,
    formik: { values, isSubmitting, setFieldValue, setFieldTouched, setValues },
  },
  { getMessage },
  className,
) => {
  const isStonesPoundsToKilo = Configuration.imperialWeightUseStones && !hideStonesUsePounds;
  const {
    stones: stonesValue,
    fullPounds: fullPoundsValue,
    remainingPounds: remainingPoundsValue,
    initialWeight,
  } = values;

  let stoneAbbreviation = '';
  if (Configuration.imperialWeightUseStones && abbreviate) {
    stoneAbbreviation = <LocaleMessage id="general.measureUnit.weight.pounds.abbreviation" />;
  } else if (Configuration.imperialWeightUseStones) {
    stoneAbbreviation = <LocaleMessage id="general.measureUnit.weight.pounds.name" />;
  }

  let fullPoundsPlaceholder = Configuration.imperialWeightUseStones ? '00' : '000';
  let remainingPoundsStonesPlaceholder = '00';

  if (hidePlaceholder) {
    fullPoundsPlaceholder = ' ';
    remainingPoundsStonesPlaceholder = ' ';
  }

  useEffect(() => {
    if (initialWeight) {
      if (isStonesPoundsToKilo) {
        const { stones, fullPounds, remainingPounds } = decomposeWeightImperialWithStonesToMetric(
          initialWeight,
        );
        setValues({
          ...values,
          stones,
          fullPounds,
          remainingPounds,
        });
      } else {
        const { fullPounds, remainingPounds } = decomposeWeightImperialToMetric(initialWeight);
        setValues({
          ...values,
          fullPounds,
          remainingPounds,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isStonesPoundsToKilo) {
      if (!stonesValue) {
        setFieldValue(name, null);
      }

      if (stonesValue || fullPoundsValue || remainingPoundsValue) {
        const composedValue = composeWeightImperialWithStonesToMetric({
          stones: stonesValue,
          fullPounds: fullPoundsValue,
          remainingPounds: remainingPoundsValue,
        });

        setFieldValue(name, composedValue);
      }
    } else {
      if (!fullPoundsValue) {
        setFieldValue(name, null);
      }
      if (fullPoundsValue || remainingPoundsValue) {
        const composedValue = composeWeightImperialToMetric({
          fullPounds: fullPoundsValue,
          remainingPounds: remainingPoundsValue,
        });

        setFieldValue(name, composedValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stonesValue, fullPoundsValue, remainingPoundsValue, initialWeight, isSubmitting]);

  const setValue = (fieldName, value) => {
    setFieldValue(fieldName, value, false);

    if (!stonesValue || !fullPoundsValue) {
      requestAnimationFrame(() => setFieldTouched(name, true, false));
    }
  };
  return (
    <Row className={className}>
      {isStonesPoundsToKilo && [
        <EnhancedFormField
          data-testid={`${getMessage('general.measureUnit.weight.stone.label')}-input`}
          component={InputNew}
          key={getMessage('general.measureUnit.weight.stone.label')}
          name={getMessage('general.measureUnit.weight.stone.label')}
          id="_composite-input-initialWeight.stones"
          descriptor={descriptorTypes.STATIC}
          type={InputType.NUMBER}
          min="0"
          max="50"
          aria-label={getMessage('general.measureUnit.weight.stone.label')}
          label={
            <LocaleMessage
              id={`general.measureUnit.weight.stone.${abbreviate ? 'labelAbbreviation' : 'label'}`}
            />
          }
          placeholder={remainingPoundsStonesPlaceholder}
          disabled={disabled}
          onBlur={e =>
            setValue(getMessage('general.measureUnit.weight.stone.label'), e.target.value)
          }
        />,
        <TextNew.Formal key="dot" cid="dot" aria-hidden="true">
          .
        </TextNew.Formal>,
      ]}

      <EnhancedFormField
        data-testid={'fullPounds-input'}
        component={InputNew}
        id="_composite-input-initialWeight.fullPounds"
        name="fullPounds"
        descriptor={descriptorTypes.STATIC}
        type={InputType.NUMBER}
        placeholder={fullPoundsPlaceholder}
        max={Configuration.imperialWeightUseStones ? '13' : '999'}
        aria-label={getMessage('general.measureUnit.weight.pounds.label')}
        onKeyPress={event => handleFieldGroupInput(event, FULL_POUND_LENGTH)}
        onBlur={e => setValue('fullPounds', e.target.value)}
        label={stoneAbbreviation}
        disabled={disabled}
      />

      {Configuration.imperialWeightUseStones ? (
        <EnhancedFormField
          dataTestId={'remainingPounds-input'}
          component={SelectNew}
          arrowType={selectArrowType}
          name="remainingPounds"
          customLabel="½"
          title="½"
          options={[
            {
              title: '0',
              value: '0',
              ariaLabel: getMessage('general.measureUnit.weight.pounds.labelZeroPound'),
            },
            {
              title: '½',
              value: '0.5',
              ariaLabel: getMessage('general.measureUnit.weight.pounds.labelHalfPound'),
            },
          ]}
          disabled={disabled}
        />
      ) : (
        <>
          <TextNew.Formal key="dot" cid="dot" aria-hidden="true">
            .
          </TextNew.Formal>
          ,
          <EnhancedFormField
            data-testid={'remainingPounds-input'}
            key="remainingPounds"
            component={InputNew}
            name="remainingPounds"
            descriptor={descriptorTypes.STATIC}
            type={InputType.NUMBER}
            placeholder={remainingPoundsStonesPlaceholder}
            label={
              <LocaleMessage
                id={`general.measureUnit.weight.pounds.${abbreviate ? 'abbreviation' : 'name'}`}
              />
            }
            min="0"
            max="1"
            onKeyPress={event => handleFieldGroupInput(event, HALF_POUND_LENGTH)}
            disabled={disabled}
          />
          ,
        </>
      )}
    </Row>
  );
};

WeightImperialField.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

WeightImperialField.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object,
  disabled: PropTypes.bool,
  abbreviate: PropTypes.bool,
  selectArrowType: PropTypes.string,
  hidePlaceholder: PropTypes.bool,
  hideStonesUsePounds: PropTypes.bool,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'WeightImperialField',
)(WeightImperialField);
