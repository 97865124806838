import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cancellableCallback from 'common/src/app/util/cancellableCallback';
import Loader from '../Loader';

/**
 * Acts as a switch statement to render one of multiple possible react components.
 * Renders the component found in `map` under the key provided in `switchValue`
 *
 * The `map` can be an object that maps every possible `switchValue`
 * to a react component, but it can also be such a map loaded through `bundle-loader`.
 * The `__default` property on the map will be used when none of the properties match
 * `switchValue`.
 *
 * Any other props passed to this component will be passed down to the switched
 * component, including the children.
 */
class ComponentSwitcher extends Component {
  state = {
    isLoading: false,
    content: null,
  };

  /**
   * If the map is a function (so loaded asynchronously) we process it here,
   * because processing the switch statement on the server
   * side can cause invariants between client and server.
   */
  componentDidMount() {
    if (typeof this.props.map === 'function' || typeof this.props.map === 'object') {
      this.parseSwitch(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.map !== prevProps.map || this.props.switchValue !== prevProps.switchValue) {
      this.parseSwitch(this.props);
    }
  }

  componentWillUnmount = () => {
    if (this.mapLoadCallback) {
      this.mapLoadCallback.cancel();
    }
  };

  /**
   * Sets the result of the switch on the component state after being
   * processed in the parseSwitch function
   * @param {object} map
   * @param {string} switchValue
   * @param {object} switchProps
   */
  setSwitchResultState(map, switchValue, switchProps) {
    // eslint-disable-next-line no-underscore-dangle
    const Result = map[switchValue] || map.__default;
    this.setState({
      isLoading: false,
      content: Result ? <Result {...(switchProps || {})} /> : null,
    });
  }

  /**
   * Processes the switch by looking up the switchValue in the map passed
   * to the current props. If the map is a function, will wait for the
   * function to complete.
   * @param props The current component props
   */
  parseSwitch(props) {
    const {
      showLoader, // eslint-disable-line no-unused-vars
      map,
      switchValue,
      ...switchProps
    } = props;

    if (!switchValue) {
      // switchValue is null or undefined. Render nothing.
      this.setState({
        isLoading: false,
        content: null,
      });

      return;
    }

    if (typeof map === 'function') {
      this.setState({
        isLoading: true,
      });

      this.mapLoadCallback = cancellableCallback(wrappedMapModule => {
        const mapModule = wrappedMapModule.default || wrappedMapModule;
        this.setSwitchResultState(mapModule, switchValue, switchProps);
      });
      map(this.mapLoadCallback);
    } else {
      this.setSwitchResultState(map, switchValue, switchProps);
    }
  }

  render() {
    const { isLoading, content } = this.state;
    const { showLoader, switchValue = null } = this.props;

    return (
      <Fragment>
        {showLoader && isLoading ? <Loader /> : null}
        {!isLoading && switchValue !== null && content ? content : null}
      </Fragment>
    );
  }
}

ComponentSwitcher.propTypes = {
  /**
   * If true, will show a loader while the map bundle is loading (has no effect when `map`
   * is not loaded through `bundle-loader`).
   */
  showLoader: PropTypes.bool,
  /**
   * One of the following:
   *  1. An object where the keys are the possible values of `value` and the values are
   *  react components that should be rendered when `value` matches.
   *  2. A function that returns such an object asynchronously to the provided callback. This
   *  can be used to pass maps that are loaded through `bundle-loader`
   *
   * __Important:__ in case `2`,  the component will be loaded lazily and will not render
   * on the server. This is to prevent invariance between the server rendering and client
   * rendering.
   */
  map: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  /**
   * The value to switch with. If one of the keys in the provided map matches this
   * value, that component will be used to render.
   *
   * When this value is `null` or `undefined`, no component will be rendered.
   */
  switchValue: PropTypes.string,
};

export default ComponentSwitcher;
