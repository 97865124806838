import React from 'react';
import PropTypes from 'prop-types';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import TextNew, { ElementTypes, Shades } from '../../atoms/TextNew';

import './diabetes-uk-nutritional-information.scss';

const getShadeFromColour = colour => {
  if (colour === 'green') {
    return Shades.GREEN_400;
  }

  if (colour === 'amber') {
    return Shades.ORANGE_400;
  }

  if (colour === 'red') {
    return Shades.RED_400;
  }

  return Shades.MONO_PRIMARY_DARK;
};

const DiabetesUKNutritionalInformation = ({ information }, context, className, dataTestid) => (
  <section className={className} data-testid={dataTestid}>
    <TextNew.Sans.SM
      element={ElementTypes.H3}
      localeId="diabetesUKNutritionalInformation.grid.title"
    />

    <ul className="grid">
      {information?.map(({ colour, content, type, typeDescription }) => {
        const shade = getShadeFromColour(colour);

        return (
          <li key={`grid-${type}`}>
            <TextNew.Sans.SM shade={shade}>{typeDescription}</TextNew.Sans.SM>
            <TextNew.Sans.SM shade={shade}>{content}</TextNew.Sans.SM>
          </li>
        );
      })}
    </ul>

    <ul className="key">
      <li key="key-low">
        <TextNew.Sans.MD
          localeId="diabetesUKNutritionalInformation.grid.key.low"
          shade={Shades.GREEN_400}
        />
      </li>
      <li key="key-medium">
        <TextNew.Sans.MD
          localeId="diabetesUKNutritionalInformation.grid.key.medium"
          shade={Shades.ORANGE_400}
        />
      </li>
      <li key="key-high">
        <TextNew.Sans.MD
          localeId="diabetesUKNutritionalInformation.grid.key.high"
          shade={Shades.RED_400}
        />
      </li>
    </ul>
  </section>
);

DiabetesUKNutritionalInformation.propTypes = {
  information: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number,
      content: PropTypes.string,
      colour: PropTypes.string,
      typeDescription: PropTypes.string,
    }),
  ).isRequired,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'DiabetesUKNutritionalInformation',
)(DiabetesUKNutritionalInformation);
