import React from 'react';
import PropTypes from 'prop-types';
import Pages from 'common/src/app/data/enum/Pages';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import Row from 'components/atoms/Row';
import Wrapper from 'components/atoms/Wrapper';
import ImageContentVerticalSplitBlock from 'components/molecules/ImageContentVerticalSplitBlock';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';

import './success-story-inspiration.scss';

const SuccessStoryContent = ({ line1, line2, subtitle, description }) => (
  <div className="content-block">
    {/* the below lines of code are for styling the title and subtitle for success stories,
    we are styling the text through css */}
    <p className="title">
      <TextNew.Serif.MD element={ElementTypes.SPAN} color={Colors.PUBLIC_PURPLE_BASE}>
        {line1}
      </TextNew.Serif.MD>

      <TextNew.Serif.MD element={ElementTypes.SPAN} color={Colors.PUBLIC_PURPLE_BASE}>
        {line2}
      </TextNew.Serif.MD>
    </p>

    <TextNew.Serif.SM cid="block-tagline" color={Colors.PUBLIC_PURPLE_BASE}>
      {subtitle}
    </TextNew.Serif.SM>
    <RichTextBlock text={description} />
  </div>
);

const SuccessStoryInspiration = ({ successStories, header, footer }) => (
  <Wrapper cid="success-story-inspiration" element={ElementTypes.SECTION} width="lg" padding="sm">
    {header && header}
    <Row className="success-story-inspiration">
      {successStories.map(
        ({
          titleLine1,
          titleLine2,
          subtitle,
          description,
          afterImage: image,
          beforeImage,
          successStoryArticle,
        }) => {
          const { id, slug, person: name } = successStoryArticle ?? {};
          const link = `${Pages.UK_PUBLIC_SUCCESS_STORIES}/${slug}`;
          const contentProps = {
            line1: titleLine1,
            line2: titleLine2,
            subtitle,
            description,
            link,
            name,
          };
          return (
            <ImageContentVerticalSplitBlock
              key={id}
              content={<SuccessStoryContent {...contentProps} />}
              image={image}
              beforeImage={beforeImage}
              link={link}
              member={name}
              backgroundPosition="center"
            />
          );
        },
      )}
    </Row>
    {footer && footer}
  </Wrapper>
);

SuccessStoryContent.propTypes = {
  line1: PropTypes.string,
  line2: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
};

SuccessStoryInspiration.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,

  successStories: PropTypes.array,
};

export default SuccessStoryInspiration;
