// The values of the keys below are delivered by WebTrends, which is inconsistent.
const Category = {
  ONLINE_REGISTRATION: 'online-registration',
  COMMUNITY: 'community',
  HOMEPAGE: 'homepage',
  ONLINE_PROFILE_BUILDER: 'profile-builder',
  SUCCESS_STORIES: 'success',
  WEIGH_IN: 'weigh-in',
  EDIT_WEIGH_IN: 'edit-weigh-in',
  SETTINGS: 'settings',
  FEATURES: 'features',
  RECIPES: 'recipes',
  GLOBAL_SEARCH: 'search',
  SLIMMING_WORLD_ESSENTIALS: 'SW Essentials',
  WEEK_ZERO: 'WeekZero',
  SLIMMING_GROUP: 'slimming-group',
  LIVE_EVENT: 'Live Event',
  FOOD_OPTIMIZING_QUICK_START: 'Food Optimizing Quick Start',
  ALL_PAGES: 'all-pages',
  EMAIL_SIGNUP: 'email-signup',
  SEVEN_DAY_MENU_SIGNUP: '7-day-menu-signup',
  POSTCODE_ENTERED: 'postcode-entered',
  PUBLIC_HOMEPAGE: 'public-homepage',
  BMI: 'bmi',
  PUBLIC_SEARCH: 'public-search',
  RECOMMENDED_READING: '12wk-content',
  GROUP_SEARCH: 'group-search',
};

export default Category;
