import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import mediaTypes from 'common/src/app/data/enum/mediaTypes';
import { API_STATIC_PUBLIC_WHAT_HAPPENS_ONLINE } from 'common/src/app/data/apiStatics';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { staticPageSelector, getPageMedia } from 'common/src/app/selectors/publicSelectors';

import WhatHappensOnline from './WhatHappensOnline';

const mapStateToProps = state => {
  const whatHappensOnlineData = staticPageSelector(state, API_STATIC_PUBLIC_WHAT_HAPPENS_ONLINE);
  const media = (imageIndex, mediaType = mediaTypes.IMAGE) =>
    getPageMedia(state, imageIndex, API_STATIC_PUBLIC_WHAT_HAPPENS_ONLINE, mediaType);

  return {
    whatHappensOnlineData,
    mediaContent: {
      successStory1: media(0),
      successStory2: media(1),
      headerImage: media(2),
      carouselAssets: [media(3), media(4), media(5), media(6), media(7)],
      successStoryBefore1: media(8),
      successStoryBefore2: media(9),
    },
  };
};

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    dispatch(getStaticContent(API_STATIC_PUBLIC_WHAT_HAPPENS_ONLINE, true)),
});

export default compose(addInitAction, connected)(WhatHappensOnline);
