/**
 * The direction type is used for css styling. With this class name i can determine
 * the position for the small arrow for the tooltip
 */
import DirectionType from '../data/enum/DirectionType';

/**
 * Get the new position when truthy otherwise use the default position
 *
 * @param defaultPosition
 * @param collisionObject
 * @returns {*}
 */
export default (defaultPosition, collisionObject) => {
  let newPosition = defaultPosition;

  // Align all top positioned tooltip to the bottom
  if (
    (defaultPosition === DirectionType.TOP ||
      defaultPosition === DirectionType.TOP_LEFT ||
      defaultPosition === DirectionType.TOP_RIGHT) &&
    collisionObject.topCollisionDetected
  ) {
    if (defaultPosition === DirectionType.TOP_LEFT) {
      newPosition = DirectionType.BOTTOM_LEFT;
    } else if (defaultPosition === DirectionType.TOP_RIGHT) {
      newPosition = DirectionType.BOTTOM_RIGHT;
    } else {
      newPosition = DirectionType.BOTTOM;
    }
    // Align all bottom positioned tooltip to the top
  } else if (
    (defaultPosition === DirectionType.BOTTOM ||
      defaultPosition === DirectionType.BOTTOM_LEFT ||
      defaultPosition === DirectionType.BOTTOM_RIGHT) &&
    collisionObject.bottomCollisionDetected
  ) {
    if (defaultPosition === DirectionType.BOTTOM_LEFT) {
      newPosition = DirectionType.TOP_LEFT;
    } else if (defaultPosition === DirectionType.BOTTOM_RIGHT) {
      newPosition = DirectionType.TOP_RIGHT;
    } else {
      newPosition = DirectionType.TOP;
    }
  }

  // Align to the left
  if (collisionObject.leftCollisionDetected) {
    if (newPosition === DirectionType.TOP_RIGHT || newPosition === DirectionType.TOP) {
      newPosition = DirectionType.TOP_LEFT;
    }
    if (newPosition === DirectionType.BOTTOM_RIGHT || newPosition === DirectionType.BOTTOM) {
      newPosition = DirectionType.BOTTOM_LEFT;
    }
  }

  // Align to the right
  if (collisionObject.rightCollisionDetected) {
    if (newPosition === DirectionType.TOP_LEFT || newPosition === DirectionType.TOP) {
      newPosition = DirectionType.TOP_RIGHT;
    }
    if (newPosition === DirectionType.BOTTOM_LEFT || newPosition === DirectionType.BOTTOM) {
      newPosition = DirectionType.BOTTOM_RIGHT;
    }
  }

  return newPosition;
};
