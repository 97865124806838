/* global 'atom','HeadingButton' */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import './heading-button.scss';

const HeadingButton = (
  { children, onToggle, childRef, showMenu, isEnabled },
  context,
  className,
) => (
  <button
    id="browseButton"
    tabIndex="0"
    aria-label={`Click to ${showMenu ? 'close' : 'expand'} menu`}
    aria-expanded={showMenu}
    onClick={isEnabled ? onToggle : null}
    className={classNames(className, {
      ' is-enabled': isEnabled,
    })}
    ref={childRef}
  >
    <div aria-hidden="true" className="hamburger">
      <span />
    </div>
    {children}
  </button>
);

HeadingButton.propTypes = {
  /**
   * Active state for the hamburger menu
   */
  showMenu: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  /**
   * A function to toggle the active state
   */
  onToggle: PropTypes.func,
  children: PropTypes.node,
  childRef: PropTypes.func,
  isEnabled: PropTypes.bool.isRequired,
};

HeadingButton.defaultProps = {
  isEnabled: true,
};

export default withFunctionalClassName('atom','HeadingButton', [{ 'is-active': 'showMenu' }])(
  HeadingButton,
);
