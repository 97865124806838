import compose from 'redux/lib/compose';
import { API_STATIC_PUBLIC_BODY_MAGIC } from 'common/src/app/data/apiStatics';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { connect } from 'react-redux';
import BodyMagic from './BodyMagic';

const mapStateToProps = state => ({
  page: staticPageSelector(state, API_STATIC_PUBLIC_BODY_MAGIC),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getStaticContent(API_STATIC_PUBLIC_BODY_MAGIC, true)),
});

export default compose(addInitAction, connected)(BodyMagic);
