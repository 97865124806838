import { FIRST_POSTCODE_PART } from 'common/src/app/data/regexPatterns';
import GroupSearchType from 'common/src/app/data/enum/GroupSearchType';
import { Category, DataLayerKeys } from 'common/src/app/data/enum/Tracking';
import { isValidPostcodeFormat } from 'common/src/app/validation/createPostcodeValidation';
import GroupSearchFieldNames from 'common/src/app/data/enum/FieldNames/GroupSearchFieldNames';
import formikErrorCodes from 'common/src/app/validation/formikErrorCodes';

/**
 * Returns a tracking object based on the search entry and search type.
 *
 * @param {string} searchEntry - The search entry to process.
 * @param {string} searchType - The type of search performed. Must be one of the following strings:
 *   - 'region'
 *   - 'postcode'
 *   - 'unknown'
 *   - 'place'
 * @returns {Object} A tracking object.
 */
const getTrackingObject = (searchEntry, searchType) => {
  // If the search type is a postcode, extract the first part of the postcode from the search entry
  if (searchType === GroupSearchType.POSTCODE) {
    // Normalize the search entry by converting it to uppercase and removing any spaces
    const normalizedSearchEntry = searchEntry.toUpperCase().replace(/\s/g, '');
    // Match the first part of the postcode using a regular expression
    const [firstPartPostcode] = normalizedSearchEntry.match(FIRST_POSTCODE_PART) || [];

    // Return a tracking object with the category and first part of the postcode
    return {
      category: Category.POSTCODE_ENTERED,
      [DataLayerKeys.POSTCODE]: firstPartPostcode,
    };
  }

  // If the search type is not a postcode, return a tracking object with the category and search entry
  return {
    category: Category.GROUP_SEARCH,
    [DataLayerKeys.PLACE_SEARCH]: searchEntry,
  };
};

export default getTrackingObject;

export const freeTextValidator = (values, errors) => {
  let newErrors = { ...errors };

  if (
    values.searchType === GroupSearchType.POSTCODE &&
    !isValidPostcodeFormat(values[GroupSearchFieldNames.FREE_TEXT].trim())
  ) {
    newErrors = { [GroupSearchFieldNames.FREE_TEXT]: formikErrorCodes.POSTCODE_FORMAT_ERROR };
    return newErrors;
  }

  if (values.searchType === GroupSearchType.PLACE && !values.entryMatchesTown) {
    newErrors = { [GroupSearchFieldNames.FREE_TEXT]: formikErrorCodes.NO_GROUP_SEARCH_TOWNS };
  }

  if (values.searchType === GroupSearchType.UNKNOWN || !values[GroupSearchFieldNames.FREE_TEXT]) {
    newErrors = { [GroupSearchFieldNames.FREE_TEXT]: formikErrorCodes.FIELD_REQUIRED };
    return newErrors;
  }

  return newErrors;
};
