/* global 'molecule','NearestGroupGeoLocation' */
import React from 'react';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import classNames from 'classnames';
import LocaleMessage from '../../atoms/LocaleMessage';
import TextNew, { Colors, TextDecoration } from '../../atoms/TextNew';
import Button from '../../atoms/Button';
import './nearest-group-geo-location.scss';

/**
 * Allow uses to find there nearest groups via the Geolocation API
 */

const NearestGroupGeoLocation = (
  { getGroupsByLatLng, loadingCallBack, useCtaText },
  { getMessage },
  className,
) => (
  <div className={classNames(className, { 'use-cta-text': useCtaText })}>
    {useCtaText ? (
      <Link onClick={() => getGroupsByLatLng()}>
        <TextNew.Sans.XS
          localeId="nearestGroupGeoLocation.cta"
          color={Colors.PRIMARY_LIGHT}
          decoration={TextDecoration.UNDERLINE}
        />
      </Link>
    ) : (
      <Button
        onClick={async () => {
          loadingCallBack(true);
          try {
            await getGroupsByLatLng();
            loadingCallBack(false);
          } catch (e) {
            loadingCallBack(false);
          }
        }}
        isTertiary={useCtaText}
        cid="geolocation-button"
        aria-label={getMessage('nearestGroupGeoLocation.label')}
      >
        <Icon
          title={<LocaleMessage id="nearestGroupGeoLocation.cta" />}
          width={22}
          name={IconName.LOCATION_TARGET}
        />
      </Button>
    )}
  </div>
);

NearestGroupGeoLocation.propTypes = {
  getGroupsByLatLng: PropTypes.func.isRequired,
  useCtaText: PropTypes.bool,
  loadingCallBack: PropTypes.func,
};

NearestGroupGeoLocation.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'NearestGroupGeoLocation',
)(NearestGroupGeoLocation);
