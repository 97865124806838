import PropTypes from 'prop-types';
import React from 'react';
import TextNew from '../../../../../atoms/TextNew';

const TextBlock = ({ text }) => <TextNew>{text}</TextNew>;

TextBlock.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextBlock;
