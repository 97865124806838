/**
 * Possible values for 'gender' in the account API
 * @module
 */

/**
 * @type {number}
 */
export const MALE = 0;
/**
 * @type {number}
 */
export const FEMALE = 1;

/**
 * @type {object}
 */
export const genderToString = {
  0: 'male',
  1: 'female',
};

/**
 * Values to use for filtering a success story, can be understood by API endpoint
 */
export const genderFilterValues = {
  MALE: 'male',
  FEMALE: 'female',
};

export const GenderType = {
  0: 'Male',
  1: 'Female',
};
