import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { offerRoundels } from 'common/src/app/config/market/market.configdefinitions';
import classNames from 'classnames';
import TextNew, { AccentColors, TextTypes } from 'components/atoms/TextNew';
import Loader from 'components/atoms/Loader/Loader';
import { consultantShape } from 'common/src/app/util/proptypes/apiEntities/groupSearchShape';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import StructuredDataGenerator from 'components/atoms/StructuredDataGenerator';
import BackLink from 'components/molecules/BackLink';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import CurrentOfferRoundel from 'components/molecules/CurrentOfferRoundel';
import errorBoundary from 'components/hoc/ErrorBoundary';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import GroupBulletin from '../../molecules/GroupBulletin';
import RelatedSuccessStories from '../../../../SuccessStories/components/molecules/RelatedSuccessStories';
import GroupFullDetails from './components/organisms/GroupFullDetails';

import './nearest-group-detail.scss';

const NearestGroupDetail = (
  {
    text,
    prefilledSuccessStories,
    bulletin,
    isLoading,
    isInitializing,
    group,
    consultant,
    deviceState,
  },
  context,
  className,
) => {
  const content = {
    one: TextTypes.SCRIPT_XS,
    two: TextTypes.LOUD,
  };

  return (
    <>
      {bulletin && <GroupBulletin text={bulletin} />}
      <div className={classNames(className, { 'disable-roundel': !offerRoundels })}>
        <div className="unfixed-wrapper">
          {deviceState <= DeviceState.SM && <BackLink />}
          <div className="intro-page">
            {offerRoundels && <div className="block blank" />}
            <div className="block main-content">
              {Object.keys(content).map(contentItem => (
                <TextNew
                  key={contentItem}
                  cid={contentItem}
                  type={content[contentItem]}
                  color={AccentColors.ACCENT_PURPLE}
                  localeId={`nearestGroupDetail.intro.${contentItem}`}
                />
              ))}
              {text && <RichTextBlock cid="content" text={text} />}
            </div>
            <CurrentOfferRoundel backgroundColor={AccentColors.ACCENT_GREEN} />
          </div>
          {isLoading || (isInitializing && <Loader />)}
          {group && Object.keys(group).length > 0 && group.consultant && (
            <GroupFullDetails consultant={consultant} group={group} isLoading={isLoading} />
          )}
          <RelatedSuccessStories {...prefilledSuccessStories} />
        </div>
      </div>
      {group?.venue && <StructuredDataGenerator data={group} type={types.LOCAL_BUSINESS} />}
    </>
  );
};

NearestGroupDetail.defaultProps = {
  group: {
    consultant: {},
    venue: {},
  },
};

NearestGroupDetail.propTypes = {
  group: PropTypes.object,
  consultant: PropTypes.shape(consultantShape),
  isLoading: PropTypes.bool,
  isInitializing: PropTypes.bool,
  deviceState: PropTypes.number,
  bulletin: PropTypes.string,
  text: PropTypes.string,
  prefilledSuccessStories: PropTypes.object,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName(ComponentType.TEMPLATE, 'NearestGroupDetail'),
)(NearestGroupDetail);
