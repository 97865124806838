import handleActions from 'redux-actions/lib/handleActions';
import isEqual from 'lodash/isEqual';
import debugLib from 'debug';
import {
  PAGINATION_NONE,
  PAGINATION_OFFSET,
  PAGINATION_PARTITION,
} from '../../data/paginationType';
import {
  COLLECTION_CLEAR_ALL,
  COLLECTION_CLEAR_IF_CONTAINS_REF,
  COLLECTION_PARTITION_SET_AFTER,
  COLLECTION_PARTITION_SET_BEFORE,
  COLLECTION_REMOVE_REFS,
  COLLECTION_REPLACE,
  COLLECTION_SET_AT_OFFSET,
  COLLECTION_SET_PAGINATION,
  COLLECTION_SET_TOTAL,
  COLLECTION_PARTITION_CHECK_COMPLETE,
  COLLECTION_UNSHIFT,
} from '../../actions/entities/collectionActions';

const debug = debugLib('SlimmingWorld:collectionReducer');

const paginationErrorMessage = (action, paginationType) =>
  `Received action "${action}" on a collection with pagination type "${paginationType}".
   You probably don't want to do this. Clear the pagination of this reducer or use a separate reducer`;

const defaultState = {
  refs: [],
  pagination: {
    type: PAGINATION_NONE,
  },
};

const collectionReducer = handleActions(
  {
    [COLLECTION_SET_PAGINATION]: (state, { payload }) => ({
      ...state,
      pagination: {
        ...state.pagination,
        ...payload,
      },
    }),
    [COLLECTION_REMOVE_REFS]: (state, { payload: { refs } }) => {
      if (!refs.length || !state.refs.length) {
        return state;
      }

      const newRefs = state.refs.filter(
        ref =>
          !refs.some(
            removeRef =>
              (typeof removeRef.type === 'undefined' || ref.type === removeRef.type) &&
              (typeof removeRef.id === 'undefined' || ref.id === removeRef.id),
          ),
      );

      if (newRefs.length === state.refs.length) {
        return state;
      }

      return {
        ...state,
        refs: newRefs,
        pagination: {
          ...state.pagination,
          total: state.pagination.total
            ? state.pagination.total + (newRefs.length - state.refs.length)
            : null,
        },
      };
    },
    [COLLECTION_CLEAR_IF_CONTAINS_REF]: (state, { payload: { ref } }) => {
      if (!state.refs.length) {
        return state;
      }

      const containsRef = state.refs.some(
        stateRef => ref.type === stateRef.type && ref.id === stateRef.id,
      );

      return containsRef ? defaultState : state;
    },
    [COLLECTION_SET_AT_OFFSET]: (state, { payload: { refs, offset, hasMore } }) => {
      if (state.pagination.type === PAGINATION_PARTITION) {
        throw new Error(paginationErrorMessage(COLLECTION_SET_AT_OFFSET, PAGINATION_PARTITION));
      }

      if (!refs.length) {
        return {
          ...state,
          pagination: {
            ...state.pagination,
            hasMore: false,
          },
        };
      }

      const nextPagination = {
        ...state.pagination,
        total: state.pagination.total || null,
        type: PAGINATION_OFFSET,
        offset: state.pagination.offset || 0,
        hasMore,
      };

      const newOffset = Math.min(offset, nextPagination.offset);
      const currentRefsIndex = nextPagination.offset - newOffset;
      const newRefsIndex = offset - newOffset;
      const newLength = Math.max(currentRefsIndex + state.refs.length, newRefsIndex + refs.length);

      const nextRefs = new Array(newLength).fill(null);
      state.refs.forEach((ref, index) => (nextRefs[index + currentRefsIndex] = ref));
      refs.forEach((ref, index) => (nextRefs[index + newRefsIndex] = ref));

      nextPagination.offset = newOffset;

      return {
        ...state,
        refs: nextRefs,
        pagination: nextPagination,
      };
    },
    [COLLECTION_UNSHIFT]: (state, { payload: { refs } }) => ({
      ...state,
      refs: [...refs, ...state.refs],
      pagination: {
        ...state.pagination,
        ...(typeof state.pagination.total === 'number'
          ? { total: state.pagination.total + refs.length }
          : {}),
      },
    }),
    [COLLECTION_REPLACE]: (state, { payload: { refs, pagination } }) => ({
      ...state,
      refs: refs || state.refs,
      pagination: pagination || state.pagination,
    }),
    [COLLECTION_PARTITION_SET_AFTER]: (
      state,
      { payload: { refs, expectedCount = 1, afterRef, assumeAtBegin = true } },
    ) => {
      if (!afterRef) {
        // no after ref set. replace current collection
        if (state.refs.length && isEqual(state.refs, refs)) {
          // current collection is the same. just return the current collection
          return state;
        }

        return {
          ...state,
          refs,
          pagination: {
            total: state.pagination.total || null,
            type: PAGINATION_PARTITION,
            atBegin:
              assumeAtBegin &&
              (state.pagination.offset === 0 || state.pagination.type === PAGINATION_NONE),
            atEnd: refs.length < expectedCount,
          },
        };
      }

      // a for loop is used instead of .findIndex(), because it makes more sense to start searching
      // from the back of the array
      let afterRefIndex = -1;
      for (let i = state.refs.length - 1; i >= 0; i--) {
        if (isEqual(afterRef, state.refs[i])) {
          afterRefIndex = i;
          break;
        }
      }
      if (afterRefIndex === -1) {
        debug(
          `afterRef ${afterRef.type}.${afterRef.id} not found in current state (probably due to simultaneous conflicting actions)`,
        );
        // something went wrong, ignore the action
        return state;
      }

      return {
        ...state,
        refs: [...state.refs.slice(0, afterRefIndex + 1), ...refs],
        pagination: {
          ...state.pagination,
          total: state.pagination.total || null,
          type: PAGINATION_PARTITION,
          atBegin:
            state.pagination.atBegin ||
            state.pagination.offset === 0 ||
            state.pagination.type === PAGINATION_NONE,
          atEnd: refs.length < expectedCount,
        },
      };
    },
    [COLLECTION_PARTITION_SET_BEFORE]: (
      state,
      { payload: { refs, expectedCount = 1, beforeRef } },
    ) => {
      if (!beforeRef) {
        // no before ref set. replace current collection
        if (state.refs.length && isEqual(state.refs, refs)) {
          // current collection is the same. just return the current collection
          return state;
        }

        return {
          ...state,
          refs,
          pagination: {
            total: state.pagination.total || null,
            type: PAGINATION_PARTITION,
            atBegin: refs.length < expectedCount,
            atEnd: false,
          },
        };
      }

      const beforeRefIndex = state.refs.findIndex(ref => isEqual(ref, beforeRef));
      if (beforeRefIndex === -1) {
        debug(
          `beforeRef ${beforeRef.type}.${beforeRef.id} not found in current state (probably due to simultaneous conflicting actions)`,
        );
        // something went wrong, ignore the action
        return state;
      }

      return {
        ...state,
        refs: [...refs, ...state.refs.slice(beforeRefIndex)],
        pagination: {
          ...state.pagination,
          total: state.pagination.total || null,
          type: PAGINATION_PARTITION,
          atBegin: refs.length < expectedCount,
          atEnd: state.pagination.atEnd || false,
        },
      };
    },
    [COLLECTION_SET_TOTAL]: (state, { payload: { total } }) => ({
      ...state,
      pagination: {
        ...state.pagination,
        total,
      },
    }),
    [COLLECTION_PARTITION_CHECK_COMPLETE]: (state, { payload: { total } }) => {
      if (state.pagination.type === PAGINATION_OFFSET) {
        throw new Error(
          paginationErrorMessage(COLLECTION_PARTITION_CHECK_COMPLETE, PAGINATION_OFFSET),
        );
      }

      if (state.refs.length >= total) {
        return {
          ...state,
          pagination: {
            ...state.pagination,
            atBegin: true,
            atEnd: true,
          },
        };
      }

      return state;
    },
    [COLLECTION_CLEAR_ALL]: () => defaultState,
  },
  defaultState,
);

export default collectionReducer;
