import { withInitAction } from '@mediamonks/react-redux-component-init';
import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { API_STATIC_PUBLIC_MYTHS } from 'common/src/app/data/apiStatics';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';

import Myths from './Myths';

const mapStateToProps = state => ({
  page: staticPageSelector(state, API_STATIC_PUBLIC_MYTHS),
});

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getStaticContent(API_STATIC_PUBLIC_MYTHS, true)),
});

const connected = connect(mapStateToProps);

export default compose(addInitAction, connected)(Myths);
