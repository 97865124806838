/* global 'molecule' */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import formFieldPropTypes from 'common/src/app/util/form/formFieldPropTypes';
import StyleType from 'common/src/app/data/enum/StyleType';
import Label from '../../atoms/Label';
import './check-box.scss';

/**
 * Renders a checkbox with a label. For usage with `<EnhancedField>`
 */
class CheckBox extends PureComponent {
  handleClick = () => {
    const { onClick } = this.props;
    onClick && onClick();
  };

  render() {
    const { input: inputProps = {}, disabled, children, dataTestid } = this.props;
    return (
      <div
        {...componentClassNameProp('molecule', this, [
          '{styleType}',
          { checked: inputProps && !!inputProps.value },
        ])}
      >
        <input
          id={`${inputProps.name}-label`}
          type="checkbox"
          disabled={disabled}
          checked={!!inputProps.value}
          className="checkbox-input"
          onClick={this.handleClick}
          data-testid={`${dataTestid}-checkbox`}
          {...inputProps}
        />
        <Label htmlFor={`${inputProps.name}-label`} text={children} />
      </div>
    );
  }
}

CheckBox.defaultProps = {
  styleType: StyleType.PRIMARY,
};

CheckBox.propTypes = {
  dataTestid: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  styleType: PropTypes.oneOf([StyleType.PRIMARY, StyleType.SECONDARY]),
  ...formFieldPropTypes,
};

export default CheckBox;
