import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { FontWeight, ElementTypes, Colors } from 'components/atoms/TextNew';
import errorBoundary from 'components/hoc/ErrorBoundary';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import FlippableContentTiles from 'components/organisms/FlippableContentTiles/FlippableContentTiles';
import Loader from 'components/atoms/Loader';

import './myths.scss';

const Myths = ({ page }, context, className) => {
  if (!page) return <Loader />;

  const { headerImage, myths } = page;

  return (
    <div className={className}>
      <HeroBarPublic image={headerImage}>
        <TextNew.Serif.MD
          cid="hero-bar-public-serif-heading"
          localeId="myths.heading.h1"
          element={ElementTypes.H1}
          weight={FontWeight.BOLD}
        />
        <TextNew.Script.SM
          cid="hero-bar-public-script-heading"
          color={Colors.BRAND}
          localeId="myths.heading.h2"
          element={ElementTypes.H2}
        />
        <TextNew.Serif.XS
          cid="hero-bar-public-introduction"
          localeId="myths.heading.introduction"
        />
      </HeroBarPublic>

      <div className="flipped-tiles-container">
        {myths && <FlippableContentTiles tiles={myths} />}
      </div>
    </div>
  );
};

Myths.propTypes = {
  page: PropTypes.shape({
    headerImage: PropTypes.shape({
      ...imageShape,
    }).isRequired,
    myths: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string,
        answerTitle: PropTypes.string,
        answerText: PropTypes.string,
        buttonLink: PropTypes.string,
        buttonText: PropTypes.string,
        tileImage: PropTypes.shape({
          ...imageShape,
        }).isRequired,
      }),
    ).isRequired,
  }),
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.PAGE, 'Myths'),
)(Myths);
