import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import Myths from 'bundle-loader?lazy&reactHot&name=Myths!./index';

const routes = (
  <Route
    title="Your burning questions answered | {pageTitle}"
    path={Pages.UK_PUBLIC_MYTHS}
    {...getRouteComponentProp(Myths)}
  />
);

export default routes;
