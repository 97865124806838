import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Link from 'react-router/lib/Link';
import { convertRecipeFlagsToIconClass } from 'common/src/app/util/content/convertRecipeFlagsToIcon';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import {
  titleStyleShape,
  titlePositionShape,
} from 'common/src/app/util/proptypes/masterTilePropTypes';
import GridItemType from 'common/src/app/data/enum/GridItemType';
import { AccentColors } from 'common/src/app/data/enum/Colors';
import Pages from 'common/src/app/data/enum/Pages';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import ArticleType from 'common/src/app/data/enum/ArticleType';
import TileDescription from '../../atoms/TileDescription';
import TileImage from '../../atoms/TileImage';
import TileTitle from '../../atoms/TileTitle';
import TileRecipeMeta from '../../atoms/TileRecipeMeta';
import SuccessStatsTile from '../../atoms/SuccessStatsTile';
import TextNew, { Colors, ElementTypes } from '../../atoms/TextNew';

import './master-tile.scss';

//  Todo: SWPUB-328 rationalise the large number of props used for single settings, look at using an object of settings perhaps instead
const MasterTile = (
  {
    _type,
    id,
    title,
    titleStyle,
    titlePosition,
    media,
    oldImage,
    slug,
    link,
    // @TODO - DIAU-33
    // isFoodRange,
    description,
    customDescription,
    person,
    introduction,
    image,
    totalTime,
    featureType,
    characteristics,
    startWeight,
    lostWeight,
    syns,
    onClick,
    category,
    tileColor,
    testId,
    imageLoading,
    dataTestId,
    isDiabetesUk,
  },
  context,
  className,
) => {
  if (!id) {
    return <div className={className} />;
  }

  const routePathBase = {
    [ArticleType.RECIPE]: `${Pages.RECIPE_LANDING}/${slug}`,
    [ArticleType.PUBLIC_RECIPE]: `${Pages.UK_PUBLIC_RECIPE_SEARCH}/${slug}`,
    [ArticleType.PUBLIC_RECIPE_LARGE]: `${Pages.UK_PUBLIC_RECIPE_SEARCH}/${slug}`,
    [ArticleType.SUCCESS_STORY]: `${Pages.SUCCESS_STORY_LANDING}/${slug}`,
    [ArticleType.PUBLIC_SUCCESS_STORY]: `${Pages.UK_PUBLIC_SUCCESS_STORIES}/${slug}`,
    [ArticleType.FOOD_RANGE_PRODUCT]: `..${Pages.UK_PUBLIC_RECIPE_SEARCH}/${slug}`,
  };

  const hasTileColor = !!tileColor;
  const isFeatured = featureType === GridItemType.FEATURED;
  const symbols = convertRecipeFlagsToIconClass(characteristics);

  // eslint-disable-next-line no-underscore-dangle
  const path = slug ? routePathBase[_type] : link;

  const footerType = {
    [ArticleType.RECIPE]: <TileRecipeMeta symbols={symbols} syns={syns} totalTime={totalTime} />,
    [ArticleType.PUBLIC_RECIPE]: <TileRecipeMeta syns={syns} totalTime={totalTime} />,
    [ArticleType.PUBLIC_RECIPE_LARGE]: (
      <TileRecipeMeta symbols={symbols} syns={syns} totalTime={totalTime} />
    ),
    [ArticleType.SUCCESS_STORY]: (
      <SuccessStatsTile
        hasTileColor={hasTileColor}
        lostWeight={lostWeight}
        startWeight={startWeight}
      />
    ),
    [ArticleType.PUBLIC_SUCCESS_STORY]: (
      <SuccessStatsTile
        hasTileColor={hasTileColor}
        lostWeight={lostWeight}
        startWeight={startWeight}
      />
    ),
  };

  return (
    <section className={className} data-testid={dataTestId} id={testId}>
      <header>
        {title && titlePosition === DirectionType.TOP && (
          <TileTitle
            titleStyle={titleStyle}
            isFeatured={isFeatured}
            title={title}
            hasTileColor={hasTileColor}
            path={path}
            onClick={onClick}
            tabIndex={person ? '-1' : ''}
          />
        )}

        <figure>
          <TileImage
            onClick={onClick}
            isFeatured={isFeatured}
            oldImage={oldImage}
            image={image || media}
            title={title}
            person={person}
            imageLoading={imageLoading}
            link={path}
            // @TODO - DIAU-33
            // isFoodRange={isFoodRange}
            isDiabetesUk={isDiabetesUk}
            _type={_type}
          />
        </figure>
      </header>
      <div className="content">
        <div className="body">
          {person && (
            <Link className="title" to={path} onClick={onClick}>
              <TextNew.Script.XS
                color={tileColor ? Colors.PRIMARY_LIGHT : Colors.PRIMARY_DARK}
                cid="person-name"
                element={ElementTypes.H2}
              >
                {person}
              </TextNew.Script.XS>
            </Link>
          )}
          {category}
          {title && titlePosition === DirectionType.BOTTOM && (
            <TileTitle
              titleStyle={titleStyle}
              isFeatured={isFeatured}
              title={title}
              hasTileColor={hasTileColor}
              path={path}
              onClick={onClick}
              tabIndex={person ? '-1' : ''}
            />
          )}
          {isFeatured && introduction && (
            <TextNew.Serif.XS color={tileColor ? Colors.PRIMARY_LIGHT : Colors.PRIMARY_DARK}>
              {introduction}
            </TextNew.Serif.XS>
          )}
          {isFeatured && description && (
            <TileDescription description={description} hasTileColor={hasTileColor} />
          )}

          {customDescription}
        </div>

        {// eslint-disable-next-line no-underscore-dangle
        footerType[_type] !== undefined && <footer className="footer">{footerType[_type]}</footer>}
      </div>
    </section>
  );
};

MasterTile.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  media: PropTypes.shape({
    content: PropTypes.string,
    alt: PropTypes.string,
  }),
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  oldImage: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  slug: PropTypes.string,
  link: PropTypes.string,
  // @TODO - DIAU-33
  // isFoodRange: PropTypes.bool,
  description: PropTypes.string,
  totalTime: PropTypes.number,
  featureType: PropTypes.string,
  characteristics: PropTypes.number,
  syns: PropTypes.number,
  tileColor: PropTypes.oneOf(AccentColors.propTypes),
  startWeight: PropTypes.number,
  lostWeight: PropTypes.number,
  category: PropTypes.node,
  customDescription: PropTypes.object,
  onClick: PropTypes.func,
  person: PropTypes.string,
  introduction: PropTypes.string,
  titleStyle: PropTypes.shape(titleStyleShape),
  titlePosition: PropTypes.oneOf(titlePositionShape),
  imageLoading: PropTypes.oneOf(['eager', 'lazy']),
  // Id used for automation tests
  testId: PropTypes.string,
  dataTestId: PropTypes.string,
  // Ideitifyer used to identify that the recipe is a diabetesUkRecipe
  isDiabetesUk: PropTypes.bool,
  _type: PropTypes.string,
};

MasterTile.defaultProps = {
  _type: ArticleType.DEFAULT,
  titlePosition: DirectionType.BOTTOM,
};

export default withFunctionalClassName(ComponentType.ORGANISM, 'MasterTile', [
  ({ featureType }) => (featureType === GridItemType.FEATURED ? ['is-featured'] : []),
  '{tileColor}-color',
])(MasterTile);
