import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import {
  getConsultantGroups,
  getConsultantDetail,
  GET_CONSULTANT_DETAIL,
  GET_CONSULTANT_GROUPS,
} from 'common/src/app/actions/resources/groupSearchConsultantActions';
import { nearestGroupResultsSelector } from 'common/src/app/selectors/nearestGroupSearchSelectors';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { push as historyPush } from 'react-router-redux';
import Pages from 'common/src/app/data/enum/Pages';
import { makeEntitySelector } from 'common/src/app/reducers/view/entityViewReducer';
import NearestGroupConsultantLanding from './NearestGroupConsultantLanding';

const mapStateToProps = state => {
  const isLoadingConsultantSelector = makeIsLoadingSelector(GET_CONSULTANT_DETAIL);
  const isLoadingConsultantGroupsSelector = makeIsLoadingSelector(GET_CONSULTANT_GROUPS);
  const entitySelector = makeEntitySelector();
  const consultant = entitySelector(state, state.view.pages.groupDetail?.consultant);

  return {
    isLoading: isLoadingConsultantSelector(state) || isLoadingConsultantGroupsSelector(state),
    consultant,
    groups: nearestGroupResultsSelector(state),
  };
};

const connected = connect(mapStateToProps);

const addInitAction = withInitAction(['params.id'], {
  prepared: ({ params: { id } }, dispatch) => {
    const formattedId = id?.toUpperCase();
    return Promise.all([
      dispatch(getConsultantGroups(formattedId)),
      dispatch(getConsultantDetail(formattedId)),
    ]).catch(error =>
      error?.response.status === 404
        ? dispatch(historyPush(Pages.UK_PUBLIC_NEAREST_GROUP_LANDING))
        : error,
    );
  },
});

export default compose(addInitAction, connected)(NearestGroupConsultantLanding);
