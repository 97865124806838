/* global 'molecule','SuccessStoryData' */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import SuccessStoryMetric from 'common/src/app/data/enum/SuccessStoryMetric';
import IconName from 'common/src/app/data/enum/IconName';
import Configuration from 'common/src/app/config/Configuration';
import { poundsToKilograms } from 'common/src/app/util/conversionUtils';
import TextNew, { Colors } from '../../atoms/TextNew';
import Icon from '../../atoms/Icon';
import DisplayWeight from '../../atoms/DisplayWeight';
import LocaleMessage from '../../atoms/LocaleMessage';
import './success-story-data.scss';

const SuccessStoryData = (
  { person, age, address, startWeight, currentWeight, timePeriod, height, isUkPublic },
  context,
  className,
) => {
  const startWeightKg = poundsToKilograms(startWeight);
  const currentWeightKg = poundsToKilograms(currentWeight);
  const publicOrMemberLocale = isUkPublic ? 'public' : 'member';

  const data = [
    {
      icon: IconName.BEFORE,
      title: `successStoryData.${publicOrMemberLocale}.startingWeight`,
      type: SuccessStoryMetric.STARTING_WEIGHT,
      /** TODO: https://slimmingworlddigital.atlassian.net/browse/SWO-3261 */
      metric: (
        <DisplayWeight
          weightUnit={Configuration.defaultWeightUnit}
          imperialWeightUseStones={Configuration.imperialWeightUseStones}
          weight={startWeightKg}
        />
      ),
      hasMetric: startWeightKg > 0,
    },
    {
      icon: IconName.CURRENT_WEIGHT,
      title: `successStoryData.${publicOrMemberLocale}.currentWeight`,
      type: SuccessStoryMetric.CURRENT_WEIGHT,
      /** TODO: https://slimmingworlddigital.atlassian.net/browse/SWO-3261 */
      metric: (
        <DisplayWeight
          weightUnit={Configuration.defaultWeightUnit}
          imperialWeightUseStones={Configuration.imperialWeightUseStones}
          weight={currentWeightKg}
        />
      ),
      hasMetric: currentWeightKg > 0,
    },
    {
      icon: IconName.CALENDAR,
      type: SuccessStoryMetric.TIME_PERIOD,
      title: `successStoryData.${publicOrMemberLocale}.timePeriod`,
      metric: (
        <LocaleMessage
          id={`successStoryData.${publicOrMemberLocale}.months`}
          params={{ PERIOD: timePeriod }}
        />
      ),
      hasMetric: timePeriod > 0,
    },
    {
      icon: IconName.HEIGHT,
      type: SuccessStoryMetric.HEIGHT,
      title: `successStoryData.${publicOrMemberLocale}.height`,
      metric: height,
      hasMetric: !!height,
    },
  ];

  return (
    <div className={classNames(className, publicOrMemberLocale)}>
      <div className="title">
        {person && (
          <TextNew.PrimaryElegant color={Colors.BRAND}>
            {person}
            {age ? `, ${age}` : ''}
          </TextNew.PrimaryElegant>
        )}
        {address && <TextNew.PrimaryElegant color={Colors.BRAND}>{address}</TextNew.PrimaryElegant>}
      </div>

      {data
        .filter(
          item =>
            !(
              [SuccessStoryMetric.TIME_PERIOD, SuccessStoryMetric.HEIGHT].includes(item.type) &&
              ['', '0 months'].includes(item.metric)
            ) && item.hasMetric,
        )
        .map(item => (
          <div className="metric" key={item.title}>
            {!isUkPublic && <Icon name={item.icon} />}
            <TextNew.FormalOriginal cid="metric-title" localeId={item.title} />
            <TextNew.Formal cid="metric" color={Colors.BRAND}>
              {item.metric}
            </TextNew.Formal>
          </div>
        ))}
    </div>
  );
};

SuccessStoryData.propTypes = {
  person: PropTypes.string,
  age: PropTypes.number,
  address: PropTypes.string,
  startWeight: PropTypes.number,
  currentWeight: PropTypes.number,
  timePeriod: PropTypes.number,
  height: PropTypes.string,
  isUkPublic: PropTypes.bool,
};

export default withFunctionalClassName('molecule','SuccessStoryData')(SuccessStoryData);
