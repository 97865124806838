/**
 * Returns default transform used to modify entities from the api before they
 * are saved in the redux state. Adds a timestamp, the api path and the action type
 * to the function.
 * @param {string} path The path of the api request
 * @param {string} actionType The action type that was used to make the request
 * @returns {function} A function that transform the
 */
const getDefaultApiEntityTransform = (path, actionType) => apiEntity => ({
  ...apiEntity,
  _source: { type: 'api', path, actionType },
  _updated: Date.now(),
});

export default getDefaultApiEntityTransform;
