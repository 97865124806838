import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import synsToLocale from 'common/src/app/util/SynsToLocale';
import IconName from 'common/src/app/data/enum/IconName';

import TextNew, { Colors } from '../TextNew';
import RecipeDurationDisplay from '../RecipeDurationDisplay';
import Icon from '../Icon';
import LocaleMessage from '../LocaleMessage';

const TileRecipeMeta = ({ totalTime, symbols, syns }, context, className, dataTestid) =>
  totalTime || symbols || syns ? (
    <div className={className} data-testid={dataTestid}>
      {totalTime > 0 && (
        <TextNew.Formal color={Colors.MID_TONE} className="cooking-time">
          <Icon name="time" />
          <RecipeDurationDisplay totalTime={totalTime} />
        </TextNew.Formal>
      )}
      {syns !== undefined && (
        <TextNew.FormalLight color={Colors.PRIMARY_DARK}>
          <LocaleMessage id="general.syns.shortLabel" params={synsToLocale(syns)} />
        </TextNew.FormalLight>
      )}
      {symbols?.includes(IconName.SP) && <Icon name={IconName.SP} height={18} />}
    </div>
  ) : null;

TileRecipeMeta.propTypes = {
  totalTime: PropTypes.number,
  symbols: PropTypes.array,
  syns: PropTypes.number,
};

export default withFunctionalClassName(ComponentType.ATOM, 'TileRecipeMeta')(TileRecipeMeta);
