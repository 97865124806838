import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { commonTrackEvent } from 'common/src/app/actions/trackingActions';
import ImageCarouselWithTextBlock from './ImageCarouselWithTextBlock';

const connected = connect(null, {
  commonTrackEvent,
});

export default compose(connected)(ImageCarouselWithTextBlock);
