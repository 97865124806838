import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction, prepareComponents } from '@mediamonks/react-redux-component-init';
import Pages from 'common/src/app/data/enum/Pages';
import { openModal } from 'common/src/app/actions/components/modalActions';
import { setTrackingPersistentData } from 'common/src/app/actions/trackingActions';
import DataLayerKeys from 'common/src/app/data/enum/Tracking/DataLayerKeys';
import UkPublicFooter from 'components/organisms/UkPublicFooter';
import { hasWebpSupport } from 'common/src/app/actions/featureSupportActions';
import ContactUs from 'components/pages/ContactUs';
import { setPersistHideNav } from 'common/src/app/actions/resources/publicActions';
import GroupOnlineSignUp from 'components/molecules/GroupOnlineSignUp';
import CardRegistration from 'components/molecules/UkPublicNavigation/components/molecules/CardRegistration';
import NotFound from 'components/templates/NotFound';
import NewsletterSignUp from '../molecules/NewsletterSignUp';
import Application from './Application';

const mapStateToProps = (state, { location }) => {
  const queries = state.routing.locationBeforeTransitions.query;
  const persisting = state.persistHideNav?.hideNav;
  const persistHideNav = queries?.persistHideNav;
  const isConsultantRoute = location?.pathname?.includes(Pages.UK_PUBLIC_BECOME_A_CONSULTANT);

  return {
    hasGeneralError: state.error && !!state.error.length,
    hideNavigation: (queries && (queries.hideNav || queries.persistHideNav)) || persisting,
    persistHideNav,
    isConsultantRoute,
  };
};

const mapDispatchToProps = dispatch => ({
  openModal,
  hasWebpSupport,
  setPersistNav: () => dispatch(setPersistHideNav(true)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction(({}, dispatch) =>
  Promise.all([
    dispatch(
      prepareComponents([
        GroupOnlineSignUp,
        CardRegistration,
        ContactUs,
        UkPublicFooter,
        NewsletterSignUp,
        NotFound,
      ]),
    ),
    dispatch(
      setTrackingPersistentData({
        [DataLayerKeys.ROLE]: 'Public',
      }),
    ),
  ]),
);

export default compose(addInitAction, connected)(Application);
