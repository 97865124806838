/* global 'atom','TitledBlock' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Row from '../Row';

import './titled-block.scss';

const TitledBlock = ({ title, children }, context, className) => (
  <Row column className={className}>
    {title}
    {children}
  </Row>
);

TitledBlock.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
};

export default withFunctionalClassName('atom','TitledBlock')(TitledBlock);
