import React, { useRef, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { FieldArray, useFormikContext } from 'formik';
import compositeInputFormatters from '../../../enhanced-redux-form/compositeInputFormatters';
import { COMPOSITE_FORMSECTION_NAME_PREFIX } from '../../../enhanced-redux-form/data/constants';

/* eslint-disable consistent-return */
const validateFormatterProp = (props, propName, componentName) => {
  const formatter = props[propName];

  if (!formatter.name || !formatter.formatter) {
    return new Error(
      `Invalid prop '${propName}' supplied to '${componentName}'. ${propName} should be of shape {name:string, formatter:<formatter config>}`,
    );
  }

  if (!compositeInputFormatters[formatter.name]) {
    return new Error(
      `Invalid prop '${propName}' supplied to '${componentName}'. ${formatter.name} does not exist in compositeInputFormatters.js`,
    );
  }
};
/* eslint-enable consistent-return */

export const CompositeFormFieldContext = createContext({
  prefixName: '',
});

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const CompositeFormField = ({
  name,
  children,
  value,
  values,
  formatter,
  composeCompositeFormField,
  decomposeCompositeFormField,
}) => {
  const compositeFormName = `${COMPOSITE_FORMSECTION_NAME_PREFIX}${name}`;
  const { setFieldValue } = useFormikContext();
  const props = { name, value, values, formatter };
  const prevProps = usePrevious(props);

  useEffect(() => {
    if (values?.[name]) {
      decomposeCompositeFormField({ currentProps: { name, values, formatter }, setFieldValue });
    }
  }, [decomposeCompositeFormField, formatter, name, setFieldValue, values]);

  useEffect(() => {
    if (
      prevProps?.name !== props.name ||
      prevProps?.formatter !== props.formatter ||
      !isEqual(props.values[compositeFormName], prevProps?.values?.[compositeFormName])
    ) {
      composeCompositeFormField({
        currentProps: props,
        prevProps,
        setFieldValue,
      });
    } else if (prevProps?.value !== props.value) {
      decomposeCompositeFormField({
        currentProps: props,
        prevProps,
        setFieldValue,
      });
    }
  }, [
    composeCompositeFormField,
    compositeFormName,
    decomposeCompositeFormField,
    prevProps,
    props,
    setFieldValue,
  ]);

  return (
    <CompositeFormFieldContext.Provider
      value={{
        prefixName: `${COMPOSITE_FORMSECTION_NAME_PREFIX}${name}`,
      }}
    >
      <FieldArray name={`${COMPOSITE_FORMSECTION_NAME_PREFIX}${name}`}>{children}</FieldArray>
    </CompositeFormFieldContext.Provider>
  );
};

CompositeFormField.propTypes = {
  /*
   * The name of the combined input
   */
  name: PropTypes.string.isRequired,
  /*
   * The value of this CompositeInput in the redux state. Passed by the connect() wrapper
   */
  value: PropTypes.any,
  /*
   * The name of the wrapping form. Passed by the CompositeInputWrapper
   */
  form: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  /*
   * A formatter definition to use to combine the input values into a single composite
   * value. Should be one of the formatters defined in 'compositeInputFormatters.js'
   */
  formatter: validateFormatterProp,
  decomposeCompositeFormField: PropTypes.func.isRequired,
  composeCompositeFormField: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  children: PropTypes.node,
  values: PropTypes.object,
};

export default CompositeFormField;
