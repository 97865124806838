import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { manualSetSEO } from 'common/src/app/actions/seoActions';
import ManualSeo from './ManualSeo';

const mapDispatchToProps = dispatch => ({
  setSeo: seoObject => dispatch(manualSetSEO(seoObject)),
});

const connected = connect(null, mapDispatchToProps);

export default compose(connected)(ManualSeo);
