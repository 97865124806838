import React from 'react';
import PropTypes from 'prop-types';
import GroupOnlineSignUp from 'components/molecules/GroupOnlineSignUp';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import Image from 'components/atoms/Image';
import classNames from 'classnames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState, { DeviceState } from 'common/src/app/util/device-state/withDeviceState';
import TextNew, { ElementTypes, FontWeight, Colors } from 'components/atoms/TextNew';

import dannRoundel from './img/dann-roundel.svg';

import './body-magic.scss';

const imageRatiosMap = (index, deviceState) => {
  if (index === 2 && deviceState > DeviceState.LG) {
    return 428 / 813;
  }

  if (deviceState > DeviceState.LG) {
    return 428 / 563;
  }

  if (deviceState > DeviceState.MD) {
    return 64 / 25;
  }

  return 1 / 1;
};

const BodyMagic = ({ page, deviceState }, context, className) => {
  const [header, tabletHeader, ...nestedContent] = page?.nestedContent || [];

  return (
    <div className={className}>
      <HeroBarPublic
        headerRatio={deviceState > DeviceState.LG ? 18 / 5 : undefined}
        image={deviceState > DeviceState.LG ? header?.image : tabletHeader?.image}
      >
        {page?.title && (
          <TextNew.Serif.SM cid="hero-bar-public-serif-heading" weight={FontWeight.BOLD}>
            {page.title}
          </TextNew.Serif.SM>
        )}
        {page?.description && (
          <TextNew.Script.LG
            element={ElementTypes.H1}
            cid="hero-bar-public-script-heading"
            color={Colors.BRAND}
          >
            {page.description}
          </TextNew.Script.LG>
        )}
      </HeroBarPublic>
      <div className="body-magic-content">
        {nestedContent?.map((item, index) =>
          // eslint-disable-next-line no-underscore-dangle
          item._type === 'RichTextWithImageBlock' ? (
            <div
              key={item.image.id}
              className={classNames(
                'body-magic-text-image',
                `align-image-${item.imageHorizontalPosition.toLowerCase()}`,
                `align-image-${item.imageVerticalPosition.toLowerCase()}`,
              )}
            >
              <RichTextBlock text={item.text} />
              <Image ratio={imageRatiosMap(index, deviceState)} {...item.image}>
                {index === 1 ? (
                  <img src={dannRoundel} alt="Dann lost 5st 2lb in 12 months" className="roundel" />
                ) : null}
              </Image>
            </div>
          ) : (
            <RichTextBlock cid={`body-magic-rich-text-${index + 1}`} text={item.text} key={index} />
          ),
        )}
      </div>
      <GroupOnlineSignUp />
    </div>
  );
};

BodyMagic.propTypes = {
  deviceState: PropTypes.number,
  page: PropTypes.object,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.PAGE, 'BodyMagic')(BodyMagic),
);
