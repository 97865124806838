import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import PodcastLanding from 'bundle-loader?lazy&reactHot&name=PodcastLanding!./components/templates/PodcastLanding';

const routes = (
  <Route
    title="Podcast | {pageTitle}"
    description="The Slimming World podcast."
    path={Pages.UK_PUBLIC_PODCASTS}
    {...getRouteComponentProp(PodcastLanding)}
  />
);

export default routes;
