import handleActions from 'redux-actions/lib/handleActions';
import { REGISTER_INSTAGRAM_POSTS } from '../../../actions/components/instagramTrendingActions';

const initialState = {
  posts: [],
};

export default handleActions(
  {
    [REGISTER_INSTAGRAM_POSTS]: (state, { payload }) => ({
      ...state,
      posts: payload,
    }),
  },
  initialState,
);
