import React from 'react';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import Link from 'react-router/lib/Link';
import { headOfficeNumber } from 'common/src/app/config/market/market.configdefinitions';
import { Department } from 'common/src/app/data/enum/DepartmentPhoneNumbersInfo';
import TextNew, { Colors, FontWeight, ElementTypes } from '../../atoms/TextNew';

import OpeningTimes from '../OpeningTimes';

import './contact-us-head-office.scss';

const ContactUsHeadOffice = ({}, context, className, dataTestid) => (
  <div className={className} data-testid={dataTestid}>
    <TextNew.Serif.SM
      localeId="contactUsHeadOffice.title"
      color={Colors.BRAND}
      dataTestid="head-office-title"
      cid="contact-section-title"
    />

    <TextNew.Sans.SM localeId="contactUsHeadOffice.address.intro" weight={FontWeight.BOLD} />
    <TextNew.Sans.SM
      dataTestid="contact-us-head-office-address"
      element={ElementTypes.ADDRESS}
      localeId="contactUsHeadOffice.address.address"
      weight={FontWeight.LIGHT}
    />

    {/* get the phone number from the market config:
    - this ensures that we will get the correct one, for UK and for IE */}
    <TextNew.Sans.SM
      localeId="contactUsHeadOffice.call.intro"
      weight={FontWeight.BOLD}
      cid="contact-us-head-office-number"
      dataTestid="head-office-phone-number"
    >
      <Link className="phone-number-link" href={`tel:${headOfficeNumber}`}>
        <TextNew.Sans.SM weight={FontWeight.LIGHT} color={Colors.BRAND} element={ElementTypes.SPAN}>
          {headOfficeNumber}
        </TextNew.Sans.SM>
      </Link>
    </TextNew.Sans.SM>

    <OpeningTimes department={Department.HEAD_OFFICE} />
  </div>
);

export default withFunctionalClassName(
  ComponentType.ATOM,
  'ContactUsHeadOffice',
)(ContactUsHeadOffice);
