import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew from '../../atoms/TextNew';

const PaginationLabel = (
  { pageSize: LIMIT, total: TOTAL, showLoadAll, loadAll },
  context,
  className,
) => (
  <div className={className}>
    <TextNew.PrimaryFootnote
      localeId="paginationLabel.pagination"
      localeParams={{ LIMIT, TOTAL }}
    />
    {showLoadAll && (
      <Link className="show-all" onClick={() => loadAll({ limit: 0 })}>
        <TextNew.PrimaryFootnote localeId="paginationLabel.showAll" />
      </Link>
    )}
  </div>
);

PaginationLabel.propTypes = {
  pageSize: PropTypes.number,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showLoadAll: PropTypes.bool,
  loadAll: PropTypes.func,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'PaginationLabel')(PaginationLabel);
