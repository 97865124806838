import commonTracking from 'common/src/client/redux-listeners/common.tracking';
import menuSignupTracking from 'common/src/client/redux-listeners/menuSignup.tracking';
import latlongTracking from 'common/src/client/redux-listeners/latlong.tracking';
import bmiCalculatorTracking from 'common/src/client/redux-listeners/bmiCalculator.tracking';
import recipesTracking from 'common/src/client/redux-listeners/searchRecipes.tracking';

export default addListener => {
  commonTracking(addListener);
  menuSignupTracking(addListener);
  latlongTracking(addListener);
  bmiCalculatorTracking(addListener);
  recipesTracking(addListener);
};
