/* global 'organism','FullWidthInformationBar' */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Wrapper from '../../atoms/Wrapper';

import './full-width-information-bar.scss';

const FullWidthInformationBar = ({ title, content, color, customClass }, context, className) => (
  <div
    className={classNames(className, color, {
      [customClass]: customClass,
    })}
  >
    <Wrapper width="lg" padding="md">
      <div className="section-title">{title}</div>
      <div className="section-content">{content}</div>
    </Wrapper>
  </div>
);

FullWidthInformationBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customClass: PropTypes.string,
};

export default withFunctionalClassName('organism','FullWidthInformationBar')(FullWidthInformationBar);
