import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction, prepareComponent } from '@mediamonks/react-redux-component-init';
import { countyBasedGroupSearch } from 'common/src/app/actions/resources/groupSearchActions';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { nearestGroupResultsSelector } from 'common/src/app/selectors/nearestGroupSearchSelectors';
import { MATCH_HYPHENS } from 'common/src/app/data/regexPatterns';
import EmptySearchResults from '../NearestGroupLanding/components/organisms/EmptySearchResults';
import NearestGroupsInATown from './NearestGroupsInATown';

const mapStateToProps = state => ({
  groups: nearestGroupResultsSelector(state),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction(['params.town'], {
  prepared: ({ params: { town } }, dispatch) => {
    const promises = [dispatch(prepareComponent(EmptySearchResults))];

    if (town) {
      const formatTown = town?.replace(MATCH_HYPHENS, ' ');
      promises.push(
        dispatch(countyBasedGroupSearch(formatTown, marketConfig.marketName, 0, 0, null, 'town')),
      );
    }
    return Promise.all(promises);
  },
});

export default compose(addInitAction, connected)(NearestGroupsInATown);
