import PropTypes from 'prop-types';

const healthyExtraShape = {
  productName: PropTypes.string.isRequired,
  synValue: PropTypes.number.isRequired,
  isHealthyExtraChoiceA: PropTypes.bool.isRequired,
  isHealthyExtraChoiceB: PropTypes.bool.isRequired,
};

export default healthyExtraShape;
