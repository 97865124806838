import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { API_STATIC_PUBLIC_HOW_IT_WORKS_PAGE } from 'common/src/app/data/apiStatics';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { scrollToElement } from 'common/src/app/actions/scrollActions';
import HowItWorks from './HowItWorks';

const mapStateToProps = (state, { location: { hash } }) => ({
  hash,
  headerHeight: state.view.components.header?.height || 0,
  data: staticPageSelector(state, API_STATIC_PUBLIC_HOW_IT_WORKS_PAGE),
});

const mapDispatchToProps = dispatch => ({
  scrollToElement: (...args) => dispatch(scrollToElement(...args)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getStaticContent(API_STATIC_PUBLIC_HOW_IT_WORKS_PAGE, true)),
});

export default compose(addInitAction, connected)(HowItWorks);
