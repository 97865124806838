/* global WP_DEFINE_IS_NODE */
import Pages from '../data/enum/Pages';
import { setSEO } from '../actions/seoActions';

const corePages = [
  Pages.UK_PUBLIC_HOME,
  Pages.UK_PUBLIC_HOW_IT_WORKS,
  Pages.UK_PUBLIC_OFFERS,
  Pages.UK_PUBLIC_OUR_STORY,
  Pages.UK_PUBLIC_RECIPE_SEARCH,
  Pages.UK_PUBLIC_SCIENCE,
  Pages.UK_PUBLIC_WHAT_CAN_I_EAT,
  Pages.UK_PUBLIC_SUCCESS_STORIES,
  Pages.UK_PUBLIC_WHAT_HAPPENS_IN_GROUP,
  Pages.UK_PUBLIC_ONLINE_INFORMATION,
  Pages.UK_PUBLIC_BMI_CALCULATOR,
  Pages.UK_PUBLIC_COMPARISON,
  Pages.UK_PUBLIC_NEAREST_GROUP_LANDING,
  Pages.UK_PUBLIC_BECOME_A_CONSULTANT,
  Pages.UK_PUBLIC_BECOME_A_CONSULTANT_OUR_CONSULTANTS,
  Pages.UK_PUBLIC_BECOME_A_CONSULTANT_OUR_FRANCHISE_PACKAGE,
  Pages.UK_PUBLIC_BECOME_A_CONSULTANT_FRANCHISE_FACTS,
];

const nonApiMetaDataPages = [Pages.UK_PUBLIC_NEAREST_GROUP_LANDING];

const shouldSuffixPageTitle = pathname => {
  if (corePages.includes(pathname)) {
    return '';
  }

  return '| Slimming World';
};

export default ({ getState, dispatch }) => next => action => {
  if (action.payload?.data?.seo?.metaTitle) {
    const {
      routing: { locationBeforeTransitions },
    } = getState();

    const seo = action.payload.data.seo;

    // Checks if current page uses hard-coded metadata and has no search queries
    if (
      nonApiMetaDataPages.includes(locationBeforeTransitions.pathname) &&
      locationBeforeTransitions.search === ''
    ) {
      return next(action);
    }

    dispatch(setSEO(seo, locationBeforeTransitions.pathname));
  }

  if (!WP_DEFINE_IS_NODE) {
    const state = getState();
    const location = state.routeHistory[0];

    if (state.seo[location.pathname] && state.seo[location.pathname].metaTitle) {
      document.title = `${state.seo[location.pathname].metaTitle} ${shouldSuffixPageTitle(
        location?.pathname,
      )}`;
    }
  }

  return next(action);
};
