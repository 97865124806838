/* global 'molecule','LocationFinderMap' */
import React from 'react';
import PropTypes from 'prop-types';
import IndexedPin from 'components/atoms/IndexedPin';
import GoogleMapReact from 'google-map-react';

import IconName from 'common/src/app/data/enum/IconName';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Icon from 'components/atoms/Icon';
import TextNew, { FontWeight } from 'components/atoms/TextNew';

import './location-finder-map.scss';

const LocationFinderMap = ({ address, latLg, title }, context, className) => (
  <div className={className}>
    <div className="location-box">
      <Icon name={IconName.MAP_PIN} width={17} />
      <TextNew.Sans.SM weight={FontWeight.LIGHT}>{address}</TextNew.Sans.SM>
    </div>
    <GoogleMapReact
      defaultZoom={15}
      bootstrapURLKeys={{ key: 'AIzaSyATzCPYClLDZvNjmBLFUD27tDOhI31cVAY' }}
      center={latLg}
      defaultCenter={latLg}
    >
      {latLg && <IndexedPin lat={latLg.lat} lng={latLg.lng} title={title} />}
    </GoogleMapReact>
  </div>
);

LocationFinderMap.propTypes = {
  address: PropTypes.string,
  latLg: PropTypes.object,
  title: PropTypes.string,
};

export default withFunctionalClassName('molecule','LocationFinderMap')(LocationFinderMap);
