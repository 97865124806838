/**
 * Contains constants for different data pagination types for collections.
 * @module paginationType
 * @category api-calls
 */

/**
 * No pagination. This means that the entire collection is retrieved in a single API call
 * @type {string}
 */
export const PAGINATION_NONE = 'NONE';

/**
 * Offset-based pagination. Entities in this collection are loaded in chunks from the api by
 * using the `offset` and `limit` parameters
 * @type {string}
 */
export const PAGINATION_OFFSET = 'OFFSET';

/**
 * The collection is a partition of the total collection. This is used when making api requests
 * using cursor-based pagination. Unlike `PAGINATION_OFFSET` this pagination does not
 * contain an `offset`, because the offset of the data is unknown.
 * @type {string}
 */
export const PAGINATION_PARTITION = 'PARTITION';
