import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import './toggle-new.scss';

export const ToggleTypes = {
  CLASSIC: 'classic',
  CONTEMPORARY: 'contemporary',
};

const ToggleNew = (
  {
    leftlabel,
    leftname,
    rightlabel,
    rightname,
    field: { name, value, onBlur },
    dataTestIdLeft,
    dataTestIdRight,
    onClick,
  },
  context,
  className,
) => {
  const { setFieldValue } = useFormikContext();
  const setValue = () => {
    setFieldValue(name, value === leftname ? rightname : leftname);
    onClick && onClick();
  };

  return (
    <div className={className}>
      <input
        id={`toggle-${name}-${leftname}`}
        aria-label={leftlabel}
        data-testid={dataTestIdLeft}
        name={name}
        type="radio"
        onBlur={onBlur}
        onChange={setValue}
        checked={value === leftname}
        value={leftname}
      />
      <label htmlFor={`toggle-${name}-${leftname}`}>{leftlabel}</label>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */}
      <a tabIndex="-1" className="toggle-slider" onClick={setValue}>
        <div
          className={`toggle-ball ${value === leftname ? DirectionType.LEFT : DirectionType.RIGHT}`}
        />
      </a>
      <input
        id={`toggle-${name}-${rightname}`}
        aria-label={rightlabel}
        data-testid={dataTestIdRight}
        name={name}
        type="radio"
        onBlur={onBlur}
        onChange={setValue}
        checked={value === rightname}
        value={rightname}
      />
      <label htmlFor={`toggle-${name}-${rightname}`}>{rightlabel}</label>
    </div>
  );
};

ToggleNew.propTypes = {
  leftlabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  leftname: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rightlabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  rightname: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  field: PropTypes.object,
  dataTestIdLeft: PropTypes.string,
  dataTestIdRight: PropTypes.string,
  onClick: PropTypes.func,
};

ToggleNew.defaultProps = {
  type: ToggleTypes.CLASSIC,
};

export default withFunctionalClassName(ComponentType.ATOM, 'ToggleNew', ['{type}-type'])(ToggleNew);
