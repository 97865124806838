import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import SuccessStoriesLanding from 'bundle-loader?lazy&reactHot&name=SuccessStoriesLanding!./components/pages/SuccessStoriesLanding';
import SuccessStoriesDetail from 'bundle-loader?lazy&reactHot&name=SuccessStoriesDetail!./components/pages/SuccessStoriesDetail';

const defaults = {
  title: 'Real-life weight loss success stories | {pageTitle}',
  description:
    'Be inspired by the amazing weight loss stories of Slimming World members. We can help you achieve your dream weight loss transformation, too!',
};

const routes = (
  <Route title="{pageTitle}">
    <Route
      path={Pages.UK_PUBLIC_SUCCESS_STORIES_FEMALE_FILTER}
      {...getRouteComponentProp(SuccessStoriesLanding)}
      {...defaults}
      description="Be inspired by the amazing weight loss stories of our Slimming World women. We can help you achieve your dream weight loss transformation, too!"
    />
    <Route
      path={Pages.UK_PUBLIC_SUCCESS_STORIES_MALE_FILTER}
      {...getRouteComponentProp(SuccessStoriesLanding)}
      {...defaults}
      description="Be inspired by the amazing weight loss stories of our Slimming World men. We can help you achieve your dream weight loss transformation, too!"
    />
    <Route
      path={Pages.UK_PUBLIC_SUCCESS_STORIES}
      {...getRouteComponentProp(SuccessStoriesLanding)}
      {...defaults}
    />
    <Route
      path={Pages.UK_PUBLIC_SUCCESS_STORIES_DETAIL}
      {...getRouteComponentProp(SuccessStoriesDetail)}
    />
  </Route>
);

export default routes;
