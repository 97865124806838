import React from 'react';
import PropTypes from 'prop-types';
import { toArray } from 'common/src/app/util/bitwiseUtils';
import IconName from 'common/src/app/data/enum/IconName';
import Icon from 'components/atoms/Icon';
import GroupField from 'components/atoms/GroupField';
import { NearestGroupSearchAccessibilityFlags } from 'common/src/app/data/enum/GroupSearchFilterFlags';
import TextNew, { AccentColors, FontSizes, FontWeight } from 'components/atoms/TextNew';

import withDeviceState, { QueryHelpers } from 'common/src/app/util/device-state/withDeviceState';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import './venue-accessibility.scss';

const VenueAccessibility = (
  { venueAccessibility, textStyle, headerTextStyle, queryHelper },
  {},
  className,
  dataTestid,
) => {
  const { weight, size, color } = textStyle || {};

  const venueAccessibilityFlags =
    venueAccessibility && toArray(NearestGroupSearchAccessibilityFlags, venueAccessibility);

  const venueAccessibilityLabelTextStyles = {
    weight: weight || (queryHelper !== QueryHelpers.TABLET ? FontWeight.LIGHT : FontWeight.NORMAL),
    size: size || (queryHelper === QueryHelpers.TABLET ? FontSizes.XS : FontSizes.SM),
    color: color || AccentColors.ACCENT_GREY,
  };

  return (
    <div className={className} data-testid={dataTestid}>
      <GroupField textStyle={headerTextStyle} label="nearestGroupTile.venueAccessibility">
        <div className="venue-accessibility-container-items">
          {venueAccessibilityFlags &&
            venueAccessibilityFlags.map((value, index) => (
              <div className="venue-accessibility-container" key={`${value}-${index}`}>
                <Icon name={IconName.CHECKMARK} width={10} cid="venue-accessibility-icon" />
                <TextNew.Sans
                  {...venueAccessibilityLabelTextStyles}
                  cid="venue-accessibility-label-option"
                  color={color || AccentColors.ACCENT_GREY}
                  localeId={`venueAccessibility.options.${value}`}
                  dataTestid={`venue-accessibility-${value}`}
                />
              </div>
            ))}
        </div>
      </GroupField>
    </div>
  );
};

VenueAccessibility.propTypes = {
  // The combined bitwise value for all available options
  venueAccessibility: PropTypes.number.isRequired,
  queryHelper: PropTypes.string,
  textStyle: PropTypes.object,
  headerTextStyle: PropTypes.object,
};

VenueAccessibility.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'VenueAccessibility')(VenueAccessibility),
);
