import moment from 'moment';

const modifiedGroupTimesFormatted = ({ session }) => {
  // Format the time in 24 hour format
  const timeFormattedArray =
    session &&
    session.sessions.map(time =>
      moment(time, 'HH:mm:SS')
        .format('LT')
        .replace(' ', ''),
    );

  // If there is a single time - return the single time
  if (timeFormattedArray && timeFormattedArray.length === 1) {
    return `${timeFormattedArray[0]}`;
  }

  // If there are two times - add `and` inbetween
  if (timeFormattedArray && timeFormattedArray.length === 2) {
    return `${timeFormattedArray[0]} and ${timeFormattedArray[1]}`;
  }
  // Create a copy of the times array and remove the last item
  const timeFormattedArrayLastItem = [...timeFormattedArray];
  timeFormattedArrayLastItem.pop();

  // Return each time with a `,` and for the last time add an `and` before it
  return `${timeFormattedArrayLastItem.join(', ')} and ${
    timeFormattedArray[timeFormattedArray.length - 1]
  }`;
};

export default modifiedGroupTimesFormatted;
