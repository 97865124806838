import PropTypes from 'prop-types';

/** @module */

/**
 * Reusable object to use in `propTypes` for a `featurdPodcast` prop.
 * @type {Object}
 * @category templating
 */
const featuredPodcastShape = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.isRequired,
  description: PropTypes.isRequired,
  link: PropTypes.isRequired,
};

export default featuredPodcastShape;
