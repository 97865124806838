import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import VideoPropTypes from 'common/src/app/util/proptypes/apiEntities/video';
import trackingShape from 'common/src/app/util/proptypes/apiEntities/tracking';
import CloseButton from '../../molecules/CloseButton';
import Video from '../../molecules/Video';

import './video-modal.scss';

const VideoModal = (
  {
    video: { _type: videoType, ...video },
    trackingData,
    closeModal,
    closeCallback,
    brightcoveAccountId,
    brightcovePlayerId,
    trackVideoEvent,
  },
  context,
  className,
) => {
  const closeButtonRef = useRef();

  useEffect(() => {
    closeButtonRef.current.focus();
  });
  return (
    <div className={className}>
      <CloseButton onClick={closeCallback || closeModal} reference={closeButtonRef} />
      <Video
        video={video}
        videoType={videoType}
        brightcoveAccountId={brightcoveAccountId}
        brightcovePlayerId={brightcovePlayerId}
        trackingData={trackingData}
        trackVideoEvent={trackVideoEvent}
      />
    </div>
  );
};

VideoModal.propTypes = {
  ...VideoPropTypes,
  brightcoveAccountId: PropTypes.string,
  brightcovePlayerId: PropTypes.string,
  trackingData: PropTypes.shape(trackingShape),
  trackVideoEvent: PropTypes.func,
  closeModal: PropTypes.func,
  closeCallback: PropTypes.func,
};

export default withFunctionalClassName(ComponentType.ORGANISM, 'VideoModal')(VideoModal);
