import React from 'react';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import ComponentType from 'common/src/app/data/enum/ComponentType';

// Utils
import abbreviate from 'common/src/app/util/locale/abbreviate';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

// Enums
import { measurementFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import MeasureType from 'common/src/app/data/enum/MeasureType';

// Components
import EnhancedFormField from 'common/src/app/formik/components/EnhancedFormField';
import FormGroup from '../../molecules/FormGroup';
import Row from '../../atoms/Row';
import LocaleMessage from '../../atoms/LocaleMessage';
import InformationToggle from '../../molecules/InformationToggle';
import MeasurementInputField from '../../organisms/MeasurementInputField';
import FormikError from '../../atoms/FormikError';
import ToggleNew from '../../atoms/ToggleNew';

import './height-input-field.scss';

/**
 * Height input, including InformationToggle and Unit selector
 * Membertype - the type of member you are displaying this message to e.g. 'group' or 'online
 * Used to switch out BMI information message
 */
const HeightInputField = (
  { memberType, deviceState, viewSettings, fieldName, ...props },
  context,
  className,
) => {
  const { measurementUnit, fieldLabel, informationToggle } = viewSettings;

  const {
    customLabel,
    formik: { values, setFieldValue },
  } = props;
  const localeMsg = abbreviate(deviceState);

  return (
    <FormGroup className={className}>
      {measurementUnit && (
        <Row aligncenter spacebetween>
          {fieldLabel && (
            <legend className="atom-label">
              {customLabel || <LocaleMessage id="medicalCheck.groupLabels.height" />}
            </legend>
          )}
          <EnhancedFormField
            type={props?.type}
            name={measurementFields.HEIGHT_UNIT}
            component={ToggleNew}
            legend={<LocaleMessage id="heightInput.legend" />}
            leftlabel={<LocaleMessage id={`general.measureUnit.height.feet.${localeMsg}`} />}
            leftname={MeasureUnit.IMPERIAL}
            rightlabel={<LocaleMessage id={`general.measureUnit.height.meters.${localeMsg}`} />}
            rightname={MeasureUnit.METRIC}
            onClick={() =>
              // Reset the weight values when switching
              [
                'feet',
                'inches',
                'halfInch',
                'metres',
                'centimeters',
                [fieldName],
              ].forEach(element => setFieldValue(element, null, false))
            }
            dataTestIdLeft={`${measurementFields.HEIGHT_UNIT}-${MeasureUnit.IMPERIAL}`}
            dataTestIdRight={`${measurementFields.HEIGHT_UNIT}-${MeasureUnit.METRIC}`}
          />
        </Row>
      )}

      <MeasurementInputField
        name={fieldName}
        unit={values?.heightUnit}
        type={MeasureType.HEIGHT}
        {...props}
      />
      <FormikError name={fieldName} />

      {informationToggle && (
        <InformationToggle
          label={<LocaleMessage id={`medicalCheck.weightHeight.informationToggleLabel`} />}
          information={
            <LocaleMessage id={`medicalCheck.weightHeight.information${upperFirst(memberType)}`} />
          }
        />
      )}
    </FormGroup>
  );
};

HeightInputField.propTypes = {
  deviceState: PropTypes.number.isRequired,
  memberType: PropTypes.string,
  viewSettings: PropTypes.object,
  fieldName: PropTypes.string,
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
  }),
  customLabel: PropTypes.node,
};

HeightInputField.defaultProps = {
  fieldName: measurementFields.HEIGHT,
  viewSettings: {
    informationToggle: true,
    measurementUnit: true,
    fieldLabel: true,
  },
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'HeightInput')(HeightInputField),
);
