/* global 'template','IcelandOurProducts' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Loader from 'components/atoms/Loader';
import IcelandOurPagesContent from '../IcelandOurPagesContent';

import './iceland-our-products.scss';

const IcelandOurProducts = ({ pageData }, context, className) => (
  <section className={className}>
    {pageData ? (
      <IcelandOurPagesContent headerRatio={1920 / 683} pageData={pageData} />
    ) : (
      <Loader />
    )}
  </section>
);

IcelandOurProducts.propTypes = {
  pageData: PropTypes.object,
};

export default withFunctionalClassName('template','IcelandOurProducts')(IcelandOurProducts);
