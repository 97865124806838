import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { supportEmail } from 'common/src/app/config/market/market.configdefinitions';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import detectUrl from 'common/src/app/util/messageParser/detectUrl';
import { SEGMENT_RAW, SEGMENT_URL } from 'common/src/app/data/enum/SegmentType';
import ApplicationError from '../ApplicationError';
import TextNew, { Colors } from '../../atoms/TextNew';
import LocaleMessage from '../../atoms/LocaleMessage';

const GeneralError = ({ error = {}, inlineError = false }, { getMessage, hasMessage }) => {
  const customErrorMessage =
    error.code && hasMessage(error.code) ? getMessage(error.code) : error.message;

  const errorMessageParsed =
    customErrorMessage && detectUrl()([{ type: SEGMENT_RAW, data: customErrorMessage }]);

  const className = functionalComponentClassName(ComponentType.PAGE, 'GeneralError');

  return (
    <div className={className}>
      {inlineError ? (
        <TextNew.Error localeId="general.error.inline" color={Colors.BRAND} />
      ) : (
        <ApplicationError>
          <TextNew.Script.XS localeId="general.error.title" />
          {customErrorMessage ? (
            <TextNew.PrimaryElegant>
              {errorMessageParsed.map((segment, index) => {
                switch (segment.type) {
                  case SEGMENT_URL:
                    return (
                      <a href={segment.data.url} target="_blank" key={index}>
                        {segment.data.text}
                      </a>
                    );
                  default:
                    return segment.data;
                }
              })}
            </TextNew.PrimaryElegant>
          ) : (
            <TextNew.PrimaryElegant localeId="general.error.description" />
          )}
          <TextNew.PrimaryElegant>
            <LocaleMessage id="general.error.contactSupport" />
            <Link href={`mailto:${supportEmail}`} target="_blank">
              {` ${supportEmail}`}
            </Link>
          </TextNew.PrimaryElegant>
        </ApplicationError>
      )}
    </div>
  );
};

GeneralError.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string,
  }),
  inlineError: PropTypes.bool,
};

GeneralError.contextTypes = {
  getMessage: PropTypes.func.isRequired,
  hasMessage: PropTypes.func.isRequired,
};

export default GeneralError;
