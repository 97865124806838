import React from 'react';
import classNames from 'classnames';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import kebabCase from 'lodash/kebabCase';
import reducePublicGroupSessions from 'common/src/app/util/ReducePublicGroupSessions';
import Pages from 'common/src/app/data/enum/Pages';
import Row from 'components/atoms/Row';
import TextNew, {
  ElementTypes,
  FontSizes,
  FontWeight,
  AccentColors,
  Colors,
} from 'components/atoms/TextNew';
import { consultantShape } from 'common/src/app/util/proptypes/apiEntities/groupSearchShape';
import Address from 'components/molecules/Address';
import StructuredDataGenerator, { types } from 'components/atoms/StructuredDataGenerator';
import GroupTimetable from 'components/molecules/GroupTimetable';
import withDeviceState, { QueryHelpers } from 'common/src/app/util/device-state/withDeviceState';
import { kmToMiles } from 'common/src/app/util/conversionUtils';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import ModifiedGroupTimetable from 'components/molecules/ModifiedGroupTimetable';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import GroupField from 'components/atoms/GroupField';
import NearestGroupSessionMessages from 'components/organisms/NearestGroupSessionMessages';

import VenueAccessibility from '../../../../../molecules/VenueAccessibility';
import './nearest-group-tile.scss';

const NearestGroupTile = (
  {
    item,
    staticConsultantDetail,
    item: {
      id,
      distance,
      venue = {},
      sessions = [],
      consultant,
      consultant: { name: consultantName, telephone } = {},
      events,
    },
    isCyprus,
    queryHelper,
  },
  context,
  className,
) => {
  const obj = {
    town: venue.town,
    area: venue.address3,
    road: venue.address1,
    id,
  };

  // This function will iteratively match tokens in the nearest group detail url.
  // Required tokens look like /:id and optional ones (:/city) - the keys are listed in the object above.
  // We want to replace the param with either the corresponding venue value or an empty value.
  // e.g. Matching against /group(/:city)(/:town)/:id => may produce any of { /group/alfreton/somercotes/1, /group/alfreton/1, /group/somercotes/1, /group/1 } depending on what values are null or empty.
  const link = Object.keys(obj).reduce((pv, key) => {
    const pattern = `\\(?\\/:${key}\\)?`;
    const re = new RegExp(pattern, 'g');

    const replaceWith = obj[key] === undefined || obj[key] === '' ? '' : `/${kebabCase(obj[key])}`;
    return pv.replace(re, replaceWith);
  }, Pages.UK_PUBLIC_NEAREST_GROUP_DETAIL);

  const groupedSessions = reducePublicGroupSessions(sessions);

  // If static consultant data is passed add this to the items (this is
  // used on the consultant landing pages when that data is not part of the object)
  const itemWithStaticConsultantDetail = { ...item, consultant: { ...staticConsultantDetail } };

  const structuredData = staticConsultantDetail ? itemWithStaticConsultantDetail : item;

  const venueAccessibility = venue?.venueAccessibility || null;

  return (
    item && (
      <>
        <Row row className={classNames(className, { 'no-consultant-detail': !consultant })}>
          <Row column grow className="nearest-group-details">
            <Row row wrap className="group-title">
              <TextNew.Serif.MD color={AccentColors.ACCENT_GREEN}>
                <Link to={link} className="group-title-link">
                  {startCase(venue.address1)}
                </Link>
              </TextNew.Serif.MD>
              {distance && (
                <TextNew.Sans
                  weight={
                    queryHelper !== QueryHelpers.TABLET ? FontWeight.LIGHT : FontWeight.NORMAL
                  }
                  size={queryHelper === QueryHelpers.TABLET ? FontSizes.SM : FontSizes.MD}
                  color={AccentColors.ACCENT_GREY}
                  localeId="nearestGroupTile.groupTile.distanceDescription"
                  localeParams={{ DISTANCE: kmToMiles(distance).toFixed(1) }}
                  element={ElementTypes.SPAN}
                  cid="nearest-group-distance"
                />
              )}
            </Row>
            <Row wrap spacebetween className="group-details">
              {consultant && (
                <Row column spacebetween>
                  <GroupField label="nearestGroupTile.consultant">
                    <TextNew.Sans
                      weight={
                        queryHelper !== QueryHelpers.TABLET ? FontWeight.LIGHT : FontWeight.NORMAL
                      }
                      size={queryHelper === QueryHelpers.TABLET ? FontSizes.XS : FontSizes.SM}
                      color={AccentColors.ACCENT_GREY}
                    >
                      {consultantName}
                    </TextNew.Sans>
                  </GroupField>
                  <GroupField
                    weight={
                      queryHelper !== QueryHelpers.TABLET ? FontWeight.LIGHT : FontWeight.NORMAL
                    }
                    size={queryHelper === QueryHelpers.TABLET ? FontSizes.XS : FontSizes.SM}
                    label="nearestGroupTile.telephone"
                    cid="phone-number"
                  >
                    <TextNew.Sans
                      weight={
                        queryHelper !== QueryHelpers.TABLET ? FontWeight.LIGHT : FontWeight.NORMAL
                      }
                      size={queryHelper === QueryHelpers.TABLET ? FontSizes.XS : FontSizes.SM}
                      color={AccentColors.ACCENT_GREY}
                    >
                      <Link href={`tel:${telephone}`}>{telephone}</Link>
                    </TextNew.Sans>
                  </GroupField>
                </Row>
              )}
              <Row column className="address-column">
                <GroupField label="nearestGroupTile.address">
                  <Address
                    textStyles={{
                      weight:
                        queryHelper !== QueryHelpers.TABLET ? FontWeight.LIGHT : FontWeight.NORMAL,
                      size: queryHelper === QueryHelpers.TABLET ? FontSizes.XS : FontSizes.SM,
                    }}
                    address={{
                      address1: venue.address1,
                      address2: venue.address2,
                      address3: venue.address3,
                      town: venue.town,
                      county: !isCyprus ? venue.county : '',
                      postcode:
                        marketConfig.textBasedGroupSearch && !isCyprus ? venue.postcode : '',
                    }}
                  />
                </GroupField>
              </Row>
              <Row column className="group-timetables">
                {groupedSessions && <GroupTimetable groupSessions={groupedSessions} />}

                {events &&
                  Object.keys(events).map(e => (
                    <ModifiedGroupTimetable
                      key={`group-${id}-${e}-timetable`}
                      type={e}
                      groupSessions={events[e]}
                      headerTextStyle={{
                        color: AccentColors.ACCENT_PINK,
                        size: queryHelper === QueryHelpers.TABLET ? FontSizes.XS : FontSizes.SM,
                        weight: FontWeight.BOLD,
                      }}
                      textStyle={{
                        color: Colors.PRIMARY_DARK,
                        size: queryHelper === QueryHelpers.TABLET ? FontSizes.XS : FontSizes.SM,
                        weight:
                          queryHelper !== QueryHelpers.TABLET
                            ? FontWeight.LIGHT
                            : FontWeight.NORMAL,
                      }}
                    />
                  ))}
              </Row>

              {groupedSessions && <NearestGroupSessionMessages sessions={groupedSessions} />}
            </Row>

            {venueAccessibility && <VenueAccessibility venueAccessibility={venueAccessibility} />}
          </Row>
        </Row>
        {structuredData && (
          <StructuredDataGenerator data={structuredData} type={types.LOCAL_BUSINESS} />
        )}
      </>
    )
  );
};

NearestGroupTile.propTypes = {
  index: PropTypes.number,
  staticConsultantDetail: PropTypes.shape(consultantShape),
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    distanceDescription: PropTypes.string,
    address: PropTypes.object,
    telephone: PropTypes.string,
    email: PropTypes.string,
    sessions: PropTypes.array,
    consultant: PropTypes.shape({
      name: PropTypes.string,
    }),
    events: PropTypes.object,
  }),
  isCyprus: PropTypes.bool,
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'NearestGroupTile')(NearestGroupTile),
);
