import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import difference from 'lodash/difference';
import debounce from 'lodash/debounce';
import withDeviceState, { DeviceState } from 'common/src/app/util/device-state/withDeviceState';
import GridItemType from 'common/src/app/data/enum/GridItemType';
import Wrapper from 'components/atoms/Wrapper';
import Row from 'components/atoms/Row';
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';
import TextNew from 'components/atoms/TextNew';
import Grid from 'components/organisms/Grid';
import GridItem from 'components/organisms/GridItem';
import SearchFilterBar from 'components/organisms/SearchFilterBar';
import LocaleMessage from 'components/atoms/LocaleMessage';
import IcelandOurPagesContent from '../IcelandOurPagesContent';
import IcelandProductTile from '../../organisms/IcelandProductTile';
import IcelandAdvertisement from '../../molecules/IcelandAdvertisement';
import IcelandOurPagesHeader from '../../molecules/IcelandOurPagesHeader';
import IcelandFilter from './components/IcelandFilter';

import './iceland-our-range.scss';

const LIMIT_VISIBLE_ITEMS = 10;
const ITEM_INCREMENT_LARGE = 8;
const ITEM_INCREMENT = 6;
const START_FROM = 0;
const EMPTY_ARRAY = [];

const IcelandOurRange = (
  {
    pageData,
    products: initialProducts,
    isLoaded,
    isInitializing,
    submitForm,
    deviceState,
    setFieldValue,
    values,
    handleChange,
  },
  { getMessage },
  className,
) => {
  const [products, setProducts] = useState(initialProducts || EMPTY_ARRAY);
  const [displayProductsTo, setDisplayProductsTo] = useState(LIMIT_VISIBLE_ITEMS);

  const debouncedSubmit = useMemo(() => debounce(() => submitForm(), 300), [submitForm]);

  useEffect(() => {
    debouncedSubmit();
    return debouncedSubmit.cancel;
  }, [debouncedSubmit, values]);

  useEffect(() => {
    if (
      difference(initialProducts, products).length > 0 ||
      difference(products, initialProducts).length > 0
    ) {
      setProducts(initialProducts);
      setDisplayProductsTo(LIMIT_VISIBLE_ITEMS);
    }
  }, [initialProducts, products]);

  const handleLoadMore = () => {
    setDisplayProductsTo(
      prevDisplayProductsTo =>
        prevDisplayProductsTo +
        (deviceState > DeviceState.XL ? ITEM_INCREMENT_LARGE : ITEM_INCREMENT),
    );
  };

  const [toggleSidebar, setToggleSidebar] = useState(false);
  const toggle = useCallback(() => setToggleSidebar(!toggleSidebar), [
    toggleSidebar,
    setToggleSidebar,
  ]);

  const clearFilters = () => {
    setFieldValue('foodRangeDiet', []);
    setFieldValue('categories', []);
  };

  const totalFilterCount = values?.categories?.length + values?.foodRangeDiet?.length ?? 0;

  // Create an object mapping category titles to their respective slugs
  const categoryFilters = pageData?.categories?.reduce(
    (acc, { title, slug }) => ({ ...acc, [title]: slug }),
    {},
  );

  return (
    <section
      className={classNames(className, { 'is-collapsed': !toggleSidebar })}
      data-testid="iceland-our-range"
    >
      {!isInitializing && isLoaded && pageData ? (
        <IcelandOurPagesContent pageData={pageData} hasHeroBar={false} hasHeader={false}>
          <form className="page-filter-container">
            <IcelandFilter
              toggle={toggle}
              values={values}
              handleChange={handleChange}
              categoryFilters={categoryFilters}
              getMessage={getMessage}
            />

            <Wrapper.XL padding="md" className="main-content">
              {(pageData?.title || pageData?.description) && (
                <IcelandOurPagesHeader title={pageData.title} description={pageData.description} />
              )}

              <SearchFilterBar
                sort={false}
                isFoodRange
                factSheet={pageData?.factSheet}
                totalResults={products?.length || 0}
                toggleCollapse={toggle}
                totalFilters={totalFilterCount}
                clearFilters={clearFilters}
              />

              {products?.length > 0 ? (
                <>
                  <Grid>
                    {products.slice(START_FROM, LIMIT_VISIBLE_ITEMS)?.map(item => (
                      <GridItem key={item.id} type={GridItemType.THIRDS}>
                        <IcelandProductTile item={item} />
                      </GridItem>
                    ))}

                    {products.slice(LIMIT_VISIBLE_ITEMS, displayProductsTo)?.map(item => (
                      <GridItem cid="appended" key={item.id} type={GridItemType.THIRDS}>
                        <IcelandProductTile item={item} />
                      </GridItem>
                    ))}

                    {pageData?.offer && (
                      <IcelandAdvertisement
                        isInGrid
                        offerGridLocation={pageData.offerGridLocation}
                        advertisement={pageData.offer}
                      />
                    )}
                  </Grid>

                  {products.length > LIMIT_VISIBLE_ITEMS && displayProductsTo < products.length && (
                    <Row justifycenter>
                      <Button isSecondary onClick={handleLoadMore}>
                        <LocaleMessage id="general.cta.loadMore" />
                      </Button>
                    </Row>
                  )}
                </>
              ) : (
                <TextNew.PrimaryOriginal cid="no-products" localeId="icelandOurRange.noProducts" />
              )}
            </Wrapper.XL>
          </form>
        </IcelandOurPagesContent>
      ) : (
        <Loader />
      )}
    </section>
  );
};

IcelandOurRange.propTypes = {
  pageData: PropTypes.object,
  products: PropTypes.array,
  isLoaded: PropTypes.bool,
  isInitializing: PropTypes.bool,
  submitForm: PropTypes.func,
  deviceState: PropTypes.number,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  handleChange: PropTypes.func,
};

IcelandOurRange.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.TEMPLATE, 'IcelandOurRange')(IcelandOurRange),
);
