import React from 'react';
import PropTypes from 'prop-types';
import JotformEmbed from 'react-jotform-embed';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Wrapper from 'components/atoms/Wrapper';
import Size from 'common/src/app/data/enum/Size';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import Loader from 'components/atoms/Loader';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import createScriptTitle from '../../../../../../utils/createScriptTitle';
import './consultant-enquiry.scss';

const ConsultantEnquiry = ({ loading, pageData, pageBackgroundImage }, context, className) => (
  <div
    className={className}
    style={{ backgroundImage: pageBackgroundImage && `url(${pageBackgroundImage.src}` }}
  >
    <Wrapper.MD padding={Size.XL}>
      {loading && <Loader />}

      {!loading &&
        // eslint-disable-next-line react/prop-types
        pageData?.nestedContent.map(({ _type, ...content }) => {
          if (_type === types.RICH_TEXT_BLOCK) {
            return (
              <RichTextBlock
                title={createScriptTitle(content.title)}
                text={createScriptTitle(content.text)}
              />
            );
          }
          return null;
        })}

      <JotformEmbed src="https://form.jotform.com/221655108499362" />
    </Wrapper.MD>
  </div>
);

ConsultantEnquiry.propTypes = {
  loading: PropTypes.bool.isRequired,
  pageData: PropTypes.object,
  pageBackgroundImage: PropTypes.shape(imageShape),
};

export default withFunctionalClassName(
  ComponentType.TEMPLATE,
  'ConsultantEnquiry',
)(ConsultantEnquiry);
