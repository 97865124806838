/* global 'atom' */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import sizePropTypes from 'common/src/app/util/proptypes/sizePropTypes';
import IconName from 'common/src/app/data/enum/IconName';
import ModalPosition from 'common/src/app/data/enum/ModalPosition';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Button from '../Button';
import Icon from '../Icon';
import CloseButton from '../../molecules/CloseButton';

import './content-modal.scss';

/**
 * Content Modal, will render children as modal content
 */
class ContentModal extends PureComponent {
  componentWillUnmount() {
    const { onCloseModal } = this.props;
    onCloseModal && onCloseModal();
  }

  render() {
    const {
      showWrapperCloseButton,
      showCloseButton,
      showCircleButton,
      deviceState,
      closeModal,
      disableCloseOnBackdrop,
      children,
      position,
      padding,
      customClass,
    } = this.props;

    const iconSize = deviceState > DeviceState.SM ? 30 : 15;

    return (
      <div {...componentClassNameProp('atom', this, null, [customClass])}>
        {showWrapperCloseButton && <CloseButton onClick={closeModal} />}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */}
        <div className="close-area" onClick={!disableCloseOnBackdrop ? closeModal : null} />
        <div className={classNames('content', position, `${padding}-padding`)}>
          <div className="content-wrapper">
            {!showWrapperCloseButton && showCloseButton && (
              <Button isTertiary onClick={closeModal}>
                <div className="screen-reader">Close</div>
                <Icon width={iconSize} name={IconName.CROSS} />
              </Button>
            )}
            {showCircleButton && <CloseButton onClick={closeModal} />}
            {children}
          </div>
        </div>
      </div>
    );
  }
}

ContentModal.defaultProps = {
  showWrapperCloseButton: true,
  showCloseButton: true,
  showCircleButton: false,
  position: ModalPosition.TOP,
  hideContent: false,
  customClass: '',
};

ContentModal.propTypes = {
  showWrapperCloseButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showCircleButton: PropTypes.bool,
  disableCloseOnBackdrop: PropTypes.bool,
  deviceState: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func,
  children: PropTypes.node,
  customClass: PropTypes.string,
  position: PropTypes.oneOf(ModalPosition.propTypes),
  padding: PropTypes.oneOf(sizePropTypes), // eslint-disable-line react/no-unused-prop-types
};

export default ContentModal;
