import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class InteractionToggleWrapper extends PureComponent {
  toggleActive = e => {
    e.preventDefault();
    const {
      interactionState: { isActive, actionIsPending },
      customIsActive,
    } = this.props;

    if (!actionIsPending) {
      this.props[isActive || customIsActive ? 'removeInteraction' : 'addInteraction']();
    }
  };

  render() {
    const {
      component: WrappedComponent,
      customIsActive,
      /* Props that are only needed within InteractionToggleWrapper's scope and shouldn't go any further  */
      /* eslint-disable no-unused-vars */
      addInteraction,
      removeInteraction,
      initialized,
      interactionState: { isActive, actionIsPending },
      interactionType,
      targetId,
      targetType,
      targetTitle,
      /* eslint-enable no-unused-vars */
      ...props
    } = this.props;

    return (
      <WrappedComponent
        {...props}
        isActive={isActive || customIsActive}
        toggleActive={this.toggleActive}
      />
    );
  }
}

InteractionToggleWrapper.defaultProps = {
  isInteractive: true,
};

InteractionToggleWrapper.propTypes = {
  component: PropTypes.func.isRequired,
  addInteraction: PropTypes.func.isRequired,
  removeInteraction: PropTypes.func.isRequired,
  interactionState: PropTypes.shape({
    isActive: PropTypes.bool,
    actionIsPending: PropTypes.bool,
  }).isRequired,
  initialized: PropTypes.bool,
  interactionType: PropTypes.string,
  targetId: PropTypes.string,
  targetType: PropTypes.number,
  targetTitle: PropTypes.string,
  /**
   * ClassName modifier
   */
  isInteractive: PropTypes.bool.isRequired,
  /**
   * The custom active property is a interaction state that is only for the Admin
   *
   * Since pinning a post is not like likes, bookmarks, hugs, applauds, it's not necessary to add
   * the pins in the interactionInfo/aggregator
   */
  customIsActive: PropTypes.bool,
};

export default InteractionToggleWrapper;
