import ArticleType from '../data/enum/ArticleType';

const ImageRatios = {
  ONE: 1,
  EIGHTNINETEEN: 8 / 19,
  EIGHTTWELVE: 8 / 12,
  TWOEIGHTYFIVETWOSIX: 280 / 526,
  FOURTEENTHIRTYONE: 14 / 31,
  HUNDREDSIX_SEVENTYNINE: 106 / 79,
  TWOSIXFOUR_THREEFOUREIGHT: 264 / 348,
};

export const imageRatiosMap = {
  [ArticleType.PUBLIC_RECIPE]: {
    normal: ImageRatios.ONE,
    isFeatured: ImageRatios.ONE,
  },
  [ArticleType.RECIPE]: {
    normal: ImageRatios.ONE,
    isFeatured: ImageRatios.TWOSIXFOUR_THREEFOUREIGHT,
  },
  [ArticleType.PUBLIC_RECIPE_LARGE]: {
    normal: ImageRatios.ONE,
    isFeatured: ImageRatios.ONE,
  },
  [ArticleType.SUCCESS_STORY]: {
    normal: ImageRatios.EIGHTTWELVE,
    isFeatured: ImageRatios.TWOEIGHTYFIVETWOSIX,
  },
  [ArticleType.PUBLIC_SUCCESS_STORY]: {
    normal: ImageRatios.EIGHTTWELVE,
    isFeatured: ImageRatios.FOURTEENTHIRTYONE,
  },
  [ArticleType.DEFAULT]: {
    normal: ImageRatios.ONE,
    isFeatured: ImageRatios.ONE,
  },
  [ArticleType.FOOD_RANGE_PRODUCT]: {
    normal: ImageRatios.HUNDREDSIX_SEVENTYNINE,
    isFeatured: ImageRatios.HUNDREDSIX_SEVENTYNINE,
  },
};

const masterTileImageRatios = (type, isFeatured) => {
  const featureType = isFeatured ? 'isFeatured' : 'normal';
  const imageWidths = {
    [ArticleType.PUBLIC_RECIPE]: {
      623: { vw: 100 },
      other: { px: 280 },
    },
    [ArticleType.RECIPE]: {
      623: { vw: 100 },
      other: { px: 280 },
    },
    [ArticleType.PUBLIC_RECIPE_LARGE]: {
      623: { vw: 100 },
      other: { px: 371 },
    },
    [ArticleType.SUCCESS_STORY]: {
      623: { px: 280 },
      other: { px: 280 },
    },
    [ArticleType.PUBLIC_SUCCESS_STORY]: {
      623: { px: 280 },
      other: { px: 280 },
    },
    [ArticleType.DEFAULT]: {
      623: { vw: 100 },
      other: { px: 381 },
    },
    [ArticleType.FOOD_RANGE_PRODUCT]: {
      623: { vw: 100 },
      other: { px: 382 },
    },
  };

  // Todo: SWPUB-799 review this function to be more flexible, not merge objects
  return {
    623: { ...imageWidths[type][623], ratio: imageRatiosMap[type][featureType] },
    other: {
      ...imageWidths[type].other,
      ratio: imageRatiosMap[type][featureType],
    },
  };
};

export default masterTileImageRatios;
