import { compose } from 'redux';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { GET_COMPONENT } from 'common/src/app/actions/resources/componentActions';
import { openModal } from 'common/src/app/actions/components/modalActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import {
  getStaticContent,
  GET_STATIC_CONTENT,
} from 'common/src/app/actions/resources/staticContentActions';
import { API_STATIC_PUBLIC_JANUARY_2021_CAMPAIGN_TILE } from 'common/src/app/data/apiStatics';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import JanuaryCampaign2021Promotion from './JanuaryCampaign2021Promotion';

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT, GET_COMPONENT);

const mapStateToProps = state => ({
  isLoading: pageLoading(state),
  assets: staticPageSelector(state, API_STATIC_PUBLIC_JANUARY_2021_CAMPAIGN_TILE)?.nestedContent,
});

const connected = connect(mapStateToProps, { openModal });

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    dispatch(getStaticContent(API_STATIC_PUBLIC_JANUARY_2021_CAMPAIGN_TILE, true)),
});

export default compose(addInitAction, connected)(JanuaryCampaign2021Promotion);
