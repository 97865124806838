import { createSelector } from 'reselect';

/**
 * Returns the current route (url) as string
 * @param {object} state - the state object
 * @example currentRoute(state)
 */
export const currentRoute = createSelector(
  [state => state.routing.locationBeforeTransitions.pathname],
  route => route,
);

/**
 * Match route
 * @param {string} routeToMatch - the route to match
 * @example dispatch(matchRouteThunk(Page.AwesomePage))
 */
export const matchRouteThunk = routeToMatch => (dispatch, getState) =>
  currentRoute(getState()) === routeToMatch;

/**
 * Match route
 * @param {string} routeToMatch - the route to match
 * @param {object} state - the state object
 * @example matchRoute(Pages.AwesomePage, state)
 */
const matchRoute = (routeToMatch, state) => currentRoute(state) === routeToMatch;

export default matchRoute;
