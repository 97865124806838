import { actionTypes } from '@mediamonks/react-redux-component-init';
import { pageLoadComplete, PAGE_RENDER_COMPLETE } from '../../actions/trackingActions';

export default ({ dispatch, getState }) => {
  let lastReportedPageLoadComplete = null;

  function checkPageLoadComplete() {
    const {
      init: { selfInit: selfInitState },
      routing: { locationBeforeTransitions },
    } = getState();
    const allCompleted = Object.values(selfInitState).every(_ => _);
    if (allCompleted) {
      const location = `${locationBeforeTransitions.pathname}${locationBeforeTransitions.search}`;
      if (location !== lastReportedPageLoadComplete) {
        lastReportedPageLoadComplete = location;
        dispatch(pageLoadComplete(locationBeforeTransitions));
      }
    }
  }

  return next => action => {
    // first execute the action
    next(action);

    // then measure the results
    if (action.type === actionTypes.INIT_COMPONENT && action.payload.complete) {
      checkPageLoadComplete();
    }

    if (action.type === PAGE_RENDER_COMPLETE) {
      checkPageLoadComplete();
    }
  };
};
