import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import LandingGrid from 'components/templates/LandingGrid';
import PublicHorizontalSearchBar from 'components/molecules/PublicHorizontalSearchBar';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Button from 'components/atoms/Button';
import TextNew, { Colors, ElementTypes, FontWeight } from 'components/atoms/TextNew';
import Pages from 'common/src/app/data/enum/Pages';
import landingGridProps from 'components/templates/LandingGrid/landingGridProps';

const RecipeNotFound = ({ gridContents, slug }, context, className) => (
  <div className={className} data-testid="recipe-not-found">
    <div className="copy-with-background">
      <TextNew.Script.MD
        color={Colors.BRAND}
        localeId="smartNotFound.header"
        element={ElementTypes.H2}
        cid="sorry"
      />
      <TextNew.Serif.SM
        color={Colors.BRAND}
        localeId="smartNotFound.body"
        element={ElementTypes.H3}
        cid="alt"
      />
      {slug && (
        <TextNew.Sans.SM
          color={Colors.BRAND}
          localeId="smartNotFound.recipe.tagLine"
          weight={FontWeight.LIGHT}
          localeParams={{
            SEARCH_TERM: (
              <TextNew.Sans.SM
                color={Colors.BRAND}
                weight={FontWeight.BOLD}
                element={ElementTypes.SPAN}
              >
                {slug.replace(/-/g, ' ')}
              </TextNew.Sans.SM>
            ),
          }}
        />
      )}
    </div>

    <PublicHorizontalSearchBar showTitle={false} isTile={false} />

    <LandingGrid {...gridContents} allowLoadMore={false} hideSortControls />

    <div className="cta-button">
      <Button to={Pages.UK_PUBLIC_RECIPE_SEARCH}>
        <LocaleMessage id="smartNotFound.recipe.cta" />
      </Button>
    </div>
  </div>
);

RecipeNotFound.propTypes = {
  gridContents: landingGridProps,
  slug: PropTypes.string,
};

export default withFunctionalClassName(ComponentType.PAGE, 'RecipeNotFound')(RecipeNotFound);
