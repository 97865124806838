import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Pages from 'common/src/app/data/enum/Pages';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import IconButton from 'components/molecules/IconButton';
import LocaleMessage from 'components/atoms/LocaleMessage';
import IconName from 'common/src/app/data/enum/IconName';
import BubbleBackgroundWrapper from '../../atoms/BubbleBackgroundWrapper';
import createScriptTitle from '../../../../../../utils/createScriptTitle';
import './consultant-cta-block.scss';

const ConsultantCtaBlock = ({ localePrefix }, { getMessage }, className) => (
  <div className={className}>
    <BubbleBackgroundWrapper>
      <RichTextBlock
        title={createScriptTitle(getMessage(`consultantCtaBlock.${localePrefix}.title`))}
        text={createScriptTitle(getMessage(`consultantCtaBlock.${localePrefix}.text`))}
      />
      <IconButton
        icon={IconName.MENU_COMMUNITY}
        buttonLabel={getMessage('consultantCtaBlock.cta')}
        to={Pages.UK_PUBLIC_BECOME_A_CONSULTANT_ENQUIRY}
      >
        <LocaleMessage id="consultantCtaBlock.cta" />
      </IconButton>
    </BubbleBackgroundWrapper>
  </div>
);

ConsultantCtaBlock.defaultProps = {
  localePrefix: 'default',
};

ConsultantCtaBlock.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

ConsultantCtaBlock.propTypes = {
  localePrefix: PropTypes.string,
};

export default withFunctionalClassName(ComponentType.ORGANISM, 'ConsultantCtaBlock', [
  ({ localePrefix }) => localePrefix,
])(ConsultantCtaBlock);
