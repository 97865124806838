import React from 'react';
import { compose } from 'redux';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';
import Loader from 'components/atoms/Loader';
import Wrapper from 'components/atoms/Wrapper';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import GroupOnlineSignUp from 'components/molecules/GroupOnlineSignUp';
import errorBoundary from 'components/hoc/ErrorBoundary';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import OffersBox from './components/OffersBox';
import NewsletterSignUp from '../../molecules/NewsletterSignUp';

import './offers.scss';

const Offers = ({ isInitializing, page }, context, className) => {
  if (isInitializing) return <Loader />;

  const { image, title } = page;
  const boxs = marketName === 'UK' ? page.ukOffers : page.roiOffers;
  const disclaimer = marketName === 'UK' ? page.ukDisclaimer : page.roiDisclaimer;

  return (
    <div className={className}>
      <HeroBarPublic image={image}>
        <TextNew.Script.SM
          color={Colors.BRAND}
          element={ElementTypes.H1}
          cid="hero-bar-public-script-heading"
        >
          {title}
        </TextNew.Script.SM>
      </HeroBarPublic>
      <div className="main-content-block">
        {boxs && (
          <Wrapper width="lg">
            {boxs.map(content => (
              <OffersBox key={content.title} {...content} />
            ))}
          </Wrapper>
        )}
        <RichTextBlock
          dataTestid="page-offers-disclaimer"
          cid="offers-disclaimer"
          text={disclaimer}
        />
      </div>
      <GroupOnlineSignUp />
      <NewsletterSignUp />
    </div>
  );
};

Offers.propTypes = {
  isInitializing: PropTypes.bool,
  page: PropTypes.shape({
    title: PropTypes.string,
    ukDisclaimer: PropTypes.node,
    roiDisclaimer: PropTypes.node,
    ukOffers: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.node,
        disclaimer: PropTypes.string,
        displayHalfWidth: PropTypes.bool,
        title: PropTypes.string,
        image: PropTypes.shape({
          ...imageShape,
        }),
      }),
    ),
    roiOffers: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.node,
        disclaimer: PropTypes.string,
        displayHalfWidth: PropTypes.bool,
        title: PropTypes.string,
        image: PropTypes.shape({
          ...imageShape,
        }),
      }),
    ),
    image: PropTypes.shape({
      ...imageShape,
    }),
  }),
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.PAGE, 'Offers'),
)(Offers);
