// Packages
import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';

// Utils
import abbreviate from 'common/src/app/util/locale/abbreviate';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

// Enums
import { measurementFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import AbbreviationType from 'common/src/app/data/enum/AbbreviationType';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import { MemberTypeStrings } from 'common/src/app/data/enum/MemberType';
import Configuration from 'common/src/app/config/Configuration';
import MeasureType from 'common/src/app/data/enum/MeasureType';
import EnhancedFormField from 'common/src/app/formik/components/EnhancedFormField';

// Components
import FormGroup from '../../molecules/FormGroup';
import Row from '../../atoms/Row';
import LocaleMessage from '../../atoms/LocaleMessage';
import FormikError from '../../atoms/FormikError';
import ToggleNew from '../../atoms/ToggleNew';
import TextNew, { Colors } from '../../atoms/TextNew';
import InformationToggle from '../../molecules/InformationToggle';
import Wrapper from '../../atoms/Wrapper';
import MeasurementInputField from '../MeasurementInputField';
import './weight-input-field.scss';
/**
 * Weight input, including InformationToggle and Unit selector
 * Membertype - the type of member you are displaying this message to e.g. 'group' or 'online
 * Used to switch out BMI information message
 */
const WeightInputField = (
  { deviceState, memberType, labelLocale, fieldName, fieldInfoLocale, viewSettings, ...props },
  {},
  className,
) => {
  const isGroup = memberType === MemberTypeStrings.GROUP;
  const { unitToggle, displayInfoButton, fieldLabel } = viewSettings;
  const {
    customLabel,
    formik: { values, setFieldValue },
  } = props;
  return (
    <FormGroup className={className}>
      <Row className="weight-toggle" alignend={isGroup} spacebetween>
        <Row>
          <Wrapper cid="optional-label-row">
            {fieldLabel && (
              <legend className="atom-label">
                {customLabel || <LocaleMessage id={labelLocale} />}
              </legend>
            )}
            {isGroup && (
              <TextNew.FormalLight
                color={Colors.MID_TONE}
                cid="optional-label"
                localeId="medicalCheck.groupLabels.weightIsOptional"
              />
            )}
          </Wrapper>
        </Row>

        {unitToggle && (
          <Row spacebetween={isGroup}>
            <EnhancedFormField
              name={measurementFields.WEIGHT_UNIT}
              component={ToggleNew}
              legend={<LocaleMessage id="weightInput.legend" />}
              leftlabel={
                Configuration.imperialWeightUseStones ? (
                  <LocaleMessage
                    id={`general.measureUnit.weight.stone.${abbreviate(deviceState)}`}
                  />
                ) : (
                  <LocaleMessage
                    id={`general.measureUnit.weight.pounds.${abbreviate(deviceState)}`}
                  />
                )
              }
              leftname={MeasureUnit.IMPERIAL}
              rightlabel={
                <LocaleMessage
                  id={`general.measureUnit.weight.kilogram.${abbreviate(deviceState)}`}
                />
              }
              rightname={MeasureUnit.METRIC}
              cid="toggle-weight-units"
              onClick={() =>
                // Reset the weight values when switching
                [
                  'stones',
                  'fullPounds',
                  'remainingPounds',
                  'kilos',
                  'grams',
                  [fieldName],
                ].forEach(element => setFieldValue(element, null))
              }
              dataTestIdLeft={`${measurementFields.WEIGHT_UNIT}-${MeasureUnit.IMPERIAL}`}
              dataTestIdRight={`${measurementFields.WEIGHT_UNIT}-${MeasureUnit.METRIC}`}
            />
          </Row>
        )}
      </Row>
      <MeasurementInputField
        name={fieldName}
        unit={values?.weightUnit}
        type={MeasureType.WEIGHT}
        abbreviate={true}
        {...props}
      />
      <FormikError name={fieldName} />

      {displayInfoButton && (
        <InformationToggle
          label={<LocaleMessage id="weightInput.label" />}
          information={<LocaleMessage id={fieldInfoLocale || `weightInput.info.${memberType}`} />}
        />
      )}
    </FormGroup>
  );
};

WeightInputField.propTypes = {
  deviceState: PropTypes.number.isRequired,
  memberType: PropTypes.string,
  labelLocale: PropTypes.string,
  viewSettings: PropTypes.shape({
    unitToggle: PropTypes.bool,
    displayInfoButton: PropTypes.bool,
    fieldLabel: PropTypes.bool,
  }),
  fieldName: PropTypes.string,
  fieldInfoLocale: PropTypes.string,
  abbreviationType: PropTypes.string,
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
  }),
  customLabel: PropTypes.node,
};

WeightInputField.defaultProps = {
  viewSettings: {
    unitToggle: true,
    displayInfoButton: true,
    fieldLabel: true,
  },
  memberType: MemberTypeStrings.ONLINE,
  labelLocale: 'medicalCheck.groupLabels.currentWeight',
  fieldName: measurementFields.INITIAL_WEIGHT,
  abbreviationType: AbbreviationType.LONG_TO_SHORT,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'WeightInputField')(WeightInputField),
);
