import React from 'react';
import PropTypes from 'prop-types';
import DataTestidOverides from 'common/src/app/data/enum/DataTestidOverides';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { countryCode, countryCodeToString } from 'common/src/app/data/enum/Countries';
import { MATCH_FIRST } from 'common/src/app/data/regexPatterns';
import Pages from 'common/src/app/data/enum/Pages';
import Select from '../../../../../atoms/Select';

const CountrySwitch = ({
  pageIsCyprus,
  currentHost,
  countrySelectHistoryPush,
  getMessage,
  path,
}) => {
  const match = MATCH_FIRST.exec(currentHost);

  const marketSwitchOptions = [
    {
      title: getMessage(
        `ukPublicNavigationLinks.countrySwitch.${countryCodeToString[countryCode.GB]}`,
      ),
      value: match
        ? `${match[1]}.swlabs.digital${path || ''}`
        : `${PagesExternal.PRODUCTION_UK}${path || ''}`,
      countryCode: countryCode.GB,
      selected: marketConfig.defaultCountry === countryCode.GB && !pageIsCyprus,
    },
    {
      title: getMessage(
        `ukPublicNavigationLinks.countrySwitch.${countryCodeToString[countryCode.IRELAND]}`,
      ),
      value: match
        ? `${match[1]}-irl.swlabs.digital${path || ''}`
        : `${PagesExternal.PRODUCTION_IE}${path || ''}`,
      countryCode: countryCode.IRELAND,
      selected: marketConfig.defaultCountry === countryCode.IRELAND,
    },
    {
      title: getMessage(
        `ukPublicNavigationLinks.countrySwitch.${countryCodeToString[countryCode.CYPRUS]}`,
      ),
      value: Pages.UK_PUBLIC_CYPRUS_GROUP_SEARCH,
      countryCode: countryCode.CYPRUS,
      selected: pageIsCyprus,
    },
  ];

  const dropDownChoices = marketConfig.hasCyprusGroups
    ? marketSwitchOptions
    : marketSwitchOptions.filter(option => option.countryCode !== countryCode.CYPRUS);

  return (
    <Select
      customLabel="Choose country"
      name="country-switch"
      cid="public-style"
      dataTestid={DataTestidOverides.PUBLIC_COUNTRY_SELECT}
      options={dropDownChoices}
      onChange={countrySelectHistoryPush}
    />
  );
};

CountrySwitch.propTypes = {
  countrySelectHistoryPush: PropTypes.func,
  getMessage: PropTypes.func,
  currentHost: PropTypes.string,
  pageIsCyprus: PropTypes.bool,
  path: PropTypes.string,
};

CountrySwitch.defaultProps = {
  pageIsCyprus: false,
};

export default CountrySwitch;
