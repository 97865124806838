import Client from 'common/src/client/Client';
import hotRoutes from 'hot-callback-loader?export=default!../app/components/Routes';
import hotReducer from 'hot-callback-loader?export=default!../app/reducers';
import setupReduxListeners from './redux-listeners';

const client = new Client();
client.reduxListenersSetup = setupReduxListeners;
hotRoutes(client.setReactRoutes);
hotReducer(client.setReduxReducer);

client.init();
