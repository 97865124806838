import React from 'react';
import PropTypes from 'prop-types';
import RouterContext from 'react-router/lib/RouterContext';

const QueryRoutingContainer = ({ query }, { queryRoutingResults }) => {
  if (queryRoutingResults[query]) {
    return <RouterContext {...queryRoutingResults[query]} />;
  }

  return <noscript />;
};

QueryRoutingContainer.contextTypes = {
  queryRoutingResults: PropTypes.objectOf(PropTypes.any),
};

QueryRoutingContainer.propTypes = {
  query: PropTypes.string.isRequired,
};

export default QueryRoutingContainer;
