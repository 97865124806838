import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import GridItemType from 'common/src/app/data/enum/GridItemType';
import './grid-item.scss';

/**
 * This component should be used within the grid component.
 * To create a grid you should pass in the type of grid you want such as featured or default.
 * The different grid types are configured within GridItemType.
 * Grid configurations should NEVER be passed in directly! Instead they should come
 * from GridItemType.
 * Offset can be used to push a column to a desired position: offset-sm-4
 */

const GridItem = ({ children }, context, className, dataTestid) => (
  <div className={className} data-testid={dataTestid}>
    {children}
  </div>
);

GridItem.propTypes = {
  /**
   * Type: Different grid configurations will be passed in from GridItemType.
   */
  type: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  /**
   * child nodes are any content display within each grid item
   */
  children: PropTypes.node,
};

export default withFunctionalClassName(ComponentType.ORGANISM, 'GridItem', [
  ({ type = GridItemType.DEFAULT }) => type,
])(GridItem);
