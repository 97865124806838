import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import Loader from 'components/atoms/Loader';

import './physical-groups-landing.scss';

const PhysicalGroupsLanding = ({ isLoading, phsyicalGroupsMessage }, context, className) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={className}>
      {phsyicalGroupsMessage && <RichTextBlock cid="top" text={phsyicalGroupsMessage} />}
    </div>
  );
};

PhysicalGroupsLanding.propTypes = {
  isLoading: PropTypes.bool,
  phsyicalGroupsMessage: PropTypes.string,
};

export default withFunctionalClassName(
  ComponentType.ORGANISM,
  'PhysicalGroupsLanding',
)(PhysicalGroupsLanding);
