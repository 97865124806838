import React from 'react';
import PropTypes from 'prop-types';
import DataTestidOverides from 'common/src/app/data/enum/DataTestidOverides';
import Pages from 'common/src/app/data/enum/Pages';
import TextNew, { Colors, FontFamily, FontSizes, ElementTypes } from '../../../../../atoms/TextNew';
import Button from '../../../../../atoms/Button';

const SignUpCTA = ({ groupSearch }) => (
  <div className="join" data-testid={DataTestidOverides.PUBLIC_JOIN_BUTTON}>
    <Button
      to={groupSearch ? Pages.UK_PUBLIC_NEAREST_GROUP_LANDING : null}
      href={!groupSearch ? Pages.UK_PUBLIC_ONLINE_INFORMATION : null}
      cid="join-online"
      isTertiary
    >
      <TextNew.Sans.SM
        element={ElementTypes.SPAN}
        color={Colors.PRIMARY_LIGHT}
        localeId={`ukPublicNavigationLinks.${groupSearch ? 'findGroup' : 'join'}`}
      />
    </Button>
  </div>
);

SignUpCTA.propTypes = {
  groupSearch: PropTypes.bool,
};

SignUpCTA.defaultProps = {
  link: Pages.UK_PUBLIC_COMPARISON,
  locale: 'join',
  fontFamily: FontFamily.SCRIPT,
  fontSize: FontSizes.SM,
};

export default SignUpCTA;
