import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import Wrapper from 'components/atoms/Wrapper';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { Helmet } from 'react-helmet';
import errorBoundary from 'components/hoc/ErrorBoundary';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import GroupOnlineSignUp from 'components/molecules/GroupOnlineSignUp';
import FoodOptimisingSteps from './components/atoms/FoodOptimisingStep';
import NewsletterSignUp from '../../molecules/NewsletterSignUp';
import SevenDayMenuCarousel from './components/SevenDayMenuCarousel';
import './seven-day-menu.scss';

const SevenDayMenu = ({ page, routeParams, location: { pathname } }) => {
  // List of paths we do not want to show in search engine results such as google by adding a noindex meta tag
  const indexSeoDisallowList = [
    '/seven-day-menus/meat.*',
    '/seven-day-menus/vegetarian.*',
    '/seven-day-menus/vegan.*',
  ];
  const hidePage = indexSeoDisallowList.some(pathRegex => {
    // loop over paths within the disallow list and check if the current path matches
    const disallowRegex = new RegExp(pathRegex);
    // eslint-disable-next-line no-underscore-dangle
    return disallowRegex.test(pathname);
  });

  const { image, introduction, healthyExtraDetail } = page || {};

  const updateLocation = (menuType, day) =>
    history.pushState('', '', `/seven-day-menus/${menuType}/day-${day}`);

  const day = routeParams.day?.replace('day-', '');
  const showMenu =
    ['meat', 'vegetarian', 'vegan'].includes(routeParams.menuType) &&
    (routeParams.day === undefined ? true : parseInt(day, 10) < 8);

  if (!page) return 'Loading';

  const menuData = [1, 2, 3, 4, 5, 6, 7].map(index => page?.[`day${index}`]?.[0]);

  return (
    <>
      {hidePage && (
        <Helmet>
          {/* We do not want to show the menus in search engine results such as google, react-helmet will update the head with a noindex meta tag for the menu paths  */}
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      <HeroBarPublic headerRatio={288 / 71} cid="seven-day-hero-bar" image={image}>
        {showMenu ? (
          <TextNew.Serif.SM cid="seven-day-tagline" localeId="sevenDayMenu.taglineMenu" />
        ) : (
          <TextNew.Serif.SM cid="seven-day-tagline" localeId="sevenDayMenu.taglineEntry" />
        )}
        <TextNew.Script.SM
          cid="seven-day-title"
          localeId="sevenDayMenu.header"
          element={ElementTypes.H1}
          color={Colors.BRAND}
        />
        {introduction && <RichTextBlock text={introduction} cid="seven-day-intro" />}
      </HeroBarPublic>
      <div className="page-seven-day-menu">
        <Wrapper>
          <FoodOptimisingSteps page={page} />
        </Wrapper>
        {showMenu && (
          <SevenDayMenuCarousel
            updateLocation={updateLocation}
            menuData={menuData}
            menuTypeParam={routeParams.menuType}
            dayParam={routeParams.day}
            healthyExtraDetail={healthyExtraDetail}
          />
        )}
        {showMenu ? <GroupOnlineSignUp /> : <NewsletterSignUp />}
      </div>
    </>
  );
};

SevenDayMenu.propTypes = {
  deviceState: PropTypes.number,
  page: PropTypes.shape({
    introduction: PropTypes.string,
    image: PropTypes.shape({
      ...imageShape,
    }),
  }),
};

export default compose(errorBoundary({ wholePageError: true }), withDeviceState())(SevenDayMenu);
