/* global 'molecule' */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import './affix-container.scss';

/**
 * Affix container
 *
 * Works with Page or Template components to keep content fixed on the screen
 * Component will never exceed the visible height of it's parent.
 * Comes with no prior styling; so ideal for wrapper other UI.
 */

class AffixContainer extends PureComponent {
  constructor() {
    super();
    this.state = {
      affixHeight: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.positionToScroll);
    this.positionToScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.positionToScroll);
  }

  // eslint-disable-next-line class-methods-use-this
  findParentPageInDom = el => {
    /* eslint-disable no-cond-assign, no-param-reassign */
    while (
      (el = el.parentElement) &&
      !el.className.split(' ').some(c => /(page-|template-).*/.test(c))
    );
    return el;
    /* eslint-enable no-cond-assign, no-param-reassign */
  };

  positionToScroll = () => {
    const pageTemplateParent = this.findParentPageInDom(this.affix);
    const compStyle = getComputedStyle(pageTemplateParent);
    const affixHeight =
      pageTemplateParent.getBoundingClientRect().bottom -
      this.props.headerHeight -
      (parseInt(compStyle.paddingTop, 10) + parseInt(compStyle.paddingBottom, 10));
    this.setState({
      affixHeight,
    });
  };

  render() {
    const { children, headerHeight } = this.props;
    return (
      <div
        ref={ref => (this.affix = ref)}
        {...componentClassNameProp('molecule', this)}
        style={{
          height: `${this.state.affixHeight}px`,
          maxHeight: `calc(100vh - ${headerHeight}px)`,
        }}
      >
        {children}
      </div>
    );
  }
}

AffixContainer.propTypes = {
  /*
   * height of the main header passed from state
   */
  headerHeight: PropTypes.number,
  /*
   * height of the main header passed from state
   */
  children: PropTypes.node,
};

export default AffixContainer;
