import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { CompositeFormFieldContext } from '../CompositeFormField';

const EnhancedFormField = ({ name, ...props }) => {
  const { prefixName } = useContext(CompositeFormFieldContext);
  const inputName = prefixName ? `${prefixName}.${name}` : name;

  return <Field name={inputName} {...props} />;
};

EnhancedFormField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default EnhancedFormField;
