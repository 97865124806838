import React from 'react';
import PropTypes from 'prop-types';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';

import Icon from '../../atoms/Icon';
import RichTextBlock from '../BlockContent/components/atoms/RichTextBlock';

import './tip.scss';

const Tip = ({ tip }, {}, className) => (
  <div className={className}>
    <Icon width={24} name={IconName.LIGHTBULB} />
    <RichTextBlock text={tip} />
  </div>
);

Tip.propTypes = {
  /*
   * Message to be displayed as a tip
   */
  tip: PropTypes.string,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'Tip')(Tip);
