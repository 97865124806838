import debugLib from 'debug';
import { apiGet } from './apiActions/apiRequest';
import { GATEWAY_CONTENT, GATEWAY_CONTENT_AUTH } from '../../data/Injectables';
import apiGetEntity from './apiActions/apiGetEntity';
import apiGetCollection, { GET_NEXT } from './apiActions/apiGetCollection';
import { PAGE } from '../../data/entityTypes';
import { COVER_PHOTOS } from '../../data/collectionIds';

const debug = debugLib('SlimmingWorld:staticContentActions');

export const GET_STATIC_CONTENT = 'componentActions/GET_STATIC_CONTENT';
export const GET_PROFILE_COVER_PHOTOS = 'staticContentActions/GET_PROFILE_COVER_PHOTOS';
export const GET_COVER_PHOTO_BY_ID = 'staticContentActions/GET_COVER_PHOTO_BY_ID';

export const getStaticContent = (
  slug,
  notAuthenticated = false,
  transformEntity,
  option,
) => dispatch => {
  const sanitiseSlug = slug?.includes('/') ? slug.replace('/', '') : slug;

  return dispatch(
    apiGetEntity(
      GET_STATIC_CONTENT,
      notAuthenticated ? GATEWAY_CONTENT : GATEWAY_CONTENT_AUTH,
      `/pages/${sanitiseSlug}`,
      PAGE,
      sanitiseSlug,
      {
        requestData: { ...option },
        updateEntityView: 'view.pages.staticContent.page',
        transformEntity,
      },
    ),
  ).catch(error => debug(error));
};

export const getProfileCoverPhotos = () =>
  apiGetCollection(GET_PROFILE_COVER_PHOTOS, GATEWAY_CONTENT_AUTH, '/cover-photos', COVER_PHOTOS, {
    offset: GET_NEXT,
  });

/**
 * Get the cover photo by id
 * @param coverPhotoId {number} The id of the coverPhoto
 */
export const getCoverPhotoById = coverPhotoId => dispatch => {
  if (coverPhotoId) {
    return dispatch(
      apiGet(GET_COVER_PHOTO_BY_ID, GATEWAY_CONTENT_AUTH, `/cover-photos/${coverPhotoId}`),
    ).catch(error => {
      debug(error);
    });
  }
  return null;
};
