import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { API_STATIC_PUBLIC_SCIENCE } from 'common/src/app/data/apiStatics';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { API_COMPONENT_PUBLIC_HEADER_SCIENCE } from 'common/src/app/data/apiComponents';
import {
  staticPageSelector,
  contentComponentSelector,
} from 'common/src/app/selectors/publicSelectors';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import Science from './Science';

const mapStateToProps = state => ({
  page: staticPageSelector(state, API_STATIC_PUBLIC_SCIENCE),
  headerImage: contentComponentSelector(state, API_COMPONENT_PUBLIC_HEADER_SCIENCE, 'image'),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(getStaticContent(API_STATIC_PUBLIC_SCIENCE, true)),
      dispatch(getComponent(API_COMPONENT_PUBLIC_HEADER_SCIENCE)),
    ]),
});

export default compose(addInitAction, connected)(Science);
