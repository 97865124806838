/* global 'molecule','HealthyExtraFlag' */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import healthyExtraShape from 'common/src/app/util/proptypes/apiEntities/healthyExtra';
import TextNew, { Colors } from '../../atoms/TextNew';
import Icon from '../../atoms/Icon';
import './healthy-extra-flag.scss';

/**
 * Shows a flag which explains that a recipe contains healthy extras, listing
 * product name, what type of healthy extra it is (a / b) & how many syns it
 * would be if not counted as a healthy extra.
 */

const getHealthyExtraName = he => {
  if (he.isHealthyExtraChoiceA) {
    return '‘a’ choice';
  }
  if (he.isHealthyExtraChoiceB) {
    return '‘b’ choice';
  }
  return '';
};

const HealthyExtraFlag = ({ healthyExtras, showIcon, textColor }, context, className) => (
  <Fragment>
    {healthyExtras && healthyExtras.length > 0 && (
      <div className={className}>
        {showIcon && <Icon name={IconName.FLAG} />}
        <div className="healthy-extra-text">
          {healthyExtras.map(he => (
            <TextNew.FormalOriginal
              key={he.productName}
              color={textColor}
              localeId="healthyExtraFlag"
              localeParams={{
                NAME: he.productName,
                SYNS: he.synValue,
                HEALTHY_EXTRA: getHealthyExtraName(he),
              }}
            />
          ))}
        </div>
      </div>
    )}
  </Fragment>
);

HealthyExtraFlag.propTypes = {
  healthyExtras: PropTypes.arrayOf(PropTypes.shape(healthyExtraShape)),
  showIcon: PropTypes.bool,
  textColor: PropTypes.oneOf(Colors.propTypes),
};

export default withFunctionalClassName('molecule','HealthyExtraFlag')(HealthyExtraFlag);
