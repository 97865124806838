/* global 'molecule' */
import React, { PureComponent } from 'react';
import Link from 'react-router/lib/Link';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import createId from 'common/src/app/util/createId';
import TextNew, { Colors } from '../../atoms/TextNew';
import CustomScrollBar from '../CustomScrollBar';
import './static-content-menu.scss';

class StaticContentMenu extends PureComponent {
  // eslint-disable-next-line class-methods-use-this
  createIdAndClose = (onClick, tab, closeMenu) => {
    onClick(createId(tab));
    closeMenu && closeMenu();
  };

  render() {
    const { tabs, activeTab, onClick, closeMenu } = this.props;

    const menu = 'staticContentMenu.';

    return (
      <div {...componentClassNameProp('molecule', this)}>
        <CustomScrollBar>
          <div className="scroll-wrapper">
            <div className="block">
              <TextNew.Loud color={Colors.PRIMARY_DARK} localeId={`${menu}title`} />
            </div>
            <div className="block">
              <nav>
                <ul>
                  {tabs &&
                    Object.keys(tabs).map(tab => (
                      <li
                        className={createId(tab) === activeTab ? 'is-active' : ''}
                        key={tab}
                        ref={createId(tab) === activeTab && (active => (this.active = active))}
                      >
                        <Link
                          className="nav-button"
                          to={tabs[tab].tabLink}
                          onClick={
                            !tabs[tab].tabLink
                              ? () => this.createIdAndClose(onClick, tab, closeMenu)
                              : null
                          }
                        >
                          <TextNew.Formal>{tabs[tab].buttonLabel || tab}</TextNew.Formal>
                        </Link>
                      </li>
                    ))}
                </ul>
              </nav>
            </div>
          </div>
        </CustomScrollBar>
      </div>
    );
  }
}

StaticContentMenu.propTypes = {
  /**
   * Array of tabs
   */
  tabs: PropTypes.objectOf(
    PropTypes.shape({
      icon: PropTypes.string,
      tabLink: PropTypes.string,
      buttonLink: PropTypes.string,
      buttonLabel: PropTypes.string,
    }),
  ),
  /**
   * The current reference that needs to be active
   */
  activeTab: PropTypes.any,
  /**
   * Callback function to handle tab click
   */
  onClick: PropTypes.func,
  closeMenu: PropTypes.func,
};

export default StaticContentMenu;
