import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { gridColumns } from 'common/src/app/actions/components/gridViewActions';

import GridSizeFilter from './GridSizeFilter';

const mapDispatchToProps = dispatch => ({
  saveGridLayout: number => dispatch(gridColumns(number)),
});

const mapStateToProps = state => ({
  gridColumnCount: state.view.components.grid?.layout,
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(GridSizeFilter);
