import { Component } from 'react';
import PropTypes from 'prop-types';

import './global-styling.scss';
import './browser-not-supported.scss';

class GlobalStyling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userScrollPosition: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isModalOpen !== prevProps.isModalOpen) {
      if (typeof window !== 'undefined') {
        if (this.props.isModalOpen === true) {
          /**
           * Set body to fixed and use negative scrollposition as 'top' value
           * This way the user can't scroll the body when a modal is opened
           */
          requestAnimationFrame(() => {
            const userScrollPosition = window.scrollY || window.pageYOffset;
            this.setState({ userScrollPosition }, () => {
              document.body.setAttribute(
                'style',
                `
              overflow-y: scroll;
              position: fixed;
              top: -${userScrollPosition}px
            `,
              );
            });
          });
        } else {
          /**
           * When a modal is closed, reset body and scrollposition to
           * where the user was before opening the modal
           */
          requestAnimationFrame(() => {
            document.body.setAttribute('style', null);
            window.scrollTo(0, this.state.userScrollPosition);
          });
        }
      }
    }
  }

  render() {
    return this.props.children;
  }
}

GlobalStyling.propTypes = {
  children: PropTypes.node,
  /**
   * Check if a modal is open
   */
  isModalOpen: PropTypes.bool,
};

export default GlobalStyling;
