/* global WP_DEFINE_IS_NODE */
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import Pages from '../data/enum/Pages';
import RouteRequirement from '../util/route-requirements/RouteRequirement';

export const marketHasCyprusGroups = new RouteRequirement(
  'marketHasCyprusGroups',
  [],
  () => marketConfig.hasCyprusGroups,
  ({}, { redirect }) => redirect(Pages.UK_PUBLIC_NEAREST_GROUP_LANDING),
);
