import React from 'react';
import PropTypes from 'prop-types';
import TextNew, { FontWeight, Colors, FontSizes } from 'components/atoms/TextNew/TextNew';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Size from 'common/src/app/data/enum/Size';
import getPublicPrices from 'common/src/app/util/getPublicPrices';
import Roundel from 'components/atoms/Roundel';
import Link from 'react-router/lib/Link';
import Pages from 'common/src/app/data/enum/Pages';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState, { QueryHelpers } from 'common/src/app/util/device-state/withDeviceState';
import './half-price-offer-roundel.scss';

const HalfPriceOfferRoundel = ({ queryHelper }, context, className, dataTestid) => (
  <div data-testid={dataTestid} className={className}>
    <Roundel size={queryHelper === QueryHelpers.TABLET ? Size.LG : Size.XL}>
      <Link to={Pages.UK_PUBLIC_OFFERS}>
        <TextNew.Script.XS
          cid="roundel-top"
          color={Colors.PRIMARY_LIGHT}
          localeId="halfPriceOfferRoundel.top"
        />
        <TextNew.Serif
          size={queryHelper === QueryHelpers.TABLET ? FontSizes.SM : FontSizes.MD}
          cid="roundel-center"
          color={Colors.PRIMARY_LIGHT}
          localeId="halfPriceOfferRoundel.center"
        />
        <TextNew.Sans
          size={queryHelper === QueryHelpers.TABLET ? FontSizes.XS : FontSizes.SM}
          cid="roundel-bottom-saving"
          color={Colors.PRIMARY_LIGHT}
          weight={FontWeight.LIGHT}
          localeId="halfPriceOfferRoundel.bottom.saving"
        />
        <TextNew.Serif.XL
          cid="roundel-bottom-price"
          color={Colors.PRIMARY_LIGHT}
          localeId="halfPriceOfferRoundel.bottom.price"
          localeParams={{
            PRICE: getPublicPrices('group', 'introductoryCharge'),
          }}
        />
      </Link>
    </Roundel>
  </div>
);

HalfPriceOfferRoundel.propTypes = {
  queryHelper: PropTypes.string,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ATOM, 'HalfPriceOfferRoundel')(HalfPriceOfferRoundel),
);
