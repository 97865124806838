const getCurrentPosition = options =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });

const geoLocation = async () => {
  try {
    if (typeof window !== 'undefined') {
      const { coords } = await getCurrentPosition({
        timeout: 30000,
        enableHighAccuracy: true,
        maximumAge: 75000,
      });

      const { latitude: lat, longitude: lng } = coords;
      return { lat, lng };
    }
    return { error: 'notSupported' };
  } catch (error) {
    return handleError(error);
  }
};

const handleError = error => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return { error: 'userDenied' };
    case error.POSITION_UNAVAILABLE:
      return { error: 'locationInformation' };
    case error.TIMEOUT:
      return { error: 'timedOut' };
    case error.UNKNOWN_ERROR:
    default:
      return { error: 'unknownError' };
  }
};

export default geoLocation;
