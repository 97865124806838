export const POST = 'post';
export const PAGE = 'page';
export const COMMENT = 'comment';
export const USER_FAVOURITE = 'favourite';
export const COMPONENT = 'component';
export const TAG = 'tag';
export const ACCOUNT = 'account';
export const SUBSCRIPTION_PACKAGE = 'subscriptionPackage';
export const SUBSCRIPTION = 'subscription';
export const WEIGH_IN = 'weighIn';
export const USER_WEIGH_IN_HISTORY = 'userWeighInHistory';
export const PRIVACY_SETTINGS = 'privacySettings';
export const PROFILE = 'profile';
export const RECIPE = 'recipe';
export const FEATURE = 'feature';
export const SEVEN_DAY_MENU = 'sevenDayMenu';
export const SUCCESS_STORY = 'successStory';
export const GOAL = 'goal';
export const AWARD = 'award';
export const WEEK_META_DATA = 'weekMetadata';
export const SEARCH = 'search';
export const PROMOTED_SEARCH = 'promotedSearch';
export const PAYMENT = 'payment';
export const GROUP = 'group';
export const CONSULTANT = 'consultant';
export const PRODUCT = 'product';
export const SHIPPING_COST = 'shippingCost';
export const DISCOUNT_ITEM = 'discountItem';
export const NOTIFICATION = 'notification';
export const VOUCHER = 'voucher';
export const MEAL_CATEGORY = 'mealCategory';
export const PLANNED_DAY = 'plannedDay';
export const FOOD_PLANNER = 'foodPlanner';
export const CUSTOM_FOOD = 'customFood';
export const PLANNED_DAY_OVERVIEW = 'plannedDayOverview';
export const PURCHASE = 'purchase';
export const MENU_PURCHASE = 'menuPurchase';
export const RETURN = 'return';
export const PLANNER_ACTIVITY_GOAL = 'plannerActivityGoal';
export const PLANNER_ACTIVITY = 'plannerActivity';
export const PLANNER_ACTIVITY_TYPE = 'plannerActivityType';
export const IRISH_COUNTIES = 'irishCounties';
export const COUNTRY_REGION = 'countryRegion';
export const NEAREST_GROUP = 'nearestGroup';
export const PUBLIC_SUCCESS_STORY = 'publicSuccessStoryDetail';
export const PACKAGE = 'package';
export const HEALTHY_EXTRA = 'healthyExtra';
export const ALLOWANCE = 'freeFoodAllowance';
export const FOOD_RANGE = 'foodRange';
export const FAQ_CAT = 'faqCategory';
export const FAQ_QUES = 'faqQuestion';
export const RECOMMENDED_READING_SECTION = 'recommendedReadingSection';
export const RECOMMENDED_READING_SECTION_META = 'recommendedReadingSectionMeta';
export const FOOD_OPTIMISING = 'foodOptimising';
export const COMMUNITY_GROUP = 'communityGroup';
export const FOLLOWERS_LIST = 'followersList';
export const FOLLOWING_LIST = 'followingList';

/**
 * The entities reducer will perform an additional validation on the following entity types.
 * This is to make sure that the id key the entity is stored under matches the id property in
 * the entity itself.
 * @type {*[]}
 */
export const strictIdCheck = [ACCOUNT];
