import { withFormik } from 'formik';
import FormNames from 'common/src/app/data/enum/FormNames';
import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { push as historyPush } from 'react-router-redux';
import Pages from 'common/src/app/data/enum/Pages';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import {
  setGroupSearchLoading,
  getFullTowns,
  GET_GROUP_SEARCH_TOWNS,
  GET_GEOCODE_FOR_TOWN,
  goToTowns,
} from 'common/src/app/actions/resources/groupSearchActions';
import GroupSearchFieldNames from 'common/src/app/data/enum/FieldNames/GroupSearchFieldNames';
import formikErrorCodes from 'common/src/app/validation/formikErrorCodes';
import GroupSearchType from 'common/src/app/data/enum/GroupSearchType';
import { trackDomEvent } from 'common/src/app/actions/trackingActions';
import { createAllTownsSelector } from 'common/src/app/selectors/nearestGroupSearchSelectors';
import WebHost from 'common/src/app/data/enum/WebHost';
import getTrackingObject, { freeTextValidator } from './utils';
import FreeTextGroupSearch from './FreeTextGroupSearch';

const formikConfig = {
  displayName: FormNames.PUBLIC_GROUP_SEARCH,
  validate: (values, { errors }) => freeTextValidator(values, errors),
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: async (values, { props }) => {
    const {
      publicHost,
      pushToSearchResults,
      external,
      dispatchGroupSearchLoading,

      dispatchTrackingEvent,
      goToTown,
    } = props;

    const entry = values[GroupSearchFieldNames.FREE_TEXT].trim();

    if (values.searchType === GroupSearchType.POSTCODE) {
      await dispatchGroupSearchLoading();
      await dispatchTrackingEvent(values[GroupSearchFieldNames.FREE_TEXT], values.searchType);

      return typeof window !== 'undefined' && external
        ? (window.location.href = `${publicHost}${Pages.UK_PUBLIC_NEAREST_GROUP_LANDING}?query=${entry}`)
        : pushToSearchResults(entry);
    }

    if (values.searchType === GroupSearchType.PLACE) {
      goToTown(entry);
    }

    dispatchTrackingEvent(values[GroupSearchFieldNames.FREE_TEXT], values.searchType);

    // PUB-426 Always return values for DL postcode tracking consumption
    return values;
  },
};

const mapStateToProps = state => {
  const makeIsLoading = makeIsLoadingSelector(GET_GROUP_SEARCH_TOWNS, GET_GEOCODE_FOR_TOWN);

  return {
    towns: createAllTownsSelector(state),
    loadingResults: makeIsLoading(state),
    publicHost: state.config?.environmentConfig?.web[WebHost.PUBLIC]?.host,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchGroupSearchLoading: () => dispatch(setGroupSearchLoading(true)),
  pushToSearchResults: result =>
    dispatch(historyPush(`${Pages.UK_PUBLIC_NEAREST_GROUP_LANDING}?query=${result}`)),
  getTowns: setErrors =>
    dispatch(getFullTowns()).catch(() =>
      setErrors({
        [GroupSearchFieldNames.FREE_TEXT]: formikErrorCodes.GET_TOWNS_FAILED,
      }),
    ),
  goToTown: town => goToTowns(town, dispatch),
  dispatchTrackingEvent: (searchEntry, searchType) => {
    const result = getTrackingObject(searchEntry, searchType);

    return dispatch(trackDomEvent(result));
  },
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected, withFormik(formikConfig))(FreeTextGroupSearch);
