import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import useResizeObserver from 'use-resize-observer';
import { useInView } from 'react-intersection-observer';
import 'intersection-observer'; // eslint-disable-line
import { getVariantUrl } from 'common/src/app/util/imageSizeUtils';
import Loader from '../Loader';
import './image.scss';

const PX_SIZE_CAP = 2000;
const IMAGE_THRESHOLD = 160;

const empty1pxImage =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const dimensionSizeCap = (size, useThreshold) =>
  Math.min(
    useThreshold ? Math.ceil(size / IMAGE_THRESHOLD) * IMAGE_THRESHOLD : Math.round(size),
    PX_SIZE_CAP,
  );

const Image = ({
  src = '',
  alt,
  ratio,
  isBackgroundImage,
  children,
  inView,
  className,
  useWebp,
  loading,
  localClassName,
  dataTestid,
}) => {
  if (!src || typeof src !== 'string' || typeof window === 'undefined') {
    console.log('src must be passed in and be a type of string');
  }

  const [ref, width, height] = useResizeObserver([() => {}, 300, 300]);

  const finalWidth = dimensionSizeCap(width, isBackgroundImage);
  const finalHeight = dimensionSizeCap(height, isBackgroundImage);

  const zeroSizeImage = finalWidth === 0 || finalHeight === 0;
  const cropSrc =
    !zeroSizeImage && inView
      ? getVariantUrl({
          url: src,
          width: finalWidth,
          ratio: ratio || finalWidth / finalHeight,
          useWebp,
        })
      : empty1pxImage;

  if (isBackgroundImage) {
    return (
      <div
        className={`${className}-background ${localClassName}`}
        ref={ref}
        style={{
          backgroundImage: `url(${cropSrc})`,
        }}
        data-testid={dataTestid}
      >
        {children}
      </div>
    );
  }

  return (
    <>
      {children}
      <img
        data-testid={dataTestid}
        width={`${finalWidth}px`}
        height={`${ratio ? finalWidth / ratio : finalHeight}px`}
        className={className}
        ref={ref}
        src={cropSrc}
        loading={loading}
        alt={alt}
      />
    </>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  isBackgroundImage: PropTypes.bool,
  children: PropTypes.node,
  inView: PropTypes.bool,
  ratio: PropTypes.number,
  // allow usage of baked in browser lazy loading
  loading: PropTypes.oneOf(['eager', 'lazy']),
  useWebp: PropTypes.bool,
  localClassName: PropTypes.string,
  dataTestid: PropTypes.string,
};

Image.defaultProps = {
  isBackgroundImage: false,
  useWebp: true,
  localClassName: '',
};

const LazyLoadImageWrapper = (
  { isLazy, localClassName, ...props },
  context,
  className,
  dataTestid,
) => {
  const [ref, inView] = useInView({
    rootMargin: '200px',
    threshold: 0,
    triggerOnce: true,
  });

  const classes = localClassName
    ? `${className}-wrapper ${localClassName}`
    : `${className}-wrapper`;
  return (
    <div ref={ref} className={classes}>
      {!inView && isLazy && <Loader />}
      <Image
        dataTestid={dataTestid}
        inView={isLazy ? inView : true}
        {...props}
        className={className}
        localClassName={localClassName}
      />
    </div>
  );
};

const LazyLoadImageWrapperE = withFunctionalClassName(
  ComponentType.ATOM,
  'image',
)(LazyLoadImageWrapper);

LazyLoadImageWrapper.propTypes = {
  isLazy: PropTypes.bool,
  localClassName: PropTypes.string,
  dataTestid: PropTypes.string,
};

LazyLoadImageWrapper.defaultProps = {
  isLazy: true,
  localClassName: '',
};

const HookWrapper = props => (
  <Fragment>{typeof window !== 'undefined' && <LazyLoadImageWrapperE {...props} />}</Fragment>
);

HookWrapper.propTypes = {
  isLazy: PropTypes.bool,
};

export default HookWrapper;
