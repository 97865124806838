/* global 'organism','DetailTopBar' */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import InteractionType from 'common/src/app/data/enum/InteractionType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import InteractionToggleWrapper from 'common/src/app/hoc/InteractionToggleWrapper';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import Configuration from 'common/src/app/config/Configuration';
import { DataLayerKeys } from 'common/src/app/data/enum/Tracking';
import ElementTypes from 'common/src/app/data/enum/ElementTypes';
import BackLink from '../../molecules/BackLink';
import IconButton from '../../molecules/IconButton';
import BookmarkButtonInteraction from '../../molecules/BookmarkButtonInteraction';
import TextNew, { Colors } from '../../atoms/TextNew';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import DomEventTracker from '../../atoms/DomEventTracker';
import errorBoundary from '../../hoc/ErrorBoundary/index';
import './detail-top-bar.scss';

const renderBackLink = (backlink, backlinkCopy, useBrowserBack) => {
  if (!backlink) {
    return null;
  }

  if (typeof backlink === 'string') {
    return <BackLink backlink={backlink} backlinkCopy={backlinkCopy} />;
  }

  return <BackLink useBrowserBack={useBrowserBack} backlinkCopy={backlinkCopy} />;
};

/**
 * Container for user navigation or quick actions placed on the content-top of page.
 */
const DetailTopBar = (
  {
    backlink,
    backlinkCopy,
    useBrowserBack,
    print,
    bookmark,
    createPostLink,
    children,
    reference,
    deviceState,
    canInteract,
  },
  _,
  className,
) => {
  const isMenuFixed = deviceState > DeviceState.XXXL && Configuration.menuFixed;

  return (
    <div className={classNames(className, { 'is-menu-fixed': isMenuFixed })} ref={reference}>
      {renderBackLink(backlink, backlinkCopy, useBrowserBack)}
      <div className="content-right">
        {print && (
          <DomEventTracker meta={{ [DataLayerKeys.PRINT]: 1 }}>
            <IconButton cid="icon-button-print" icon="print" onClick={() => window.print()}>
              <TextNew localeId="general.cta.print" />
            </IconButton>
          </DomEventTracker>
        )}
        {bookmark && canInteract && (
          <InteractionToggleWrapper
            targetId={bookmark.pageId}
            targetTitle={bookmark.title}
            interactionType={InteractionType.BOOKMARK}
            targetType={bookmark.RelatedEntity}
            hasLabel
            component={BookmarkButtonInteraction}
          />
        )}
        {createPostLink && canInteract && (
          <Button ripple to={createPostLink} cid="create-post-link">
            <Icon name={IconName.COMMENT_FILLED} />
            <TextNew
              element={ElementTypes.SPAN}
              color={Colors.PRIMARY_LIGHT}
              localeId="general.cta.startAChat"
            />
          </Button>
        )}
        {children}
      </div>
    </div>
  );
};

DetailTopBar.defaultProps = {
  backlink: true,
  canInteract: true,
};

DetailTopBar.propTypes = {
  /*
   * Displays back link
   */
  backlink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Custom copy for the back link
   */
  backlinkCopy: PropTypes.string,
  useBrowserBack: PropTypes.bool,
  /*
   * Displays print icon
   */
  print: PropTypes.bool,
  /*
   * Displays Bookmark, requires page id and related
   * entity type
   */
  bookmark: PropTypes.shape({
    pageId: PropTypes.string.isRequired,
    RelatedEntity: PropTypes.number.isRequired,
  }),
  children: PropTypes.node,
  /*
   * Displays Create post link
   */
  createPostLink: PropTypes.string,
  reference: PropTypes.func,
  deviceState: PropTypes.number,
  canInteract: PropTypes.bool,
};

export default compose(
  errorBoundary(),
  withDeviceState(),
  withFunctionalClassName('organism','DetailTopBar'),
)(DetailTopBar);
