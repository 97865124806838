import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import PropTypes from 'prop-types';

// 404 components
import NotFound from 'components/templates/NotFound';
import RecipeNotFound from './components/molecules/RecipeNotFound';
import RealLifeStoryNotFound from './components/molecules/RealLifeStoryNotFound';
import GroupNotFound from './components/molecules/GroupNotFound';

import './smart-not-found.scss';

// The areas of the site - which are elegible for smart 404s
const site404Areas = {
  GROUP: 'group',
  RECIPES: 'recipes',
  REAL_LIFE_STORIES: 'real-life-stories',
};

const urlTestCases = {
  [site404Areas.GROUP]: /group-/,
  [site404Areas.RECIPES]: /recipes-/,
  [site404Areas.REAL_LIFE_STORIES]: /real-life-stories-/,
};

const urlMatchFunctions = {
  [site404Areas.GROUP]: () => <GroupNotFound key="group-not-found" />,
  [site404Areas.RECIPES]: slug => <RecipeNotFound slug={slug} key="recipe-not-found" />,
  [site404Areas.REAL_LIFE_STORIES]: () => <RealLifeStoryNotFound key="real-life-story-not-found" />,
};

const notFoundCase = slug =>
  // Map over the site404Areas object - enum above
  Object.values(site404Areas)
    .map(area => {
      // For each area e.g. `recipes`
      // - Perform its corresponding regex test
      if (urlTestCases[area].test(slug)) {
        // If the regex matches
        // - Return the areas corresponding function (from urlMatchFunctions)
        return urlMatchFunctions[area](slug);
      }
      return null;
    })
    .filter(x => x);

const SmartNotFound = ({ params: { slug } }, context, className) => {
  const smartlandingPage = notFoundCase(slug);

  return (
    <div className={className}>
      {smartlandingPage.length === 0 ? <NotFound /> : smartlandingPage}
    </div>
  );
};

SmartNotFound.propTypes = {
  params: PropTypes.object,
};

export default withFunctionalClassName(ComponentType.PAGE, 'SmartNotFound')(SmartNotFound);
