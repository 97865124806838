/**
 * Renders text using one of our pre-defined text styles.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { Scrollbars } from 'react-custom-scrollbars';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import './custom-scrollbar.scss';

const THUMB_THICKNESS = 8;

/**
 * The reason that i didn't put the default height value in the defaultProps implementation is
 * because the only value that we want to pass is a number and not a string value
 */
const CustomScrollBar = (
  { children, height = '100%', reference, autoHide = true, position = 'relative', updateHandler },
  context,
  className,
) => (
  <Scrollbars
    universal
    autoHide={autoHide}
    style={{
      height,
      position,
    }}
    onUpdate={updateHandler}
    ref={reference}
    className={className}
    renderTrackVertical={({ style, ...props }) => (
      <div {...props} style={{ ...style, width: THUMB_THICKNESS }} className="track-vertical" />
    )}
    renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
    renderTrackHorizontal={({ style, ...props }) => (
      <div {...props} style={{ ...style, height: THUMB_THICKNESS }} className="track-horizontal" />
    )}
    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" />}
    renderView={props => <div {...props} className={'scroll-content-wrapper'} />}
  >
    {children}
  </Scrollbars>
);

CustomScrollBar.propTypes = {
  height: PropTypes.node,
  position: PropTypes.string,
  reference: PropTypes.func,
  children: PropTypes.node.isRequired,
  updateHandler: PropTypes.func,
  autoHide: PropTypes.bool,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'CustomScrollBar')(CustomScrollBar);
