import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import IconName from 'common/src/app/data/enum/IconName';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import Icon from '../../../../../atoms/Icon';
import TextNew, { AccentColors, Colors } from '../../../../../atoms/TextNew';
import ResponsiveImage from '../../../../../atoms/ResponsiveImage';
import './quotation-block.scss';

const QuotationBlock = (
  {
    quotation,
    author,
    authorTextColor,
    authorPhoto,
    note,
    hasLargeQuoteStyle,
    isConsultant,
    deviceState,
  },
  context,
  className,
) => (
  <div
    className={classNames(className, {
      'has-small-quotes': !hasLargeQuoteStyle,
      'is-consultant': isConsultant,
    })}
  >
    <blockquote>
      {hasLargeQuoteStyle && (
        <Icon
          name={isConsultant ? IconName.CONSULTANT_QUOTES : IconName.BLOCKQUOTE}
          width={isConsultant ? (deviceState <= DeviceState.SM ? 22 : 44) : 76} // eslint-disable-line no-nested-ternary
        />
      )}

      <div
        className="quote"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: quotation }}
      />

      {hasLargeQuoteStyle && (
        <Icon
          name={isConsultant ? IconName.CONSULTANT_QUOTES : IconName.BLOCKQUOTE}
          width={isConsultant ? (deviceState <= DeviceState.SM ? 22 : 44) : 76} // eslint-disable-line no-nested-ternary
          direction={DirectionType.UP}
        />
      )}
    </blockquote>

    <div className="author-details">
      {authorPhoto && (
        <ResponsiveImage src={authorPhoto.src} alt={authorPhoto.alt} ratio={1} px={64} />
      )}

      <div className="quote-detail">
        {author && (
          <TextNew.Details cid="quotation-author" color={authorTextColor}>
            {author}
          </TextNew.Details>
        )}
        {note && <TextNew.PrimaryFootnote>{note}</TextNew.PrimaryFootnote>}
      </div>
    </div>
  </div>
);

QuotationBlock.defaultProps = {
  authorTextColor: Colors.BRAND,
  hasLargeQuoteStyle: true,
  isConsultant: false,
};

QuotationBlock.propTypes = {
  quotation: PropTypes.string.isRequired,
  author: PropTypes.string,
  note: PropTypes.string,
  authorTextColor: PropTypes.oneOf([...Colors.propTypes, ...AccentColors.propTypes]),
  authorPhoto: PropTypes.shape({
    ...imageShape,
  }),
  hasLargeQuoteStyle: PropTypes.bool,
  isConsultant: PropTypes.bool,
  deviceState: PropTypes.number,
};

export const ComponentProps = QuotationBlock;

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ATOM, 'QuotationBlock')(QuotationBlock),
);
