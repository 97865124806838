import startCase from 'lodash/startCase';

const getGroupsInCountyAndTown = (groups, town, county) => {
  if (!town && !county) {
    return [];
  }

  return groups?.reduce((acc, group) => {
    const isInTown = startCase(group.venue.town) === startCase(town);
    const results = [];

    if (town && county) {
      isInTown && startCase(group.venue.county) === startCase(county) && results.push(group);
      return [...results, ...acc];
    }

    isInTown && results.push(group);
    return [...results, ...acc];
  }, []);
};

export default getGroupsInCountyAndTown;
