import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Wrapper from 'components/atoms/Wrapper';
import Size from 'common/src/app/data/enum/Size';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import TextNew, { ElementTypes, Colors } from 'components/atoms/TextNew';
import './consultant-enquiry-success.scss';

const ConsultantEnquirySuccess = ({ pageBackgroundImage }, context, className) => (
  <div
    className={className}
    style={{ backgroundImage: pageBackgroundImage && `url(${pageBackgroundImage.src}` }}
  >
    <Wrapper.MD padding={Size.XL}>
      <h1>
        <TextNew.Script.LG
          element={ElementTypes.SPAN}
          color={Colors.BRAND}
          localeId="consultantEnquirySuccess.thankYou"
        />
        <TextNew.Serif.LG element={ElementTypes.SPAN} localeId="consultantEnquirySuccess.header" />
      </h1>
      <TextNew.Serif.SM localeId="consultantEnquirySuccess.moreInfo" />
    </Wrapper.MD>
  </div>
);

ConsultantEnquirySuccess.propTypes = {
  pageBackgroundImage: PropTypes.shape(imageShape),
};

export default withFunctionalClassName(
  ComponentType.TEMPLATE,
  'ConsultantEnquirySuccess',
)(ConsultantEnquirySuccess);
