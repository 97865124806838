import handleActions from 'redux-actions/lib/handleActions';
import { SAVE_PUBLIC_BMI } from 'common/src/app/actions/resources/publicActions';

const initialState = { bmi: null };

export default handleActions(
  {
    [SAVE_PUBLIC_BMI]: (state, { payload }) => ({
      ...state,
      bmi: payload,
    }),
  },
  initialState,
);
