import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Configuration from 'common/src/app/config/Configuration';
import IconName from 'common/src/app/data/enum/IconName';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import MeasureType from 'common/src/app/data/enum/MeasureType';
import AbbreviationType from 'common/src/app/data/enum/AbbreviationType';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import CSSTransition from '../../molecules/CSSTransition';
import HeightImperialField from './components/molecules/HeightImperialField';
import HeightMetricField from './components/molecules/HeightMetricField';
import WeightMetricField from './components/molecules/WeightMetricField';
import WeightImperialField from './components/molecules/WeightImperialField';
import './measurement-input-field.scss';

/**
 *  Complete Measurement component.
 *  Take any one of the WeightMeasurementUnit / HeightMeasurementUnit
 *  ENUMS, and returns the correct component.
 **/
const MeasurementInputField = (
  {
    animated,
    name,
    unit,
    abbreviationType,
    type,
    standoutStyle,
    deviceState,
    disabled,
    hidePlaceholder,
    hideStonesUsePounds,
    ...props
  },
  context,
  className,
) => {
  let measurementComponent;
  let abbreviate;
  let selectArrowType;

  // If standout style is set change the type of dropdown appear
  selectArrowType = standoutStyle
    ? (selectArrowType = IconName.CHEVRON_LARGE)
    : (selectArrowType = Configuration.selectIconName);
  // Set abbreviation based on viewport width
  if (abbreviationType === AbbreviationType.SHORT_TO_LONG) {
    abbreviate = deviceState <= DeviceState.MD;
  } else if (abbreviationType === AbbreviationType.LONG_TO_SHORT) {
    abbreviate = deviceState >= DeviceState.MD;
  }
  // Assign correct measurement input based on type passed
  if (type === MeasureType.WEIGHT && unit === MeasureUnit.IMPERIAL) {
    measurementComponent = (
      <WeightImperialField
        name={name}
        abbreviate={abbreviate}
        disabled={disabled}
        key={0}
        selectArrowType={selectArrowType}
        hidePlaceholder={hidePlaceholder}
        hideStonesUsePounds={hideStonesUsePounds}
        {...props}
      />
    );
  } else if (type === MeasureType.WEIGHT && unit === MeasureUnit.METRIC) {
    measurementComponent = (
      <WeightMetricField
        name={name}
        abbreviate={abbreviate}
        disabled={disabled}
        key={2}
        hidePlaceholder={hidePlaceholder}
        {...props}
      />
    );
  } else if (type === MeasureType.HEIGHT && unit === MeasureUnit.IMPERIAL) {
    // formatter = compositeInputFormatters.HEIGHT_FEET_TO_CM;
    measurementComponent = (
      <HeightImperialField
        name={name}
        abbreviate={abbreviate}
        disabled={disabled}
        key={3}
        {...props}
      />
    );
  } else if (type === MeasureType.HEIGHT && unit === MeasureUnit.METRIC) {
    measurementComponent = (
      <HeightMetricField
        name={name}
        abbreviate={abbreviate}
        disabled={disabled}
        key={4}
        {...props}
      />
    );
  } else {
    throw new Error("MeasurementInputField wasn't passed any values.");
  }

  return (
    <div className={className}>
      {animated ? (
        <CSSTransition animateIn={true} animationType="fade">
          {measurementComponent}
        </CSSTransition>
      ) : (
        measurementComponent
      )}
    </div>
  );
};

MeasurementInputField.defaultProps = {
  abbreviationType: AbbreviationType.SHORT_TO_LONG,
};

MeasurementInputField.propTypes = {
  /*
  * If set shortToLong, the abbreviation
  will be used on mobile but not desktop and vise versa
  */
  abbreviationType: PropTypes.oneOf([
    AbbreviationType.SHORT_TO_LONG,
    AbbreviationType.LONG_TO_SHORT,
  ]),
  type: PropTypes.oneOf([MeasureType.HEIGHT, MeasureType.WEIGHT]),
  unit: PropTypes.oneOf([MeasureUnit.IMPERIAL, MeasureUnit.METRIC]),
  name: PropTypes.string,
  /*
   * Large measurement input style(used within weigh-in). By default the measurement input
   * does not have any additional styles
   */
  standoutStyle: PropTypes.bool,
  animated: PropTypes.bool,
  deviceState: PropTypes.number,

  // Allow input fields to be disabled
  disabled: PropTypes.bool,
  // Allow the place holders to be hidden
  hidePlaceholder: PropTypes.bool,
  // Allow the stones input to be hidden
  hideStonesUsePounds: PropTypes.bool,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'MeasurementInputField', ['standoutStyle'])(
    MeasurementInputField,
  ),
);
