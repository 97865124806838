import createAction from 'redux-actions/lib/createAction';

export const SET_ENVIRONMENT_CONFIG = 'renderActions.SET_ENVIRONMENT_CONFIG';
export const SET_VIEWER_COUNTRY = 'renderActions.SET_VIEWER_COUNTRY';
export const ENABLE_PERFORMANCE_MEASUREMENTS = 'configActions/ENABLE_PERFORMANCE_MEASUREMENTS';
export const SET_WEBVIEW_COOKIE = 'configActions/SET_WEBVIEW_COOKIE';
export const SET_RECAPTCHA_TOKEN = 'configActions/SET_RECAPTCHA_TOKEN';

export const enablePerformanceMeasurements = createAction(ENABLE_PERFORMANCE_MEASUREMENTS);
export const setEnvironmentConfig = createAction(SET_ENVIRONMENT_CONFIG);
export const setViewerCountry = createAction(SET_VIEWER_COUNTRY);
export const setWebViewCookie = createAction(SET_WEBVIEW_COOKIE);
export const setRecaptchaToken = createAction(SET_RECAPTCHA_TOKEN);
