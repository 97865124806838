import React from 'react';
import PropTypes from 'prop-types';
import FilterSidebar from 'components/organisms/FilterSidebar';
import camelCase from 'lodash/camelCase';
import FilterCategoryGroup from 'components/organisms/FilterSidebar/components/FilterCategoryGroup';
import { groupSearchfilterConfig } from 'common/src/app/data/enum/GroupSearchFilterFlags';

const generateValues = ({ types, name, getMessage, section }) =>
  Object.keys(types).map(key => ({
    name: key,
    text: getMessage(`searchFilter.${section}.${name}.${camelCase(key)}`),
  }));

const GroupSearchFilter = ({ values, toggle, handleChange, getMessage }) => (
  <FilterSidebar filterLabel={'Filter Group search'} onClose={toggle}>
    {groupSearchfilterConfig.map(({ fieldName, legend, flags }) => (
      <FilterCategoryGroup
        key={fieldName}
        activeValues={values[fieldName]}
        legend={legend}
        values={generateValues({
          types: flags,
          name: fieldName,
          getMessage,
          section: 'nearestGroupSearch',
        })}
        handleChange={handleChange}
        fieldName={fieldName}
      />
    ))}
  </FilterSidebar>
);

GroupSearchFilter.propTypes = {
  values: PropTypes.object,
  toggle: PropTypes.func,
  handleChange: PropTypes.func,
  getMessage: PropTypes.func,
};

export default GroupSearchFilter;
