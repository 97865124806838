import React from 'react';
import PropTypes from 'prop-types';
import { AccentColors } from 'common/src/app/data/enum/Colors';
import ArticleType from 'common/src/app/data/enum/ArticleType';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import TextNew from '../../../../../atoms/TextNew';
import Row from '../../../../../atoms/Row';
import MasterTile from '../../../../MasterTile';

const mealCategory = ['breakfast', 'lunch', 'dinner'];

const RecipeTiles = ({ data }) => (
  <Row row wrap className="recipes-container">
    {data &&
      mealCategory.map(mealType => {
        const [meal] = data[mealType];

        return (
          <div className="recipe-tile" key={mealType}>
            <MasterTile
              {...meal}
              _type={ArticleType.PUBLIC_RECIPE_LARGE}
              category={
                <TextNew.Script.XS color={AccentColors.ACCENT_PURPLE} cid="recipe-category">
                  {mealType}
                </TextNew.Script.XS>
              }
              imageLoading="lazy"
              customDescription={<RichTextBlock text={meal.description} cid="recipe-description" />}
              id={meal.id}
            />
          </div>
        );
      })}
  </Row>
);

RecipeTiles.propTypes = {
  data: PropTypes.object,
};

export default RecipeTiles;
