import createAction from 'redux-actions/lib/createAction';
import GatewayError from '../net/gateway/GatewayError';

export const ADD_ERROR = 'errorActions/ADD_ERROR';
export const CLEAR_ERRORS = 'errorActions/CLEAR_ERRORS';

export const clearErrors = createAction(CLEAR_ERRORS);

/**
 * @param {string} errorCode
 * @param {string} errorMessage
 * @param {number} statusCode The http status code that should be used when displaying this error
 */
export const addError = createAction(
  ADD_ERROR,
  ({ errorCode, errorMessage, statusCode, stack }) => ({
    statusCode,
    code: errorCode,
    message: errorMessage,
    stack,
  }),
);

export const handleRuntimeError = error => dispatch => {
  const isGatewayError = error instanceof GatewayError;
  let errorCode = 'errors.runtime';
  if (isGatewayError) {
    errorCode = error.response.status === 404 ? 'errors.notFound' : 'errors.gateway';
  }

  dispatch(
    addError({
      errorCode,
      statusCode: (isGatewayError && error.response.status) || 500,
    }),
  );
};
