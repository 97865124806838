/**
 * List Types
 * The ListType is returned from Umbraco as an integer.
 * This enum is used to help us identfy the type
 */

export default {
  OL: 0,
  UL: 1,
};
