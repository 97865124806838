import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import NearestGroupLandingHeader from './NearestGroupLandingHeader';

const mapStateToProps = state => ({
  query: state.routing.locationBeforeTransitions.query,
});

const connected = connect(mapStateToProps);

export default compose(connected)(NearestGroupLandingHeader);
