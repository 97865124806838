import { createSelector } from 'reselect';

/**
 * Creates a selector that returns `true` if an async action is currently pending for any
 * of the given action types.
 * @function makeIsLoadingSelector
 * @param actionTypes {...string} One or multiple action types to check for an isLoading
 * state
 * @category api-calls
 * @example export default connect(
 *   () => {
 *     const isLoadingPostsSelector = makeIsLoadingSelector(GET_POSTS);
 *     return state => ({
 *       isLoadingPosts: isLoadingPostsSelector(state),
 *     });
 *   }
 * )(MyComponent);
 */
const makeIsLoadingSelector = (...actionTypes) =>
  createSelector(
    state => state.async.actionTypes,
    actionTypesState => actionTypes.some(actionType => actionTypesState[actionType]),
  );

export default makeIsLoadingSelector;
