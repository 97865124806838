import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeInteractionStateSelector } from 'common/src/app/reducers/interactionsReducer';
import InteractionType from 'common/src/app/data/enum/InteractionType';
import RecipeTileContent from './RecipeTileContent';

const mapStateToProps = () => {
  const interactionSelector = makeInteractionStateSelector();

  return (state, { id, isTwelveWeekProgramContent }) => {
    if (isTwelveWeekProgramContent) {
      const { isActive: isRead } = interactionSelector(state.interactions, {
        interactionType: InteractionType.READ,
        targetId: id,
      });
      return { isRead };
    }
    return {};
  };
};

RecipeTileContent.propTypes = {
  ...RecipeTileContent.propTypes,
  isTwelveWeekProgramContent: PropTypes.bool,
};

export default connect(mapStateToProps)(RecipeTileContent);
