// import Url from 'url-parse';
import serviceConfig from '../config/service.configdefinitions';
import WebHost from '../data/enum/WebHost';

/**
 * Array of webHost values
 * @type {Array}
 */
const webHosts = Object.values(WebHost);

/**
 * Will prepend the given webHost from the environment config to the given path, except
 * when the given webHost is the same as the current webHost. This will make sure that we don't
 * create a full url (with host) when a root-relative path will suffice.
 *
 * @param environmentConfig {object} The full environment config object
 * @param path {string} The path. Should be one of the paths defined in Pages.js
 * @param [webHost=null] {string} The webHost to redirect to. Should be one of the strings
 * from the WebHost.js enum. If omitted, will never prepend a host
 */
function prependWebHost(environmentConfig, path = '', webHost = null) {
  if (!webHost || serviceConfig.webHost === webHost) {
    return path;
  }
  if (!webHosts.includes(webHost)) {
    throw new ReferenceError(`Unknown webHost "${webHost}"`);
  }

  return `${environmentConfig.web[webHost].host}${path}`;
}

export default prependWebHost;
