/* global 'atom','ButtonBulleted' */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Row from '../Row';

import './button-bulleted.scss';

const ButtonBulleted = (
  { gridColumnCount, bulletCount, onClick },
  context,
  className,
  dataTestid,
) => (
  <button
    onClick={() => onClick(bulletCount)}
    tabIndex="0"
    data-testid={dataTestid}
    className={classNames(className, { 'is-active': gridColumnCount === bulletCount })}
  >
    <Row row spacearound>
      {Array(bulletCount)
        .fill()
        .map((_, index) => (
          <span key={`bullet-button-${index}`} />
        ))}
    </Row>
  </button>
);

ButtonBulleted.propTypes = {
  gridColumnCount: PropTypes.number,
  bulletCount: PropTypes.number,
  onClick: PropTypes.func,
};

export default withFunctionalClassName('atom','ButtonBulleted')(ButtonBulleted);
