/**
 * Cuisine map
 */
const Cuisine = {
  0: 'Italian',
  1: 'Chinese',
  2: 'Mexican',
  3: 'Greek',
  4: 'Thai',
  5: 'Indian',
  6: 'MiddleEastern',
  7: 'American',
  8: 'Mediterranean',
  9: 'French',
  10: 'Asian',
  11: 'BritishIsles',
};

export default Cuisine;
