/**
 * Age map
 */
const AgeType = {
  0: 'Under20',
  1: 'Twenties',
  2: 'Thirties',
  3: 'Forties',
  4: 'Fifties',
  5: 'SixtiesAndMore',
};

export default AgeType;
