import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Pages, { REGISTRATION_FIRST_STEP } from 'common/src/app/data/enum/Pages';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import WebHost from 'common/src/app/data/enum/WebHost';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import Wrapper from 'components/atoms/Wrapper';
import Image from 'components/atoms/Image';

import Button from '../../atoms/Button';
import WebHostLink from '../../atoms/WebHostLink';
import TextNew, { AccentColors, Colors, ElementTypes, FontWeight } from '../../atoms/TextNew';
import errorBoundary from '../../hoc/ErrorBoundary';

import './group-online-sign-up.scss';

const GroupOnlineSignUp = (
  { headerTitle, headerSubtitle, group, online },
  { getMessage },
  className,
) => {
  if (!group || !online) {
    return null;
  }

  return (
    <div className={className} data-testid="group-online-signup">
      <header>
        <h2>
          <TextNew.Confident color={AccentColors.ACCENT_PURPLE} element={ElementTypes.SPAN}>
            {headerTitle}
          </TextNew.Confident>
          <TextNew.Script.LG color={AccentColors.ACCENT_PURPLE} element={ElementTypes.SPAN}>
            {headerSubtitle}
          </TextNew.Script.LG>
        </h2>
      </header>

      <Wrapper className="group-online-signup-content">
        {['group', 'online'].map(signUpType => {
          const {
            subtitle,
            title,
            description,
            headerImage,
            introductoryOffer,
            primaryButtonCopy,
            secondaryButtonCopy,
          } = signUpType === 'group' ? group : online;

          return (
            <div className={`signup-block ${signUpType}`} key={`${signUpType}-sign-up-type`}>
              <figure>
                <Image alt={headerImage?.alt} ratio={476 / 309} src={headerImage?.src} />
              </figure>
              <div className="content">
                <h3 className="heading">
                  <TextNew.Strong
                    cid="title"
                    color={Colors.PRIMARY_DARK}
                    element={ElementTypes.SPAN}
                  >
                    {title}
                  </TextNew.Strong>
                  <TextNew.Script.MD cid="subtitle" element={ElementTypes.SPAN}>
                    {subtitle}
                  </TextNew.Script.MD>
                </h3>

                <RichTextBlock cid="description" text={description} />

                <div className="footer-section">
                  <RichTextBlock cid="introductory-offer" text={introductoryOffer?.offer} />

                  <TextNew.Sans.XS
                    cid="introductory-offer-disclaimer"
                    color={Colors.PRIMARY_DARK}
                    weight={FontWeight.BOLD}
                  >
                    {introductoryOffer?.disclaimer}
                  </TextNew.Sans.XS>

                  {signUpType === 'group' ? (
                    <>
                      <Button
                        aria-label={getMessage('groupOnlineSignUp.buttonLabel.group')}
                        isGhostPink
                        to={Pages.UK_PUBLIC_WHAT_HAPPENS_IN_GROUP}
                      >
                        <TextNew.Sans.SM weight={FontWeight.BOLDER}>
                          {primaryButtonCopy}
                        </TextNew.Sans.SM>
                      </Button>

                      <Button isOnBackgroundColor to={Pages.UK_PUBLIC_NEAREST_GROUP_LANDING}>
                        <TextNew.Sans.SM element={ElementTypes.SPAN} weight={FontWeight.BOLDER}>
                          {secondaryButtonCopy}
                        </TextNew.Sans.SM>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        aria-label={getMessage('groupOnlineSignUp.buttonLabel.online')}
                        isGhostBlue
                        to={Pages.UK_PUBLIC_ONLINE_INFORMATION}
                      >
                        <TextNew.Sans.SM weight={FontWeight.BOLDER}>
                          {primaryButtonCopy}
                        </TextNew.Sans.SM>
                      </Button>
                      <WebHostLink webHost={WebHost.ACCOUNT} link={REGISTRATION_FIRST_STEP}>
                        <Button isOnBackgroundColor to={null}>
                          <TextNew.Sans.SM element={ElementTypes.SPAN} weight={FontWeight.BOLDER}>
                            {secondaryButtonCopy}
                          </TextNew.Sans.SM>
                        </Button>
                      </WebHostLink>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </Wrapper>
    </div>
  );
};

GroupOnlineSignUp.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

GroupOnlineSignUp.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  headerSubtitle: PropTypes.string.isRequired,
  group: PropTypes.shape({
    headerImage: PropTypes.shape(imageShape),
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    introductoryOffer: PropTypes.shape({
      offer: PropTypes.string.isRequired,
      disclaimer: PropTypes.string,
    }),
  }).isRequired,
  online: PropTypes.shape({
    headerImage: PropTypes.shape(imageShape),
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    introductoryOffer: PropTypes.shape({
      offer: PropTypes.string.isRequired,
      disclaimer: PropTypes.string,
    }),
    primaryButtonCopy: PropTypes.string.isRequired,
    secondaryButtonCopy: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.MOLECULE, 'GroupOnlineSignUp'),
)(GroupOnlineSignUp);
