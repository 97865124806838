import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import {
  GET_STATIC_CONTENT,
  getStaticContent,
} from 'common/src/app/actions/resources/staticContentActions';
import { API_STATIC_PUBLIC_FRANCHISE_FACTS } from 'common/src/app/data/apiStatics';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import FranchiseFacts from './FranchiseFacts';

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT);

const mapStateToProps = state => ({
  loading: pageLoading(state),
  pageData: staticPageSelector(state, API_STATIC_PUBLIC_FRANCHISE_FACTS),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  clientOnly: ({}, dispatch) => dispatch(getStaticContent(API_STATIC_PUBLIC_FRANCHISE_FACTS, true)),
});

export default compose(addInitAction, connected)(FranchiseFacts);
