import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import ContactUsFieldNames from 'common/src/app/data/enum/FieldNames/ContactUsFieldNames';
import {
  ContactFormSubjectIds,
  getSubjectOptions,
} from 'common/src/app/data/enum/ContactFormCategory';
import InputType from 'common/src/app/data/enum/InputType';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import { ContactUsType } from 'common/src/app/data/enum/ContactUsType';
import Textarea from '../../atoms/Textarea';
import Button from '../../atoms/Button';
import Select from '../../atoms/Select';
import FormikError from '../../atoms/FormikError';
import TextNew, { Colors, ElementTypes, FontWeight } from '../../atoms/TextNew';
import ContactUsIcelandConfirm from '../../atoms/ContactUsIcelandConfirm';
import ContactUsSuccessMessage from '../../molecules/ContactUsSuccessMessage';

import ContactUsPublicForm from './components/molecules/ContactUsPublicForm';

import './contact-us-form.scss';

class ContactUsForm extends Component {
  componentDidMount() {
    window.addEventListener('load', this.onLoad);
  }

  onLoad = () => {
    const { scrollToElement, contactType } = this.props;
    // Do not scroll past Group Enquiries/Chat Now section when coming from faqs
    if (this.contactForm && contactType !== ContactUsType.GET_IN_TOUCH) {
      scrollToElement(this.contactForm, 0, false);
    }
  };

  componentWillUnmount() {
    const { setSucessMessage } = this.props;
    setSucessMessage && setSucessMessage(null);
  }

  render() {
    const { getMessage } = this.context;
    const {
      errors,
      successMessageSubjectId,
      values,
      handleSubmit,
      handleChange,
      handleBlur,
      children,
      loading,
      hasErrorBoundary,
      hasContactType,
      isValid,
      isSubmitting,
    } = this.props;

    const { contactFormCategory, icelandTerms } = values;

    const dataTestid = componentClassNameProp(ComponentType.ORGANISM, this)['data-testid'];

    const selectOptions = [
      { title: getMessage('contactUsForm.placeholder.subject'), value: '' },
      ...getSubjectOptions({ getMessage }),
    ];

    let localeMessageType = '';

    if (contactFormCategory === ContactFormSubjectIds.ADVERTISEMENT_ARTWORK) {
      localeMessageType = 'Advertisement';
    }

    return (
      <div
        {...componentClassNameProp(ComponentType.ORGANISM, this)}
        ref={el => (this.contactForm = el)}
      >
        {successMessageSubjectId ? (
          <header>
            <ContactUsSuccessMessage subjectId={successMessageSubjectId} />
          </header>
        ) : (
          <>
            <TextNew.Sans.SM
              weight={FontWeight.LIGHT}
              localeId="contactUsForm.body"
              localeParams={{
                PREFER: (
                  <TextNew.Sans.SM
                    key="contact-us-prefer-email"
                    weight={FontWeight.NORMAL}
                    localeId="contactUsForm.prefer"
                    element={ElementTypes.SPAN}
                  />
                ),
              }}
            />
            <form onSubmit={handleSubmit}>
              <Select
                customLabel={getMessage('contactUsForm.placeholder.subject')}
                defaultValue={contactFormCategory}
                dataTestid={`${dataTestid}-select`}
                onChange={handleChange}
                name={ContactUsFieldNames.CONTACT_FORM_CATEGORY}
                options={selectOptions}
                disabled={hasErrorBoundary || hasContactType}
              />
              {contactFormCategory === ContactFormSubjectIds.SW_ICELAND && (
                <ContactUsIcelandConfirm value={icelandTerms} handleChange={handleChange} />
              )}
              {contactFormCategory !== '' && (
                <>
                  {children}
                  <Textarea
                    data-testid={`${dataTestid}-message`}
                    rows="4"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={ContactUsFieldNames.MESSAGE}
                    type={InputType.TEXT}
                    placeholder={getMessage(`contactUsForm.contactMessage${localeMessageType}`)}
                  />
                  <FormikError
                    dataTestid={`${dataTestid}-error`}
                    name={ContactUsFieldNames.MESSAGE}
                  />
                  <Button
                    type={InputType.SUBMIT}
                    dataTestid={`${dataTestid}-button`}
                    disabled={!isValid || isSubmitting}
                    isLoading={isSubmitting || loading}
                  >
                    <TextNew.Sans.SM
                      element={ElementTypes.SPAN}
                      color={Colors.PRIMARY_LIGHT}
                      localeId="contactUsForm.cta"
                    />
                  </Button>
                </>
              )}
            </form>
          </>
        )}

        {errors?.generalError && (
          <TextNew.Error localeId={errors.generalError} cid="error" color={Colors.BRAND} />
        )}
      </div>
    );
  }
}

ContactUsForm.defaultProps = {
  hasContactType: false,
};

ContactUsForm.propTypes = {
  children: PropTypes.node,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  successMessageSubjectId: PropTypes.string,
  setSucessMessage: PropTypes.func,
  loading: PropTypes.bool,
  hasErrorBoundary: PropTypes.bool,
  hasContactType: PropTypes.bool,
  scrollToElement: PropTypes.func,
  contactType: PropTypes.string,
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

ContactUsForm.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export { ContactUsPublicForm };

export default ContactUsForm;
