import { isAsyncAction } from '../../util/asyncActionMiddleware';

/**
 * Reducer that keeps track of each actionType for which an async action
 * is currently pending. The state has the following shape:
 * ```
 * { [actionType]: <numberOfPendingCalls> }
 * ```
 *
 * @function asyncActionTypes
 */
const asyncActionTypes = (state = {}, action) => {
  if (action && isAsyncAction(action)) {
    if (action.meta.isFulfilled) {
      if (state[action.type] === 1) {
        // eslint-disable-next-line no-unused-vars
        const { [action.type]: toRemove, ...newState } = state;

        return newState;
      }

      return {
        ...state,
        [action.type]: (state[action.type] || 0) - 1,
      };
    }

    return {
      ...state,
      [action.type]: (state[action.type] || 0) + 1,
    };
  }
  return state;
};

export default asyncActionTypes;
