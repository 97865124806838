/**
* Takes an Array and returns and object of grouped values
* @param {Array}
* @example given ["zebras","cats","bats"]
* @returns object {
  "b": ["bats"],
  "c": ["cats"],
  "z": ["zebras"]
}
*/

const groupByFirstChar = data =>
  data.reduce((accum, current) => {
    if (accum[current.charAt(0)] === undefined) {
      accum[current.charAt(0)] = []; // eslint-disable-line no-param-reassign
    }
    accum[current.charAt(0)].push(current);
    return accum;
  }, {});

export default groupByFirstChar;
