import debugLib from 'debug';
import camelCase from 'lodash/camelCase';
import { LOCATION_CHANGE } from 'react-router-redux';
import WebHost from '../../data/enum/WebHost';
import InteractionType from '../../data/enum/InteractionType';
import { ADD_INTERACTION } from '../../actions/resources/interactionActions';
import { dataLayerCustomEvent } from './dataLayerUtils';
import { getEntityTypeFromRelatedEntityKind } from '../../data/enum/RelatedEntityKind';
import { isFulfilled, isInteraction } from '../actionFilters';
import { entityNameMap, entityCategoryMap } from './entityTrackingMaps';
import updateTrackingDataCategory from './updateTrackingDataCategory';
import { POST } from '../../data/entityTypes';
import Pages from '../../data/enum/Pages';
import { DataLayerKeys } from '../../data/enum/Tracking';
import {
  PAGE_LOAD_COMPLETE,
  TRACK_DOM_EVENT,
  setTrackingPersistentData,
} from '../../actions/trackingActions';
import { marketName, pageTitle } from '../../config/market/market.configdefinitions';
import { trackPageView } from './index';
import trackingConfig from './tracking.configdefinitions';

export const debug = debugLib('SlimmingWorld:foodActions');
let eventFired = false;

const blacklistedPages = [
  Pages.REGISTRATION,
  Pages.GROUP_REGISTRATION,
  Pages.QUICK_START,
  Pages.WEIGHIN,
  Pages.EDIT_WEIGHIN,
  Pages.ADD_WEIGHIN,
  Pages.SETTINGS_NEW_JOURNEY,
  Pages.RE_RECOVER_EMAIL,
  Pages.PROFILE_BUILDER_GROUP,
  Pages.PROFILE_BUILDER_ONLINE,
  Pages.NEW_GROUP_JOURNEY,
];

const sharedListeners = (actionType = PAGE_LOAD_COMPLETE) => [
  /**
   * Initialisation phase
   */
  {
    listener: {
      actionType: LOCATION_CHANGE,
      filter: () => !eventFired,
    },
    callback: (_, getState, dispatch) => {
      dispatch(
        setTrackingPersistentData({
          [DataLayerKeys.MARKET]: marketName,
          [DataLayerKeys.PAGE_SOURCE]: trackingConfig.dataLayerSitePrefix,
        }),
      );

      eventFired = true;
    },
  },
  /**
   * PageView
   */
  {
    listener: { actionType },
    callback: (actionData, getState, dispatch) => {
      const state = getState();
      const location = state.routeHistory[0];

      if (blacklistedPages.includes(location.pathname)) {
        return;
      }

      const { locationBeforeTransitions } = state.routing;
      const isModal = locationBeforeTransitions.query.modal || location.query.modal;

      // If routeHistory length is 1 or smaller, we're at the landing page, so use the
      // original referrer. Otherwise, take the previous page we were on.
      let referrer = document.referrer;
      if (state.routeHistory.length > 1) {
        const historyEntry = state.routeHistory[1];
        referrer = `${document.location.origin}${historyEntry.pathname.replace(/\/$/gi, '')}${
          historyEntry.search
        }${historyEntry.hash}`;
      }

      const url = `${document.location.origin}${location.pathname}${location.search}${location.hash}`;
      const pathname = isModal ? getState().tracking.persistentData.pathname : location.pathname;

      const { data } = state.tracking.pageData.find(page => page.pathname === pathname) || {
        data: {},
      };

      dispatch(setTrackingPersistentData({ [DataLayerKeys.PATHNAME]: pathname }));

      /**
       * Specific tracking for live micro service
       */
      if (trackingConfig.dataLayerSitePrefix === WebHost.LIVE) {
        if (actionData && actionData.payload) {
          const dispatcher = (groupId, type) => {
            dispatch(
              setTrackingPersistentData({
                [DataLayerKeys.LIVE_EVENT_GROUP_ID]: groupId,
                [DataLayerKeys.LIVE_EVENT_STAGE]: type,
              }),
            );

            /**
             * Setting document.title manually here. The setSEO action is incompatible with
             * the live event microservice as it behaves differently (e.g. no PAGE_LOAD_COMPLETE).
             */
            document.title = `Chat ${type} | ${pageTitle}`;
          };

          if (actionData.payload.currentRound) {
            const {
              payload: {
                currentRound: { $type },
                id,
              },
            } = actionData;
            dispatcher(id, $type);
          } else {
            const {
              payload: { groupId: id, $type },
            } = actionData;
            dispatcher(id, $type);
          }
        }
      }

      if (pathname) {
        const sanitizedPathname = pathname.replace(/\/$/gi, '');
        const sanitizedUrl = url.replace(/\/$/gi, '');

        dispatch(
          setTrackingPersistentData({
            [DataLayerKeys.PATHNAME]: sanitizedPathname,
            [DataLayerKeys.REFERRER]: referrer,
            [DataLayerKeys.PAGE_ROUTE]: `/${trackingConfig.dataLayerSitePrefix}${sanitizedPathname}`,
            [DataLayerKeys.FULL_URL]: sanitizedUrl,
            [DataLayerKeys.PAGE_URL]: sanitizedPathname,
            [DataLayerKeys.PAGE_TITLE]: document.title,
          }),
        );
      }

      trackPageView(data, getState());
    },
  },
  /**
   * Track DOM event
   */
  {
    listener: { actionType: TRACK_DOM_EVENT },
    callback: ({ meta }, getState) => dataLayerCustomEvent({ ...meta }, getState()),
  },
  /**
   * Track bookmark interaction
   */
  {
    listener: {
      actionType: ADD_INTERACTION,
      filter: [isFulfilled, isInteraction(InteractionType.BOOKMARK)],
    },
    callback: (
      { meta: { interactionSubjectId, interactionSubjectKind, interactionSubjectTitle } },
      getState,
    ) => {
      const entityType = getEntityTypeFromRelatedEntityKind(interactionSubjectKind);
      let trackingData = {
        category: entityCategoryMap[entityType],
        trackingName: [entityNameMap[entityType]],
      };

      if (entityType === POST) {
        trackingData = updateTrackingDataCategory(trackingData, getState);
      }

      dataLayerCustomEvent(
        {
          category: entityType,
          entityId: interactionSubjectId,
          [trackingData.trackingName]: interactionSubjectTitle,
          [camelCase(`${DataLayerKeys.BOOKMARK}-${trackingData.category}`)]: 1,
        },
        getState(),
      );
    },
  },
];

export default sharedListeners;
