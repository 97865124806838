/**
 * FoodOptimising map
 */
const FoodOptimising = {
  1: 'Free',
  3: 'FoodOptimizingSP',
  5: 'FreezerFriendly',
};

export default FoodOptimising;
