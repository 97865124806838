/* global 'molecule','IconWithRichText' */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Icon from '../../atoms/Icon';
import TextNew, { Colors } from '../../atoms/TextNew';
import RichTextBlock from '../BlockContent/components/atoms/RichTextBlock';

const IconWithRichText = ({ item }) => (
  <div>
    <Icon width={item.link ? 35 : 74} name={item.icon.replace('icon-', '')} />
    <div className="list-content">
      {item.link ? (
        <Link target="_blank" to={item.link}>
          <TextNew.PrimaryElegant>{item.title}</TextNew.PrimaryElegant>
        </Link>
      ) : (
        <TextNew.PrimaryElegant color={Colors.DARK_TONE}>{item.title}</TextNew.PrimaryElegant>
      )}
      <RichTextBlock text={item.text} />
    </div>
  </div>
);

IconWithRichText.propTypes = {
  item: PropTypes.shape({
    link: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
  }),
};

export default withFunctionalClassName('molecule','IconWithRichText')(IconWithRichText);
