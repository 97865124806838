import PropTypes from 'prop-types';

/**
 * Reusable objects to use in `propTypes` for the group search components
 * @type {Object}
 * @category templating
 */

export const consultantShape = {
  name: PropTypes.string,
  location: PropTypes.string,
  biography: PropTypes.string,
  imageUrl: PropTypes.string,
  telephone: PropTypes.string,
};

export const groupSeoShape = {
  _type: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  metaTitle: PropTypes.string.isRequired,
};

export const latLongShape = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export const groupVenueShape = {
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  county: PropTypes.string,
  location: PropTypes.shape(latLongShape).isRequired,
  postcode: PropTypes.string.isRequired,
  region: PropTypes.string,
  town: PropTypes.string,
};

export const groupSessionShape = PropTypes.shape({
  day: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
}).isRequired;

export const groupShape = {
  distance: PropTypes.string,
  id: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  name: PropTypes.string,
  score: PropTypes.string,
  seo: PropTypes.shape(groupSeoShape),
  sessions: PropTypes.arrayOf(groupSessionShape).isRequired,
  // venue: PropTypes.shape(groupVenueShape).isRequired,
  consultant: PropTypes.shape(consultantShape),
};
