import { push as historyPush } from 'react-router-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { object, string, boolean } from 'yup';
import { createPath } from 'common/src/app/util/routeUtils';
import { withFormik } from 'formik';
import FormNames from 'common/src/app/data/enum/FormNames';
import Pages from 'common/src/app/data/enum/Pages';
import {
  userDetailFields,
  emailFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { sevenDayNewsletterSignUp } from 'common/src/app/actions/resources/publicActions';
import NewsLetterSignUpEnum from 'common/src/app/data/NewsLetterSignUpEnum';
import { FIRSTNAME_EMAIL_SIGNUP_REGEX } from 'common/src/app/data/regexPatterns';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { isEmailValid } from 'common/src/app/validation/formikValidation';
import { API_STATIC_PUBLIC_NEWSLETTER_SIGNUP } from 'common/src/app/data/apiStatics';
import sevenDayMenuFieldNames from '../../../data/enum/sevenDayMenuFieldNames';

import NewsletterSignUp, { MenuType } from './NewsletterSignUp';

const signUpSchema = object().shape({
  [userDetailFields.FIRST_NAME]: string()
    .trim()
    .required('errors.requiredField')
    .matches(FIRSTNAME_EMAIL_SIGNUP_REGEX, 'newsletterSignUp.errors.firstName.validator'),
  [sevenDayMenuFieldNames.RECAPTCHA]: boolean()
    .required('newsletterSignUp.errors.blankRecaptcha')
    .oneOf([true], 'newsletterSignUp.errors.blankRecaptcha'),
  [emailFields.EMAIL_ADDRESS]: string()
    .trim()
    .test(isEmailValid)
    .required('errors.requiredField'),
});

const mapStateToProps = state => ({
  assets: staticPageSelector(state, API_STATIC_PUBLIC_NEWSLETTER_SIGNUP)?.nestedContent,
});

const mapDispatchToProps = dispatch => ({
  signUpAction: values => dispatch(sevenDayNewsletterSignUp(values)),
  sendToTheSevenDayMenu: page => dispatch(historyPush(page)),
});

const EnhancedNewsletterSignUp = withFormik({
  displayName: FormNames.NEWSLETTER_SIGNUP,
  mapPropsToValues: props => ({
    [userDetailFields.FIRST_NAME]: '',
    [emailFields.EMAIL_ADDRESS]: '',
    [sevenDayMenuFieldNames.RECAPTCHA]: props.recaptcha || false,
    menuType: MenuType.CLASSIC,
  }),
  validationSchema: signUpSchema,
  handleSubmit: (values, { setSubmitting, props, setStatus, setErrors }) => {
    props.signUpAction(values).then(result => {
      const sevenDayMenuLink = () => {
        if (values.menuType === MenuType.CLASSIC) {
          return createPath(Pages.UK_PUBLIC_SEVEN_DAY_MENU, {
            menuType: 'meat',
          });
        }
        if (values.menuType === MenuType.VEGETARIAN) {
          return createPath(Pages.UK_PUBLIC_SEVEN_DAY_MENU, {
            menuType: 'vegetarian',
          });
        }
        return createPath(Pages.UK_PUBLIC_SEVEN_DAY_MENU, {
          menuType: 'vegan',
        });
      };

      switch (result?.data) {
        case NewsLetterSignUpEnum.FAILED_CAPTCHA:
          setSubmitting(false);
          setErrors({ id: 'newsletterSignUp.errors.invalidRecaptcha' });
          break;
        case NewsLetterSignUpEnum.ALREADY_SUBSCRIBED:
          setSubmitting(false);
          setErrors({
            id: 'newsletterSignUp.errors.alreadySubscribed',
            params: { MENU_LINK: sevenDayMenuLink },
          });
          break;
        case NewsLetterSignUpEnum.EXISTING_UNVERIFIED_SUBSCRIPTION:
          setSubmitting(false);
          setErrors({
            id: 'newsletterSignUp.errors.existingUnverifiedSubscription',
          });
          break;
        case NewsLetterSignUpEnum.NEW_VERIFICATION_EMAIL_SENT:
          setSubmitting(false);
          // we need to show them that they have been subscribed.
          // but not show them the link to the seven day menu yet
          // as for this we need to wait until they have confirmed their email
          setStatus({
            id: 'newsletterSignUp.success.newVerificationEmailSent',
            sent: true,
          });
          break;
        default:
          // We should always have a result returned
          // Add a catch all error message if we dont have a result returned
          setSubmitting(false);
          setErrors({
            id: 'newsletterSignUp.errors.generalErrorMessage',
          });
      }
    });
  },
})(NewsletterSignUp);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getStaticContent(API_STATIC_PUBLIC_NEWSLETTER_SIGNUP, true)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(addInitAction, connected)(EnhancedNewsletterSignUp);
