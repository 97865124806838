import { memo } from 'react';
import PropTypes from 'prop-types';

const LocaleMessage = ({ id, params }, { getMessage }) => getMessage(id, params) || null;

LocaleMessage.propTypes = {
  id: PropTypes.string.isRequired,
  params: PropTypes.object,
};

LocaleMessage.defaultProps = {
  params: {},
};

LocaleMessage.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default memo(LocaleMessage);
