import { connect } from 'react-redux';
import { registerDeepLinksHiddenPosition } from 'common/src/app/actions/components/deepLinkActions';
import { currentRoute } from 'common/src/app/util/routeCheckUtils';
import DeepLinkDisplayToggle from './DeepLinkDisplayToggle';

export default connect(
  state => ({
    currentRoute: currentRoute(state),
  }),
  dispatch => ({
    linkHiddenPosition: position => dispatch(registerDeepLinksHiddenPosition(position)),
  }),
)(DeepLinkDisplayToggle);
