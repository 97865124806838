import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';

import { API_COMPONENT_DIABETES_UK_LOGO } from 'common/src/app/data/apiComponents';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';

import DiabetesUkBanner from './DiabetesUkBanner';

const mapStateToProps = state => ({
  logo: contentComponentSelector(state, API_COMPONENT_DIABETES_UK_LOGO, 'image') || null,
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(API_COMPONENT_DIABETES_UK_LOGO)),
});

export default compose(addInitAction, connected)(DiabetesUkBanner);
