/* global 'molecule','InstagramImageModal' */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import SocialMedia from 'common/src/app/data/enum/SocialMedia';
import TextNew from '../../atoms/TextNew';
import CloseButton from '../CloseButton';
import Image from '../../atoms/Image';

import './instagram-image-modal.scss';

const InstagramImageModal = ({ closeModal, image, caption }, context, className) => {
  const captionSplit = caption.split(` ${SocialMedia.INSTAGRAM_HASH_TAG} `);
  const captionFormatted = [
    captionSplit[0],
    <Link key="instagram-link" target="_blank" href={PagesExternal.INSTAGRAM}>
      {` ${SocialMedia.INSTAGRAM_HASH_TAG}`}
    </Link>,
    captionSplit[1],
  ];

  return (
    <div className={className}>
      <div className="post-detail">
        <Image ratio={1} useWebp={false} src={image} />
        <CloseButton onClick={closeModal} />
        {caption && (
          <TextNew.Serif.XS>
            {captionFormatted.map((text, index) => (
              <Fragment key={index}>{text}</Fragment>
            ))}
          </TextNew.Serif.XS>
        )}
      </div>
    </div>
  );
};

InstagramImageModal.propTypes = {
  closeModal: PropTypes.func,
  caption: PropTypes.string,
  image: PropTypes.string,
};

export default withFunctionalClassName('molecule','InstagramImageModal')(InstagramImageModal);
