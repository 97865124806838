/* eslint-disable no-bitwise */
const FoodRangeFlags = {
  0: 'Vegetarian',
  1: 'Vegan',
  2: 'SpeedFood',
  3: 'GlutenFree',
  4: 'DairyFree',
  5: 'EggFree',
};

export const FoodRangeFlagsBitwise = {
  Vegetarian: 1,
  Vegan: 2,
  SpeedFood: 4,
  GlutenFree: 8,
  DairyFree: 16,
  EggFree: 32,
};

export const icelandfilterConfig = catogeriesFlags => [
  {
    fieldName: 'categories',
    legend: 'Range',
    flags: catogeriesFlags,
  },
  {
    fieldName: 'foodRangeDiet',
    legend: 'Dietary preferences',
    flags: FoodRangeFlagsBitwise,
  },
];

export default FoodRangeFlags;
