/* global 'organism','FeaturedPodcasts' */
import React from 'react';
import PropTypes from 'prop-types';
import featuredPodcastShape from 'common/src/app/util/proptypes/apiEntities/featuredPodcastShape';
import Episode from './components/Episode/Episode';

import './featured-podcasts.scss';

const FeaturedPodcasts = ({ podcasts }) => {
  const componentClass = 'organism-featured-podcasts';

  return (
    <div className={componentClass} data-testid="featured-podcasts">
      {podcasts.map((podcast, index) => (
        <Episode podcast={podcast} key={`featured-podcast-${index}`} />
      ))}
    </div>
  );
};

FeaturedPodcasts.propTypes = {
  podcasts: PropTypes.arrayOf(PropTypes.shape(featuredPodcastShape)).isRequired,
};

export default FeaturedPodcasts;
