import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import classNames from 'classnames';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import WebHost from 'common/src/app/data/enum/WebHost';
import prependWebHost from 'common/src/app/util/prependWebHost';

const WebHostLink = (
  {
    environmentConfig,
    children,
    link,
    webHost,
    newWindow,
    dispatch, // eslint-disable-line no-unused-vars
    innerRef,
    onClick,
    ...props
  },
  context,
  className,
  dataTestid,
) => {
  const path = prependWebHost(environmentConfig, link, webHost);
  const linkProps = {
    [path === link && (!newWindow || newWindow === 'self') ? 'to' : 'href']: path,
  };

  if (!link) {
    return (
      <button onClick={onClick} {...props} className={classNames(className, props.className)}>
        {children}
      </button>
    );
  }

  if (newWindow === 'blank') {
    linkProps.target = path;
  }

  return (
    <Link
      data-testid={dataTestid}
      innerRef={innerRef || ''}
      onClick={onClick}
      {...linkProps}
      {...props}
      className={classNames(className, props.className)}
    >
      {children}
    </Link>
  );
};

WebHostLink.propTypes = {
  environmentConfig: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  webHost: PropTypes.oneOf(Object.values(WebHost)),
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  newWindow: PropTypes.string,
  dispatch: PropTypes.func,
  className: PropTypes.string,
  innerRef: PropTypes.object,
  onClick: PropTypes.func,
};

export default withFunctionalClassName(ComponentType.ATOM, 'WebHostLink')(WebHostLink);
