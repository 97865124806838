import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { COMPONENT } from 'common/src/app/data/entityTypes';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { API_COMPONENT_STATIC_PAGE_BACKGROUND } from 'common/src/app/data/apiComponents';

import StaticContent from './StaticContent';

const mapStateToProps = (state, { location }) => {
  const path = location?.pathname.replace('/', '');

  return {
    page: staticPageSelector(state, path),
    publicHost: state.config.environmentConfig.web.public.host,
    background: state?.entities?.[COMPONENT]?.[API_COMPONENT_STATIC_PAGE_BACKGROUND]?.image,
  };
};

const connected = connect(mapStateToProps);

const addInitAction = withInitAction(
  ['location.pathname'],
  ({ location: { pathname } }, dispatch) =>
    Promise.all([
      dispatch(getStaticContent(pathname, true)),
      dispatch(getComponent(API_COMPONENT_STATIC_PAGE_BACKGROUND)),
    ]),
);

export default compose(addInitAction, connected)(StaticContent);
