import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';
import TextNew, { ElementTypes, FontSizes, Colors } from 'components/atoms/TextNew';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Button from 'components/atoms/Button';

import './iceland-advertisement.scss';

const IcelandAdvertisement = (
  { advertisement, offerGridLocation, isThirdGridColumn, isFourthGridColumn, isInGrid },
  context,
  className,
) => {
  const {
    [marketConfig.locale]: {
      title,
      subtitle,
      description,
      buttonText,
      buttonLink,
      image,
      recipe,
      accentColor,
    },
  } = advertisement;
  const hasOfferGridLocation = offerGridLocation || offerGridLocation === 0;

  return (
    <section
      className={classNames(
        className,
        { 'is-in-grid': isInGrid },
        { 'has-grid-location': hasOfferGridLocation },
        { 'is-third-grid-column': isThirdGridColumn },
        { 'is-fourth-grid-column': isFourthGridColumn },
        hasOfferGridLocation && `grid-${offerGridLocation}`,
        accentColor && `is-accent-color-${accentColor}`,
      )}
    >
      <BackgroundImageWrapper image={image || recipe?.image} />
      <div className="content">
        <header className="header">
          {title && (
            <TextNew.Script
              cid="title"
              element={ElementTypes.H2}
              color={Colors.PRIMARY_LIGHT}
              size={FontSizes.MD}
            >
              {title}
            </TextNew.Script>
          )}

          {(subtitle || recipe?.title) && (
            <TextNew.Confident
              cid="sub-title"
              element={ElementTypes.H3}
              color={Colors.PRIMARY_LIGHT}
            >
              {subtitle || recipe.title}
            </TextNew.Confident>
          )}
        </header>

        {(description || recipe?.introduction) && (
          <TextNew.PrimaryElegant cid="description" color={Colors.PRIMARY_LIGHT}>
            {description || recipe.introduction}
          </TextNew.PrimaryElegant>
        )}

        {(buttonLink || recipe?.link) && (
          <Button isGhostLight isLarge to={buttonLink || recipe.link}>
            {buttonText || <LocaleMessage id="icelandAdvertisement.buttonText" />}
          </Button>
        )}
      </div>
    </section>
  );
};

IcelandAdvertisement.propTypes = {
  advertisement: PropTypes.object,
  offerGridLocation: PropTypes.number,
  isThirdGridColumn: PropTypes.bool,
  isFourthGridColumn: PropTypes.bool,
  isInGrid: PropTypes.bool,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'IcelandAdvertisement',
)(IcelandAdvertisement);
