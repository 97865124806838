/**
 * stories map
 */
const StoriesType = {
  0: 'Health',
  1: 'Wedding',
  2: 'BecomingAParent',
  3: 'Vacation',
  4: 'GeneralWellBeing',
};

export default StoriesType;
