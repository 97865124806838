import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Pages from 'common/src/app/data/enum/Pages';
import IconName from 'common/src/app/data/enum/IconName';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import TextNew, { FontSizes, Colors, ElementTypes } from '../../atoms/TextNew';
import Image from '../../atoms/Image';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import LocaleMessage from '../../atoms/LocaleMessage';
import './iceland-promotion.scss';

const IcelandPromotion = ({ icelandBackground, deviceState }, context, className) => {
  const { smallMobileHeaderImage, desktopHeaderImage } = icelandBackground || {};
  return (
    <article className={className}>
      <Image
        src={deviceState > DeviceState.XL ? desktopHeaderImage?.src : smallMobileHeaderImage?.src}
        isBackgroundImage
      >
        <header className="content">
          <h2 className="free-food">
            <TextNew.Serif.XL
              size={deviceState > DeviceState.XS ? FontSizes.XL : FontSizes.LG}
              localeId="icelandPromotion.sw"
              color={Colors.PRIMARY_LIGHT}
              element={ElementTypes.SPAN}
            />
            <TextNew.Script.LG
              localeId="icelandPromotion.freeFood.title"
              element={ElementTypes.SPAN}
            />
            <Icon name={IconName.REGISTERED_TRADEMARK_SYMBOL} />
          </h2>
          <span className="screen-reader">
            <LocaleMessage id="icelandPromotion.freeFood.trademarkDisclaimer" />
          </span>
          <TextNew.Serif.XS
            localeId="icelandPromotion.description"
            color={Colors.PRIMARY_LIGHT}
            cid="description"
          />
        </header>
        <Button to={Pages.UK_PUBLIC_ICELAND}>
          <LocaleMessage id="icelandPromotion.cta" />
        </Button>
      </Image>
    </article>
  );
};

IcelandPromotion.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

IcelandPromotion.propTypes = {
  icelandBackground: PropTypes.object,
  deviceState: PropTypes.number,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'IcelandPromotion',
)(IcelandPromotion);
