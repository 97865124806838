import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { CSSTransition } from 'react-transition-group';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import classNames from 'classnames';
import Configuration from 'common/src/app/config/Configuration';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import LocationHash from 'common/src/app/data/enum/LocationHash';
import './off-canvas-menu.scss';

const OffCanvasMenu = (
  { topOffset, children, showMenu, deviceState, childRef },
  content,
  className,
) => {
  const isMenuFixed = deviceState > DeviceState.XXXL && Configuration.menuFixed;
  const customShowMenu = isMenuFixed ? true : showMenu;

  return (
    <CSSTransition timeout={250} in={customShowMenu} classNames="slide">
      <nav
        id={LocationHash.SUB_NAVIGATION}
        tabIndex="-1"
        ref={childRef}
        aria-label="Main"
        className={classNames(className, {
          active: customShowMenu,
          'menu-fixed': isMenuFixed,
        })}
        style={{ top: topOffset, height: `calc(100vh - ${topOffset}px)` }}
      >
        {typeof children === 'function' ? children(customShowMenu) : children}
      </nav>
    </CSSTransition>
  );
};

OffCanvasMenu.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

OffCanvasMenu.propTypes = {
  /**
   * Boolean indicating whether menu is visible
   */
  showMenu: PropTypes.bool,
  /**
   * List of links
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * topOffset can be used to stop overlap of the header
   */
  topOffset: PropTypes.number,
  deviceState: PropTypes.number,
  childRef: PropTypes.func,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'OffCanvasMenu')(OffCanvasMenu),
);
