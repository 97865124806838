import React from 'react';
import PropTypes from 'prop-types';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';

import FlippableContentTile from './components/FlippableContentTile';

import './flippable-content-tiles.scss';

const FlippableContentTiles = ({ tiles }) => {
  const className = 'organism-flippable-content-tiles-wrapper';

  return (
    <div className={className} data-testid={className}>
      {tiles?.map((tile, index) => (
        <FlippableContentTile tile={tile} key={`flip-tile-${index}`} />
      ))}
    </div>
  );
};

FlippableContentTiles.propTypes = {
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answerTitle: PropTypes.string,
      answerText: PropTypes.string,
      buttonLink: PropTypes.string,
      buttonText: PropTypes.string,
      tileImage: PropTypes.shape({
        ...imageShape,
      }),
    }),
  ).isRequired,
};

export const ComponentProps = FlippableContentTiles;

export default withDeviceState()(FlippableContentTiles);
