import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, { Colors, ElementTypes, FontWeight } from 'components/atoms/TextNew';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import LandingGrid from 'components/templates/LandingGrid';
import landingGridProps from 'components/templates/LandingGrid/landingGridProps';
import PublicHorizontalSearchBar from 'components/molecules/PublicHorizontalSearchBar';
import Wrapper from 'components/atoms/Wrapper';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import DataTestidOverides from 'common/src/app/data/enum/DataTestidOverides';
import errorBoundary from 'components/hoc/ErrorBoundary';
import recipeDetailShape from 'common/src/app/util/proptypes/apiEntities/recipeDetail';

import './recipe-landing.scss';

const RecipeLanding = (
  {
    location: {
      query: { query },
    },
    deviceState,
    gridContents,
    isInitializing,
    loadMore,
    headerAssets,
    highlightedRecipe,
    featuredRecipes,
  },
  context,
  className,
  dataTestid,
) => {
  // Select the correct header image
  const headerSelector = () => {
    if (!headerAssets) return null;
    const [mobile, largeMobile, tablet, desktop] = headerAssets;

    if (deviceState >= DeviceState.LG) {
      return desktop;
    } else if (deviceState > DeviceState.MD) {
      return tablet;
    } else if (deviceState > DeviceState.SM) {
      return largeMobile;
    }
    return mobile;
  };

  const hasSearched = !!query;

  return (
    <div className={className} data-testid={dataTestid}>
      <HeroBarPublic image={headerSelector()?.image}>
        <TextNew.Serif.MD
          localeId="recipeLanding.heading.h1"
          element={ElementTypes.H1}
          weight={FontWeight.BOLD}
          cid="hero-bar-public-serif-heading"
        />
        <TextNew.Script.SM
          localeId="recipeLanding.heading.h2"
          color={Colors.BRAND}
          element={ElementTypes.H2}
          cid="hero-bar-public-script-heading"
        />
        <TextNew.Serif.XS
          cid="hero-bar-public-introduction"
          localeId="recipeLanding.heading.introduction"
        />
      </HeroBarPublic>

      <Wrapper.MD dataTestid={DataTestidOverides.SEARCH_INTRO_TEXT} padding="sm">
        <PublicHorizontalSearchBar showTitle={false} isTile={false} />
      </Wrapper.MD>

      <LandingGrid
        {...gridContents}
        isInitializing={isInitializing}
        load={() => loadMore(featuredRecipes, highlightedRecipe)}
        featuredTile={(!hasSearched && highlightedRecipe) || undefined}
        hideSortControls={!hasSearched}
        featuredItems={!hasSearched ? featuredRecipes : []}
      />
    </div>
  );
};

RecipeLanding.propTypes = {
  isInitializing: PropTypes.bool,
  gridContents: landingGridProps,
  deviceState: PropTypes.number,
  loadMore: PropTypes.func,
  headerAssets: PropTypes.array,
  highlightedRecipe: PropTypes.shape(recipeDetailShape),
  featuredRecipes: PropTypes.arrayOf(PropTypes.shape(recipeDetailShape)),
  location: PropTypes.object,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName(ComponentType.PAGE, 'RecipeLanding'),
)(RecipeLanding);
