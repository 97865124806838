import compose from 'redux/lib/compose';
import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';
import ContactUsGetInTouch from './ContactUsGetInTouch';
import * as ContactUsForm from '../../organisms/ContactUsForm';

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(prepareComponent(ContactUsForm.Public)),
});

export default compose(addInitAction)(ContactUsGetInTouch);
