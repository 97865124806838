import combineReducersNamed from 'common/src/app/util/reducers/combineReducersNamed';
import successStoriesDetail from './successStoriesDetailReducer';
import recipeDetail from './recipeDetailReducer';
import foodRangeDetail from './foodRangeDetailReducer';
import groupDetail from './groupDetailReducer';
import staticContent from './staticContentReducer';

export default combineReducersNamed({
  successStoriesDetail,
  groupDetail,
  recipeDetail,
  foodRangeDetail,
  staticContent,
});
