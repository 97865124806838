import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { toggleUiFeatures } from 'common/src/app/actions/components/headerActions';
import NavigationController, { NavViews } from './NavigationController';

const mapDispatchToProps = dispatch => ({
  toggleUiFeatures: options => dispatch(toggleUiFeatures(options)),
});

const connected = connect(null, mapDispatchToProps);

export { NavViews };

export default compose(connected)(NavigationController);
