import React from 'react';
import PropTypes from 'prop-types';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import Image from 'components/atoms/Image';

import './diabetes-uk-banner.scss';

const DiabetesUkBanner = ({ logo }, context, className) => {
  if (!logo?.src) {
    return null;
  }

  return (
    <section className={className}>
      <Image
        src={logo.src}
        alt="Diabetes UK"
        data-testid="diabetes-uk-logo"
        ratio={146 / 39}
        isBackgroundImage
        useWebp={false}
      />
    </section>
  );
};

DiabetesUkBanner.propTypes = {
  logo: PropTypes.shape(imageShape).isRequired,
};

export default withFunctionalClassName(ComponentType.ATOM, 'DiabetesUkBanner')(DiabetesUkBanner);
