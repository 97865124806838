/* global 'atom' */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import debounce from 'lodash/debounce';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import CloseButton from '../../molecules/CloseButton';

import './accordion.scss';

/**
 * Shows a collapsible list of items
 */
class Accordion extends PureComponent {
  constructor(props) {
    super();

    this.state = {
      isCollapsed: props.isCollapsed || false,
      height: props.minHeight,
    };
  }

  componentDidMount() {
    this.setHeight();
    window.addEventListener('resize', this.setHeightOnResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeightOnResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isCollapsed !== this.props.isCollapsed) {
      this.toggleAccordion();
    }
  }

  handleClick = () => {
    this.props.customToggleFunction ? this.props.customToggleFunction() : this.toggleAccordion();
  };

  toggleAccordion = () => {
    this.setState(prevState => ({ isCollapsed: !prevState.isCollapsed }));
    requestAnimationFrame(() => this.setHeightOnResize());
  };

  setHeight = () => {
    if (this.wrapper) {
      this.setState({ height: this.wrapper.clientHeight });
    }
  };

  setHeightOnResize = debounce(() => {
    if (this.wrapper) {
      this.setHeight();
    }
  }, 200);

  render() {
    const {
      label,
      minHeight,
      children,
      closeButton,
      additionalClassName,
      reference,
      onClick,
      toggleChevron,
    } = this.props;

    const { isCollapsed, height } = this.state;

    const chevron = (
      <Icon
        name={IconName.CHEVRON}
        direction={isCollapsed ? DirectionType.DOWN : DirectionType.UP}
        width={24}
      />
    );

    return (
      <div
        {...componentClassNameProp('atom', this, { isCollapsed }, [additionalClassName])}
      >
        {label && (
          <button
            ref={reference}
            type="button"
            className="accordion-button"
            onClick={this.handleClick}
          >
            {typeof label === 'function' ? label(isCollapsed) : label}
            {toggleChevron && chevron}
          </button>
        )}

        <Motion
          defaultStyles={{ maxHeight: height }}
          style={{
            maxHeight: spring(isCollapsed ? minHeight : height, {
              precision: 75,
            }),
          }}
          onRest={onClick}
        >
          {interpolatingStyle => (
            <div
              data-testid="accordion-wrapper"
              className="accordion-wrapper"
              style={{ maxHeight: interpolatingStyle.maxHeight }}
            >
              <div
                ref={ref => {
                  this.wrapper = ref;
                }}
              >
                {closeButton && <CloseButton cid="close-button" onClick={this.handleClick} />}

                {children}
              </div>
            </div>
          )}
        </Motion>
      </div>
    );
  }
}

Accordion.defaultProps = {
  minHeight: 0,
};

Accordion.propTypes = {
  /**
   * The button content
   */
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Collapses the content by default
   */
  isCollapsed: PropTypes.bool,
  /**
   * Minimal content height to ensure a smooth transition
   * IMPORTANT: make sure to hide the content yourself using css when setting this property
   */
  minHeight: PropTypes.number,
  /**
   * Accordion content
   */
  children: PropTypes.node,
  /**
   * Boolean indicating whether or not the content should have a close button
   */
  closeButton: PropTypes.bool,
  /**
   * Additional className
   */
  additionalClassName: PropTypes.string,
  /**
   * Additional function to be exectuted on toggle
   */
  customToggleFunction: PropTypes.func,
  reference: PropTypes.func,
  toggleChevron: PropTypes.bool,
  /**
   * Custom onClick handler
   */
  onClick: PropTypes.func,
};

export default Accordion;
