import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import InteractionType from '../../data/enum/InteractionType';
import RelatedEntity from '../../data/enum/RelatedEntityKind';
import {
  bookmark,
  unbookmark,
  unlike,
  pin,
  unpin,
  favourite,
  unfavourite,
} from '../../actions/resources/interactionActions';
import { makeInteractionStateSelector } from '../../reducers/interactionsReducer';
import UnwrappedInteractionToggleWrapper from './InteractionToggleWrapper';

const addActions = {
  [InteractionType.BOOKMARK]: bookmark,
  [InteractionType.PIN]: pin,
  [InteractionType.FAVOURITE]: favourite,
};

const removeActions = {
  [InteractionType.BOOKMARK]: unbookmark,
  [InteractionType.LIKE]: unlike,
  [InteractionType.PIN]: unpin,
  [InteractionType.FAVOURITE]: unfavourite,
};

/**
 * IMPORTANT: interactions are now fetched within the application layer due to massive
 * performance issues doing the data fetching logic inside this component, if any service
 * is using this component they will need to make sure getUserInteractions() has been called.
 */
const connector = connect(
  () => {
    const interactionSelector = makeInteractionStateSelector();

    return (state, props) => ({
      interactionState: interactionSelector(state.interactions, props),
      initialized: state.interactions.loadedInitial,
    });
  },
  (dispatch, { targetId, targetType, interactionType, targetTitle }) => ({
    addInteraction: () => dispatch(addActions[interactionType](targetId, targetType, targetTitle)),
    removeInteraction: () =>
      dispatch(removeActions[interactionType](targetId, targetType, targetTitle)),
  }),
);

const InteractionToggleWrapper = compose(connector)(UnwrappedInteractionToggleWrapper);

InteractionToggleWrapper.propTypes = {
  interactionType: PropTypes.oneOf([
    InteractionType.BOOKMARK,
    InteractionType.LIKE,
    InteractionType.PIN,
    InteractionType.FAVOURITE,
  ]).isRequired,
  targetType: PropTypes.oneOf([
    RelatedEntity.POST,
    RelatedEntity.FEATURE,
    RelatedEntity.SUCCESS_STORY,
    RelatedEntity.RECIPE,
    RelatedEntity.COMMENT,
    RelatedEntity.PLANNER_ACTIVITY,
  ]),
  targetId: PropTypes.string.isRequired,
};

export default InteractionToggleWrapper;
