/* global 'atom','IndexedPin' */
import React from 'react';
import PropTypes from 'prop-types';
import IconName from 'common/src/app/data/enum/IconName';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Icon from '../Icon';
import TextNew from '../TextNew';

import './indexed-pin.scss';

const IndexedPin = ({ index }, context, className) => (
  <div style={{ transform: 'translate(-50%, -50%)' }}>
    <div className={className}>
      <Icon name={IconName.MAP_PIN} width={28} />
      <TextNew.FormalLight>{index}</TextNew.FormalLight>
    </div>
  </div>
);

IndexedPin.propTypes = {
  index: PropTypes.number,
};

export default withFunctionalClassName('atom','IndexedPin')(IndexedPin);
