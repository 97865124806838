/* global 'atom','SectionBlock' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

const SectionBlock = ({ children }, context, className) => (
  <section className={className}>{children}</section>
);

SectionBlock.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withFunctionalClassName('atom','SectionBlock')(SectionBlock);
