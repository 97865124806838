import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import TextNew, { Colors, FontWeight } from 'components/atoms/TextNew';
import KeyCode from 'common/src/app/data/enum/KeyCode';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import Image from 'components/atoms/Image';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import Button from 'components/atoms/Button';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';

import './flippable-content-tile.scss';

const componentClass = 'molecule-flippable-content-tile';

const FlippableContentTile = ({ tile }) => {
  const [isTileFlipped, setTileFlipped] = useState(false);
  const [isInFocus, setIsInFocus] = useState(false);

  const { question, answerTitle, answerText, tileImage, buttonLink, buttonText } = tile;
  const cardId = question?.toLowerCase().replace(/ /g, '-');
  const cardClasses = isTileFlipped ? 'card is-flipped' : 'card';

  const handleUserKeyPress = useCallback(
    event => {
      const { keyCode } = event;

      if (keyCode === KeyCode.SPACE) {
        event.preventDefault();
        setTileFlipped(!isTileFlipped);
      }
    },
    [isTileFlipped],
  );

  const handleBlur = () => {
    setIsInFocus(false);
  };

  return (
    <div
      className={componentClass}
      data-testid={`flippable-tile-outer-${cardId}`}
      id={`${componentClass}-${cardId}`}
    >
      <div
        className="flippable-content-wrapper"
        role="switch"
        aria-checked={isTileFlipped}
        aria-label={isTileFlipped ? answerText : question}
        tabIndex={0}
        onFocus={() => setIsInFocus(!isInFocus)}
        onBlur={handleBlur}
        data-testid={`flippable-tile-wrapper-${cardId}`}
        onClick={() => setTileFlipped(!isTileFlipped)}
        onKeyDown={handleUserKeyPress}
      >
        <div
          className={cardClasses}
          role="button"
          data-testid={`flippable-content-wrapper-${cardId}`}
          aria-label="flibppable-button"
        >
          <Image
            src={tileImage.src}
            localClassName="card-face card-face-front"
            isBackgroundImage
            dataTestid={`${cardId}-card-face-front`}
            ratio={292 / 349}
          >
            <div className="flip-circle">
              <Icon name={IconName.ARROW_FLIP} rounded={false} width={20} height={20} />
            </div>
          </Image>

          {isTileFlipped && (
            <div className="card-face card-face-back" data-testid={`${cardId}-card-face-back`}>
              <div className="content">
                <TextNew.Serif.XS color={Colors.PRIMARY_DARK} weight={FontWeight.BOLD}>
                  {answerTitle}
                </TextNew.Serif.XS>
                <RichTextBlock cid="flipped-tile-answer" text={answerText} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="question-or-button">
        <TextNew.Serif.XS
          weight={FontWeight.BOLDER}
          cid={isTileFlipped ? 'flip-tile-question flipped' : 'flip-tile-question'}
          id={`flip-tile-question-${cardId}`}
        >
          {question}
        </TextNew.Serif.XS>
        <Button
          to={buttonLink}
          cid={isTileFlipped ? 'flip-tile-button flipped' : 'flip-tile-button'}
        >
          <TextNew.Sans.XS color={Colors.PRIMARY_LIGHT}>{buttonText}</TextNew.Sans.XS>
        </Button>
      </div>
    </div>
  );
};

FlippableContentTile.propTypes = {
  tile: PropTypes.shape({
    question: PropTypes.string,
    answerTitle: PropTypes.string,
    answerText: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    tileImage: PropTypes.shape({
      ...imageShape,
    }),
  }).isRequired,
};

export const ComponentProps = FlippableContentTile;

export default FlippableContentTile;
