import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { scrollToElement } from 'common/src/app/actions/scrollActions';
import TooltipWrapper from './TooltipWrapper';

// This fallback height is used for the pattern-lib
const connected = connect(state => ({ headerHeight: state.view.components.header?.height }), {
  scrollToElement,
});

export default compose(connected)(TooltipWrapper);
