import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { scrollToElement } from 'common/src/app/actions/scrollActions';
import { currentRoute } from 'common/src/app/util/routeCheckUtils';
import DeepLinkHandler from './DeepLinkHandler';

const EMPTY_OBJECT = {};

const connected = connect(
  state => ({
    headerHeight: state.view.components.header?.height - 1,
    deepLinks: state.view.components.deepLinks?.[currentRoute(state)] || EMPTY_OBJECT,
    hideUntil: state.view.components.deepLinks?.hideUntil?.[currentRoute(state)],
    page: state.routing.locationBeforeTransitions.pathname,
  }),
  { scrollToElement },
);

export default compose(connected)(DeepLinkHandler);
