/* global 'atom','LabeledSelect' */
import React from 'react';
import PropTypes from 'prop-types';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import StyleType from 'common/src/app/data/enum/StyleType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextTypes from 'common/src/app/data/enum/TextTypes';
import Colors from 'common/src/app/data/enum/Colors';
import Label from '../Label';
import Select from '../Select';
import './labeled-select.scss';

const LabeledSelect = (
  { text, selectOptions, name, styleType, changeHandler },
  context,
  className,
) => (
  <div className={className}>
    <Label type={TextTypes.FORMAL_ORIGINAL} color={Colors.MID_TONE} text={text} htmlFor={name} />

    <EnhancedField
      visibleLabel
      component={Select}
      name={name}
      options={selectOptions}
      styleType={styleType}
      id={name}
      onChange={changeHandler}
    />
  </div>
);

LabeledSelect.defaultProps = {
  isHidden: false,
  styleType: StyleType.SECONDARY,
};

LabeledSelect.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  isHidden: PropTypes.bool,
  /**
   * Name/id of select
   */
  name: PropTypes.string.isRequired,
  /**
   * Sort description
   */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /**
   * Select options
   */
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,

  styleType: PropTypes.oneOf([StyleType.PRIMARY, StyleType.SECONDARY]).isRequired,
  /**
   * input changed
   */
  changeHandler: PropTypes.func,
};

export default withFunctionalClassName('atom','LabeledSelect', ['isHidden'])(LabeledSelect);
