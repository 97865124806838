import compose from 'redux/lib/compose';
import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';

// Child components that need to be prepared
import GroupNotFound from './components/molecules/GroupNotFound';
import RecipeNotFound from './components/molecules/RecipeNotFound';
import RealLifeStoryNotFound from './components/molecules/RealLifeStoryNotFound';

import SmartNotFound from './SmartNotFound';

const addInitAction = withInitAction(['params'], {
  prepared: ({ params: { slug } }, dispatch) =>
    Promise.all([
      dispatch(prepareComponent(GroupNotFound)),
      dispatch(prepareComponent(RecipeNotFound, { slug })),
      dispatch(prepareComponent(RealLifeStoryNotFound)),
    ]),
});

export default compose(addInitAction)(SmartNotFound);
