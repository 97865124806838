import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import {
  GET_STATIC_CONTENT,
  getStaticContent,
} from 'common/src/app/actions/resources/staticContentActions';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { API_STATIC_PUBLIC_CONSULTANT_ENQUIRY } from 'common/src/app/data/apiStatics';
import { API_CONSULTANT_CAREERS_PAGE_BACKGROUND } from 'common/src/app/data/apiComponents';
import {
  contentComponentSelector,
  staticPageSelector,
} from 'common/src/app/selectors/publicSelectors';
import ConsultantEnquiry from './ConsultantEnquiry';

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT);

const mapStateToProps = state => ({
  loading: pageLoading(state),
  pageData: staticPageSelector(state, API_STATIC_PUBLIC_CONSULTANT_ENQUIRY),
  pageBackgroundImage: contentComponentSelector(
    state,
    API_CONSULTANT_CAREERS_PAGE_BACKGROUND,
    'image',
  ),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  clientOnly: ({}, dispatch) =>
    dispatch(getStaticContent(API_STATIC_PUBLIC_CONSULTANT_ENQUIRY, true)),
  prepared: ({}, dispatch) => dispatch(getComponent(API_CONSULTANT_CAREERS_PAGE_BACKGROUND)),
});

export default compose(addInitAction, connected)(ConsultantEnquiry);
