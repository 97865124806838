import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GroupSearchFieldNames from 'common/src/app/data/enum/FieldNames/GroupSearchFieldNames';
import EnhancedFormField from 'common/src/app/formik/components/EnhancedFormField';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import InputType from 'common/src/app/data/enum/InputType';
import IconName from 'common/src/app/data/enum/IconName';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { Colors, ElementTypes } from '../../atoms/TextNew';
import Icon from '../../atoms/Icon';
import { descriptorTypes } from '../../atoms/Input';
import SelectNew from '../../atoms/SelectNew';
import FormikError from '../../atoms/FormikError';
import Button from '../../atoms/Button';

/**
 * Drop down nearest group search
 */
const DropDownGroupSearch = (
  {
    handleChange,
    handleSubmit,
    isEmptySearchQuery,
    countyOptions,
    getCounties,
    deviceState,
    showSubmit,
    localeId,
    isSubmitting,
  },
  { getMessage },
  className,
  dataTestid,
) => {
  useEffect(() => {
    getCounties && !countyOptions && getCounties();
  }, []);

  const countyOptionsOrEmpty =
    countyOptions && countyOptions.length > 0
      ? countyOptions.map(county => ({
          title: county,
          value: county,
        }))
      : [];

  return (
    <form className={className}>
      <div className="dropdown-input">
        <EnhancedFormField
          type={InputType.TEXT}
          name={GroupSearchFieldNames.COUNTY}
          component={SelectNew}
          dataTestid={`nearest-group-search-bar-${
            isEmptySearchQuery ? 'isEmptySearchQuery' : 'default'
          }`}
          options={countyOptionsOrEmpty}
          descriptorType={descriptorTypes.GHOST}
          placeholder={getMessage('nearestGroupSearchBar.searchPlaceholder')}
          onChange={e => {
            handleChange(e);
            handleSubmit();
          }}
        />
        {showSubmit && (
          <Button type={InputType.SUBMIT} cid="find-group-button-dropdown" isLoading={isSubmitting}>
            {deviceState <= DeviceState.SM ? (
              <Icon name={IconName.MAGNIFY_GLASS} />
            ) : (
              <TextNew.Sans.SM
                color={Colors.PRIMARY_LIGHT}
                element={ElementTypes.SPAN}
                localeId={`${localeId}.button`}
              />
            )}
          </Button>
        )}
      </div>
      <FormikError dataTestid={`${dataTestid}-error`} name={GroupSearchFieldNames.COUNTY} />
    </form>
  );
};

DropDownGroupSearch.defaultProps = {
  latlong: { lat: 53.090528, lng: -1.350232 },
  isEmptySearchQuery: false,
  showSubmit: false,
  localeId: 'nearestGroupSearchBar',
};

DropDownGroupSearch.propTypes = {
  showSubmit: PropTypes.bool,
  localeId: PropTypes.string,
  deviceState: PropTypes.number,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool.isRequired,
  isEmptySearchQuery: PropTypes.bool,
  countyOptions: PropTypes.arrayOf(PropTypes.string),
  getCounties: PropTypes.func,
};

DropDownGroupSearch.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'DropDownGroupSearch',
)(DropDownGroupSearch);
