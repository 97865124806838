/* global 'organism','GroupSessionExplained' */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, {
  Colors,
  ElementTypes,
  FontSizes,
  FontWeight,
  AccentColors,
} from 'components/atoms/TextNew';
import Row from 'components/atoms/Row';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import Button from 'components/atoms/Button';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import classNames from 'classnames';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';

import './group-session-explained.scss';

/**
 * This component is also used as a modal
 */

const GroupSessionExplained = (
  { displayModalLaunchButton, openModal, deviceState },
  context,
  className,
) => {
  const steps = [IconName.GROUP_SUPPORT, IconName.SIGN_UP, IconName.CLOCK];

  const sansSize =
    deviceState === DeviceState.MD || deviceState === DeviceState.LG ? FontSizes.XS : FontSizes.SM;
  const iconSize = deviceState < DeviceState.MD ? 48 : 32;
  const scriptSize =
    deviceState === DeviceState.MD || deviceState === DeviceState.LG ? FontSizes.SM : FontSizes.MD;

  const title = (
    <h2 className="title">
      <TextNew.Script
        localeId="groupSessionExplained.titleBegin"
        element={ElementTypes.SPAN}
        color={Colors.PRIMARY_LIGHT}
        size={scriptSize}
      />
      <TextNew.Script
        localeId="groupSessionExplained.titleEnd"
        element={ElementTypes.SPAN}
        color={Colors.PRIMARY_LIGHT}
        size={scriptSize}
      />
    </h2>
  );

  const openGroupExplainedModal = () =>
    openModal(ModalNames.PUBLIC_GROUP_EXPLAINED_MODAL, {}, true, false, true);

  return (
    <div
      className={classNames(className, displayModalLaunchButton && 'display-modal-launch-button')}
    >
      {title}
      {displayModalLaunchButton ? (
        <Button isOnBackgroundColor onClick={() => openGroupExplainedModal()}>
          <TextNew.Sans
            element={ElementTypes.SPAN}
            size={sansSize}
            color={AccentColors.ACCENT_BLUE}
            localeId="groupSessionExplained.modalLaunch"
          />
        </Button>
      ) : (
        <Fragment>
          <TextNew.Sans
            size={sansSize}
            localeId="groupSessionExplained.intro"
            color={Colors.PRIMARY_LIGHT}
            cid="intro"
          />
          {steps.map((step, index) => (
            <Row key={step}>
              <Icon name={step} width={iconSize} />
              <TextNew.Sans
                size={sansSize}
                localeId={`groupSessionExplained.part${index}`}
                color={Colors.PRIMARY_LIGHT}
                weight={FontWeight.LIGHT}
              />
            </Row>
          ))}
        </Fragment>
      )}
    </div>
  );
};

GroupSessionExplained.propTypes = {
  displayModalLaunchButton: PropTypes.bool,
  openModal: PropTypes.func,
  deviceState: PropTypes.number,
};

export default withDeviceState()(withFunctionalClassName('organism','GroupSessionExplained')(GroupSessionExplained));
