export default (assets, imageBreakpointMap) => {
  const { alt, caption, ...rest } = assets || {};
  const imageNames = Object.keys(rest);
  let images = [];

  imageNames.forEach(name => {
    if (!rest[name].src) return;

    const src = rest[name].src;
    const selectedMapping = imageBreakpointMap.find(item => item.name === name);
    const breakpoint = selectedMapping?.breakpoint;

    images = images.concat({ name, src, breakpoint });
  });

  return { alt, caption, images };
};
