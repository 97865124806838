/**
 * Camelcased by filterGenerator to match a locale key
 */
const WeightLossType = {
  0: 'UnderOneStoneHalf',
  1: 'OneHalfThreeHalf',
  2: 'ThreeHalfFiveHalf',
  3: 'FiveHalfSevenHalf',
  4: 'OverSevenHalfStone',
};

export default WeightLossType;
