import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { currentRoute } from 'common/src/app/util/routeCheckUtils';
import SocialShare from './SocialShare';

const EMPTY_OBJECT = {};

const mapStateToProps = state => {
  const route = currentRoute(state);

  return {
    pageTitle: state.seo[route]?.metaTitle || EMPTY_OBJECT,
    pageDescription: state.seo[route]?.metaDescription || EMPTY_OBJECT,
  };
};

const connected = connect(mapStateToProps);

export default compose(connected)(SocialShare);
