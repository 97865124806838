import { Component } from 'react';
import PropTypes from 'prop-types';

const EventListenerTypes = {
  SCROLL: 'scroll',
  LOAD: 'load',
  RESIZE: 'resize',
  CLICK: 'click',
};

class DomEventObserver extends Component {
  componentDidMount() {
    const { handler, event, observe } = this.props;

    if (observe && handler && event) {
      window.addEventListener(event, handler);
    }
  }

  componentWillUnmount() {
    const { handler, event } = this.props;

    if (handler && event) {
      window.removeEventListener(event, handler);
    }
  }

  render() {
    return this.props.children;
  }
}

DomEventObserver.propTypes = {
  event: PropTypes.oneOf(Object.values(EventListenerTypes)),
  handler: PropTypes.func,
  children: PropTypes.node,
  observe: PropTypes.bool,
};

export { DomEventObserver as default, EventListenerTypes };
