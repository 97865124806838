import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Wrapper from 'components/atoms/Wrapper';
import BlockContent from 'components/molecules/BlockContent';
import Loader from 'components/atoms/Loader';
import SuccessStoryDetailHead from 'components/organisms/SuccessStoryDetailHead';
import successStories from 'common/src/app/util/proptypes/apiEntities/successStories';
import errorBoundary from 'components/hoc/ErrorBoundary';
import RelatedSuccessStories from '../../molecules/RelatedSuccessStories';
import './success-stories-detail.scss';

/**
 *SuccessStoriesDetail
 */
const SuccessStoriesDetail = ({ successStory, isInitializing }, context, className) => {
  if (isInitializing) {
    return (
      <div className={className}>
        <Loader />
      </div>
    );
  }

  return (
    successStory && (
      <div className={className}>
        {isInitializing ? (
          <Loader />
        ) : (
          <SuccessStoryDetailHead isUkPublic successStory={successStory} />
        )}
        <div className="background">
          <Wrapper width="ls" padding="md">
            <BlockContent addSectionPaddingTop data={successStory.nestedContent} noWrapper />
          </Wrapper>
        </div>

        <RelatedSuccessStories successStories={successStory?.related} />
      </div>
    )
  );
};

SuccessStoriesDetail.propTypes = {
  successStory: PropTypes.shape(successStories),
  isInitializing: PropTypes.bool,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.PAGE, 'SuccessStoriesDetail'),
)(SuccessStoriesDetail);
