import React from 'react';
import PropTypes from 'prop-types';
import IconName from 'common/src/app/data/enum/IconName';
import TextNew from '../../atoms/TextNew';
import IconButton from '../IconButton';
import LocaleMessage from '../../atoms/LocaleMessage';

const BookmarkButtonInteraction = ({ toggleActive, isActive, hasLabel, buttonId, ...props }) => (
  <IconButton
    icon={IconName.BOOKMARK}
    width={10}
    isActive={isActive}
    onClick={toggleActive}
    id={buttonId}
    buttonLabel="Bookmark"
    {...props}
  >
    {hasLabel ? (
      <TextNew localeId={isActive ? 'general.cta.bookmarked' : 'general.cta.bookmark'} />
    ) : (
      <div className="screen-reader">
        <LocaleMessage id={`bookmarkButtonInteraction.${isActive ? 'unbookmark' : 'bookmark'}`} />
      </div>
    )}
  </IconButton>
);

BookmarkButtonInteraction.defaultProps = {
  buttonId: 'bookmarkButton',
};

BookmarkButtonInteraction.propTypes = {
  buttonId: PropTypes.string,
  toggleActive: PropTypes.func,
  isActive: PropTypes.bool,
  hasLabel: PropTypes.bool,
};

export default BookmarkButtonInteraction;
