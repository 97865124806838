/* global 'template','IcelandLanding' */
import React from 'react';
import PropTypes from 'prop-types';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Loader from 'components/atoms/Loader';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import NewsletterSignUp from '../../../../../../components/molecules/NewsletterSignUp';
import IcelandHeader from '../../molecules/IcelandHeader';
import IcelandCategoryCarousel from '../../organisms/IcelandCategoryCarousel';
import IcelandRecipeInspiration from '../../organisms/IcelandRecipeInspiration';
import IcelandOurProductsPromo from '../../molecules/IcelandOurProductsPromo';
import IcelandOurStoryPromo from '../../molecules/IcelandOurStoryPromo';
import IcelandAdvertisement from '../../molecules/IcelandAdvertisement';

import './iceland-landing.scss';

const IcelandLanding = ({ pageData, deviceState }, context, className) => (
  <div className={className}>
    {pageData ? (
      <>
        <HeroBarPublic
          image={
            deviceState <= DeviceState.MD && pageData.imageSmall
              ? pageData.imageSmall
              : pageData.image
          }
        >
          <IcelandHeader accentColor={pageData.headerAccentColor} />
        </HeroBarPublic>

        <IcelandCategoryCarousel
          categories={pageData.categories}
          title={pageData.carouselTitle}
          intro={pageData.carouselMessageAbove}
          textBlockBottom={pageData.carouselMessageBelow}
          accentColor={pageData.carouselTitleAccentColor}
        />

        <IcelandOurStoryPromo pageData={pageData} />

        {pageData?.offer && <IcelandAdvertisement advertisement={pageData.offer} />}

        <IcelandOurProductsPromo pageData={pageData} />

        {pageData?.recipeInspirations && pageData?.recipeInspirations?.length > 0 && (
          <IcelandRecipeInspiration recipeInspirations={pageData.recipeInspirations} />
        )}

        <NewsletterSignUp />
      </>
    ) : (
      <Loader />
    )}
  </div>
);

IcelandLanding.propTypes = {
  pageData: PropTypes.object,
  deviceState: PropTypes.number,
};

export default withDeviceState()(withFunctionalClassName('template','IcelandLanding')(IcelandLanding));
