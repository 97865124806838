import { connect } from 'react-redux';
import { COMPONENT } from 'common/src/app/data/entityTypes';
import compose from 'redux/lib/compose';
import { API_COMPONENT_STATIC_PAGE_BACKGROUND } from 'common/src/app/data/apiComponents';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import ConfirmedNewsletterSignupEmailDeprecated from './ConfirmedNewsletterSignupEmailDeprecated';

const mapStateToProps = state => ({
  background: state?.entities?.[COMPONENT]?.[API_COMPONENT_STATIC_PAGE_BACKGROUND]?.image,
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(API_COMPONENT_STATIC_PAGE_BACKGROUND)),
});

export default compose(addInitAction, connected)(ConfirmedNewsletterSignupEmailDeprecated);
