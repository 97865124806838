import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ListType from 'common/src/app/data/enum/ListType';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, { ElementTypes, TextTypes } from 'components/atoms/TextNew';
import RichText from '../../../../../atoms/RichText';

import './list-block.scss';

const ListBlock = ({ listType, listItems, title }, context, className, dataTestid) => {
  const List = listType === ListType.UL ? 'ul' : 'ol';

  return (
    <div className={className} data-testid={dataTestid}>
      {title && (
        <TextNew.Confident cid="title" element={ElementTypes.H3} dataTestid="list-title">
          {title}
        </TextNew.Confident>
      )}

      <List className="list-content">
        {listItems?.length > 0 &&
          listItems.map((item, index) => (
            <li key={`list-${index}`}>
              <RichText
                type={TextTypes.PRIMARY_ELEGANT}
                text={item.listItem}
                dataTestid="list-item"
              />
            </li>
          ))}
      </List>
    </div>
  );
};

ListBlock.propTypes = {
  // OL or UL
  listType: PropTypes.oneOf(Object.values(ListType)).isRequired,
  // The list items
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      listItem: PropTypes.string.isRequired,
      _type: PropTypes.string.isRequired,
    }),
  ),
  // Title to (optionally) display above the list.
  title: PropTypes.string,
};

export default withFunctionalClassName(ComponentType.ATOM, 'ListBlock')(ListBlock);
