import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import WhatHappensInGroup from 'bundle-loader?lazy&reactHot&name=WhatHappensInGroup!./index';

const routes = (
  <Route
    title="Weight Loss Support | Join a Slimming World Group"
    description="You’ll find unbeatable support in a Slimming World group. Come and join a welcoming community for motivation and help at our weight loss support groups today!"
    path={Pages.UK_PUBLIC_WHAT_HAPPENS_IN_GROUP}
    {...getRouteComponentProp(WhatHappensInGroup)}
  />
);

export default routes;
