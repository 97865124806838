export const POST_OVERVIEW = 'postOverview';

export const FOLLOWING_POSTS = 'followingPosts';

export const COMMUNITY_GROUP_POSTS = 'communityGroupPosts';

export const FEATURES_LANDING = 'featuresLanding';

export const SUCCESS_STORIES_LANDING = 'successStoriesLanding';

export const RECIPES_LANDING = 'recipesLanding';

export const SEARCH_RESULTS = 'searchResults';

export const PROMOTED_SEARCH_RESULTS = 'promotedSearchResults';

export const WEIGH_IN_HISTORY = 'weighInHistory';

export const PLANNER_RECIPES = 'plannerRecipes';

export const PLANNER_FOODS = 'plannerFoods';

export const PLANNER_CUSTOM_FOODS = 'plannerCustomFoods';

export const HEALTHY_EXTRAS = 'healthyExtras';

export const PURCHASE_RESULTS = 'purchaseResults';

export const MENU_PURCHASE_RESULT = 'menuPurchaseResult';

export const RETURN_RESULTS = 'returnResults';

export const PLANNER_ACTIVITIES = 'plannerActivities';

export const PLANNER_ACTIVITY_TYPES = 'plannerActivityTypes';

export const MEMBER_POSTS = 'memberPosts';
