const countries = [
  { title: 'United Kingdom', value: 'GB', requiresTimezone: false },
  { title: 'Ireland', value: 'IE', requiresTimezone: false },
  { title: 'Cyprus', value: 'CY', requiresTimezone: false },
  { title: '--------------------', value: '--', requiresTimezone: false, disabled: true },
  { title: 'Afghanistan', value: 'AF', requiresTimezone: false },
  { title: 'Åland Islands', value: 'AX', requiresTimezone: false },
  { title: 'Albania', value: 'AL', requiresTimezone: false },
  { title: 'Algeria', value: 'DZ', requiresTimezone: false },
  { title: 'American Samoa', value: 'AS', requiresTimezone: false },
  { title: 'Andorra', value: 'AD', requiresTimezone: false },
  { title: 'Angola', value: 'AO', requiresTimezone: false },
  { title: 'Anguilla', value: 'AI', requiresTimezone: false },
  { title: 'Antarctica', value: 'AQ', requiresTimezone: false },
  { title: 'Antigua and Barbuda', value: 'AG', requiresTimezone: false },
  { title: 'Argentina', value: 'AR', requiresTimezone: false },
  { title: 'Armenia', value: 'AM', requiresTimezone: false },
  { title: 'Aruba', value: 'AW', requiresTimezone: false },
  { title: 'Australia', value: 'AU', requiresTimezone: false },
  { title: 'Austria', value: 'AT', requiresTimezone: false },
  { title: 'Azerbaijan', value: 'AZ', requiresTimezone: false },
  { title: 'Bahamas', value: 'BS', requiresTimezone: false },
  { title: 'Bahrain', value: 'BH', requiresTimezone: false },
  { title: 'Bangladesh', value: 'BD', requiresTimezone: false },
  { title: 'Barbados', value: 'BB', requiresTimezone: false },
  { title: 'Belarus', value: 'BY', requiresTimezone: false },
  { title: 'Belgium', value: 'BE', requiresTimezone: false },
  { title: 'Belize', value: 'BZ', requiresTimezone: false },
  { title: 'Benin', value: 'BJ', requiresTimezone: false },
  { title: 'Bermuda', value: 'BM', requiresTimezone: false },
  { title: 'Bhutan', value: 'BT', requiresTimezone: false },
  { title: 'Bolivia', value: 'BO', requiresTimezone: false },
  { title: 'Bosnia and Herzegovina', value: 'BA', requiresTimezone: false },
  { title: 'Botswana', value: 'BW', requiresTimezone: false },
  { title: 'Bouvet Island', value: 'BV', requiresTimezone: false },
  { title: 'Brazil', value: 'BR', requiresTimezone: false },
  { title: 'British Indian Ocean Territory', value: 'IO', requiresTimezone: false },
  { title: 'Brunei Darussalam', value: 'BN', requiresTimezone: false },
  { title: 'Bulgaria', value: 'BG', requiresTimezone: false },
  { title: 'Burkina Faso', value: 'BF', requiresTimezone: false },
  { title: 'Burundi', value: 'BI', requiresTimezone: false },
  { title: 'Cambodia', value: 'KH', requiresTimezone: false },
  { title: 'Cameroon', value: 'CM', requiresTimezone: false },
  { title: 'Canada', value: 'CA', requiresTimezone: false },
  { title: 'Cape Verde', value: 'CV', requiresTimezone: false },
  { title: 'Cayman Islands', value: 'KY', requiresTimezone: false },
  { title: 'Central African Republic', value: 'CF', requiresTimezone: false },
  { title: 'Chad', value: 'TD', requiresTimezone: false },
  { title: 'Chile', value: 'CL', requiresTimezone: false },
  { title: 'China', value: 'CN', requiresTimezone: false },
  { title: 'Christmas Island', value: 'CX', requiresTimezone: false },
  { title: 'Cocos (Keeling) Islands', value: 'CC', requiresTimezone: false },
  { title: 'Colombia', value: 'CO', requiresTimezone: false },
  { title: 'Comoros', value: 'KM', requiresTimezone: false },
  { title: 'Congo', value: 'CG', requiresTimezone: false },
  { title: 'Congo, The Democratic Republic of the', value: 'CD', requiresTimezone: false },
  { title: 'Cook Islands', value: 'CK', requiresTimezone: false },
  { title: 'Costa Rica', value: 'CR', requiresTimezone: false },
  { title: "Cote D'Ivoire", value: 'CI', requiresTimezone: false },
  { title: 'Croatia', value: 'HR', requiresTimezone: false },
  { title: 'Cuba', value: 'CU', requiresTimezone: false },
  { title: 'Czech Republic', value: 'CZ', requiresTimezone: false },
  { title: 'Denmark', value: 'DK', requiresTimezone: false },
  { title: 'Djibouti', value: 'DJ', requiresTimezone: false },
  { title: 'Dominica', value: 'DM', requiresTimezone: false },
  { title: 'Dominican Republic', value: 'DO', requiresTimezone: false },
  { title: 'Ecuador', value: 'EC', requiresTimezone: false },
  { title: 'Egypt', value: 'EG', requiresTimezone: false },
  { title: 'El Salvador', value: 'SV', requiresTimezone: false },
  { title: 'Equatorial Guinea', value: 'GQ', requiresTimezone: false },
  { title: 'Eritrea', value: 'ER', requiresTimezone: false },
  { title: 'Estonia', value: 'EE', requiresTimezone: false },
  { title: 'Ethiopia', value: 'ET', requiresTimezone: false },
  { title: 'Falkland Islands (Malvinas)', value: 'FK', requiresTimezone: false },
  { title: 'Faroe Islands', value: 'FO', requiresTimezone: false },
  { title: 'Fiji', value: 'FJ', requiresTimezone: false },
  { title: 'Finland', value: 'FI', requiresTimezone: false },
  { title: 'France', value: 'FR', requiresTimezone: false },
  { title: 'French Guiana', value: 'GF', requiresTimezone: false },
  { title: 'French Polynesia', value: 'PF', requiresTimezone: false },
  { title: 'French Southern Territories', value: 'TF', requiresTimezone: false },
  { title: 'Gabon', value: 'GA', requiresTimezone: false },
  { title: 'Gambia', value: 'GM', requiresTimezone: false },
  { title: 'Georgia', value: 'GE', requiresTimezone: false },
  { title: 'Germany', value: 'DE', requiresTimezone: false },
  { title: 'Ghana', value: 'GH', requiresTimezone: false },
  { title: 'Gibraltar', value: 'GI', requiresTimezone: false },
  { title: 'Greece', value: 'GR', requiresTimezone: false },
  { title: 'Greenland', value: 'GL', requiresTimezone: false },
  { title: 'Grenada', value: 'GD', requiresTimezone: false },
  { title: 'Guadeloupe', value: 'GP', requiresTimezone: false },
  { title: 'Guam', value: 'GU', requiresTimezone: false },
  { title: 'Guatemala', value: 'GT', requiresTimezone: false },
  { title: 'Guernsey', value: 'GG', requiresTimezone: false },
  { title: 'Guinea', value: 'GN', requiresTimezone: false },
  { title: 'Guinea-Bissau', value: 'GW', requiresTimezone: false },
  { title: 'Guyana', value: 'GY', requiresTimezone: false },
  { title: 'Haiti', value: 'HT', requiresTimezone: false },
  { title: 'Heard Island and Mcdonald Islands', value: 'HM', requiresTimezone: false },
  { title: 'Holy See (Vatican City State)', value: 'VA', requiresTimezone: false },
  { title: 'Honduras', value: 'HN', requiresTimezone: false },
  { title: 'Hong Kong', value: 'HK', requiresTimezone: false },
  { title: 'Hungary', value: 'HU', requiresTimezone: false },
  { title: 'Iceland', value: 'IS', requiresTimezone: false },
  { title: 'India', value: 'IN', requiresTimezone: false },
  { title: 'Indonesia', value: 'ID', requiresTimezone: false },
  { title: 'Iran, Islamic Republic Of', value: 'IR', requiresTimezone: false },
  { title: 'Iraq', value: 'IQ', requiresTimezone: false },
  { title: 'Isle of Man', value: 'IM', requiresTimezone: false },
  { title: 'Israel', value: 'IL', requiresTimezone: false },
  { title: 'Italy', value: 'IT', requiresTimezone: false },
  { title: 'Jamaica', value: 'JM', requiresTimezone: false },
  { title: 'Japan', value: 'JP', requiresTimezone: false },
  { title: 'Jersey', value: 'JE', requiresTimezone: false },
  { title: 'Jordan', value: 'JO', requiresTimezone: false },
  { title: 'Kazakhstan', value: 'KZ', requiresTimezone: false },
  { title: 'Kenya', value: 'KE', requiresTimezone: false },
  { title: 'Kiribati', value: 'KI', requiresTimezone: false },
  { title: "Democratic People's Republic of Korea", value: 'KP', requiresTimezone: false },
  { title: 'Korea, Republic of', value: 'KR', requiresTimezone: false },
  { title: 'Kosovo', value: 'XK', requiresTimezone: false },
  { title: 'Kuwait', value: 'KW', requiresTimezone: false },
  { title: 'Kyrgyzstan', value: 'KG', requiresTimezone: false },
  { title: "Lao People's Democratic Republic", value: 'LA', requiresTimezone: false },
  { title: 'Latvia', value: 'LV', requiresTimezone: false },
  { title: 'Lebanon', value: 'LB', requiresTimezone: false },
  { title: 'Lesotho', value: 'LS', requiresTimezone: false },
  { title: 'Liberia', value: 'LR', requiresTimezone: false },
  { title: 'Libyan Arab Jamahiriya', value: 'LY', requiresTimezone: false },
  { title: 'Liechtenstein', value: 'LI', requiresTimezone: false },
  { title: 'Lithuania', value: 'LT', requiresTimezone: false },
  { title: 'Luxembourg', value: 'LU', requiresTimezone: false },
  { title: 'Macao', value: 'MO', requiresTimezone: false },
  { title: 'Macedonia, The Former Yugoslav Republic of', value: 'MK', requiresTimezone: false },
  { title: 'Madagascar', value: 'MG', requiresTimezone: false },
  { title: 'Malawi', value: 'MW', requiresTimezone: false },
  { title: 'Malaysia', value: 'MY', requiresTimezone: false },
  { title: 'Maldives', value: 'MV', requiresTimezone: false },
  { title: 'Mali', value: 'ML', requiresTimezone: false },
  { title: 'Malta', value: 'MT', requiresTimezone: false },
  { title: 'Marshall Islands', value: 'MH', requiresTimezone: false },
  { title: 'Martinique', value: 'MQ', requiresTimezone: false },
  { title: 'Mauritania', value: 'MR', requiresTimezone: false },
  { title: 'Mauritius', value: 'MU', requiresTimezone: false },
  { title: 'Mayotte', value: 'YT', requiresTimezone: false },
  { title: 'Mexico', value: 'MX', requiresTimezone: false },
  { title: 'Micronesia, Federated States of', value: 'FM', requiresTimezone: false },
  { title: 'Moldova, Republic of', value: 'MD', requiresTimezone: false },
  { title: 'Monaco', value: 'MC', requiresTimezone: false },
  { title: 'Mongolia', value: 'MN', requiresTimezone: false },
  { title: 'Montenegro', value: 'ME', requiresTimezone: false },
  { title: 'Montserrat', value: 'MS', requiresTimezone: false },
  { title: 'Morocco', value: 'MA', requiresTimezone: false },
  { title: 'Mozambique', value: 'MZ', requiresTimezone: false },
  { title: 'Myanmar', value: 'MM', requiresTimezone: false },
  { title: 'Namibia', value: 'NA', requiresTimezone: false },
  { title: 'Nauru', value: 'NR', requiresTimezone: false },
  { title: 'Nepal', value: 'NP', requiresTimezone: false },
  { title: 'Netherlands', value: 'NL', requiresTimezone: false },
  { title: 'Netherlands Antilles', value: 'AN', requiresTimezone: false },
  { title: 'New Caledonia', value: 'NC', requiresTimezone: false },
  { title: 'New Zealand', value: 'NZ', requiresTimezone: false },
  { title: 'Nicaragua', value: 'NI', requiresTimezone: false },
  { title: 'Niger', value: 'NE', requiresTimezone: false },
  { title: 'Nigeria', value: 'NG', requiresTimezone: false },
  { title: 'Niue', value: 'NU', requiresTimezone: false },
  { title: 'Norfolk Island', value: 'NF', requiresTimezone: false },
  { title: 'Northern Mariana Islands', value: 'MP', requiresTimezone: false },
  { title: 'Norway', value: 'NO', requiresTimezone: false },
  { title: 'Oman', value: 'OM', requiresTimezone: false },
  { title: 'Pakistan', value: 'PK', requiresTimezone: false },
  { title: 'Palau', value: 'PW', requiresTimezone: false },
  { title: 'Palestinian Territory, Occupied', value: 'PS', requiresTimezone: false },
  { title: 'Panama', value: 'PA', requiresTimezone: false },
  { title: 'Papua New Guinea', value: 'PG', requiresTimezone: false },
  { title: 'Paraguay', value: 'PY', requiresTimezone: false },
  { title: 'Peru', value: 'PE', requiresTimezone: false },
  { title: 'Philippines', value: 'PH', requiresTimezone: false },
  { title: 'Pitcairn', value: 'PN', requiresTimezone: false },
  { title: 'Poland', value: 'PL', requiresTimezone: false },
  { title: 'Portugal', value: 'PT', requiresTimezone: false },
  { title: 'Puerto Rico', value: 'PR', requiresTimezone: false },
  { title: 'Qatar', value: 'QA', requiresTimezone: false },
  { title: 'Reunion', value: 'RE', requiresTimezone: false },
  { title: 'Romania', value: 'RO', requiresTimezone: false },
  { title: 'Russian Federation', value: 'RU', requiresTimezone: false },
  { title: 'Rwanda', value: 'RW', requiresTimezone: false },
  { title: 'Saint Helena', value: 'SH', requiresTimezone: false },
  { title: 'Saint Kitts and Nevis', value: 'KN', requiresTimezone: false },
  { title: 'Saint Lucia', value: 'LC', requiresTimezone: false },
  { title: 'Saint Pierre and Miquelon', value: 'PM', requiresTimezone: false },
  { title: 'Saint Vincent and the Grenadines', value: 'VC', requiresTimezone: false },
  { title: 'Samoa', value: 'WS', requiresTimezone: false },
  { title: 'San Marino', value: 'SM', requiresTimezone: false },
  { title: 'Sao Tome and Principe', value: 'ST', requiresTimezone: false },
  { title: 'Saudi Arabia', value: 'SA', requiresTimezone: false },
  { title: 'Senegal', value: 'SN', requiresTimezone: false },
  { title: 'Serbia', value: 'RS', requiresTimezone: false },
  { title: 'Seychelles', value: 'SC', requiresTimezone: false },
  { title: 'Sierra Leone', value: 'SL', requiresTimezone: false },
  { title: 'Singapore', value: 'SG', requiresTimezone: false },
  { title: 'Slovakia', value: 'SK', requiresTimezone: false },
  { title: 'Slovenia', value: 'SI', requiresTimezone: false },
  { title: 'Solomon Islands', value: 'SB', requiresTimezone: false },
  { title: 'Somalia', value: 'SO', requiresTimezone: false },
  { title: 'South Africa', value: 'ZA', requiresTimezone: false },
  { title: 'South Georgia and the South Sandwich Islands', value: 'GS', requiresTimezone: false },
  { title: 'Spain', value: 'ES', requiresTimezone: false },
  { title: 'Sri Lanka', value: 'LK', requiresTimezone: false },
  { title: 'Sudan', value: 'SD', requiresTimezone: false },
  { title: 'Surititle', value: 'SR', requiresTimezone: false },
  { title: 'Svalbard and Jan Mayen', value: 'SJ', requiresTimezone: false },
  { title: 'Swaziland', value: 'SZ', requiresTimezone: false },
  { title: 'Sweden', value: 'SE', requiresTimezone: false },
  { title: 'Switzerland', value: 'CH', requiresTimezone: false },
  { title: 'Syrian Arab Republic', value: 'SY', requiresTimezone: false },
  { title: 'Taiwan', value: 'TW', requiresTimezone: false },
  { title: 'Tajikistan', value: 'TJ', requiresTimezone: false },
  { title: 'Tanzania, United Republic of', value: 'TZ', requiresTimezone: false },
  { title: 'Thailand', value: 'TH', requiresTimezone: false },
  { title: 'Timor-Leste', value: 'TL', requiresTimezone: false },
  { title: 'Togo', value: 'TG', requiresTimezone: false },
  { title: 'Tokelau', value: 'TK', requiresTimezone: false },
  { title: 'Tonga', value: 'TO', requiresTimezone: false },
  { title: 'Trinidad and Tobago', value: 'TT', requiresTimezone: false },
  { title: 'Tunisia', value: 'TN', requiresTimezone: false },
  { title: 'Turkey', value: 'TR', requiresTimezone: false },
  { title: 'Turkmenistan', value: 'TM', requiresTimezone: false },
  { title: 'Turks and Caicos Islands', value: 'TC', requiresTimezone: false },
  { title: 'Tuvalu', value: 'TV', requiresTimezone: false },
  { title: 'Uganda', value: 'UG', requiresTimezone: false },
  { title: 'Ukraine', value: 'UA', requiresTimezone: false },
  { title: 'United Arab Emirates', value: 'AE', requiresTimezone: false },
  { title: 'United States', value: 'US', requiresTimezone: false },
  { title: 'United States Minor Outlying Islands', value: 'UM', requiresTimezone: false },
  { title: 'Uruguay', value: 'UY', requiresTimezone: false },
  { title: 'Uzbekistan', value: 'UZ', requiresTimezone: false },
  { title: 'Vanuatu', value: 'VU', requiresTimezone: false },
  { title: 'Venezuela', value: 'VE', requiresTimezone: false },
  { title: 'Viet Nam', value: 'VN', requiresTimezone: false },
  { title: 'Virgin Islands, British', value: 'VG', requiresTimezone: false },
  { title: 'Virgin Islands, U.S.', value: 'VI', requiresTimezone: false },
  { title: 'Wallis and Futuna', value: 'WF', requiresTimezone: false },
  { title: 'Western Sahara', value: 'EH', requiresTimezone: false },
  { title: 'Yemen', value: 'YE', requiresTimezone: false },
  { title: 'Zambia', value: 'ZM', requiresTimezone: false },
  { title: 'Zimbabwe', value: 'ZW', requiresTimezone: false },
];

export default countries;
