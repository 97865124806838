/* global WP_DEFINE_IS_NODE */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import formFieldPropTypes from 'common/src/app/util/form/formFieldPropTypes';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { isIE } from 'common/src/app/util/browserUtil';
import TextNew, { ElementTypes, FontWeight, Colors } from 'components/atoms/TextNew';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import LocaleMessage from '../LocaleMessage';

import './textarea.scss';

const Textarea = (
  {
    input: inputProps = {},
    placeholder = '',
    showCharLimit,
    // should not show up in props
    form, // eslint-disable-line no-unused-vars
    meta, // eslint-disable-line no-unused-vars
    onChange,
    ...props
  },
  {},
  className,
) => {
  const ieHack = {};
  /*
   * on IE, use onInput instead of onChange. See:
   * https://github.com/facebook/react/issues/7211
   * https://github.com/facebook/react/issues/7027
   * https://github.com/omcljs/om/issues/704
   */
  if (!WP_DEFINE_IS_NODE && isIE()) {
    ieHack.onInput = inputProps.onChange;
    ieHack.onChange = null;
  }

  const [charCount, setCharCount] = useState(inputProps?.value?.length || 0);

  const handleChange = event => {
    onChange && onChange(event);
    showCharLimit && setCharCount(event.target.value.length);
  };

  return (
    <Fragment>
      <textarea
        {...props}
        {...inputProps}
        {...ieHack}
        placeholder={placeholder}
        className={classNames(className, {
          'has-counter': showCharLimit && props.maxLength > 0,
        })}
        onChange={handleChange}
        value={undefined}
        defaultValue={inputProps.value}
      />
      {showCharLimit && props.maxLength > 0 ? (
        <TextNew.Sans.SM
          element={ElementTypes.SPAN}
          color={Colors.DARK_TONE}
          weight={FontWeight.LIGHT}
        >
          {charCount} <LocaleMessage id="general.inlineText.of" /> {props.maxLength}
        </TextNew.Sans.SM>
      ) : null}
    </Fragment>
  );
};

Textarea.defaultProps = {
  showCharLimit: false,
};

Textarea.propTypes = {
  placeholder: PropTypes.node,
  showCharLimit: PropTypes.bool,
  ...formFieldPropTypes,
};

export default withFunctionalClassName(ComponentType.ATOM, 'textarea')(Textarea);
