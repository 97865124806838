/**
 * Converts syns in decimal.
 * @function
 * @param {number} value - syns in integer and decimal (1.5)
 *
 * Locale files cannot handle decimal correctly, this function
 * will split the decimal and pass to the Locale files as an object with {SYNS, HALF}
 * exmaple: getMessage('general.syns.shortLabel', synsToLocale(syns))
 */
import React from 'react';
import SynsType from '../data/enum/SynsType';
import SynsCount from '../data/enum/SynsCount';

const synsToLocale = (value, hasMinValue = false, synsType, LocaleMessage) => {
  const synsRound = Math.round(value * 2) / 2;
  const synsValue =
    hasMinValue && synsRound < SynsCount.MINIMUM_SYN_VALUE
      ? SynsCount.MINIMUM_SYN_VALUE
      : synsRound;

  const syns = synsValue
    .toString()
    .replace('½', '.5')
    .split('.');

  const synsObj = {
    SYNS: syns[0] || '0',
    HALF: syns[1],
  };

  if (synsType) {
    synsObj.SYNSTYPE = (
      <LocaleMessage
        key="synsType"
        id={`general.syns.${synsType !== SynsType.EACH ? 'perPerson' : 'each'}`}
      />
    );
  }

  return synsObj;
};

export default synsToLocale;
