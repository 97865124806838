import createAction from 'redux-actions/lib/createAction';

export const CLOSE_ALL_HEADER_MENUS = 'headerActions/CLOSE_ALL_HEADER_MENUS';
export const HEADER_TOGGLE_FIXED = 'headerActions/TOGGLE_FIXED';
export const HEADING_MAIN_MENU_TOGGLE = 'headerActions/HEADING_MAIN_MENU_TOGGLE';
export const PROFILE_MENU_TOGGLE = 'headerActions/PROFILE_MENU_TOGGLE';
export const NOTIFICATION_MENU_TOGGLE = 'headerActions/NOTIFICATION_MENU_TOGGLE';
export const TOGGLE_UI_FEATURES = 'headerActions/TOGGLE_UI_FEATURES';

export const headerToggleFixed = createAction(HEADER_TOGGLE_FIXED, (isFixed, height) => ({
  isFixed,
  height,
}));

export const closeAllHeaderMenus = createAction(CLOSE_ALL_HEADER_MENUS);
export const headingMainMenuToggle = createAction(HEADING_MAIN_MENU_TOGGLE);
export const profileMenuToggle = createAction(PROFILE_MENU_TOGGLE);
export const notificationMenuToggle = createAction(NOTIFICATION_MENU_TOGGLE);
export const toggleUiFeatures = createAction(TOGGLE_UI_FEATURES);
