const FilterType = {
  NONE: '',
  RECIPES: 'recipes',
  FEATURES: 'features',
  SUCCESS_STORY: 'successStories',
  COMMUNITY: 'community',
  FOOD_RANGE: 'foodRange',
  ADVERTISEMENT: 'advertisement',
  NEAREST_GROUP_SEARCH: 'nearestGroupSearch',
};

export default FilterType;
