/**
 * Available unit types
 * @type {{pixel: string, percentage: string}}
 */
const Unit = {
  PIXEL: 'px',
  PERCENTAGE: '%',
  EM: 'em',
};

Unit.propTypes = Object.values(Unit);

export default Unit;
