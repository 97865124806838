class GatewayError extends Error {
  constructor(message, requestOptions, responseStatus, responseText, parsedResponse) {
    super(message);

    this.request = requestOptions;
    this.response = {
      status: responseStatus,
      text: responseText,
      parsed: parsedResponse,
    };
  }
}

export default GatewayError;
