import React from 'react';
import PropTypes from 'prop-types';
import TextNew, {
  ElementTypes,
  AccentColors,
  FontSizes,
  FontWeight,
  Colors,
} from 'components/atoms/TextNew';

const Address = ({ address, textStyles }) => (
  <address>
    {Object.values(address).map(
      (value, index) =>
        value && (
          <TextNew.Sans
            {...textStyles}
            key={`address-field-${index}`}
            element={ElementTypes.P}
            color={AccentColors.ACCENT_GREY}
          >
            {value}
          </TextNew.Sans>
        ),
    )}
  </address>
);

Address.propTypes = {
  address: PropTypes.object.isRequired,
  textStyles: PropTypes.shape({
    color: PropTypes.oneOf([...Colors.propTypes, ...AccentColors.propTypes]),
    weight: PropTypes.oneOf(FontWeight.propTypes),
    fontSize: PropTypes.oneOf(FontSizes.propTypes),
  }),
};

export default Address;
