import React from 'react';
import classNames from 'classnames';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import PropTypes from 'prop-types';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import Button from 'components/atoms/Button';
import './our-story-section.scss';

const highlightSlimmingWorld = text => {
  const words = ['Slimming World'];
  const isWordPresent = words.some(word => text.includes(word));
  if (!isWordPresent) {
    return text;
  }

  const regex = new RegExp(`(${words.join('|')})`, 'ig');

  return text
    .split(regex)
    .map(item => (words.includes(item) ? `<span class="sw">${item}</span>` : item))
    .join(' ');
};

const OurStorySection = (
  {
    title,
    imagePosition,
    imageSize,
    backgroundColour,
    fontColour,
    text,
    buttonText,
    buttonLink,
    image,
  },
  context,
  className,
) => {
  const isExternalLink = buttonLink?.includes('http');
  return (
    <section
      key={title}
      className={classNames(
        className,
        imagePosition === 0 ? 'left' : 'right',
        `image-${imageSize.toLowerCase()}`,
        `background-${backgroundColour.toLowerCase()}`,
        `font-${fontColour.toLowerCase()}`,
      )}
    >
      <div className="section-blocks-wrapper">
        {image && (
          <figure>
            <ResponsiveImage px={600} {...image} />
          </figure>
        )}
        <aside className="content">
          <RichTextBlock text={text} title={highlightSlimmingWorld(title)} />
          {buttonLink?.length > 0 && buttonText?.length > 0 && (
            <Button
              isGhostLight={fontColour === 'white'}
              isGhostDark={fontColour !== 'white'}
              href={isExternalLink ? buttonLink : null}
              to={isExternalLink ? null : buttonLink}
            >
              {buttonText}
            </Button>
          )}
        </aside>
      </div>
    </section>
  );
};

OurStorySection.propTypes = {
  title: PropTypes.string.isRequired,
  imageSize: PropTypes.string.isRequired,
  backgroundColour: PropTypes.string.isRequired,
  fontColour: PropTypes.string.isRequired,
  imagePosition: PropTypes.number.isRequired,
  image: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'OurStorySection')(OurStorySection);
