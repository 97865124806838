/* global 'organism','IcelandRecipeInspiration' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState, { DeviceState } from 'common/src/app/util/device-state/withDeviceState';
import TextNew, { ElementTypes, FontSizes } from 'components/atoms/TextNew';
import RecipeTileContent from 'components/organisms/RecipeTileContent';

import './iceland-recipe-inspiration.scss';

const IcelandRecipeInspiration = ({ recipeInspirations, deviceState }, context, className) => (
  <section className={className}>
    <div className="wrapper">
      <header className="header">
        <TextNew.Script
          size={deviceState > DeviceState.SM ? FontSizes.MD : FontSizes.SM}
          element={ElementTypes.H2}
          cid="title"
          localeId="icelandRecipeInspiration.title"
        />
      </header>

      <div className="recipe-columns">
        {recipeInspirations?.map(recipe => (
          <div className="column" key={recipe.id}>
            <RecipeTileContent
              {...recipe?.recipePicker}
              {...recipe}
              title={recipe?.title?.length > 0 ? recipe.title : recipe?.recipePicker?.title}
              description={recipe?.text || recipe?.recipePicker?.introduction}
              isInspirationTile
            />
          </div>
        ))}
      </div>
    </div>
  </section>
);

IcelandRecipeInspiration.propTypes = {
  recipeInspirations: PropTypes.array,
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName('organism','IcelandRecipeInspiration')(IcelandRecipeInspiration),
);
