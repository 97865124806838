/**
 * Returns scrollbar width for current OS/Browser
 * @function scrollBarWidth
 *
 * Creates a div of screen, forces an overflow and checks the new size,
 * returning the difference between the two.
 */
const scrollBarWidth = () => {
  const scrollDiv = document.createElement('div');
  scrollDiv.style.width = '100px';
  scrollDiv.style.height = '100px';
  scrollDiv.style.overflow = 'scroll';
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

export default typeof window !== 'undefined' ? scrollBarWidth : null;
