import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import Offers from 'bundle-loader?lazy&reactHot&name=Offers!./index';

const routes = (
  <Route
    title="Slimming World offers to help you slim and save"
    description="Save money while committing to your weight loss dreams with Slimming World offers. Find our latest joining offers and pricing for group and online here"
    path={Pages.UK_PUBLIC_OFFERS}
    {...getRouteComponentProp(Offers)}
  />
);

export default routes;
