/**
 * GridItem represents an amount of columns from 1 through 12.
 * classNames are passed via the enum file in the format of:
 * @param {string} breakpoint-number
 * breakpoint format defined in scss: xs, sm, md, lg
 * numbers: 1-12 corresponds to how many columns an item will fill.
 * you can define one of each breakpoint: xs-12 sm-8 md-6 lg-4
 * Offset can be used to push a column to a desired position: offset-sm-4
 * We should not be applying className directly.
 */
const GridItemType = {
  FEATURED: 'xs-12 sm-12 md-8 lg-6',
  DEFAULT: 'xs-12 sm-6 md-4 lg-3',
  FULL_WIDTH: 'xs-12 sm-12 md-12 lg-12',
  MEDIUM_WIDTH: 'xs-12 sm-12 md-12 lg-8',
  THIRDS: 'xs-12 sm-6 md-4 lg-4',
  FOURTHS: 'xs-12 sm-6 md-3 lg-3',
};

export default GridItemType;
