import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { makeEntitySelector } from 'common/src/app/reducers/view/entityViewReducer';
import { getRecipeDetail } from 'common/src/app/actions/resources/publicActions';
import { withInitAction, prepareComponent } from '@mediamonks/react-redux-component-init';
import TypicalDayMealPlan from 'components/organisms/TypicalDayMealPlan';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { trackVideoEvent } from 'common/src/app/actions/trackingActions';
import { API_COMPONENT_ICELAND_INFORMATION_SHEET } from 'common/src/app/data/apiComponents';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';
import RecipeDetail from './RecipeDetail';

const mapStateToProps = state => {
  const entitySelector = makeEntitySelector();
  const recipe = entitySelector(state, state.view.pages.recipeDetail?.recipeDetail);
  const factSheet = contentComponentSelector(state, API_COMPONENT_ICELAND_INFORMATION_SHEET);

  return {
    recipe,
    factSheet,
    brightcoveAccountId: state.config.environmentConfig.brightCove.accountId,
    brightcovePlayerId: state.config.environmentConfig.brightCove.playerId,
  };
};

const addInitAction = withInitAction(['params.slug'], {
  prepared: ({ params: { slug } }, dispatch) =>
    Promise.all([
      dispatch(prepareComponent(TypicalDayMealPlan)),
      dispatch(getRecipeDetail(slug)),
      dispatch(getComponent(API_COMPONENT_ICELAND_INFORMATION_SHEET)),
    ]),
});

const connected = connect(mapStateToProps, { trackVideoEvent });

export default compose(addInitAction, connected)(RecipeDetail);
