/* global 'atom','ImageBlockCarouselBlock' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import Carousel from 'components/organisms/Carousel';
import ImageBlock from '../ImageBlock';
import './image-block-carousel-block.scss';

/**
 * Carousel that contains an array of images with caption
 */
const ImageBlockCarouselBlock = ({ images = [] }, context, className) => {
  const carouselSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
    initialSlide: Math.ceil(images?.length / 2) - 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: 'center',
    centerPadding: '0px',
  };

  return (
    <div className={className}>
      {images && (
        <div className="carousel-wrapper">
          <Carousel settings={carouselSettings}>
            {images.map(({ caption, image }) => (
              <ImageBlock key={image.id} caption={caption} image={image} />
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

ImageBlockCarouselBlock.propTypes = {
  /**
   * Array of items to display
   */
  images: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
};

export default withDeviceState()(
  withFunctionalClassName('atom','ImageBlockCarouselBlock')(ImageBlockCarouselBlock),
);
