import { Category } from '../../../app/data/enum/Tracking/index';

const trackingCategories = Object.keys(Category).map(key => key.replace(/_/g, '-').toLowerCase());

const getCategoryBasedOnPath = (currentPath = window.location.pathname) => {
  const path = currentPath
    .substr(1)
    .toLowerCase()
    .replace(/search\//, '');

  for (let i = 0; i < trackingCategories.length; i++) {
    if (path.startsWith(trackingCategories[i])) {
      return Category[trackingCategories[i].toUpperCase().replace(/-/g, '_')];
    }
  }

  return null;
};

export default getCategoryBasedOnPath;
