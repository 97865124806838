import React from 'react';
import PropTypes from 'prop-types';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { countryCode } from 'common/src/app/data/enum/Countries';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import TextNew, { ElementTypes, Colors } from '../../atoms/TextNew';
import MembershipPackageCard from '../../molecules/MembershipPackageCard';

import './our-membership-packages.scss';

const membershipPackages = [
  IconName.MEMBERSHIP_BADGE_BRONZE,
  IconName.MEMBERSHIP_BADGE_SILVER,
  IconName.MEMBERSHIP_BADGE_GOLD,
];

const country = marketConfig.marketName === countryCode.IRELAND ? 'Roi' : 'Uk';

const OurMembershipPackages = ({ data }) => (
  <section
    className={functionalComponentClassName(ComponentType.ORGANISM, 'OurMembershipPackages')}
  >
    <TextNew.Script.SM element={ElementTypes.H2} color={Colors.BRAND} cid="packages-title">
      {data.packagesTitleUk}
    </TextNew.Script.SM>

    <div className="package-container">
      {membershipPackages.map((membershipPackage, i) => (
        <MembershipPackageCard
          badge={membershipPackage}
          key={membershipPackage}
          title={data[`package${i + 1}Title${country}`]}
          description={data[`package${i + 1}Subtitle${country}`]}
          benefits={data[`package${i + 1}Features${country}`]}
        />
      ))}
    </div>
  </section>
);

OurMembershipPackages.propTypes = {
  data: PropTypes.object,
};

export default OurMembershipPackages;
