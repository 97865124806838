import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import DataTestidOverides from 'common/src/app/data/enum/DataTestidOverides';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import ButtonBulleted from '../../atoms/ButtonBulleted';

import './grid-size-filter.scss';

const GridSizeFilter = ({ deviceState, gridColumnCount, saveGridLayout }, context, className) => (
  <div className={className}>
    {deviceState > DeviceState.XL && (
      <Fragment>
        <ButtonBulleted
          gridColumnCount={gridColumnCount}
          bulletCount={3}
          onClick={saveGridLayout}
          dataTestid={DataTestidOverides.GRID_CONTROL_THREE}
        />
        <ButtonBulleted
          gridColumnCount={gridColumnCount}
          bulletCount={4}
          dataTestid={DataTestidOverides.GRID_CONTROL_FOUR}
          onClick={saveGridLayout}
        />
      </Fragment>
    )}
  </div>
);

GridSizeFilter.propTypes = {
  deviceState: PropTypes.number,
  gridColumnCount: PropTypes.number,
  saveGridLayout: PropTypes.func,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'GridSizeFilter')(GridSizeFilter),
);
