import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import {
  consultantShape,
  groupShape,
} from 'common/src/app/util/proptypes/apiEntities/groupSearchShape';
import Wrapper from 'components/atoms/Wrapper';
import GridItemType from 'common/src/app/data/enum/GridItemType';
import Grid from 'components/organisms/Grid';
import GridItem from 'components/organisms/GridItem';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import NearestGroupTile from '../NearestGroupTile';

import './group-directory.scss';

const GroupDirectory = ({ consultant, groups, deviceState }, context, className) => (
  <div className={className}>
    <Wrapper padding={deviceState >= DeviceState.MD ? 'md' : 'xs'}>
      <Grid>
        {groups &&
          groups.map((item, index) => (
            <GridItem
              type={GridItemType.FULL_WIDTH}
              key={`grid-item-group-${item.id}`}
              cid={`result-${index}`}
            >
              <NearestGroupTile staticConsultantDetail={consultant} item={item} />
            </GridItem>
          ))}
      </Grid>
    </Wrapper>
  </div>
);

GroupDirectory.propTypes = {
  consultant: PropTypes.shape(consultantShape),
  groups: PropTypes.arrayOf(PropTypes.shape(groupShape)),
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'GroupDirectory')(GroupDirectory),
);
