import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import TextNew from 'components/atoms/TextNew';
import LocaleMessage from 'components/atoms/LocaleMessage';
import errorBoundary from 'components/hoc/ErrorBoundary';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Pages from 'common/src/app/data/enum/Pages';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import { createPath } from 'common/src/app/util/routeUtils';
import Button from 'components/atoms/Button';
import ElementTypes from 'common/src/app/data/enum/ElementTypes';
import NewsletterConfirmEmailEnum from 'common/src/app/data/NewsletterConfirmEmailEnum';
import NewsletterSignUp from '../../../../../molecules/NewsletterSignUp';

import './confirm-newsletter-signup-email.scss';

const MenuTypeEnum = {
  0: 'meat',
  1: 'vegetarian',
  2: 'vegan',
};

class ConfirmNewsletterSignupEmail extends PureComponent {
  componentDidMount() {
    const { location, confirmNewsletterSignup } = this.props;
    const id = location?.query?.id || '';
    const token = (location?.hash || '#').substring(1);
    confirmNewsletterSignup(id, token);
  }

  // eslint-disable-next-line class-methods-use-this
  getLocaleMessagePrefix = confirmationFlow => {
    let copy = '';
    if (confirmationFlow === NewsletterConfirmEmailEnum.UNKNOWN) {
      copy = 'confirmNewsletterSignupEmail.errors.unknown';
    }
    if (confirmationFlow === NewsletterConfirmEmailEnum.INVALID_TOKEN) {
      copy = 'confirmNewsletterSignupEmail.errors.invalid';
    }
    if (confirmationFlow === NewsletterConfirmEmailEnum.EXPIRED_TOKEN) {
      copy = 'confirmNewsletterSignupEmail.errors.expired';
    }
    if (confirmationFlow === NewsletterConfirmEmailEnum.SUCCESS) {
      copy = 'confirmNewsletterSignupEmail';
    }
    return copy;
  };
  render() {
    const { confirmationFlow, deviceState, background, menuType } = this.props;

    const copy = this.getLocaleMessagePrefix(confirmationFlow);

    const menuLink = createPath(Pages.UK_PUBLIC_SEVEN_DAY_MENU, {
      menuType: menuType !== undefined && menuType !== null && MenuTypeEnum[menuType],
      day: 'day-1',
    });

    return (
      copy && (
        <div {...componentClassNameProp(ComponentType.TEMPLATE, this)}>
          <BackgroundImageWrapper
            image={deviceState >= DeviceState.XL && background ? background : ''}
          >
            <div className="wrapper">
              <TextNew.Loud localeId={`${copy}.title`} element={ElementTypes.H1} />
              <TextNew.PrimaryOriginal localeId={`${copy}.subTitle`} />
              {confirmationFlow === NewsletterConfirmEmailEnum.SUCCESS && (
                <Button href={menuLink} ripple>
                  <LocaleMessage id="confirmNewsletterSignupEmail.linkLabel" />
                </Button>
              )}
            </div>
            {confirmationFlow !== NewsletterConfirmEmailEnum.SUCCESS && <NewsletterSignUp />}
          </BackgroundImageWrapper>
        </div>
      )
    );
  }
}

ConfirmNewsletterSignupEmail.propTypes = {
  confirmationFlow: PropTypes.number.isRequired,
  menuType: PropTypes.number,
  location: PropTypes.object,
  confirmNewsletterSignup: PropTypes.func.isRequired,
  background: ImagePropTypes,
  deviceState: PropTypes.number,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
)(ConfirmNewsletterSignupEmail);
