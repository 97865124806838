import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { createLinkProps } from 'common/src/app/util/urlUtils';
import ImageBreakpointMap from '../../enum/ImageBreakpointMap';
import formulateImages from '../../utils/formulateImages';
import promotionShape from '../../utils/propTypes/promotion';

const HomepageCampaignPromotion = ({ promotion, slidePosition }) => {
  const { alt, caption, images } = formulateImages(promotion, ImageBreakpointMap);
  const { link } = promotion || {};

  const linkProp = useMemo(() => createLinkProps(link), [link]);
  const linkTarget = linkProp?.href ? '_blank' : null;

  return link ? (
    <div data-testid={`homepage-campaign-slide-${slidePosition}`}>
      <Link data-campaign-carousel-slide={slidePosition} {...linkProp} target={linkTarget}>
        <picture>
          {images.map(({ name, src, breakpoint }) =>
            breakpoint ? (
              <source srcSet={src} media={breakpoint} key={name} data-testid="image-source" />
            ) : (
              <img src={src} alt={alt} key={name} />
            ),
          )}
        </picture>
        {caption && (
          <figcaption className="screen-reader" data-testid="caption">
            {caption}
          </figcaption>
        )}
      </Link>
    </div>
  ) : null;
};

HomepageCampaignPromotion.propTypes = {
  promotion: PropTypes.shape(promotionShape).isRequired,
  slidePosition: PropTypes.number,
};

export default HomepageCampaignPromotion;
