import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import {
  userDetailFields,
  emailFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import FormGroup from 'components/molecules/FormGroup';
import RadioButton from 'components/atoms/Form/components/atoms/RadioButton';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ReCaptchaCheckbox from 'components/atoms/ReCaptchaCheckbox';
import InputType from 'common/src/app/data/enum/InputType';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Input, { descriptorTypes } from 'components/atoms/Input';
import FormikError from 'components/atoms/FormikError';
import Row from 'components/atoms/Row';
import TextNew, { Colors, AccentColors, FontWeight, ElementTypes } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import errorBoundary from 'components/hoc/ErrorBoundary';
import Image from 'components/atoms/Image';
import sevenDayMenuFieldNames from '../../../data/enum/sevenDayMenuFieldNames';
import NewsletterSignUpSubmittedSuccessMessage from './components/molecules/NewsletterSignUpSubmittedSuccessMessage';

import './newsletter-sign-up.scss';

export const MenuType = {
  CLASSIC: 'Classic',
  VEGAN: 'Vegan',
  VEGETARIAN: 'Vegetarian',
};

const NewsletterSignUp = (
  {
    handleSubmit,
    isSubmitting,
    deviceState,
    errors,
    values,
    handleChange,
    status,
    setFieldValue,
    assets,
  },
  { getMessage },
  className,
) => {
  const backgroundSelector = () => {
    if (!assets) return null;

    const [mobile, tablet, desktop] = assets;

    if (deviceState >= DeviceState.XL) {
      return desktop;
    } else if (deviceState === DeviceState.LG) {
      return tablet;
    } else if (deviceState >= DeviceState.XS) {
      return mobile;
    }
    return mobile;
  };

  const ratioSelector = () => {
    if (deviceState >= DeviceState.XL) {
      return 748 / 536;
    } else if (deviceState === DeviceState.LG) {
      return 924 / 656;
    } else if (deviceState >= DeviceState.XS) {
      return 828 / 682;
    }
    return 640 / 610;
  };

  if (status) {
    return <NewsletterSignUpSubmittedSuccessMessage />;
  }

  return (
    <section className={className} data-testid="newsletter-sign-up">
      <div className="content-wrapper">
        <header className="title-and-image">
          <div className="info">
            <TextNew.Script.XS
              element={ElementTypes.H2}
              cid="main-title"
              color={AccentColors.ACCENT_CAMPAIGN}
              localeId="newsletterSignUp.title"
            />

            <TextNew.Serif.XL
              element={ElementTypes.H3}
              cid="newsletter-title"
              color={AccentColors.ACCENT_PURPLE}
              localeId={'newsletterSignUp.info.title'}
              weight={FontWeight.BOLD}
            />

            {deviceState <= DeviceState.LG && (
              <TextNew.Sans.LG
                cid="newsletter-info"
                color={AccentColors.ACCENT_PURPLE}
                localeId="newsletterSignUp.info.content"
                weight={FontWeight.LIGHT}
              />
            )}
          </div>

          {backgroundSelector()?.image && (
            <Image {...backgroundSelector()?.image} ratio={ratioSelector()} />
          )}
        </header>

        <form onSubmit={handleSubmit}>
          <Row column>
            {deviceState > DeviceState.LG && (
              <TextNew.Sans.LG
                cid="newsletter-info-form"
                color={AccentColors.ACCENT_PURPLE}
                localeId="newsletterSignUp.info.content"
                weight={FontWeight.LIGHT}
              />
            )}
            <Input
              id="newsletter-firstname"
              label={getMessage('newsletterSignUp.label.name')}
              descriptorType={descriptorTypes.STATIC}
              value={values[userDetailFields.FIRST_NAME]}
              name={userDetailFields.FIRST_NAME}
              placeholder={getMessage('newsletterSignUp.placeholders.name')}
              type={InputType.TEXT}
              onChange={handleChange}
              maxLength={30}
              convertOnChange={false}
              hasValue={values[userDetailFields.FIRST_NAME] !== ''}
            />
            <FormikError
              errorName={getMessage('newsletterSignUp.label.name')}
              name={userDetailFields.FIRST_NAME}
            />

            <Input
              id="newsletter-email"
              label={getMessage('newsletterSignUp.label.email')}
              descriptorType={descriptorTypes.STATIC}
              value={values[emailFields.EMAIL_ADDRESS]}
              name={emailFields.EMAIL_ADDRESS}
              placeholder={getMessage('newsletterSignUp.placeholders.email')}
              type={InputType.TEXT}
              onChange={handleChange}
              convertOnChange={false}
              hasValue={values[emailFields.EMAIL_ADDRESS] !== ''}
            />
            <FormikError
              errorName={getMessage('newsletterSignUp.label.email')}
              name={emailFields.EMAIL_ADDRESS}
            />
          </Row>
          <div className="bottom-row">
            <FormGroup label="Choice menu type" hideLabel className="radio-button-group">
              <RadioButton
                onChange={handleChange}
                value={MenuType.CLASSIC}
                checked={values.menuType === MenuType.CLASSIC}
                label={getMessage('newsletterSignUp.label.radioButton.classic')}
                name="menuType"
              />
              <RadioButton
                onChange={handleChange}
                value={MenuType.VEGETARIAN}
                checked={values.menuType === MenuType.VEGETARIAN}
                label={getMessage('newsletterSignUp.label.radioButton.vegetarian')}
                name="menuType"
              />
              <RadioButton
                onChange={handleChange}
                value={MenuType.VEGAN}
                checked={values.menuType === MenuType.VEGAN}
                label={getMessage('newsletterSignUp.label.radioButton.vegan')}
                name="menuType"
              />
            </FormGroup>
            <div className="recaptcha">
              <ReCaptchaCheckbox
                onChange={() => setFieldValue([sevenDayMenuFieldNames.RECAPTCHA], true)}
              />
              <FormikError name="recaptcha" />
              <Button
                type={InputType.SUBMIT}
                disabled={isSubmitting}
                isLoading={isSubmitting}
                dataTestid={'submit-button'}
              >
                <TextNew.PrimaryFootnote
                  element={ElementTypes.SPAN}
                  color={Colors.PRIMARY_LIGHT}
                  localeId="newsletterSignUp.submit"
                />
              </Button>
            </div>
          </div>

          {errors?.id && (
            <TextNew.Error
              localeId={errors.id}
              localeParams={{
                MENU_LINK: (
                  <Link to={errors?.params?.MENU_LINK} target="_blank">
                    <LocaleMessage key="link-text" id="newsletterSignUp.linkText" />
                  </Link>
                ),
              }}
            />
          )}
        </form>
      </div>
    </section>
  );
};

NewsletterSignUp.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  deviceState: PropTypes.number,
  errors: PropTypes.object,
  values: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  status: PropTypes.object,
  setFieldValue: PropTypes.func,
  assets: PropTypes.array,
};

NewsletterSignUp.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default compose(
  errorBoundary(),
  withDeviceState(),
  withFunctionalClassName(ComponentType.MOLECULE, 'NewsletterSignUp'),
)(NewsletterSignUp);
