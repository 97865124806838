import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import EnhancedFormField from 'common/src/app/formik/components/EnhancedFormField';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { handleFieldGroupInput } from 'common/src/app/util/form/inputEventUtil';
import LocaleMessage from 'components/atoms/LocaleMessage';
import { InputNew, descriptorTypes } from 'components/atoms/Form';
import InputType from 'common/src/app/data/enum/InputType';
import SelectNew from 'components/atoms/SelectNew';
import Row from 'components/atoms/Row';
import {
  composeHeightImperialToMetric,
  decomposeHeightImperialToMetric,
} from 'common/src/app/util/heightUtils';
import './height-imperial-field.scss';

const FEET_LENGTH = 1;
const INCHES_LENGTH = 2;

const HeightImperialField = (
  {
    name,
    abbreviate,
    disabled,
    formik: { values, isSubmitting, setFieldValue, setFieldTouched, setValues },
  },
  { getMessage },
  className,
) => {
  const { feet: feetValue, inches: inchesValue, halfInch: halfInchValue, height } = values;

  useEffect(() => {
    if (height) {
      const { feet, inches } = decomposeHeightImperialToMetric(height);
      setValues({ ...values, feet, inches });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!feetValue) {
      setFieldValue(name, null);
    }
    if (feetValue || inchesValue || halfInchValue) {
      const composedValue = composeHeightImperialToMetric({
        feet: feetValue,
        inches: inchesValue,
        halfInch: halfInchValue,
      });

      setFieldValue(name, composedValue);
    }
  }, [feetValue, inchesValue, halfInchValue, height, isSubmitting, setFieldValue, name]);

  const setValue = (fieldName, value) => {
    setFieldValue(fieldName, value, false);

    if (!feetValue || !inchesValue) {
      requestAnimationFrame(() => setFieldTouched(name, true, false));
    }
  };

  return (
    <Row className={className}>
      <EnhancedFormField
        data-testid={`${getMessage('general.measureUnit.height.feet.label')}-input`}
        component={InputNew}
        name={getMessage('general.measureUnit.height.feet.label')}
        id="_composite-input-height.feet"
        type={InputType.NUMBER}
        min="0"
        max="9"
        aria-label={getMessage('general.measureUnit.height.feet.label')}
        placeholder="0"
        label={
          <LocaleMessage
            id={`general.measureUnit.height.feet.${abbreviate ? 'labelAbbreviation' : 'label'}`}
          />
        }
        descriptor={descriptorTypes.STATIC}
        onKeyPress={event => handleFieldGroupInput(event, FEET_LENGTH)}
        onBlur={e => setValue(getMessage('general.measureUnit.height.feet.label'), e.target.value)}
        disabled={disabled}
      />
      <EnhancedFormField
        data-testid={`${getMessage('general.measureUnit.height.inches.label')}-input`}
        component={InputNew}
        name={getMessage('general.measureUnit.height.inches.label')}
        id="_composite-input-height.inches"
        type={InputType.NUMBER}
        min="0"
        max="12"
        arial-label={getMessage('general.measureUnit.height.inches.label')}
        placeholder="00"
        label={
          <LocaleMessage
            id={`general.measureUnit.height.inches.${abbreviate ? 'labelAbbreviation' : 'label'}`}
          />
        }
        descriptor={descriptorTypes.STATIC}
        onKeyPress={event => handleFieldGroupInput(event, INCHES_LENGTH)}
        onBlur={e =>
          setValue(getMessage('general.measureUnit.height.inches.label'), e.target.value)
        }
        disabled={disabled}
      />
      <EnhancedFormField
        dataTestId={'halfInch-input'}
        component={SelectNew}
        name="halfInch"
        customLabel="Half Inch"
        type={InputType.NUMBER}
        options={[
          {
            title: '0',
            value: 0,
            ariaLabel: getMessage('general.measureUnit.height.inches.labelZeroInch'),
          },
          {
            title: '½',
            value: 0.5,
            ariaLabel: getMessage('general.measureUnit.height.inches.labelHalfInch'),
          },
        ]}
        onChange={e => setValue('halfInch', e.target.value)}
        disabled={disabled}
        value={0}
      />
    </Row>
  );
};

HeightImperialField.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

HeightImperialField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  abbreviate: PropTypes.bool,
  formik: PropTypes.object,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'HeightImperialField',
)(HeightImperialField);
