import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import Cyprus from 'bundle-loader?lazy&reactHot&name=Cyprus!./index';
import { marketHasCyprusGroups } from 'common/src/app/config/routeRequirements';

const routes = (
  <Route
    title="Cyprus | {pageTitle}"
    path={Pages.UK_PUBLIC_CYPRUS_GROUP_SEARCH}
    requirements={[marketHasCyprusGroups]}
    {...getRouteComponentProp(Cyprus)}
  />
);

export default routes;
