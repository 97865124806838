import camelCase from 'lodash/camelCase';
import InteractionType from '../../app/data/enum/InteractionType';
import { isFulfilled, isInteraction } from '../../app/util/actionFilters';
import { DataLayerKeys } from '../../app/data/enum/Tracking/index';
import { ADD_INTERACTION } from '../../app/actions/resources/interactionActions';
import { COMMENT, POST } from '../../app/data/entityTypes';
import {
  COMMON_TRACK_EVENT,
  TRACK_LINK,
  TRACK_VIDEO_EVENT,
} from '../../app/actions/trackingActions';
import { dataLayerCustomEvent } from '../../app/util/tracking/dataLayerUtils';
import { getEntityTypeFromRelatedEntityKind } from '../../app/data/enum/RelatedEntityKind';
import { entityNameMap, entityCategoryMap } from '../../app/util/tracking/entityTrackingMaps';
import sharedListeners from '../../app/util/tracking/sharedListeners';
import updateTrackingDataCategory from '../../app/util/tracking/updateTrackingDataCategory';
import Category from '../../app/data/enum/Tracking/Category';
import { HEADING_MAIN_MENU_TOGGLE } from '../../app/actions/components/headerActions';
import getCategoryBasedOnPath from './util/getCategoryBasedOnPath';

export default addListener => {
  /**
   * Add shared listeners (used on registration/member)
   */
  sharedListeners().forEach(({ listener, callback }) => addListener(listener, callback));

  /**
   * Track like interaction
   */
  addListener(
    {
      actionType: ADD_INTERACTION,
      filter: [isFulfilled, isInteraction(InteractionType.LIKE)],
    },
    (
      { meta: { interactionSubjectId, interactionSubjectKind, interactionSubjectTitle } },
      getState,
    ) => {
      const entityType = getEntityTypeFromRelatedEntityKind(interactionSubjectKind);
      let trackingData = {
        category: entityCategoryMap[entityType],
        trackingName: [entityNameMap[entityType]],
      };

      // Don't track comments
      if (entityType === COMMENT) {
        return;
      }

      if (entityType === POST) {
        trackingData = updateTrackingDataCategory(trackingData, getState);
      }

      dataLayerCustomEvent(
        {
          category: entityType,
          entityId: interactionSubjectId,
          [trackingData.trackingName]: interactionSubjectTitle,
          [camelCase(`${DataLayerKeys.LIKE}-${trackingData.category}`)]: 1,
        },
        getState(),
      );
    },
  );

  /**
   * Track Link (href) events
   */
  addListener(
    {
      actionType: TRACK_LINK,
    },
    ({ meta }, getState) => {
      if (meta) {
        const category = !meta.category
          ? getCategoryBasedOnPath() ||
            window.location.pathname
              .substr(1)
              .replace(/\s/, '-')
              .toLowerCase()
          : meta.category;

        dataLayerCustomEvent(
          {
            ...meta,
            ...(!meta.contentView ? { [DataLayerKeys.CONTENT_GROUP_NAME]: category } : {}),
            ...(meta.category ? { category } : {}),
          },
          getState(),
        );
      }
    },
  );

  /**
   * @description Custom/Common events
   */
  addListener(
    {
      actionType: COMMON_TRACK_EVENT,
    },
    ({ payload }, getState) => dataLayerCustomEvent(payload, getState()),
  );

  /**
   * Track Video events
   */
  addListener(
    {
      actionType: TRACK_VIDEO_EVENT,
    },
    ({ payload: { eventType, playerType, video }, meta }, getState) => {
      const category = !meta.category
        ? getCategoryBasedOnPath() ||
          window.location.pathname
            .substr(1)
            .replace(/\s/, '-')
            .toLowerCase()
        : meta.category;

      dataLayerCustomEvent(
        {
          ...meta,
          category,
          contentView: category,
          ...(eventType ? { [DataLayerKeys.EVENT_TYPE]: eventType } : {}),
          ...(playerType ? { [DataLayerKeys.PLAYER_TYPE]: playerType } : {}),
          video,
        },
        getState(),
      );
    },
  );

  /**
   * User opens the main menu
   */
  addListener(
    {
      actionType: HEADING_MAIN_MENU_TOGGLE,
    },
    ({}, getState) => {
      const state = getState();

      // This is the state before the action takes place. When showMenu is false, the menu will be opened and we need to track it.
      if (!state.view.components.header?.showMenu) {
        dataLayerCustomEvent(
          {
            category: Category.ALL_PAGES,
            [DataLayerKeys.OPEN_NAVIGATION]: 1,
          },
          state,
        );
      }
    },
  );
};
