const Size = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LS: 'ls',
  LG: 'lg',
  XL: 'xl',
  XXL: 'xxl',
};

export default Size;
