import PropTypes from 'prop-types';
import React from 'react';
import TextNew from '../../../../../../TextNew';
import LocaleMessage from '../../../../../../LocaleMessage';
import Select from '../../../../../../Select';
import InputNew from '../../../../atoms/InputNew';
import WeightInputLabel from '../WeightInputLabel';

const halfPoundOptions = [
  {
    title: '0',
    value: 0,
  },
  {
    title: '½',
    value: 0.5,
  },
];

const WeightInputImperial = ({ abbreviate, unitsToDisplay, disabled, values, ...restProps }) => {
  const label = weightUnit =>
    `general.measureUnit.weight.${weightUnit}.${abbreviate ? 'labelAbbreviation' : 'label'}`;

  return (
    <>
      {unitsToDisplay.includes('stone') && (
        <>
          <InputNew
            id="targetWeight-stones"
            data-testid="targetWeight-stones"
            descriptor="static"
            name="stones"
            type="number"
            min="0"
            max="50"
            label={
              <>
                <span aria-hidden="true">
                  <WeightInputLabel weightUnit="stone" abbreviate={abbreviate} />
                </span>
                <span className="screen-reader">
                  <WeightInputLabel weightUnit="stone" abbreviate={true} />
                </span>
              </>
            }
            placeholder="00"
            value={values?.stones || ''}
            disabled={disabled}
            {...restProps}
          />
          <TextNew.Formal ariaHidden cid="dot">
            .
          </TextNew.Formal>
        </>
      )}
      <InputNew
        id="targetWeight-fullPounds"
        data-testid="targetWeight-fullPounds"
        descriptor="static"
        name="fullPounds"
        type="number"
        value={values?.fullPounds || ''}
        max="13"
        placeholder="00"
        label={
          <>
            <span aria-hidden="true">
              <WeightInputLabel weightUnit="pounds" abbreviate={abbreviate} />
            </span>
            <span className="screen-reader">
              <WeightInputLabel weightUnit="pounds" abbreviate={true} />
            </span>
          </>
        }
        disabled={disabled}
        {...restProps}
      />
      <label className="screen-reader" htmlFor="targetWeight-remainingPounds">
        <LocaleMessage id="general.measureUnit.weight.pounds.labelHalfPound" />
      </label>
      <Select
        id="targetWeight-remainingPounds"
        data-testid="targetWeight-remainingPounds"
        value={`${values?.remainingPounds}`}
        name="remainingPounds"
        options={halfPoundOptions}
        input={restProps}
      />
    </>
  );
};

WeightInputImperial.defaultProps = {
  unitsToDisplay: ['stone', 'pounds'],
  abbreviate: true,
};

WeightInputImperial.propTypes = {
  abbreviate: PropTypes.bool,
  disabled: PropTypes.bool,
  unitsToDisplay: PropTypes.array,
};

export default WeightInputImperial;
