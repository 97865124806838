import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';
import AdvertiseWithUs from '../../molecules/AdvertiseWithUs';

import AdvertiseWithUsPage from './AdvertiseWithUsPage';

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(prepareComponent(AdvertiseWithUs)),
});

export default addInitAction(AdvertiseWithUsPage);
