import React, { useEffect, useState, useCallback } from 'react';
import { compose } from 'redux';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import PropTypes from 'prop-types';
import debugLib from 'debug';
import debounce from 'lodash/debounce';
import Loader from 'components/atoms/Loader';
import ManualSeo from 'components/atoms/ManualSeo';
import DomEventObserver, { EventListenerTypes } from 'common/src/app/hoc/DomEventObservers';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import uniqWith from 'lodash/uniqWith';
import errorBoundary from 'components/hoc/ErrorBoundary';
import Wrapper from 'components/atoms/Wrapper';

// New filters
import classNames from 'classnames';
import SearchFilterBar from 'components/organisms/SearchFilterBar';
import GroupSearchFieldNames from 'common/src/app/data/enum/FieldNames/GroupSearchFieldNames';
import GroupSearchFilter from './components/molecules/GroupSearchFilter';

import NearestGroupLandingHeader from './components/organisms/NearestGroupLandingHeader';
import EmptySearchQuery from './components/organisms/EmptySearchQuery';
import PhysicalGroupsLanding from './components/organisms/PhysicalGroupsLanding';
import EmptySearchResults from './components/organisms/EmptySearchResults';

import GroupDirectory from '../../../components/organisms/GroupDirectory';
import './nearest-group-landing.scss';

export const getGroupedLocations = results => {
  const debug = debugLib('SlimmingWorld:PublicGroupSearch');

  // check for lat and long
  const missingLatLongGroups = results?.filter?.(group => !group.venue.location);

  if (missingLatLongGroups?.length > 0) {
    debug('groups with missing LatLong (not displayed)', missingLatLongGroups);
  }

  const uniqueLocations = uniqWith(
    results?.filter?.(group => group.venue.location).map(group => group.venue.location),
    (left, right) => left?.lat === right?.lat && left?.lng === right?.lng,
  );

  // Todo SWPUB-942 use more readable variable names not letters
  return uniqueLocations.map((location, index) => ({
    index: index + 1,
    location,
    groups: results
      .filter(group => group)
      .filter(
        group =>
          group?.venue?.location?.lat === location.lat &&
          group?.venue?.location?.lng === location.lng,
      ),
  }));
};

const NearestGroupLanding = (
  {
    location: {
      query: { query, days, times, venueAccessibility, lat, lng },
    },
    isLoading,
    isInitializing,
    results,
    bulletinMessage,
    searchResultsMessage,
    isCollapsed,
    setFieldValue,
    handleReset,
    submitForm,
    handleChange,
    handleSubmit,
    values,
  },
  { getMessage },
  className,
  dataTestid,
) => {
  const hasSearched = !!query || (!!lat && !!lng);
  const hasResults = hasSearched && results.length > 0;
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const toggle = useCallback(() => setToggleSidebar(!toggleSidebar), [
    toggleSidebar,
    setToggleSidebar,
  ]);
  const hasFiltered = !!days || !!times || !!venueAccessibility;
  const shouldShowIntro = hasFiltered || (hasSearched && hasResults);
  const shouldShowNoSearchQuery = !hasSearched;
  const shouldShowNoResults = hasSearched && !hasResults && !isLoading && !isInitializing;
  const shouldShowResults = hasSearched && hasResults;

  const debouncedSubmit = useCallback(
    debounce(() => submitForm(), 300),
    [debounce, submitForm],
  );

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, values]);

  const clearFilters = () => {
    handleReset();
    setFieldValue(GroupSearchFieldNames.DAYS, []);
    setFieldValue(GroupSearchFieldNames.TIMES, []);
    setFieldValue(GroupSearchFieldNames.VENUE_ACCESSIBILITY, []);
  };

  const totalFilterCount =
    values?.[GroupSearchFieldNames.DAYS]?.length +
      values?.[GroupSearchFieldNames.TIMES]?.length +
      values?.[GroupSearchFieldNames.VENUE_ACCESSIBILITY]?.length ?? 0;

  return (
    <div
      className={classNames(className, { 'is-collapsed': !toggleSidebar })}
      data-testid={dataTestid}
    >
      <NearestGroupLandingHeader
        subText={searchResultsMessage?.text}
        shouldShowIntro={shouldShowIntro}
        bulletin={bulletinMessage}
      />
      {shouldShowNoSearchQuery && (
        <>
          <EmptySearchQuery />
          <PhysicalGroupsLanding />
        </>
      )}
      {(isLoading || isInitializing) && <Loader />}
      {shouldShowNoResults &&
        (hasFiltered ? (
          <>
            <section
              className={classNames('search-results', {
                'is-collapsed': isCollapsed,
                toggleSidebar,
              })}
            >
              <form className="page-filter-container">
                <GroupSearchFilter
                  values={values}
                  toggle={toggle}
                  handleChange={handleChange}
                  getMessage={getMessage}
                />
                <Wrapper.XL padding="md" className="main-content">
                  <SearchFilterBar
                    toggleCollapse={toggle}
                    sort={false}
                    totalResults={results?.length || 0}
                    totalFilters={totalFilterCount}
                    clearFilters={clearFilters}
                  />
                  <EmptySearchResults hasFilter={hasFiltered} />
                </Wrapper.XL>
              </form>
            </section>
          </>
        ) : (
          <EmptySearchResults hasFilter={hasFiltered} />
        ))}
      {shouldShowResults && (
        <>
          <section
            className={classNames('search-results', {
              'is-collapsed': isCollapsed,
              'toggle-sidebar': toggleSidebar,
            })}
          >
            <form onSubmit={handleSubmit} className="page-filter-container">
              <GroupSearchFilter
                values={values}
                toggle={toggle}
                handleChange={handleChange}
                getMessage={getMessage}
              />

              <Wrapper.XL padding="md" className="main-content">
                <SearchFilterBar
                  toggleCollapse={toggle}
                  sort={false}
                  totalResults={results?.length || 0}
                  totalFilters={totalFilterCount}
                  clearFilters={clearFilters}
                />
                <>
                  <DomEventObserver event={EventListenerTypes.SCROLL}>
                    <GroupDirectory groups={results} />
                  </DomEventObserver>
                </>
              </Wrapper.XL>
            </form>
          </section>
        </>
      )}
      <ManualSeo metaKeywords="slimming world groups,slimming world near me,slimming world group near me" />
    </div>
  );
};

NearestGroupLanding.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

NearestGroupLanding.defaultProps = {
  geoLocationFromSearch: { lat: 53.090528, lng: -1.350232 },
  results: [],
};

NearestGroupLanding.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

NearestGroupLanding.propTypes = {
  results: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  location: PropTypes.object,
  isLoading: PropTypes.bool,
  isInitializing: PropTypes.bool,
  bulletinMessage: PropTypes.PropTypes.string,
  searchResultsMessage: PropTypes.string,
  isCollapsed: PropTypes.bool,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  submitForm: PropTypes.func,
  handleReset: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.TEMPLATE, 'NearestGroupLanding'),
)(NearestGroupLanding);
