/* eslint-disable import/prefer-default-export */
import { push as historyPush, goBack as historyGoBack } from 'react-router-redux';

/**
 * Action to be executed when the BackLink component is clicked. Will look up the most recent
 * route in the browser history for this session that does not start with the current pathname.
 *  - if this is the previous route, a history back will be executed
 *  - if this is an older history entry, we will do a history push to that entry
 *  - if no such history was found (probably due to the user landing on a deeplink) we will check
 *  the referrer url.
 *     - if the url appears to be from a SlimmingWorld related site, perform a history back
 *     - otherwise, redirect to the root route ('/')
 */
export const navigateBackLink = () => (dispatch, getState) => {
  const {
    routeHistory: [currentRoute, ...routeHistory],
  } = getState();

  // find the first history entry that does not start with the current path
  const returnHistoryIndex = routeHistory.findIndex(
    history => !history.pathname.startsWith(currentRoute.pathname),
  );

  if (returnHistoryIndex < 0) {
    // only do a history back if we come from an internal referrer
    // otherwise redirect to the homepage to prevent the back button from going to an external site
    const fromExternal = ['swlabs', 'slimmingworld'].every(ref => !document.referrer.includes(ref));

    if (fromExternal) {
      return dispatch(historyPush('/'));
    }
    return dispatch(historyGoBack());
  }

  if (!returnHistoryIndex) {
    // return to previous route (equivalent to browser back)
    return dispatch(historyGoBack());
  }

  const { pathname, query, hash } = routeHistory[returnHistoryIndex];
  return dispatch(historyPush({ pathname, query, hash }));
};
