import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import InputType from 'common/src/app/data/enum/InputType';
import classNames from 'classnames';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import IconName from 'common/src/app/data/enum/IconName';
import { isIEOrEdge } from 'common/src/app/util/browserUtil';
import { Category, DataLayerKeys } from 'common/src/app/data/enum/Tracking';

import Image from '../../atoms/Image';
import Icon from '../../atoms/Icon';
import DomEventTracker from '../../atoms/DomEventTracker';

import './image-flip.scss';

class ImageFlip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlipped: false,
    };
  }

  toggleActive = () => {
    this.setState({
      isFlipped: !this.state.isFlipped,
    });
  };

  render() {
    const { image, revealImage, link, title, person, ratio, ...props } = this.props;
    const { isFlipped } = this.state;
    const { getMessage } = this.context;

    return (
      <div
        {...componentClassNameProp(ComponentType.ORGANISM, this, null, isFlipped && 'is-flipped')}
      >
        <div
          className={classNames('flipper', {
            'preserve-3d': isIEOrEdge(),
          })}
        >
          <div className="front">
            <Link tabIndex="-1" aria-hidden="true" to={link}>
              {image && <Image ratio={ratio} {...image} {...props} alt={title} />}
            </Link>
          </div>
          <div className="back">
            <Link tabIndex="-1" aria-hidden="true" to={link}>
              {revealImage && <Image ratio={ratio} {...revealImage} {...props} alt={title} />}
            </Link>
          </div>
        </div>
        <DomEventTracker
          meta={{
            category: Category.SUCCESS_STORIES,
            [DataLayerKeys.NAME_SUCCESS_STORIES]: title,
            [DataLayerKeys.CONTENT_GROUP_NAME]: Category.SUCCESS_STORIES,
            [DataLayerKeys.BEFORE_AFTER]: 1,
          }}
        >
          <button
            type={InputType.BUTTON}
            onClick={this.toggleActive}
            aria-label={
              person &&
              getMessage('imageFlip.label.text', {
                TYPE: getMessage(`imageFlip.label.${isFlipped ? 'before' : 'after'}`),
                PERSON: person,
              })
            }
          >
            <Icon name={IconName.BEFORE} />
          </button>
        </DomEventTracker>
      </div>
    );
  }
}

ImageFlip.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

ImageFlip.propTypes = {
  title: PropTypes.string,
  person: PropTypes.string,
  image: PropTypes.shape({
    ...imageShape,
  }),
  revealImage: PropTypes.shape({
    ...imageShape,
  }),
  link: PropTypes.string,
  ratio: PropTypes.number,
};

export default ImageFlip;
