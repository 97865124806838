import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import UserTesting from 'bundle-loader?lazy&reactHot&name=UserTesting!./index';

const routes = (
  <Route path={Pages.UK_PUBLIC_USER_TESTING} {...getRouteComponentProp(UserTesting)} />
);

export default routes;
