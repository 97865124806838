import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ContactUsFieldNames from 'common/src/app/data/enum/FieldNames/ContactUsFieldNames';
import FormikError from '../FormikError';
import TextNew, { FontWeight } from '../TextNew';
import CheckBox from '../../molecules/CheckBox';

const ContactUsIcelandConfirm = ({ handleChange, value }) => (
  <Fragment>
    <CheckBox
      dataTestid="contact-us-iceland-confirm"
      input={{
        onChange: handleChange,
        name: 'icelandTerms',
        checked: value,
      }}
    >
      <TextNew.Sans.SM weight={FontWeight.LIGHT} localeId="contactUsIcelandConfirm.checkbox" />
    </CheckBox>
    <FormikError name={ContactUsFieldNames.ICELAND_TERMS} />
    <TextNew.Sans.SM
      cid="iceland-disclaimer"
      weight={FontWeight.LIGHT}
      localeId="contactUsIcelandConfirm.disclaimer"
    />
  </Fragment>
);

ContactUsIcelandConfirm.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.bool,
};

export default ContactUsIcelandConfirm;
