import { connect } from 'react-redux';
import { withFormik } from 'formik';
import compose from 'redux/lib/compose';
import { object, string } from 'yup';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import {
  GET_STATIC_CONTENT,
  getStaticContent,
} from 'common/src/app/actions/resources/staticContentActions';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import FormNames from 'common/src/app/data/enum/FormNames';
import { measurementFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { calculatePublicBmi } from 'common/src/app/actions/resources/publicActions';
import {
  bmiSelector,
  contentComponentSelector,
  staticPageSelector,
} from 'common/src/app/selectors/publicSelectors';
import { API_COMPONENT_PUBLIC_HEADER_BMI_PAGE } from 'common/src/app/data/apiComponents';
import { API_STATIC_PUBLIC_BMI_CALCULATOR_PAGE } from 'common/src/app/data/apiStatics';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import BmiCalculator from './BmiCalculator';

const bmiCheckValidation = object().shape({
  [measurementFields.HEIGHT]: string()
    .required('validation.errors.height.required')
    .nullable(),
  [measurementFields.INITIAL_WEIGHT]: string()
    .required('validation.errors.initialWeight.required')
    .nullable(),
});
const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT);

const enhanced = withFormik({
  displayName: FormNames.PUBLIC_BMI_CALCULATOR,
  mapPropsToValues: () => ({
    [measurementFields.HEIGHT]: null,
    [measurementFields.INITIAL_WEIGHT]: null,
    weightUnit: MeasureUnit.IMPERIAL,
    heightUnit: MeasureUnit.IMPERIAL,
  }),
  validationSchema: bmiCheckValidation,
  handleSubmit: (values, { props }) => props.calculatePublicBmiAction(values),
});

const mapStateToProps = state => ({
  pageData: staticPageSelector(state, API_STATIC_PUBLIC_BMI_CALCULATOR_PAGE),
  loading: pageLoading(state),
  bmiValue: bmiSelector(state),
  headerImage: contentComponentSelector(state, API_COMPONENT_PUBLIC_HEADER_BMI_PAGE, 'image'),
});

const mapDispatchToProps = dispatch => ({
  calculatePublicBmiAction: values => dispatch(calculatePublicBmi(values)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(getStaticContent(API_STATIC_PUBLIC_BMI_CALCULATOR_PAGE, true)),
      dispatch(getComponent(API_COMPONENT_PUBLIC_HEADER_BMI_PAGE)),
    ]),
});

export default compose(addInitAction, connected, enhanced)(BmiCalculator);
