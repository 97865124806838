import { dataLayerCustomEvent } from '../../app/util/tracking/dataLayerUtils';
import { Category, DataLayerKeys } from '../../app/data/enum/Tracking';
import { isFulfilled } from '../../app/util/actionFilters';
import { SEVEN_DAY_MENU_SIGNUP } from '../../app/actions/resources/publicActions';

export default addListener => {
  addListener(
    {
      actionType: SEVEN_DAY_MENU_SIGNUP,
      filter: [isFulfilled],
    },
    (_, getState) => {
      dataLayerCustomEvent(
        {
          category: Category.SEVEN_DAY_MENU_SIGNUP,
          [DataLayerKeys.MENU_SIGNUP]: 1,
        },
        getState(),
      );
    },
  );
};
