import handleActions from 'redux-actions/lib/handleActions';
import { STORE_API_LOADED, GEOLOCATION_DATA } from '../actions/externalApiActions';

const externalApis = handleActions(
  {
    [STORE_API_LOADED]: (state, { payload }) => ({
      ...state,
      [payload]: true,
    }),
    [GEOLOCATION_DATA]: (state, { payload }) => ({
      ...state,
      [payload.name]: payload.geoData,
    }),
  },
  {},
);

export default externalApis;
