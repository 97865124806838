import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Pages from 'common/src/app/data/enum/Pages';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import TextNew, { Colors, AccentColors, ElementTypes, FontWeight } from 'components/atoms/TextNew';
import LandingGrid from 'components/templates/LandingGrid';
import Loader from 'components/atoms/Loader';
import GroupOnlineSignUp from 'components/molecules/GroupOnlineSignUp';
import SuccessStoryFilters from 'components/molecules/SuccessStoryFilters';
import Promotion from 'components/organisms/Promotion';
import landingGridProps from 'components/templates/LandingGrid/landingGridProps';
import errorBoundary from 'components/hoc/ErrorBoundary';

// Shapes
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import successStoriesShape from 'common/src/app/util/proptypes/apiEntities/successStories';

import NewsletterSignUp from '../../../../../molecules/NewsletterSignUp';

import './success-stories-landing.scss';

const SuccessStoriesLanding = (
  {
    gridContents,
    loadMore,
    isInitializing,
    featuredStory,
    featuredStories,
    headerImage,
    genderFromPath,
    hasFiltered,
    pathname,
    icelandBackground,
    deviceState,
  },
  context,
  className,
  dataTestid,
) => {
  if (isInitializing) {
    return (
      <div className={className}>
        <Loader />
      </div>
    );
  }

  const genders = {
    [Pages.UK_PUBLIC_SUCCESS_STORIES_FEMALE_FILTER]: 'female',
    [Pages.UK_PUBLIC_SUCCESS_STORIES_MALE_FILTER]: 'male',
  };

  const genderLocale = {
    male: 'successStoriesLanding.heading.male',
    female: 'successStoriesLanding.heading.female',
    undefined: 'successStoriesLanding.heading.landing',
  };

  const gender = genders[pathname];

  return (
    <div className={className} data-testid={dataTestid} data-gender={gender}>
      <HeroBarPublic image={headerImage}>
        <TextNew.Serif.MD
          cid="hero-bar-public-paragraph"
          localeId="successStoriesLanding.heading.p1"
          weight={FontWeight.BOLD}
        />
        <TextNew.Script.SM
          color={Colors.BRAND}
          element={ElementTypes.H1}
          localeId={genderLocale[gender]}
          cid="hero-bar-public-script-heading"
        />
        <TextNew.Serif.XS
          localeId="successStoriesLanding.heading.introduction"
          cid="hero-bar-public-introduction"
        />
      </HeroBarPublic>

      <SuccessStoryFilters />
      <LandingGrid
        {...gridContents}
        load={() => loadMore(featuredStories, featuredStory, genderFromPath)}
        hideSortControls
        tileColors={{
          featured: AccentColors.ACCENT_BLUE,
        }}
        featuredTile={(!hasFiltered && featuredStory) || undefined}
        featuredItems={!hasFiltered ? featuredStories : []}
      />

      <div className="additional-content">
        <Promotion icelandBackground={icelandBackground} deviceState={deviceState} />
        <GroupOnlineSignUp />
        <NewsletterSignUp />
      </div>
    </div>
  );
};
SuccessStoriesLanding.propTypes = {
  headerImage: PropTypes.shape({
    ...imageShape,
  }),
  gridContents: landingGridProps,
  isInitializing: PropTypes.bool,
  featuredStory: PropTypes.shape(successStoriesShape),
  featuredStories: PropTypes.arrayOf(PropTypes.shape(successStoriesShape)),
  loadMore: PropTypes.func,
  genderFromPath: PropTypes.shape({
    gender: PropTypes.string,
  }),
  hasFiltered: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  icelandBackground: PropTypes.object,
  deviceState: PropTypes.number,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName(ComponentType.PAGE, 'SuccessStoriesLanding'),
)(SuccessStoriesLanding);
