import createAction from 'redux-actions/lib/createAction';
import { push as historyPush } from 'react-router-redux';
import geoLocation from '../../util/geoLocationUtils/geoLocationUtils';
import Pages from '../../data/enum/Pages';

export const STORE_LAT_LNG_ERROR = 'geoActions/STORE_LAT_LNG_ERROR';
export const storeLatLngError = createAction(STORE_LAT_LNG_ERROR);

export const STORE_LAT_LNG = 'geoActions/STORE_LAT_LNG';
const storeLatLng = createAction(STORE_LAT_LNG);

export const setGeoLocation = ({ external, publicHost }) => async dispatch => {
  const { lat, lng, error } = await geoLocation();

  if (!lat && !lng) {
    return dispatch(storeLatLngError({ error }));
  }
  // Reset any prior errors
  await dispatch(storeLatLngError({ error: null }));

  const pagePath = `${Pages.UK_PUBLIC_NEAREST_GROUP_LANDING}?lat=${lat}&lng=${lng}`;
  // Push the user to the nearest group landing with the lat lng as params
  typeof window !== 'undefined' && external
    ? (window.location.href = `${publicHost}${pagePath}`)
    : await dispatch(historyPush(pagePath));

  // Store lat lng in state
  dispatch(storeLatLng({ lat, lng }));
  return { lat, lng };
};
