import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import {
  API_STATIC_PUBLIC_SEVEN_DAY_ENTRY,
  API_STATIC_PUBLIC_SEVEN_DAY_MENU,
} from 'common/src/app/data/apiStatics';

import compose from 'redux/lib/compose';
import SevenDayMenu from './SevenDayMenu';

const mapStateToProps = (state, { params }) => {
  const showMenu = ['meat', 'vegetarian', 'vegan'].includes(params?.menuType);

  return {
    page: staticPageSelector(
      state,
      showMenu
        ? `${API_STATIC_PUBLIC_SEVEN_DAY_MENU}${params?.menuType}`
        : API_STATIC_PUBLIC_SEVEN_DAY_ENTRY,
    ),
  };
};

const connected = connect(mapStateToProps);

const addInitAction = withInitAction(['params'], ({ params }, dispatch) => {
  const showMenu = ['meat', 'vegetarian', 'vegan'].includes(params?.menuType);
  const promise = [];
  if (showMenu) {
    promise.push(
      dispatch(getStaticContent(`${API_STATIC_PUBLIC_SEVEN_DAY_MENU}${params?.menuType}`, true)),
    );
  } else {
    promise.push(dispatch(getStaticContent(API_STATIC_PUBLIC_SEVEN_DAY_ENTRY, true)));
  }

  return Promise.all(promise);
})('SevenDayMenu');

export default compose(addInitAction, connected)(SevenDayMenu);
