import { createSelector } from 'reselect';
import { stringToUrl } from '../util/urlUtils';
import groupByFirstChar from '../util/groupByFirstChar';
import Pages from '../data/enum/Pages';
import { createPath } from '../util/routeUtils';

export const urlReadyPlaceSelector = places => buildPlace => {
  const p = groupByFirstChar(places);
  return Object.values(p)
    .map((names, k) => ({ [Object.keys(p)[k]]: names.map(buildPlace) }))
    .reduce((acc, x) => Object.assign(acc, x), {});
};

export const nearestGroupResultsSelector = state => state.view.nearestGroup.searchResults;

const nearestGroupCountiesSelecttion = state =>
  state.view.nearestGroup.counties &&
  urlReadyPlaceSelector(state.view.nearestGroup.counties)(name => ({
    name,
    url: stringToUrl(name),
    link: createPath(Pages.UK_PUBLIC_NEAREST_GROUP_GROUPS_IN_TOWNS, {
      county: stringToUrl(name),
      town: '',
    }),
  }));

export const nearestGroupCountiesSelector = createSelector(nearestGroupCountiesSelecttion, e => e);

const nearestGroupTownsFromState = state => countyName => {
  const towns = state.view.nearestGroup.towns;
  if (towns && towns.length === 1 && towns[0].toLowerCase() === countyName.toLowerCase()) {
    // if we only have one town, witht he same name as the county
    // redirect to the towns page
    return 'redirectToTowns';
  }
  return (
    towns &&
    urlReadyPlaceSelector(towns)(
      name =>
        name && {
          name,
          url: stringToUrl(name),
          link: `${createPath(Pages.UK_PUBLIC_NEAREST_GROUP_GROUPS_IN_TOWNS, {
            county: stringToUrl(countyName),
            town: '',
          })}${stringToUrl(name)}`, // HACK: createPath doesn't replace all the params in the query below. This needs a TODO.
        },
    )
  );
};

export const nearestGroupTownsSelector = createSelector(nearestGroupTownsFromState, e => e);

export const createAllTownsSelector = createSelector(
  state => state.view.nearestGroup?.allTowns,
  t => t,
);
