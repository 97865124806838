import React from 'react';
import Link from 'react-router/lib/Link';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, { Colors, FontWeight, ElementTypes } from 'components/atoms/TextNew';
import Pages from 'common/src/app/data/enum/Pages';
import getPublicPrices from 'common/src/app/util/getPublicPrices';
import './free-book-offer-roundel.scss';

const FreeBookOfferRoundel = ({ book }, context, className, dataTestid) => (
  <Link to={Pages.UK_PUBLIC_OFFERS} className={className} data-testid={dataTestid}>
    <TextNew.Serif.LG
      cid="book-free"
      color={Colors.LIGHT_TONE}
      localeId="freeBookOfferRoundel.free"
    />
    <TextNew.Serif.MD
      cid="book-cookbook"
      color={Colors.LIGHT_TONE}
      localeId="freeBookOfferRoundel.book"
      localeParams={{ BOOK: book }}
    />
    <div className="worth-price">
      <TextNew.Serif.XS
        cid="book-worth"
        color={Colors.LIGHT_TONE}
        weight={FontWeight.LIGHT}
        localeId="freeBookOfferRoundel.worth"
      />

      <TextNew.Serif.SM
        cid="book-price"
        color={Colors.LIGHT_TONE}
        localeId="freeBookOfferRoundel.price"
        element={ElementTypes.SPAN}
        localeParams={{
          PRICE: getPublicPrices('summerBookOffer', 'price'),
        }}
      />
    </div>
    <TextNew.Sans.SM
      cid="book-more-details"
      weight={FontWeight.LIGHT}
      color={Colors.LIGHT_TONE}
      localeId="freeBookOfferRoundel.moreDetails"
    />
  </Link>
);

FreeBookOfferRoundel.propTypes = {
  book: PropTypes.string,
};

FreeBookOfferRoundel.defaultProps = {
  book: 'cookbook',
};

export default withFunctionalClassName(
  ComponentType.ATOM,
  'FreeBookOfferRoundel',
)(FreeBookOfferRoundel);
