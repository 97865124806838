import ImageRefs from './ImageRefs';
import Breakpoints from './Breakpoints';

export default [
  { name: ImageRefs.ULTRA_WIDE, breakpoint: Breakpoints[ImageRefs.ULTRA_WIDE] },
  { name: ImageRefs.DESKTOP, breakpoint: Breakpoints[ImageRefs.DESKTOP] },
  { name: ImageRefs.SMALL_DESKTOP, breakpoint: Breakpoints[ImageRefs.SMALL_DESKTOP] },
  { name: ImageRefs.TABLET, breakpoint: Breakpoints[ImageRefs.TABLET] },
  { name: ImageRefs.LARGE_MOBILE, breakpoint: Breakpoints[ImageRefs.LARGE_MOBILE] },
  { name: ImageRefs.SMALL_MOBILE, breakpoint: Breakpoints[ImageRefs.SMALL_MOBILE] },
];
