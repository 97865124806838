// these numbers are expected from the API.
// use '0' for general
import fromPairs from 'lodash/fromPairs';

export const ContactFormSubjectIds = {
  GROUP_INFORMATION: 'id-7',
  GROUP_CUSTOMER_SERVICES: 'id-8',
  SW_ICELAND: 'id-11',
  HI_FI_BARS: 'id-10',
  CONSULTANT_RECRUITMENT: 'id-4',
  EDITORIAL: 'id-13',
  ADVERTISEMENT_ARTWORK: 'id-17',
  MEDIA_ENQUIRIES: 'id-23',
  ONLINE_TECHNICAL_HELP: 'id-1',
};

export const ContactFormSubjectCategory = {
  [ContactFormSubjectIds.GROUP_INFORMATION]: 'groupInformation',
  [ContactFormSubjectIds.GROUP_CUSTOMER_SERVICES]: 'groupCustomerServices',
  [ContactFormSubjectIds.SW_ICELAND]: 'swIceland',
  [ContactFormSubjectIds.HI_FI_BARS]: 'hiFiBars',
  [ContactFormSubjectIds.CONSULTANT_RECRUITMENT]: 'consultantRecruitment',
  [ContactFormSubjectIds.EDITORIAL]: 'editorial',
  [ContactFormSubjectIds.ADVERTISEMENT_ARTWORK]: 'advertisementRequest',
  [ContactFormSubjectIds.MEDIA_ENQUIRIES]: 'mediaEnquiries',
  [ContactFormSubjectIds.ONLINE_TECHNICAL_HELP]: 'onlineTechnicalHelp',
};

const toCategories = (names = []) =>
  fromPairs(
    names.map(name => [
      ContactFormSubjectIds[name],
      ContactFormSubjectCategory[ContactFormSubjectIds[name]],
    ]),
  );

export const ContactFormCategory = {
  public: toCategories([
    'GROUP_INFORMATION',
    'GROUP_CUSTOMER_SERVICES',
    'ONLINE_TECHNICAL_HELP',
    'SW_ICELAND',
    'HI_FI_BARS',
    'CONSULTANT_RECRUITMENT',
    'EDITORIAL',
    'ADVERTISEMENT_ARTWORK',
    'MEDIA_ENQUIRIES',
  ]),
};

export const getSubjectOptions = ({ getMessage }) =>
  Object.keys(ContactFormCategory.public).reduce(
    (subjects, key) => [
      ...subjects,
      {
        value: key,
        title: getDropdownLabel(getMessage, key),
      },
    ],
    [],
  );

const getDropdownLabel = (getMessage, key) =>
  getMessage(`contactUsForm.categories.${ContactFormCategory.public[key]}`);

export default ContactFormCategory;
