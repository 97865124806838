import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import EnhancedFormField from 'common/src/app/formik/components/EnhancedFormField';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { handleFieldGroupInput } from 'common/src/app/util/form/inputEventUtil';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Row from 'components/atoms/Row';
import InputType from 'common/src/app/data/enum/InputType';
import { InputNew, descriptorTypes } from 'components/atoms/Form';
import TextNew from 'components/atoms/TextNew';
import { composeHeightMetric, decomposeHeightMetric } from 'common/src/app/util/heightUtils';
import './height-metric-field.scss';

const METERS_LENGTH = 1;
const CENTIMETERS_LENGTH = 2;

const HeightMetricField = (
  {
    name,
    abbreviate,
    disabled,
    formik: { values, isSubmitting, setFieldValue, setFieldTouched, setValues },
  },
  { getMessage },
  className,
) => {
  const { metres: metresValue, centimeters: centimetersValue, height } = values;

  useEffect(() => {
    if (height) {
      const { metres, centimeters } = decomposeHeightMetric(height);
      setValues({ ...values, metres, centimeters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!metresValue) {
      setFieldValue(name, null);
    }

    if (metresValue || centimetersValue) {
      const composedValue = composeHeightMetric({
        metres: metresValue,
        centimeters: centimetersValue,
      });

      setFieldValue(name, composedValue);
    }
  }, [metresValue, centimetersValue, height, isSubmitting, setFieldValue, name]);

  const setValue = (fieldName, value) => {
    setFieldValue(fieldName, value, false);

    if (!metresValue || !centimetersValue) {
      requestAnimationFrame(() => setFieldTouched(name, true, false));
    }
  };

  return (
    <Row className={className}>
      <EnhancedFormField
        data-testid={`${getMessage('general.measureUnit.height.meters.label')}-input`}
        component={InputNew}
        name={getMessage('general.measureUnit.height.meters.label')}
        type={InputType.NUMBER}
        min="0"
        max="2"
        aria-label={getMessage('general.measureUnit.height.meters.label')}
        placeholder="0"
        label=""
        descriptor={descriptorTypes.STATIC}
        onKeyPress={event => handleFieldGroupInput(event, METERS_LENGTH)}
        onBlur={e =>
          setValue(getMessage('general.measureUnit.height.meters.label'), e.target.value)
        }
        disabled={disabled}
      />
      <TextNew.Formal>.</TextNew.Formal>
      <EnhancedFormField
        data-testid={`${getMessage('general.measureUnit.height.centimeters.label')}-input`}
        component={InputNew}
        name={getMessage('general.measureUnit.height.centimeters.label')}
        type={InputType.NUMBER}
        min="0"
        max="99"
        aria-label={getMessage('general.measureUnit.height.centimeters.label')}
        placeholder="00"
        label={
          <LocaleMessage
            id={`general.measureUnit.height.meters.${abbreviate ? 'labelAbbreviation' : 'label'}`}
          />
        }
        descriptor={descriptorTypes.STATIC}
        onKeyPress={event => handleFieldGroupInput(event, CENTIMETERS_LENGTH)}
        onBlur={e =>
          setValue(getMessage('general.measureUnit.height.centimeters.label'), e.target.value)
        }
        disabled={disabled}
      />
    </Row>
  );
};

HeightMetricField.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

HeightMetricField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  abbreviate: PropTypes.bool,
  formik: PropTypes.object,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'HeightMetricField',
)(HeightMetricField);
