import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import './label.scss';

/**
 * The <label> tag defines a label for an <input> element.
 */

const Label = ({ text, htmlFor, ...props }) => (
  <label
    htmlFor={htmlFor}
    {...props}
    className={functionalComponentClassName(ComponentType.ATOM, 'Label')}
  >
    {text}
  </label>
);

Label.propTypes = {
  /**
   * Label text
   */
  text: PropTypes.node,
  /**
   * Label html for
   */
  htmlFor: PropTypes.string,
};

export default Label;
