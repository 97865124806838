/**
 * Define your media queries here.
 */
export const mediaQueries = {
  XS: '(max-width: 360px)',
  SM: '(max-width: 578px)', // triggered from 361 +
  MD: '(max-width: 767px)', // triggered from 579 +
  LG: '(max-width: 1024px)', // triggered from 768 +
  XL: '(max-width: 1280px)', // triggered from 1025 +
  XXL: '(max-width: 1440px)', // triggered from 1281 +
  XXXL: '(max-width: 1600px)', // triggered from 1441 +
  XXXXL: '(max-width: 1900px)', // triggered from 1601 +
  XXXXXL: '(min-width: 1901px)', // triggered from 1901 +
};
/**
 * This enum is used by the DeviceStateTracker class to determine which of the media queries in
 * the mediaQueries object above are considered 'device states'. Names of this enum have to
 * correspond with one of the keys in the mediaQueries object. When using the DeviceStateTracker,
 * make sure you have enough device states so that there will always be one with a matching media
 * query.
 *
 * At any time only one "device state" will be active. This will be the last name below that has a
 * matching breakpoint. This is usually convenient for mobile-first designs. If you want to reverse
 * this order (for desktop-first designs, for example), set the reverseDeviceState boolean below
 * to 'true'.
 */
export const DeviceState = {};
DeviceState[(DeviceState.XS = 0)] = 'XS';
DeviceState[(DeviceState.SM = 1)] = 'SM';
DeviceState[(DeviceState.MD = 2)] = 'MD';
DeviceState[(DeviceState.LG = 3)] = 'LG';
DeviceState[(DeviceState.XL = 4)] = 'XL';
DeviceState[(DeviceState.XXL = 5)] = 'XXL';
DeviceState[(DeviceState.XXXL = 6)] = 'XXXL';
DeviceState[(DeviceState.XXXXL = 7)] = 'XXXXL';
DeviceState[(DeviceState.XXXXXL = 8)] = 'XXXXXL';

export const QueryHelpers = {
  MOBILE: 'isMobile',
  TABLET: 'isTablet',
  DESKTOP: 'isDesktop',
};

export const deviceStateQueryHelper = size => {
  if (size <= DeviceState.SM) {
    return QueryHelpers.MOBILE;
  }
  if (size >= DeviceState.MD && size <= DeviceState.LG) {
    return QueryHelpers.TABLET;
  }
  if (size >= DeviceState.XL) {
    return QueryHelpers.DESKTOP;
  }
  return null;
};

/**
 * The DeviceStateTracker will set the current deviceState to the last DeviceState with a
 * breakpoint that matches. This is usually desired in mobile-first designs. Set this value to
 * true if the tracker should choose the first matching DeviceState instead.
 */
export const reverseDeviceStateOrder = true;
