/* global 'molecule','AllergyInfo' */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import factSheetShape from 'common/src/app/util/proptypes/apiEntities/factSheetShape';
import IconName from 'common/src/app/data/enum/IconName';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import TextNew, { Colors } from '../../atoms/TextNew';
import Accordion from '../../atoms/Accordion';
import Icon from '../../atoms/Icon';

import './allergy-info.scss';

const AllergyInfo = ({ factSheet }, context, className) => (
  <section className={className}>
    <Accordion
      isCollapsed
      label={
        <>
          <Icon cid="icon-information" rounded width={15} name={IconName.INFORMATION} />

          <TextNew.Formal localeId="allergyInfo.toggleButtonText" color={Colors.DARK_TONE} />

          <Icon name={IconName.CHEVRON} direction={DirectionType.DOWN} />
        </>
      }
    >
      {factSheet?.pdfDisclaimer && (
        <TextNew.Formal cid="disclaimer" color={Colors.MID_TONE}>
          {factSheet.pdfDisclaimer}
        </TextNew.Formal>
      )}

      {factSheet?.factSheetLink && factSheet?.pdfLinkText && (
        <Link className="fact-sheet-link" href={factSheet.factSheetLink} target="_blank">
          <TextNew.Formal color={Colors.PRIMARY_DARK}>{factSheet.pdfLinkText}</TextNew.Formal>
        </Link>
      )}
    </Accordion>
  </section>
);

AllergyInfo.propTypes = {
  factSheet: PropTypes.shape(factSheetShape).isRequired,
};

export default withFunctionalClassName('molecule','AllergyInfo')(AllergyInfo);
