import React from 'react';
import PropTypes from 'prop-types';
import {
  textBasedGroupSearch,
  hasGeolocationGroupSearch,
} from 'common/src/app/config/market/market.configdefinitions';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import classNames from 'classnames';
import TextNew, { FontWeight, FontSizes, Colors, ElementTypes } from 'components/atoms/TextNew';
import Wrapper from '../../atoms/Wrapper';
import FreeTextGroupSearch from '../../molecules/FreeTextGroupSearch';
import DropDownGroupSearch from '../../molecules/DropDownGroupSearch';

import './nearest-group-search-bar.scss';

const NearestGroupSearchBar = ({
  hideIntro,
  deviceState,
  isEmptySearchQuery,
  geoLocationError,
  external,
  getCounties,
  countyOptions,
  resultsPositionedRelative,
  addH2Tag,
}) => {
  const className = 'organism-nearest-group-search-bar';

  return (
    <div
      className={classNames(className, {
        'results-positioned-relative': resultsPositionedRelative,
        'text-based-search': textBasedGroupSearch,
      })}
    >
      <Wrapper width="md">
        {(!hideIntro || isEmptySearchQuery) && (
          <div className="find-group-text">
            <TextNew.Sans.SM
              weight={isEmptySearchQuery ? FontWeight.NORMAL : FontWeight.LIGHT}
              localeId={`nearestGroupSearchBar.${addH2Tag ? 'longTitle' : 'title'}`}
              color={Colors.PRIMARY_LIGHT}
              size={deviceState >= DeviceState.MD ? FontSizes.SM : FontSizes.XS}
              element={addH2Tag ? ElementTypes.H2 : ElementTypes.P}
            />
          </div>
        )}

        <div className="group-search-form">
          <>
            {textBasedGroupSearch ? (
              typeof window !== 'undefined' && (
                <FreeTextGroupSearch
                  external={external}
                  resultsPositionedRelative={resultsPositionedRelative}
                  geoLocationError={geoLocationError}
                />
              )
            ) : (
              <DropDownGroupSearch
                isEmptySearchQuery={isEmptySearchQuery}
                getCounties={getCounties}
                countyOptions={countyOptions}
              />
            )}
          </>

          {hasGeolocationGroupSearch && geoLocationError && (
            <TextNew.Error
              cid="geo-location-error"
              color={Colors.ERROR}
              localeId="publicGroupSearch.errors.geoLocation.error"
            />
          )}
        </div>
      </Wrapper>
    </div>
  );
};

NearestGroupSearchBar.propTypes = {
  deviceState: PropTypes.number,
  isEmptySearchQuery: PropTypes.bool,
  countyOptions: PropTypes.arrayOf(PropTypes.string),
  getCounties: PropTypes.func,
  geoLocationError: PropTypes.object,
  external: PropTypes.bool,
  hideIntro: PropTypes.bool,
  resultsPositionedRelative: PropTypes.bool,
  // Make the intro text a H2 element
  addH2Tag: PropTypes.bool,
};

export default withDeviceState()(NearestGroupSearchBar);
