import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import classNames from 'classnames';

const NavLink = ({ children, to, href, className, currentRoute, innerRef }) => {
  /**
   * BUG
   *
   * Not sure what is happening here, activeClassName should automatically match
   * the current route - however it only works on hard load of a page so we are doing a manual check here
   */
  const isActive = path => (path === currentRoute ? 'active' : '');

  return (
    <Link
      className={classNames('nav-link', className, isActive(to))}
      to={to}
      href={href}
      innerRef={innerRef}
    >
      {children}
    </Link>
  );
};

NavLink.defaultProps = {
  externalLink: false,
};

NavLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  href: PropTypes.string,
  currentRoute: PropTypes.string.isRequired,
  className: PropTypes.string,
  innerRef: PropTypes.func,
};

export default NavLink;
