import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import currencyCode from '../data/enum/currencyCode';

/*
 * currenncy symbol Provider
 * this will return the correct currency symbol when passing in
 * a currency code attached to one of our products
 *
 */

const currencySymbolProvider = currencyCodeFromProduct => {
  const scc = currencyCodeFromProduct && currencyCodeFromProduct.toString();

  if (scc in currencyCode) {
    return currencyCode[scc];
  }
  return currencyCode[marketConfig.currencyCode];
};

export default currencySymbolProvider;
