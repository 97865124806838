import createAction from 'redux-actions/lib/createAction';

export const REGISTER_ANIMATION = 'lottieActions/REGISTER_ANIMATION';
export const registerAnimation = createAction(REGISTER_ANIMATION);

export const UNREGISTER_ANIMATION = 'lottieActions/REMOVE_ANIMATION';
export const unregisterAnimation = createAction(UNREGISTER_ANIMATION);

export const RETRIGGER_ANIMATION = 'lottieActions/RETRIGGER_ANIMATION';
export const retriggerAnimation = createAction(RETRIGGER_ANIMATION);

export const PLAY = 'lottieActions/PLAY';
export const playSegments = createAction(PLAY);
