import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import NavigationButton from 'components/atoms/NavigationButton';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { Colors, FontSizes, ElementTypes } from 'components/atoms/TextNew';
import Slider from 'react-slick';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';

// The child carousel item
import OnlineSignup from 'components/atoms/OnlineSignup';

import './online-carousel.scss';

const OnlineCarousel = (
  { deviceState, appPromos, appPromoTitle },
  context,
  className,
  dataTestid,
) => {
  const carouselSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NavigationButton direction={DirectionType.RIGHT} buttonLabel="Next" />,
    prevArrow: <NavigationButton direction={DirectionType.LEFT} buttonLabel="Previous" />,
    variableWidth: true,
    initialSlide: 0,
    focusOnSelect: true,
    centerMode: true,
    dots: true,
  };

  const whatHappensOnlineCarousel = appPromos.map(onlineSignUp => (
    <OnlineSignup
      key={onlineSignUp.title}
      title={onlineSignUp.title}
      description={onlineSignUp.description}
      descriptionMobile={onlineSignUp.descriptionMobile}
      caption={onlineSignUp.caption}
      image={onlineSignUp.image}
    />
  ));

  return (
    <div className={className} data-testid={dataTestid}>
      <TextNew.Script.SM
        size={deviceState > DeviceState.LG ? FontSizes.MD : FontSizes.SM}
        cid="title"
        element={ElementTypes.H2}
        color={Colors.PUBLIC_PURPLE_BASE}
      >
        {appPromoTitle}
      </TextNew.Script.SM>

      {whatHappensOnlineCarousel && (
        <Slider {...carouselSettings}>{whatHappensOnlineCarousel}</Slider>
      )}
    </div>
  );
};

OnlineCarousel.propTypes = {
  deviceState: PropTypes.number,
  appPromos: PropTypes.array,
  appPromoTitle: PropTypes.string,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'OnlineCarousel')(OnlineCarousel),
);
