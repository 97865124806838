import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { API_STATIC_PUBLIC_GROUP_OR_ONLINE_MEMBERSHIP_PAGE } from 'common/src/app/data/apiStatics';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';

import GroupOnlineComparison from './GroupOnlineComparison';

const introdoctoryOfferFields = {
  UK: 'introductoryOfferUK',
  ROI: 'introductoryOfferROI',
};

const updateIntroductoryOffer = (data, wantedRegionFieldName, unwantedRegionFieldName) => {
  if (!data || !data[wantedRegionFieldName]) {
    return data;
  }

  const processedData = { ...data };
  processedData.introductoryOffer = processedData[wantedRegionFieldName];

  delete processedData[unwantedRegionFieldName];

  return processedData;
};

const processData = data => {
  if (marketName === 'UK') {
    return updateIntroductoryOffer(data, introdoctoryOfferFields.UK, introdoctoryOfferFields.ROI);
  }

  if (marketName === 'IE') {
    return updateIntroductoryOffer(data, introdoctoryOfferFields.ROI, introdoctoryOfferFields.UK);
  }

  return data;
};

const mapStateToProps = state => {
  const pageData =
    staticPageSelector(state, API_STATIC_PUBLIC_GROUP_OR_ONLINE_MEMBERSHIP_PAGE) || {};

  return {
    ...pageData,
    headerAssets: {
      smallMobileHeaderImage: pageData?.smallMobileHeaderImage,
      largeMobileHeaderImage: pageData?.largeMobileHeaderImage,
      tabletHeaderImage: pageData?.tabletHeaderImage,
      desktopHeaderImage: pageData?.desktopHeaderImage,
      ultraWideHeaderImage: pageData?.ultraWideHeaderImage,
    },
    group: processData(pageData?.group),
    online: processData(pageData?.online),
  };
};

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    dispatch(getStaticContent(API_STATIC_PUBLIC_GROUP_OR_ONLINE_MEMBERSHIP_PAGE, true)),
});

export default compose(addInitAction, connected)(GroupOnlineComparison);
