import { connect } from 'react-redux';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { getCounties } from 'common/src/app/actions/resources/groupSearchActions';

import JoinBar from './JoinBar';

const countiesSelector = state => state.view.nearestGroup?.counties;
const mapStateToProps = () => state => ({
  geoLocationError: state.geoLocation?.error,
  countyOptions: countiesSelector(state),
  accountHost: state.config.environmentConfig.web.account.host,
});

const mapDispatchToProps = dispatch => {
  if (marketConfig.dropDownBasedGroupSearch) {
    return {
      getCounties: () => dispatch(getCounties(marketConfig.marketName)).catch(),
    };
  }

  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinBar);
