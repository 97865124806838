import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import SlimmingWorldApp from 'bundle-loader?lazy&reactHot&name=SlimmingWorldApp!./index';

const routes = (
  <Route
    title="Explore our Slimming World App | {pageTitle}"
    description="The Slimming World app helps your track your weight loss your way, with support and inspiration every step of the way to your dream weight."
    path={Pages.UK_PUBLIC_SLIMMING_WORLD_APP}
    {...getRouteComponentProp(SlimmingWorldApp)}
  />
);

export default routes;
