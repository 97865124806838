import PropTypes from 'prop-types';
import imageShape from './image';

/**
 * Reusable object to use in `propTypes` for a `quicklink` prop.
 * @type {Object}
 * @category templating
 */
const quickLinkShape = {
  image: PropTypes.shape(imageShape),
  palette: PropTypes.string,
  searchLink: PropTypes.string,
  title: PropTypes.string,
  _type: PropTypes.string,
};

export default quickLinkShape;
