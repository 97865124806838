import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import OurStory from 'bundle-loader?lazy&reactHot&name=OurStory!./index';

const routes = (
  <Route
    title="The Slimming World story | About us"
    description="Discover the Slimming World difference as we celebrate helping millions of slimmers achieve their weight loss dreams since 1969"
    path={Pages.UK_PUBLIC_OUR_STORY}
    {...getRouteComponentProp(OurStory)}
  />
);

export default routes;
