import PropTypes from 'prop-types';
import imageShape from './image';

/** @module */

/**
 * Reusable object to use in `propTypes` for a `success stories` prop.
 * @type {Object}
 * @category templating
 */
const successStoriesShape = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  contentGroup: PropTypes.string,
  image: PropTypes.shape(imageShape),
  oldImage: PropTypes.shape(imageShape),
  link: PropTypes.string,
  age: PropTypes.number,
  lostWeight: PropTypes.number,
  startWeight: PropTypes.number,
  timePeriod: PropTypes.number,
  slug: PropTypes.string,
  palette: PropTypes.string,
  nestedContent: PropTypes.array,
  related: PropTypes.array,
  isPublic: PropTypes.bool,
  seo: PropTypes.object,
  _type: PropTypes.string,
  _source: PropTypes.object,
  _updated: PropTypes.number,
};

export default successStoriesShape;
