/* global 'organism','NotableDetails' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Configuration from 'common/src/app/config/Configuration';
import synsToLocale from 'common/src/app/util/SynsToLocale';
import IconName from 'common/src/app/data/enum/IconName';
import healthyExtraShape from 'common/src/app/util/proptypes/apiEntities/healthyExtra';
import RecipeAdditionalTimeSuffixArray from 'common/src/app/data/RecipeAdditionalTimeSuffixArray';
import TextNew, { Colors } from '../../atoms/TextNew';
import Icon from '../../atoms/Icon';
import RecipeDurationDisplay from '../../atoms/RecipeDurationDisplay';
import LocaleMessage from '../../atoms/LocaleMessage';
import HealthyExtraFlag from '../../molecules/HealthyExtraFlag';
import './notable-details.scss';

/**
 * This component holds important information relating a to recipes
 */

const NotableDetails = (
  {
    syns,
    servings,
    synsType,
    likesCount,
    totalTime,
    healthyExtras,
    showLikes,
    additionalTimeInMinutes,
    additionalTimeSuffix,
  },
  context,
  className,
) => (
  <div className={className}>
    {syns || syns === 0 ? (
      <div className="syns">
        <TextNew.Formal
          color={Colors.MID_TONE}
          localeId="general.syns.label"
          localeParams={synsToLocale(syns, false, synsType, LocaleMessage)}
        />
      </div>
    ) : null}
    {totalTime > 0 && (
      <div className="time">
        <Icon name={IconName.TIME} />
        <TextNew.Formal color={Colors.MID_TONE}>
          <RecipeDurationDisplay
            totalTime={totalTime}
            additionalTimeInMinutes={additionalTimeInMinutes}
            additionalTimeSuffix={additionalTimeSuffix}
            itemProp="totalTime"
          />
        </TextNew.Formal>
      </div>
    )}
    <div className="portion">
      <Icon name={Configuration.icons.profile} />
      <TextNew.Formal
        color={Colors.MID_TONE}
        localeId="general.portionSize"
        localeParams={{ SERVINGS: servings, TYPE: synsType && synsType.replace(' ', '') }}
        itemProp="recipeYield"
      />
    </div>
    {showLikes && (
      <div className="likes">
        <Icon width={20} height={20} rounded name={Configuration.icons.liked} />
        <TextNew.Formal
          color={Colors.MID_TONE}
          localeId={`general.post.${likesCount > 0 ? 'like' : 'noLike'}.label`}
          localeParams={{ TOTAL_LIKES: likesCount }}
        />
      </div>
    )}
    {healthyExtras && healthyExtras.length > 0 && (
      <HealthyExtraFlag healthyExtras={healthyExtras} showIcon textColor={Colors.MID_TONE} />
    )}
  </div>
);

NotableDetails.propTypes = {
  /**
   * Cooking time is how long a recipe takes to cook
   */
  totalTime: PropTypes.number.isRequired,

  /**
   * How many syns per portion
   */
  syns: PropTypes.number.isRequired,

  /**
   * How many portions the recipe creates
   */
  servings: PropTypes.number.isRequired,
  synsType: PropTypes.string.isRequired,

  /**
   * Total likes for the content
   */
  likesCount: PropTypes.number,
  healthyExtras: PropTypes.arrayOf(PropTypes.shape(healthyExtraShape)),
  showLikes: PropTypes.bool,
  /**
   * The additional time in minutes to be displayed in hours and minutes
   */
  additionalTimeInMinutes: PropTypes.number,
  /**
   * The additional time suffix as a string - e.g Standing, Chilling, Overnight freezing
   * This may not be present on all recipes - due to legacy recipes
   */
  additionalTimeSuffix: PropTypes.oneOf(RecipeAdditionalTimeSuffixArray),
};

export default withFunctionalClassName('organism','NotableDetails')(NotableDetails);
