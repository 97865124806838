import React from 'react';
import PropTypes from 'prop-types';

import ComponentType from 'common/src/app/data/enum/ComponentType';

import Overlay from 'components/atoms/Overlay';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconButton from 'components/molecules/IconButton';
import LocaleMessage from 'components/atoms/LocaleMessage';

import IconName from 'common/src/app/data/enum/IconName';

import './diabetes-uk-nutritional-information-modal.scss';

const DiabetesUKNutritionalInformationModal = (
  { children, modalIsOpen, setModalIsOpen },
  context,
  className,
) => (
  <Overlay
    label="Diabetes UK Nutritional Information"
    isOpen={modalIsOpen}
    onDismiss={() => setModalIsOpen(false)}
    localClassName="diabetes-uk-modal-lightbox"
  >
    {({ onDismiss }) => (
      <div className={className} data-testid="diabetes-uk-nutritional-information-modal">
        <IconButton
          buttonLabel={<LocaleMessage id="general.cta.close" />}
          dataTestid="icon-close"
          cid="icon-close"
          icon={IconName.CROSS}
          width={18}
          onClick={onDismiss}
        />
        <div className="content">{children}</div>
      </div>
    )}
  </Overlay>
);

DiabetesUKNutritionalInformationModal.propTypes = {
  children: PropTypes.node.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  setModalIsOpen: PropTypes.func.isRequired,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'DiabetesUKNutritionalInformationModal',
)(DiabetesUKNutritionalInformationModal);
