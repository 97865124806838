/**
 * Name of one of the hosts as defined in the "web" key of the environment config
 */
export default {
  ACCOUNT: 'account',
  MEMBER: 'member',
  WEB: 'web',
  LIVE: 'live',
  SHOP: 'shop',
  PUBLIC: 'public',
  PLANNER: 'planner',
  MIGRATION: 'migration',
};
