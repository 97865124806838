import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ConsultantHeader from './components/organisms/ConsultantHeader';
import './become-a-consultant.scss';

const BecomeAConsultant = ({ children }, context, className) => (
  <div className={className}>
    <ConsultantHeader />
    {children}
  </div>
);

BecomeAConsultant.propTypes = {
  children: PropTypes.node,
};

export default withFunctionalClassName(ComponentType.PAGE, 'BecomeAConsultant')(BecomeAConsultant);
