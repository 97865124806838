import handleActions from 'redux-actions/lib/handleActions';
import { SET_SUBJECT, SET_MAP_VISIBLE } from '../../../actions/components/contactUsActions';

const initialState = {
  subjectId: null,
  map: false,
};

export default handleActions(
  {
    [SET_SUBJECT]: (state, { payload }) => ({
      ...state,
      subjectId: payload,
    }),
    [SET_MAP_VISIBLE]: (state, { payload }) => ({
      ...state,
      map: payload,
    }),
  },
  initialState,
);
