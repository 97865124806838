import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { API_STATIC_PUBLIC_ICELAND_LANDING } from 'common/src/app/data/apiStatics';
import { GATEWAY_CONTENT } from '../../data/Injectables';
import apiGetEntity from './apiActions/apiGetEntity';
import { FOOD_RANGE } from '../../data/entityTypes';

export const getIcelandLandingPage = () => dispatch =>
  dispatch(getStaticContent(API_STATIC_PUBLIC_ICELAND_LANDING, true));

export const GET_FOOD_RANGE_CATEGORIES = 'searchActions/GET_FOOD_RANGE_CATEGORIES';

export const getFoodRangeCategories = () => dispatch =>
  dispatch(
    apiGetEntity(
      GET_FOOD_RANGE_CATEGORIES,
      GATEWAY_CONTENT,
      `/food-range`,
      FOOD_RANGE,
      'products',
      {
        updateEntityView: 'view.pages.foodRangeDetail.foodRange',
      },
    ),
  );
