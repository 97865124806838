import moment from 'moment';

/**
 * Reduce function to create group session information
 *
 */
const reducePublicGroupSessions = sessions =>
  sessions.reduce((total, e) => {
    const newTotal = total;
    const found = total[e.day] && total[e.day].times.length > 0;
    const time = moment.utc(moment.duration(e.time).asMilliseconds());

    if (!found) {
      newTotal[e.day] = { ...newTotal[e.day], times: [time] };
    } else {
      newTotal[e.day].times.push(time);
    }

    if (e.opening) {
      newTotal[e.day] = { ...newTotal[e.day], opening: e.opening };
    }
    if (e.extraInformation) {
      newTotal[e.day] = { ...newTotal[e.day], extraInformation: e.extraInformation };
    }
    return newTotal;
  }, {});

export default reducePublicGroupSessions;
