import PropTypes from 'prop-types';
import podcastShape from 'common/src/app/util/proptypes/apiEntities/podcastShape';

/** @module */

/**
 * Reusable object to use in `propTypes` for a `podcastCategory` prop.
 * @type {Object}
 * @category templating
 */
const podcastCategoryShape = {
  /**
   * The podcast category id
   */
  id: PropTypes.string.isRequired,

  /**
   * The podcast category name
   */
  name: PropTypes.string,

  /**
   * The podcast category slug
   */
  slug: PropTypes.string.isRequired,

  /**
   * The array of podcasts
   */
  podcasts: PropTypes.arrayOf(PropTypes.shape(podcastShape)).isRequired,
};

export default podcastCategoryShape;
