import React from 'react';
import PropTypes from 'prop-types';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import './food-optimising-step.scss';

export const FoodOptimisingStep = ({ icon, title, step }) => (
  <div className="food-optimising-step-tile" data-testid="food-optimising-tile">
    <div className="food-optimising-title">
      <Icon name={icon} cid="step-number" dataTestid="step-number" />
      <TextNew.Serif.SM localeId={title} dataTestid="title" element={ElementTypes.H3} />
    </div>
    <RichTextBlock text={step} dataTestid="step-text" />
  </div>
);

FoodOptimisingStep.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  step: PropTypes.string,
};

const FoodOptimisingSteps = ({ page }) => (
  <div className="food-optimising-wrapper" data-testid="food-optimising-wrapper">
    <h2>
      <TextNew.Serif.MD
        color={Colors.BRAND}
        cid="food-optimising-part-one"
        localeId="foodOptimisingStep.foodOptimisingTitlePartOne"
        element={ElementTypes.SPAN}
      />
      <TextNew.Script.XL
        color={Colors.BRAND}
        localeId="foodOptimisingStep.foodOptimisingTitlePartTwo"
        element={ElementTypes.SPAN}
        cid="food-optimising-part-two"
      />
      <TextNew.Serif.MD
        color={Colors.BRAND}
        cid="food-optimising-part-three"
        localeId="foodOptimisingStep.foodOptimisingTitlePartThree"
        element={ElementTypes.SPAN}
      />
    </h2>

    <div className="food-optimising-steps">
      <FoodOptimisingStep
        icon={IconName.FOOD_OPTIMISING_STEP_ONE}
        title="foodOptimisingStep.freeFoodTitle"
        step={page.step1}
      />
      <FoodOptimisingStep
        icon={IconName.FOOD_OPTIMISING_STEP_TWO}
        title="foodOptimisingStep.healthyExtrasTitle"
        step={page.step2}
      />
      <FoodOptimisingStep
        icon={IconName.FOOD_OPTIMISING_STEP_THREE}
        title="foodOptimisingStep.synsTitle"
        step={page.step3}
      />
    </div>
  </div>
);

FoodOptimisingSteps.propTypes = {
  page: PropTypes.object,
};

export default FoodOptimisingSteps;
