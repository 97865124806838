import React, { useEffect } from 'react';
import { compose } from 'redux';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import PropTypes from 'prop-types';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import GroupOnlineSignUp from 'components/molecules/GroupOnlineSignUp';
import Wrapper from 'components/atoms/Wrapper';
import Row from 'components/atoms/Row';
import TextNew, { Colors, ElementTypes, FontWeight } from 'components/atoms/TextNew';
import HashDeepLinks from 'common/src/app/data/enum/HashDeepLinks';
import errorBoundary from 'components/hoc/ErrorBoundary';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import Button from 'components/atoms/Button';
import NewsletterSignUp from '../../molecules/NewsletterSignUp';
import OneStep from './components/molecules/OneStep';
import Pillars from './components/molecules/Pillars';

import RelatedSuccessStories from '../SuccessStories/components/molecules/RelatedSuccessStories';
import './how-it-works.scss';

const HowItWorks = ({ hash, scrollToElement, headerHeight, data }, context, className) => {
  useEffect(() => {
    if (hash && headerHeight) {
      const moveMoreWrapper = document?.querySelector(hash);
      if (hash?.length > 0 && hash === HashDeepLinks.MOVE_MORE && moveMoreWrapper) {
        scrollToElement(moveMoreWrapper, -headerHeight);
      }
    }
  }, [hash, headerHeight, scrollToElement]);

  return (
    <div className={className}>
      <HeroBarPublic image={data?.headerImage}>
        <TextNew.Serif.SM
          cid="hero-bar-public-serif-heading"
          element={ElementTypes.H1}
          weight={FontWeight.BOLD}
        >
          {data?.title}
        </TextNew.Serif.SM>
        <TextNew.Script.LG cid="hero-bar-public-script-heading" color={Colors.BRAND}>
          {data?.subtitle}
        </TextNew.Script.LG>
        <TextNew.Serif.XS cid="hero-bar-public-introduction" element={ElementTypes.H2}>
          <RichTextBlock text={data?.introduction} />
        </TextNew.Serif.XS>

        <Button to={data?.introductionButton.link} isGhostBrand cid="comparison-button">
          <TextNew.Sans.SM element={ElementTypes.SPAN} color={Colors.BRAND}>
            {data?.introductionButton.text}
          </TextNew.Sans.SM>
        </Button>
      </HeroBarPublic>
      <Wrapper.LG>
        <Pillars steps={data?.iconBlocks} title={data?.iconBlocksTitle} />

        <Row column className="articles">
          <OneStep blocks={data?.imageBlocksTop} hasButtonTo />
        </Row>

        <RelatedSuccessStories successStories={data?.successStories} />

        <Row column className="articles">
          <OneStep blocks={data?.imageBlocksBottom} hasButtonTo hasSubtitle />
        </Row>
        <GroupOnlineSignUp />
        <NewsletterSignUp />
      </Wrapper.LG>
    </div>
  );
};

HowItWorks.propTypes = {
  headerHeight: PropTypes.number.isRequired,
  hash: PropTypes.string,
  scrollToElement: PropTypes.func.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    introduction: PropTypes.string,
    introductionButton: PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
    }),
    iconBlocksTitle: PropTypes.string,
    iconBlocks: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
        }),
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
    imageBlocksTop: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
        }),
        button: PropTypes.shape({
          text: PropTypes.string,
          link: PropTypes.string,
        }),
      }),
    ),
    successStories: PropTypes.arrayOf(
      PropTypes.shape({
        person: PropTypes.string,
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
        }),
        quote: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
    imageBlocksBottom: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
        }),
        button: PropTypes.shape({
          text: PropTypes.string,
          link: PropTypes.string,
        }),
      }),
    ),
  }),
};

HowItWorks.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.PAGE, 'HowItWorks'),
)(HowItWorks);
