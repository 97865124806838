/* global 'molecule' */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  EmailShareButton,
  TumblrShareButton,
} from 'react-share';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import TextNew from '../../atoms/TextNew';
import LocaleMessage from '../../atoms/LocaleMessage';
import Icon from '../../atoms/Icon';
import './social-share.scss';

/**
 * Social share, used on UK public recipies and success stories
 */

class SocialShare extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urlToShare: typeof navigator !== 'undefined' ? window.location.href : '',
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (typeof navigator !== 'undefined' && window.location.href !== nextState.urlToShare) {
      this.setState(() => ({
        urlToShare: window.location.href,
      }));
      return true;
    }
    return false;
  }

  render() {
    const { title, image, pageType } = this.props;
    const { urlToShare } = this.state;
    const shareServices = [
      { component: EmailShareButton, icon: IconName.ENVELOPE_EMAIL },
      { component: FacebookShareButton, icon: IconName.FACEBOOK },
      { component: TwitterShareButton, icon: IconName.TWITTER },
      { component: TumblrShareButton, icon: IconName.TUMBLR },
      { component: PinterestShareButton, icon: IconName.PINTEREST },
    ];

    const localeParam = (
      <LocaleMessage key={pageType} id={pageType || 'socialShare.typesOfPost.default'} />
    );
    return (
      <div {...componentClassNameProp('molecule', this)}>
        <TextNew.Formal localeId="socialShare.title" localeParams={{ PAGE: localeParam }} />
        <div className="share-icons">
          {shareServices.map(SocialNetwork => (
            <SocialNetwork.component
              key={SocialNetwork.icon}
              url={urlToShare}
              media={`${urlToShare}/${image}`}
              quote={title}
              title={title}
              subject={title}
              windowWidth={1000}
              windowHeight={730}
              className="social-share-button"
            >
              <Icon name={SocialNetwork.icon} width={28} height={28} />
            </SocialNetwork.component>
          ))}
        </div>
      </div>
    );
  }
}

SocialShare.propTypes = {
  /* Share url to be passed in */
  // shareUrl: PropTypes.string,
  /* title passed in */
  title: PropTypes.string,
  /* type of page, for the share button locale e.g Share this article */
  pageType: PropTypes.string,
  /* defaulted titled */
  image: PropTypes.object,
};

export default SocialShare;
