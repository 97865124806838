import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import classNames from 'classnames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import breakpoints from 'common/src/app/data/enum/Breakpoints';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import Carousel from '../../../../../organisms/Carousel';
import ResponsiveImage from '../../../../../atoms/ResponsiveImage';
import NavigationButton from '../../../../../atoms/NavigationButton';

import './image-carousel-block.scss';

const ImageCarouselBlock = (
  { imageCarousel, hasDots, hasOneSlide, hasSquareImage },
  context,
  className,
) => {
  const hasMultipleImages = imageCarousel?.length > 1;
  const settings = {
    dots: hasDots && hasMultipleImages,
    infinite: false,
    speed: 500,
    draggable: hasMultipleImages,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: hasMultipleImages,
    responsive: [
      { breakpoint: breakpoints.MD, settings: { slidesToShow: 1 } },
      { breakpoint: breakpoints.XL, settings: { slidesToShow: hasOneSlide ? 1 : 2 } },
      { breakpoint: breakpoints.XXL, settings: { slidesToShow: hasOneSlide ? 1 : 3 } },
    ],
    nextArrow: <NavigationButton direction={DirectionType.RIGHT} />,
    prevArrow: <NavigationButton direction={DirectionType.LEFT} />,
  };

  return (
    <div
      className={classNames(className, {
        'has-dots': hasDots,
        'has-square-image': hasSquareImage,
        'has-single-image': imageCarousel?.length === 1,
      })}
    >
      <Carousel settings={settings}>
        {imageCarousel.map(item => (
          <div key={item.id} className="wrapper">
            <ResponsiveImage src={item.src} alt={item.alt} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

ImageCarouselBlock.defaultProps = {
  dots: false,
};

ImageCarouselBlock.propTypes = {
  /**
   * Array of items to display
   */
  imageCarousel: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      caption: PropTypes.string,
      image: PropTypes.shape({
        ...imageShape,
      }),
    }),
  ).isRequired,
  hasDots: PropTypes.bool,
  hasOneSlide: PropTypes.bool,
  hasSquareImage: PropTypes.bool,
};

export default withFunctionalClassName(
  ComponentType.ATOM,
  'ImageCarouselBlock',
)(ImageCarouselBlock);
