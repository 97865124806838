import PropTypes from 'prop-types';

/** @module */

/**
 * Reusable object to use in `propTypes` for a `podcast` prop.
 * @type {Object}
 * @category templating
 */
const podcastShape = {
  /**
   * The link that links to the podcast
   */
  link: PropTypes.string.isRequired,

  /**
   * The podcast id
   */
  id: PropTypes.string.isRequired,

  /**
   * The podcast slug
   */
  slug: PropTypes.string.isRequired,

  /**
   * The podcast title
   */
  title: PropTypes.string.isRequired,
};

export default podcastShape;
