import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import GroupFullDetails from './GroupFullDetails';

const mapStateToProps = state => ({
  googleMapApi: state.config.environmentConfig.gmaps,
});

const connected = connect(mapStateToProps);

export default compose(connected)(GroupFullDetails);
