import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import HowItWorks from 'bundle-loader?lazy&reactHot&name=HowItWorks!./index';

const routes = (
  <Route
    title="How does Slimming World work? | {pageTitle}"
    description="Find out how Slimming World works with a weight loss programme backed by science, no-hunger eating plan and unbeatable support. Join today!"
    path={Pages.UK_PUBLIC_HOW_IT_WORKS}
    {...getRouteComponentProp(HowItWorks)}
  />
);

export default routes;
