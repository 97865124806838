/**
 * Converts inches to feet
 *
 * @param inches
 * @returns {array} [feet, inches]
 */
export function inchesToFeet(inches) {
  const feet = Math.trunc(Number(inches) / 12);
  const inchesRemainder = Math.round(inches % 12);

  if (inchesRemainder === 12) return [feet + 1, 0];

  return [feet, inchesRemainder];
}

/**
 * Converts inches to centimeters
 *
 * @param inches
 * @returns {number} in centimeters
 */
export function inchesToCentimeters(inches) {
  return Number(inches) / 0.393701;
}

/**
 * Converts centimeters to inches
 *
 * @param centimeters
 * @returns {number} in inches
 */
export function centimetersToInches(centimeters) {
  return Number(centimeters) * 0.393701;
}

/**
 * Converts Centimeters to meters
 *
 * @param centimeters
 * @returns {Array} [meters, centimeters]
 */
export function centimetersToMeters(centimeters) {
  const meters = Number(centimeters) / 100;
  const centimeterRemainder = parseInt((meters % 1) * 100, 10);

  return [Math.floor(meters), centimeterRemainder];
}

/**
 * Converts pounds to kilograms
 *
 * @param pounds
 * @returns {number} in kilograms
 */
export function poundsToKilograms(pounds) {
  return Number(pounds) / 2.2046226218;
}

/**
 * Converts kilograms to pounds
 *
 * @param kgs
 * @returns {number} in pounds
 */
export function kilogramsToPounds(kgs) {
  return Number(kgs) * 2.2046226218;
}

/**
 * Converts pounds to stones
 *
 * @param pounds
 * @returns {Array} [stones, pounds]
 */
export function poundsToStones(pounds) {
  const stones = Number(pounds) / 14;
  const poundsRemainder = (stones % 1) * 14;
  const stonesRemainder = Math.trunc(stones);

  return [stonesRemainder, poundsRemainder];
}

/**
 * Converts kilograms to stones
 *
 * @param kilograms
 * @returns {number} in stones
 */
export function kilogramsToStones(kgs) {
  return Number(kgs) * 0.157473;
}

/**
 * Converts stones to pounds
 * @param stones
 * @returns {number}
 */
export function stonesToPounds(stones) {
  return Number(stones) * 14;
}

/**
 * Converts KM to miles
 * @param km
 * @returns {number}
 */
export function kmToMiles(km) {
  return Number(km) * 0.6213712;
}

/**
 * Converts days to years and days
 * @param days
 * @returns {string}
 */
export function daysToYearsAndDays(days) {
  return `${Math.floor(days / 365)} years ${days % 365} days`;
}
