import React from 'react';
import PropTypes from 'prop-types';
import AnimatedInput from './components/atoms/AnimatedInput';
import StaticInput from './components/atoms/StaticInput';

export const descriptorTypes = {
  ANIMATED: 'animated',
  STATIC: 'static',
  GHOST: 'ghost',
};

const InputNew = ({ field, descriptor = descriptorTypes.ANIMATED, ...props }) =>
  descriptor === descriptorTypes.ANIMATED ? (
    <AnimatedInput {...field} {...props} />
  ) : (
    <StaticInput descriptor={descriptor} {...field} {...props} />
  );
descriptorTypes.PropTypes = Object.values(descriptorTypes);

InputNew.propTypes = {
  descriptor: PropTypes.oneOf(descriptorTypes.PropTypes),
  field: PropTypes.object,
};

export default InputNew;
