import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { RoleType } from 'common/src/app/data/enum/AccessibilityEnum';
import ErrorType from 'common/src/app/data/enum/ErrorType';
import './formik-error.scss';
import TextNew, { Colors } from '../TextNew';

const FormikError = ({
  formik,
  name,
  errorName,
  dataTestid = 'validationError',
  params,
  triggerUntouched = false,
}) => {
  // This component renders an error message if a field has
  // an error and it's already been touched.

  const error = getIn(formik?.errors, name);
  const touch = getIn(formik?.touched, name);

  if (
    (triggerUntouched || touch || name === ErrorType.GENERAL_ERROR) &&
    error &&
    /**
     * blockingErrorCode is used for the BlockingFormError and HideOnError components
     *
     * examples: hasValidBirthDateValidationSchema, pregnancyValidationSchema
     */
    !error.blockingErrorCode
  ) {
    /**
     * Fetch backend messages instead of hard coded locale messages
     *
     * Reference: ChangePassword/index.js
     */
    if (
      typeof error === 'object' &&
      Object.prototype.hasOwnProperty.call(error, 'fromBackendLocaleMessage')
    ) {
      return (
        <TextNew.Error
          role={RoleType.ALERT}
          dataTestid={`${dataTestid || ''}-${name}`}
          id={`${`${dataTestid}-` || ''}${name}`}
          cid="error"
          color={Colors.BRAND}
        >
          {error.message}
        </TextNew.Error>
      );
      /**
       * You can define your validation message object with the locale and params properties
       */
    } else if (
      typeof error === 'object' &&
      Object.prototype.hasOwnProperty.call(error, 'locale') &&
      Object.prototype.hasOwnProperty.call(error, 'params')
    ) {
      return (
        <TextNew.Error
          role={RoleType.ALERT}
          dataTestid={`${dataTestid || ''}-${name}`}
          id={`${`${dataTestid}-` || ''}${name}`}
          localeId={error.locale}
          localeParams={{ FIELD_NAME: errorName || name, ...(params || error.params) }}
          cid="error"
          color={Colors.BRAND}
        />
      );
    }

    // Default error message
    return (
      <TextNew.Error
        role={RoleType.ALERT}
        dataTestid={`${dataTestid || ''}-${name}`}
        id={`${`${dataTestid}-` || ''}${name}`}
        localeId={error}
        localeParams={{ FIELD_NAME: errorName || name, ...params }}
        cid="error"
        color={Colors.BRAND}
      />
    );
  }

  return null;
};

FormikError.defaultProps = {
  name: ErrorType.GENERAL_ERROR,
};

FormikError.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  errorName: PropTypes.string,
  dataTestid: PropTypes.string,
  triggerUntouched: PropTypes.bool,
  params: PropTypes.object,
};

export default FormikError;
