/**
 * Key for the cookie that will contain an index of all keys in redux-persist.
 * Will be prefixed with `KEY_PREFIX`
 * @type {string}
 */
export const INDEX_KEY = 'index';

/**
 * Prefix for all redux-persist related cookies
 * @type {string}
 */
export const KEY_PREFIX = 'rp.';
