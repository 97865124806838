import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { API_STATIC_PUBLIC_WHAT_HAPPENS_ONLINE } from 'common/src/app/data/apiStatics';
import RegistrationCTAWithOptionalRoundelAndTitle from './RegistrationCTAWithOptionalRoundelAndTitle';

const mapStateToProps = state => ({
  page: staticPageSelector(state, API_STATIC_PUBLIC_WHAT_HAPPENS_ONLINE),
  accountHost: state.config.environmentConfig.web.account.host,
});

const connected = connect(mapStateToProps);

export default compose(connected)(RegistrationCTAWithOptionalRoundelAndTitle);
