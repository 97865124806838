import { connect } from 'react-redux';
import { DeviceState, QueryHelpers } from '../../data/MediaQueries';

/**
 * @function withDeviceState
 *
 * A HoC for passing the deviceState to your component via the props.
 * Makes use of react-redux connect() HoC
 *
 * @returns {function} A function to compose or pass your component to
 *
 * @example
 *
 * import React from 'react';
 * import PropTypes from 'prop-types';
 * import withDeviceState, { DeviceState, QueryHelpers } from './withDeviceState';
 *
 * const CreatePost = ({
 *   otherPropFromComponent,
 *   deviceState,
 *   deviceStateName,
 *   queryHelper,
 * }, { getMessage }) => (
 *   <div>
 *     { deviceState > DeviceState.LG ?
 *       <span>{foo}</span>
 *     : null }
 *
 *      {queryHelper === QueryHelpers.MOBILE && <div>Only On Mobile!</div>}
 *      {queryHelper === QueryHelpers.TABLET && <div>Only On Tablet!</div>}
 *      {queryHelper === QueryHelpers.DESKTOP && <div>Only On Desktop!</div>}
 *
 *   </div>
 * );
 *
 * CreatePost.propTypes = {
 *   otherPropFromComponent: PropTypes.number,
 *   deviceState: PropTypes.number,
 *   deviceStateName: PropTypes.string,
 *   queryHelper: PropTypes.string,
 * };
 *
 * export default withDeviceState()(CreatePost);
 *
 */

const withDeviceState = () => WrappedComponent =>
  connect(({ deviceState }) => ({
    deviceState: deviceState.state,
    deviceStateName: deviceState.name,
    queryHelper: deviceState.helper,
  }))(WrappedComponent);

export { withDeviceState as default, DeviceState, QueryHelpers };
