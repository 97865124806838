import { connect } from 'react-redux';
import { closeModal, isOpenedModal } from 'common/src/app/actions/components/modalActions';

import ModalRoot from './ModalRoot';

export default connect(
  state => ({
    modalComponent: state.view.components.modal.component,
    modalProps: state.view.components.modal.props,
    enableCloseOnBackdrop: state.view.components.modal.enableCloseOnBackdrop,
    showCloseButton: state.view.components.modal.showCloseButton,
    isFullBleed: state.view.components.modal.isFullBleed,
    isOpen: state.view.components.modal.isOpen,
    scrollbarWidth: state.deviceState.scrollbarWidth,
  }),
  dispatch => ({
    closeModal: () => dispatch(closeModal()),
    isOpened: opened => dispatch(isOpenedModal(opened)),
  }),
)(ModalRoot);
