import React from 'react';
import { Route } from 'react-router';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import ComparisonPage from 'bundle-loader?lazy&reactHot&name=ComparisonPage!./index';

const routes = (
  <Route
    title="Slimming World or Weight Watchers | {pageTitle}"
    description="Discover the difference between Weight Watchers and Slimming World to find a weight loss plan that works for you and gives you the support you need."
    path={Pages.UK_PUBLIC_COMPARISON_PAGE}
    {...getRouteComponentProp(ComparisonPage)}
  />
);

export default routes;
