/* global 'template','NearestGroupCounties' */
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { AccentColors, Colors, FontSizes, ElementTypes } from 'components/atoms/TextNew';
import Loader from 'components/atoms/Loader';
import Link from 'react-router/lib/Link';
import Wrapper from 'components/atoms/Wrapper';
import Pages from 'common/src/app/data/enum/Pages';
import errorBoundary from 'components/hoc/ErrorBoundary';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import PlaceDirectory from '../../organisms/PlaceDirectory';
import './nearest-group-counties.scss';

const NearestGroupCounties = ({ isInitializing, counties, deviceState }, context, className) => (
  <div className={className}>
    <Wrapper.MD cid="header" padding="xs">
      <>
        <h1>
          <TextNew.Script.LG
            size={deviceState > DeviceState.LG ? FontSizes.LG : FontSizes.SM}
            localeId="nearestGroupCounties.title1"
            color={AccentColors.ACCENT_PINK}
            element={ElementTypes.SPAN}
          />
          <TextNew.Serif.XL
            localeId="nearestGroupCounties.title2"
            color={AccentColors.ACCENT_PINK}
            element={ElementTypes.SPAN}
            cid="title-center"
          />
          <TextNew.Script.LG
            size={deviceState > DeviceState.LG ? FontSizes.LG : FontSizes.SM}
            localeId="nearestGroupCounties.title3"
            color={AccentColors.ACCENT_PINK}
            element={ElementTypes.SPAN}
            cid="title-bottom"
          />
        </h1>

        <TextNew.Serif.SM
          localeId="nearestGroupCounties.county.description"
          cid="descriptor"
          color={Colors.DARK_TONE}
        >
          <Link to={Pages.UK_PUBLIC_WHAT_HAPPENS_IN_GROUP} className="inline-group-link">
            <TextNew.Serif.SM
              element={ElementTypes.SPAN}
              localeId="nearestGroupCounties.county.link"
              cid="descriptor"
            />
          </Link>
        </TextNew.Serif.SM>
      </>
    </Wrapper.MD>
    {!isInitializing ? <PlaceDirectory displaySortTitle data={counties} /> : <Loader />}
  </div>
);

NearestGroupCounties.contextTypes = {
  getMessage: PropTypes.func,
};

NearestGroupCounties.defaultProps = {
  counties: [],
  towns: [],
};

NearestGroupCounties.propTypes = {
  counties: PropTypes.object,
  isInitializing: PropTypes.bool,
  deviceState: PropTypes.number,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName('template','NearestGroupCounties'),
)(NearestGroupCounties);
