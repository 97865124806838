import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { API_COMPONENT_PUBLIC_ICELAND_FOOD_WAREHOUSE_LOGO } from 'common/src/app/data/apiComponents';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';
import IcelandProductBanner from './IcelandProductBanner';

const mapStateToProps = state => ({
  warehouseImage: contentComponentSelector(
    state,
    API_COMPONENT_PUBLIC_ICELAND_FOOD_WAREHOUSE_LOGO,
    'image',
  ),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    dispatch(getComponent(API_COMPONENT_PUBLIC_ICELAND_FOOD_WAREHOUSE_LOGO)),
});

export default compose(addInitAction, connected)(IcelandProductBanner);
