import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import LocaleMessage from 'components/atoms/LocaleMessage';
import NearestGroupInformationBox from '../../molecules/NearestGroupInformationBox';

/**
 * A component that displays addtional information unique to a group these have
 * a limited time that they are visable for depending on the message.
 * Examples of this could be a new group opening or venue changes
 */

const NearestGroupSessionMessages = ({ sessions }) => {
  const groupSessions = Object.values(sessions);
  const extraInformation = groupSessions.reduce((sessionsExtraInformation, session) => {
    /**
     * If session does not have extraInformation or extraInformation is just a
     * space return without it
     */
    if (!session?.extraInformation?.trim()) return sessionsExtraInformation;
    /**
     * Check if extraInformation has a dupe message already within do not add current extraInformation in
     */
    if (
      sessionsExtraInformation.length > 0 &&
      sessionsExtraInformation.includes(session.extraInformation)
    ) {
      return sessionsExtraInformation;
    }

    return [...sessionsExtraInformation, session.extraInformation] || sessionsExtraInformation;
  }, []);
  return (
    <Fragment>
      {Object.keys(groupSessions).map(currentSession => (
        <Fragment key={`session-time-${currentSession}`}>
          {groupSessions[currentSession].opening && (
            <NearestGroupInformationBox
              information={
                <LocaleMessage
                  id="nearestGroupTile.opens"
                  params={{
                    DATE: moment(groupSessions[currentSession].opening).format('DD/MM/YYYY'),
                  }}
                />
              }
            />
          )}
        </Fragment>
      ))}
      {extraInformation.length > 0 &&
        extraInformation.map(information => (
          <NearestGroupInformationBox key={information} information={information} />
        ))}
    </Fragment>
  );
};

NearestGroupSessionMessages.propTypes = {
  sessions: PropTypes.object.isRequired,
};

export default NearestGroupSessionMessages;
