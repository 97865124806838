/* global 'organism','SuccessStoryDetailHead' */
import React from 'react';
import PropTypes from 'prop-types';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import successStories from 'common/src/app/util/proptypes/apiEntities/successStories';
import Configuration from 'common/src/app/config/Configuration';
import { poundsToKilograms } from 'common/src/app/util/conversionUtils';
import TextNew, { AccentColors, Colors, ElementTypes, TextTypes } from '../../atoms/TextNew';
import Wrapper from '../../atoms/Wrapper';
import Icon from '../../atoms/Icon';
import ResponsiveImage from '../../atoms/ResponsiveImage';
import ImageFlip from '../ImageFlip';
import DisplayWeight from '../../atoms/DisplayWeight';
import SuccessStoryData from '../../molecules/SuccessStoryData';
import SocialShare from '../../molecules/SocialShare';

import './success-story-detail-head.scss';

/**
 *SuccessStoryDetailHead
 */
const SuccessStoryDetailHead = (
  { deviceState, successStory, interaction, isUkPublic },
  { getMessage },
  className,
) => (
  <Wrapper width="lg" className={className}>
    <figure>
      {deviceState > DeviceState.MD ? (
        <>
          <ResponsiveImage key="main-image" {...successStory.image} px={640} ratio={946 / 1280} />
          <div className="polaroid" key="polaroid-image">
            <ResponsiveImage {...successStory.oldImage} px={225} ratio={49 / 65} />
            {!isUkPublic && (
              <>
                <Icon size="48" name="paperclip" />
                <TextNew.Details>BEFORE</TextNew.Details>
              </>
            )}
          </div>
        </>
      ) : (
        <ImageFlip
          storyId={successStory.id}
          image={successStory.image}
          title={successStory.title}
          revealImage={successStory.oldImage}
          ratio={0.75}
        />
      )}
      {!isUkPublic && (
        <div className="badge">
          <TextNew.Strong
            cid="badge-title"
            color={Colors.PRIMARY_LIGHT}
            element={ElementTypes.DIV}
            localeId="successStoryDetail.badge.lost"
          />
          <TextNew.Confident color={Colors.PRIMARY_LIGHT} element={ElementTypes.DIV}>
            {/** TODO: https://slimmingworlddigital.atlassian.net/browse/SWO-3261 */}
            <DisplayWeight
              weightUnit={Configuration.defaultWeightUnit}
              imperialWeightUseStones={Configuration.imperialWeightUseStones}
              weight={poundsToKilograms(successStory.lostWeight)}
            />
          </TextNew.Confident>
        </div>
      )}
    </figure>
    <aside>
      <div>
        <div className="intro">
          <TextNew
            element={ElementTypes.H1}
            type={isUkPublic ? TextTypes.SCRIPT_SM : TextTypes.CONFIDENT}
            color={isUkPublic && AccentColors.ACCENT_PINK}
          >
            {successStory.title}
          </TextNew>
          <div
            className="description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: successStory.description }}
          />
          <SuccessStoryData
            person={successStory.person}
            age={successStory.age}
            address={successStory.address}
            startWeight={successStory.startWeight}
            currentWeight={successStory.currentWeight}
            timePeriod={successStory.timePeriod}
            height={successStory.height}
            isUkPublic={isUkPublic}
          />
          {interaction || null}
          {isUkPublic && (
            <SocialShare
              title={successStory.title}
              image={successStory.image}
              pageType={getMessage('socialShare.typesOfPost.successStory')}
            />
          )}
        </div>
      </div>
    </aside>
  </Wrapper>
);

SuccessStoryDetailHead.propTypes = {
  /**
   * Standard device state for JS media query
   */
  deviceState: PropTypes.number,
  interaction: PropTypes.node,
  successStory: PropTypes.shape(successStories),
  isUkPublic: PropTypes.bool,
};

SuccessStoryDetailHead.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withDeviceState()(withFunctionalClassName('organism','SuccessStoryDetailHead')(SuccessStoryDetailHead));
