import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { trackVideoEvent } from 'common/src/app/actions/trackingActions';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import {
  GET_STATIC_CONTENT,
  getStaticContent,
} from 'common/src/app/actions/resources/staticContentActions';
import { API_STATIC_PUBLIC_OUR_CONSULTANTS } from 'common/src/app/data/apiStatics';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import OurConsultants from './OurConsultants';

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT);

const mapStateToProps = state => ({
  brightcoveAccountId: state.config.environmentConfig.brightCove.accountId,
  brightcovePlayerId: state.config.environmentConfig.brightCove.playerId,
  loading: pageLoading(state),
  pageData: staticPageSelector(state, API_STATIC_PUBLIC_OUR_CONSULTANTS),
});

const connected = connect(mapStateToProps, { trackVideoEvent });

const addInitAction = withInitAction({
  clientOnly: ({}, dispatch) => dispatch(getStaticContent(API_STATIC_PUBLIC_OUR_CONSULTANTS, true)),
});

export default compose(addInitAction, connected)(OurConsultants);
