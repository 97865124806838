import React from 'react';
import { getWeekName } from 'common/src/app/util/dateUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import modifiedGroupTimesFormatted from 'components/molecules/ModifiedGroupTimesFormatted';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import GroupField from '../../atoms/GroupField';

const ModifiedGroupTimetable = ({ type, textStyle, headerTextStyle, groupSessions }) => {
  // Group configuration settings - these are used to drive the display of sections and messaging
  const venueChanged = groupSessions
    .filter(x => x.venueHasChanged)
    .map(x => getWeekName(x.day))
    .join(', ');
  const additionalGroups = groupSessions.filter(x => x.runningAdditionalSessions).length > 0;
  const groupIsRunning = groupSessions.filter(x => x.isRunning).length > 0;
  const consultantHasConfirmed = groupSessions.filter(x => x.hasConfirmed).length > 0;

  return (
    groupSessions && (
      <GroupField
        dataTestid="modified-group-timetable"
        label={`modifiedGroupTimetable.${type}GroupTimes`}
        cid="modified-group-timetable"
        textStyle={headerTextStyle}
      >
        {consultantHasConfirmed &&
          groupIsRunning &&
          groupSessions.map((s, index) => (
            <div className="session-label" key={`session-day-${s.day}`}>
              {s.isRunning && s.sessions?.length > 0 ? (
                <>
                  <TextNew.Sans
                    {...textStyle}
                    cid="session-label-day"
                    dataTestid={`group-session-day-${index}`}
                  >
                    {getWeekName(s.day)}
                  </TextNew.Sans>

                  <TextNew.Sans {...textStyle} cid="session-label-times" element={ElementTypes.UL}>
                    <TextNew.Sans
                      {...textStyle}
                      dataTestid={`group-session-times-${index}`}
                      element={ElementTypes.LI}
                    >
                      {`${modifiedGroupTimesFormatted({ session: s })}`}
                    </TextNew.Sans>
                  </TextNew.Sans>
                </>
              ) : (
                groupSessions?.length < 2 && (
                  <TextNew.Sans {...textStyle} localeId="modifiedGroupTimetable.hasNotConfirmed" />
                )
              )}
            </div>
          ))}
        {consultantHasConfirmed && groupIsRunning && venueChanged && (
          <TextNew.Sans
            {...textStyle}
            dataTestid="venue-has-changed"
            localeId="modifiedGroupTimetable.venueChanged"
            localeParams={{ DAYS: venueChanged }}
          />
        )}
        {consultantHasConfirmed && groupIsRunning && additionalGroups && (
          <TextNew.Sans
            {...textStyle}
            dataTestid="additional-groups-are-running"
            localeId="modifiedGroupTimetable.additionalGroups"
          />
        )}
        {consultantHasConfirmed && !groupIsRunning && (
          <TextNew.Sans
            {...textStyle}
            dataTestid="has-confirmed-group-is-not-running"
            localeId="modifiedGroupTimetable.groupNotRunning"
          />
        )}
        {!consultantHasConfirmed && (
          <TextNew.Sans
            {...textStyle}
            dataTestid="has-not-confirmed"
            localeId="modifiedGroupTimetable.hasNotConfirmed"
          />
        )}
      </GroupField>
    )
  );
};

export default withDeviceState()(ModifiedGroupTimetable);
