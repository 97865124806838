import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Wrapper from '../../atoms/Wrapper';
import TextNew, { FontSizes, AccentColors, ElementTypes } from '../../atoms/TextNew';
import PublicHorizontalSearchBar from '../../molecules/PublicHorizontalSearchBar';
import RecipeTiles from './components/molecules/RecipeTiles';

import './typical-day-meal-plan.scss';

const TypicalDayMealPlan = (
  { deviceState, showTitle, typicalDayMealPlan, isRecipe, showSearchBarIntro },
  context,
  className,
) => (
  <section className={className}>
    <Wrapper padding="lg" width="lg">
      <header className="header-section">
        <Wrapper width="sm">
          {showTitle && (
            <>
              <TextNew.Script
                size={deviceState >= DeviceState.SM ? FontSizes.LG : FontSizes.SM}
                color={AccentColors.ACCENT_PURPLE}
                cid="typical-day-title"
                element={ElementTypes.H2}
              >
                {typicalDayMealPlan?.[isRecipe ? 'recipeTitle' : 'title']}
              </TextNew.Script>

              <TextNew.Serif
                cid="typical-day-subtitle"
                size={deviceState >= DeviceState.MD ? FontSizes.SM : FontSizes.XS}
              >
                {typicalDayMealPlan?.[isRecipe ? 'recipeSubtitle' : 'subtitle']}
              </TextNew.Serif>
            </>
          )}
        </Wrapper>
      </header>

      <RecipeTiles data={typicalDayMealPlan} />
      <PublicHorizontalSearchBar showIntro={showSearchBarIntro} locale="typicalDay.search" />
    </Wrapper>
  </section>
);

TypicalDayMealPlan.propTypes = {
  showTitle: PropTypes.bool,
  isRecipe: PropTypes.bool,
  showSearchBarIntro: PropTypes.bool,
  deviceState: PropTypes.number,
  typicalDayMealPlan: PropTypes.object,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'TypicalDayMealPlan')(TypicalDayMealPlan),
);
