import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, { FontWeight, FontSizes } from 'components/atoms/TextNew';
import withDeviceState, { QueryHelpers } from 'common/src/app/util/device-state/withDeviceState';
import './nearest-group-information-box.scss';

/**
 * A box that display custom extra information or new group opening dates
 * See following link for more infromation
 * https://slimmingworlddigital.atlassian.net/wiki/spaces/SWD/pages/1120043177/Group+search+knowledge+shaf
 */

const NearestGroupInformationBox = (
  { information, queryHelper },
  context,
  className,
  dataTestid,
) => (
  <div data-testid={dataTestid} className={className}>
    <TextNew.Sans.SM
      size={queryHelper === QueryHelpers.DESKTOP ? FontSizes.SM : FontSizes.XS}
      weight={FontWeight.LIGHT}
    >
      {information}
    </TextNew.Sans.SM>
  </div>
);

NearestGroupInformationBox.propTypes = {
  information: PropTypes.node,
  queryHelper: PropTypes.string,
};

export default withDeviceState()(
  withFunctionalClassName(
    ComponentType.MOLECULE,
    'NearestGroupInformationBox',
  )(NearestGroupInformationBox),
);
