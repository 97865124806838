import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextNew, { AccentColors, Colors } from 'components/atoms/TextNew';
import Carousel from 'components/organisms/Carousel';
import Wrapper from 'components/atoms/Wrapper';
import Row from 'components/atoms/Row';
import PublicHorizontalSearchBar from 'components/molecules/PublicHorizontalSearchBar';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import isString from 'lodash/find';
import Arrows from '../atoms/Arrows';
import SingleDayMenu from '../atoms/SingleDayMenu';

import './seven-day-menu-carousel.scss';

/**
 * If set, takes day url param as a string and returns the zero-based day integer
 * @example "day-3" returns 2
 */
const transformDayOrGetDefault = dayString => {
  if (isString(dayString)) {
    const stringCheck = dayString.match(/\d/g);

    if (stringCheck) {
      return Number(stringCheck) - 1;
    }
  }
  return 0;
};

const SevenDayMenuCarousel = ({
  updateLocation,
  menuData,
  dayParam,
  menuTypeParam,
  healthyExtraDetail,
  deviceState,
}) => {
  const [currentDay, setCurrentDay] = useState(transformDayOrGetDefault(dayParam));
  const displayCurrentDay = currentDay + 1;

  useEffect(() => {
    setCurrentDay(transformDayOrGetDefault(dayParam));
  }, [dayParam]);

  return (
    <div data-testid="menu-block" className="menu-block">
      <Wrapper>
        <div className={classNames('carousel-title')}>
          <TextNew.Serif.SM localeId="sevenDayMenu.carousel.title" />
          <TextNew.Script.SM
            color={Colors.BRAND}
            dataTestid="active-day"
            localeId="sevenDayMenu.carousel.title2"
            localeParams={{ ACTIVE: displayCurrentDay }}
          />
        </div>

        {!menuData ? (
          <div>
            <TextNew.Script.XS color={AccentColors.ACCENT_PURPLE} localeId="sevenDayMenu.noMenu" />
          </div>
        ) : (
          <Wrapper>
            <Carousel
              settings={{
                infinite: true,
                nextArrow: (
                  <Arrows
                    nextSlideIndex={
                      displayCurrentDay === menuData.length ? 1 : displayCurrentDay + 1
                    }
                    type="next"
                  />
                ),
                prevArrow: (
                  <Arrows
                    prevSlideIndex={currentDay === 0 ? menuData.length : displayCurrentDay - 1}
                    type="prev"
                    textColor={AccentColors.ACCENT_RED}
                  />
                ),
                initialSlide: transformDayOrGetDefault(dayParam),
                adaptiveHeight: false,
                afterChange: current => {
                  updateLocation(menuTypeParam, current + 1);
                  setCurrentDay(current);
                },
              }}
            >
              {menuData.map((day, index) => (
                <SingleDayMenu
                  currentDayIndex={displayCurrentDay}
                  key={`singleDayMenu-${index}`}
                  dayIndex={index + 1}
                  day={day}
                />
              ))}
            </Carousel>
            {healthyExtraDetail && (
              <Wrapper>
                <TextNew.Sans.SM cid="subtitle" dataTestid="healthy-extra-detail">
                  {healthyExtraDetail}
                </TextNew.Sans.SM>
              </Wrapper>
            )}
            <Row className="carousel-sub" column={deviceState < DeviceState.LG}>
              <PublicHorizontalSearchBar
                stacked={deviceState <= DeviceState.SM || deviceState >= DeviceState.XL}
                locale="typicalDay.search"
              />
            </Row>
          </Wrapper>
        )}
      </Wrapper>
    </div>
  );
};

SevenDayMenuCarousel.propTypes = {
  updateLocation: PropTypes.func,
  menuData: PropTypes.array,
  menuTypeParam: PropTypes.string,
  dayParam: PropTypes.string,
  healthyExtraDetail: PropTypes.string,
  deviceState: PropTypes.number,
};

export default withDeviceState()(SevenDayMenuCarousel);
