/* eslint-disable class-methods-use-this */

import stringify from 'qs/lib/stringify';

/**
 * Formats the data according to the MediaMonks REST API spec, where the action is appended to
 * the base-url.
 */
class RESTOutputHandler {
  /**
   * @param {string} action
   * @param {any} data
   * @param {IGatewayOptions} options
   * @returns {any} data
   */
  format(action, data, options) {
    /* eslint-disable no-param-reassign */
    options.url += action;

    if (options.params) {
      options.url += options.url.indexOf('?') >= 0 ? '&' : '?';
      options.url += stringify(options.params);
    }

    // make headers optional
    options.headers = options.headers || {};

    // Optional body parameters no longer work since upgrading to .NET Core 2.0
    if (options.method && options.method === 'POST') {
      data = data || {};
    }

    if (data) {
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(data);
    }
    /* eslint-disable no-param-reassign */

    return options;
  }
}

export default RESTOutputHandler;
