import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';
import {
  GET_STATIC_CONTENT,
  getStaticContent,
} from 'common/src/app/actions/resources/staticContentActions';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { API_CONSULTANT_CAREERS_PAGE_BACKGROUND } from 'common/src/app/data/apiComponents';
import { API_STATIC_PUBLIC_OUR_FRANCHISE_PACKAGE } from 'common/src/app/data/apiStatics';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import {
  staticPageSelector,
  contentComponentSelector,
} from 'common/src/app/selectors/publicSelectors';
import CallOutBlock from '../../atoms/CallOutBlock';
import OurFranchisePackage from './OurFranchisePackage';

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT);

const mapStateToProps = state => ({
  loading: pageLoading(state),
  pageData: staticPageSelector(state, API_STATIC_PUBLIC_OUR_FRANCHISE_PACKAGE),
  pageBackgroundImage: contentComponentSelector(
    state,
    API_CONSULTANT_CAREERS_PAGE_BACKGROUND,
    'image',
  ),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  clientOnly: ({}, dispatch) =>
    dispatch(getStaticContent(API_STATIC_PUBLIC_OUR_FRANCHISE_PACKAGE, true)),
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(prepareComponent(CallOutBlock, { showBackground: false })),
      dispatch(getComponent(API_CONSULTANT_CAREERS_PAGE_BACKGROUND)),
    ]),
});

export default compose(addInitAction, connected)(OurFranchisePackage);
