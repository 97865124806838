import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Configuration from 'common/src/app/config/Configuration';
import { poundsToKilograms } from 'common/src/app/util/conversionUtils';
import DisplayWeight from '../DisplayWeight';
import TextNew, { Colors } from '../TextNew';
import './success-story-stats.scss';

/**
 * Direct copy of success story stats
 */
const SuccessStatsTile = ({ startWeight, lostWeight, hasTileColor }, context, className) => (
  <div className={className}>
    {startWeight > 0 && (
      <div className="line">
        <TextNew.FormalLight
          color={hasTileColor ? Colors.PRIMARY_LIGHT : Colors.MID_TONE}
          localeId="successStatsTile.startWeight"
        />
        <TextNew.Formal color={hasTileColor ? Colors.PRIMARY_LIGHT : Colors.DARK_TONE}>
          {/** TODO: https://slimmingworlddigital.atlassian.net/browse/SWO-3261 */}
          <DisplayWeight
            weightUnit={Configuration.defaultWeightUnit}
            imperialWeightUseStones={Configuration.imperialWeightUseStones}
            weight={poundsToKilograms(startWeight)}
          />
        </TextNew.Formal>
      </div>
    )}
    {lostWeight > 0 && (
      <div className="line">
        <TextNew.FormalLight
          color={hasTileColor ? Colors.PRIMARY_LIGHT : Colors.MID_TONE}
          localeId="successStatsTile.lostWeight"
        />
        <TextNew.Formal color={hasTileColor ? Colors.PRIMARY_LIGHT : Colors.DARK_TONE}>
          {/** TODO: https://slimmingworlddigital.atlassian.net/browse/SWO-3261 */}
          <DisplayWeight
            weightUnit={Configuration.defaultWeightUnit}
            imperialWeightUseStones={Configuration.imperialWeightUseStones}
            weight={poundsToKilograms(lostWeight)}
          />
        </TextNew.Formal>
      </div>
    )}
  </div>
);

SuccessStatsTile.propTypes = {
  startWeight: PropTypes.number,
  lostWeight: PropTypes.number,
  hasTileColor: PropTypes.bool,
};

export default withFunctionalClassName(ComponentType.ATOM, 'SuccessStatsTile')(SuccessStatsTile);
