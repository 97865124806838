import PropTypes from 'prop-types';
import VideoType from '../../../data/enum/VideoType';
import ImagePropTypes from '../ImagePropTypes';

const propType = {
  video: PropTypes.shape({
    video: PropTypes.shape({
      _type: PropTypes.oneOf([VideoType.YOUTUBE, VideoType.BRIGHTCOVE, VideoType.LEGACY]),
      videoId: PropTypes.string.isRequired,
      autohide: PropTypes.number,
      autoPlay: PropTypes.number,
      controls: PropTypes.number,
      rel: PropTypes.number,
    }).isRequired,
    videoThumbnail: ImagePropTypes,
    title: PropTypes.string,
    videoCaption: PropTypes.string,
  }),
};
export default propType;
