/* global 'molecule','CommentButton' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Colors from 'common/src/app/data/enum/Colors';
import Configuration from 'common/src/app/config/Configuration';
import EventButton from '../EventButton';
import LocaleMessage from '../../atoms/LocaleMessage';

const CommentButton = ({ to, onClick, count, hideLabel }, context, className) => (
  <div className={className}>
    <EventButton
      to={to}
      rounded
      iconSize={20}
      textColor={Colors.MID_TONE}
      rounded
      icon={Configuration.icons.commented}
      label={
        hideLabel ? (
          Number(count)
        ) : (
          <LocaleMessage id="general.post.comment.cta" params={{ TOTAL_COMMENTS: Number(count) }} />
        )
      }
      onClick={onClick}
    />
  </div>
);

CommentButton.defaultProps = {
  count: 0,
};

CommentButton.propTypes = {
  to: PropTypes.string,
  /**
   * The total comments
   */
  count: PropTypes.number,
  /**
   * Used for only showing the count without the label
   */
  hideLabel: PropTypes.bool,
  /**
   * Handles the comment click
   */
  onClick: PropTypes.func,
};

export default withFunctionalClassName('molecule','CommentButton')(CommentButton);
