import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { API_STATIC_PUBLIC_ICELAND_OUR_PRODUCTS } from 'common/src/app/data/apiStatics';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import IcelandOurProducts from './IcelandOurProducts';

const mapStateToProps = state => ({
  pageData: staticPageSelector(state, API_STATIC_PUBLIC_ICELAND_OUR_PRODUCTS),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction(
  {
    prepared: ({}, dispatch) =>
      dispatch(getStaticContent(API_STATIC_PUBLIC_ICELAND_OUR_PRODUCTS, true)),
  },
  {
    getPrepareKey: componentId => componentId,
  },
);

export default compose(addInitAction, connected)(IcelandOurProducts);
