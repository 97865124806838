/**
 * Closes any open tags
 * @param text
 * @returns {string} Parsed text
 */
const ensureTagsClosed = text => {
  const properties = ['r', 'b', 'u', 'i'];
  let parsedText = '';
  if (text) {
    parsedText = text;
  }

  for (let i = 0, l = properties.length; i < l; i++) {
    const propertyName = properties[i];
    const openMatch = text.match(new RegExp(`[${propertyName}]`));
    const closeMatch = text.match(new RegExp(`[/${propertyName}]`));
    const openBrackets = openMatch ? openMatch.length : 0;
    const closedBrackets = closeMatch ? closeMatch.length : 0;
    parsedText += `[/${propertyName}]`.repeat(
      openBrackets - closedBrackets > 0 ? openBrackets - closedBrackets : 0,
    );
  }

  return parsedText;
};

/**
 * Replaces UBB codes to HTML tags
 * @param returnPromise returns a promise (useful when chaining transformations)
 * @param input
 */
export const transformUbbToHTML = (input, returnPromise = false) => {
  const output = ensureTagsClosed(input)
    .replace(/\[r]/g, '<p>')
    .replace(/\[\/r]/g, '</p>')
    .replace(/\[i]/g, '<em class="italic">')
    .replace(/\[\/i]/g, '</em>')
    .replace(/\[b]/g, '<strong class="bold">')
    .replace(/\[\/b]/g, '</strong>')
    .replace(/\[u]/g, '<span class="underline">')
    .replace(/\[\/u]/g, '</span>')
    .replace(/<div><\/div>/g, '<div>&nbsp;</div>');

  if (returnPromise) {
    return Promise.resolve(output);
  }

  return output;
};

export const stripUbb = input =>
  input
    .replace(/\[r]/g, '')
    .replace(/\[\/r]/g, ' ')
    .replace(/\[i]/g, '')
    .replace(/\[\/i]/g, '')
    .replace(/\[b]/g, '')
    .replace(/\[\/b]/g, '')
    .replace(/\[u]/g, '')
    .replace(/\[\/u]/g, '')
    .replace(/<div><\/div>/g, '');
