import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import QuickLinks from './QuickLinks';

const mapStateToProps = state => ({
  location:
    state.routing.locationBeforeTransitions.pathname +
    state.routing.locationBeforeTransitions.search,
});

const connected = connect(mapStateToProps);

export default compose(connected)(QuickLinks);
