/* global 'page','UserTesting' */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import TextNew, { Colors, FontSizes } from 'components/atoms/TextNew';
import Loader from 'components/atoms/Loader';
import LocationFinderMap from 'components/molecules/LocationFinderMap';
import { Department, DepartmentDetails } from 'common/src/app/data/enum/DepartmentPhoneNumbersInfo';
import VideoBlock from 'components/molecules/BlockContent/components/atoms/VideoBlock';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import IconName from 'common/src/app/data/enum/IconName';
import Image from 'components/atoms/Image';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import UserTestingActivityBlock from './components/molecules/UserTestingActivityBlock';
import UserTestingHeader from './components/molecules/UserTestingHeader';
import UserTestingResearcher from './components/molecules/UserTestingResearcher';
import UserTestingTestimonial from './components/molecules/UserTestingTestimonial';
import UserTestingScrollTo from './components/molecules/UserTestingScrollTo';

import './user-testing.scss';

const UserTestingContentType = {
  SENIOR_DIGITAL_RESEARCHER: 'Senior Digital Researcher',
  SENIOR_DIGITAL_RESEARCHER_BIO: 'Senior Digital Researcher bio',
  USER_EXPERIENCE: 'User experience',
  ONE_TO_ONE: 'One-to-one',
  GROUP_WORKSHOP: 'Group workshop',
  PLEASE_NOTE: 'Please note',
  WHAT_DO_I_RECEIVE_FOR_TAKING_PART: 'What do I receive for taking part?',
  TESTIMONIAL_QUOTE_INTRO: 'Testimonial quote intro',
  TESTIMONIAL_QUOTE_BODY: 'Testimonial quote body',
  TESTIMONIAL_AUTHOR: 'Testimonial author ',
  VIDEO: 'Head office video',
  VIDEO_THUMBNAIL: 'Video thumbnail',
  MAP_HEADER: 'Map location header text',
};

const clientOnly = typeof window !== 'undefined';

const UserTesting = (
  { deviceState, pageContent, isLoading, scrollToElement },
  { getMessage },
  className,
) => {
  const getContentType = type => pageContent?.content.find(content => content.title === type);

  if (isLoading) {
    return <Loader />;
  }

  const { image, description, title } = pageContent || {};

  // Sections
  const researcher = getContentType(UserTestingContentType.SENIOR_DIGITAL_RESEARCHER);
  const researcherBio = getContentType(UserTestingContentType.SENIOR_DIGITAL_RESEARCHER_BIO);
  const userTesting = getContentType(UserTestingContentType.USER_EXPERIENCE);
  const oneToOne = getContentType(UserTestingContentType.ONE_TO_ONE);
  const groupWorkshop = getContentType(UserTestingContentType.GROUP_WORKSHOP);
  const disclaimer = getContentType(UserTestingContentType.PLEASE_NOTE);
  const whatIGet = getContentType(UserTestingContentType.WHAT_DO_I_RECEIVE_FOR_TAKING_PART);
  const userQuoteBody = getContentType(UserTestingContentType.TESTIMONIAL_QUOTE_BODY);
  const userQuoteIntro = getContentType(UserTestingContentType.TESTIMONIAL_QUOTE_INTRO);
  const quoteAuthor = getContentType(UserTestingContentType.TESTIMONIAL_AUTHOR);
  const video = getContentType(UserTestingContentType.VIDEO);
  const videoThumbnail = getContentType(UserTestingContentType.VIDEO_THUMBNAIL);
  const mapHeader = getContentType(UserTestingContentType.MAP_HEADER);

  // User refs
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userTestingRef = clientOnly && useRef(UserTestingContentType.USER_EXPERIENCE);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const oneToOneRef = clientOnly && useRef(UserTestingContentType.ONE_TO_ONE);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const groupWorkshopRef = clientOnly && useRef(UserTestingContentType.GROUP_WORKSHOP);

  const scrollElements = [
    {
      locale: 'userTesting.userTestingButton',
      iconSize: 16,
      icon: IconName.APP_MOBILE,
      reference: userTestingRef,
    },
    {
      locale: 'userTesting.oneToOneButton',
      iconSize: 19,
      icon: IconName.PERSON_BORDER,
      reference: oneToOneRef,
    },
    {
      locale: 'userTesting.groupWorkshopButton',
      iconSize: 25,
      icon: IconName.MENU_SLIMMING_GROUP,
      reference: groupWorkshopRef,
    },
  ];

  return (
    <div className={className}>
      <UserTestingHeader image={image} title={title} description={description} />
      {researcher && researcherBio && (
        <UserTestingResearcher
          image={researcher.image}
          name={researcher.text}
          bio={researcherBio.text}
          title={researcher.title}
        />
      )}
      <UserTestingScrollTo scrollToElement={scrollToElement} scrollTo={scrollElements} />

      {userTesting && (
        <UserTestingActivityBlock
          reference={userTestingRef}
          type={UserTestingContentType.USER_EXPERIENCE}
          icon={IconName.APP_MOBILE}
          iconSize={deviceState > DeviceState.MD ? 20 : 14}
          title={userTesting.title}
          image={userTesting?.image}
          text={userTesting.text}
          imageRatio={deviceState > DeviceState.MD ? 64 / 23 : 1 / 1}
        />
      )}

      {oneToOne && (
        <UserTestingActivityBlock
          reference={oneToOneRef}
          type={UserTestingContentType.ONE_TO_ONE}
          icon={IconName.PERSON_BORDER}
          iconSize={deviceState > DeviceState.MD ? 40 : 24}
          title={oneToOne.title}
          text={oneToOne.text}
        />
      )}
      {groupWorkshop && (
        <UserTestingActivityBlock
          reference={groupWorkshopRef}
          type={UserTestingContentType.GROUP_WORKSHOP}
          icon={IconName.MENU_SLIMMING_GROUP}
          iconSize={deviceState > DeviceState.MD ? 44 : 26}
          title={groupWorkshop.title}
          image={groupWorkshop?.image}
          text={groupWorkshop.text}
          imageRatio={deviceState > DeviceState.LG ? 736 / 195 : 1 / 1}
        />
      )}
      {disclaimer && (
        <div className="info-block disclaimer">
          <TextNew.Serif.SM>{disclaimer.title}</TextNew.Serif.SM>
          <RichTextBlock text={disclaimer.text} />
        </div>
      )}
      {whatIGet && (
        <div className="info-block what-i-get">
          <TextNew.Serif.MD
            size={DeviceState.SM ? FontSizes.MD : FontSizes.SM}
            color={Colors.PRIMARY_LIGHT}
          >
            {whatIGet.title}
          </TextNew.Serif.MD>
          <RichTextBlock text={whatIGet.text} />
        </div>
      )}

      {userQuoteBody && userQuoteIntro && quoteAuthor && (
        <UserTestingTestimonial
          header={userQuoteIntro.text}
          quote={userQuoteBody.text}
          author={quoteAuthor}
        />
      )}

      {videoThumbnail?.image?.src && (
        <div className="video">
          <Image src={videoThumbnail.image.src} ratio={1 / 1} cid="head-office-video" />
          {video && <VideoBlock video={video?.video} showVideoThumbnail={false} />}
        </div>
      )}
      {mapHeader && <RichTextBlock cid="map-header" text={mapHeader.text} />}
      {typeof window !== 'undefined' && (
        <LocationFinderMap
          latLg={DepartmentDetails[Department.HEAD_OFFICE].latLngLocation}
          address={getMessage('userTesting.headOfferAddress')}
        />
      )}
    </div>
  );
};

UserTesting.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

UserTesting.propTypes = {
  pageContent: PropTypes.object,
  isLoading: PropTypes.bool,
  scrollToElement: PropTypes.func,
  deviceState: PropTypes.number,
};

export default withDeviceState()(withFunctionalClassName('page', 'user-testing')(UserTesting));
