import { apiPost } from './resources/apiActions/apiRequest';
import { getValue } from '../util/injector';
import { GATEWAY_CONTENT } from '../data/Injectables';

export const getAntiForgeryToken = () => () =>
  getValue(GATEWAY_CONTENT).get('/aft', null, {
    credentials: 'include',
  });

export const SEND_CONTACT = 'accountActions/SEND_CONTACT';

const sendContact = values => dispatch => {
  const processedValues = Object.keys(values).reduce((processedField, field) => {
    let value = values[field];
    // Trim any string types before posting
    if (typeof value === 'string') {
      value = value?.trim();
    }
    processedField[field] = value; // eslint-disable-line no-param-reassign
    return processedField;
  }, {});

  return dispatch(getAntiForgeryToken()).then(result =>
    dispatch(
      apiPost(SEND_CONTACT, GATEWAY_CONTENT, '/contact', processedValues, {
        headers: {
          // eslint-disable-next-line no-underscore-dangle
          'X-XSRF-Token': result.data.__RequestVerificationToken,
        },
        credentials: 'include',
      }),
    ),
  );
};

export default sendContact;
