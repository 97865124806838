/* global 'organism','IcelandCategoryCarousel' */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState, { DeviceState } from 'common/src/app/util/device-state/withDeviceState';
import Pages from 'common/src/app/data/enum/Pages';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import Carousel from 'components/organisms/Carousel';
import NavigationButton from 'components/atoms/NavigationButton';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import TextNew, { ElementTypes, FontSizes } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import LocaleMessage from 'components/atoms/LocaleMessage';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import './iceland-category-carousel.scss';

const IcelandCategoryCarousel = (
  { categories, deviceState, title, intro, textBlockBottom },
  context,
  className,
) => {
  const carouselSettings = {
    nextArrow: <NavigationButton iconWidth={24} square direction={DirectionType.RIGHT} />,
    prevArrow: <NavigationButton iconWidth={24} square direction={DirectionType.LEFT} />,
    arrows: deviceState >= DeviceState.MD,
    dots: true,
    initialSlide: 2,
    focusOnSelect: true,
    draggable: deviceState <= DeviceState.MD,
    className: 'center',
    centerPadding: '0px',
    centerMode: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    cssEase: 'cubic-bezier(0.666, 0, 0.333, 1)',
  };

  return (
    <section className={className}>
      <div className="menu-header">
        {title && (
          <TextNew.Script
            size={deviceState > DeviceState.SM ? FontSizes.XL : FontSizes.MD}
            element={ElementTypes.H2}
            cid="menu-header-title"
          >
            {title}
          </TextNew.Script>
        )}

        {intro && (
          <TextNew.Serif
            size={deviceState > DeviceState.SM ? FontSizes.SM : FontSizes.XS}
            element={ElementTypes.DIV}
            cid="menu-intro"
          >
            <RichTextBlock text={intro} />
          </TextNew.Serif>
        )}
      </div>

      <div className="carousel-wrapper">
        <Carousel settings={carouselSettings}>
          {categories.map(category => (
            <div key={category.id} className="category-wrapper">
              <Link
                className="category-link"
                to={`${Pages.UK_PUBLIC_ICELAND_OUR_RANGE}?categories=${category.slug}`}
              >
                <TextNew.Serif.SM cid="title">{category.title}</TextNew.Serif.SM>

                <ResponsiveImage {...category.image} />
              </Link>
            </div>
          ))}
        </Carousel>
      </div>

      <div className="view-full-range">
        {textBlockBottom && (
          <TextNew.Serif
            size={deviceState > DeviceState.SM ? FontSizes.SM : FontSizes.XS}
            element={ElementTypes.DIV}
            cid="full-range-info"
          >
            <RichTextBlock text={textBlockBottom} />
          </TextNew.Serif>
        )}
        <Button to={Pages.UK_PUBLIC_ICELAND_OUR_RANGE}>
          <LocaleMessage id="icelandCategoryCarousel.button" />
        </Button>
      </div>
    </section>
  );
};

IcelandCategoryCarousel.propTypes = {
  categories: PropTypes.array,
  deviceState: PropTypes.number,
  title: PropTypes.string,
  intro: PropTypes.string,
  textBlockBottom: PropTypes.string,
  accentColor: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
};

export default withDeviceState()(
  withFunctionalClassName('organism','IcelandCategoryCarousel', [
    ({ accentColor }) => accentColor && `is-accent-color-${accentColor}`,
  ])(IcelandCategoryCarousel),
);
