/* global 'molecule','LikeButton' */
import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'common/src/app/data/enum/Colors';
import Configuration from 'common/src/app/config/Configuration';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import EventButton from '../EventButton';
import LocaleMessage from '../../atoms/LocaleMessage';
import './like-button.scss';

const LikeButton = ({ toggleActive, count, hasLiked, hideLabel, id }, context, className) => (
  <div className={className}>
    <EventButton
      rounded
      id={id}
      iconSize={20}
      textColor={Colors.MID_TONE}
      icon={Configuration.icons.liked}
      label={
        hideLabel ? (
          count
        ) : (
          <LocaleMessage
            id={`general.post.like.${hasLiked ? 'ctaUnlike' : 'cta'}`}
            params={{ TOTAL_LIKES: count }}
          />
        )
      }
      onClick={toggleActive}
    />
  </div>
);

LikeButton.defaultProps = {
  count: 0,
};

LikeButton.propTypes = {
  /**
   * The total likes
   */
  count: PropTypes.number,
  id: PropTypes.string,
  /**
   * Used for showing different label
   */
  hasLiked: PropTypes.bool,
  /**
   * Used for only showing the count without the label
   */
  hideLabel: PropTypes.bool,
  /**
   * Handles the like click
   */
  toggleActive: PropTypes.func,
};

export default withFunctionalClassName('molecule','LikeButton', ['hasLiked'])(LikeButton);
