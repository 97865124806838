import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReduxFormContext } from 'redux-form/lib/ReduxFormContext';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import Field from 'redux-form/lib/Field';
import debugLib from 'debug';
import { makeGetCustomValidationMeta } from '../reducers/validationReducer';

/**
 * @module enhanced-redux-form/components/EnhancedField
 */

const debug = debugLib('SlimmingWorld:EnhancedField');

const createEnhancedInputComponent = (InputComponent, context) => {
  const ConnectedInputComponent = connect(
    () => {
      const getCustomValidationMeta = makeGetCustomValidationMeta();

      return (state, props) => getCustomValidationMeta(state.enhancedForm.validation, props);
    },
    null,
    (stateProps, dispatchProps, ownProps) => ({
      ...ownProps,
      meta: {
        ...ownProps.meta,
        ...stateProps.meta,
      },
    }),
  )(InputComponent);

  return props => <ConnectedInputComponent {...props} form={context.form} />;
};

/**
 * Should be used instead of the redux-form &lt;Field&gt; component to provide
 * enhanced-redux-form functionality to inputs. The API for this component is exactly the
 * same as the &lt;Field&gt; component. Please see the
 * {@link http://redux-form.com/6.3.1/docs/api/Field.md/|redux-form documentation}
 * for more info.
 * @class EnhancedField
 * @category forms
 */
class EnhancedField extends Component {
  static contextType = ReduxFormContext;

  state = {
    EnhancedInput: createEnhancedInputComponent(this.props.component, this.context),
  };

  componentDidMount() {
    this.context.registerWizardField && this.context.registerWizardField(this.props.name);

    const domNode = findDOMNode(this);
    if (domNode) {
      const input = domNode.querySelector(
        'input[type=text],input[type=email],input[type=password]',
      );
      if (input) {
        const formContext = this.context;
        if (formContext) {
          const values = formContext.values || {};

          if (input.value && values[this.props.name] !== input.value) {
            formContext.dispatch(formContext.change(this.props.name, input.value));
          }
        }
      }
    }
  }

  componentDidUpdate({ component }) {
    if (component !== this.props.component) {
      debug('Ignored change of "component" prop. Prop cannot be changed after mount.');
    }
  }

  render() {
    const { EnhancedInput } = this.state;
    return EnhancedInput ? <Field {...this.props} component={EnhancedInput} /> : null;
  }
}

EnhancedField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any.isRequired,
  name: PropTypes.string,
};

export default EnhancedField;
