import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Icon from '../../atoms/Icon';

import './icon-label.scss';

/**
 * This component create a icon with a label e.g. the like and comment
 *
 * note: When using this make sure to use the getMessage plural implementation for the label
 *
 * @param icon
 * @param label
 * @constructor
 */
const IconLabel = ({ icon, label, width, height, rounded, icondataTestid }, context, className) => (
  <div className={className}>
    <Icon dataTestid={icondataTestid} width={width} height={height} name={icon} rounded={rounded} />
    {label}
  </div>
);

IconLabel.propTypes = {
  /**
   * label
   */
  label: PropTypes.node.isRequired,
  /**
   * Icon
   */
  icon: PropTypes.string.isRequired,
  /**
   * Size
   */
  width: PropTypes.number,
  /**
   * Size
   */
  height: PropTypes.number,
  /**
   * show icon with background and rounded border
   */
  rounded: PropTypes.bool,
  icondataTestid: PropTypes.string,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'IconLabel')(IconLabel);
