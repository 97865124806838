import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { API_COMPONENT_PUBLIC_NAVIGATION_REGISTER_CARD } from 'common/src/app/data/apiComponents';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';
import UKPublicNavigationLinks from './UKPublicNavigationLinks';

const mapStateToProps = state => {
  const cardImage = contentComponentSelector(
    state,
    API_COMPONENT_PUBLIC_NAVIGATION_REGISTER_CARD,
    'image',
  );

  return {
    cardImage,
  };
};

const connected = connect(mapStateToProps);

export default compose(connected)(UKPublicNavigationLinks);
