import { compose } from 'redux';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { marketName } from 'common/src/app/config/market/market.configdefinitions';
import { countryCode } from 'common/src/app/data/enum/Countries';
import { GET_COMPONENT, getComponent } from 'common/src/app/actions/resources/componentActions';
import {
  API_COMPONENT_PUBLIC_ADAPTIVE_PROMOTIONS_UK,
  API_COMPONENT_PUBLIC_ADAPTIVE_PROMOTIONS_IE,
} from 'common/src/app/data/apiComponents';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import HomepageCampaignBlock from './HomepageCampaignBlock';

const componentLoading = makeIsLoadingSelector(GET_COMPONENT);

const homepageCampaignBlockContentIdentifier = () => {
  if (marketName === countryCode.IRELAND) {
    return API_COMPONENT_PUBLIC_ADAPTIVE_PROMOTIONS_IE;
  }

  return API_COMPONENT_PUBLIC_ADAPTIVE_PROMOTIONS_UK;
};

const mapStateToProps = state => ({
  isLoading: componentLoading(state),
  promotions: contentComponentSelector(
    state,
    homepageCampaignBlockContentIdentifier(),
    'promotions',
  ),
});
const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(homepageCampaignBlockContentIdentifier())),
});

export default compose(addInitAction, connected)(HomepageCampaignBlock);
