import { kilogramsToPounds } from '../../util/conversionUtils';

/**
 * Converts values from kilos to pounds
 * @param {string} kilos A string that contains an integer representing
 * the amount of kilograms
 *
 * @returns {object} The resulting object with { fullPounds, remainingPounds }
 */
const decomposeWeightImperialToMetric = kilos => {
  if (typeof kilos === 'undefined') {
    return { fullPounds: '', remainingPounds: '' };
  }

  const parsedKilos = kilos || 0;

  const pounds = kilogramsToPounds(parsedKilos);
  const fullPounds = Math.floor(pounds);
  const remainingPounds = Math.round(parseFloat((pounds % 1) * 100));

  return { fullPounds, remainingPounds };
};

export default decomposeWeightImperialToMetric;
