import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { scrollToElement } from 'common/src/app/actions/scrollActions';
import { longContactTimes } from 'common/src/app/selectors/featureFlagSelectors';

import ContactUsSuccessMessage from './ContactUsSuccessMessage';

const mapDispatchToProps = {
  scrollToElement,
};

const mapStateToProps = state => ({
  showLongResponseMessage: longContactTimes(state),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(ContactUsSuccessMessage);
