import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, { ElementTypes, Colors } from 'components/atoms/TextNew';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import errorBoundary from 'components/hoc/ErrorBoundary';
import Loader from 'components/atoms/Loader';
import OurStoryBlockContent from './components/molecules/OurStoryBlockContent';
import './our-story.scss';

const OurStory = ({ pageContent }, context, className) => {
  const { desktopHeaderImage, content, title } = pageContent || {};

  return (
    <main className={className}>
      {!pageContent ? (
        <Loader />
      ) : (
        <>
          <HeroBarPublic image={desktopHeaderImage}>
            <TextNew.Script.MD
              cid="hero-bar-public-script-heading"
              element={ElementTypes.H1}
              color={Colors.BRAND}
            >
              {title}
            </TextNew.Script.MD>
          </HeroBarPublic>

          <div className="wrapper">
            <OurStoryBlockContent content={content} />
          </div>
        </>
      )}
    </main>
  );
};

OurStory.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

OurStory.propTypes = {
  pageContent: PropTypes.shape({
    desktopHeaderImage: PropTypes.shape({
      ...imageShape,
    }),
    content: PropTypes.arrayOf(
      PropTypes.shape({
        _type: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
  }),
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName(ComponentType.PAGE, 'OurStory'),
)(OurStory);
