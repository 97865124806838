import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import GridItemType from 'common/src/app/data/enum/GridItemType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import DataTestidOverides from 'common/src/app/data/enum/DataTestidOverides';
import DataLayerKeys from 'common/src/app/data/enum/Tracking/DataLayerKeys';
import { FontSizes, FontFamily, FontWeight } from 'components/atoms/TextNew';
import recipeDetailShape from 'common/src/app/util/proptypes/apiEntities/recipeDetail';
import Button from '../../atoms/Button';
import Wrapper from '../../atoms/Wrapper';
import LocaleMessage from '../../atoms/LocaleMessage';
import Row from '../../atoms/Row';
import MasterTile from '../../organisms/MasterTile';
import Grid from '../../organisms/Grid';
import GridItem from '../../organisms/GridItem';
import StructuredDataGenerator from '../../atoms/StructuredDataGenerator';
import GridControlBar from '../../organisms/GridControlBar';
import Loader from '../../atoms/Loader';
import DomEventTracker from '../../atoms/DomEventTracker';

const LandingGrid = (
  {
    results = [],
    pagination,
    isInitializing,
    load,
    hideSortControls,
    gridColumnCount,
    allowLoadMore,
    featuredTile,
    filter,
    tileColors,
    additionalParams, // eslint-disable-line no-unused-vars
    featuredItems = [],
  },
  context,
  className,
) => {
  if (isInitializing || !results) {
    return (
      <div className={className}>
        <Loader />
      </div>
    );
  }

  const { limit, total, after } = pagination || {};

  return (
    <div className={className}>
      <Wrapper cid="landing-grid-wrapper">
        {!hideSortControls && total && (
          <GridControlBar loadAll={load} resultCount={Math.min(limit, total)} total={total} />
        )}

        <Row wrap justifycenter>
          <Grid>
            {featuredTile && (
              <GridItem key={featuredTile?.id} type={GridItemType.FEATURED}>
                <MasterTile
                  dataTestId="highlighted-tile"
                  featureType={GridItemType.FEATURED}
                  {...featuredTile}
                  tileColor={tileColors?.featured}
                  titleStyle={{
                    fontFamily: FontFamily.SCRIPT,
                    fontWeight: FontWeight.NORMAL,
                    fontSize: FontSizes.XS,
                  }}
                />
                {/* eslint-disable-next-line no-underscore-dangle */}
                <StructuredDataGenerator data={featuredTile} type={featuredTile._type} />
              </GridItem>
            )}

            {featuredItems
              ?.filter(filter)
              .filter(item => item)
              .map(item => {
                const gridType =
                  (gridColumnCount === 3 && GridItemType.THIRDS) || GridItemType.DEFAULT;
                return (
                  <GridItem key={item.id} type={gridType}>
                    <MasterTile featureType={gridType} {...item} />
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    <StructuredDataGenerator data={item} type={item._type} />
                  </GridItem>
                );
              })}

            {results
              ?.filter(filter)
              .filter(item => item)
              .map((item, index) => {
                const gridType =
                  (index === featuredTile && GridItemType.FEATURED) ||
                  (gridColumnCount === 3 && GridItemType.THIRDS) ||
                  GridItemType.DEFAULT;
                return (
                  <GridItem key={item.id} type={gridType}>
                    <MasterTile
                      featureType={gridType}
                      {...item}
                      tileColor={tileColors && tileColors[index]}
                    />
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    <StructuredDataGenerator data={item} type={item._type} />
                  </GridItem>
                );
              })}
          </Grid>
        </Row>
        <Row justifycenter>
          {results && after && !after.atEnd && allowLoadMore && (
            <DomEventTracker
              className="load-more"
              meta={{ [DataLayerKeys.LOAD_MORE]: results.length }}
            >
              <Button
                dataTestid={DataTestidOverides.LOAD_MORE}
                type="button"
                isLoading={isInitializing}
                onClick={() => load()}
              >
                <LocaleMessage id="general.cta.loadMore" />
              </Button>
            </DomEventTracker>
          )}
        </Row>
      </Wrapper>
    </div>
  );
};

LandingGrid.propTypes = {
  results: PropTypes.array,
  // This is currently only used for featured recipes
  featuredItems: PropTypes.arrayOf(PropTypes.shape(recipeDetailShape)),
  featuredTile: PropTypes.object,
  pagination: PropTypes.shape({
    total: PropTypes.string,
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    after: PropTypes.shape({
      count: PropTypes.number.isRequired,
      atEnd: PropTypes.bool.isRequired,
    }),
    before: PropTypes.shape({
      count: PropTypes.number.isRequired,
      atBegin: PropTypes.bool.isRequired,
    }).isRequired,
  }),
  isInitializing: PropTypes.bool,
  hideSortControls: PropTypes.bool,
  gridColumnCount: PropTypes.number,
  load: PropTypes.func,
  allowLoadMore: PropTypes.bool.isRequired,
  filter: PropTypes.func,
  tileColors: PropTypes.object,
  // Other params to pass to the API, e.g. to use in filtered results
  additionalParams: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
};

LandingGrid.defaultProps = {
  allowLoadMore: true,
  buttonProps: 'isSecondary',
  filter: item => item,
};

export default withFunctionalClassName(ComponentType.TEMPLATE, 'LandingGrid')(LandingGrid);
