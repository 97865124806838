/* global 'molecule','BackLink' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import IconName from 'common/src/app/data/enum/IconName';
import LocaleMessage from '../../atoms/LocaleMessage';
import Icon from '../../atoms/Icon';
import TextNew, { ElementTypes } from '../../atoms/TextNew';
import './back-link.scss';

const executeBackLink = (onClick, backlink, useBrowserBack, navigateBackLink) => {
  if (onClick) {
    return onClick();
  }
  if (backlink || !useBrowserBack) {
    navigateBackLink();
  } else {
    history.back();
  }

  return null;
};

/**
 * BackLink Molecule
 * Used for navigating back using react-router
 */
const BackLink = (
  { onClick, backlink, backlinkCopy, useBrowserBack, navigateBackLink, labelVisible },
  context,
  className,
  dataTestid,
) => (
  <button
    className={className}
    onClick={() => executeBackLink(onClick, backlink, useBrowserBack, navigateBackLink)}
    type="button"
    id={dataTestid}
    data-testid={dataTestid}
  >
    <div className="flex-wrapper">
      <Icon name={IconName.CHEVRON} direction={DirectionType.LEFT} />
      {labelVisible ? (
        <TextNew.FormalOriginal
          element={ElementTypes.SPAN}
          localeId={backlinkCopy || 'general.cta.back'}
        />
      ) : (
        <div className="screen-reader">
          <LocaleMessage id={backlinkCopy || 'general.cta.back'} />
        </div>
      )}
    </div>
  </button>
);

BackLink.defaultProps = {
  hideIfNoValidBackRoute: true,
  useBrowserBack: false,
  labelVisalbe: true,
};

BackLink.propTypes = {
  backlink: PropTypes.string,
  backlinkCopy: PropTypes.string,
  useBrowserBack: PropTypes.bool,
  onClick: PropTypes.func,
  labelVisible: PropTypes.bool,
  navigateBackLink: PropTypes.func.isRequired,
  hideIfNoValidBackRoute: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  hasValidBackRoute: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'BackLink', [
  'hide-if-no-valid-back-route',
  'has-valid-back-route',
])(BackLink);
