import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import Wrapper from 'components/atoms/Wrapper';
import Image from 'components/atoms/Image';

import './hero-bar-public.scss';

/**
 * Full width 'hero' style section, typically image and content (child elements)
 */
const HeroBarPublic = ({ children, image, headerRatio }, context, className, dataTestid) => (
  <div data-testid={dataTestid} className={className}>
    {image && <Image ratio={headerRatio} {...image} />}

    {children && <Wrapper.XL>{children}</Wrapper.XL>}
  </div>
);

HeroBarPublic.propTypes = {
  image: PropTypes.shape({ ...imageShape }),
  /**
   * Content to display below the hero image
   */
  children: PropTypes.node,
  headerRatio: PropTypes.number,
};

export default withFunctionalClassName(ComponentType.ORGANISM, 'HeroBarPublic')(HeroBarPublic);
