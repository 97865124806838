import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GroupSearchFieldNames from 'common/src/app/data/enum/FieldNames/GroupSearchFieldNames';
import Loader from 'components/atoms/Loader';
import formikErrorCodes from 'common/src/app/validation/formikErrorCodes';

const DropDownResults = ({
  towns,
  entry,
  getItemProps,
  getMenuProps,
  highlightedIndex,
  loadingResults,
  setErrors,
  hasNoResultError,
  setFieldValue,
}) => {
  const filteredResults =
    towns && towns.filter(each => each.toLowerCase().startsWith(entry.toLowerCase()));

  useEffect(() => {
    if (towns) {
      // Generate a formik error if there are no results
      // We also set a field 'entryMatchesTown' that acts as a validator
      // as validation function will overwrite the error object!
      // https://github.com/jaredpalmer/formik/issues/1309

      if (!hasNoResultError && filteredResults.length === 0) {
        setErrors({
          [GroupSearchFieldNames.FREE_TEXT]: formikErrorCodes.NO_GROUP_SEARCH_TOWNS,
        });
        setFieldValue('entryMatchesTown', false);
      }

      // If we're in error state, and we go out of error state, reset the errors
      if (hasNoResultError && filteredResults.length > 0) {
        setErrors({});
        setFieldValue('entryMatchesTown', true);
      }
    }
  }, [filteredResults, hasNoResultError, towns, setFieldValue, setErrors]);

  return (
    (!hasNoResultError || loadingResults) && (
      <ul
        data-testid="dropdown-results-list"
        {...getMenuProps()}
        className={hasNoResultError ? '' : 'towns-drop-downs'}
      >
        {filteredResults ? (
          filteredResults.map((place, index) => (
            // Because getItemProps() provides accesibility goodness
            // eslint-disable-next-line
            <li
              data-testid="dropdown-results-list-items"
              key={place}
              {...getItemProps({ item: { value: place } })}
              className="each-result"
              style={{
                backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
              }}
            >
              {place}
            </li>
          ))
        ) : (
          <Loader />
        )}
      </ul>
    )
  );
};

export default DropDownResults;

DropDownResults.propTypes = {
  getItemProps: PropTypes.func.isRequired,
  getMenuProps: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number,
  selectedItem: PropTypes.bool,
  loadingResults: PropTypes.bool,
  towns: PropTypes.array,
  entry: PropTypes.string,
  hasNoResultError: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
};
