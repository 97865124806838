import { createSelector } from 'reselect';

const featureFlagsSelector = state => state.config.environmentConfig.featureFlags;

export const shopForAllEnabledSelector = createSelector(
  featureFlagsSelector,
  featureFlags => featureFlags?.shopForAll?.toLowerCase() === 'true',
);

export const onlineTransferEnabledSelector = createSelector(
  featureFlagsSelector,
  featureFlags => featureFlags?.transferToOnline?.toLowerCase() === 'true',
);

export const groupTransferEnabledSelector = createSelector(
  featureFlagsSelector,
  featureFlags => featureFlags?.transferToGroup?.toLowerCase() === 'true',
);

export const groupRegistrationActive = createSelector(
  featureFlagsSelector,
  featureFlags => featureFlags?.groupRegOpen?.toLowerCase() === 'true',
);

export const longContactTimes = createSelector(
  featureFlagsSelector,
  featureFlags => featureFlags?.longResponseTimes?.toLowerCase() === 'true',
);
