import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Pages from 'common/src/app/data/enum/Pages';
import TextNew, { Colors, FontSizes, ElementTypes, FontWeight } from '../../atoms/TextNew';
import Image from '../../atoms/Image';
import Button from '../../atoms/Button';
import LocaleMessage from '../../atoms/LocaleMessage';
import './better-health-tile.scss';

const BetterHealthTile = ({ assets, deviceState }, context, className) => {
  if (!assets) return null;
  return (
    <article className={className}>
      <Image isBackgroundImage src={assets?.nestedContent[0].image.src} alt="Background">
        <div className="tile-content">
          <header>
            <h2>
              <TextNew.Serif.SM
                localeId="betterHealthTile.titleSerif"
                weight={FontWeight.BOLD}
                cid="head-top"
                element={ElementTypes.SPAN}
              />
              <TextNew.Script.XL
                localeId="betterHealthTile.titleScript"
                cid="head-bottom"
                element={ElementTypes.SPAN}
              />
            </h2>
          </header>
          <aside className="special-offer-wrapper">
            <TextNew.Serif.SM
              localeId="betterHealthTile.specialOffer"
              weight={FontWeight.BOLD}
              cid="special-offer"
              element={ElementTypes.SPAN}
              color={Colors.PRIMARY_LIGHT}
            />
          </aside>

          <TextNew.Sans.SM localeId="betterHealthTile.body" weight={FontWeight.NORMAL} cid="body" />

          <div className="cta-and-logo">
            {assets && (
              <Image
                ratio={429 / 285}
                src={assets?.nestedContent[1].image?.src}
                alt="Let's do this"
              />
            )}
            <Button to={Pages.UK_PUBLIC_BETTER_HEALTH}>
              <LocaleMessage id="betterHealthTile.cta" />
            </Button>
          </div>
          <TextNew.Sans.XS
            size={deviceState >= DeviceState.XL ? FontSizes.SM : FontSizes.XS}
            localeId="betterHealthTile.terms"
            cid="terms"
          />
        </div>
      </Image>
    </article>
  );
};

BetterHealthTile.propTypes = {
  assets: PropTypes.object,
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'BetterHealthTile')(BetterHealthTile),
);
