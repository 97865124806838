import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { API_COMPONENT_GROUP_OR_ONLINE_SIGNUP } from 'common/src/app/data/apiComponents';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { COMPONENT } from 'common/src/app/data/entityTypes';

import { marketName } from 'common/src/app/config/market/market.configdefinitions';

import GroupOnlineSignUp from './GroupOnlineSignUp';

const mapStateToProps = state => {
  const { title, subtitle, group, online } =
    state.entities?.[COMPONENT]?.[API_COMPONENT_GROUP_OR_ONLINE_SIGNUP] || {};

  // Reduce the data based on the market
  const processData = data =>
    data &&
    Object.keys(data)?.reduce((processed, fieldName) => {
      let newFieldName = fieldName;

      if (marketName === 'UK' && fieldName === 'introductoryOfferUK') {
        newFieldName = 'introductoryOffer';
        delete data.introductoryOfferROI; // eslint-disable-line no-param-reassign
      }

      if (marketName === 'IE' && fieldName === 'introductoryOfferROI') {
        newFieldName = 'introductoryOffer';
        delete data.introductoryOfferUK; // eslint-disable-line no-param-reassign
      }

      processed[newFieldName] = data[fieldName]; // eslint-disable-line no-param-reassign
      return processed;
    }, {});

  return {
    headerTitle: title,
    headerSubtitle: subtitle,
    group: processData(group),
    online: processData(online),
  };
};

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(API_COMPONENT_GROUP_OR_ONLINE_SIGNUP)),
});

const connected = connect(mapStateToProps);

export default compose(addInitAction, connected)(GroupOnlineSignUp);
