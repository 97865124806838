import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import LandingGrid from 'components/templates/LandingGrid';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Pages from 'common/src/app/data/enum/Pages';
import Button from 'components/atoms/Button';
import landingGridProps from 'components/templates/LandingGrid/landingGridProps';

const RealLifeStoryNotFound = ({ gridContents }, context, className) => (
  <div className={className} data-testid="real-life-story-not-found">
    <div className="copy-with-background">
      <TextNew.Script.MD
        color={Colors.BRAND}
        localeId="smartNotFound.header"
        element={ElementTypes.H2}
        cid="sorry"
      />
      <TextNew.Serif.SM
        color={Colors.BRAND}
        localeId="smartNotFound.body"
        element={ElementTypes.H3}
        cid="alt"
      />
      <TextNew.Sans.SM color={Colors.BRAND} localeId="smartNotFound.story.tagLine" />
    </div>
    <LandingGrid {...gridContents} allowLoadMore={false} hideSortControls />
    <div className="cta-button">
      <Button to={Pages.UK_PUBLIC_SUCCESS_STORIES}>
        <LocaleMessage id="smartNotFound.story.cta" />
      </Button>
    </div>
  </div>
);

RealLifeStoryNotFound.propTypes = {
  gridContents: landingGridProps,
};

export default withFunctionalClassName(
  ComponentType.PAGE,
  'RealLifeStoryNotFound',
)(RealLifeStoryNotFound);
