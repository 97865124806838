import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import BmiCalculator from 'bundle-loader?lazy&reactHot&name=BmiCalculator!./index';

const routes = (
  <Route
    title="Slimming World BMI calculator | Simple BMI check"
    description="At Slimming World, you decide your personal weight loss goal. If you’d like to do a BMI check, this BMI calculator is a handy tool."
    path={Pages.UK_PUBLIC_BMI_CALCULATOR}
    {...getRouteComponentProp(BmiCalculator)}
  />
);

export default routes;
