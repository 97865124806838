import handleActions from 'redux-actions/lib/handleActions';
import { SET_ENTITY, UPDATE_ENTITIES } from '../../actions/entities/entityActions';

/**
 * A reducer that manages a single entity. The state for this reducer is an object
 * containing the entity data.
 */
const entityReducer = handleActions(
  {
    [SET_ENTITY]: (state, { payload: { merge, data } }) => {
      if (!merge) {
        // merge option is false. replace existing data
        return data;
      }
      if (typeof merge === 'function') {
        // merge option is a custom merging function
        return merge(state, data);
      }
      // merge option is true. perform a default merge
      return { ...state, ...data };
    },
    [UPDATE_ENTITIES]: (state, { payload: { data } }) => ({ ...state, ...data }),
  },
  {},
);

export default entityReducer;
