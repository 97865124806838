import collectionPaginationViewReducer from './collectionPaginationViewReducer';
import { META_PROP_PAGINATION_VIEW } from '../../../actions/collectionPaginationViewActions';

export default (state = {}, action) => {
  if (action.meta && action.meta[META_PROP_PAGINATION_VIEW]) {
    const viewId = action.meta[META_PROP_PAGINATION_VIEW];
    const newCollectionState = collectionPaginationViewReducer(state[viewId], action);
    if (newCollectionState !== state[viewId]) {
      return {
        ...state,
        [viewId]: newCollectionState,
      };
    }
  }

  return state;
};
