import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import TextNew, { Colors } from 'components/atoms/TextNew';
import Image from 'components/atoms/Image';
import { countryCode } from 'common/src/app/data/enum/Countries';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import errorBoundary from 'components/hoc/ErrorBoundary';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import OfferBlock from './molecules/OfferBlock';

import './better-health.scss';

const BetterHealth = ({ data }, {}, className) => {
  const country = marketConfig.marketName === countryCode.IRELAND ? 'roi' : 'uk';

  return (
    <main className={className}>
      <Helmet>
        {/* We do not want to show the better health page in search engine results such as google, react-helmet will update the head with a noindex meta tag for the page  */}
        <meta name="robots" content="noindex" />
      </Helmet>
      {data && (
        <header>
          <Image isBackgroundImage src={data?.heroImage?.src} alt={data?.heroImage?.alt}>
            <TextNew.Script.SM cid="head-bottom" color={Colors.BRAND}>
              {data.title}
            </TextNew.Script.SM>
          </Image>
        </header>
      )}

      <section className="content">
        <div className="logos">
          {data && (
            <>
              <picture className="sw-logo">
                <Image ratio={106 / 64} src={data?.logo1?.src} alt={data?.logo1?.alt} />
              </picture>
              <picture className="bh-logo">
                <Image ratio={98 / 64} src={data?.logo2?.src} alt={data?.logo2?.alt} />
              </picture>
            </>
          )}
        </div>

        <RichTextBlock text={data.introduction} cid="body" />

        <OfferBlock offers={data[`${country}Offers`]} />

        <RichTextBlock
          text={data[`${country}Disclaimer`]}
          cid="terms-and-conditons"
          dataTestid="terms-and-conditions"
        />
      </section>
    </main>
  );
};

BetterHealth.propTypes = {
  data: PropTypes.object,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.PAGE, 'BetterHealth'),
)(BetterHealth);
