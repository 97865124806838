import React from 'react';
import Link from 'react-router/lib/Link';
import PropTypes from 'prop-types';
import Accordion from 'components/atoms/Accordion';
import TextNew from 'components/atoms/TextNew';
import podcastCategoryShape from 'common/src/app/util/proptypes/apiEntities/podcastCategoryShape';

import './podcasts-list.scss';

const componentClass = 'molecule-podcasts-list';

const PodcastsList = ({ categories }) => (
  <div className={componentClass} data-testid="podcast-category-list">
    {categories.map(category => (
      <Accordion
        label={<TextNew.Serif.MD weight="bold">{category.name}</TextNew.Serif.MD>}
        key={category.id}
        isCollapsed
        toggleChevron
      >
        <ul>
          {category.podcasts.map(podcast => (
            <li key={podcast.id}>
              <Link href={podcast.link} target="_blank">
                <TextNew.Sans.SM dataTestid="podcast-title">{podcast.title}</TextNew.Sans.SM>
              </Link>
            </li>
          ))}
        </ul>
      </Accordion>
    ))}
  </div>
);

PodcastsList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape(podcastCategoryShape)),
};

export default PodcastsList;
