import handleActions from 'redux-actions/lib/handleActions';
import { OPEN_MODAL, CLOSE_MODAL, IS_OPENED_MODAL } from '../../../actions/components/modalActions';

const initialState = {
  component: null,
  props: {},
  showCloseButton: true,
  enableCloseOnBackdrop: true,
  isFullBleed: false,
  isOpen: false,
};

const modalReducer = handleActions(
  {
    [OPEN_MODAL]: (
      state,
      { payload: { component, props = {}, showCloseButton, enableCloseOnBackdrop, isFullBleed } },
    ) => ({
      ...state,
      component,
      props,
      showCloseButton,
      enableCloseOnBackdrop,
      isFullBleed,
      isOpen: true,
    }),
    [CLOSE_MODAL]: () => initialState,
    [IS_OPENED_MODAL]: (state, { payload: isOpen }) => ({
      ...state,
      isOpen,
    }),
  },
  initialState,
);

export default modalReducer;
