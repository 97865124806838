/* global 'molecule' */
import React, { Component } from 'react';
import moment from 'moment';

import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import InputType from 'common/src/app/data/enum/InputType';
import FormGroup from '../../../../../../molecules/FormGroup';
import InputNew from '../../../atoms/InputNew';

import './date-input-group.scss';

class DateInputGroup extends Component {
  state = {
    day: this.props.date?.date() || '',
    month: this.props.date?.month() + 1 || '',
    year: this.props.date?.year() || '',
  };

  componentDidUpdate(prevProps) {
    const oldDate = prevProps.date;
    const { date } = this.props;

    if (date !== oldDate && date) {
      if (date.isValid()) {
        this.setState({
          day: date.date(),
          month: date.month() + 1,
          year: date.year(),
        });
      }
    }
  }

  handleOnChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const { day, month, year } = this.state;
        const { onDateChange } = this.props;

        onDateChange && onDateChange(moment([year, month - 1, day]));
      },
    );
  };

  render() {
    const { day, month, year } = this.state;
    const { dataTestId } = this.props;

    return (
      <FormGroup type="inline" {...componentClassNameProp('molecule', this)} {...this.props}>
        <div className="dob-inputs" data-testid={dataTestId}>
          <InputNew
            value={day}
            placeholder="Day"
            type={InputType.NUMBER}
            name="day"
            onChange={this.handleOnChange}
            min="1"
            max="31"
            id={`${dataTestId}-day`}
            data-testid="day"
            title="day"
            aria-label="day"
            data-hj-suppress={true}
          />
          <InputNew
            value={month}
            placeholder="Month"
            type={InputType.NUMBER}
            name="month"
            onChange={this.handleOnChange}
            min="1"
            max="12"
            id={`${dataTestId}-month`}
            data-testid="month"
            title="month"
            aria-label="month"
            data-hj-suppress={true}
          />
          <InputNew
            value={year}
            placeholder="Year"
            type={InputType.NUMBER}
            name="year"
            onChange={this.handleOnChange}
            min="1900"
            max="2900"
            id={`${dataTestId}-year`}
            data-testid="year"
            title="year"
            aria-label="year"
            data-hj-suppress={true}
          />
        </div>
      </FormGroup>
    );
  }
}

DateInputGroup.propTypes = {
  onDateChange: PropTypes.func,
  date: PropTypes.object,
  dataTestId: PropTypes.any,
};

export default DateInputGroup;
