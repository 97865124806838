import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import classNames from 'classnames';
import Slider from 'react-slick';
import IconName from 'common/src/app/data/enum/IconName';
import NavigationButton from 'components/atoms/NavigationButton';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import Loader from 'components/atoms/Loader';
import promotionShape from './utils/propTypes/promotion';
import HomepageCampaignPromotion from './components/HomepageCampaignPromotion';

import './homepage-campaign-block.scss';

const carouselSettings = deviceState => ({
  autoplay: deviceState > DeviceState.XL,
  autoplaySpeed: 4000,
  cssEase: 'linear',
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NavigationArrow />,
  prevArrow: <NavigationArrow />,
  speed: 500,
});

const NavigationArrow = ({ onClick, className }) => (
  <NavigationButton
    uniqueId="homepage-campaign"
    buttonLabel={className.includes('slick-prev') ? 'Previous' : 'Next'}
    className={className}
    onClick={onClick}
    iconName={IconName.ARROW}
    direction={className.includes('slick-prev') ? DirectionType.UP : DirectionType.DOWN}
  />
);

const HomepageCampaignBlock = (
  { isLoading, promotions, deviceState },
  context,
  className,
  dataTestid,
) => {
  if (isLoading) {
    return (
      <section
        className={classNames(className, {
          'single-promo': promotions?.length === 1,
        })}
        data-testid={dataTestid}
      >
        <Loader />
      </section>
    );
  }

  return (
    <section
      className={classNames(className, {
        'single-promo': promotions?.length === 1,
      })}
      data-testid={dataTestid}
    >
      {promotions?.length > 1 ? (
        <Slider {...carouselSettings(deviceState)}>
          {promotions.map((promotion, index) =>
            promotion ? (
              <HomepageCampaignPromotion
                slidePosition={index + 1}
                id={`homepage-campaign-slide-${index}`}
                promotion={promotion}
                key={`${index}-number-slide`}
              />
            ) : null,
          )}
        </Slider>
      ) : (
        promotions?.[0] && (
          <figure role="group">
            <HomepageCampaignPromotion id="homepage-campaign-slide" promotion={promotions[0]} />
          </figure>
        )
      )}
    </section>
  );
};

HomepageCampaignBlock.propTypes = {
  deviceState: PropTypes.number,
  promotions: PropTypes.arrayOf(promotionShape),
  isLoading: PropTypes.bool,
};

NavigationArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'HomepageCampaignBlock')(HomepageCampaignBlock),
);
