import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import './bubble-background-wrapper.scss';

export const GradientTypes = {
  LINEAR: 'linear',
  RADIAL: 'radial',
};

const BubbleBackgroundWrapper = ({ children }, context, className) => (
  <div className={className}>{children}</div>
);

BubbleBackgroundWrapper.propTypes = {
  children: PropTypes.node,
  gradient: PropTypes.oneOf(Object.values(GradientTypes)), // eslint-disable-line react/no-unused-prop-types
  hasWhiteBubbles: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
};

BubbleBackgroundWrapper.defaultProps = {
  gradient: GradientTypes.RADIAL,
  hasWhiteBubbles: false,
};

export default withFunctionalClassName(ComponentType.ATOM, 'BubbleBackgroundWrapper', [
  'gradient-{gradient}',
  'hasWhiteBubbles',
])(BubbleBackgroundWrapper);
