/* global 'template','NearestGroupTownsInCounty' */
import React from 'react';
import { compose } from 'redux';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import Wrapper from 'components/atoms/Wrapper';
import errorBoundary from 'components/hoc/ErrorBoundary';
import PlaceDirectory from '../../organisms/PlaceDirectory';
import './nearest-group-towns-in-county.scss';

const NearestGroupRegions = (
  { isInitializing, towns, params: { county }, redirectToTowns },
  context,
  className,
) => {
  // If we only have one town, with the same name as the county
  // redirect to the towns page
  if (towns === 'redirectToTowns') {
    redirectToTowns(county);
  }
  return (
    <div className={className}>
      <Wrapper.MD cid="header" padding="xs">
        <h1>
          <TextNew.Serif.SM
            localeId="nearestGroupTownsInCounty.town.description"
            localeParams={{ COUNTY: startCase(county) }}
            color={Colors.DARK_TONE}
            cid="descriptor"
            element={ElementTypes.SPAN}
          />
        </h1>
      </Wrapper.MD>
      <PlaceDirectory isInitializing={isInitializing} data={towns} displaySortTitle={false} />
    </div>
  );
};

NearestGroupRegions.defaultProps = {
  towns: [],
};

NearestGroupRegions.propTypes = {
  isInitializing: PropTypes.bool,
  params: PropTypes.object,
  towns: PropTypes.object,
  redirectToTowns: PropTypes.func,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName('template','NearestGroupTownsInCounty'),
)(NearestGroupRegions);
