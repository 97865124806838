/* global WP_DEFINE_IS_NODE */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollBehavior from 'scroll-behavior';
import ScrollBehaviorContext from 'react-router-scroll/lib/ScrollBehaviorContext';
import GlobalStyling from 'components/atoms/GlobalStyling';
import ScrollManager from 'common/src/app/hoc/ScrollManager';
import LocationHash from 'common/src/app/data/enum/LocationHash';
import ModalRoot from 'components/atoms/ModalRoot';
import ContentModal from 'components/atoms/ContentModal';
import GalleryModal from 'components/atoms/GalleryModal';
import VideoModal from 'components/organisms/VideoModal';
import InstagramImageModal from 'components/molecules/InstagramImageModal';
import DeviceStateProvider from 'common/src/app/hoc/DeviceStateProvider';
import LocaleProvider from 'common/src/app/hoc/LocaleProvider';
import shouldUpdateScroll from 'common/src/app/util/shouldUpdateScroll';
import GeneralError from 'components/templates/GeneralError';
import QueryRoutingContainer from 'common/src/app/util/QueryRouting/QueryRoutingContainer';
import SkipLinks from 'components/molecules/SkipLinks';
import UkPublicNavigation from 'components/molecules/UkPublicNavigation';
import Header from 'components/organisms/Header';
import UkPublicFooter from 'components/organisms/UkPublicFooter';
import GroupSessionExplained from 'components/organisms/GroupSessionExplained';
import ModalNames from 'common/src/app/data/enum/ModalNames';

import getMessages from '../../utils/locale/getMessages';

const modalMap = {
  [ModalNames.CONTENT]: ContentModal,
  [ModalNames.GALLERY]: GalleryModal,
  [ModalNames.VIDEO]: VideoModal,
  [ModalNames.PUBLIC_GROUP_EXPLAINED_MODAL]: GroupSessionExplained,
  [ModalNames.INSTAGRAM_IMAGE_MODAL]: InstagramImageModal,
};

/**
 * The root component of the application.
 *
 * As this is a different component in every microservice, avoid creating functionality here
 * that is needed across all microservices. Instead, consider moving shared functionality to
 * a component that is in the common codebase.
 */

class Application extends Component {
  componentDidMount() {
    this.props.hasWebpSupport();
    this.props.persistHideNav && this.props.setPersistNav && this.props.setPersistNav();
  }

  render() {
    const { children, hasGeneralError, hideNavigation, isConsultantRoute } = this.props;

    const content = (
      <DeviceStateProvider>
        <LocaleProvider getMessages={getMessages}>
          <GlobalStyling>
            <div className="component-application">
              <SkipLinks isPublic />
              <ScrollManager />
              <QueryRoutingContainer query="modal" />
              <ModalRoot componentMap={modalMap} />
              {!hideNavigation && !isConsultantRoute && (
                <>
                  <Header>
                    <UkPublicNavigation />
                  </Header>
                </>
              )}
              {hasGeneralError ? (
                <GeneralError />
              ) : (
                <div className="application-page" id={LocationHash.CONTENT} tabIndex="-1">
                  {children}
                  {!hideNavigation && <UkPublicFooter />}
                </div>
              )}

              {hasGeneralError && !hideNavigation && <UkPublicFooter />}
            </div>
          </GlobalStyling>
        </LocaleProvider>
      </DeviceStateProvider>
    );

    return WP_DEFINE_IS_NODE ? (
      content
    ) : (
      <ScrollBehaviorContext
        routerProps={this.props}
        shouldUpdateScroll={shouldUpdateScroll}
        createScrollBehavior={config => new ScrollBehavior(config)}
      >
        {content}
      </ScrollBehaviorContext>
    );
  }
}

Application.propTypes = {
  children: PropTypes.node.isRequired,
  hasGeneralError: PropTypes.bool,
  hasWebpSupport: PropTypes.func,
  hideNavigation: PropTypes.bool,
  setPersistNav: PropTypes.func,
  persistHideNav: PropTypes.bool,
  isConsultantRoute: PropTypes.bool,
};

export default Application;
