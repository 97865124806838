/* global 'molecule','UserTestingHeader' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import Image from 'components/atoms/Image';
import Icon from 'components/atoms/Icon';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import IconName from 'common/src/app/data/enum/IconName';
import TextNew, { FontSizes, ElementTypes, Colors } from 'components/atoms/TextNew';
import './user-testing-header.scss';

const UserTestingHeader = ({ deviceState, title, description, image }, context, className) => {
  const titleSplit = title?.split(' ') || [];
  const [research, and, user, testing] = titleSplit;
  return (
    <div className={className}>
      <Image
        className={className}
        id="header"
        isBackgroundImage
        src={image?.src}
        alt={image?.alt}
        ratio={deviceState > DeviceState.SM ? 736 / 321 : 320 / 517}
      >
        <h1 className="title">
          <Icon
            cid="heart"
            width={deviceState > DeviceState.SM ? 30 : 16}
            name={IconName.HEART_LIKE_BORDER}
          />
          <TextNew.Script.XL
            color={Colors.PRIMARY_LIGHT}
            cid="title-research"
            element={ElementTypes.SPAN}
          >
            {research}
          </TextNew.Script.XL>
          <span className="title-user-testing">
            <TextNew.Script
              size={deviceState > DeviceState.SM ? FontSizes.MD : FontSizes.SM}
              color={Colors.PRIMARY_LIGHT}
              cid="title-and"
              element={ElementTypes.SPAN}
            >
              {`${and} `}
            </TextNew.Script>
            <TextNew.Script
              size={deviceState > DeviceState.SM ? FontSizes.MD : FontSizes.SM}
              color={Colors.PRIMARY_LIGHT}
              element={ElementTypes.SPAN}
            >
              {user}
            </TextNew.Script>
            <TextNew.Script
              size={deviceState > DeviceState.SM ? FontSizes.MD : FontSizes.SM}
              color={Colors.PRIMARY_LIGHT}
              element={ElementTypes.SPAN}
            >
              {` ${testing}`}
            </TextNew.Script>
          </span>
        </h1>
        <RichTextBlock text={description} />
      </Image>
    </div>
  );
};

UserTestingHeader.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  description: PropTypes.string,
  deviceState: PropTypes.number,
};

export default withDeviceState()(withFunctionalClassName('molecule','UserTestingHeader')(UserTestingHeader));
