import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import uniqueId from 'lodash/uniqueId';
import Configuration from 'common/src/app/config/Configuration';
import StyleType from 'common/src/app/data/enum/StyleType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { removeKey } from 'common/src/app/util/objectUtils';
import Icon from '../Icon';
import './select-new.scss';

/**
 * The select element is used to create a drop-down list.
 */

const SelectNew = (
  {
    name,
    options,
    placeholder,
    arrowType,
    dataTestId,
    suppress,
    id = name,
    customLabel,
    visibleLabel,
    field,
    onChange,
    ...otherProps
  },
  context,
  className,
) => {
  const placeholderOption = placeholder && (
    <option value="" disabled>
      {placeholder}
    </option>
  );

  const defaultValue = options.find(item => item?.selected)?.value || otherProps?.defaultValue;

  // Create a unique id for the select box if it does not have an id yet. Using react hooks here so it doesn't happen on each render
  const [numberedId] = useState(() => uniqueId('select-'));

  return (
    <div className={className}>
      {!visibleLabel && (
        <label className="screen-reader" htmlFor={id || numberedId}>
          {customLabel || placeholder}
        </label>
      )}
      <select // eslint-disable-line jsx-a11y/no-onchange
        id={id || numberedId}
        {...removeKey('descriptorType', otherProps)}
        {...removeKey('form', otherProps)}
        {...removeKey('styleType', otherProps)}
        name={name}
        data-testid={dataTestId}
        data-hj-suppress={suppress}
        defaultValue={defaultValue}
        {...field}
        onChange={onChange}
      >
        {placeholderOption}
        {options &&
          Object.keys(options).length !== 0 &&
          options.map((item, index) => (
            <option
              data-testid={dataTestId !== undefined ? `${dataTestId}-${item.value}` : item.value}
              disabled={item.disabled}
              key={index}
              value={item.value}
              aria-label={item?.ariaLabel}
            >
              {item.title}
            </option>
          ))}
        <optgroup label="" />
      </select>
      <Icon name={arrowType} />
    </div>
  );
};

SelectNew.defaultProps = {
  styleType: StyleType.PRIMARY,
  arrowType: Configuration.selectIconName,
  visibleLabel: false,
};

SelectNew.propTypes = {
  name: PropTypes.string,
  arrowType: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      disabled: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      selected: PropTypes.bool,
      ariaLabel: PropTypes.string,
    }),
  ),
  field: PropTypes.objectOf(PropTypes.any),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  dataTestId: PropTypes.string,
  suppress: PropTypes.bool,
  visibleLabel: PropTypes.bool,
  customLabel: PropTypes.string,
  onChange: PropTypes.func,
};

export default withFunctionalClassName(ComponentType.ATOM, 'SelectNew', [
  ({ styleType }) => styleType,
  ({ form }) => form?.dirty && 'dirty',
])(SelectNew);
