/* global 'molecule' */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import debugLib from 'debug';
import formFieldPropTypes from 'common/src/app/util/form/formFieldPropTypes';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import cancellableCallback from 'common/src/app/util/cancellableCallback';
import Row from '../../atoms/Row';

const debug = debugLib('SlimmingWorld:RadioButtonGroup');

class RadioButtonGroup extends Component {
  constructor(props) {
    super(props);
    this.processUnmountsCallback = cancellableCallback(this.processUnmounts);
  }

  getChildContext() {
    return {
      radioButtonGroup: {
        type: this.props.type,
        color: this.props.color,
        name: this.props.input.name,
        setValue: this.setValue,
        handleFocus: this.handleFocus,
        handleBlur: this.handleBlur,
        handleUnmount: this.handleUnmount,
        value: this.props.input.value,
      },
    };
  }

  componentWillUnmount() {
    if (this.processUnmountsTimeout) {
      clearTimeout(this.processUnmountsTimeout);
    }
    if (this.processUnmountsCallback) {
      this.processUnmountsCallback.cancel();
    }
  }

  setValue = value => {
    this.props.input.onChange(value);
  };

  handleFocus = () => {
    this.focusCount += 1;
    this.scheduleBlurUpdate();
  };

  handleBlur = () => {
    this.focusCount -= 1;
    this.scheduleBlurUpdate();
  };

  handleUnmount = value => {
    this.unmounted.push(value);

    if (this.processUnmountsTimeout) {
      clearTimeout(this.processUnmountsTimeout);
    }

    this.processUnmountsTimeout = setTimeout(this.processUnmountsCallback, 500);
  };

  processUnmounts = () => {
    this.processUnmountsCallback = null;
    if (this.unmounted.length) {
      debug(`Handle unmount for values: ${this.unmounted.join(',')}`);

      this.unmounted.forEach(unmountedValue => {
        if (this.props.input.value === unmountedValue) {
          this.props.input.onChange(null);
        }
      });

      this.unmounted.length = 0;
    }
  };

  scheduleBlurUpdate = debounce(50, () => {
    if (this.focusCount && !this.hasFocus) {
      this.props.input.onFocus();
      this.hasFocus = true;
    } else if (!this.focusCount && this.hasFocus) {
      this.props.input.onBlur();
      this.hasFocus = false;
    }
  });

  focusCount = 0;
  hasFocus = false;
  unmounted = [];
  processUnmountsTimeout = null;

  render() {
    const { column } = this.props;
    return (
      <Row column={column} {...componentClassNameProp('molecule', this)}>
        {this.props.children}
      </Row>
    );
  }
}
RadioButtonGroup.propTypes = {
  ...formFieldPropTypes,
  /**
   * Radio button group name
   */
  name: PropTypes.string,
  /**
   * Radio button direction
   */
  column: PropTypes.bool,
  /**
   * Indivdual Radio buttons
   */
  children: PropTypes.node,
};

RadioButtonGroup.childContextTypes = {
  radioButtonGroup: PropTypes.shape({
    type: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    handleFocus: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default RadioButtonGroup;
