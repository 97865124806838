/* eslint-disable no-console */
let latestAction = null;
let latestLoggedAction = null;

export const actionLoggerMiddleware = () => next => action => {
  latestAction = action;
  return next(action);
};

export const logActionIfChanged = () => {
  if (latestAction !== latestLoggedAction) {
    latestLoggedAction = latestAction;
    console.log(`%c${latestAction.type}`, 'background: #282828; color: #fff;');
    console.log({ payload: latestAction.payload, meta: latestAction.meta });
  }
};
