import CompositeInputFormatterError from '../CompositeInputFormatterError';
import { poundsToKilograms } from '../../util/conversionUtils';

/**
 * Converts values from the WeightMetricFieldGroup with stones input into a
 * number value in pounds and then in kilograms.
 *
 * @returns {number} The resulting number value in kilograms
 */
const composeWeightImperialWithStonesToMetric = ({ stones, fullPounds, remainingPounds }) => {
  if (typeof stones === 'undefined' && typeof fullPounds === 'undefined') {
    return stones;
  }
  const parsedStones = stones ? parseInt(stones, 10) : 0;
  const parsedPounds = fullPounds ? parseInt(fullPounds, 10) : 0;
  const parsedremainingPounds = remainingPounds ? parseFloat(remainingPounds) : 0;

  if (isNaN(parsedStones)) {
    throw new CompositeInputFormatterError({ stones: 'stones should be a number' });
  }
  if (isNaN(parsedPounds)) {
    throw new CompositeInputFormatterError({ fullPounds: 'lbs should be a number' });
  }
  if (parsedPounds >= 14) {
    throw new CompositeInputFormatterError({ fullPounds: 'lbs should be smaller than 14' });
  }
  const wholePounds = parsedremainingPounds + parsedPounds;
  // There are 14 pounds in each stone this add together wholepounds and converts
  const wholePoundsWithStones = wholePounds + parsedStones * 14;
  return poundsToKilograms(wholePoundsWithStones);
};

export default composeWeightImperialWithStonesToMetric;
