/* global WP_DEFINE_IS_NODE */
/* eslint-disable import/prefer-default-export */
import createAction from 'redux-actions/lib/createAction';
import {
  API_GOOGLE_MAPS,
  API_GOOGLE_CHARTS,
  API_GOOGLE_GEOLOCATION,
  POSTCODE_IO,
} from '../data/externalApis';

const GMAPS_API_TIMEOUT = 4000;
const GMAPS_CALLBACK_NAME = '__googleMapsOnLoadCallback';

export const apiLoaders = {
  [POSTCODE_IO]: (dispatch, state, params) =>
    fetch(`https://api.postcodes.io/postcodes/${params}`)
      .catch(err => err)
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.error) {
          return responseJson.error;
        }
        dispatch(
          geolocationData({
            name: POSTCODE_IO,
            geoData: responseJson.result,
          }),
        );
        return responseJson.result;
      }),
  [API_GOOGLE_GEOLOCATION]: (dispatch, state, params) => {
    const { key } = state.config.environmentConfig.gmaps;

    return fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${params}&key=${key}`)
      .catch(err => err)
      .then(response => response.json())
      .then(responseJson => {
        dispatch(
          geolocationData({
            name: API_GOOGLE_GEOLOCATION,
            geoData: responseJson.results,
          }),
        );
        return responseJson.results;
      });
  },
  [API_GOOGLE_MAPS]: (dispatch, state) => {
    const apiKey = state.config.environmentConfig.gmaps?.key;

    return new Promise((resolve, reject) => {
      if (WP_DEFINE_IS_NODE) {
        reject('Google Maps API should not be loaded on server side');
        return;
      }
      if (state.externalApis?.[API_GOOGLE_MAPS]) {
        return;
      }

      const apiTimeoutID = setTimeout(
        () => reject('Loading google maps API timed out'),
        GMAPS_API_TIMEOUT,
      );

      window[GMAPS_CALLBACK_NAME] = () => {
        clearTimeout(apiTimeoutID);
        resolve();
        window[GMAPS_CALLBACK_NAME] = null;
      };

      const scriptTag = document.createElement('script');
      scriptTag.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?libraries=places&key=${apiKey}&callback=${GMAPS_CALLBACK_NAME}`,
      );
      document.body.appendChild(scriptTag);
    }).then(() => dispatch(registerApiLoaded(API_GOOGLE_MAPS)));
  },
  [API_GOOGLE_CHARTS]: (dispatch, state) =>
    new Promise((resolve, reject) => {
      if (WP_DEFINE_IS_NODE) {
        reject('Google Charts API should not be loaded on server side');
        return;
      }
      if (state.externalApis?.[API_GOOGLE_CHARTS]) {
        return;
      }

      const scriptTag = document.createElement('script');
      scriptTag.setAttribute('src', 'https://www.gstatic.com/charts/loader.js');
      scriptTag.addEventListener('load', () => {
        window.google.charts.load('current', { packages: ['corechart', 'table'] });
        window.google.charts.setOnLoadCallback(resolve);
      });
      document.body.appendChild(scriptTag);
    }).then(dispatch(registerApiLoaded(API_GOOGLE_CHARTS))),
};

const apiPromises = {};

export const getApi = (id, params) => (dispatch, getState) => {
  if (!apiLoaders[id]) {
    throw new ReferenceError(`Cannot find api loader with id "${id}"`);
  }

  if (!apiPromises[id]) {
    apiPromises[id] = apiLoaders[id](dispatch, getState(), params);
  }

  return apiPromises[id];
};

export const getApiNoCache = (id, params) => (dispatch, getState) =>
  apiLoaders[id](dispatch, getState(), params);

export const STORE_API_LOADED = 'externalApiActions/STORE_API_LOADED';
export const registerApiLoaded = createAction(STORE_API_LOADED);

export const GEOLOCATION_DATA = 'externalApiActions/GEOLOCATION_DATA';
export const geolocationData = createAction(GEOLOCATION_DATA);
