import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Tabs as ReachTabs,
  TabList as ReachTabList,
  Tab as ReachTab,
  TabPanels as ReachTabPanels,
  TabPanel as ReachTabPanel,
} from '@reach/tabs';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import './panel-tabs.scss';

/**
 * PanelTab should be used when you don't want to link to another page but
 * instead when to dynamically change a panel via JS only
 * See https://reacttraining.com/reach-ui/tabs for full spec
 */
export const Tabs = ({ children, index, onChange, defaultIndex, keyboardActivation }) => (
  <ReachTabs
    index={index}
    onChange={onChange}
    defaultIndex={defaultIndex}
    keyboardActivation={keyboardActivation}
  >
    {children}
  </ReachTabs>
);

Tabs.propTypes = {
  children: PropTypes.node,
  defaultIndex: PropTypes.number,
  index: PropTypes.number,
  keyboardActivation: PropTypes.oneOf(['auto', 'manual']),
  onChange: PropTypes.func,
};

export const Tab = ({ as, dataTestid, children, styles, icon, selectedColor }) => (
  <ReachTab
    data-testid={dataTestid}
    className={classNames('tab-bar-list-item', selectedColor)}
    as={as}
  >
    <TextNew element={ElementTypes.SPAN} {...styles}>
      {children}
    </TextNew>
    {icon && <Icon name={icon} />}
  </ReachTab>
);

Tab.propTypes = {
  icon: PropTypes.string,
  dataTestid: PropTypes.string,
  styles: PropTypes.object,
  children: PropTypes.node,
  as: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  selectedColor: PropTypes.string,
};

export const TabList = ({ as, children }) => (
  <ReachTabList className="tab-bar-list" as={as}>
    {children}
  </ReachTabList>
);

TabList.propTypes = {
  children: PropTypes.node,
  as: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export const TabPanel = ({ as, children, className }) => (
  <ReachTabPanel className={className} as={as}>
    {children}
  </ReachTabPanel>
);

TabPanel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  as: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export const TabPanels = ({ as, children }) => <ReachTabPanels as={as}>{children}</ReachTabPanels>;

TabPanels.propTypes = {
  children: PropTypes.node,
  as: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
