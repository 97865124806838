import { DataLayerKeys, Category } from '../../data/enum/Tracking';

/**
 * Retrieves the right category and name for a POST entity. We have two categories for this:
 * 1) Community
 * 2) Slimming Group
 * @param trackingData
 * @param getState
 * @returns {{trackingData: *}}
 */
const updateTrackingDataCategory = (trackingData, getState) => {
  const state = getState();
  const data = {};
  const pageTracking = state.tracking.pageData.find(
    page => page.pathname === state.tracking.persistentData.pageUrl,
  );

  if (pageTracking && pageTracking.data.contentGroupName) {
    data.category = pageTracking.data.contentGroupName;

    if (pageTracking.data.contentGroupName === Category.SLIMMING_GROUP) {
      data.trackingName = DataLayerKeys.NAME_SLIMMING_GROUP;
    }
  }

  return { ...trackingData, ...data };
};

export default updateTrackingDataCategory;
