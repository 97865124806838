/* global 'molecule' */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Pages from 'common/src/app/data/enum/Pages';
import DataTestidOverides from 'common/src/app/data/enum/DataTestidOverides';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import { NavViews, NavViewClassNames } from 'common/src/app/data/enum/NavViews';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import LocationHash from 'common/src/app/data/enum/LocationHash';
import Row from '../../atoms/Row';
import Wrapper from '../../atoms/Wrapper';
import SlimmingWorldLogo from '../../atoms/SlimmingWorldLogo';
import JoinBar from '../../organisms/JoinBar';
import OffCanvasMenu from '../OffCanvasMenu';
import UKPublicNavigationLinks from '../UKPublicNavigationLinks';
import PublicNavMenu from './components/molecules/PublicNavMenu';
import CardRegistration from './components/molecules/CardRegistration';
import SignUpCTA from './components/molecules/SignUpCTA';

import './uk-public-navigation.scss';

class UKPublicNavigation extends PureComponent {
  render() {
    const { deviceState, viewOptions } = this.props;
    const headerHeight = this.wholeNavWrapper?.getBoundingClientRect()?.height;

    return (
      <div
        data-testid={DataTestidOverides.PUBLIC_NAVIGATION_BAR}
        id={LocationHash.MAIN_NAVIGATION}
        tabIndex="-1"
        className={classNames(
          componentClassNameProp('molecule', this).className,
          Object.keys(viewOptions).map(option => NavViewClassNames[option]),
        )}
      >
        <div className="main-header">
          <Wrapper cid="top-header" padding="xl" reference={ref => (this.wholeNavWrapper = ref)}>
            <Row row spacebetween aligncenter>
              <SlimmingWorldLogo
                dataTestid={DataTestidOverides.LOGO}
                themedLogo={{
                  height: deviceState > DeviceState.SM ? 78 : 54,
                  width: deviceState > DeviceState.SM ? 156 : 108,
                }}
                link={Pages.HOME}
              />
              <div className="navigation-links-wrapper">
                {!viewOptions[NavViews.HIDE_CARD_REG] && deviceState > DeviceState.SM && (
                  <CardRegistration />
                )}
                {!viewOptions[NavViews.HIDE_NAV_LINKS] && <PublicNavMenu />}
                {!viewOptions[NavViews.HIDE_JOIN_CTA] && viewOptions[NavViews.HIDE_NAV_LINKS] && (
                  <SignUpCTA />
                )}
                {!viewOptions[NavViews.HIDE_FIND_GROUP_CTA] &&
                  viewOptions[NavViews.HIDE_NAV_LINKS] && <SignUpCTA groupSearch />}
              </div>
            </Row>
          </Wrapper>
          {!viewOptions[NavViews.HIDE_GROUP_SEARCH_BAR] && <JoinBar />}

          {!viewOptions[NavViews.HIDE_NAV_LINKS] && (
            <OffCanvasMenu childRef={o => (this.offCanvas = o)} topOffset={headerHeight}>
              {customShowMenu => <UKPublicNavigationLinks isActive={customShowMenu} />}
            </OffCanvasMenu>
          )}
        </div>
      </div>
    );
  }
}

UKPublicNavigation.propTypes = {
  viewOptions: PropTypes.shape({
    [NavViews.HIDE_NAV_LINKS]: PropTypes.bool,
    [NavViews.HIDE_GROUP_SEARCH_BAR]: PropTypes.bool,
    [NavViews.HIDE_CARD_REG]: PropTypes.bool,
    [NavViews.HIDE_COUNTRY_DROPDOWN]: PropTypes.bool,
  }),
  deviceState: PropTypes.number,
};

UKPublicNavigation.defaultProps = {
  pageHasNoSearchBar: false,
};

export default withDeviceState()(UKPublicNavigation);
