import { withInitAction } from '@mediamonks/react-redux-component-init';
import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { API_STATIC_PUBLIC_WHAT_HAPPENS_IN_GROUP_PAGE } from 'common/src/app/data/apiStatics';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';

import WhatHappensInGroup from './WhatHappensInGroup';

const mapStateToProps = state => {
  const data = staticPageSelector(state, API_STATIC_PUBLIC_WHAT_HAPPENS_IN_GROUP_PAGE);
  const featuredSuccessStories = data?.featuredSuccessStories?.[0] || {};

  return {
    featuredStories: {
      slug: featuredSuccessStories?.successStoryArticle?.slug,
      beforeImage: featuredSuccessStories?.beforeImage?.src,
      afterImage: featuredSuccessStories?.afterImage?.src,
      title: featuredSuccessStories?.title,
      introduction: featuredSuccessStories?.introduction,
      subtitle: featuredSuccessStories?.subtitle,
      quote: featuredSuccessStories?.quote,
      memberName: featuredSuccessStories?.memberName,
    },
    heroBar: {
      image: data?.heroImage,
      title: data?.title,
      subtitle: data?.subtitle,
      introduction: data?.introduction,
    },
    groupFeatureCards: data?.groupFeatureCards || [],
    firstBanner: {
      line1: data?.banner1TitleLine1,
      line2: data?.banner1TitleLine2,
      description: data?.banner1Description,
      buttonLink: data?.banner1Buttons[0]?.buttonLink,
      buttonLabel: data?.banner1Buttons[0]?.buttonLabel,
    },
    promiseTitle: {
      line1: data?.promiseTitleLine1,
      line2: data?.promiseTitleLine2,
      line3: data?.promiseTitleLine3,
    },
    promisePoints: data?.promisePoints || [],
    secondBanner: {
      line1: data?.banner2TitleLine1,
      line2: data?.banner2TitleLine2,
      line3: data?.banner2TitleLine3,
      description: data?.banner2Description,
    },
    successStoryLeft: data?.successStoriesLeft[0],
    successStoryRight: data?.successStoriesRight[0],
  };
};

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    dispatch(getStaticContent(API_STATIC_PUBLIC_WHAT_HAPPENS_IN_GROUP_PAGE, true)),
});

const connected = connect(mapStateToProps, null);

export default compose(addInitAction, connected)(WhatHappensInGroup);
