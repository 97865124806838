/* global 'molecule','UserTestingActivityBlock' */
import React from 'react';
import classNames from 'classnames';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import Image from 'components/atoms/Image';
import Icon from 'components/atoms/Icon';
import TextNew, { FontSizes } from 'components/atoms/TextNew';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import './user-testing-activity-block.scss';

const UserTestingActivityBlock = (
  { deviceState, reference, image, imageRatio, text, title, type, icon, iconSize },
  context,
  className,
) => {
  const spaceRegex = new RegExp(' ', 'g');

  return (
    <div
      ref={reference}
      className={classNames(className, type.toLowerCase().replace(spaceRegex, '-'))}
    >
      <header>
        <TextNew.Script size={deviceState > DeviceState.MD ? FontSizes.XL : FontSizes.SM}>
          {title}
        </TextNew.Script>
        {icon && (
          <div className="circle">
            <Icon name={icon} width={iconSize} />
          </div>
        )}
      </header>
      {image && <Image src={image?.src} alt={image?.alt} ratio={imageRatio} />}
      <RichTextBlock text={text} />
    </div>
  );
};

UserTestingActivityBlock.propTypes = {
  image: PropTypes.object,
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  imageRatio: PropTypes.number,
  iconSize: PropTypes.number,
  reference: PropTypes.object,
  deviceState: PropTypes.number,
};

export default withDeviceState()(
  withFunctionalClassName('molecule','UserTestingActivityBlock')(UserTestingActivityBlock),
);
