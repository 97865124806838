/* global 'molecule','IcelandProductCharacteristics' */
import React from 'react';
import PropTypes from 'prop-types';
import { availableFlags } from 'common/src/app/util/bitwiseUtils';
import FoodRangeFlags from 'common/src/app/data/enum/FoodRangeFlags';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ItemRating from 'components/atoms/ItemRating';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import './iceland-product-characteristics.scss';

/**
 * This component display nutritional information about a product such as if it
 * is vegan and its sprice rating
 */
const findCharacter = (array, character) =>
  array?.length > 0 ? array.find(i => i === character) : null;

const IcelandProductCharacteristics = ({ chilliRating, characteristics }, context, className) => {
  const flags = characteristics && Object.values(availableFlags([characteristics], FoodRangeFlags));
  const isVegan = findCharacter(flags, 'Vegan');
  const isVegetarian = findCharacter(flags, 'Vegetarian');
  const isSP = findCharacter(flags, 'SpeedFood');

  if (!(isVegan || isVegetarian || isSP || chilliRating)) {
    return null;
  }

  return (
    <div className={className}>
      <div className="characteristics">
        {isVegan && <Icon name={IconName.ICELAND_VEGAN} height={24} />}
        {isVegetarian && <Icon name={IconName.ICELAND_VEGETARIAN} height={24} />}
        {isSP && <Icon name={IconName.ICELAND_SP} height={24} />}
      </div>
      {chilliRating !== 0 && (
        <ItemRating maximumRating={3} itemRating={chilliRating} icon={IconName.CHILLI} />
      )}
    </div>
  );
};

IcelandProductCharacteristics.propTypes = {
  chilliRating: PropTypes.number,
  characteristics: PropTypes.number,
};

export default withFunctionalClassName('molecule','IcelandProductCharacteristics')(IcelandProductCharacteristics);
